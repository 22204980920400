import React from 'react';
import { useState } from 'react';
import { addEmployeeTesting } from '../../api';
import {TextField, Box, Typography, Button} from '@material-ui/core'
import { useHistory } from 'react-router-dom';
export const InputForm = (props: any) => {
    const [alert, setAlert] = useState(false)
    const [alert1, setAlert1] = useState(false)
    const [mail, setMail] = useState('');
    const [firstName, setFirstName] = useState('');
    const history = useHistory();
    const [lastName, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const handleMailChange = (e: any) => {
        setAlert(false)
        setMail(e.target.value);
    }

    const handleFirstnameChange = (e: any) => {
        setFirstName(e.target.value)
    }
    const handleLastnameChange = (e: any) => {
        setLastName(e.target.value)
    }
    const handleSubmit = async (e: any) => {
        if(mail){
            setIsLoading(true)
           const newRow = props.rowdata.filter((e: any) => e.emailId === mail);
           if (newRow.length === 0) {
            await addEmployeeTesting({
                emailId: mail ? mail?.trim()?.toLowerCase() : mail,
                firstName:firstName ? firstName?.trim() : firstName,
                lastName: lastName ? lastName?.trim() : lastName,
            })
            props.handleCancel()
            props.setOpen(true)
            setIsLoading(false)
          window.location.reload();
           }else{
               setAlert1(true)
               setIsLoading(false)
           }
       
        } else {
            setAlert(true)
            setIsLoading(false)
        }
       
    }
    const cancelHandle=()=>{
        props.handleCancel()
        setIsLoading(false);
        setMail("")
        setFirstName("")
        setLastName("")
    }

return (
        
        <form >
        <Box
      component="form"
    >
    <Typography variant="subtitle2" color="initial" > Email Address </Typography>
      <TextField id="outlined-basic" value={mail} className='txt' type='email'  variant="outlined"  onChange={handleMailChange} style={{width : '300px'}} /><br /><br />
    <Typography variant="subtitle2" color="initial" > First Name </Typography>
      <TextField id="outlined-basic" value={firstName} className='txt1' type='text' variant="outlined" onChange={handleFirstnameChange} style={{width : '300px'}} /><br /><br />
    <Typography variant="subtitle2" color="initial" > Last Name </Typography>
      <TextField id="outlined-basic" value={lastName} type='text' className='txt2' variant="outlined" onChange={handleLastnameChange} style={{width : '300px'}} /><br /><br />
      {alert ? <p style={{'color':'red'}}>Please enter email</p> : alert1 ? <p style={{'color' :'red'}}>This email is already exist</p> : "" }
      <div style={{textAlign : 'right'}}><br />
      <Button variant="outlined" size="medium" style={{color : '#6558F5', borderColor : '#6558F5'}} onClick={cancelHandle}>
          Cancel
        </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        { isLoading ? <Button variant="contained" size="medium" type='button' onClick={handleSubmit} style={{'backgroundColor' : '#6558F5', 'color' : '#fff'}}>
        Processing...
        </Button> 
        : <Button variant="contained" size="medium" type='button' onClick={handleSubmit} style={{'backgroundColor' : '#6558F5', 'color' : '#fff'}}>
        Submit
        </Button> }
        </div> 

    </Box>
    <br />
    </form>
   
);
};
