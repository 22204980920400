import React, { useState, useEffect } from 'react';
import axios from 'axios';

// @material-ui/icons
import { makeStyles } from '@material-ui/core/styles';
import Card from './Card/Card.js';
import Grid from '@material-ui/core/Grid';
// Component
import CardBody from './Card/CardBody.js';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';

import {
    Button,
    Col,
    Form,
    Layout,
    Select,
    Typography,
    Row,
    Space,
} from 'antd';
import { useAdminState } from '../../provider';
import { format } from 'date-fns';
import { useSharedState } from '../../../shared/provider';
import { config } from '../../../../config';
import { getPeriodsFromSchedule } from '../../../../utils';

import styles from './dashboardStyle.js';
import { apiConfig } from '../../../../apiConfig';
const useStyles = makeStyles(styles);

const { Title } = Typography;
const { Option } = Select;

const periods = getPeriodsFromSchedule(config.schedule);

export default function KeyMetrics() {
    const classes = useStyles();
    const { locations } = useSharedState();
    const [loading, setLoading] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState(
        format(new Date(), 'LL/dd/yyyy')
    );
    const [selectedToDate, setSelectedToDate] = useState(
        format(new Date(), 'yyyy-LL-dd')
    );
    const [selectedLocation, setLocation] = useState('');
    const [expand, setExpand] = useState(true);

    const [selectedTime, setTime] = useState('');
    const [selectedStatus, setStatus] = useState('');
    const [searchresult, searchConsolidationReport] = useState([]);
    const [apiresult, setResult] = useState(true);
    const [Cancellation, cancellationcount] = useState('');
    const [Allappointment, allappointment] = useState('');

    const handleselectionlocation = (location: any) => {
        if (location === 'All') {
            setLocation('');
        } else {
            setLocation(location);
        }
    };

    const handleselectionfromdate = (date: any) => {
        var toDates = date;
        setSelectedFromDate(format(toDates, 'LL/dd/yyyy'));

        var myFutureDate = new Date(date);
        var myFutureDates = new Date(
            myFutureDate.setDate(myFutureDate.getDate() + 7)
        );
        setSelectedToDate(format(myFutureDates, 'yyyy-LL-dd'));
    };

    const handleSearch = () => {
        searchconsolidationapi();
    };

    function searchconsolidationapi() {
        setResult(true);
        const searchparams = {
            appttime:
                selectedTime !== undefined && selectedTime !== ''
                    ? selectedTime
                    : null,
            results:
                selectedStatus !== undefined && selectedStatus !== ''
                    ? selectedStatus
                    : null,
            location:
                selectedLocation !== undefined && selectedLocation !== ''
                    ? selectedLocation
                    : null,
            fromdate: selectedFromDate,
            todate: format(new Date(selectedToDate), 'LL/dd/yyyy'),
        };

        axios({
            method: 'post',
            // url: `${apiConfig.hostUrl}/getfilterwithrecords`,
            url: `${config.cloudRun.baseUrl}getfilterwithrecords`,
            data:  searchparams,
        }).then(function (response) {
            if (response?.data.status === 200) {
                setResult(false);
                const items = response?.data.data.Location;
                if (selectedLocation !== undefined && selectedLocation !== '') {
                    var result = items.filter((obj: any) => {
                        return obj.Locationid === selectedLocation;
                    });
                    searchConsolidationReport(result);
                } else {
                    searchConsolidationReport(items);
                }

                allappointment(response?.data.data.allsitescount);
                cancellationcount(response?.data.data.cancelappointmentcount);
                return true;
            }
        });
    }

    useEffect(() => {
        var todate = new Date();
        var todateselect = new Date(todate.setDate(todate.getDate() + 7));
        setSelectedToDate(format(todateselect, 'yyyy-LL-dd'));
        setSelectedFromDate(format(new Date(), 'LL/dd/yyyy'));
        setLocation('');
        setTime('');
        setStatus('');
    }, []);

    return (
        <Layout
            style={{
                marginTop: '100px',
                backgroundColor: '#FFF',
                border: '1px solid #f3eeee',
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
            }}
        >
            <div
                style={{
                    borderRadius: '3px',
                    background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                    boxShadow:
                        '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                    margin: '0px 25px',
                    marginTop: '-25px',
                }}
            >
                <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
                    Key Metrics
                </Title>
            </div>

            <div
                style={{
                    flexGrow: 1,
                    marginTop: '50px',
                    marginBottom: '20px',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ backgroundColor: 'white' }}>
                        <Form style={{ marginLeft: '5%' }} layout="vertical">
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                    xl: 40,
                                    xxl: 48,
                                }}
                            >
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 8 }}
                                >
                                    <Form.Item
                                        label="From Date"
                                        name="fromdate"
                                    >
                                        <AntDDatePicker
                                            style={{ width: '95%' }}
                                            disabled={false}
                                            defaultValue={
                                                new Date(selectedFromDate)
                                            }
                                            showToday
                                            onChange={(fromDate: any) =>
                                                handleselectionfromdate(
                                                    fromDate
                                                )
                                            }
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 8 }}
                                >
                                    <Form.Item label="To Date" name="todate">
                                        <div
                                            style={{
                                                paddingLeft: '5px',
                                                paddingRight: '5px',
                                                paddingTop: '4.8px',
                                                paddingBottom: '4.8px',
                                                border: '1px solid #e2e2e2',
                                                borderRadius: 2,
                                                fontSize: 14,
                                                backgroundColor: '#F7F7F7',
                                                outline: 'none',
                                            }}
                                        >
                                            <span>{selectedToDate}</span>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 8 }}
                                >
                                    <Form.Item label="Location" name="location">
                                        <Select
                                            disabled={loading}
                                            allowClear
                                            placeholder="Location"
                                            showArrow
                                            onChange={(location: any) =>
                                                handleselectionlocation(
                                                    location
                                                )
                                            }
                                        >
                                            <option value="All">All</option>
                                            {locations?.map((location) => (
                                                <Option
                                                    key={
                                                        location.qbenchCustomerId
                                                    }
                                                    value={
                                                        location.qbenchCustomerId
                                                    }
                                                >
                                                    {location.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 8 }}
                                >
                                    <Form.Item label="Time" name="time">
                                        <Select
                                            allowClear
                                            placeholder="Appointment Time"
                                            showArrow
                                            onChange={(time: any) =>
                                                setTime(time)
                                            }
                                        >
                                            {periods?.map((period) => (
                                                <Option
                                                    key={period.index}
                                                    value={period.index}
                                                >
                                                    {period.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 8 }}
                                >
                                    <Form.Item
                                        label="Result Status"
                                        name="status"
                                    >
                                        <Select
                                            allowClear
                                            showArrow
                                            placeholder="Result Status"
                                            onChange={(status: any) =>
                                                setStatus(status)
                                            }
                                        >
                                            <Option value="detected">
                                                Detected
                                            </Option>
                                            <Option value="notdetected">
                                                Not Detected
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>{' '}
                            </Row>
                            <Row>
                                <Col
                                    span={18}
                                    style={{
                                        marginLeft: expand ? '0%' : '65%',
                                    }}
                                ></Col>
                                <Col
                                    span={6}
                                    style={{
                                        marginLeft: expand ? '0%' : '65%',
                                    }}
                                >
                                    <Form.Item name="search">
                                        <Space
                                            size="small"
                                            // style={styles.searchBarActions}
                                        >
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ fontSize: 15 }}
                                                onClick={handleSearch}
                                            >
                                                {' '}
                                                Search{' '}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Grid>

                    {apiresult ? (
                        false
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    style={{ backgroundColor: '#ffffff' }}
                                >
                                    <Card className={classes.defectcardview}>
                                        <CardBody
                                            className={classes.defectcardview}
                                        >
                                            <div id="container">
                                                <div
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        float: 'left',
                                                        width: '100%',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '14px',
                                                                color:
                                                                    '#4d4f5c',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            Appointments - All
                                                            Sites
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '24px',
                                                                color: '#000',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {Allappointment}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    style={{ backgroundColor: '#ffffff' }}
                                >
                                    <Card className={classes.defectcardview}>
                                        <CardBody
                                            className={classes.defectcardview}
                                        >
                                            <div id="container">
                                                <div
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        float: 'left',
                                                        width: '100%',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '14px',
                                                                color:
                                                                    '#4d4f5c',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            Cancellations - All
                                                            Sites
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '24px',
                                                                color: '#000',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {Cancellation}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </Grid>

                            <div
                                style={{
                                    float: 'left',
                                    width: '100%',
                                    marginTop: '1%',
                                    marginBottom: '1%',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',
                                    backgroundColor: '#D8D8D8',
                                }}
                            />

                            {searchresult.map((item: any, i) => (
                                <Grid
                                    key={i}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    style={{ backgroundColor: '#ffffff' }}
                                >
                                    <Card className={classes.defectcardview}>
                                        <CardBody
                                            className={classes.defectcardview}
                                        >
                                            <div id="container">
                                                <div
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        float: 'left',
                                                        width: '100%',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '14px',
                                                                color:
                                                                    '#4d4f5c',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {item.Location}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '24px',
                                                                color: '#000',
                                                                display:
                                                                    'block',
                                                                textAlign:
                                                                    'center',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {item.count}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </div>
        </Layout>
    );
}
