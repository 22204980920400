import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { format, parse } from 'date-fns';
import { SiGoogleclassroom } from 'react-icons/si'
import pin from '../../../../../assets/pin-grey.svg';
import calendar from '../../../../../assets/calendar-grey.svg';
import clock from '../../../../../assets/clock.svg';
import person from '../../../../../assets/person-grey.svg';
import moment from 'moment';
import { differenceInHours } from 'date-fns';
import ChangeCalendlyModel from './ChangeCalendlyModel';
import {
    Container,
    Content,
    Button,
} from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import {
    useStepsState,
} from '../../../../shared/providers/schedule/provider';
import { Breakpoints } from '../../../../../../src/dictionaries';
import { config } from '../../../../../../src/config';
import { getPeriodsFromSchedule } from '../../../../../../src/utils';
import FormLabel from '../../../../shared/components/form/FormLabel';
import {
    DatepickerField,
} from '../../../../shared/components/Datepicker';
import ChangeLocationModal from '../../AppointmentList/components/ChangeLocationModel';
import ChangeClassRoomField from '../../AppointmentList/components/ChangeClassRoomField';
import ChangeDateTimeModal from '../../AppointmentList/components/ChangeDateTimeModal';
import EditAppointmentModal from '../../AppointmentList/components/EditAppointmentModal';
import PaymentModal from '../../../../shared/components/ConfirmAndPay/PaymentModal';
import { useAdminDispatch, useAdminState } from '../../../provider';
import Loader from '../../../../shared/components/Loader';
// import EditableInfo from './EditableInfo';
import { isBefore, startOfDay, subHours } from 'date-fns';
import {
    checkInCrelioAppointment,
    getAppointmentdata,
    updateAppointmentdata,
} from '../../../api';

const Title = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 21px 0;
`;

export const TestingInfoContainer = styled.div`
  background: ${colors.blue8};
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;

    & > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;

export const PinIcon = styled(Icon)`
  left: 5px;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat center center;
`;

export const CalendarIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${person}) no-repeat center center;
`;

export const TestingInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  & > * {
    margin: 8px 0;
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    margin: 0 -30px;

    & > * {
      margin: 0 30px;
    }
  }
`;

export const TestingInfo = styled.div`
  position: relative;
  padding-left: 31px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TestingInfoBtn = styled(Button)`
  max-width: 200px;
`;

interface ButtonProps {
    libraryType: 'primary' | 'default';
    accentColor?: string;
    size?: 'sm' | 'lg';
}

const ReturnButton = styled.button`
  border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
  font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
  line-height: 1.6;
  border-radius: 5px;
  padding: ${({ size }: ButtonProps) =>
        size === 'sm' ? '4px 15px' : '9px 20px'};
  font-weight: ${({ size }: ButtonProps) =>
        size === 'sm' ? 'normal' : 'bold'};
  width: 100%;
  text-align: center;
  color: ${({ libraryType, accentColor }: ButtonProps) => {
        return libraryType === 'primary'
            ? colors.white
            : accentColor || colors.darkBlue;
    }};
  background: ${({ libraryType, accentColor }: ButtonProps) =>
        libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
        libraryType === 'primary'
            ? accentColor || colors.darkBlue50
            : colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
        return libraryType === 'primary'
            ? colors.white
            : accentColor || colors.darkBlue50;
    }};
  }
`;

const ErrorInfoText = styled.div`
  color: red;
  font-weight: bold;
  font-size: 16px;
`;

// const EditableInfosWrapper = styled.div`
//   margin-top: 40px;
// `;
interface Props {
    handleDrawerToggle: () => void;
    appointment: any;
    apptType?: boolean;
    // setModalVisible: (params: any) => void;
    fromApptList?: boolean;
    returnBtn?: boolean;
    handleSearch:()=>void;
}

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);

const EditAppoinment: React.FC<Props> = ({
    appointment,
    // setModalVisible,
    fromApptList,
    handleDrawerToggle,
    returnBtn,
    handleSearch
}) => {
    const {
        form: {
            commitToAttend,
            agreeToCancel,
            location,
            phone,
            date,
            firstName,
            lastName,
        },
    } = useStepsState();
    const [previousDateAndTime, setPreviousDateAndTime] = useState<any>(null);
    const [isAllowRescheduleForFutureAppt, setIsAllowRescheduleForFutureAppt] = useState(false)
    const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
    const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [
        showChangeApptAttendeeModal,
        setShowChangeApptAttendeeModal,
    ] = useState(false);
    const [
        showChangeClassroomModal,
        setShowChangeClassroomModal,
    ] = useState(false);
    const [showDepartureTime, setShowDepartureTime] = useState(false);

    const { params, Editappointments }: any = useAdminState();
    const { setEditAppointment, updateParams } = useAdminDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [timeValue, setTimeValue] = useState('');
    const [error, setError] = useState(false);
    const [roomValue, setRoomValue] = useState('');
const [showChangeCalendlyModel, setshowChangeCalendlyModel] = useState(false);
    const selectedDate = date
        ? parse(date, config.dateFormat, new Date())
        : new Date();

    const checkinHandler = async (record: any) => {
        const checkInData = {
            phone: record.phone,
            bookingNo: record.acknowledgementId,
        };

        await checkInCrelioAppointment(checkInData)
            .then(() => {
                handleDrawerToggle();
                window.location.href = '/dashboard/checkin';
            })
            .catch((e) => {
                handleDrawerToggle();
                window.location.href = '/dashboard/checkin';
            });
    };

    const getHourDifference = (startDate: Date, endDate: Date) =>
        moment.duration(moment(endDate).diff(moment(startDate))).asHours();
    const [loading, setLoading] = useState<boolean>(false);
    const handleEditAppointment = async () => {
         //setModalVisible(false);
         let params: any;
         let localMinors = JSON.parse(
             JSON.stringify(
                 Editappointments[0]
                     ?.minors
             )
         );
         localMinors.map(
             (e: any) =>
                 (e.birthDate = e.birthDate
                     ? format(
                       new Date(
                         e.birthDate
                     ),
                           config.dateFormat
                       )
                     : Editappointments[0]
                           ?.birthDate)
         );
         if (!Editappointments[0].isSchedularLite && Editappointments[0].cPartnerID === 'LLT001') {
             if (!timeRegExp.test(timeValue ==''?Editappointments[0].slot.label:timeValue)) {
                 setError(true);
             }
             params = {
                 ...Editappointments[0],
                 location: { ...Editappointments[0].location, room: roomValue==''?Editappointments[0].location.room:roomValue },
                 slot: {
                     ...Editappointments[0].slot,
                     date:Editappointments[0]?.date,
                     minors: localMinors,
                     label: timeValue ==''?Editappointments[0].slot.label:timeValue,
                     locationId: Editappointments[0].location.qbenchCustomerId,
                 },
                 // school:{
                 //     label:Editappointments[0].location.name,
                 //     value:Editappointments[0].location.name,
                 //     qbenchCustomerId:Editappointments[0].location.qbenchCustomerId
                 // },
                 note: Editappointments[0]?.note
                     ? Editappointments[0]?.note
                     : '',
             };
         } else {
             params = {
                 ...Editappointments[0],
                 minors: localMinors,
                 note: Editappointments[0]?.note
                     ? Editappointments[0]?.note
                     : '',
             };
         }
        try {
            if(!Editappointments[0].isSchedularLite && !timeRegExp.test(timeValue) && Editappointments[0].cPartnerID==='LLT001' && !roomValue.length ){
                console.log(timeValue)
            }else {
            setError(false)
                setLoading(true);
               const response= await updateAppointmentdata(params);
               if(response.data===null){
                handleSearch();
                 handleSearch();
               }
          
                setLoading(false);
                if (appointment?.apptType) {
                    if (
                        getHourDifference(
                            new Date(),
                            new Date(Editappointments[0]?.slot?.date)
                        ) > 72
                    ) {
                        checkinHandler(Editappointments[0]);
                    } else {
                        handleDrawerToggle();
                        window.location.href = '/dashboard/checkin';
                    }
                } else {
                    setEditAppointment(null);
                    handleDrawerToggle();
                    // updateParams(params);
                }
            }
            
        } catch (e) {
            
            setEditAppointment(null);
            handleDrawerToggle();
            updateParams(params);
        }
    };

    const timeRegExp = new RegExp(/((([1-9])|(1[0-2])):([0-5])(0|5)\s(A|P)M)/);
    const locationStartDate = location
        ? parse(location.startDate, config.dateFormat, new Date())
        : null;

    // console.log("Editappointments[0].departureDateAndTime", Editappointments);

    let hourDiff: any = null;
    if (
        Editappointments !== undefined &&
        Editappointments !== null &&
        Editappointments[0]?.departureDateAndTime &&
        Editappointments[0].testSelection === 'flight'
    ) {
        if (Editappointments[0].isRapidTest) {
            hourDiff = differenceInHours(
                new Date(Editappointments[0]?.departureDateAndTime),
                new Date(
                    `${Editappointments[0].date} ${rapidPeriods[Editappointments[0].slot?.period]?.label.split('-')[0]
                    }`
                )
            );
        } else if (Editappointments[0].slot !== undefined) {
            hourDiff = differenceInHours(
                new Date(Editappointments[0].departureDateAndTime),
                new Date(
                    `${Editappointments[0].date} ${periods[Editappointments[0].slot?.period]?.label.split('-')[0]
                    }`
                )
            );
        }
    }
    useEffect(() => {
        if(!previousDateAndTime && Editappointments)
            setPreviousDateAndTime({time:Editappointments[0]?.slot?.label, date:Editappointments[0]?.date, location:Editappointments[0]?.location})
    }, [Editappointments])
    return (
        <div>
            {Editappointments !== null ? (
                <Container size="lg">
                    <Content style={{ padding: `${!fromApptList && '0px'}` }}>
                        <Formik
                            initialValues={{
                                commitToAttend,
                                agreeToCancel,
                                phone,
                                departureDateAndTime:
                                    Editappointments[0]?.departureDateAndTime,
                                name: `${firstName} ${lastName}`,
                                isExpressSameDayTest:
                                    Editappointments[0]?.isExpressSameDayTest,
                            }}
                            onSubmit={() => {
                                // setShowPaymentModal(true)
                            }}
                        >
                            {({ dirty, isValid, values, setFieldValue }) => (
                                <Form>
                                    <Title>Testing Information</Title>
                                    {Editappointments[0]?.testSelection ===
                                        'flight' &&
                                        Editappointments[0]
                                            .departureDateAndTime && (
                                            <>
                                                <TestingInfoContainer>
                                                    <TestingInfo>
                                                        <PinIcon />
                                                        <strong>
                                                            Departure date and
                                                            time
                                                        </strong>
                                                        <br />
                                                        {
                                                            Editappointments[0]
                                                                .departureDateAndTime
                                                        }
                                                    </TestingInfo>
                                                    <TestingInfoBtn
                                                        type="button"
                                                        onClick={() =>
                                                            setShowDepartureTime(
                                                                true
                                                            )
                                                        }
                                                        size="sm"
                                                        libraryType="default"
                                                    >
                                                        {'Edit Departure Time'}
                                                    </TestingInfoBtn>
                                                </TestingInfoContainer>
                                                {showDepartureTime && (
                                                    <>
                                                        <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                                            <DatepickerField
                                                                name="departureDateAndTimedata"
                                                                showTimeSelect
                                                                minDate={
                                                                    locationStartDate ===
                                                                        null ||
                                                                    isBefore(
                                                                        locationStartDate,
                                                                        new Date()
                                                                    )
                                                                        ? new Date()
                                                                        : locationStartDate
                                                                }
                                                                onChange={(
                                                                    newDepartureDate: any
                                                                ) => {
                                                                    //This time zone offset is for pacific daylight time
                                                                    //Will need to add the other time zones offset later.
                                                                    let getTimeZoneOffSet = new Date(
                                                                        newDepartureDate
                                                                    ).getTimezoneOffset();

                                                                    const overTime = new Date(
                                                                        newDepartureDate
                                                                    ).getHours();

                                                                    let newDate = values.isExpressSameDayTest
                                                                        ? subHours(
                                                                              newDepartureDate,
                                                                              overTime >=
                                                                                  12
                                                                                  ? 24
                                                                                  : config
                                                                                        .vipSlots
                                                                                        .minAdvanceHours
                                                                          ) // startOfDay(newDepartureDate)
                                                                        : // : subHours(newDepartureDate, config.maxAdvanceHours);
                                                                          subHours(
                                                                              newDepartureDate,
                                                                              getTimeZoneOffSet ==
                                                                                  420 ||
                                                                                  getTimeZoneOffSet ==
                                                                                      300 ||
                                                                                  getTimeZoneOffSet ==
                                                                                      240
                                                                                  ? 71
                                                                                  : config.maxAdvanceHours
                                                                          );

                                                                    if (
                                                                        isBefore(
                                                                            newDate,
                                                                            new Date()
                                                                        )
                                                                    ) {
                                                                        newDate = startOfDay(
                                                                            new Date()
                                                                        );
                                                                    }

                                                                    setFieldValue(
                                                                        'departureDateAndTime',
                                                                        format(
                                                                            newDepartureDate,
                                                                            config.dateTimeFormat
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        </FormLabel>
                                                    </>
                                                )}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        margin:
                                                            '0px 0px 25px 0px',
                                                    }}
                                                >
                                                    <ReturnButton
                                                        onClick={async () => {
                                                            setButtonLoading(
                                                                true
                                                            );
                                                            setShowDepartureTime(
                                                                false
                                                            );
                                                            setDisabled(true);
                                                            // format minors DOB
                                                            let localMinors = JSON.parse(
                                                                JSON.stringify(
                                                                    Editappointments[0]
                                                                        ?.minors
                                                                )
                                                            );
                                                            localMinors.map(
                                                                (e: any) =>
                                                                    (e.birthDate = e.birthDate
                                                                        ? format(
                                                                              new Date(
                                                                                  e.birthDate
                                                                              ),
                                                                              config.dateFormat
                                                                          )
                                                                        : Editappointments[0]
                                                                              ?.birthDate)
                                                            );

                                                            let params: any = {
                                                                ...Editappointments[0],
                                                                minors: localMinors,
                                                                departureDateAndTime:
                                                                    values.departureDateAndTime,
                                                                note: Editappointments[0]
                                                                    ?.note
                                                                    ? Editappointments[0]
                                                                          ?.note
                                                                    : '',
                                                            };
                                                            const udpateAppt = await updateAppointmentdata(
                                                                params
                                                            );

                                                            setButtonLoading(
                                                                false
                                                            );
                                                            if (udpateAppt) {
                                                                getAppointmentdata(
                                                                    {
                                                                        confirmationId:
                                                                            Editappointments[0]
                                                                                ?.confirmationId,
                                                                    }
                                                                ).then(
                                                                    async (
                                                                        response
                                                                    ) => {
                                                                        if (
                                                                            response
                                                                                .data
                                                                                .length >
                                                                            0
                                                                        ) {
                                                                            // const periods: any = await getSlotsByLocationId(response.data[0].location?.qbenchCustomerId)
                                                                            // updateAdminSlots(periods);

                                                                            if (
                                                                                response.data &&
                                                                                response
                                                                                    .data
                                                                                    .length
                                                                            ) {
                                                                                response.data[0]?.minors?.map(
                                                                                    (
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.birthDate =
                                                                                            e.birthDate !==
                                                                                                null &&
                                                                                            e.birthDate !==
                                                                                                undefined &&
                                                                                            Object.keys(
                                                                                                e.birthDate
                                                                                            )
                                                                                                .length >
                                                                                                0
                                                                                                ? parse(
                                                                                                      e.birthDate,
                                                                                                      config.dateFormat,
                                                                                                      new Date()
                                                                                                  )
                                                                                                : new Date();
                                                                                    }
                                                                                );
                                                                            }

                                                                            setEditAppointment(
                                                                                response.data
                                                                            );
                                                                            setButtonLoading(
                                                                                false
                                                                            );
                                                                            setShowChangeDatetimeModal(
                                                                                true
                                                                            );
                                                                            setDisabled(
                                                                                false
                                                                            );
                                                                            return true;
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        libraryType="primary"
                                                        disabled={buttonLoading}
                                                    >
                                                        {buttonLoading
                                                            ? 'Processing...'
                                                            : 'Update'}
                                                    </ReturnButton>
                                                </div>
                                            </>
                                        )}
                                    <TestingInfoContainer>
                                        <TestingInfoRow>
                                            <TestingInfo>
                                                <PersonIcon />
                                                {fromApptList ? (
                                                    <strong>
                                                        Appointment for
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        Personal Details
                                                    </strong>
                                                )}
                                                <br />
                                                {
                                                    Editappointments[0]
                                                        ?.firstName
                                                }{' '}
                                                {Editappointments[0]?.lastName}
                                                <br />
                                                {Editappointments[0]?.birthDate}
                                                <br />
                                                {
                                                    Editappointments[0]?.phone
                                                }, {Editappointments[0]?.email}
                                                <br />
                                                {
                                                    Editappointments[0]?.address
                                                        ?.address
                                                }
                                                ,{' '}
                                                {
                                                    Editappointments[0]?.address
                                                        ?.city
                                                }
                                                <br />
                                                {
                                                    Editappointments[0]?.address
                                                        ?.state
                                                }
                                                , ,
                                                {
                                                    Editappointments[0]?.address
                                                        ?.country
                                                }
                                                -
                                                {
                                                    Editappointments[0]?.address
                                                        ?.zipCode
                                                }
                                            </TestingInfo>
                                        </TestingInfoRow>
                                        <TestingInfoBtn
                                            type="button"
                                            hidden={
                                                Editappointments[0].isSolvHealth
                                            }
                                            onClick={() =>
                                                setShowChangeApptAttendeeModal(
                                                    true
                                                )
                                            }
                                            size="sm"
                                            libraryType="default"
                                        >
                                            {fromApptList
                                                ? 'Change Appointment Attendees'
                                                : 'Edit Personal Details'}
                                        </TestingInfoBtn>
                                    </TestingInfoContainer>
                                    <TestingInfoContainer>
                                        <TestingInfo>
                                            <PinIcon />
                                            {!fromApptList && (
                                                <strong>
                                                    Testing Location
                                                </strong>
                                            )}
                                            {fromApptList ? (
                                                <strong>
                                                    {
                                                        Editappointments[0]
                                                            ?.location?.name
                                                    }
                                                </strong>
                                            ) : (
                                                Editappointments[0]?.location
                                                    ?.name
                                            )}
                                            <br />
                                            {
                                                Editappointments[0]?.location
                                                    ?.address1
                                            }{' '}
                                            {
                                                Editappointments[0]?.location
                                                    ?.address2
                                            }
                                        </TestingInfo>
                                        <TestingInfoBtn
                                            hidden={
                                                !Editappointments[0]
                                                    ?.uniqueID &&
                                                !Editappointments[0]
                                                    ?.multipleDates?.length && 
                                                (Editappointments[0]
                                                    .isWalkUpTest ||
                                                Editappointments[0]
                                                    ?.cPartnerID === 'KEN001' ||
                                                Editappointments[0]
                                                    .isReelHealth ||
                                                Editappointments[0].isSolvHealth
                                                    ? true
                                                    : false)
                                            }
                                            type="button"
                                            onClick={() =>
                                                setShowChangeLocationModal(true)
                                            }
                                            size="sm"
                                            libraryType="default"
                                        >
                                            Change Location
                                        </TestingInfoBtn>
                                    </TestingInfoContainer>
                                    {Editappointments[0]?.uniqueID &&
                                        Editappointments[0]
                                            ?.isSchedularLite && (
                                            <TestingInfoContainer>
                                                <TestingInfo>
                                                    <SiGoogleclassroom
                                                        style={{
                                                            color: '#9b9999',
                                                            position:
                                                                'absolute',
                                                            left: 5,
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        <strong>
                                                            Classroom
                                                        </strong>
                                                        <br />
                                                        {Editappointments[0]
                                                            ?.classroom
                                                            ? Editappointments[0]
                                                                  ?.classroom
                                                            : ''}
                                                    </div>
                                                </TestingInfo>
                                                <TestingInfoBtn
                                                    type="button"
                                                    onClick={() =>
                                                        setShowChangeClassroomModal(
                                                            true
                                                        )
                                                    }
                                                    size="sm"
                                                    libraryType="default"
                                                >
                                                    Change Classroom
                                                </TestingInfoBtn>
                                            </TestingInfoContainer>
                                        )}
                                    <TestingInfoContainer>
                                        <TestingInfoRow>
                                            <TestingInfo>
                                                <CalendarIcon />
                                                <strong>
                                                    Appointment Date
                                                </strong>
                                                <br />
                                                {format(
                                                    Editappointments[0]?.date
                                                        ? parse(
                                                              Editappointments[0]
                                                                  ?.date,
                                                              config.dateFormat,
                                                              new Date()
                                                          )
                                                        : new Date(),
                                                    config.weekDateFormat
                                                )}
                                            </TestingInfo>
                                            <TestingInfo>
                                                <ClockIcon />
                                                <strong>Time</strong>
                                                <br />
                                                {/* {Editappointments[0]?.slot &&
                                                  (Editappointments[0]
                                                      ?.isRapidTest
                                                      ? rapidPeriods[
                                                            Editappointments[0]
                                                                ?.slot?.period
                                                        ]?.label
                                                      : periods[
                                                            Editappointments[0]
                                                                ?.slot?.period
                                                        ]?.label)} */}
                                                {Editappointments !== null &&
                                                    Editappointments !==
                                                        undefined &&
                                                    Editappointments[0]
                                                        ?.slot !== null &&
                                                    Editappointments[0]
                                                        ?.slot !== undefined &&
                                                    Editappointments[0]?.slot
                                                        ?.label}
                                            </TestingInfo>
                                        </TestingInfoRow>
                                        <TestingInfoBtn
                                            type="button"
                                            onClick={() =>
                                                {
                                                    Editappointments[0]?.appointmentType === 'Virtual'
                                                        ? setshowChangeCalendlyModel(
                                                              true
                                                          )
                                                        : setShowChangeDatetimeModal(
                                                              true
                                                          );
                                                }
                                            }
                                            size="sm"
                                            hidden={Editappointments[0]?.isSchedularLite ? (Editappointments[0]?.appointmentType !== 'Virtual' && !Editappointments[0]?.weeklyTesting && !Editappointments[0]?.multipleDates?.length ) : false}
                                            libraryType="default"
                                        >
                                            Change Date & Time
                                        </TestingInfoBtn>
                                    </TestingInfoContainer>
                                    {Editappointments[0]?.location?.name &&
                                        Editappointments[0]?.uniqueID &&
                                        Editappointments[0]?.slot !==
                                            undefined &&
                                        (previousDateAndTime?.time !==
                                            Editappointments[0]?.slot?.label ||
                                            previousDateAndTime?.date !==
                                                Editappointments[0]?.date ||
                                            previousDateAndTime?.location
                                                ?.name !==
                                                Editappointments[0]?.location
                                                    ?.name) && (
                                            <div style={{ marginTop: 10 }}>
                                                <input
                                                    onChange={(value) => {
                                                        setIsAllowRescheduleForFutureAppt(
                                                            value.target.checked
                                                        );
                                                        setEditAppointment([
                                                            {
                                                                ...Editappointments?.[0],
                                                                isAllowRescheduleForFutureAppt:
                                                                    value.target
                                                                        .checked,
                                                            },
                                                        ]);
                                                    }}
                                                    type="checkbox"
                                                    style={{
                                                        marginRight: 8,
                                                        cursor: 'pointer',
                                                    }}
                                                    id="ScheduledTesting"
                                                    checked={
                                                        isAllowRescheduleForFutureAppt
                                                    }
                                                />
                                                <label
                                                    htmlFor="ScheduledTesting"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {`Reschedule all upcomming appointments on every `}
                                                    <b>
                                                        {` ${format(
                                                            new Date(
                                                                Editappointments[0]?.date
                                                            ),
                                                            'cccc'
                                                        )} `}
                                                    </b>
                                                    at
                                                    <b>
                                                        {` ${Editappointments[0]?.slot?.label} `}
                                                    </b>
                                                    {previousDateAndTime
                                                        ?.location?.name !==
                                                    Editappointments[0]
                                                        ?.location?.name ? (
                                                        <>
                                                            to
                                                            <b>
                                                                {` ${Editappointments[0]?.location?.name} `}
                                                            </b>
                                                            Location?
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </label>
                                            </div>
                                        )}
                                    {Editappointments[0]?.slot == undefined && (
                                        <ErrorInfoText>
                                            {' '}
                                            Please Select the Slot time{' '}
                                        </ErrorInfoText>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Content>
                    <ChangeLocationModal
                        showModal={showChangeLocationModal}
                        closeModal={() => {
                            setShowChangeLocationModal(false);
                            setShowChangeDatetimeModal(true);
                        }}
                    />
                    <ChangeDateTimeModal
                        showModal={showChangeDatetimeModal}
                        closeModal={() => setShowChangeDatetimeModal(false)}
                    />
                    {showChangeCalendlyModel && <ChangeCalendlyModel
                        showModal={showChangeCalendlyModel}
                        closeModal={() => setshowChangeCalendlyModel(false)}
                    />}
                    <PaymentModal
                        showModal={showPaymentModal}
                        closeModal={() => setShowPaymentModal(false)}
                    />
                    <EditAppointmentModal
                        showModal={showChangeApptAttendeeModal}
                        closeModal={() => setShowChangeApptAttendeeModal(false)}
                    />
                    <ChangeClassRoomField
                        showModal={showChangeClassroomModal}
                        closeModal={() => setShowChangeClassroomModal(false)}
                    />

                    {fromApptList && !Editappointments[0]?.isSolvHealth && (
                        <>
                            <Button
                                libraryType="primary"
                                size="sm"
                                type="submit"
                                onClick={() => handleEditAppointment()}
                                disabled={
                                    loading ||
                                    Editappointments[0]?.slot == undefined ||
                                    disabled ||
                                    showChangeDatetimeModal
                                }
                            >
                                {appointment?.apptType
                                    ? 'Change & Checkin'
                                    : loading
                                    ? 'Processing...'
                                    : 'Change'}
                            </Button>
                            <br />
                        </>
                    )}
                    <br />
                    {returnBtn && (
                        <>
                            <Button
                                disabled={loading}
                                libraryType="primary"
                                accentColor="#f29a4e"
                                onClick={() => {
                                    handleDrawerToggle();
                                    setEditAppointment(null);
                                }}
                            >
                                Return
                            </Button>
                            <br />
                            <br />
                        </>
                    )}
                </Container>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default EditAppoinment;
