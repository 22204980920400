import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, Paper, Typography, TextField, Button,} from '@material-ui/core';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useAdminDispatch, useAdminState } from '../../provider'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { config } from '../../../../config';
import { apiConfig } from '../../../../apiConfig';
import { isValidEmail } from '../../../../validations';

const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        hidefile: {
            display: 'none'
        },
        imgheight: {
            height: '80px',
            marginRight: '20px'
        },
        displayflex: {
            display: 'flex',
        },
        btncolor: {
            borderRadius: 0,
            background: "#1890ff",
            "&:hover": {
                backgroundColor: "#40a9ff !important",
            }
        },
        color: {
            background: "#1890ff",
        },
        colorwhite: {
            color: 'white'
        }
    }),
);
export default function UserRolesForm() {
    const history = useHistory();
    const { getAllUserRoles, getUserID,userRole } = useAdminState();
    const { SetUserID } = useAdminDispatch();
    const [update, setupdate] = useState(false);
    const [error, seterror] = useState([])
    const [rolesArr, setRolesArr] = useState([])
    const [existEmail,setExistEmail]=useState(false)
    const [alertbox, setalertbox] = useState({
        type: '',
        msg: '',
        open: false
    });
    const [submitform, setsubmitform] = useState(false);

    const [InputFormData, setInputFormData] = useState({
        Role: '',
        UserEmail: '',
        // Module:'',
        // isActive: false,
    });
    const [rowdata,setrowdata]=useState([])
    const classes = useStyles();


    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const handleClose = () => {
        error.length > 0?seterror([]):
        setalertbox({
            type: '',
            msg: '',
            open: false
        });
    }
    const newfunforerrorcheck = () => {
        let errorlist: any = [];
        if (InputFormData.Role === null || InputFormData.Role.trim() === '') {
            errorlist.push('Please Enter the Role');
        }

        if (InputFormData.UserEmail === null || InputFormData.UserEmail.trim() === '') {
            errorlist.push('Please Enter the UserEmail');
        }

        if (!isValidEmail.test(InputFormData.UserEmail)){
            errorlist.push('Please enter valid email address');
        }

        seterror(errorlist);
        return errorlist.length > 0 ? false : true;
    }


    React.useEffect(() => {
        getRoleByEmail()
    }, [])
    const getRoleByEmail = async () => {
        try {
            // const resData = await fetch(`${apiConfig.hostUrl}/getRoleByEmail`, {
                const resData = await fetch(`${config.cloudRun.baseUrl}getRoleByEmail`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ email: JSON.parse(window.localStorage.getItem("userDetails")!).email })
            })
            const jsonData = await resData.json()
                .then(res => {
                    if (res.data.length == 0) {
                        getAllRolesAccessFields("Default")
                    } else {
                        getAllRolesAccessFields(res.data[0].data.role)
                    }
                })
        }
        catch (error) {
            console.log(error)
            setalertbox({ open: true, type: 'error', msg: 'failed' });
        }
    }

    const getAllRolesAccessFields = async (roleName: any) => {
        // const resData = await fetch(`${apiConfig.hostUrl}/getAccessFieldsByRole`, {
            const resData = await fetch(`${config.cloudRun.baseUrl}getAccessFieldsByRole`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ roleName: roleName })
        })
        const jsonData = await resData.json()
            .then(res => checkAccess(res.data[0]?.data.accessFields))
    }
    const checkAccess = (allAccessFields: any) => {
    //     //check allAccessFiels includes curent component name
    //     //Name should be as given in the routes
        if (!allAccessFields.includes("Roles Access Form")) {
            alert("You should be admin to access this page")
            window.location.href = "https://admin-dev-b5e92.web.app/dashboard";
        }
    }
    const getdata = async () => {
            const responsedata = await axios({
                method: 'GET',
                url: `${config.cloudRun.baseUrl}getAllUserRolesData`,
                headers: {
                    'content-type': 'application/json'
                }
            });
            return responsedata;
        }
  useEffect(()=>{
    getdata().then((res: any) => {
        let newdata = res.data.data.map((e: any) => {
            return ({ id: e.newid,email:e.data.email, ...e.data  })
        });
        setrowdata(newdata);
    }).catch(err => { console.log(err); })
  },[])

    const handleform = async (e: any) => {
        let Id:any=getUserID;
        e.preventDefault();
        if (newfunforerrorcheck()) {
            setsubmitform(true);
            seterror([]);
            e.target.reset();
            let responsedata: any;
            let row:any;
            
            try {
                if (!update) {
                     row= rowdata.filter((e:any)=>e.email===InputFormData.UserEmail)
                    if(row.length >0){
                        setExistEmail(true)
                    } else{
                        responsedata = await axios({
                            method: 'POST',
                            url: `${config.cloudRun.baseUrl}createUserRolesData`,
                            headers: {
                                'content-type': 'application/json'
                            },
                            data: {
                                'role': InputFormData.Role,
                                'email': InputFormData.UserEmail,
                                // 'isActive': InputFormData.isActive,
                                // 'module': InputFormData.Module
                            }
                        });
                    }
                   
                }
                else {
                    row= rowdata.filter((e:any)=>e.email ===InputFormData.UserEmail)[0]
                        if( row !==undefined && row.email ===InputFormData.UserEmail && row.role===InputFormData.Role && row?.id ===Id){
                        } else if(row !==undefined && row?.id !==Id){
                            setalertbox({ type: 'error', msg:'Email already exist', open: true });
                            setsubmitform(false);
                            setupdate(true);
                            setExistEmail(true);
                        }else
                        {
                        responsedata = await axios({
                            method: 'POST',
                            // url: `${apiConfig.hostUrl}/updateUserRolesData`,
                            url: `${config.cloudRun.baseUrl}updateUserRolesData`,
                            headers: {
                                'content-type': 'application/json'
                            },
                            data: {
                                'role': InputFormData.Role,
                                'email': InputFormData.UserEmail,
                                // 'isActive': InputFormData.isActive,
                                // 'module': InputFormData.Module,
                                'id': getUserID
                            }
                        });
                }
            }

                if (update) {
                    setTimeout(() => {
                        history.push('/dashboard/Users');
                    }, 2000);
                }
                
                if(responsedata){
                if (responsedata.status === 200 ) {
                    setalertbox({ type: 'info', msg:update? 'User Updated Successfully':'User Created Successfully', open: true });
                    setsubmitform(false);
                    setInputFormData({
                        Role: '',
                        UserEmail: '',
                        //  isActive: false,
                        // Module:''
                    })
                }
                else {
                    setalertbox({ type: 'error', msg: 'Create User Failed', open: true });
                    setsubmitform(false);
                    setInputFormData({
                        Role: '',
                        UserEmail: '',
                        // Module:'',
                        // isActive: false
                    })
                }}else if(!update && row.length >0){
                    setalertbox({ type: 'error', msg:'Email already exist', open: true });
                    setsubmitform(false);
                    setInputFormData({
                        Role: '',
                        UserEmail:'' ,
                        //  isActive: false,
                        // Module:''
                    })
                }
            }
            catch (err) {
                setalertbox({ type: 'error', msg:'Failed', open: true });
                setsubmitform(false);
                setInputFormData({
                    Role: '',
                    UserEmail: '',
                    // Module:'',
                    // isActive: false
                });
                console.log(err);
            }
        }
    }

    const getAllRolesFun = async () => {
        // const resData = await fetch(`${apiConfig.hostUrl}/getAllAccessRolesData`)
        const resData = await fetch(`${config.cloudRun.baseUrl}getAllAccessRolesData`)
        const jsonData = await resData.json();
        if(userRole == "Super Admin"){
            setRolesArr(jsonData.data)
        }else {
            setRolesArr(jsonData.data?.filter((role: any) => role.data.roleName !== 'Super Admin'));
        }
    }

    React.useEffect(() => {
        getAllRolesFun()
    }, [])


    React.useEffect(() => {
        try {
            let ID = getUserID;
            let data = getAllUserRoles;
            if (ID !== null && ID.length > 5 && ID.trim() !== '') {
                let newdata = data.filter((e: any) => e.id === ID);
                newdata.length > 0 ? setupdate(true) : setupdate(false)
                newdata = newdata[0];

                setInputFormData({
                    Role: newdata.role,
                    UserEmail: newdata.email,
                    // Module: newdata.module,
                    // isActive: newdata.isActive,
                })

            }
            else {
                setupdate(false)
            }
        } catch (error) {
            console.log(error);
            setupdate(false)
        }
    }, [])

    const Listdata = ({ error }: any) => {
        return (
            <ul>
                {error.map((e: any) => (<li>{e}</li>))}
            </ul>
        )
    }
    const inputfile: any = React.useRef();
    return (<>
        <Backdrop className={classes.backdrop} open={submitform}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={error.length > 0 ? true : alertbox.open} onClose={handleClose} autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <Alert onClose={handleClose}  severity={error.length > 0 ? 'error' : alertbox.type }>
                {error.length === 0 ? alertbox.msg : ''}
                {error.length > 0 ? (<>Required Field<br /><Listdata error={error} /></>) : ''}
            </Alert>
        </Snackbar>
        <Container maxWidth='lg'>
            <Link to='/dashboard/Users' >
                <IconButton color="primary">
                    <ArrowBackIcon fontSize='large' />
                </IconButton>
            </Link>
            <Paper elevation={3}>

                <Grid xl={12}>
                    <Box m={2} p={3} className={classes.color}>
                        <Typography variant="h4" className={classes.colorwhite}>
                            {update ? 'Update' : 'Add'} User
                        </Typography>
                    </Box>
                    <Box m={2} p={4}>
                        <form noValidate autoComplete='off' onSubmit={handleform}>

                            <Box mb={4}>
                                <TextField
                                // style={{textTransform:'lowercase'}}
                                 onChange={(e) => {
                                    let data = { ...InputFormData };
                                    data.UserEmail = e.target.value.toLowerCase();
                                    setInputFormData(data)
                                }} value={InputFormData.UserEmail.toLowerCase()} id="UserEmail" fullWidth label="User Email" variant="outlined" name='UserEmail'/>
                            </Box>
                            <Box mb={4}>
                                <InputLabel id="Role">Role</InputLabel>
                                <Select
                                    labelId="Role"
                                    id="Role" onChange={(e) => {
                                        let data: any = { ...InputFormData };
                                        data.Role = e.target.value;
                                        setInputFormData(data)
                                    }} fullWidth
                                    value={InputFormData.Role}
                                    label="Role"
                                >

                                    {rolesArr.length ? rolesArr.filter((role: any) => role.data.roleName !== "Default").sort((a:any,b:any)=>a.data.roleName.localeCompare(b.data.roleName)).map((role: any) => {
                                        
                                        return <MenuItem value={role.data.roleName}>{role.data.roleName}</MenuItem>

                                    }) : <MenuItem value=""></MenuItem>}

                                </Select>
                            </Box>

                            {/* <Box mb={4}>
                                <TextField onChange={(e) => {
                                    let data = { ...InputFormData };
                                    data.Module = e.target.value;
                                    setInputFormData(data)
                                }} value={InputFormData.Module} id="Module" fullWidth label="Module" variant="outlined" name='Module' />
                            </Box> */}


                            {/* <Box mb={6}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Switch
                                            onChange={() => {
                                                let data = { ...InputFormData };
                                                data.isActive = !data.isActive;
                                                setInputFormData(data)
                                            }}
                                            color="primary"
                                            checked={InputFormData.isActive}
                                            name="isActive"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                    label="Is Account Active"
                                    labelPlacement="end"
                                />
                            </Box> */}
                            <Button variant="contained" disabled={submitform} color='secondary' className={classes.btncolor} type='submit'>{update ? !submitform ? 'Update' : 'Updating' : !submitform ? 'Submit' : 'Please Wait'}</Button>&nbsp;
                            <Button variant="contained" color='secondary' type='reset' className={classes.btncolor} disabled={submitform} onClick={() => {
                                setalertbox({ type: '', msg: '', open: false })
                                seterror([]);
                                setInputFormData({
                                    Role: '',

                                    UserEmail: '',
                                    // Module:'',

                                    // isActive: false,
                                })
                            }}>Reset</Button>
                        </form>
                    </Box>
                </Grid>
            </Paper>
        </Container>
    </>);
}
