  /* eslint-disable no-unused-expressions */
  import React, { useState, useMemo } from 'react';
  import { CloseCircleFilled, CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
  import Title from 'antd/lib/typography/Title';
  import styled from 'styled-components';
  import { useSharedState, useSharedDispatch } from '../../../shared/provider';
  import { useAdminState } from '../../provider';
  import { updateSchedularLite } from './API';
  import SwitchComponent from './SwitchComponent';
  import { useHistory } from 'react-router-dom';
  import { Divider, notification } from 'antd';
  import { firebase } from '../../../../firebase';
  import { TESTINGOPTIONS, TIME, WEEK,pVerifyCount, appointmentType } from './schedularUtils';
  import { format } from 'date-fns';
  import { config } from '../../../../config';
  const Container = styled.div`
      display: flex;
      justify-content: center;
      width: 90vw;
      @media (min-width: 1000px) {
          width: 50vw;
      }
  `;
  const ShadowBox = styled.div`
      background-color: rgb(233, 229, 229);
      border-radius: 10px;
      box-shadow: rgb(136 136 136) 0px 10px 12px -6px;
      padding: 25px;
      margin-bottom: 30px;
      
  `;
  const Returnbtn = styled.div`
      width: 100%;
      border: 0;
      align-items: center;
      text-align: center;
      border: 1px solid #1890ff;
      line-height: 1.6;
      padding: 5px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      display: inline-block;
      background-color: #fff;
      color: #1890ff;
      border-radius: 4px;
      margin: 10px 0px 30px 0px;
  `;
  const openNotificationWithIcon = () => {
      notification.success({
          message: 'Success',
          description: 'Settings Applied',
      });
  };
  let timeout:any;
  const ConfigureSchedular = () => {
      const history = useHistory();
      const { cPartner }:any =useAdminState()
      const { allLocations }:any = useSharedState();
      const { setLocation } = useSharedDispatch();
      const [changed, setChanged] = useState(false);
      const [background, setBackground] = useState(false);
      const [loading, setLoading] = useState(false);
      const [imageNumber, setImageNumber] = useState(-1);
      const [openDropDown, setOpenDropDown] = useState({moreOptions:false})
      const { locationID } = Object.fromEntries(
          new URLSearchParams(window.location.search).entries()
      );
      const location = useMemo(
          () =>
              allLocations?.filter(
                  (e: any) => locationID && e.id === locationID
              )?.[0] ?? null,
          [changed, allLocations]
      );
      const updateLocation = (
          id: any,
          qbenchCustomerId: any,
          cPartnerID: any,
          data: any
      ) => {
          const user:any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
          const dateAndTime = format(new Date(), config.dateTimeFormat)
          const updatedBy = {user:user?.email ?? '' , time: dateAndTime ?? ''};
          data = {schedularLite:{...data.schedularLite, updatedBy}}
          let newLocation = allLocations.map((e: any) => {
              if (
                  e.id === id &&
                  qbenchCustomerId === e.qbenchCustomerId &&
                  e.cPartnerID === cPartnerID
              ) {
                  return { ...e, ...data };
              }
              return e;
          });
          setLocation(newLocation);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
              openNotificationWithIcon();
              updateSchedularLite({ qbenchCustomerId, data });
          }, 1000);
          setChanged(!changed);
      };
      React.useEffect(()=>{
          window.document.getElementsByClassName('makeStyles-mainPanel1-3')?.[0]?.scroll(0,0)
          window.document.getElementsByClassName('jss3')?.[0]?.scroll(0,0)
      },[])
      const handleChangeFront = async (e: any) => {
          try {
              setLoading(true);
              const file = e.target.files[0];
              const storageref = firebase.storage().ref();
              const fileref = storageref.child(file.name);
              await fileref.put(file).then(() => {
                  console.log('filename', file.type);
              });
              if (
                  file.type === 'image/jpeg' ||
                  file.type === 'image/png' ||
                  file.type === 'image/tiff-fx' ||
                  file.type === 'image/tiff'
              ) {
                  const url: string = await fileref.getDownloadURL();
                  let logo = location?.schedularLite?.logo ?? [];
                  logo[0] = imageNumber === 0 ? url : logo?.[0] ?? '';
                  logo[1] = imageNumber === 1 ? url : logo?.[1] ?? '';
                  updateLocation(
                      location.id,
                      location.qbenchCustomerId,
                      location.cPartnerID,
                      { schedularLite: { ...location.schedularLite, logo } }
                  );
                  setLoading(false);
              } else {
                  setLoading(false);
              }
          } catch (error) {
              setLoading(false);
          }
      };
      if (!locationID || !location || !allLocations) {
          return (
              <div
                  style={{
                      display: 'flex',
                      width: '100%',
                      height: '80vh',
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}
              >
                  <LoadingOutlined style={{ fontSize: '50px' }} />
              </div>
          );
      }
  
      return (
          <div
              style={{ width: '100%', marginTop: '10px' }}
              id="schedulerLiteConfiguration"
          >
              <Title
                  level={3}
                  style={{
                      margin: '24px 16px',
                      color: '#black',
                      textAlign: 'center',
                      fontWeight: 600,
                      fontSize: '40px',
                  }}
              >
                  {location.name}
              </Title>
              <div
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                  }}
              >
                  <Container>
                      <div style={{ width: '100%' }}>
                          <input
                              type="file"
                              id="upload_logo"
                              accept="image/*"
                              name="frontCard"
                              onChange={handleChangeFront}
                              style={{ display: 'none' }}
                          />
                          <ShadowBox>
                              <SwitchComponent title="Logo/Branding" />
                              <div
                                  style={{ width: '100%', position: 'relative' }}
                              >
                                  <p
                                      style={{
                                          position: 'absolute',
                                          top: -25,
                                          right: -2,
                                          cursor: 'pointer',
                                          padding: 2,
                                      }}
                                      onClick={() => setBackground(!background)}
                                  >
                                      Toggle Background
                                  </p>
                              </div>
                              <div
                                  style={{
                                      display: 'flex',
                                      width: '100%',
                                      justifyContent: 'space-around',
                                      gap: 30,
                                      marginTop: 50,
                                      overflow: 'auto',
                                  }}
                              >
                                  <div
                                      style={{
                                          display: 'flex',
                                          width: '100%',
                                          justifyContent: 'space-around',
                                          gap: 10,
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                      }}
                                  >
                                      {loading && imageNumber === 0 ? (
                                          <LoadingOutlined
                                              style={{ fontSize: '60px' }}
                                          />
                                      ) : (
                                          <>
                                              {Boolean(
                                                  location?.schedularLite
                                                      ?.logo?.[0]
                                              ) && (
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                          alignItems: 'center',
                                                          width: '100%',
                                                      }}
                                                  >
                                                      <div
                                                          style={{
                                                              width: '65%',
                                                              position:
                                                                  'relative',
                                                          }}
                                                      >
                                                          <img
                                                              style={{
                                                                  backgroundColor:
                                                                      background
                                                                          ? 'white'
                                                                          : 'black',
                                                                  backgroundImage:
                                                                      '',
                                                                  borderRadius: 15,
                                                                  width: '100%',
                                                                  minWidth: 150,
                                                              }}
                                                              src={
                                                                  location
                                                                      ?.schedularLite
                                                                      ?.logo?.[0] ??
                                                                  ''
                                                              }
                                                              alt="Not Found"
                                                          />
                                                          {/* <div style={{position:'absolute',top:-13,right:-10,color:'red',fontSize:20,cursor:'pointer'}}><CloseCircleFilled onClick={()=>{
                                                              let logo = location?.schedularLite?.logo ?? [];
                                                              logo[0] = '';
                                                              updateLocation(
                                                                  location.id,
                                                                  location.qbenchCustomerId,
                                                                  location.cPartnerID,
                                                                  { schedularLite: { ...location.schedularLite, logo } }
                                                              );
                                                          }}/>
                                                          </div> */}
                                                      </div>
                                                  </div>
                                              )}
                                              <label
                                                  htmlFor="upload_logo"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                      setImageNumber(0)
                                                  }
                                              >
                                                  <CloudUploadOutlined
                                                      style={{ fontSize: 60 }}
                                                  />
                                              </label>
                                          </>
                                      )}
                                  </div>
                                  <Divider type="vertical" />
                                  <div
                                      style={{
                                          display: 'flex',
                                          width: '100%',
                                          justifyContent: 'space-around',
                                          gap: 15,
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                      }}
                                  >
                                      {loading && imageNumber === 1 ? (
                                          <LoadingOutlined
                                              style={{ fontSize: '60px' }}
                                          />
                                      ) : (
                                          <>
                                              {Boolean(
                                                  location?.schedularLite
                                                      ?.logo?.[1]
                                              ) && (
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                          alignItems: 'center',
                                                          width: '100%',
                                                          padding: 10,
                                                      }}
                                                  >
                                                      <div
                                                          style={{
                                                              width: '65%',
                                                              position:
                                                                  'relative',
                                                          }}
                                                      >
                                                          <img
                                                              style={{
                                                                  backgroundColor:
                                                                      background
                                                                          ? 'white'
                                                                          : 'black',
                                                                  backgroundImage:
                                                                      '',
                                                                  borderRadius: 15,
                                                                  width: '100%',
                                                                  minWidth: 150,
                                                              }}
                                                              src={
                                                                  location
                                                                      ?.schedularLite
                                                                      ?.logo?.[1] ??
                                                                  ''
                                                              }
                                                              alt="Not Found"
                                                          />
                                                          <div
                                                              style={{
                                                                  position:
                                                                      'absolute',
                                                                  top: -13,
                                                                  right: -10,
                                                                  color: 'red',
                                                                  fontSize: 20,
                                                                  cursor: 'pointer',
                                                              }}
                                                          >
                                                              <CloseCircleFilled
                                                                  onClick={() => {
                                                                      let logo =
                                                                          location
                                                                              ?.schedularLite
                                                                              ?.logo ??
                                                                          [];
                                                                      logo[1] =
                                                                          '';
                                                                      updateLocation(
                                                                          location.id,
                                                                          location.qbenchCustomerId,
                                                                          location.cPartnerID,
                                                                          {
                                                                              schedularLite:
                                                                                  {
                                                                                      ...location.schedularLite,
                                                                                      logo,
                                                                                  },
                                                                          }
                                                                      );
                                                                  }}
                                                              />
                                                          </div>
                                                      </div>
                                                  </div>
                                              )}
                                              <label
                                                  htmlFor="upload_logo"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                      setImageNumber(1)
                                                  }
                                              >
                                                  <CloudUploadOutlined
                                                      style={{ fontSize: 60 }}
                                                  />
                                              </label>
                                          </>
                                      )}
                                  </div>
                              </div>
                          </ShadowBox>
                          <ShadowBox>
                              <SwitchComponent title="Verification" />
                              <SwitchComponent
                                  name="Phone"
                                  onChange={(value) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  verification: {
                                                      ...(location?.schedularLite
                                                          ?.verification ?? {}),
                                                      phone: value,
                                                  },
                                              },
                                          }
                                      )
                                  }
                                  value={
                                      location?.schedularLite?.verification?.phone
                                  }
                              />
                              <div style={{ margin: '10px 0' }} />
                              <SwitchComponent
                                  name="Email"
                                  onChange={(value) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  verification: {
                                                      ...(location?.schedularLite
                                                          ?.verification ?? {}),
                                                      email: value,
                                                  },
                                              },
                                          }
                                      )
                                  }
                                  value={
                                      location?.schedularLite?.verification?.email
                                  }
                              />
                          </ShadowBox>
                          {/* <ShadowBox>
                              <SwitchComponent title="More Fields" />
                              
                          </ShadowBox> */}
                          <ShadowBox>
                              <SwitchComponent title="Address" />
                              <SwitchComponent
                                  name="Address"
                                  onChange={(value) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  address: {
                                                      ...(location?.schedularLite
                                                          ?.address ?? {}),
                                                      address: value,
                                                  },
                                              },
                                          }
                                      )
                                  }
                                  value={
                                      location?.schedularLite?.address?.address
                                  }
                              />
                              <SwitchComponent title="OR" />
                              <SwitchComponent
                                  name="Govt Proof"
                                  disabled={false}
                                  onChange={(value) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  address: {
                                                      ...(location?.schedularLite
                                                          ?.address ?? {}),
                                                      drivingLicence: value,
                                                  },
                                              },
                                          }
                                      )
                                  }
                                  value={
                                      location?.schedularLite?.address
                                          ?.drivingLicence
                                  }
                              />
                          </ShadowBox>
                          <ShadowBox>
                              <SwitchComponent title="Test Types" />
                              <SwitchComponent
                                  name="testTypes"
                                  location={location ?? []}
                                  onChange={(value: any) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  testTypes: [
                                                      ...location.schedularLite.testTypes.map(
                                                          (e: any) => {
                                                              if (
                                                                  e.id ===
                                                                  value?.id
                                                              ) {
                                                                  return {
                                                                      ...e,
                                                                      ...value,
                                                                  };
                                                              } else {
                                                                  return e;
                                                              }
                                                          }
                                                      ),
                                                  ],
                                              },
                                          }
                                      )
                                  }
                              />
                          </ShadowBox>
                          <ShadowBox>
                              <SwitchComponent title="Hippa Consent" />
                              <SwitchComponent
                                  size={250}
                                  name="cPartner"
                                  value={
                                      cPartner.filter(
                                          (e: any) =>
                                              e.value ===
                                              location?.schedularLite?.hippaInfo
                                      )[0]?.label ?? 'DISABLE'
                                  }
                                  list={[
                                      { value: 'DISABLE', label: 'DISABLE' },
                                      ...cPartner,
                                  ]}
                                  location={location}
                                  onChange={({ value }: any) =>
                                      updateLocation(
                                          location.id,
                                          location.qbenchCustomerId,
                                          location.cPartnerID,
                                          {
                                              schedularLite: {
                                                  ...location.schedularLite,
                                                  hippaInfo: value,
                                              },
                                          }
                                      )
                                  }
                              />
                          </ShadowBox>
                          <ShadowBox>
                              <SwitchComponent
                                  title="More Options"
                                  toggleDropDown
                                  openDropDown={openDropDown}
                                  setOpenDropDown={setOpenDropDown}
                              />
                              {openDropDown.moreOptions && (
                                  <>
                                      <SwitchComponent
                                          name="Sex Field"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          moreDetails: {
                                                              ...(location
                                                                  ?.schedularLite
                                                                  ?.moreDetails ??
                                                                  {}),
                                                              sex: value,
                                                          },
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite?.moreDetails
                                                  ?.sex
                                          }
                                      />
                                      <div style={{ margin: '10px 0' }} />
                                      <SwitchComponent
                                          name="Race Field"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          moreDetails: {
                                                              ...(location
                                                                  ?.schedularLite
                                                                  ?.moreDetails ??
                                                                  {}),
                                                              race: value,
                                                          },
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite?.moreDetails
                                                  ?.race
                                          }
                                      />
                                      <div style={{ margin: '10px 0' }} />
                                      <SwitchComponent
                                          name="Ethnicity Field"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          moreDetails: {
                                                              ...(location
                                                                  ?.schedularLite
                                                                  ?.moreDetails ??
                                                                  {}),
                                                              ethnicity: value,
                                                          },
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite?.moreDetails
                                                  ?.ethnicity
                                          }
                                      />
                                      <div style={{ margin: '10px 0' }} />
                                      {location?.schedularLite?.payment
                                          ?.insurance == true && (
                                          <SwitchComponent
                                              name="Payerlist Other Option"
                                              onChange={(value) =>
                                                  updateLocation(
                                                      location.id,
                                                      location.qbenchCustomerId,
                                                      location.cPartnerID,
                                                      {
                                                          schedularLite: {
                                                              ...location.schedularLite,
                                                              isOther: value,
                                                              isInsuranceProof:
                                                                  value == false
                                                                      ? false
                                                                      : location
                                                                            .schedularLite
                                                                            .isInsuranceProof,
                                                          },
                                                      }
                                                  )
                                              }
                                              value={
                                                  location?.schedularLite?.isOther
                                              }
                                          />
                                      )}
                                      <div style={{ margin: '10px 0' }} />
                                      {location?.schedularLite?.isOther ==
                                          true && (
                                          <SwitchComponent
                                              name="Upload Insurance Card"
                                              onChange={(value) =>
                                                  updateLocation(
                                                      location.id,
                                                      location.qbenchCustomerId,
                                                      location.cPartnerID,
                                                      {
                                                          schedularLite: {
                                                              ...location.schedularLite,
                                                              isInsuranceProof:
                                                                  value,
                                                          },
                                                      }
                                                  )
                                              }
                                              value={
                                                  location?.schedularLite
                                                      ?.isInsuranceProof
                                              }
                                          />
                                      )}
                                      <div style={{ margin: '10px 0' }} />
                                      {/* <SwitchComponent
                                          name="Classroom Field"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          moreDetails: {
                                                              ...(location?.schedularLite
                                                                  ?.moreDetails ?? {}),
                                                              classroom: value,
                                                          },
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite?.moreDetails?.classroom
                                          }
                                      />
                                      <hr style={{marginBottom:'30px'}}/>  */}
                                      <SwitchComponent
                                          name="Default Email Fields"
                                          value={
                                              location?.schedularLite
                                                  ?.noOfEmailFields ?? 1
                                          }
                                          list={Array.from(
                                              { length: 10 },
                                              (_, i) => ({
                                                  value: i + 1 + '',
                                                  label: i + 1 + '',
                                              })
                                          )}
                                          location={location}
                                          onChange={({ value }: any) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          noOfEmailFields:
                                                              parseInt(value),
                                                      },
                                                  }
                                              )
                                          }
                                      />
                                      <div style={{ margin: '20px 0' }} />
                                      <SwitchComponent
                                          name="Pverify Count"
                                          value={
                                              location?.schedularLite
                                                  ?.pVerifyCount
                                          }
                                          list={[
                                              {
                                                  value: 'DISABLE',
                                                  label: 'DISABLE',
                                              },
                                              ...pVerifyCount,
                                          ]}
                                          onChange={({ value }: any) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          pVerifyCount: value,
                                                      },
                                                  }
                                              )
                                          }
                                      />
                                      <div style={{ margin: '20px 0' }} />
                                      <SwitchComponent
                                          name="Allow User Dynamic Email Fields"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          allowUserToEnterDynamicEmails:
                                                              value,
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite
                                                  ?.allowUserToEnterDynamicEmails
                                          }
                                      />
                                      <div style={{ margin: '20px 0' }} />
                                      <SwitchComponent
                                          name="Sub Locaton Field"
                                          onChange={(value) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          enableSubLocation:
                                                              value,
                                                      },
                                                  }
                                              )
                                          }
                                          value={
                                              location?.schedularLite
                                                  ?.enableSubLocation
                                          }
                                      />
                                      <div style={{ margin: '20px 0' }} />
                                      <SwitchComponent
                                          name="Appointment Type"
                                          size={300}
                                          value={
                                              location?.schedularLite
                                                  ?.appointmentType
                                          }
                                          list={appointmentType}
                                          onChange={({ value }: any) =>
                                              updateLocation(
                                                  location.id,
                                                  location.qbenchCustomerId,
                                                  location.cPartnerID,
                                                  {
                                                      schedularLite: {
                                                          ...location.schedularLite,
                                                          appointmentType: value,
                                                          weeklyTestingAppointment:
                                                              {
                                                                  isActive:
                                                                      (location
                                                                          ?.schedularLite
                                                                          ?.testingFor ===
                                                                          'Weekly Testing' &&
                                                                          value?.includes(
                                                                              'Onsite'
                                                                          )) ??
                                                                      false,
                                                                  week:
                                                                      location
                                                                          ?.schedularLite
                                                                          ?.weeklyTestingAppointment
                                                                          ?.week ??
                                                                      'Sunday',
                                                                  time:
                                                                      location
                                                                          ?.schedularLite
                                                                          ?.weeklyTestingAppointment
                                                                          ?.time ??
                                                                      '06:00 AM',
                                                                  classroom:
                                                                      location
                                                                          ?.schedularLite
                                                                          ?.weeklyTestingAppointment
                                                                          ?.classroom ??
                                                                      '',
                                                              },
                                                      },
                                                  }
                                              )
                                          }
                                      />
  
                                      {location?.schedularLite?.appointmentType?.includes(
                                          'Virtual'
                                      ) && (
                                          <>
                                              <div style={{ margin: '20px 0' }} />
                                              <SwitchComponent
                                                  name="Calandly appointment link"
                                                  input
                                                  size={300}
                                                  onChange={(value: any) =>
                                                      updateLocation(
                                                          location.id,
                                                          location.qbenchCustomerId,
                                                          location.cPartnerID,
                                                          {
                                                              schedularLite: {
                                                                  ...location.schedularLite,
                                                                  calendlyURL:
                                                                      value
                                                                          ?.target
                                                                          ?.value
                                                                          ? value
                                                                                ?.target
                                                                                ?.value
                                                                          : '',
                                                              },
                                                          }
                                                      )
                                                  }
                                                  value={
                                                      location?.schedularLite
                                                          ?.calendlyURL ?? ''
                                                  }
                                              />
                                          </>
                                      )}
                                      {location?.schedularLite.appointmentType?.includes(
                                          'Onsite'
                                      ) && (
                                          <>
                                              <div style={{ margin: '20px 0' }} />
                                              <SwitchComponent
                                                  size={300}
                                                  name="Testing Cadence"
                                                  list={TESTINGOPTIONS}
                                                  onChange={(value: any) => {
                                                      updateLocation(
                                                          location.id,
                                                          location.qbenchCustomerId,
                                                          location.cPartnerID,
                                                          {
                                                              schedularLite: {
                                                                  ...location.schedularLite,
                                                                  weeklyTestingAppointment:
                                                                      {
                                                                          isActive:
                                                                              value.value ===
                                                                              'Weekly Testing',
                                                                          week:
                                                                              location
                                                                                  ?.schedularLite
                                                                                  ?.weeklyTestingAppointment
                                                                                  ?.week ??
                                                                              'Sunday',
                                                                          time:
                                                                              location
                                                                                  ?.schedularLite
                                                                                  ?.weeklyTestingAppointment
                                                                                  ?.time ??
                                                                              '06:00 AM',
                                                                          classroom:
                                                                              location
                                                                                  ?.schedularLite
                                                                                  ?.weeklyTestingAppointment
                                                                                  ?.classroom ??
                                                                              '',
                                                                      },
                                                                  testingFor:
                                                                      value?.value,
                                                              },
                                                          }
                                                      );
                                                  }}
                                                  value={
                                                      location?.schedularLite
                                                          ?.testingFor ??
                                                      'Walk up'
                                                  }
                                              />
  
                                              {location?.schedularLite
                                                  ?.weeklyTestingAppointment
                                                  ?.isActive !== true &&
                                                  location?.schedularLite
                                                      ?.testingFor !==
                                                      'Walk up' && (
                                                      <>
                                                          <div
                                                              style={{
                                                                  margin: '20px 0',
                                                              }}
                                                          />
                                                          <SwitchComponent
                                                              name="Add Blockout Days"
                                                              date
                                                              onChange={(
                                                                  value: any
                                                              ) => {
                                                                  const datesList: any =
                                                                      value?.map(
                                                                          (
                                                                              e: any
                                                                          ) =>
                                                                              e
                                                                                  ?.month
                                                                                  ?.number +
                                                                              '/' +
                                                                              e.day +
                                                                              '/' +
                                                                              e.year
                                                                      );
                                                                  console.log(
                                                                      'value',
                                                                      datesList,
                                                                      location.id,
                                                                      location.qbenchCustomerId,
                                                                      location.cPartnerID
                                                                  );
                                                                  updateLocation(
                                                                      location.id,
                                                                      location.qbenchCustomerId,
                                                                      location.cPartnerID,
                                                                      {
                                                                          schedularLite:
                                                                              {
                                                                                  ...location.schedularLite,
                                                                                  blockDates:
                                                                                      datesList,
                                                                              },
                                                                      }
                                                                  );
                                                              }}
                                                              value={
                                                                  location
                                                                      ?.schedularLite
                                                                      ?.blockDates ??
                                                                  ''
                                                              }
                                                          />
                                                      </>
                                                  )}
                                              {location?.schedularLite
                                                  ?.weeklyTestingAppointment
                                                  ?.isActive && (
                                                  <>
                                                      <div
                                                          style={{
                                                              margin: '20px 0',
                                                          }}
                                                      />
                                                      <SwitchComponent
                                                          list={WEEK}
                                                          name="Week Day for Scheduled Testing"
                                                          onChange={(
                                                              value: any
                                                          ) =>
                                                              updateLocation(
                                                                  location.id,
                                                                  location.qbenchCustomerId,
                                                                  location.cPartnerID,
                                                                  {
                                                                      schedularLite:
                                                                          {
                                                                              ...location.schedularLite,
                                                                              weeklyTestingAppointment:
                                                                                  {
                                                                                      isActive:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.isActive ??
                                                                                          false,
                                                                                      week: value?.value,
                                                                                      time:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.time ??
                                                                                          '06:00 AM',
                                                                                      classroom:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.classroom ??
                                                                                          '',
                                                                                  },
                                                                          },
                                                                  }
                                                              )
                                                          }
                                                          value={
                                                              location
                                                                  ?.schedularLite
                                                                  ?.weeklyTestingAppointment
                                                                  ?.week
                                                          }
                                                      />
                                                      <div
                                                          style={{
                                                              margin: '20px 0',
                                                          }}
                                                      />
                                                      <SwitchComponent
                                                          name="Time for Scheduled Testing"
                                                          list={TIME}
                                                          onChange={(
                                                              value: any
                                                          ) =>
                                                              updateLocation(
                                                                  location.id,
                                                                  location.qbenchCustomerId,
                                                                  location.cPartnerID,
                                                                  {
                                                                      schedularLite:
                                                                          {
                                                                              ...location.schedularLite,
                                                                              weeklyTestingAppointment:
                                                                                  {
                                                                                      isActive:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.isActive ??
                                                                                          false,
                                                                                      time: value.value,
                                                                                      week:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.week ??
                                                                                          'Sunday',
                                                                                      classroom:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.classroom ??
                                                                                          '',
                                                                                  },
                                                                          },
                                                                  }
                                                              )
                                                          }
                                                          value={
                                                              location
                                                                  ?.schedularLite
                                                                  ?.weeklyTestingAppointment
                                                                  ?.time
                                                          }
                                                      />
                                                      <div
                                                          style={{
                                                              margin: '20px 0',
                                                          }}
                                                      />
                                                      <SwitchComponent
                                                          name="Classroom Number"
                                                          input
                                                          onChange={(
                                                              value: any
                                                          ) =>
                                                              updateLocation(
                                                                  location.id,
                                                                  location.qbenchCustomerId,
                                                                  location.cPartnerID,
                                                                  {
                                                                      schedularLite:
                                                                          {
                                                                              ...location.schedularLite,
                                                                              weeklyTestingAppointment:
                                                                                  {
                                                                                      isActive:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.isActive ??
                                                                                          false,
                                                                                      time:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.time ??
                                                                                          '06:00 AM',
                                                                                      week:
                                                                                          location
                                                                                              ?.schedularLite
                                                                                              ?.weeklyTestingAppointment
                                                                                              ?.week ??
                                                                                          'Sunday',
                                                                                      classroom:
                                                                                          value
                                                                                              ?.target
                                                                                              ?.value
                                                                                              ? value
                                                                                                    ?.target
                                                                                                    ?.value
                                                                                              : '',
                                                                                  },
                                                                          },
                                                                  }
                                                              )
                                                          }
                                                          value={
                                                              location
                                                                  ?.schedularLite
                                                                  ?.weeklyTestingAppointment
                                                                  ?.classroom
                                                                  ? location
                                                                        ?.schedularLite
                                                                        ?.weeklyTestingAppointment
                                                                        ?.classroom
                                                                  : ''
                                                          }
                                                      />
                                                  </>
                                              )}
                                          </>
                                      )}
                                  </>
                              )}
                          </ShadowBox>
                          <Returnbtn
                              className="btn-common"
                              onClick={() =>
                                  history.push('/dashboard/schedularlite')
                              }
                          >
                              Back
                          </Returnbtn>
                      </div>
                  </Container>
              </div>
          </div>
      );
  };
  
  export default ConfigureSchedular;
  