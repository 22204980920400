import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import {  AdminProvider } from '../../provider';
import Inventorystep from './Inventorystep'
function InventoryWrapper(){


    useEffect(() => {
        mixpanel.track('Page View');
    }, []);

    return(
        <AdminProvider>
            <Inventorystep />
        </AdminProvider>
                            )
                        }

export default InventoryWrapper;