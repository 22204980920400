import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
    EditOutlined,
    MoreOutlined
} from '@ant-design/icons';
import Cancel from '../../components/AppointmentList/components/Cancel'

import NpModal from './NpModal'

interface IProps {
    record?: any;
    getNpDest?: () => void;
    isNpDest?: boolean;
    handleSearch:()=>void;
}

function MoreActions({
    record,
    getNpDest,
    isNpDest,
    handleSearch
}: IProps) {
    const [visible, setVisible] = useState(false);
    const [showEditNpModal, setShowEditNpModal] = React.useState(false);
    
    const handleVisibleChange = (visibleStatus: boolean) => {
        setVisible(visibleStatus);
    };
    const handleEditNp = () => {
        setShowEditNpModal(!showEditNpModal);
    };
    useEffect(() => {
        setVisible(false);
    }, [record]);
    const menu = (record: any) => (
        <>
            <Menu>
                <Menu.Item key="0">
                    <Button
                        type="primary"
                        onClick={handleEditNp}
                        block
                        icon={<EditOutlined />}
                    >
                        Edit
                    </Button>
                </Menu.Item>
                <Menu.Item key="1">
                    <Cancel
                        isNpDest={isNpDest}
                        deletes={record}
                        getNpDest={getNpDest}
                        handleSearch={handleSearch}
                    />
                </Menu.Item>
                {showEditNpModal && (
                    <NpModal
                        showEditNpModal={showEditNpModal}
                        setShowEditNpModal={setShowEditNpModal}
                        npData={record}
                        getNpDest={getNpDest}
                    />
                )}
            </Menu>
        </>
    );

    return (
        <Dropdown
            overlay={menu(record)}
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button type="link" icon={<MoreOutlined />} />
        </Dropdown>
    );
}

export default MoreActions;
