import * as React from 'react';
import {
    Button,
    Card,
    Form,
    Layout,
    Select,
    Space,
    Table,
    Typography,
    Modal
} from 'antd';
import { getEmployeeTesting} from '../../api' 
import { styles } from '../../components/AppointmentList/styles'; 
import { EmployeeTestingColumn } from '../../components/AppointmentList/constants'; 
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries'; 
import { Snackbar } from '@material-ui/core';
import {InputForm} from './InputForm'
import MoreActions from './MoreActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;


const HeaderBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
 

  @media (max-width: ${Breakpoints.sm}px) {
    flex-direction: column;
    align-items: start;
  }
`;



const EmpTesting : React.FC = (handleSearch:any) => {
	const [getdata, setgetdata] = React.useState(false);
	const [rowdata, setrowdata] = React.useState<any>([]);
	const [loading, setLoading] = React.useState(false);
	const [loadings, setLoadings] = React.useState(false);
    const [inputBox, setInputBox] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState<boolean>(false);

	// const classes = stylesForListing();
    
	React.useEffect(() => {
        getEmpTesting();
	}, []);

    const getEmpTesting = async() =>{
        await getEmployeeTesting().then((res:any) => {
            setLoading(true)
            if(res?.data?.status === 200){
				setrowdata(res?.data?.data);
				setgetdata(true);
			}
			else{
				console.log(res);
			}
            
            setLoading(false)
		}).catch(err => { console.log(err); })
    }


	
	const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]); 

	const renderActionsMenu = (action: any, record: any) => (
        <MoreActions
            record={record}
            isEmpTest={true}
            getEmpTesting={getEmpTesting}
            handleSearch={handleSearch}
        />
    );

	async function TableRender() {
		setLoading(true);
		const tableColumnData = EmployeeTestingColumn(renderActionsMenu)
		setRenderTableColumn(tableColumnData);
		setRenderTableColumn(
				EmployeeTestingColumn(renderActionsMenu)
			  );
			  setLoading(false);
			}
	  

	React.useEffect(() => {
		
		TableRender();
		
	  }, []);
	

      const handleCancel = () => {
        setInputBox(false);
    };
    const handleToClose = () => {
        setOpen(false);
    }
    
	return (
        <Layout
        style={{
            marginTop: '100px',
            backgroundColor: '#FFF',
            border: '1px solid #f3eeee',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        }}
    >
            <div
                style={{
                    borderRadius: '3px',
                    background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                    boxShadow:
                        '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                    margin: '0px 25px',
                    marginTop: '-25px',
                }}
            >
                <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
                    Employee Testing
                </Title>
                </div>
                <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={open}
        autoHideDuration={3000}
        message="User Added"
        onClose={handleToClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleToClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
                
            
            <Content style={styles.content}>
                <Space
                    direction="vertical"
                    style={styles.spaceWidth}
                    size="middle"
                >
                    <Card bodyStyle={styles.headCards}>
                    <p style={{ width: '83%', paddingLeft: '17.5%', fontSize : '17px'}}>Please enter information for contractors who should receive free testing services.
                    Employees who already have a WorksiteLabs email account will already receive
                    free covid tests. This page is dedicated for employees who do not have a WorksiteLabs
                    email address.</p>
                    
                        <HeaderBox style={{ width: '80%',margin:'auto' }}>
                            

                            <Button
                                type="primary"
                                loading={loadings}
                                style={{'backgroundColor' : '#655FFF', 'border' : 'none', marginRight: '-67%'}}
                                onClick={()=>setInputBox(true)}
                            >
                                + Add New Employee
                            </Button>
                        </HeaderBox>
                        </Card>
                        <div
                            style={{
                                padding: '25px 75px',
                                width: '70%',
                                margin: 'auto',
                            }}
                        >
                            <Table
                                size="small"
                                columns={renderTableColumn as any}
                                bordered
                                dataSource={(rowdata as any) || []}
                                pagination={{
                                    defaultPageSize: 100,
                                }}
                                loading={loading}
                                //scroll={{ x: 1500 }}
                                rowKey={(row: any) => row.key}
                                //  rowSelection={rowSelection}
                                //  rowClassName={(record: any) => record.testType === 'Rapid Test'? classes.rapid: record.testType === 'Express Test'? classes.express:record.testType==='Antigen Test'?classes.antigen:classes.standard
                                //  }
                            />
            <Modal
            zIndex={1000}
            visible={inputBox}
            onCancel={handleCancel}
            // onOk={handleOk}
            // okText={'Submit'}
            footer={null}
            width={500}
            bodyStyle={{height: '470px',paddingLeft: '98px', paddingTop: '50px', paddingRight: '108px',}}
        >
         <InputForm  handleCancel = {handleCancel} rowdata = {rowdata} setOpen = {setOpen}></InputForm></Modal>

                        </div>
                        
                </Space>
            </Content>
       </Layout>
    );
}
export default EmpTesting;




