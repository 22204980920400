import React from 'react'
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import Iframe from 'react-iframe'


const Mainpage = styled.div`
  margin: 7% 0 0px 10%;
  font-family: Open Sans;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20%;
    margin-left: 20%;
  }
`;
const Profile = styled.div`
  margin: 0% 0 0px 0%;
  font-family: Open Sans;
  margin-right: 10%;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20%;
    margin-left: 20%;
  }

`;

const ProviderTelemedicine = () => {

    return (
        <Mainpage>
            <>
                <Profile>
                        <Iframe
                            url={`https://cal.com/wsl-uat`}
                            position="absolute"
                            width="80%"
                            id="calenderBooking"
                            className="calenderBooking"
                            height="80%"
                            styles={{ height: "10px", backgroundColor: '#fff' }}
                            allowFullScreen={true}
                        />
                </Profile>
            </>
        </Mainpage>
    )
}

export default ProviderTelemedicine