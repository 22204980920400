import React, { useState, useEffect, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CardBody from './Card/CardBody';
import Card from './Card/Card.js';
import { Wrapper } from '../../../shared/components/styled';
import Loader from '../../../shared/components/Loader';
import Grid from '@material-ui/core/Grid';
import { useAdminState } from '../../provider';

import { generatecountyReport, getCountyReport,reportAccess } from '../../api';

const ButtonWrapper = styled.div`
    margin-bottom: 15px;
    max-width: 1200px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        cardTitleWhite: {
            color: '#FFFFFF',
            marginTop: '0px',
            minHeight: 'auto',
            fontWeight: 'bold',
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: '3px',
            textDecoration: 'none',
        },
        typo: {
            paddingLeft: '25%',
            marginBottom: '40px',
            position: 'relative',
        },
        note: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            bottom: '10px',
            color: '#c0c1c2',
            display: 'block',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '13px',
            left: '0',
            marginLeft: '20px',
            position: 'absolute',
            width: '260px',
        },
        cardCategoryWhite: {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        textcolor: {
            color: 'balck',
        },
    })
);

function WizardReport() {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), 'LL/dd/yyyy')
    );
    const [Allconsolidationdetails, setAllconsolidationdetails] = useState([]);
    const [access, setAccess]=useState(false)
    const { insuranceAccess}=useAdminState();
    const [
        Positiveconsolidationdetails,
        setPositiveconsolidationdetails,
    ] = useState([]);
    const [
        Negativeconsolidationdetails,
        setNegativeconsolidationdetails,
    ] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = () => {
        setLoading(true);
        getCountyReport(selectedDate).then((response: any) => {
            setLoading(false);
            setAllconsolidationdetails(response.data.Allconsolidationdetails);
            setPositiveconsolidationdetails(
                response.data.Positiveconsolidationdetails
            );
            setNegativeconsolidationdetails(
                response.data.Negativeconsolidationdetails
            );
        });
    };

    const onreportdetails = (data: any) => {
        console.log('datadata', data);
        generatecountyReport(data);
    };

    // const insuranceReportAccess=()=>{
    //     reportAccess().then((res:any)=>{
    //        if((res.data[0].insurance).includes(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails')))).email))
    //            setAccess(true)
    //     })
    // }
  console.log("insuranceAccess",insuranceAccess)

    // useEffect(() => {
    //     // getCountyReport()
    //     insuranceReportAccess()
    // }, []);
    console.log(access)
    return (
        <div>
            <Wrapper>
                <Card>
                    {!loading ? (
                        <CardBody>
                            <div className={classes.note}></div>

                            <Grid container spacing={2}>
                                <Grid xs={12} sm={6} md={4}>
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                (window.location.href =
                                                    '/dashboard/countyreport')
                                            }
                                        >
                                            County Report
                                        </Button>
                                    </ButtonWrapper>
                                </Grid>
                                <Grid xs={12} sm={6} md={4}>
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                (window.location.href =
                                                    '/dashboard/weeklyreport')
                                            }
                                        >
                                            Weekly Report
                                        </Button>
                                    </ButtonWrapper>
                                </Grid>
                                <Grid xs={12} sm={6} md={4}>
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                (window.location.href =
                                                    '/dashboard/consolidationreport')
                                            }
                                        >
                                            Consolidation Report
                                        </Button>
                                    </ButtonWrapper>
                                </Grid>
                               {insuranceAccess && <Grid xs={12} sm={6} md={4}>
                                    {' '}
                                    <ButtonWrapper>
                                        <Link
                                            className={classes.textcolor}
                                            to="/dashboard/insuranceReport"
                                        >
                                            <Button variant="contained">
                                                Insurance Report
                                            </Button>
                                        </Link>
                                    </ButtonWrapper>
                                </Grid>}
                                <Grid xs={12} sm={6} md={4}>
                                    <ButtonWrapper>
                                        <Link
                                            className={classes.textcolor}
                                            to="/dashboard/discountreport"
                                        >
                                            <Button variant="contained">
                                                Discount Amount
                                            </Button>
                                        </Link>
                                    </ButtonWrapper>
                                </Grid>
                                <Grid xs={12} sm={6} md={4}>
                                    <ButtonWrapper>
                                        <Link
                                            className={classes.textcolor}
                                            to="/dashboard/insuranceAndStripeRevenu"
                                        >
                                            <Button variant="contained">
                                                Insurance and Stripe Revenue
                                            </Button>
                                        </Link>
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </CardBody>
                    ) : (
                        <Loader />
                    )}
                </Card>
            </Wrapper>
        </div>
    );
}

export default WizardReport;
