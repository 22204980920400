import {
    Container,
    FormControl,
    TextareaAutosize,
    TextField,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedState } from '../../../shared/provider';
import { getTelemedicineInfo, addTelemedicineApptViewLog, updateTelemedicineInfo } from '../LocationAndService/API';
import { useParams, useHistory } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { config } from '../../../../config';
import Loader from '../../../shared/components/Loader';
import moment from 'moment';


const Title = styled.div`
    color: rgba(27, 20, 131, 0.722);
    font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: left;
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const textField = {
    margin: "5px 0px 5px 0px"
}

const InfocustomerInfomedicine = () => {
    const params: any = useParams();
    const history = useHistory();
    const [customerInfo, setCustomerInfo] = React.useState<any>([]);
    const [teleApptInfo, setTeleApptInfo] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<any>(false);

    const sortByDate = (a: any, b: any) => {
        if (a.appointmentdate !== null && b.appointmentdate !== null) {
            const date1: any = new Date(a.appointmentdate)
            const date2: any = new Date(b.appointmentdate)
            return date2 - date1
        }
        return 0;
    };
    const { records } = useSharedState();

    const customerInfomedicineData = async (id: any) => {
        setLoading(true);
        getTelemedicineInfo({ id: id }).then((response: any) => {
            if (response.data.data.length > 0) {
                let newdata: any = response.data.data.sort(sortByDate)
                setCustomerInfo(newdata);
                setTeleApptInfo(response.data.appointment[0]);
                setLoading(false);
            } else {
                history.push(`/dashboard/telemedicineAppt`);
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
        })
    }

    const addViewLog = async (id: any) => {
        setLoading(true);
        addTelemedicineApptViewLog({
            id: id,
            name: JSON.parse(window.localStorage.getItem('userDetails')!).name,
            email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
        }).then((response: any) => {
        }).catch((error: any) => {
            setLoading(false);
        })
    }

    async function callStack() {
        await addViewLog(params.id);
        customerInfomedicineData(params.id);
    }

    React.useEffect(() => {
        callStack();
    }, []);

    async function handleSubmit() {
        updateTelemedicineInfo(teleApptInfo)
            .then((response: any) => {
                callStack();
            }).catch((error: any) => {
                setLoading(false);
            })
    }

    async function handleChange(key: string, value: any) {
        let obj: any = {
            ...teleApptInfo
        };
        obj[key] = value;
        setTeleApptInfo(obj);
    }

    if (loading) {
        return <Loader />
    }

    return (
        <Container maxWidth='sm'>
            <Title>Customer Details</Title>
            <FormControl style={{ display: 'flex', width: 'lg' }}>
                <TextField
                    id="outlined-basic"
                    label="Full Name"
                    value={customerInfo[0]?.firstName + " " + customerInfo[0]?.lastName}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Address"
                    value={customerInfo[0]?.address.address+"." +" "+ "City" + ":"+ customerInfo[0]?.address.city+"."+" "+"State"+":"+customerInfo[0]?.address.state+"."+" "+"ZipCode"+":"+customerInfo[0]?.address.zipCode}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Symptoms"
                    value={customerInfo[0]?.symptoms}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Ethnicity"
                    value={customerInfo[0]?.ethnicity}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Test Type"
                    value={customerInfo[0]?.testType}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Results"
                    value={customerInfo[0]?.results}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
                <TextField
                    id="outlined-basic"
                    label="Tested Date"
                    value={customerInfo[0]?.appointmentdate}
                    variant="outlined"
                    style={textField}
                    disabled={true}
                />
            </FormControl>
            <Title>Questions</Title>
            <TextField
                id="outlined-multiline-static"
                label="Are you allergic to any medications?"
                multiline
                fullWidth
                variant="outlined"
                value={
                    teleApptInfo.questions !== undefined && teleApptInfo.questions.q1 !== undefined
                        ? teleApptInfo.questions.q1.answer
                        : ""
                }
                style={textField}
                onChange={(res) => {
                    let questions: any = {};
                    if (teleApptInfo.questions !== undefined) {
                        questions = {
                            ...teleApptInfo.questions,
                            "q1": {
                                question: "Are you allergic to any medications?",
                                answer: res.target.value
                            }
                        }
                    } else {
                        questions = {
                            "q1": {
                                question: "Are you allergic to any medications?",
                                answer: res.target.value
                            }
                        }

                    }
                    handleChange("questions", questions);
                }}
            />
            <TextareaAutosize
                style={{
                    width: '100%',
                    padding: "15px",
                    border: "1px solid",
                    borderRadius: '3px',
                    margin: "5px 0px 5px 0px"
                }}
                aria-label="empty textarea"
                placeholder="Note"
                value={teleApptInfo.note !== undefined ? teleApptInfo.note : ""}
                onChange={(res) => {
                    handleChange("note", res.target.value);
                }}
            />
            <div style={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    style={{
                        color: "#fff",
                        background: "#1890ff",
                        width: "100%"
                    }}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Submit
                </Button>
            </div>
            <div style={{ margin: "40px" }}></div>
            {teleApptInfo.viewLogs !== undefined && teleApptInfo.viewLogs.length > 0 &&
                <>
                    <Title>Log</Title>
                    <div>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 120 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Viewer Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {teleApptInfo.viewLogs.map((row: any, index: any) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {moment(row.dateTime).format("MM-DD-YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.dateTime).format("hh:mm A")}
                                            </TableCell>
                                            <TableCell>{row.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            }
            <div style={{ margin: "40px" }}></div>
        </Container>
    )
}

export default InfocustomerInfomedicine