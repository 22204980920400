import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
    EditOutlined,
    MoreOutlined,
    UnorderedListOutlined,
    UpSquareOutlined,
} from '@ant-design/icons';
import { parse, format } from 'date-fns';
import { config } from '../../../../../../config';
import Cancel from '../Cancel';
import Results from '../Results';
import Upgrade from '../Upgrade';
import SendConfirmation from '../SendConfirmation';
import firebase, { functions } from 'firebase';
import axios from 'axios';
import { useAdminDispatch, useAdminState } from '../../../../provider';
import { getSlotsByLocationId } from '../../../../../shared/api';
import { getAppointmentdata } from '../../../../api';
import { useHistory } from 'react-router-dom';
import QBenchModal from '../../../../Pages/LocationAndService/QBenchModal';

interface IProps {
    record?: any;
    onEditAppointment?: (record: any) => void;
    editid?: (id: any, page: any) => void;
    handleDrawerToggle: Function;
    isLocation?: boolean;
   handleSearch:()=>void;
    
}

function MoreActions({
    record,
    onEditAppointment,
    handleDrawerToggle,
    isLocation,
    handleSearch
}: IProps) {
    const [visible, setVisible] = useState(false);

    const { appointments } = useAdminState();
    const { setLocation } = useAdminDispatch();

    const {
        updateParams,
        setEditAppointment,
        updateAdminSlots,
        goToNextStep,
        SetID,
    } = useAdminDispatch();
    const [upgrade, setUpgrade] = useState(false);
    const [showQBenchModal, setShowQBenchModal] = useState(false)
    const [handleOpen, setHandleOpen] = React.useState(false);
    const [handleUpgradeOpen, setHandleUpgradeOpen] = React.useState(false);
    const [hasRapid, setHasRapid] = React.useState(false);
    const [hasExpress, setHasExpress] = React.useState(false);
    const history = useHistory();
    const db = firebase.firestore();
    const fetchAvailableTest = async () => {
        if (record?.slot?.locationId) {
            const snap = db
                .collection('locations')
                .where('qbenchCustomerId', '==', record?.slot?.locationId);
            const snapShot = await snap.get();
            const data = snapShot.docs.map((doc: any) => doc.data());            
            setHasRapid(data[0]?.rapidTest);
            setHasExpress(data[0]?.hasVipSlots);
        }
    };
    useEffect(() => {
        (isLocation !== true) && fetchAvailableTest();
    }, [record]);

    const handleClickOpen = () => {
        setVisible(false)
        setHandleOpen(true);
    };
    const handleVisibleChange = (visibleStatus: boolean) => {
        setVisible(visibleStatus);
    };
    const handleEditAppointment = (record: any, upgrade?: boolean) => {
        setVisible(false); 
        // onEditAppointment(record)
        handleDrawerToggle(upgrade === true ? false : true);

        getAppointmentdata({ confirmationId: record.confirmationId })
            .then(async (response: any) => {
                if (response.data.length > 0) {
                    // const periods: any = await getSlotsByLocationId(response.data[0].location?.qbenchCustomerId)
                    // updateAdminSlots(periods)
                    console.log('response.data', response.data);
                    setEditAppointment(response.data);
                    return true;
                } else {
                    return true;
                }
            })
            .catch(() => {});
    };

    const editid = (id: string, page: number) => {
        SetID(id);
    };

    const menu = (record: any) => (
        <>
            {console.log('record', record)}
            <Menu>
                {(record.isCanceled === '' || record.isCanceled === false) && (record.isPanel ? (record?.tests?.length &&  record.tests?.filter((e:any)=>e?.result).length === 0) : record.results === '' ) && (
                    <>
                        <Menu.Item key="0">
                            <Button
                                type="link"
                                onClick={() => handleEditAppointment(record)}
                                block
                                icon={<EditOutlined />}
                            >
                                Edit
                            </Button>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <SendConfirmation
                                confirmationId={record.confirmationId}
                                email={record.email}
                                setVisible={()=>{setVisible(false)}}
                                isMinor={(record?.minorIndex ?? -1)>=0}
                            />
                        </Menu.Item>
                    </>
                )}
                {!isLocation &&
                !record.isPanel&&
                    !record.isRapidTest &&
                    !record.isAntigen &&
                    record.results == '' &&
                    !record.isCanceled &&
                    record.promoCode !== 'EMPLOYEE' &&
                    (hasRapid || hasExpress) &&
                    (record.payingMethod === 'Credit Card' ||
                        record.payingMethod === 'Credit card') && (
                        <Menu.Item key="7">
                            <Button
                                type="link"
                                block
                                icon={<UpSquareOutlined />}
                                onClick={() => {
                                    setUpgrade(true);
                                    setVisible(false);
                                }}
                            >
                                Upgrade
                            </Button>
                        </Menu.Item>
                    )}
                {upgrade && (
                    <Upgrade
                        testType={record.testType}
                        confirmationId={record.confirmationId}
                        onClose={setUpgrade}
                        hasRapid={hasRapid}
                        hasExpress={hasExpress}
                        handleSearch={handleSearch}
                        testSelection={record.testSelection}
                        email={record.email}
                        record={record}
                    />
                )}
                {!isLocation && (
                    <Menu.Item key="3">
                        <Cancel appt={record} handleSearch={handleSearch} />
                    </Menu.Item>
                )}
                 {(record.isAntigen || record.isRapidTest || record.isPanel) && !isLocation && (record.isPanel ? (record.tests.length && record.tests.filter((e:any)=>e?.result).length === 0) : !(record.isRelease)) && !record.isCanceled && (
                    <Menu.Item key="4">
                        <Button
                            type="link"
                            onClick={handleClickOpen}
                            block
                            icon={<UnorderedListOutlined />}
                        >
                            Input Results
                        </Button>
                    </Menu.Item>
                )}
                {(record.isAntigen || record.isRapidTest || record.isPanel) && !isLocation && (record.isPanel ? (record.tests.length && record.tests.filter((e:any)=>e?.result).length !== 0) : (record.isRelease)) &&(
                    <Menu.Item key="4">
                        <Button
                            type="link"
                            onClick={handleClickOpen}
                            block
                            icon={<UnorderedListOutlined />}
                        >
                            {record.isPanel ? record.tests.filter((e:any)=>e.result).length === record.tests.length ? 'Unrelease Results' : 'Update Results' : 'Unrelease Result'}
                        </Button>
                    </Menu.Item>
                )}
                {isLocation === true && showQBenchModal && (
                    <QBenchModal
                        showQBenchModal={showQBenchModal}
                        setShowQBenchModal={setShowQBenchModal}
                        QBdata={record}
                        handleDrawerToggle={handleDrawerToggle}
                    />
                )}
                {isLocation === true &&
                    !showQBenchModal &&
                    record.cPartnerID !== 'BEL001' &&
                    record.cPartnerID !== 'SSF001' &&
                    record.cPartnerID !== 'SEQ001' &&
                    record.cPartnerID !== 'LLT001' &&
                    record.cPartnerID !== 'POR001' &&
                    record.cPartnerID !== 'SCS001' && 
                    record.cPartnerID !== 'PCMA001' && 
                     (
                        <>
                            <Menu.Item key="5">
                                <Button
                                    type="primary"
                                    onClick={() => setShowQBenchModal(true)}
                                    style={{ width: '100%' }}
                                >
                                    Update QBench
                                </Button>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        console.log("record.id", record.id)
                                        setVisible(false);
                                        history.push(
                                            `/dashboard/updateLocation?id=${record.id}`
                                        );
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    Edit General
                                </Button>
                            </Menu.Item>
                            <Menu.Item key="7">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setVisible(false);
                                        console.log('1234', record);
                                        editid(record.id, 0);
                                        setLocation(record);
                                        goToNextStep();
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    Edit Timeslots
                                </Button>
                            </Menu.Item>
                        </>
                    )}
            </Menu>
            { handleOpen && <Results
                open={handleOpen}
                handleClose={() => setHandleOpen(false)}
                record={record}
                handleSearch={handleSearch}
            />}
        </>
    );

    return (
        <Dropdown
            overlay={menu(record)}
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button type="link" icon={<MoreOutlined />} />
        </Dropdown>
    )
}

export default MoreActions
