import React, { useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { SearchOutlined, FilterFilled,RedoOutlined } from '@ant-design/icons';
import {
    getAdminConfigDaywise,
    getUserDetails,
    adminRegisterLogin,
    updateCustomer,
} from '../../api';
import { useAdminState, useAdminDispatch } from '../../provider';
import {
    getPeriodsFromSchedule,
    compareStrings,
    comparePhone,
} from '../../../../utils';
import { AppointmentListItem } from '../../models';
import { config } from '../../../../config';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    formItemLayout,
    searchBarActionLayout,
    customerTable,
} from './constants';
import { styles } from './styles';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const { Content } = Layout;
const { Title } = Typography;

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        odd: {
            background: '#a6d0e3',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#a6d0e3 !important',
                    '&:last-child': {
                        backgroundColor: '#f8f9fa !important',
                    },
                },
            },
        },

        even: {
            background: '#f8f9fa',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#f8f9fa !important',
                },
            },
        },

        rapid: {
            background: '#FFD580',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#FFD580 !important',
                },
            },
        },
        customfilterdropdown: {
            padding: '12px',
            borderRadius: '4px',
            background: '#fff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        },
    })
);

const sortByPeriod = (a: AppointmentListItem, b: AppointmentListItem) => {
    if (a.period !== null && b.period !== null) {
        return a.period - b.period;
    }
    return 0;
};

function AppointmentList() {
    const classes = useStyles();
    const { params, appointments } = useAdminState();
    const { updateParams, setAppointments } = useAdminDispatch();
    const [loading, setLoading] = useState(false);
    const [checks, setchecks] = useState(false);
    const [expand, setExpand] = useState(true);
    const [searchText, setsearchText] = useState('');
    const [searchInput, setsearchInput] = useState<any[]>([]);

    const [searchKeys, setSearchKeys] = useState({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        // date: format(new Date(), config.dateFormat),
        date: '',
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments:'',
    });

    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });

    // let displayColumns:any=[];
    const renderCheckin = (status: any, record: any) => {
        return (
            <div>
                <Button
                    type="primary"
                    // loading={loading}
                    // disabled={record.isVerified && record.adminLogin  ? false : record.isVerified && record.adminLoginCount==0 ? false : true}
                    disabled={record.isVerified ? false : true}
                    onClick={async () => {
                        setLoading(true);
                        const params = {
                            email: record.email,
                            password: record.password,
                        };
                        const loggedUser = await adminRegisterLogin(params);
                        // await updateCustomer(params);
                        if (loggedUser?.data?.status == 200) {
                            // update();
                            //   window.open(`https://localhost:3001/signin/${params.email}-${record.id}`, '_blank');
                            window.open(
                                `https://wsl-multitenancy-dev-ac13b.web.app/signin/${record.id}`,
                                '_blank'
                            );
                            // setchecks(!checks)

                            setLoading(false);
                        } else {
                            setalertbox({
                                open: true,
                                type: 'error',
                                msg: loggedUser?.data?.message,
                            });
                            setLoading(false);
                        }
                    }}
                >
                    {record.isVerified ? 'Log in as Customer' : 'Not Verified'}
                    {/* {record.isVerified && ( record.adminLogin || record.adminLoginCount==0) ? 'Log in as Customer' : record.isVerified && record.adminLoginCount!=0 ? 'Logout from previous account' : 'Not Verified'} */}
                </Button>
            </div>
        );
    };

    const handleFilterSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        setsearchText(selectedKeys[0]);
    };
    const handleReset = (clearFilters: any) => {
        clearFilters();
        setsearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: any) => (
            <div className={classes.customfilterdropdown}>
                <Input
                    ref={(node: any) => {
                        setsearchInput(node);
                    }}
                    // placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleFilterSearch(selectedKeys, confirm)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Tooltip placement="top" title={'Search'}>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleFilterSearch(selectedKeys, confirm)
                        }
                        // icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8, border: '#fff' }}
                    >
                        <SearchOutlined style={{ color: '#fff' }} />
                    </Button>
                </Tooltip>
                <Tooltip placement="top" title={'Reset'}>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90, borderRadius: '5px' }}
                    >
                        <RedoOutlined style={{ color: '#000' }} />
                    </Button>
                </Tooltip>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            (record[dataIndex] ? record[dataIndex] : '')
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                // setTimeout(() =>searchInput.select());
            }
        },
        // render: (text:any) => (
        // 	<Highlighter
        // 		highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        // 		searchWords={searchText}
        // 		autoEscape
        // 		textToHighlight={text.toString()}
        // 	/>
        // )
    });


    const [renderTableColumn, setRenderTableColumn] = useState<any[]>([]);

    // const update = () => {
    // 		async function getAdminConfigDaywiseColumn() {
    // 			const tableColumnData = customerTable(renderCheckin)
    // 			setRenderTableColumn(tableColumnData);
    // 			let response = await getAdminConfigDaywise({email: "aravindan@worksitelabs.com" })
    // 			if(response.data.status == 200){
    // 				if(response.data.data.length > 0){
    // 					// setRenderTableColumn(response.data.data[0].daywiseconfig.hideandshowcolumn);
    // 				}else{
    // 					const tableColumnData = customerTable(renderCheckin)
    // 					setRenderTableColumn(tableColumnData);
    // 					// setInitialColumnList(tableColumnData);
    // 				}
    // 			}else{
    // 				const tableColumnData = customerTable(renderCheckin)
    // 				setRenderTableColumn(tableColumnData);
    // 				// setInitialColumnList(tableColumnData);
    // 			}
    // 	}

    // 	getAdminConfigDaywiseColumn()
    // 	setLoading(true)
    // 	getUserDetails(params)
    // 	.then(({ data }) => {
    // 		const updatedAppointments = data
    // 			.sort(sortByPeriod)
    // 			.map((item: any) => ({
    // 				...item,
    // 				period:
    // 					item.isRapidTest || item.isRapidTest == 'TRUE'
    // 						? item.period !== null && item.period >= 0
    // 							? rapidPeriods[item.period].label
    // 							: '-'
    // 						: item.period !== null && item.period >= 0
    // 							? periods[item.period].label
    // 							: '-',
    // 				// isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
    // 				isExpressSameDayTest:
    // 					item.isExpressSameDayTest == 'FALSE'
    // 						? 'No'
    // 						: item.isExpressSameDayTest == 'TRUE'
    // 							? 'Yes'
    // 							: item?.isExpressSameDayTest
    // 								? 'Yes'
    // 								: 'No',
    // 				isRapidTest:
    // 					item.isRapidTest == 'FALSE'
    // 						? 'No'
    // 						: item.isRapidTest == 'TRUE'
    // 							? 'Yes'
    // 							: item?.isRapidTest
    // 								? 'Yes'
    // 								: 'No',
    // 				isCanceled: item.isCanceled ? 'Canceled' : '',
    // 			}));
    // 		console.log('updatedAppointments',updatedAppointments)
    // 		setAppointments(updatedAppointments);
    // 		setLoading(false);

    // 	})
    // 	.catch((err:any) => {
    // 		console.error(err);
    // 		setLoading(false);
    // 	});
    // }

    useEffect(() => {
        async function getAdminConfigDaywiseColumn() {
            const tableColumnData = customerTable(renderCheckin,getColumnSearchProps);
            setRenderTableColumn(tableColumnData);
            let response = await getAdminConfigDaywise({
                email: 'aravindan@worksitelabs.com',
            });
            if (response.data.status == 200) {
                if (response.data.data.length > 0) {
                    // setRenderTableColumn(response.data.data[0].daywiseconfig.hideandshowcolumn);
                } else {
                    const tableColumnData = customerTable(renderCheckin,getColumnSearchProps);
                    setRenderTableColumn(tableColumnData);
                    // setInitialColumnList(tableColumnData);
                }
            } else {
                const tableColumnData = customerTable(renderCheckin,getColumnSearchProps);
                setRenderTableColumn(tableColumnData);
                // setInitialColumnList(tableColumnData);
            }
        }

        getAdminConfigDaywiseColumn();
        // setLoading(true);

        console.log('params',params)

        if (
            params.firstName !== '' ||
            params.lastName !== '' ||
            params.email !== '' ||
            params.phone !== '' 
        ) {
            setLoading(true);
            getUserDetails(params)
                .then(( data ) => {
                    const updatedAppointments = data
                        .sort(sortByPeriod)
                        .map((item: any) => ({
                            ...item,
                            period:
                                item.isRapidTest || item.isRapidTest == 'TRUE'
                                    ? item.period !== null && item.period >= 0
                                        ? rapidPeriods[item.period].label
                                        : '-'
                                    : item.period !== null && item.period >= 0
                                        ? periods[item.period].label
                                        : '-',
                            // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
                            isExpressSameDayTest:
                                item.isExpressSameDayTest == 'FALSE'
                                    ? 'No'
                                    : item.isExpressSameDayTest == 'TRUE'
                                        ? 'Yes'
                                        : item?.isExpressSameDayTest
                                            ? 'Yes'
                                            : 'No',
                            isRapidTestText:
                                item.isRapidTest == 'FALSE'
                                    ? 'No'
                                    : item.isRapidTest == 'TRUE'
                                        ? 'Yes'
                                        : item?.isRapidTest
                                            ? 'Yes'
                                            : 'No',
                            isCanceled: item.isCanceled ? 'Canceled' : '',
                        }));
                    console.log('updatedAppointments', updatedAppointments);
                    setAppointments(updatedAppointments);
                    // update();
                    setLoading(false);
                })
                .catch((err: any) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    }, []);

    // React.useEffect(()=>{

    // 	 if (typeof window !== "undefined") {
    // 		window.addEventListener('visibilitychange', () => {
    // 		  if(document.hidden){
    // 			console.log('checks1',window.document.hidden)
    // 			const getAdminConfigDaywiseColumn = async() => {
    // 				const tableColumnData = customerTable(renderCheckin)
    // 				setRenderTableColumn(tableColumnData);
    // 				let response = await getAdminConfigDaywise({email: "aravindan@worksitelabs.com" })
    // 				if(response.data.status == 200){
    // 					if(response.data.data.length > 0){
    // 						// setRenderTableColumn(response.data.data[0].daywiseconfig.hideandshowcolumn);
    // 					}else{
    // 						const tableColumnData = customerTable(renderCheckin)
    // 						setRenderTableColumn(tableColumnData);
    // 						// setInitialColumnList(tableColumnData);
    // 					}
    // 				}else{
    // 					const tableColumnData = customerTable(renderCheckin)
    // 					setRenderTableColumn(tableColumnData);
    // 					// setInitialColumnList(tableColumnData);
    // 				}
    // 		}

    // 		getAdminConfigDaywiseColumn()
    // 		setLoading(true)
    // 		getUserDetails(params)
    // 		.then(({ data }) => {
    // 			const updatedAppointments = data
    // 				.sort(sortByPeriod)
    // 				.map((item: any) => ({
    // 					...item,
    // 					period:
    // 						item.isRapidTest || item.isRapidTest == 'TRUE'
    // 							? item.period !== null && item.period >= 0
    // 								? rapidPeriods[item.period].label
    // 								: '-'
    // 							: item.period !== null && item.period >= 0
    // 								? periods[item.period].label
    // 								: '-',
    // 					// isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
    // 					isExpressSameDayTest:
    // 						item.isExpressSameDayTest == 'FALSE'
    // 							? 'No'
    // 							: item.isExpressSameDayTest == 'TRUE'
    // 								? 'Yes'
    // 								: item?.isExpressSameDayTest
    // 									? 'Yes'
    // 									: 'No',
    // 					isRapidTest:
    // 						item.isRapidTest == 'FALSE'
    // 							? 'No'
    // 							: item.isRapidTest == 'TRUE'
    // 								? 'Yes'
    // 								: item?.isRapidTest
    // 									? 'Yes'
    // 									: 'No',
    // 					isCanceled: item.isCanceled ? 'Canceled' : '',
    // 				}));
    // 			console.log('updatedAppointments',updatedAppointments)
    // 			setAppointments(updatedAppointments);
    // 			update();
    // 			setLoading(false);
    // 		})
    // 		.catch((err:any) => {
    // 			console.error(err);
    // 			setLoading(false);
    // 		});
    // 		  }
    // 	  })
    // 	 }

    //   },[window.document.hidden])

    const dataSourceAppointments = useMemo(
        () =>
            appointments
                ?.filter((appointment: any) => {
                    const filterList = [
                        'firstName',
                        'lastName',
                        'confirmationId',
                        'phone',
                        'birthDate',
                        'email',
                        'sampleId',
                    ];
                    // for (const filterItem of filterList) {
                    //     const searchKey =
                    //         searchKeys[filterItem as keyof typeof searchKeys];
                    //     const value = appointment[filterItem];

                    //     if (
                    //         filterItem == 'phone' &&
                    //         searchKey &&
                    //         !comparePhone(value, searchKey.toString())
                    //     ) {
                    //         return false;
                    //     } else if (
                    //         searchKey &&
                    //         !compareStrings(
                    //             value ? value : '',
                    //             searchKey.toString() ? searchKey.toString() : ''
                    //         )
                    //     ) {
                    //         return false;
                    //     }
                    // }
                    return true;
                })
                .map((appointment: any, appIndex: number) => ({
                    ...appointment,
                    key: appIndex,
                })),

        [appointments, searchKeys]
    );

    useEffect(() => {
        if (params.locationQbenchId == null) {
            params.locationQbenchId = 0;
        }

        if (
            params.firstName !== '' ||
            params.lastName !== '' ||
            params.email !== '' ||
            params.phone !== ''
        ) {
            setLoading(true);
            getUserDetails(params)
                .then((data) => {
                    const updatedAppointments = data
                        .sort(sortByPeriod)
                        .map((item: any) => ({
                            ...item,
                            period:
                                item.isRapidTest || item.isRapidTest == 'TRUE'
                                    ? item.period !== null && item.period >= 0
                                        ? rapidPeriods[item.period].label
                                        : '-'
                                    : item.period !== null && item.period >= 0
                                        ? periods[item.period].label
                                        : '-',
                            // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
                            isExpressSameDayTest:
                                item.isExpressSameDayTest == 'FALSE'
                                    ? 'No'
                                    : item.isExpressSameDayTest == 'TRUE'
                                        ? 'Yes'
                                        : item?.isExpressSameDayTest
                                            ? 'Yes'
                                            : 'No',
                            isRapidTestText:
                                item.isRapidTest == 'FALSE'
                                    ? 'No'
                                    : item.isRapidTest == 'TRUE'
                                        ? 'Yes'
                                        : item?.isRapidTest
                                            ? 'Yes'
                                            : 'No',
                            isCanceled: item.isCanceled ? 'Canceled' : '',
                        }));

                    setAppointments(updatedAppointments);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    }, [params, setAppointments]);

    const handleSearchKeysUpdate = (key: string) => (
        value: string | number
    ) => {
        setSearchKeys({
            ...searchKeys,
            [key]: value,
        });
    };

    const handleSearch = () => {
        console.log('searchKeys', searchKeys);
        updateParams(searchKeys);
    };

    return (
        <div>
            <Layout
                style={{
                    marginTop: '100px',
                    backgroundColor: '#FFF',
                    border: '1px solid #f3eeee',
                    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
                }}
            >
                <div
                    style={{
                        borderRadius: '3px',
                        background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                        boxShadow:
                            '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                        margin: '0px 25px',
                        marginTop: '-25px',
                    }}
                >
                    <Title
                        level={4}
                        style={{ margin: '24px 16px', color: '#FFF' }}
                    >
                        Customers
                    </Title>
                </div>
                <Content style={styles.content}>
                    <Space
                        direction="vertical"
                        style={styles.spaceWidth}
                        size="middle"
                    >
                        <Card bodyStyle={styles.card}>
                            <Form layout={'vertical'}>
                                <Row
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    }}
                                >
                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 12 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item label="Email" name="email">
                                            <Input
                                                disabled={loading}
                                                allowClear
                                                onChange={(event) =>
                                                    handleSearchKeysUpdate(
                                                        'email'
                                                    )(event.target.value.replace(/\s+/g, ''))
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 12 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item label="Phone" name="phone">
                                            <Input
                                                allowClear
                                                disabled={loading}
                                                onChange={(event) =>
                                                    handleSearchKeysUpdate(
                                                        'phone'
                                                    )(event.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 12 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                        >
                                            <Input
                                                disabled={loading}
                                                allowClear
                                                onChange={(event) =>
                                                    handleSearchKeysUpdate(
                                                        'firstName'
                                                    )(event.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 12 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            label="Last Name"
                                            name="lastName"
                                        >
                                            <Input
                                                disabled={loading}
                                                allowClear
                                                onChange={(event) =>
                                                    handleSearchKeysUpdate(
                                                        'lastName'
                                                    )(event.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        span={18}
                                        style={{
                                            marginLeft: expand ? '0%' : '65%',
                                        }}
                                    ></Col>
                                    <Col
                                        span={6}
                                        style={{
                                            marginLeft: expand ? '0%' : '65%',
                                        }}
                                    >
                                        <Form.Item {...searchBarActionLayout}>
                                            <Space
                                                size="small"
                                                style={styles.searchBarActions}
                                            >
                                                <Button
                                                    type="primary"
                                                    loading={loading}
                                                    onClick={handleSearch}
                                                >
                                                    Search
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <Card>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={alertbox.open}
                                autoHideDuration={6000}
                                onClose={() =>
                                    setalertbox({
                                        open: false,
                                        type: '',
                                        msg: '',
                                    })
                                }
                            >
                                <Alert
                                    onClose={() =>
                                        setalertbox({
                                            open: false,
                                            type: '',
                                            msg: '',
                                        })
                                    }
                                    severity={alertbox.type}
                                >
                                    {alertbox.msg}
                                </Alert>
                            </Snackbar>
                            <div style={{ paddingBottom: '25px' }}>
                                <Table
                                    size="small"
                                    columns={renderTableColumn as any}
                                    dataSource={
                                        (dataSourceAppointments as any) || []
                                    }
                                    loading={loading}
                                    scroll={{ x: 1500 }}
                                    rowKey={(record: any) => record.key}
                                    // rowSelection={rowSelection}
                                    rowClassName={(record: any) =>
                                        record.isRapidTest === 'Yes'
                                            ? classes.rapid
                                            : record.isExpressSameDayTest ===
                                                'Yes'
                                                ? classes.odd
                                                : classes.even
                                    }
                                />
                            </div>
                        </Card>
                    </Space>
                </Content>
            </Layout>
        </div>
    );
}

export default AppointmentList;
