
import React, { useState, useEffect } from 'react'
import { Button, Layout, Typography } from 'antd';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { BsPencilSquare } from 'react-icons/bs'
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Radio, RadioGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import Input from '../../../shared/components/form/Input';
import { useAdminDispatch, useAdminState } from '../../provider';
import Select from '../../../shared/components/form/Select';
import { MappedFields } from '../../models';
import { getJotFormConfigList, storeJotFormConfig } from '../LocationAndService/API';
import moment from 'moment';


const { Title } = Typography;

interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
  marginTop?: boolean;
}

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '650px';
  }};
  margin-top: ${({ marginTop }: ContainerProps) => (marginTop ? '40px' : '50px')};
  padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
`;

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
};

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

const Container1 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  align-content: center;
`

const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;


const Returnbtn = styled.div`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
;
`;

export const FormContainer = styled.div`
  padding: 50px;
  flex-direction: row;
`
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const JotFormConfig: React.FC = () => {
  const { updateJotFormValues } = useAdminDispatch();
  const { jotForm } = useAdminState();
  const { jotFormUrl, formName, location, mappedFields, qBenchCustomer } = jotForm

  const qbenchCustomer = [
    {
      label: 'WSL',
      value: 'WSL001'
    },
    {
      label: 'School',
      value: 'SEQ001'
    }

  ]

  const firebaseFields = [
    {
      label: 'X_PATIENT_FIRST_NAME',
      value: 'X_PATIENT_FIRST_NAME'
    },
    {
      label: 'X_PATIENT_MIDDLE_NAME',
      value: 'X_PATIENT_MIDDLE_NAME'
    },
    {
      label: 'X_PATIENT_LAST_NAME',
      value: 'X_PATIENT_LAST_NAME'
    },
    {
      label: 'X_PATIENT_DOB',
      value: 'X_PATIENT_DOB',
    },
    {
      label: 'X_PATIENT_ADDRESS1',
      value: 'X_PATIENT_ADDRESS1',
    },
    {
      label: 'X_PATIENT_CITY',
      value: 'X_PATIENT_CITY',
    },
    {
      label: 'X_PATIENT_STATE',
      value: 'X_PATIENT_STATE',
    },
    {
      label: 'X_PATIENT_ZIP_CODE',
      value: 'X_PATIENT_ZIP_CODE',
    },
    {
      label: 'patient_race',
      value: 'patient_race',
    },
    {
      label: 'patient_ethnicity',
      value: 'patient_ethnicity',
    },
    {
      label: 'X_PATIENT_SEX',
      value: 'X_PATIENT_SEX',
    },
    {
      label: 'X_PASSPORT_NUMBER',
      value: 'X_PASSPORT_NUMBER',
    },
    {
      label: 'X_PASSPORT_COUNTRY',
      value: 'X_PASSPORT_COUNTRY',
    },
    {
      label: 'X_PATIENT_EXTERNAL_ID',
      value: 'X_PATIENT_EXTERNAL_ID',
    },
    {
      label: 'X_EXTERNAL_ORDER_NUMBER',
      value: 'X_EXTERNAL_ORDER_NUMBER',
    },
    {
      label: 'X_TRAVEL_TYPE',
      value: 'X_TRAVEL_TYPE',
    },
    {
      label: 'customer_account_id',
      value: 'customer_account_id',
    },
    {
      label: 'date_received',
      value: 'date_received',
    },
    {
      label: 'X_EXTERNAL_ORDER_NUMBER',
      value: 'X_EXTERNAL_ORDER_NUMBER',
    },
    {
      label: 'X_SPECIMEN_COUNT',
      value: 'X_SPECIMEN_COUNT',
    },
    {
      label: 'X_PATIENT_EXTERNAL_ID',
      value: 'X_PATIENT_EXTERNAL_ID',
    },
    {
      label: 'X_TRAVEL_DEST',
      value: 'X_TRAVEL_DEST',
    },
    {
      label: 'X_SPECIMEN_COUNT',
      value: 'X_SPECIMEN_COUNT',
    },
    {
      label: 'ID_NUMBER',
      value: 'ID_NUMBER',
    },
    {
      label: 'accessioning_type_id',
      value: 'accessioning_type_id',
    },
    {
      label: 'X_SCHOOL',
      value: 'X_SCHOOL',
    },
    {
      label: 'X_PATIENT_EXTERNAL_ID',
      value: 'X_PATIENT_EXTERNAL_ID',
    },
    {
      label: 'X_EXTERNAL_ACCESSION_ID',
      value: 'X_EXTERNAL_ACCESSION_ID',
    },
    {
      label: 'X_ASSIGNING_AUTHORITY',
      value: 'X_ASSIGNING_AUTHORITY',
    },
    {
      label: 'order_id',
      value: 'order_id',
    },
  ];

  const data: any = {
    "responseCode": 200,
    "message": "success",
    "content": {
      "1": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "FirstName",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "2": {
        "labelAlign": "Auto",
        "middle": "No",
        "name": "LastName",
        "order": "1",
        "prefix": "No",
        "qid": "2",
        "readonly": "No",
        "required": "No",
        "shrink": "Yes",
        "suffix": "No",
        "text": "Full Name",
        "type": "control_fullname"
      },
      "3": {
        "cols": "40",
        "entryLimit": "None-0",
        "labelAlign": "Auto",
        "name": "PhoneNumber",
        "order": "3",
        "qid": "3",
        "required": "No",
        "rows": "6",
        "text": "Your Message",
        "type": "control_textarea",
        "validation": "None"
      },
      "4": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "Email",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "5": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "Address",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "6": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "DateOfBirth",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "7": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "Sex",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "8": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "Race",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "9": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "Ethnicity",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
      "10": {
        "hint": " ",
        "labelAlign": "Auto",
        "name": "TestLocation",
        "order": "1",
        "qid": "1",
        "readonly": "No",
        "required": "No",
        "shrink": "No",
        "size": "20",
        "text": "Textbox Example",
        "type": "control_textbox",
        "validation": "None"
      },
    },
    "limit-left": 4982
  }

  const [step, setStep] = useState<number>(0);
  const [selectLocation, setSelectLocation] = useState('custom location');
  const [fieldMapping, setFieldMapping] = useState<MappedFields[]>([]);
  const [missingFields, setMissingFields] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [configList, setConfigList] = useState<any>([]);
  const [record, setRecord] = useState<any>()


  const fetchData = async () => {
    const response: any = await getJotFormConfigList();
    if (response?.status == 200) {
      const result = response?.data?.result?.data;
      setConfigList(result);
    }
  }

  const fieldsDropdown: any = [];
  const sampleQuestions: any = [];
  const keys = Object.keys(data.content);
  for (let key of keys) {
    sampleQuestions.push(data.content[key].name)
    fieldsDropdown.push({ label: data.content[key].name, value: data.content[key].name })
  }

  const handleSelect = (formField: any, firebaseField: any) => {
    console.log(formField, firebaseField);

    for (const field of fieldMapping) {
      if (field.formField === formField) {
        fieldMapping.splice(fieldMapping.indexOf(field), 1);
      }
    }
    setFieldMapping((prev) => (
      [...prev, { formField: formField, firebaseField: firebaseField }]
    ))
  }

  const updateMissedFields = (formField: any, firebaseField: any) => {
    setFieldMapping((prev) => (
      [...prev, { formField: formField, firebaseField: firebaseField }]
    ))
  }
  const handleMissingFields = () => {
    const missingData = [];
    for (const field of firebaseFields) {
      console.log(field);
      if (mappedFields.filter((item) => item.firebaseField === field.value).length) {
        console.log("exists")
      }
      else {
        console.log("doesn't")
        missingData.push(field);
      }
    }
    console.log(missingData);
    setMissingFields(missingData)

  }

  const handleSubmit = async () => {
    updateJotFormValues({ createdAt: new Date() })
    const data = await storeJotFormConfig(jotForm);
    console.log(data);
  }

  const handleClick = (id: any) => {
    setIsEdit(true);
    setRecord(configList[id]);
    setStep(step + 1)

  }

  useEffect(() => {
    fetchData()
  }, []);
  return (
    <>
      {step === 0 &&
        <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>
          <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
            <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>JotForm Configurations</Title>
          </div>
          <div style={{ padding: "30px" }}>
            <Button type="primary"
              onClick={() => { setStep(step + 1); setIsEdit(false) }}>
              Create New JotForm Config
            </Button>
          </div>

          <div style={{ padding: "50px" }}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell><b>Form Name</b></TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Location Type</TableCell>
                  <TableCell>Create On</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {configList?.map((data: any, index: number) => (
                  <TableRow>
                    <TableCell>{data.formName}</TableCell>
                    <TableCell>{data.jotFormUrl}</TableCell>
                    <TableCell>{data.location}</TableCell>
                    <TableCell>{moment(data.createdAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell><button style={{ border: '0px', backgroundColor: 'white' }} onClick={() => handleClick(index)}><BsPencilSquare /></button> </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Layout>
      }
      {step === 1 &&
        <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>
          <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
            <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>{isEdit ? "Edit JotForm Configuration" : "Create New JotForm Configuration"}</Title>
          </div>
          <Container >
            <Formik
              initialValues={{
                jotFormUrl: isEdit ? record?.jotFormUrl : jotFormUrl,
                formName: isEdit ? record?.formName : formName,
                location: isEdit ? record?.location : location,
                qBenchCustomer: isEdit ? qbenchCustomer.filter((o) => Object.values(o).includes(record?.qBenchCustomer)) : qBenchCustomer
              }}
              onSubmit={(values) => {
                setStep(step + 1)
              }}
            >
              {() => (
                <Form>
                  <FormContainer >
                    <span >To begin, Please paste in the URL of your jotForm</span>
                    <Space />
                    <Input name="jotFormUrl"
                      onChange={(value) =>
                        updateJotFormValues({ jotFormUrl: value })
                      }></Input>
                    <Space />
                    <FormControl >
                      <span>Where will this test takes place?</span>
                      <RadioGroup value={selectLocation} onChange={(e) => { setSelectLocation(e.target.value); updateJotFormValues({ location: e.target.value }) }} name='location' >
                        <FormControlLabel value="worksite labs location" control={<Radio color='primary' />} label="Worksite Labs Location" />
                        <FormControlLabel value="custom location" control={<Radio color='primary' />} label="Custom Location" />
                      </RadioGroup>
                    </FormControl>
                    <Space />

                    <span >Form Name</span>
                    <Space />
                    <Input name='formName'
                      onChange={(value) =>
                        updateJotFormValues({ formName: value })
                      }></Input>
                    <Space />
                    <span >Qbench Customer</span>

                    <Select<string>
                      name="qBenchCustomer"
                      options={qbenchCustomer}
                      onChange={(value) =>
                        updateJotFormValues({ qBenchCustomer: value })
                      }

                    />

                    <Space />
                    <ButtonContainer>
                      <Returnbtn onClick={() => { setStep(step - 1); }}>Cancel</Returnbtn>
                      <Space />
                      <Confirmbtn type='submit' > Next</Confirmbtn>

                    </ButtonContainer>
                  </FormContainer>
                </Form>
              )}
            </Formik>
          </Container>
          {/* </div> */}
        </Layout>
      }
      {step === 2 &&
        <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>
          <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
            <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>{isEdit ? "Edit JotForm Configuration" : "Create New JotForm Configuration"}</Title>
          </div>
          <Container>
            <Formik
              initialValues={{
                mappedFields: isEdit ? record?.mappedFields : [],
              }}
              onSubmit={() => {
                updateJotFormValues({ mappedFields: fieldMapping })
                setStep(step + 1)

              }}
            >
              {() => (
                <Form>
                  <span>We have detected the following fields within your JotForm. Please map these fields to the corresponding fields within Firebase.</span>
                  <FormContainer>
                    <div style={{ padding: '5px' }}>
                      <span style={{ padding: '0 10px' }}><b>JotForm Fields</b></span>
                      <span style={{ padding: '0 80px', float: 'right' }}><b>Firebase Fields</b></span>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      {record?.mappedFields && record.mappedFields.length > 0 && record.mappedFields.map((field: any) => (
                        <>
                          <div style={{ padding: '20px' }}>
                            {field.formField}

                            <Select<string>
                              styles={{ float: 'right', width: '50%', height: '30px' }}
                              name={field.firebaseField}
                              options={firebaseFields}
                              onChange={(value) => {
                                handleSelect(field.formField, value)
                              }}
                            />

                          </div>
                        </>
                      ))}
                      {(!record?.mappedFields) && sampleQuestions?.map((question: any) => (
                        <>
                          <div style={{ padding: '20px' }}>
                            {question}

                            <Select<string>
                              styles={{ float: 'right', width: '50%', height: '30px' }}
                              name={question}
                              options={firebaseFields}
                              onChange={(value) => {
                                handleSelect(question, value)
                              }}
                            />

                          </div>
                        </>
                      ))}
                    </div>
                    <ButtonContainer>
                      <Returnbtn onClick={() => { setStep(step - 1); }}>Back</Returnbtn>
                      <Space />
                      <Confirmbtn type='submit' > Next</Confirmbtn>
                    </ButtonContainer>
                  </FormContainer>
                </Form>
              )}
            </Formik>
          </Container>
        </Layout>
      }
      {step === 3 &&
        <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>
          <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
            <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>Review JotForm Configuration</Title>
          </div>
          <Container>
            <Formik
              initialValues={{}}
              onSubmit={() => {
                handleMissingFields()
                setStep(step + 1)

              }}
            >
              {() => (
                <Form>
                  <span>Please Review your JotForm Mappings. if you would like to edit a mapping, Click the 'Back' button below.</span>
                  <FormContainer>
                    <Container1>
                      <span><b>JotForm Fields</b></span>
                      <span><b>Firebase Fields</b></span>

                      {mappedFields?.map((field: any) => (
                        <>
                          <span > {field.formField}  </span>
                          <span > {field.firebaseField} </span>
                        </>
                      ))}
                    </Container1>

                    <ButtonContainer>
                      <Returnbtn onClick={() => { setStep(step - 1); }}>Back</Returnbtn>
                      <Space />
                      <Confirmbtn type='submit' > Next</Confirmbtn>
                    </ButtonContainer>

                  </FormContainer>
                </Form>
              )}
            </Formik>
          </Container>
        </Layout>
      }
      {step === 4 &&
        <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>
          <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
            <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>Error - Missing Required Mappings</Title>
          </div>
          <Container>
            <Formik
              initialValues={{}}
              onSubmit={() => {
                handleSubmit()
                setStep(0)
              }}
            >
              {() => (
                <Form>
                  <span>Based on your testing type, the mappings highlighted in red are missing from your submission. Please map these FireBase Fields to a JotForm field. If field is missing, please go back to JotForm and add the fields.</span>
                  <FormContainer>
                    <Container1 style={{ padding: '5px' }}>
                      <span><b>JotForm Fields</b></span>
                      <span><b>Firebase Fields</b></span>

                      {missingFields?.map((field: any) => (
                        <>
                          <Select<string>
                            name={field.value}
                            key={field.value}
                            options={fieldsDropdown}
                            onChange={(value) => {
                              updateMissedFields(value, field.value)
                            }}
                          />

                          <span > {field.value} </span>
                        </>
                      ))}
                    </Container1>

                    <ButtonContainer>
                      <Returnbtn onClick={() => { setStep(step - 1); }}>Back</Returnbtn>
                      <Space />
                      <Confirmbtn type='submit' > Submit</Confirmbtn>
                    </ButtonContainer>

                  </FormContainer>
                </Form>
              )}
            </Formik>
          </Container>
        </Layout>
      }

    </>
  )
}
export default JotFormConfig;