import AppointmentList from "../AppointmentList/AppointmentList";
import Npdestinations from "../NpDestination/Npdestinations";
import CustomerList from "../AppointmentList/logUsCustomer";
import AppointmentsCheckin from "../../Pages/AppointmentsCheckin";
import QbenchResultsList from "../AppointmentList/QbenchResultsList";

import SlotOccupany from "../AppointmentList/slotoccupany";
import CountyReport from "../AppointmentList/CountyReport";
import WizardReport from "../AppointmentList/WizardReport";

import Dashboard from "../AppointmentList/Dashboard";
import KeyMetrics from "../AppointmentList/KeyMetrics";

import Report from "../../../shared/components/Report";
import ConsolidationReport from "../../../shared/components/ConsolidationReport";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReportIcon from "@material-ui/icons/InsertChart";

import ClientPartnerListing from "../../Pages/ClientPartner/ClientPartnerListing";
import ClientPartnerForm from "../../Pages/ClientPartner/ClientPartnerForm";
import  InsuranceReport  from "../../../shared/components/InsuranceReport";
import InsuranceAndStripeRevenue from '../../../shared/components/InsuranceAndStripeRevenue';

import  DiscountReport  from "../../../shared/components/DiscountReport";
//Manage screen
import AdminManage from "../../Pages/Manage/index";

import ChangeMobileNumber from "../../Pages/UserInfoUpdate/ChangeMobileNumber";
import AddLocations from "../../Pages/LocationAndService/AddLocation";
import Listing from "../../Pages/LocationAndService/Listing";
import AddServices from "../../Pages/LocationAndService/AddServices";

import UserRolesForm from '../../Pages/UserInfoUpdate/UserRolesForm';
import UserRoles from '../../Pages/UserInfoUpdate/UserRoles';
import RolesAccessForm from "../../Pages/UserInfoUpdate/RolesAccessForm.js";
import JotFormConfig from "../../Pages/JotFormConfig/JotFormConfig.tsx";
import RetoolForm from "../../Pages/RetoolForm/RetoolForm.tsx";
import LocationWrapper from "../../Pages/LocationAndService/LocationWrapper";
import InventoryWrapper from "../../Pages/InventoryTracker/InventoryTrackerWrapper";
import TelemedicineAppt from "../../Pages/TelemedicineAppt/ListTelemedicine";
import EmpTesting from "../../Pages/EmployeeTesting/EmployeeTesting";
import QBenchSetupPage from "../../Pages/LocationAndService/QBenchSetupPage";
import Recurring from '../AppointmentList/Recurring';
import InfoTelemedicine from '../../Pages/TelemedicineAppt/InfoTelemedicine';
import ProviderTelemedicine from "../../Pages/TelemedicineAppt/ProviderTelemedicine";
import SchedularLite from "../../Pages/SchedularLite";
import WeeklyTestingAdministrator from "../../Pages/WeeklyTestingAdministrator";
import ConfigureSchedular from "../../Pages/SchedularLite/ConfigureSchedular";
import StripeDashboard from '../../Pages/StripeDashboard/StripeDashboard';

const dashboardRoutes = [
  {
    path: "/Home",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/stripeDashboard",
    name: "Revenue Dashboard",
    icon: DashboardIcon,
    component: StripeDashboard,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/KeyMetrics",
    name: "Key Metrics",
    icon: ReportIcon,
    component: KeyMetrics,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/DayWiseAppointments",
    name: "Day Wise Appointments",
    icon: InsertInvitation,
    component: AppointmentList,
    layout: "/dashboard",
    display:true
  },
  {
    path: '/RetoolForm',
    name: 'Retool Form',
    icon: DashboardIcon,
    component: RetoolForm,
    layout: '/dashboard',
    display: true,
  },
  {
    path: '/recurring',
    name: 'Recurring PubSub',
    icon: DashboardIcon,
    component: Recurring,
    layout: '/dashboard',
    display: true,
  },
  {
    path: "/customer",
    name: "Customers",
    icon: InsertInvitation,
    component: CustomerList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/schedularlite",
    name: "Schedular Lite",
    icon: InsertInvitation,
    component: SchedularLite,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/weeklyTestingLocationAdmin",
    name: "Special Daywise Users",
    icon: InsertInvitation,
    component: WeeklyTestingAdministrator,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/JotFormConfig",
    name: "JotForm Config",
    icon: InsertInvitation,
    component: JotFormConfig,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/checkin",
    name: "Appointments Checkin",
    icon: InsertInvitation,
    component: AppointmentsCheckin,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/manage",
    name: "Manage",
    icon: InsertInvitation,
    component: AdminManage,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/weeklyreport",
    name: "Weekly Report",
    icon: InsertInvitation,
    component: Report,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/result",
    name: "Results",
    icon: InsertInvitation,
    component: QbenchResultsList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/slotoccupany",
    name: "Slot Occupancy",
    icon: InsertInvitation,
    component: SlotOccupany,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/consolidationreport",
    name: "Consolidation Report",
    icon: InsertInvitation,
    component: ConsolidationReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/countyreport",
    name: "County Report",
    icon: InsertInvitation,
    component: CountyReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/reports",
    name: "Reports",
    icon: InsertInvitation,
    component: WizardReport,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/updateLocation",
    name: "location",
    icon: InsertInvitation,
    component: AddLocations,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/addLocation/:id",
    name: "location",
    icon: InsertInvitation,
    component: AddLocations,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/addLocation/lims",
    name: "location",
    icon: InsertInvitation,
    component: QBenchSetupPage,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/locations",
    name: "Location",
    icon: InsertInvitation, 
    component: LocationWrapper,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/employeeTesting",
    name: "Employee Testing",
    icon: InsertInvitation,
    component: EmpTesting,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/inventoryTracker",
    name: "Inventory Tracker",
    icon: InsertInvitation, 
    component: InventoryWrapper,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/telemedicineAppt",
    name: "Telemedicine Appointment",
    icon: InsertInvitation, 
    component: TelemedicineAppt,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/telemedicineInfo/:id",
    name: "Telemedicine Info",
    icon: InsertInvitation, 
    component: InfoTelemedicine,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/providertelemedicine",
    name: "Provider Telemedicine",
    icon: InsertInvitation, 
    component: ProviderTelemedicine,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/npdestions",
    name: "NP Destinations",
    icon: InsertInvitation, 
    component: Npdestinations,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/addService",
    name: "location",
    icon: InsertInvitation,
    component: AddServices,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/services",
    name: "Services",
    icon: InsertInvitation,
    component: LocationWrapper,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/tenant",
    name: "Tenants",
    // icon: FormatAlignJustifyIcon,
    icon: InsertInvitation,
    component: ClientPartnerListing,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/tenantform",
    name: "Tenants",
    // icon: FormatAlignJustifyIcon,
    icon: InsertInvitation,
    component: ClientPartnerForm,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/insuranceReport",
    name: "Services",
    icon: InsertInvitation,
    component: InsuranceReport,
    layout: "/dashboard",
    display: false
    },
    {
        path: "/insuranceAndStripeRevenu",
        name: "Services",
        icon: InsertInvitation,
        component: InsuranceAndStripeRevenue,
        layout: "/dashboard",
        display: false
      },
  {
    path: "/discountreport",
    name: "Services",
    icon: InsertInvitation,
    component: DiscountReport,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/ChangePhoneNumber",
    name: "Change Mobile Number",
    icon: InsertInvitation,
    component: ChangeMobileNumber,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/Users",
    name: "Users",
    icon: InsertInvitation,
    component: UserRoles,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/UserRolesForm",
    name: "User Roles Form",
    icon: InsertInvitation,
    component: UserRolesForm,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/RolesAccessForm",
    name: "Roles Access Form",
    icon: InsertInvitation,
    component: RolesAccessForm,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/configureSchedularLite",
    name: "Roles Access Form",
    icon: InsertInvitation,
    component: ConfigureSchedular,
    layout: "/dashboard",
    display: false
  },
 
 
];

export default dashboardRoutes;
