import * as React from 'react';
import { Formik, Form, FormikErrors, Field } from 'formik';

import { Container, Grid, Paper, Typography, IconButton, Snackbar, Backdrop, CircularProgress, Card,Button } from '@material-ui/core';

import { Link, useHistory, useParams } from 'react-router-dom';
import Checkbox from '../../../shared/components/form/Checkbox';
import Input from '../../../shared/components/form/Input';
import Select from '../../../shared/components/form/Select';
import { stylesForListing } from './styles';
import { DatepickerField } from '../../../shared/components/Datepicker';
import MultipleSelect from './Components/MultipleSelect';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MuiAlert from '@material-ui/lab/Alert'
import { useAdminState, useAdminDispatch } from './../../provider'
import { slotstartdatefor30, slotduration, slotcapacity, finalvalue, getEditDetails } from './Model';
import { loadstate, loadCountry, getQbenchCustomers, getAssayList, addHolidays } from './API';
import { firebase } from '../../../../firebase';
import styled from 'styled-components';
// import Table from 'material';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { is } from 'date-fns/locale';

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
gap: 20%;
justify-content: space-between;
justify-content: left;
};
@media (min-width: 450px) { flex-direction: row; }
`;

const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;

const Returnbtn = styled.button
`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
`;

const Buttons = styled.button`
  width: 100%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #1890ff;
  color: #fff;
  border-radius: 4px;
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;
// const Button = styled.div`
// border: "2px solid #87cefa";
// width: "60%";
// height: "40px";
// color:"#1e90ff";
// `;
// const Buttons = styled.div`
// border: "2px solid";
// width: "60%";
// height: "43px";
// background-color: "#1e90ff";
// color:"	#FFFFFF";
// `;

const AddBtn = styled.div`
width: 72%;
border: 0;
align-items: center;
text-align: center;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
color: #1e90ff;
border-radius: 4px;
`;

const ErrorText = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  color: red;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:300px;
`;

type QBenchSetupPage = {
	isNewLocation?: boolean,
    onBack?: () => void, 
    onNext?: () => void
}

const Holidays: React.FC<QBenchSetupPage> = ({isNewLocation, onNext, onBack}) => {
  const { GetServiceData, getID, locationData, adminSlots ,editLocation} = useAdminState();
  const { SetID, updateLocation ,goToPrevStep, goToInitialStep  } = useAdminDispatch();
  const history = useHistory();
  const params: any = useParams()
  function Alert(props: any) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [alertbox, setalertbox] = React.useState<{ type: string, msg: string, open: true | false }>({ type: '', msg: '', open: false })
  const [slotstype, setslotstype] = React.useState<string>('30 MIN')
  const [submitbtn, setsubmitbtn] = React.useState<true | false>(false)
  const [traveltype, settraveltype] = React.useState<any>(false);
  const [testtype, settesttype] = React.useState<any>(false);
  const [checkweek, setcheckweek] = React.useState<any>(false);
  const [visibleWeek, setVisibleWeek] = React.useState<any>(false);
  const [visible, setvisible] = React.useState<any>({ touch: false, value: [] });
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [update, setupdate] = React.useState<true | false>(false)
  const [loader, setloader] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [state, setstate] = React.useState<any>([]);
  const [country, setcountry] = React.useState<any>([]);
  const [useeffect, setuseeffect] = React.useState<boolean>(true);
  const [submit, setsubmit] = React.useState<boolean>(false)
  const [ID, setID] = React.useState<any>(null);
  const [assayDropdown, setAssayDropdown] = React.useState<any>();
  const [customerDropdown, setCustomerDropdown] = React.useState<any>();
  const [cPartnerDropdown, setCPartnerDropdown] = React.useState<any>();
  const [step, setStep] = React.useState<number>(Number(params?.id) || 1)
  const [tenentTypes, setTenentType] = React.useState<any>(false);
  const [holidays, setHolidays] = React.useState<any>([]);
  const [myTest, setMyTest] = React.useState<any>();
  const [slotsList, setSlotsList] = React.useState<any>();
  const [testTypeDropdown, setTestTypeDropdown] = React.useState<any>([]);

  const timeZones = ['HST', 'AKST', 'PST', 'MST', 'CST', 'EST'];

  const timeZoneDropdown: any = []
  timeZones.map((item) => { timeZoneDropdown.push({ label: item, value: item }) });

  React.useEffect(() => {
		let data = { testtype: { rapidTest: true, expressTest: true, standardTest: true } }
		updateLocation(data);  
	if (useeffect) {
	  getData()
	  setHolidays([
		{ id: 1, startDay: '', endDay: '', reason: '' },
	  ])
	  let weekMapArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
	  let standard: any = {}, rapid: any = {}, express: any = {}, antigen: any = {}, standardNp: any = {}, expressNp: any = {}, rapidNp: any = {};
	  weekMapArr.map((day) => {
		standard[`${day}`] = {};
		rapid[`${day}`] = {};
		express[`${day}`] = {};
		antigen[`${day}`] = {};
		expressNp[`${day}`] = {}
		standardNp[`${day}`] = {}
		rapidNp[`${day}`] = {}

	  })
	  if(editLocation?.holidays){
		setHolidays(editLocation?.holidays)
	}
	  setSlotsList({ standard: standard, rapid: rapid, express: express, antigen: antigen, expressNp: expressNp, standardNp: standardNp, rapidNp: rapidNp })
	  if (adminSlots.length > 0 && state.length > 0) {
		if (!(getID === '' || getID === null || getID === undefined)) {
		  setloader(true)
		  let newdata: any = GetServiceData.filter((e: any) => e.id === getID)[0];
		  let editdata = getEditDetails(newdata, adminSlots);
		  let newstatename = '';
		  state.map((e: any) => {
			if (e.value === newdata?.state)
			  newstatename = e.label;
		  })

		  let data = {
			locationname: newdata.name ? newdata.name : '',
			cPartnerID: { label: newdata.cPartnerID ? newdata.cPartnerID : '', value: newdata.cPartnerID ? newdata.cPartnerID : '' },
			visibleSince: newdata?.visibleSince ? newdata?.visibleSince : new Date(),
			address1: newdata.address1 ? newdata.address1 : '',
			address2: newdata.address2 ? newdata.address2 : '',
			city: newdata.city ? newdata.city : '',
			state: { label: newdata.state ? newstatename : '', value: newdata.state ? newdata.state : '' },
			zipcode: newdata.zipcode ? newdata.zipcode : '',
			// googlemapshortlink: newdata.loactionlink ? newdata.loactionlink : '',
			traveltypes: { domestic: newdata.isDomestic ? true : false, international: newdata.isInternational ? true : false },
			tenentTypes: { travel: newdata.isTravel ? true : false, generel: newdata.isGeneral ? true : false, school: newdata.isSchool ? true : false, community: newdata.isCommunity ? true : false, work: newdata.isWork ? true : false },
			testtype: {
			  rapidTest: newdata.rapidTest ? true : false, expressTest: newdata.hasVipSlots ? true : false, standardTest: true, antigenTest: newdata.isAntigen ? true : false,
			  standardNpTest: newdata.isStandardNp ? true : false, rapidNpTest: newdata.isRapidNp ? true : false, expressNpTest: newdata.isExpressNp ? true : false
			},
			sitestatus: { label: newdata.isOpened ? 'Open' : 'Closed', value: newdata.isOpened ? true : false },
			delayopenstartdate: newdata.startDate ? newdata.startDate : new Date(),
			qbenchcustomer: { label: newdata.qbenchCustomerId ? newdata.qbenchCustomerId : '', value: newdata.qbenchCustomerId ? newdata.qbenchCustomerId : '' },
			qbenchassay: { label: newdata.qbenchAssayId ? newdata.qbenchAssayId : '', value: newdata.qbenchAssayId ? newdata.qbenchAssayId : '' },
			country: { label: newdata.country ? newdata.country : '', value: newdata.country ? newdata.country : '' },
			medicalDirector: newdata.medicalDirector ? newdata.medicalDirector : '',
			timezone: newdata.timezone ? newdata.timezone : '',
			weeklyVisibleDay: {
			  tue: newdata?.weeklyVisibleDays?.includes(2),
			  mon: newdata?.weeklyVisibleDays?.includes(1),
			  sun: newdata?.weeklyVisibleDays?.includes(0),
			  wed: newdata?.weeklyVisibleDays?.includes(3),
			  thu: newdata?.weeklyVisibleDays?.includes(4),
			  fri: newdata?.weeklyVisibleDays?.includes(5),
			  sat: newdata?.weeklyVisibleDays?.includes(6)
			},
			dayFromWeeklyVisibleNumber: weekMapArr[newdata?.weeklyVisibleNumber?.weekDay],
			weekNumber: String(newdata?.weeklyVisibleNumber?.weekNumber),
			...editdata
		  }
		  stepTwoSetup(data)
		  console.log("abc",editdata.slots)
		  setSlotsList(editdata?.slots);
		  if(!isNewLocation){
			updateLocation(data);
		  }
		  
		  setuseeffect(false);
		  setloader(false);
		  setupdate(true);
		  setID(newdata.id);
		  if (newdata.holidays) setHolidays(newdata.holidays);
		}
	  }
	  else {
			let data = { testtype: { rapidTest: true, expressTest: true, standardTest: true } }
		updateLocation(data);	  
	  }
	}
  }, [adminSlots, state])
  const methodfortesttype = () => {
	settesttype(true)
  }

  const methodforcheckweek = () => {
	setcheckweek(true)
  }
  const methodforcheckweek2 = () => {
	setVisibleWeek(true)
  }
  const methodfortraveltype = () => {
	settraveltype(true)
  }
  const methodforTenentType = () => {
	setTenentType(true)
  }
  const clickMe = () => {
	setHolidays([...holidays, { id: holidays.length + 1, startDay: '', endDay: '', reason: '' }])

  }
  const setInput = (event: any, type: any, index: number) => {
	holidays[index - 1][`${type}`] = event?.target?.value;
	setHolidays(holidays);
  }

  const handleHoursOfOperation = (value: any, test: string, day: string, type: string) => {
	const slots = slotsList;
	console.log("before update", slots);
	slots[test][day][type] = value;
	console.log(slots);
	setSlotsList(slots);
  }


  const validateform = (value: any) => {
	let error: FormikErrors<any> = {};
	if (!value.state.value) error.state = 'Required Field';
	if (value.country.value === '') error.country = 'Required Field';
	// if (value.acceptance.value === '') error.acceptance = 'Required Field';
	if (value.sitestatus.value === '') error.sitestatus = 'Required Field';
	if (value.qbenchcustomer === '') error.qbenchcustomer = 'Required Field';
	if (((submitbtn || tenentTypes) && !(value?.tenentTypes?.travel || value?.tenentTypes?.general || value?.tenentTypes?.school || value?.tenentTypes?.community || value?.tenentTypes?.work))) error.tenentTypes = 'Choose';
	if (((submitbtn || traveltype) && !(value?.traveltypes?.domestic || value?.traveltypes?.international))) error.traveltype = 'Choose';
	if ((submitbtn || testtype) && !(value?.testtype?.expressTest || value?.testtype?.rapidTest || value?.testtype?.standardTest || value?.testtype?.antigenTest || value?.testtype?.expressNpTest || value?.testtype?.standerdNpTest || value?.testtype?.rapidNpTest)) error.testtype = 'Choose';
	if (!value.traveltypes.domestic && !value.traveltypes.international) error.traveltypes = 'Required Field';
	return error;
  }
  const classes = stylesForListing();
  const handleClose = () => {
	setalertbox({ open: false, type: '', msg: '' })
  }
  React.useEffect(() => {
	setloader((previous) => {
	  return true;
	});
	loadstate().then((response: any) => {
	  setstate(response)
	})
	loadCountry().then((response: any) => {
	  let data: any = [];
	  response.map((res: any) => {
		let newdata = {
		  label: res?.label,
		  value: res?.label
		}
		data.push(newdata);
	  })
	  setcountry(data)
	  setloader((previous) => false);
	})
  }, [])
  const getData = async () => {
	const snaps = await firebase.firestore().collection('clientpartner').get();
	const responseData = snaps.docs.map((doc: any) => doc.data());
	console.log(responseData);
	const dropdown: any = []
	responseData.map((data) => {
	  if (data.cPartnerID)
		dropdown.push({ label: data.cPartnerID, value: data.cPartnerID })
	});
	setCPartnerDropdown(dropdown)
	await getQbenchCustomers().then((response: any) => {
	  const data = [];
	  if (response?.data?.result) {
		for (const customer of response.data.result?.data?.data)
		  if (!customer.deleted)
			data.push({ label: customer.customer_name, value: customer.id })

		setCustomerDropdown(data);
	  }
	})
	await getAssayList().then((response: any) => {
	  const data: any = [];
	  if (response?.data?.result?.assayList) {
		for (const assay of response.data.result.assayList?.data)
		  data.push({ label: assay.method, value: assay.id })
		setAssayDropdown(data);
	  }
	})
  }
  function handleChange(event: any) {
	console.log(event.target.value);
  }
  function setbuttonLoading() {
    setTimeout(() => {
        setBtnLoading(false)
    }, 30000);
}
  const nextHandler = (holiday : any): void => {
	const holidays = locationData.holidays;
	for(const record of holiday){
	holidays.push(record);
	}	
	updateLocation({...locationData, holidays})
	if(onNext) onNext();
}

const backHandler = (): void => {
	if(onBack) onBack();
}

  React.useEffect(() => {
	updateLocation({ visible: personName })
  }, [personName])
  const stepTwoSetup = (value: any) => {
	let dropdown = [];

	if (value.testtype.standardTest) dropdown.push({ label: "Standard Test", value: "standard" });
	if (value.testtype.rapidTest) dropdown.push({ label: "Rapid Test", value: "rapid" });
	if (value.testtype.expressTest) dropdown.push({ label: "Express Test", value: "express" });
	if (value.testtype.antigenTest) dropdown.push({ label: "Antigen Test", value: "antigen" });
	if (value.testtype.standerdNpTest) dropdown.push({ label: "Standard NP Test", value: "standardNp" });
	if (value.testtype.expressNpTest) dropdown.push({ label: "Express NP Test", value: "expressNp" });
	if (value.testtype.rapidNpTest) dropdown.push({ label: "Rapid NP Test", value: "rapidNp" });
	setTestTypeDropdown(dropdown)
  }

  const [error, setError] = React.useState<string>('');
  
	return (
	  <Container maxWidth='md' style={{paddingLeft: '0'}}>
		<Snackbar open={alertbox.open} onClose={handleClose}
		  anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		  }}>
		  <Alert onClose={handleClose} severity={alertbox.type}>
			{alertbox.msg}
		  </Alert>
		</Snackbar>
		<Grid container justify='flex-start'>
		  <Grid xl={8} lg={12} md={10} sm={12} xs={12} item >
			<Link to='/dashboard/locations' className={classes.backbtn}>
			  {/* <IconButton color="primary">
				<ArrowBackIcon fontSize='large' />
			  </IconButton> */}
			</Link>
			<Paper elevation={0}>
			<Formik
				initialValues={{ ...locationData }}
				// validate={validateform}
				onSubmit={async (record, { resetForm }) => {
					setBtnLoading(true)
				  if (step === 2 && !update) {
					stepTwoSetup(record)
					setStep(step + 1)
				  }
				  else {
				   
					let err: any = false;
					for (let item of holidays) {
						let hasErr = false;
						if( item.startDay === '' || item.startDay === undefined || item.startDay === null ) {
							hasErr = true;
							setError('start day is missing');
							console.log('checking', 2)
						}
						else if( item.endDay === '' || item.endDay === undefined || item.endDay === null) {
							hasErr = true;
							setError('End day is missing');
							console.log('checking', 3)
						}
						else if( item.reason === '' || item.reason === undefined || item.reason === null) {
							hasErr = true;
							setError('Reason is missing');
							console.log('checking', 4)
						}

						if(hasErr === true) {
							err = true;
							setBtnLoading(false)
							break;
						}
					}
					if(!err && !isNewLocation){
						setError('');
						record['slotConfig'] = slotsList;
						console.log('holidays', holidays,record)
						record['holidayList'] = holidays;
						// addHolidays
						let data: any= {
							blockingDates: holidays, 
							locationId :editLocation.qbenchCustomerId
						}

						await addHolidays(data).then((resdata: any) => {
							if (resdata.status) {
								console.log('succ', resdata);
								history.push('/dashboard/locations');
								window.location.reload();
							}
						})

						console.log(slotsList);
						setsubmit(true);
						setloader(true)
						setsubmit(false);
						settraveltype(false);
						setcheckweek(false);
						setVisibleWeek(false);
						settesttype(false);
						updateLocation(record, true);
						resetForm();
						setloader(false);
						setsubmitbtn(false);
						setBtnLoading(false)
						goToInitialStep();
					}else if(!err && isNewLocation){
						setError('');
						nextHandler(holidays)
						setbuttonLoading()
					}
					
				  }
				}}
			  >
				  
				<Form >
			 <>
					{ isNewLocation ? <Typography style={{width: '122%'}} variant="subtitle1" color="initial" className={classes.fontweight700}>
					  <h2><b>Add New Location - Holidays</b></h2>
					</Typography>
					: <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
					  Location Name: {editLocation?.name}
					</Typography>}

					{isNewLocation ? <Typography style={{width:'122%'}} variant="subtitle2" color="initial"><b>Holidays*</b><br></br>
					  Customers will not be able to book any time slots on the following dates. This applies to all test types.
					  <br></br></Typography>
					  : <Typography variant="subtitle2" color="initial"><b>Holidays*</b><br></br>
					  Customers will not be able to book any time slots on the following dates. This applies to all test types.
					  <br></br></Typography>}
					{ loader ?
					<Box>
					 	<CircularProgress/> 
				 	</Box>
					:
					<>
						<br />
						<br />
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
								<TableCell>Start Date</TableCell>
								<TableCell>End Date</TableCell>
								<TableCell>Notes</TableCell>
								<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{holidays.map((item: any) => {
								return (
									<TableRow>
									<TableCell><input type="date" defaultValue={item.startDay} key={item.id} onChange={(e) => setInput(e, 'startDay', item.id)} /></TableCell>
									<TableCell><input type="date" defaultValue={item.endDay} key={item.id} onChange={(e) => setInput(e, 'endDay', item.id)}></input></TableCell>
									<TableCell><input key={item.id} defaultValue={item.reason} onChange={(e) => setInput(e, 'reason', item.id)}></input></TableCell>
									<TableCell>
										{isNewLocation ? <Buttons className='btn-common btn-alter Mui-selected' type="button" onClick={() => 
												setHolidays(holidays.filter((e: any)=> e.id !== item.id))
											}
										>
												delete
										</Buttons>
										: <Buttons type="button" onClick={() => 
											setHolidays(holidays.filter((e: any)=> e.id !== item.id))
										}
									>
											delete
									</Buttons>
								}
									</TableCell>
									</TableRow>
								)
								})}
							</TableBody>
							<TableBody>
								<TableRow>
									<TableCell> { isNewLocation ? <AddBtn onClick={clickMe} style={{color:"#6558F5"}}>Add Row</AddBtn> : <AddBtn onClick={clickMe}>Add Row</AddBtn>  }</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</>
					
					}

					
					{/* <AddBtn onClick={clickMe} >Add Row</AddBtn> */}
					<br /><br />

					{error && <ErrorText >{error}</ErrorText>}
					
					 {isNewLocation ? <ButtonContainer style={{ gap: '3%'}}>
					  <Returnbtn className='btn-common' onClick={() => backHandler()}> Back </Returnbtn>
					  <Space />
					  {console.log('btn', btnLoading)}
					  <Confirmbtn className='btn-common btn-alter Mui-selected' type='submit' > {btnLoading ? 'Processing...' : 'Submit'} </Confirmbtn>
					  
						</ButtonContainer>
					 : <ButtonContainer>
					  <Returnbtn onClick={() => goToPrevStep() }> Back </Returnbtn>
					  <Space />
					  <Confirmbtn type='submit' onClick={() => { setsubmitbtn(false) }} > {btnLoading ? 'Processing...' : 'Submit'} </Confirmbtn>
					</ButtonContainer>}
					{/* <button type='submit' onClick={()=>{setsubmitbtn(true)}} >{!update?'conform':'update'} </button> */}
				  </>
				
				</Form>
			  </Formik>
			</Paper>
		  </Grid>
		</Grid>
	  </Container>
	);
}
export default Holidays;