import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../../../shared/providers/schedule/provider';
import { Button } from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import { Breakpoints } from '../../../../../dictionaries';
import FormLabel from '../../../../shared/components/form/FormLabel';
import Input from '../../../../shared/components/form/Input';
import Modal from '../../../../shared/components/Modal';
import { cloneDeep } from 'lodash';
import { useAdminDispatch, useAdminState } from '../../../provider';
import {  updateAppointmentdata } from '../../../api';
import { config } from '../../../../../config';
import { parse, format } from 'date-fns';




const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

    
interface Props {
    showModal: boolean;
    closeModal: () => void;
  }
  
  const ChangeLocationModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const { Editappointments } = useAdminState();
  const classroom = Editappointments !== null ? Editappointments[0]?.classroom:''
  const appointmentdata:any = Editappointments !== null ? Editappointments[0]:null;

  const handleSearchfunction = (value:any) => {
    appointmentdata.classroom = value;
    appointmentdata.minors.map((e:any)=>e.birthDate=e.birthDate
    ? format(e.birthDate, config.dateFormat)
    : appointmentdata.birthDate);
    updateAppointmentdata(appointmentdata)
    appointmentdata.minors.map((e:any)=>e.birthDate= e.birthDate !== null && e.birthDate !== undefined && (Object.keys(e.birthDate).length)>0 
    ? parse(e.birthDate, config.dateFormat, new Date())
    : new Date())
    closeModal();     
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Formik
        initialValues={{classroom:classroom} }
        onSubmit={(value) => {

          handleSearchfunction(value?.classroom ?? '');
          
        }}
      >
        {(value) => (
          <Form>
            <Content>
              <Title>Change Class Room</Title>
                <FormLabel label="Class Room">
                    <Input
                        name="classroom"
                        value={value?.values?.classroom ? value?.values?.classroom : ''}
                    />
                </FormLabel>
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => { 
                    closeModal();                    
                  }}
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit">
                  Change
                </Button>
              </ButtonsContainer>
            </Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLocationModal;
