import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import {
    MoreOutlined
} from '@ant-design/icons';
import Cancel from './Cancel'
interface IProps {
    record?: any;
    getEmpTesting?: () => void;
    isEmpTest?: boolean;
    handleSearch:()=>void;
}

function MoreActions({
    record,
    getEmpTesting,
    isEmpTest,
    handleSearch,
}: IProps) {
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (visibleStatus: boolean) => {
        setVisible(visibleStatus);
    };
    useEffect(() => {
        setVisible(false);
    }, [record]);
    const menu = (record: any) => (
        <>
            <Menu>
                
                <Menu.Item key="0">
                    <Cancel
                        isEmpTest={isEmpTest}
                        deletes={record}
                        getEmpTesting={getEmpTesting}
                        handleSearch={handleSearch}
                    />
                </Menu.Item>
               
            </Menu>
        </>
    );
    return (
        <Dropdown
            overlay={menu(record)}
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button type="link" icon={<MoreOutlined />} />
        </Dropdown>
    );
}

export default MoreActions;
