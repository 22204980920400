export const cPartnerID =  [
    { value: 'DISABLE', label: 'DISABLE'},
    { value: 'BEL001', label: 'Belmont-Redwood Shores School District' },
    { value: 'COM001', label: "Community Sites" },
    { value: 'KEN001', label: "Kern Health Systems" },
    { value: 'LLT001', label: "Las Lomitas School District" },
    { value: 'PCMA001', label: "Worksitelabs" },
    { value: 'POR001', label: "Portola Valley School District" },
    { value: 'SCS001', label: "San Carlos School District" },
    { value: 'SEQ001', label: "Sequoia Union High School District" },
    { value: 'BGC001', label: "Boys and Girls Club"},
    { value: 'SSF001', label: "South San Francisco School District" },
    { value: 'WSL001', label: "Worksite Labs - General"},
];
export const pVerifyCount = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
];
export const WEEK = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
];
export const appointmentType = [
    { value: 'Onsite only', label: 'Onsite only' },
    { value: 'Virtual only', label: 'Virtual only' },
    { value: 'Onsite or Virtual', label:'Onsite or Virtual'}
];
export const TESTINGOPTIONS = [
    { value: 'Walk up', label: 'Walk up' },
    // { value: 'Schedule', label: 'Schedule' },
    { value: 'Multiple appointments', label: 'Multiple appointments' },
    { value: 'Walk up or Multiple appointments', label: 'Walk up or Multiple appointments' },
    { value: 'Weekly Testing', label: 'Weekly Testing' }
    
];
export const TIME = [
	{ "value": "06:00 AM", "label": "06:00 AM" },
	{ "value": "06:15 AM", "label": "06:15 AM" },
	{ "value": "06:30 AM", "label": "06:30 AM" },
	{ "value": "06:45 AM", "label": "06:45 AM" },
	{ "value": "07:00 AM", "label": "07:00 AM" },
	{ "value": "07:15 AM", "label": "07:15 AM" },
	{ "value": "07:30 AM", "label": "07:30 AM" },
	{ "value": "07:45 AM", "label": "07:45 AM" },
	{ "value": "08:00 AM", "label": "08:00 AM" },
	{ "value": "08:15 AM", "label": "08:15 AM" },
	{ "value": "08:30 AM", "label": "08:30 AM" },
	{ "value": "08:45 AM", "label": "08:45 AM" },
	{ "value": "09:00 AM", "label": "09:00 AM" },
	{ "value": "09:15 AM", "label": "09:15 AM" },
	{ "value": "09:30 AM", "label": "09:30 AM" },
	{ "value": "09:45 AM", "label": "09:45 AM" },
	{ "value": "10:00 AM", "label": "10:00 AM" },
	{ "value": "10:15 AM", "label": "10:15 AM" },
	{ "value": "10:30 AM", "label": "10:30 AM" },
	{ "value": "10:45 AM", "label": "10:45 AM" },
	{ "value": "11:00 AM", "label": "11:00 AM" },
	{ "value": "11:15 AM", "label": "11:15 AM" },
	{ "value": "11:30 AM", "label": "11:30 AM" },
	{ "value": "11:45 AM", "label": "11:45 AM" },
	{ "value": "12:00 PM", "label": "12:00 PM" },
	{ "value": "12:15 PM", "label": "12:15 PM" },
	{ "value": "12:30 PM", "label": "12:30 PM" },
	{ "value": "12:45 PM", "label": "12:45 PM" },
	{ "value": "01:00 PM", "label": "01:00 PM" },
	{ "value": "01:15 PM", "label": "01:15 PM" },
	{ "value": "01:30 PM", "label": "01:30 PM" },
	{ "value": "01:45 PM", "label": "01:45 PM" },
	{ "value": "02:00 PM", "label": "02:00 PM" },
	{ "value": "02:15 PM", "label": "02:15 PM" },
	{ "value": "02:30 PM", "label": "02:30 PM" },
	{ "value": "02:45 PM", "label": "02:45 PM" },
	{ "value": "03:00 PM", "label": "03:00 PM" },
	{ "value": "03:15 PM", "label": "03:15 PM" },
	{ "value": "03:30 PM", "label": "03:30 PM" },
	{ "value": "03:45 PM", "label": "03:45 PM" },
	{ "value": "04:00 PM", "label": "04:00 PM" },
	{ "value": "04:15 PM", "label": "04:15 PM" },
	{ "value": "04:30 PM", "label": "04:30 PM" },
	{ "value": "04:45 PM", "label": "04:45 PM" },
	{ "value": "05:00 PM", "label": "05:00 PM" },
	{ "value": "05:15 PM", "label": "05:15 PM" },
	{ "value": "05:30 PM", "label": "05:30 PM" },
	{ "value": "05:45 PM", "label": "05:45 PM" },
	{ "value": "06:00 PM", "label": "06:00 PM" }
]
export const QRCodeLink = 'https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=https://api--dev.web.app/?location=';
export const locationLink = "https://api--dev.web.app/?location=";
export const initialSetUpforSchedule = {"schedularLite": {
    "countryCodeSuggesion": true,
    "testTypes": [
        {
            "isExpress": false,
            "standardTest": false,
            "isDuretionVisible": false,
            "title": "RAPID PCR TEST",
            "id": 1,
            "hidden": false,
            "isCostLabelVisibile": false,
            "isAntigen": false,
            "isPanel":false,
            "testDuration": "Results within 1.5 hours",
            "testType": "rapid",
            "isRapid": true,
            "disabled":false,
            "prices": 250
        },
        {
            "prices": 150,
            "isExpress": true,
            "title": "EXPRESS PCR TEST",
            "testDuration": "Results within 12 hours",
            "testType": "express",
            "standardTest": false,
            "isDuretionVisible": false,
            "hidden": false,
            "isCostLabelVisibile": false,
            "isRapid": false,
            "disabled":false,
            "isPanel":false,
            "id": 2,
            "isAntigen": false
        },
        {
            "testDuration": "Results within 24 hours",
            "isAntigen": false,
            "id": 3,
            "isRapid": false,
            "isExpress": false,
            "testType": "standard",
            "isDuretionVisible": false,
            "hidden": false,
            "isPanel":false,
            "title": "STANDARD PCR TEST",
            "standardTest": true,
            "disabled":false,
            "prices": 90,
            "isCostLabelVisibile": false
        },
        {
            "prices": 75,
            "standardTest": false,
            "isDuretionVisible": false,
            "isAntigen": true,
            "isCostLabelVisibile": false,
            "isPanel":false,
            "title": "ANTIGEN TEST",
            "testType": "antigen",
            "disabled":false,
            "isRapid": false,
            "hidden": false,
            "testDuration": "Results within 30 minutes",
            "isExpress": false,
            "id": 4
        },
        {
            "prices": 150,
            "standardTest": false,
            "isDuretionVisible": false,
            "isAntigen": false,
            "isPanel":true,
            "isCostLabelVisibile": false,
            "title": "Flu A&B/Covid-19	",
            "testType": "panel",
            "disabled":false,
            "isRapid": false,
            "hidden": false,
            "testDuration": "Results within 30 minutes",
            "isExpress": false,
            "id": 5
        }
    ],
    "verification": {
        "email": false,
        "phone": false
    },
    "address": {
        "address": false,
        "drivingLicence": false
    },
    "isActive": false,
    "logo":['https://firebasestorage.googleapis.com/v0/b/wsl-multitenancy.appspot.com/o/New%20Logo.png?alt=media&token=cbcb8b48-8f97-4202-af7e-c5244168fdb5',''],
    "payment": {
        "insurance": false,
        "credit": false
    },
    "pVerifyCount":"DISABLE",
    "hippaInfo":"DISABLE",
    "testingFor":'Walk up',
    "appointmentType":"Onsite only",
    "calendlyURL":""
}};

export const newLocationFormItems = [
    {
        label: "Location Name",
        required: true,
        hidden: false,
        type: "text",
        name: "name"
    },
    {
        label: "Address 1",
        required: true,
        hidden: false,
        type: "text",
        name: "address1"
    },
    {
        label: "Address 2",
        required: false,
        hidden: false,
        type: "text",
        name: "address2"
    },
    {
        label: "City",
        required: true,
        hidden: false,
        type: "text",
        name: "city"
    },
    {
        label: "State",
        required: true,
        hidden: false,
        type: "text",
        name: "state"
    },
    {
        label: "Country",
        required: true,
        hidden: false,
        type: "text",
        name: "country"
    },
    {
        label: "Zip Code",
        required: true,
        hidden: false,
        type: "text",
        name: "zipcode"
    },
    {
        label: "County",
        required: false,
        hidden: false,
        type: "text",
        name: "county"
    },
    {
        label: "cPartnerID",
        required: true,
        hidden: false,
        type: "select",
        list:cPartnerID,
        name: "cPartnerID"
    },
    {
        label: "Location Link",
        required: true,
        hidden: false,
        type: "url",
        name: "locationlink"
    },
    {
        label: "QR Code Sent",
        required: true,
        hidden: false,
        type: "select",
        list:[
            { value: 'SHOW', label: 'SHOW' },
            { label: 'HIDE', value: 'HIDE' },
        ],
        name: "isQRCodeSend",
        
    },
    {
        label: "Qbench Customer ID",
        required: true,
        hidden: false,
        type: "number",
        name: "qbenchCustomerId",
        
    },
    {
        label: "Qbench Rapid Assay ID",
        required: false,
        hidden: false,
        type: "number",
        name: "qbenchRapidAssayId",
        
    },
    {
        label: "Qbench Express Assay ID",
        required: false,
        hidden: false,
        type: "number",
        name: "qbenchExpressAssayId"
    },
    {
        label: "Qbench Standard Assay ID",
        required: false,
        hidden: false,
        type: "number",
        name: "qbenchStandardAssayId",
    },
    {
        label: "Qbench Antigen Assay ID",
        required: false,
        hidden: false,
        type: "number",
        name: "qbenchAntigenAssayId"
    },
    {
        label: "Time Zone",
        required: true,
        hidden: false,
        type: "text",
        name: "timezone"
    },
    {
        label: "CLIA",
        required: true,
        hidden: false,
        type: "text",
        name: "CLIA"
    },
    {
        label: "GMT",
        required: true,
        hidden: false,
        type: "text",
        name: "GMT"
    },
]

export const numberFormat = [ "qbenchCustomerId", "qbenchRapidAssayId", "qbenchExpressAssayId", "qbenchStandardAssayId", "qbenchAntigenAssayId" ]