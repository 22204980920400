import React from 'react';

function RetoolForm() {
  return (
    <>
      <iframe
        title="retool"
        src="https://worksitelabs.retool.com/embedded/public/cb37a121-5438-4474-92b2-55e33767402c"
        width="100%"
        height="800px"
      />
    </>
  );
}

export default RetoolForm;