import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { format } from 'date-fns';
import { Form } from 'antd';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card.js";
import { Wrapper } from '../../../shared/components/styled';
import Loader from '../../../shared/components/Loader';
import { cloudRunPost } from '../../../shared/api';
import { generatecountyReport,getCountyReport } from '../../api';
import { config } from '../../../../config';

const ButtonWrapper = styled.div`
  margin-bottom: 15px;
  max-width: 1200px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none"
        },
        typo: {
            paddingLeft: "25%",
            marginBottom: "40px",
            position: "relative"
        },
        note: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            bottom: "10px",
            color: "#c0c1c2",
            display: "block",
            fontWeight: "bold",
            fontSize: "13px",
            lineHeight: "13px",
            left: "0",
            marginLeft: "20px",
            position: "absolute",
            width: "260px"
        },
        cardCategoryWhite: {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
    }),
);

function CountyReport() {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(format(new Date(), 'LL/dd/yyyy'));
    const [Allconsolidationdetails, setAllconsolidationdetails] = useState([]);
    const [Positiveconsolidationdetails, setPositiveconsolidationdetails] = useState([]);
    const [Negativeconsolidationdetails, setNegativeconsolidationdetails] = useState([]);
    const [loading, setLoading] = useState(false);

    // const handleSearch = async (result: string) => {
    //     let response;
    //     let countyReportData;
    //     try {
    //         setLoading(true);
    //         // const results:any =await getMysqlCountyReport({date:selectedDate,result})
    //         let params = {
    //             fromDate: selectedDate,
    //             result: result
    //         }
    //         response = await cloudRunPost(config.cloudRunApi.getMysqlCountyReport, params);
    //         if (response) {
    //             countyReportData = response.data
    //         } else {
    //             console.log('Response is not received')
    //         }

    //         if (countyReportData.length > 0) {
    //             generatecountyReport(countyReportData);
    //             setLoading(false);
    //         } else {
    //             setLoading(false)
    //             alert("No data on the selected date")
    //         }
    //     } catch (error) {
    //         setLoading(false)
    //     }
    // };

    const handleSearch = async () => {
        setLoading(true);
        getCountyReport(selectedDate).then((response: any)=>{
            setAllconsolidationdetails(response.Allconsolidationdetails)
            setPositiveconsolidationdetails(response.Positiveconsolidationdetails)
            setNegativeconsolidationdetails(response.Negativeconsolidationdetails)
            setLoading(false);
            return true;
        })
    };

    // const handleSearch = async () => {
    //     setLoading(true);

    //     const allReport: any = await cloudRunPost(config.cloudRunApi.getMysqlCountyReport,
    //         { fromDate: selectedDate, result: "" });
    //     setAllconsolidationdetails(allReport.data)
        
    //     const positiveReport: any = await cloudRunPost(config.cloudRunApi.getMysqlCountyReport,
    //         { fromDate: selectedDate, result: "DETECTED" });
    //     setPositiveconsolidationdetails(positiveReport.data)

    //     const negativeReport: any = await cloudRunPost(config.cloudRunApi.getMysqlCountyReport,
    //         { fromDate: selectedDate, result: "NOT DETECTED" });
    //     setNegativeconsolidationdetails(negativeReport.data)

    //     setLoading(false)
    // }


    const onreportdetails = (data: any) => {
        generatecountyReport(data)
    };

    // if (loading) {
    //     return <Loader />;
    //   }

    return (
        <div>
            <Form style={{ marginLeft: "5%" }}>
                <Form.Item label="Slot Date" name="date">
                    <AntDDatePicker
                        style={{ width: '20%' }}
                        disabled={false}
                        defaultValue={new Date(selectedDate)}
                        showToday
                        onChange={(newDate: any) =>

                            setSelectedDate(format(newDate, 'LL/dd/yyyy'))

                        }
                        allowClear={false}
                    />
                    <span style={{ marginLeft: "5%" }}>
                        <Button variant="contained" style={{ backgroundColor: "#00acc1", color: "#fff" }}
                            onClick={handleSearch}

                        >
                            Search
                        </Button>

                    </span>
                </Form.Item>

            </Form>

            <Wrapper>

                <Card>
                    <CardHeader color="primary">
                        <h2 className={classes.cardTitleWhite}>COUNTY REPORT</h2>
                    </CardHeader>
                    {!loading ? <CardBody>
                        <div style={{
                            paddingLeft: "20%",
                            marginBottom: "40px",
                            position: "relative",
                            marginTop: "30px"
                        }}>
                            <div className={classes.note}></div>

                            <div style={{ display: "flex", flex: 1, width: "80%", justifyContent: "space-between" }}>
                                <ButtonWrapper>
                                    <Button variant="contained" onClick={() => onreportdetails(Allconsolidationdetails)}
                                    >
                                        Download All Report
                                    </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                    <Button variant="contained" onClick={() => onreportdetails(Positiveconsolidationdetails)}
                                    >
                                        Download Positive Report
                                    </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                    <Button variant="contained" onClick={() => onreportdetails(Negativeconsolidationdetails)}
                                    >
                                        Download Negative Report
                                    </Button>
                                </ButtonWrapper>
                            </div>
                        </div>



                    </CardBody> : <Loader />}
                </Card>

            </Wrapper>

        </div>
    );
}

export default CountyReport;