import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { getApiData } from './API';
import { stylesForListing } from './styles';
import { useAdminDispatch } from './../../provider'
import { Button,Card,Layout,Space,Table,Typography } from 'antd';
import { getAdminConfigDaywise } from '../../api';
import { styles } from '../../components/AppointmentList/styles';
import { locationTableColumn } from './../../../admin/components/AppointmentList/constants';
import { MoreOutlined } from '@ant-design/icons';
import MoreActions from '../../components/AppointmentList/components/MoreActions';
import { orderBy } from 'lodash';
import { NonceProvider } from 'react-select';
const { Content } = Layout;
const { Title } = Typography;

const Listing : React.FC = (handleSearch:any) => {

	const { SetServiceData, SetID,updateLocation, setLocation} = useAdminDispatch();
	SetID(null);
	const history = useHistory();
	const path = history.location.pathname.split('/');
	const [getdata, setgetdata] = React.useState(false);
    const [isDataUpdated, setIsDataUpdated] = React.useState(false);
	const [rowdata, setrowdata] = React.useState<any>([]);
	const [location, setlocation] = React.useState(path[2]==='locations')
	const [loading, setLoading] = React.useState(false);
	const classes = stylesForListing();
    const getWalkUpLink = (link: any) =>{
        return <><a href={link} target='_blank'>{link}</a></>;
    }
	React.useEffect(() => {
        SetID('');
        let url = location ? 'getLocations' : 'getServices';
        getApiData(url)
            .then((res: any) => {
                if (res.status === 200) {
                    let newdata = res.data.locationservice.map((e: any) => {
                        if (location) {
                            let visible: any =
                                e.data.startDate?._seconds !== undefined &&
                                e.data.startDate?._nanoseconds !== undefined
                                    ? (e.data.startDate?._seconds +
                                          e.data.startDate?._nanoseconds *
                                              0.00000001) *
                                      1000
                                    : '';
                            let start: any =
                                e.data.visibleSince?._seconds !== undefined &&
                                e.data.visibleSince?._nanoseconds !== undefined
                                    ? (e.data.visibleSince?._seconds +
                                          e.data.visibleSince?._nanoseconds *
                                              0.00000001) *
                                      1000
                                    : '';
                            let newdata = {
                                ...e.data,
                                visibleSince: new Date(visible),
                                startDate: new Date(start),
                            };
                            let devLink = 'https://wsl-multitenancy-dev-ac13b.web.app/bookappointment/walkup/'+e.data.qbenchCustomerId;
                            //let prodLink = 'https://www.schedulecovidtesting.com/bookappointment/walkup/'+e.data.qbenchCustomerId;
                            let cPartnerID = e.data.cPartnerID;
                            let walkUpLink = getWalkUpLink(devLink)
                            let travelType = cPartnerID ==='WSL001' && !!e.data.isTravel && !!e.data.isGeneral ? "Travel and General flow" : cPartnerID ==='WSL001' && !!e.data.isTravel && !!!e.data.isGeneral ? "Travel flow" 
							: cPartnerID ==='WSL001' && !!!e.data.isTravel && !!e.data.isGeneral ? "General flow" : cPartnerID === 'COM001' ? 'Community flow' : cPartnerID === 'SFO001' ? 'SFO airport flow' : cPartnerID === 'SSF001' ? 'School flow - SSFSD'
							 : cPartnerID === "PCMA001" ? "School flow - PCMA" : cPartnerID === "SEQ001" ? "School flow - SUHSD" : cPartnerID === "LLT001" ? "School flow - LLESD" : cPartnerID === "POR001" ? "School flow - PVSD" : cPartnerID === "SCS001" ? "School flow - SCSD" : cPartnerID === "BEL001" ? "School flow - BRSSD" : "";
                            return {
                                id: e.newid,
                                ...newdata,
                                travelType,
                                walkUpLink,
                            };
                        } else return { id: e.newid, ...e.data };
                    });
                    SetServiceData(newdata);
					let orderedData = orderBy(newdata, ['name'], ['asc']);
					// let filteredOrderData = orderedData.filter( (ele: any, ind: any) => ind === orderedData.findIndex( (elem: any) => elem.name === ele.name))
					// let cpIdArray: any=[];
					// filteredOrderData.map((ele: any)=>{
					// 	let cpIdArray: any=[];
					// 	if(!!ele.cPartners){
					// 		ele.cPartners.map((cpID: any)=>{
					// 			if(cpID === 'WSL001'){
					// 				if(!!ele.isTravel && !!!ele.isGeneral){
					// 					cpIdArray.push("Travel flow")
					// 				} else if (!!!ele.isTravel && !!ele.isGeneral){
					// 					cpIdArray.push("General flow")
					// 				} else {
					// 					cpIdArray.push("Travel flow, General flow")
					// 				}
					// 			} else if(cpID === 'COM001'){
					// 				cpIdArray.push("Community flow")
					// 			}
					// 			 else if(cpID === 'SFO001'){
					// 				cpIdArray.push("SFO airport flow")
					// 			}
					// 			 else{
					// 				cpIdArray.push("School flow")
					// 			}
					// 		})
					// 		let filteredTestType = cpIdArray.filter( (ele: any, ind: any) => ind === cpIdArray.findIndex( (elem: any) => elem === ele))
					// 		let count :number = 0;
					// 		ele.travelType = filteredTestType.map((cp: any)=>{
					// 			count++;
					// 			return count === filteredTestType.length ?  cp  : cp + ',' + ' ';
					// 		} )
					// 	} 
					// })
                    setrowdata(orderedData);
                    setgetdata(true);
                    getAdminConfigDaywiseColumn();
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
            console.log('isDataUpdated :::::', isDataUpdated);

			const content = document.getElementsByClassName('makeStyles-mainPanel1-3');
		if(content && content.length) {
			content[0].scrollTop = 0;
		}
            
    }, [isDataUpdated]);

	const editid=(id:string, page: number)=>{
		SetID(id);
		location ? history.push(`/dashboard/addLocation/${page}`) : history.push('/dashboard/addService')        
	}

	// Table location item
	const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]); 

	
	const renderActionsMenu = (action: any, record: any) => (
        <MoreActions
            record={record}
            handleDrawerToggle={setIsDataUpdated}
            isLocation={true}
            handleSearch={handleSearch}
            // editid={editid}
        />
    );

	async function getAdminConfigDaywiseColumn() {
		setLoading(true);
		const tableColumnData = locationTableColumn(renderActionsMenu);
		setRenderTableColumn(tableColumnData);
		let response = await getAdminConfigDaywise({
		  email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
		});
  
		if (response.data.status == 200) {
		  if (response.data.data.length > 0) {
			if (
			  response.data.data[0].daywiseconfig.hideandshowcolumn.length > 0
			) {
			  setRenderTableColumn(
				locationTableColumn(renderActionsMenu)
			  );
			  setLoading(false);
			}
		  } else {
			const tableColumnData = locationTableColumn(renderActionsMenu);
			setRenderTableColumn(tableColumnData);
			setLoading(false);
		  }
		} else {
		  const tableColumnData = locationTableColumn(renderActionsMenu);
		  setRenderTableColumn(tableColumnData);
		  setLoading(false);
		}
	  }

	React.useEffect(() => {
		
		getAdminConfigDaywiseColumn();
		
	  }, []);
	

	return (
		<Layout
			style={{
			marginTop: '100px',
			backgroundColor: '#FFF',
			border: 'none',
			
			}}
		>
		<div
			style={{
				borderRadius: '3px',
				background: 'white',
				margin: '0px 25px',
				marginTop: '-100px',
				border: 'none',
			}}
		>
		  <Title level={3} style={{ margin: '24px 16px', color: '#black', textAlign:'center', fontWeight: 400, fontSize: '40px' }}>
			Locations Management
		  </Title>
		</div>
		<Content style={styles.content}>
			<Space direction="vertical" style={styles.spaceWidth} size="middle">
				<Card bodyStyle={styles.cards} style={{border:'none'}}>
					<Button
						type="primary"
						style={{height:'40px'}}
						// loading={loading}
						onClick={() => { 
							setLocation(null)
							location ? history.push('/dashboard/addLocation/1') : history.push('/dashboard/addService');updateLocation('',true)}
						}
					>
						+ Add a new location
					</Button>
				</Card>
				<Card style={{border:'none'}}>
					<div style={{ padding: '25px 35px' }}>
						<Table
						size="small"
						columns={renderTableColumn as any}
						bordered
						dataSource={(rowdata as any) || []}
						//  rowSelection={{
						//    type: selectionType,
						//    selectedRowKeys,
						//    ...rowSelectionData,
						//  }}
						pagination= {false}
						onChange={(value) => {
							// console.log('valueappt', value);
						}}
						loading={loading}
						scroll={{ x: 1500 }}
						rowKey={(row: any) => row.key}
						//  rowSelection={rowSelection}
						//  rowClassName={(record: any) => record.testType === 'Rapid Test'? classes.rapid: record.testType === 'Express Test'? classes.express:record.testType==='Antigen Test'?classes.antigen:classes.standard
						//  }
							/>
					</div>
				</Card>
			</Space>
		</Content>
	  </Layout>
	);
}
export default Listing;

{/* <Container maxWidth='xl'>
<Grid container spacing={1} justify='center'>
  <Grid lg={8} xl={8} md={10} sm={12} xs={12} item>
	  <Typography variant="h4" color="initial" className={classes.heading}>
		  {location ?'Locations Management':'Service Offering Management'}
	  </Typography>
	  <Box>
			  
			  <button  className={classes.button} onClick={() => { location ? history.push('/dashboard/addLocation/1') : history.push('/dashboard/addService');updateLocation('',true)}}>
			  {location ?'+ Add a new location':'+ Add A New Service Offering'}
		  </button>
			  {location ? !getdata ? (<>
				  <Paper elevation={0} className={classes.textcenter}>
					  Please Wait...
				  </Paper>
			  </>) 
			  :
			  rowdata 
			  ?(
				  <Card>
			   <Table
			   size="small"
			   columns={renderTableColumn as any}
			   dataSource={(rowdata as any) || []}
			  //  rowSelection={{
			  //    type: selectionType,
			  //    selectedRowKeys,
			  //    ...rowSelectionData,
			  //  }}
			   onChange={(value) => {
				 // console.log('valueappt', value);
			   }}
			   loading={false}
			   scroll={{ x: 1500 }}
			   rowKey={(record: any) => record.key}
			  //  rowSelection={rowSelection}
			  //  rowClassName={(record: any) => record.testType === 'Rapid Test'? classes.rapid: record.testType === 'Express Test'? classes.express:record.testType==='Antigen Test'?classes.antigen:classes.standard
			  //  }
			 />
			 </Card>
			  )
				  // rowdata ? rowdata.map((row: any) => (
				  //     <Paper elevation={0} className={classes.borderonly}>
				  //         <Grid container direction="row" justify='space-around' alignItems="center" >
				  //             <Grid xl={4} lg={4} md={4} sm={5} xs={9} item>
				  //                 <Typography variant="h6" color="initial">
				  //                     {row.name}
				  //                 </Typography>
				  //                 <Typography variant="subtitle2" color="initial">
				  //                     {row.address1}
				  //                 </Typography>
				  //                 <Typography variant="subtitle2" color="initial">
				  //                     {row.CLIA}
				  //                 </Typography>
				  //                 <Typography variant="subtitle2" color="initial">
				  //                     {row.performingfacility}
				  //                 </Typography>
				  //             </Grid>
							  
				  //             <Grid xl={4} lg={4} md={4} sm={4} xs={6} alignItems="flex-end" item>
				  //                 <Button variant='outlined' className={classes.editbtn} onClick={() => editid(row.id,1)}>
				  //                     Edit General
				  //                 </Button>
				  //             </Grid>

				  //             <Grid xl={3} lg={3} md={3} sm={3} xs={6} alignItems="flex-end" item>
				  //                 <Button variant='outlined' className={classes.editbtn} onClick={() => editid(row.id, 2)}>
				  //                 Edit Timeslots
				  //                 </Button>
				  //             </Grid>
									 
				  //         </Grid>
							  
				  //     </Paper>
				  // )) 
				  
				  : (<>
					  <Paper elevation={0} className={classes.textcenter}>
						  No Data Found...
					  </Paper>
					  </>
					  )
				  :
				  !getdata ? (<>
					  <Paper elevation={0} className={classes.textcenter}>
						  Please Wait...
					  </Paper>
				  </>) :
					  rowdata ? rowdata.map((row: any) => (
						  <Paper elevation={0} className={classes.borderonly}>
							  <Grid container direction="row" justify="space-between">
								  <Grid xl={9} lg={9} md={9} sm={10} xs={12} item>
									  <Typography variant="h6" color="initial">
										  {row.name}
									  </Typography>
									  <Typography variant="subtitle2" color="initial">
										  Price : ${row.price}
									  </Typography>
									  <Typography variant="subtitle2" color="initial">
										  Turnaround Time: {row.time}
									  </Typography>
								  </Grid>
								  <Grid xl={2} lg={2} md={2} sm={2} xs={12} alignItems='center' item>
									  <button  className={classes.editbtn} onClick={() => editid(row.id, 0)}>
										  Edit
									  </button>
								  </Grid>
							  </Grid>
						  </Paper>
					  )) : (<>
						  <Paper elevation={0} className={classes.textcenter}>
							  No Data Found...
						  </Paper>
		   </>)}
	  </Box>
  </Grid>
</Grid>
</Container> */}


