import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import { colors } from '../../../../../styles/colors';
import { Button } from '../../../../shared/components/styled';
import { useAdminState, useAdminDispatch } from '../../../provider';
import { getAppointmentdata } from '../../../api';
const Container = styled.div`
    max-width: 700px;
`;

const Title = styled.h2`
    color: ${colors.darkBlue};
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 240px;
    margin: 30px -5px 0;

    & > * {
        margin: 0 5px;
    }
`;

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const ChangeCalendlyModel: React.FC<Props> = ({ showModal, closeModal }) => {
    const { params, appointments,Editappointments } = useAdminState();
    const {setEditAppointment} = useAdminDispatch();
    let [url, setUrl] = useState('');
    const [loading, setloading] = React.useState<boolean>(false);
    console.log('KC appointments', appointments);
    console.log('KC EditAppoinment', Editappointments);
    const getAppointmentdataCall = async (confirmationId:any, close=false) =>{
        setloading(true)
        if(!confirmationId)
        closeModal()
        getAppointmentdata({ confirmationId })
        .then((response: any) => {
            if (response.data.length > 0) {
                setEditAppointment(response.data)
                setUrl(response.data[0]?.calendlyResponse.rescheduleURL);
                if(close)
                closeModal()
            } else {
                closeModal()
            }
            setloading(false)
        })
        .catch(() => {
            setloading(false)
        });
    }
    const sleep = async(time=500)=>new Promise((res)=>setTimeout(() => {res(true)},time))
    let value = Editappointments?.map((a) => a.confirmationId).toString();
    useEffect(() => {
        getAppointmentdataCall(value);
    },[])
    const handleSubmit = async() => {
        await sleep(3000)
        getAppointmentdataCall(value, true);
    }
    return (
        <Modal open={showModal} onClose={closeModal}>
            <Container>
                {url ? (
                    <iframe
                        src={url}
                        // allowFullScreen
                        style={{
                            height: '100vh',
                            width: '100%',
                            border:'none'
                        }}
                    />
                ) : (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Loading...
                    </div>
                )}
                <ButtonsContainer>
                    <Button
                        libraryType="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        libraryType="primary"
                        size="sm"
                        type="submit"
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {loading ? 'Processing...' : 'Change'}
                    </Button>
                </ButtonsContainer>
            </Container>
        </Modal>
    );
};

export default ChangeCalendlyModel;
