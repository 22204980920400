/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    format,
    startOfDay,
    isEqual,
    isBefore,
    isAfter,
    set,
    subHours,
    subMinutes,
    parse,
    isSameDay,
    differenceInDays,
    differenceInHours,
    addMinutes,
    addDays,
    addYears,
} from 'date-fns';
import { Field, FieldProps } from 'formik';

import { colors } from '../../../styles/colors';
import { ErrorText } from './styled';
import { AnyObject, Appointment } from '../models';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import {
    subscribeOnAppointmentsUpdate,
    reserveSlot,
    releaseReservation
} from '../../shared/providers/schedule/api';
import {
    pluralize,
    getPeriodsFromSchedule,
    timeDifferenceFilter,
    timeToPeriod,
    weekAndWeekDayConvertion,
} from '../../../utils';
import { getMinAvailableDate, getMaxAvailableDate } from './Schedule';
import Datepicker from './Datepicker';
import { cloneDeep, has, includes } from 'lodash';
import {
    useStepsState,
    useStepsDispatch,
} from '../../shared/providers/schedule/provider';
import { useAdminState } from '../../admin/provider';
import { useManageDispatch, useManageState } from '../../shared/providers/manage/provider';
import { useSharedDispatch } from '../provider';
import is from 'date-fns/esm/locale/is/index.js';
import { useAdminDispatch } from '../../admin/provider';
import { TIME } from '../../admin/Pages/SchedularLite/schedularUtils';
import { getNewSlots } from '../../admin/api';
const periods = getPeriodsFromSchedule(config.schedule);

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 23px;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const SlotsContainer = styled.div`
  background: ${colors.blue8};
  padding: 30px;
  position: relative;

  @media (min-width: ${Breakpoints.lg}px) {
    padding: 80px 60px;
  }
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -5px 0 30px;

  & > * {
    margin: 5px 0;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    flex-direction: row;
    margin: 0 -17px 20px;

    & > * {
      margin: 0 17px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0px;
  }
`;

const ChooseSlotBtn = styled.button`
  background: ${({ selected }: { selected: boolean }) =>
        selected ? colors.blue : colors.white};
  color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.blue};
  border: 1px solid ${colors.blue};
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.15s ease-in-out;
  padding: 10px 30px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: ${({ selected }: { selected: boolean }) =>
        selected ? 'default' : 'pointer'};

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 10px 50px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 10px 80px;
    width: 350px;
  }

  &:disabled {
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.grey};
    border: 1px solid
      ${({ selected }: { selected: boolean }) =>
        selected ? colors.blue : colors.grey};
    cursor: default;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${Breakpoints.sm}px) {
    bottom: -20px;
  }
`;

const DatePickerContainer = styled.div`
  display: block;
  line-height: 1.5;

  @media (min-width: ${Breakpoints.md}px) {
    display: ${({ alwaysShowDatePicker }: { alwaysShowDatePicker?: boolean }) =>
        alwaysShowDatePicker ? 'block' : 'none'};
  }
`;

const ChangeDateBtn = styled.div`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.blue};
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 2px;
`;

const SelectedDate = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 10px;
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Shimmer = styled.div`
  height: 13px;
  width: 120px;
  border-radius: 2px;
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 400px 100%;
`;

interface Props {
    alwaysShowDatePicker?: boolean;
    appointment: Appointment;
    onFieldUpdate: (update: AnyObject) => void;
    onChangeDate?: (data: any) => void;
    isAdmin?: boolean;
    isManage?: any;
    isWalkUpTest?: boolean;
}

const Slots: React.FC<Props> = ({
    alwaysShowDatePicker,
    appointment,
    onFieldUpdate,
    onChangeDate,
    isAdmin,
    isManage,
    isWalkUpTest
}) => {
    // const periods = getPeriodsFromSchedule(config.schedule);

    const [loading, setLoading] = useState(false);
    const [slots, setSlots] = useState<any>(periods);

    const {
        date,
        slot,
        minors,
        departureDateAndTime,
        isExpressSameDayTest,
        isRapidTest,
        location,
        reservationId,
        reservationCount,
        testSelection,
        rescheduleReservationId,
        isAntigen,
        isSolvHealth,
        isSchedularLite,
        uniqueID
    } = appointment;
    const numberOfGuests = minors.length + 1;
    let { slotsList } = useStepsState();
    const { updateFormValues, updateSlotsList } = useStepsDispatch();
    const { adminSlots } = useAdminState();
    const { rescheduleslots } = useManageState();
    const { updateAdminSlots } = useAdminDispatch();
    const { updateRescheduleSlots } = useManageDispatch();
    const [blockOutDays, setBlockOutDays] = useState(
        JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays') ?? []))
    );
    
    function checkIsWeekDay(date: any) {
        let validDay: any = "";
        let day: any = new Date(date).getDay();
        let isaddOutDays: any =
        location !== undefined && location?.cPartnerID === 'SEQ001'
        ? includes([config.qbenchCustomerId.PostIsolationRapidAntigenTest], location?.qbenchCustomerId)
            ? ["02/25/2022"]
            : includes([config.qbenchCustomerId.SequoiaHighSchool], location?.qbenchCustomerId)
                        ? ["09/05/2022", "12/29/2022", "01/05/2023"]                       
                : []
        : 
        location?.cPartnerID === 'KEN001'
        ? includes([config.qbenchCustomerId.KernHealthSystems], location?.qbenchCustomerId)
        ? [ "09/06/2022"]
        :[]
    :[];
        let additionalOutDays: any = includes(isaddOutDays, format(new Date(date), config.dateFormat))
        // allowed same day booking for sfo airport
        // if (location?.qbenchCustomerId == config.qbenchCustomerId.SFO_Airport) {
        //     location.blockOutDays.push(format(new Date(), config.dateFormat))
        // }
        let blockOutDays: any = location?.blockOutDays !== undefined ? includes(location?.blockOutDays, format(new Date(date), config.dateFormat)) : false;
        let isDayFilter: any = testSelection !== "general" ? location?.isDayFilter !== undefined ? location?.isDayFilter : false : false
        let isWeeklyFlag: any = testSelection !== "general" ? location?.isWeeklyFlag !== undefined ? location?.isWeeklyFlag : false : false
        let weeklyVisible: any = testSelection !== "general" ? {
            weeklyVisibleDays: location?.weeklyVisibleDays !== undefined ? location?.weeklyVisibleDays : [0, 1, 2, 3, 4, 5, 6],
            weeklyVisibleNumber: location?.weeklyVisibleNumber !== undefined ? location?.weeklyVisibleNumber : { weekDay: 0, weekNumber: 0 },
        }
            : {}

        if (isWeeklyFlag !== undefined && isWeeklyFlag) {
            let addDay: any = weekAndWeekDayConvertion({
                weeklyVisible: weeklyVisible,
                date: date,
            });
            if (isDayFilter) {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (additionalOutDays) {
                    validDay = true;
                }
                else if (new Date(date).getDate() === new Date(addDay).getDate()) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            } else {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (additionalOutDays) {
                    validDay = true;
                }
                else if (includes(weeklyVisible.weeklyVisibleDays, day)) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            }
        } else if (blockOutDays !== undefined && blockOutDays) {
            validDay = false;
        } else if (additionalOutDays) {
            validDay = true;
        } else {
            validDay = true
        }

        //Added the speical conditaional for SSF001 // can be removed in the feature 
        // if (cPartnerID === "SSF001" &&
        //     includes([config.qbenchCustomerId.BadenAdultSchool,
        //     config.qbenchCustomerId.ElCaminoHighSchool,
        //     config.qbenchCustomerId.WestboroughMiddleSchool], location?.qbenchCustomerId) &&
        //     includes(["01/28/2022", "01/29/2022", "01/30/2022"], format(new Date(date), config.dateFormat))) {
        //     validDay = true;
        // }

        return validDay
    }

    const locationId = location?.qbenchCustomerId;

    const [selectedDate, setSelectedDate] = useState(
        isWalkUpTest ? new Date() : date ? parse(date, config.dateFormat, new Date()) : new Date()
    );
    const {updateSharedSlots, updateBlockOutDays } = useSharedDispatch();

    const getNewAPI = async () => {
        updateBlockOutDays([]);
        const response = await getNewSlots({
            testType: isRapidTest ? 'rapid' : isExpressSameDayTest ? 'express' : isAntigen ? 'antigen' : 'standard',
            locationId: appointment.location?.qbenchCustomerId,
            cPartnerID : location?.cPartnerID
        })
        updateSlotsList(response.data);
         updateSharedSlots(response.data);
        updateRescheduleSlots(response.data);
        updateAdminSlots(response.data);
        let arr: Number[] = [];
        const update = response.data ?? [];
        for (let i = 0; i < 7; i++) {
            update[update.length - 1 ?? 0]?.slotsByDay[i]?.length !== 0 &&
                arr.push(i);
        }
        if(uniqueID)
        {
            localStorage.setItem('blockOutDays',JSON.stringify([0,1,2,3,4,5,6]))        
            updateBlockOutDays([0,1,2,3,4,5,6])
            setBlockOutDays([0,1,2,3,4,5,6]);   
        }else{
            localStorage.setItem('blockOutDays',JSON.stringify(arr))        
            updateBlockOutDays(arr)
            setBlockOutDays(arr);   

        }
    }
    useEffect(() => {
        localStorage.setItem('blockOutDays', JSON.stringify([]));
        getNewAPI()
    }, [isExpressSameDayTest, location, locationId, isRapidTest, isAntigen])
    useEffect(() => {
        let arr: Number[] = [];
        const update = slotsList ?? [];
        for (let i = 0; i < 7; i++) {
            update[update.length - 1 ?? 0]?.slotsByDay[i]?.length !== 0 &&
                arr.push(i);
        }
        localStorage.setItem('blockOutDays', JSON.stringify(arr));
        updateBlockOutDays(arr);  
        setBlockOutDays(arr)      
    }, [slotsList]);
    useEffect(() => {
        setLoading(true);

        //Validate the persons count and make the customer select the slots again
        async function updateReserveData() {
            setLoading(true);
            updateFormValues({
                reservationId: '',
                slot: undefined,
            });
            setLoading(false);
        }

        if (
            reservationId !== undefined &&
            reservationId !== '' &&
            reservationCount !== numberOfGuests
        ) {
            updateReserveData();
        }

        let unsubscribe: any;

        async function getSlots() {
            try {
                if (!!testSelection && 
                    //testSelection !== 'flight' && //commemnted for removal of dep.time
                    date == null) {
                    updateFormValues({ date: format(new Date(), config.dateFormat) });
                }
                if (date && locationId) {
                    const parsed = parse(date, config.dateFormat, new Date());
                    const dayAsNumber = new Date(parsed).getDay();
                    const todayDate = format(new Date(), config.dateFormat);
                    let updatedPeriods: any = [];
                    if (!isAdmin && !isManage) {
                        slotsList.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest
                                ? 'rapid'
                                : isExpressSameDayTest
                                    ? 'express'
                                    : isAntigen ? 'antigen'
                                        :
                                        'standard';
                            if (item.testType == testType) {
                                if(location?.isSameDay && !location?.isSameDay[testType] && date == todayDate){
                                    updatedPeriods = [];
                                } else {
                                if (isWalkUpTest) {
                                    updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                                } else {
                                    item.slotsByDay[`${dayAsNumber}`]
                                        .map((res: any) => {
                                            if (res.index < 20) {
                                                updatedPeriods.push({
                                                    ...res,
                                                    startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                                })
                                            }
                                        })
                                }
                            }
                            }
                        });
                    } else if (isManage) {
                        rescheduleslots.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest
                                ? 'rapid'
                                : isExpressSameDayTest
                                    ? 'express'
                                    :
                                    isAntigen ? 'antigen'
                                        : 'standard';
                            if (item.testType == testType) {
                                if(location?.isSameDay && !location?.isSameDay[testType] && date == todayDate){
                                    updatedPeriods = [];
                                } else {
                                if (isWalkUpTest) {
                                    updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                                } else {
                                    item.slotsByDay[`${dayAsNumber}`]
                                        .map((res: any) => {
                                            if (res.index < 20) {
                                                updatedPeriods.push({
                                                    ...res,
                                                    startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                                })
                                            }
                                        })
                                }
                            }
                            }
                        });
                    } else if (isAdmin) {

                        adminSlots.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest
                                ? 'rapid'
                                : isExpressSameDayTest
                                    ? 'express'
                                    :
                                    isAntigen ? 'antigen'
                                        : 'standard';
                            if (item.testType == testType) {
                               if (isWalkUpTest) {
                                    updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.SanJose && (new Date(date) > new Date("04/23/2022"))){
                                    updatedPeriods=[];

                            //Atlanta-ID Tech Molecular
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.ProudBird && (new Date(date) >= new Date("11/20/2022"))){
                                    updatedPeriods=[];
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.Atlanta_ID_Tech_Molecular){
                                    const periods = getPeriodsFromSchedule({
                                        startTime: dayAsNumber=== 6?'11:00a':'9:00a',
                                        endTime: '5:00p',
                                        periodDuration: 30,
                                        availability: 10,
                                        });
                                     
    
                                        updatedPeriods = periods;
                               }
                               else if (location?.qbenchCustomerId === config.qbenchCustomerId.ArroyoUpperSchool && (new Date(date) <= new Date("10/05/2022"))) {

                                   const periods = getPeriodsFromSchedule({
                                       startTime: '3:30p',
                                       endTime: '6:30p',
                                       periodDuration: 30,
                                       availability: 26,
                                   });

                                   updatedPeriods = periods
                               }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date==='04/21/2022'){
                                //Woodside High School
                                    const periods = getPeriodsFromSchedule({
                                    startTime: '9:30a',
                                    endTime: '5:30p',
                                    periodDuration: 30,
                                    availability: 50,
                                    });

                                    updatedPeriods = periods;
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && date === '04/28/2022'){
                                //Carlmont High School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 50,
                                    });

                                    updatedPeriods = periods;
                                }
                               else if (location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && ["12/29/2022", '01/05/2023'].includes(date)) {
                                   console.log('Executed')
                                   const periods = getPeriodsFromSchedule({
                                       startTime: '09:30a',
                                       endTime: '5:30p',
                                       periodDuration: 30,
                                       availability: 13,
                                   });

                                   updatedPeriods = periods
                               } // // sequoia and woodside school start
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '09/05/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:00a',
                            endTime: '2:00p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }//Kern Health systems
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems && date === '09/06/2022') {
                        const periods = getPeriodsFromSchedule({
                            startTime: '8:00a',
                            endTime: '12:00p',
                            periodDuration: 15,
                            availability: 15,
                        });

                        updatedPeriods = periods;
                    }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy && date==='06/01/2022'){
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 22,
                                    });
                                    updatedPeriods = periods;
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date==='04/26/2022'){
                                    //Sequoia High School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '2:30p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });
            
                                    updatedPeriods = periods;
                                }// // sequoia and woodside school start
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '09/05/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:00a',
                            endTime: '2:00p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }//Kern Health systems
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems && date === '09/06/2022') {
                        const periods = getPeriodsFromSchedule({
                            startTime: '8:00a',
                            endTime: '12:00p',
                            periodDuration: 15,
                            availability: 15,
                        });

                        updatedPeriods = periods;
                    }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date==='04/29/2022'){
                                    //Woodside High School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '3:30p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });
            
                                    updatedPeriods = periods;
                                } 
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date==='05/06/2022'){
                                    //Woodside High School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });
                                    updatedPeriods = periods;
                                } 
                                // sequoia and woodside school end
                                 else {
                                    item.slotsByDay[`${dayAsNumber}`]
                                        .map((res: any) => {
                                            updatedPeriods.push({
                                                ...res,
                                                startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                            })
                                        })
                                }
                            }
                        });
                    } else {
                        updatedPeriods = [];
                    }

                    unsubscribe = subscribeOnAppointmentsUpdate(
                        { date, locationId, isRapidTest, isWalkUpTest },
                        (result: any) =>
                            setSlots(
                                updatedPeriods.reduce((acc: any, period: any, i: any) => {
                                    const a = acc;
                                    if (isWalkUpTest)
                                        if (period?.available)
                                            return [{ available: parseInt(period?.available) - parseInt(result?.data?.appointmentsPerPeriod ? result?.data?.appointmentsPerPeriod : 0) }];
                                        else
                                            return []
                                    let starttime: any = period.startTime;
                                    var dateNow = new Date();
                                    const startDateOfLocation:any = location?.startDate
                                    var startDate: any = typeof startDateOfLocation === 'object' && '_seconds' in startDateOfLocation ? format(new Date(startDateOfLocation?._seconds*1000),config.dateFormat) : startDateOfLocation;
                                    const startTime = set(starttime, {
                                        year: parsed.getFullYear(),
                                        date: parsed.getDate(),
                                        month: parsed.getMonth(),
                                    });
                                    // commented for removal of dep.time
                                    // if (!departureDateAndTime && testSelection === 'flight') {
                                    //     return a;
                                    // }

                                     //Check theweek day filter for slot days
                                     let filterWeekDay: any = checkIsWeekDay(startTime);
                                    if (testSelection === 'flight') {
                                        // const parsedDeparture = parse(
                                        //     departureDateAndTime,
                                        //     config.dateTimeFormat,
                                        //     new Date()
                                        // );
                                        let DateValidation =
                                            location?.blockOutDays !== undefined
                                                ? includes(
                                                      location.blockOutDays,
                                                      format(
                                                          startTime,
                                                          config.dateFormat
                                                      )
                                                  )
                                                    ? false
                                                    : true
                                                : true;

                                        if (
                                            isBefore(
                                                dateNow,
                                                addMinutes(startTime, 15)
                                            ) &&
                                            DateValidation
                                        ) {
                                            if ((isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {
                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result &&
                                                            result.data
                                                                .appointmentsPerPeriod?.[
                                                                period.index
                                                            ]) ||
                                                            0),
                                                });
                                            }
                                        }
                                    } else {
                                        if (isBefore(dateNow, startTime)) {
                                            if (
                                                testSelection !== 'flight' &&
                                                (isAfter(
                                                    parsed,
                                                    new Date(startDate)
                                                ) ||
                                                    isEqual(
                                                        parsed,
                                                        new Date(startDate)
                                                    ))
                                            ) {
                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result &&
                                                            result.data
                                                                .appointmentsPerPeriod?.[
                                                                period.index
                                                            ]) ||
                                                            0),
                                                });
                                            }
                                            else if(isSolvHealth == true) {
                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result &&
                                                            result.data.appointmentsPerPeriod?.[
                                                            period.index
                                                            ]) ||
                                                            0),
                                                });
                                            }
                                        }
                                    }
                                   
                                    return a;
                                }, [])
                            )
                    );

                    await new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 800);
                    });
                }
                setTimeout(()=>{
                    setLoading(false);
                },1000)

            } catch (e) {
                console.error(e);

                setLoading(false);
            }
        }
        setSelectedDate(
            date ? parse(date, config.dateFormat, new Date()) : new Date()
        );

        new Promise<void>((resolve) => {
            setTimeout(() => {
                getSlots();
            }, 1000);
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [
        date,
        departureDateAndTime,
        isExpressSameDayTest,
        location,
        locationId,
        isRapidTest,
        isAntigen,
        slotsList,
        adminSlots,
        rescheduleslots
    ]);
    // blocking next day after some time period
    let bufferDate: any[] = []
    let currentTime = new Date();
    let Hour = currentTime.getHours()
    let nextDay = format(addDays(currentTime, 1), config.dateFormat).toString()
    let blockOutDayTime: any = location?.blockNextDayAfter ? location?.blockNextDayAfter?.replace(/[^0-9]/g, '') : ''
    if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('p')) {
        blockOutDayTime = parseInt(blockOutDayTime) + 12;
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('a')) {
        blockOutDayTime = parseInt(blockOutDayTime);
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else {
        bufferDate = [];
    }
    const infiniteYear = () => addYears(new Date("01/01/2220"), 100)
    // ends blocking next day
    return (
        <Field name="slot">
            {({
                form: { setFieldValue, setFieldError, values },
                field: { value },
                meta: { error },
            }: FieldProps) => {
                const parsedDeparture = departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : new Date();

                return (
                    <Container>
                        <Header>
                            <SelectedDate>
                                {format(selectedDate, config.weekDateFormat)}
                            </SelectedDate>
                            <DatePickerContainer alwaysShowDatePicker={alwaysShowDatePicker}>
                                {!!testSelection ? (
                                    <>
                                    <Datepicker
                                        value={selectedDate}
                                        minDate={new Date()}
                                        //maxDate={isWalkUpTest ? new Date() : infiniteYear()}
                                        onChange={(newDepartureDate) => {
                                            setSelectedDate(newDepartureDate);
                                            if (onChangeDate) {
                                                onChangeDate(newDepartureDate);
                                            }
                                        }}
                                        isDayFilter={
                                            testSelection !== 'general'
                                                ? location?.isDayFilter !== undefined
                                                    ? location?.isDayFilter
                                                    : false
                                                : false
                                        }
                                        isWeeklyFlag={
                                            // location?.isWeeklyFlag !== undefined
                                            //     ?
                                            //     blockOutDays !== undefined
                                            //         ?
                                            //         blockOutDays.length < 7
                                            //             ?
                                            //             true
                                            //             :
                                            //             location?.isWeeklyFlag
                                            //         :
                                            //         location?.isWeeklyFlag
                                            //     :
                                            //     location?.isWeeklyFlag
                                            blockOutDays !== undefined
                                                            ?
                                                                blockOutDays.length < 7
                                                                ?
                                                                    true
                                                                :
                                                                    location?.isWeeklyFlag
                                                            :
                                                            location?.isWeeklyFlag
                                        }
                                        // weeklyVisible={
                                        //     {
                                        //     weeklyVisibleDays: blockOutDays,
                                        //     weeklyVisibleNumber:
                                        //         location?.weeklyVisibleNumber !==
                                        //             undefined
                                        //             ? location?.weeklyVisibleNumber
                                        //             : {
                                        //                 weekDay: 0,
                                        //                 weekNumber: 0,
                                        //             }
                                        // }
                                        // }
                                                   weeklyVisible={{
                                                            weeklyVisibleDays:blockOutDays,
                                                            weeklyVisibleNumber:
                                                                location?.weeklyVisibleNumber !==
                                                                    undefined
                                                                    ? location?.weeklyVisibleNumber
                                                                    : {
                                                                        weekDay: 0,
                                                                        weekNumber: 0,
                                                                    },
                                                        }
                                                }
                                        customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                        // isblockOutDays={location?.blockOutDays}
                                        // location={location}
                                            isblockOutDays={
                                                            location?.blockOutDays !==
                                                                undefined
                                                                ? location?.blockOutDays
                                                                : bufferDate
                                                        }
                                                        location={location}
                                    /></>
                                ) : (
                                    <>
                                    
                                    <Datepicker
                                        value={selectedDate}
                                        maxDate={isWalkUpTest ? infiniteYear() : getMaxAvailableDate(
                                            location,
                                            isExpressSameDayTest,
                                            parsedDeparture,
                                            isRapidTest
                                        )}
                                        minDate={getMinAvailableDate(
                                            location,
                                            isExpressSameDayTest,
                                            parsedDeparture,
                                            isRapidTest
                                        )}
                                        onChange={(newDepartureDate) => {
                                            setSelectedDate(newDepartureDate);
                                            if (onChangeDate) {
                                                onChangeDate(newDepartureDate);
                                            }
                                        }}
                                        customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                    /></>
                                )}
                            </DatePickerContainer>
                        </Header>
                        <SlotsContainer>
                            {/* {!isWalkUpTest ? slots.length > 0 ? ( */}
                            
                            {!isWalkUpTest ? !loading ?
                            slots.length > 0 ? (
                                slots.map(
                                    (
                                        {
                                            label,
                                            startTime,
                                            available,
                                            index,
                                        }: {
                                            label: any;
                                            startTime: any;
                                            available: number;
                                            index: any;
                                        },
                                        i: any
                                    ) => {
                                        const preSelectedSlotDate = slot?.date
                                            ? parse(slot.date, config.dateFormat, new Date())
                                            : null;

                                        const isSelected =
                                            !!preSelectedSlotDate &&
                                            isEqual(selectedDate, preSelectedSlotDate) &&
                                            locationId === slot?.locationId &&
                                            index === value?.period;

                                        return (
                                            <Slot key={i}>
                                                <ChooseSlotBtn
                                                    selected={isSelected}
                                                    type="button"
                                                    onClick={async () => {
                                                        if (isSelected || !location) {
                                                            return;
                                                        }
                                                        const desiredSlot = {
                                                            date: format(selectedDate, config.dateFormat),
                                                            period: index,
                                                            locationId: location?.qbenchCustomerId,
                                                            label: label,
                                                        };
                                                        localStorage.setItem(
                                                            'desiredSlot',
                                                            JSON.stringify(desiredSlot)
                                                        );

                                                        appointment.slot = desiredSlot;

                                                        onFieldUpdate({ slot: desiredSlot });
                                                        setFieldValue('slot', desiredSlot);

                                                        setLoading(true);
                                                        await reserveSlot(
                                                            desiredSlot,
                                                            isManage &&
                                                                rescheduleReservationId !== '' &&
                                                                rescheduleReservationId !== null &&
                                                                rescheduleReservationId !== undefined
                                                                ? slot
                                                                : !isManage && rescheduleReservationId == ''
                                                                    ? slot
                                                                    : null,
                                                            numberOfGuests,
                                                            isRapidTest,
                                                            isManage
                                                                ? rescheduleReservationId
                                                                : reservationId,
                                                            isManage,
                                                            appointment,
                                                            isWalkUpTest
                                                        )
                                                            .then((res) => {
                                                                if (isManage) {
                                                                    if (res.data.status == 200) {
                                                                        onFieldUpdate({
                                                                            rescheduleReservationId:
                                                                                res.data.reservationId,
                                                                        });
                                                                    }
                                                                    setLoading(false);
                                                                } else {
                                                                    if (res.data.status == 200) {
                                                                        updateFormValues({
                                                                            reservationId: res.data.reservationId,
                                                                            reservationCount: res.data.numberOfGuests,
                                                                        });
                                                                    }
                                                                    setLoading(false);
                                                                }
                                                                setLoading(false);
                                                            })
                                                            .catch((e) => {
                                                                // setFieldError('slot', e.message)
                                                            });
                                                    }}
                                                    disabled={
                                                        available === 0 ||
                                                        available < numberOfGuests ||
                                                        loading
                                                    }
                                                >
                                                    {label}
                                                </ChooseSlotBtn>
                                                {loading ? (
                                                    <Shimmer />
                                                ) : (
                                                    <span>{pluralize(available, 'slot')} available</span>
                                                )}
                                            </Slot>
                                        );
                                    }
                                )
                            ) : (
                                <>No available slots</>
                            // ) : slots.length > 0 ? slots.map((availableSlots: any, key: any) => {
                                ) : <Shimmer />
                                :(isSchedularLite && uniqueID) ? 
                                 TIME.map((time,index)=>{

                                    return (
                                        <Slot key={index}>
                                            <ChooseSlotBtn
                                                selected={slot?.label === time.label ? true: false}
                                                type="button"
                                                onClick={()=>{
                                                    const desiredSlot = {
                                                        date: format(selectedDate, config.dateFormat),
                                                        locationId: location?.qbenchCustomerId ? location?.qbenchCustomerId : 0,
                                                        label: time.label,
                                                        period: timeToPeriod(time.label, appointment.isRapidTest).index
                                                    };
                                                    localStorage.setItem(
                                                        'desiredSlot',
                                                        JSON.stringify(desiredSlot)
                                                    );
                                                    appointment.slot = desiredSlot;
                                                    onFieldUpdate({ slot: desiredSlot });
                                                    setFieldValue('slot', desiredSlot);
                                                }}
                                            >
                                                {time.label}
                                            </ChooseSlotBtn>
                                        </Slot>
                                    )
                                })
                                : slots.length > 0 ? slots.map((availableSlots: any, key: any) => {
                                if (key > 0)
                                    return;
                                return (
                                    <Slot key={key}>
                                        <ChooseSlotBtn
                                            selected={slot ? true : false}
                                            type={isManage ? "button" : "submit"}
                                            onClick={async () => {
                                                if (slot || !location) {
                                                    return;
                                                }

                                                const desiredSlot = {
                                                    date: format(selectedDate, config.dateFormat),
                                                    locationId: location?.qbenchCustomerId,
                                                    label: format(new Date(), 'p'),
                                                    period: timeToPeriod(format(new Date(date ? date : ''), 'p'), appointment.isRapidTest).index
                                                };
                                                localStorage.setItem(
                                                    'desiredSlot',
                                                    JSON.stringify(desiredSlot)
                                                );

                                                appointment.slot = desiredSlot;

                                                onFieldUpdate({ slot: desiredSlot });
                                                setFieldValue('slot', desiredSlot);

                                                setLoading(true);
                                                await reserveSlot(
                                                    desiredSlot,
                                                    slot,
                                                    numberOfGuests,
                                                    isRapidTest,
                                                    reservationId,
                                                    false,
                                                    appointment,
                                                    isWalkUpTest
                                                )
                                                    .then((res) => {
                                                        if (res.data.status == 200) {
                                                            updateFormValues({
                                                                reservationId: res.data.reservationId,
                                                                reservationCount: res.data.numberOfGuests,
                                                            });
                                                            if (isManage) {
                                                                onFieldUpdate({
                                                                    reservationId: res.data.reservationId,
                                                                    reservationCount: res.data.numberOfGuests,
                                                                });
                                                            }
                                                        }
                                                        setLoading(false);
                                                    })
                                                    .catch((e) => {
                                                        // setFieldError('slot', e.message)
                                                    });
                                            }}
                                            disabled={loading}
                                        >
                                            Walk Up
                                        </ChooseSlotBtn>
                                        {loading ? (
                                            <Shimmer />
                                        ) : (
                                            <>
                                                <span>{pluralize(availableSlots.available, ' slot')} available</span>
                                            </>
                                        )}
                                    </Slot>
                                )
                            }) : (
                                <>No available slots</>
                            )}
                        </SlotsContainer>
                        <Error>
                            <ErrorText hasError={error !== undefined}>{error}</ErrorText>
                        </Error>
                    </Container>
                );
            }}
        </Field>
    );
};


export default Slots;
