import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Container, Grid } from '@material-ui/core';
import {Link} from 'react-router-dom';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TenantImageReview from './LogoModal'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { lightBlue } from '@material-ui/core/colors';
import { useAdminDispatch} from '../../provider'
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import {  createStyles } from '@material-ui/core/styles';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesforbackdrop = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tablehead: {
        fontWeight: 'bold'
    },
    lightblue:
    {
        // backgroundColor:lightBlue
    }, btncolor: {
        borderRadius: 0,
        color:'white',
        background: "#1890ff",
        "&:hover": {
            backgroundColor: "#40a9ff !important",
        }
    },
    success:{
        color:'#1890ff'
    }
});


export default function ClientPartnerListing() {
    const { SetAllClientPartner, SetID } = useAdminDispatch();
    SetID('');
    const [alertbox, setalertbox] = useState<any>({open:false,type:'',msg:''});
    const [backdrop, setbackdrop] = useState(false);
    const [image, setimage] = useState({
        open: false,
        imageurl: ''
    })
    const [loaded, setloaded] = useState(false)
    const getdata = async (method: any, id = null)=>{
        if(method==='getAll')
        {
            const responsedata = await axios({
                method: 'POST',
                url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getAllClientPartnerData',
                headers: {
                    'content-type': 'application/json'
                }
            });
            setloaded(true)
            return responsedata;
        }
        else
        if(method==='delete')
        {
            const responsedata = await axios({
                method: 'POST',
                url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/deleteClientPartnerData',
                headers: {
                    'content-type': 'application/json'
                },
                data: { id }
            });
            return responsedata;
        }
    }
    const [rowdata, setrowdata] = React.useState([]);
    useEffect(() => {
        getdata('getAll').then((res: any) => {
            let newdata = res.data.clientPartner.map((e: any) => {
                return ({ id: e.newid, ...e.data })
            });
            console.log(newdata);
            SetAllClientPartner(newdata);
            setrowdata(newdata);
        }).catch(err => { console.log(err); })
    }, []);

    const classes = useStyles();
    const [confirmdelete, setconfirmdelete] = useState({open:false,id:''})
    const deleteData=(id: any)=>{
        if(!(id===''||id===null))
        {
            setbackdrop(true);
            getdata('delete',id).then((res: any)=>{
                if (res.status === 200) {
                    let newdata = rowdata.filter((e: any)=>e.id!==id);
                    setrowdata(newdata);
                    SetAllClientPartner(newdata);
                    setalertbox({open:true,type:'success',msg:'Row Deleted Successfully'});
                    setbackdrop(false); 
                }
                else {
                    setalertbox({ open: true, type: 'error', msg: 'Unable to Deleted Row' });
                    setbackdrop(false);
                }
            }).catch(err => {
                setalertbox({ open: true, type: 'error', msg: 'Unable to Deleted Row' });
                setbackdrop(false);
            })
        }
        setconfirmdelete({open:false,id:''});
    }

    const forbackdrop = useStylesforbackdrop();
    function ConfirmAlertBox() {
        return(
            <>
                <Dialog
                    open={confirmdelete.open}
                    onClose={()=>setconfirmdelete({open:false,id:''})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to Delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setconfirmdelete({ open: false, id: '' })} color="primary">
                            Cancel
                        </Button>
                        <Button color="secondary" onClick={() => deleteData(confirmdelete.id)}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }    
    return (
        <>
            <Box >
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertbox.open} autoHideDuration={6000} onClose={() => setalertbox({ oepn: false, type: '', msg: '' })}>
                    <Alert onClose={() => setalertbox({ oepn: false, type: '', msg: '' })} severity={alertbox.type}>{alertbox.msg}</Alert>
                </Snackbar>
                <Backdrop className={forbackdrop.backdrop} open={backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ConfirmAlertBox/>
                <TenantImageReview image={image} setimage={setimage} />
                <Container maxWidth='xl'>
                    <Grid >
                        <Container maxWidth='xl'>
                            <Box  mt={10} mb={5}>
                                <Link to='/dashboard/tenantform'><Button className={classes.btncolor} variant='contained'>Add New Tenant Data</Button></Link>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tablehead}>cPartner ID</TableCell>
                                            <TableCell className={classes.tablehead}>Created Date</TableCell>
                                            <TableCell className={classes.tablehead}>Partner ID</TableCell>
                                            <TableCell className={classes.tablehead}>Partner Name</TableCell>
                                            <TableCell className={classes.tablehead}>Prefix</TableCell>
                                            <TableCell className={classes.tablehead}>Tenant ID</TableCell>
                                            <TableCell className={classes.tablehead}>is Active</TableCell>
                                            <TableCell align='center' className={classes.tablehead}>Logo</TableCell>
                                            <TableCell align='center' className={classes.tablehead}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loaded ? (
                                            !rowdata.length ? (<TableRow >
                                                <TableCell align='center' colSpan={9}>
                                                    No Data Found
                                                </TableCell>
                                            </TableRow>):(
                                            rowdata.map((row: any) => (
                                                <TableRow key={row.id}>
                                                    <TableCell >{row.cPartnerID}</TableCell>
                                                    <TableCell >{row.createdDT.split('-')[2] + '-' + row.createdDT.split('-')[1] + '-' + row.createdDT.split('-')[0]}</TableCell>
                                                    <TableCell >{row.partnerID}</TableCell>
                                                    <TableCell >{row.partnerName}</TableCell>
                                                    <TableCell >{row.prefix}</TableCell>
                                                    <TableCell >{row.tenantid}</TableCell>
                                                    <TableCell >{row.isActive ? 'Active' : 'Not Active'}</TableCell>
                                                    <TableCell align='center'>
                                                        {row.tenantlogo?(
                                                            <IconButton aria-label="image" onClick={() => { setimage({ open: true, imageurl: typeof row.tenantlogo === 'object' ? row.tenantlogo.base64 : row.tenantlogo }) }}>
                                                                <VisibilityIcon  color='primary' />
                                                            </IconButton>
                                                        ) : (
                                                            <Tooltip title='Logo Not Uploaded'>
                                                                <IconButton aria-label="image" onClick={() => { setimage({ open: false, imageurl: typeof row.tenantlogo === 'object' ? row.tenantlogo.base64 : row.tenantlogo }) }}>
                                                                    <VisibilityOffIcon color='primary' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Link to={'/dashboard/tenantform'}>
                                                            <IconButton aria-label="edit" onClick={()=>SetID(row.id)}>
                                                                <EditIcon  className={classes.success} />
                                                            </IconButton>
                                                        </Link>
                                                        <IconButton aria-label="delete" onClick={() => setconfirmdelete({ open: true, id: row.id })}>
                                                            <DeleteIcon  color='secondary' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )):(<>
                                            <TableRow >
                                                <TableCell align='center' colSpan={9}>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        </>)}
                                    </TableBody>
                                    
                                </Table>
                            </TableContainer>
                        </Container>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
