export const isUSPhone = /^\s*(?:\+?(\d{1,3}))?[(]*(\d{3})[]*(\d{3})[]*(\d{4})(?: (\d+))?\s*$/

export const isTenDigitNumber = /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/;

export const isValidZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidDateFormat = /^\d{2}\/\d{2}\/\d{4}$/;

export const UrlValidation  = /^(?:\/dashboard\b)(?:\/DayWiseAppointments+)(?:\?Id=.+)$/

export const password = /^(?=.*[A-Z])(?=.*[a-z].*[a-z]).{8,}$/

export const fullNameValidation = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;