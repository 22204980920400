import React, { useRef, useContext, createContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import {
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { apiConfig } from '../../../../../apiConfig';
import { Spin } from 'antd';
import { config } from '../../../../../config';
import axios from 'axios';
const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '800px',
    position: 'absolute',
    top: '30%',
    left: '50%',
    width: '400',
    backgroundColor: 'background.paper',
    border: '0.5px solid #000',
    boxShadow: '24',
    padding: '4px',
    transform: 'translate(-50%, -50%)',
    height: '56%',
  },
  PrintModal: {
    overflow: 'scroll',
  },
}));
interface Props {
    open: boolean;
    setOpen: (data: boolean) => void;
    btnShow?: boolean;
    handleSearch?: () => void;
};
export const PrintDataContext = createContext(null);
const PrintSampleLabels: React.FC<Props> = ({
    btnShow,
    open,
    setOpen,
    handleSearch,
}) => {
    //const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        //  handleSearch && handleSearch();
    };
    const { getPrintSampleLabels } = useAdminState();
    const [select, setSelect] = React.useState('19');
    let selectedData: any = getPrintSampleLabels;
    console.log('selectedData', selectedData);
    let countData: any = [];
    selectedData.map((data: any) => {
        let newdata = { id: data.sampleId, value: 3 };
        countData.push(newdata);
    });
    
    const [value, setValue] = React.useState(countData);
    useEffect(() => {
        setValue(countData);
    }, [selectedData]);
    const onchangeVal = (id: any) => {
        try {
            let data = value.filter((e: any) => {
                if (e.id === id) return e;
            });
            return data[0].value;
        } catch (e) {
            return 3;
        }
    };

    const getPrintSampleLabelsData = async () => {
        let data: any = {};
        data['labelId'] = select;
        data['entity_dicts'] = [];
        const timeZone = encodeURIComponent(
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        data['timeZone'] = timeZone;
        selectedData.map((rowData: any) => {
            let tempObj: any = {};
            tempObj['id'] = rowData.sampleId;            
            value.map((valData: any) => {
                if (rowData.sampleId === valData.id) {
                    tempObj['count'] = parseInt(valData.value);
                }
            });

            data.entity_dicts.push(tempObj);
        });
        console.log('data', data);
        try {
            // const resdata = await fetch(
            //     // `${apiConfig.hostUrl}/getPrintSampleLabels`,
            //     `${config.cloudRun.baseUrl}getPrintSampleLabels`,
            //     {
            //         method: 'POST',
            //         headers: {
            //             'content-type': 'application/json',
            //         },
            //         body: data,
            //     }
            // );
            // let dataafter: any = await resData.json();

            const resData: any = await axios({
                method: 'POST',
                url: `${config.cloudRun.baseUrl}getPrintSampleLabels`,
                headers: {
                  'content-type': 'application/json',
                },
                data: data,
              });
            var printWindow: any = window.open('');
            printWindow.document.write(resData?.data.data.htmlContent);
            printWindow.document.close();
        } catch (error) {
            //console.log(error);
        }
    };
    const classes = useStyles();
    return (
        <>
            {btnShow && (
                <Button
                    onClick={handleOpen}
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                >
                    Print Sample Labels
                </Button>
            )}
            <Modal
                className={classes.PrintModal}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className={`${classes.paper}`}
                    style={{ backgroundColor: 'white' }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Print Sample Labels
                    </Typography>
                    <br />
                    <hr />
                    <Typography
                        id="modal-modal-description"
                        style={{ marginTop: 2 }}
                    >
                        Please enter the amount of labels you would like to
                        print for each.
                    </Typography>
                    <br />
                    <Typography>Label Template</Typography>
                    <Box style={{ minWidth: 120, marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                variant="outlined"
                                value={select}
                                onChange={(e: any) => setSelect(e.target.value)}
                            >
                                <MenuItem value={19}>Sample Label</MenuItem>
                                {/* <MenuItem value={40}>Mobile Label</MenuItem>
                <MenuItem value={41}>SCHOOL TESTING</MenuItem>
                <MenuItem value={45}>Scheduler Label</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box style={{ backgroundColor: 'white' }}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sample ID</TableCell>
                                        <TableCell align="right">
                                            Classroom Number
                                        </TableCell>
                                        <TableCell align="right">
                                            External Accession ID
                                        </TableCell>
                                        <TableCell align="right">
                                            Lab ID
                                        </TableCell>
                                        <TableCell align="left">
                                            Label Count
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {selectedData.length > 0 ? (
                                    selectedData.map((tabledata: any) => {
                                        return (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {tabledata.sampleId}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            tabledata.X_PATIENT_EXTERNAL_ID
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                backgroundColor:
                                                                    '#dee3e0',
                                                            }}
                                                        >
                                                            Fill
                                                        </Button>
                                                        <TextField
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            value={onchangeVal(
                                                                tabledata.sampleId
                                                            )}
                                                            onChange={(e) => {
                                                                let countData: any = [];
                                                                value.map(
                                                                    (
                                                                        data: any
                                                                    ) => {
                                                                        if (
                                                                            data.id ===
                                                                            tabledata.sampleId
                                                                        ) {
                                                                            let newdata = {
                                                                                id:
                                                                                    data.id,
                                                                                value:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            };
                                                                            countData.push(
                                                                                newdata
                                                                            );
                                                                        } else {
                                                                            let newdata = {
                                                                                id:
                                                                                    data.id,
                                                                                value:
                                                                                    data.value,
                                                                            };
                                                                            countData.push(
                                                                                newdata
                                                                            );
                                                                        }
                                                                        setValue(
                                                                            countData
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        );
                                    })
                                ) : (
                                    <Spin style={{ margin: 'auto' }} />
                                )}
                            </Table>
                        </TableContainer>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                style={{ backgroundColor: 'lightgreen' }}
                            >
                                close
                            </Button>
                            <Button
                                style={{ backgroundColor: 'lightblue' }}
                                onClick={async () => {
                                    await getPrintSampleLabelsData();
                                    handleClose();
                                }}
                            >
                                Print
                            </Button>
                        </DialogActions>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default PrintSampleLabels;