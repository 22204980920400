import { format, isBefore, parse } from 'date-fns';
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { firebase } from '../../firebase';
import { config } from '../../config';
import { FirebaseLocation } from './models';
import { getPeriodsFromSchedule, Last7Days } from '../../utils';
import { Location } from './models';
import { filter, orderBy, uniqBy } from 'lodash';
import axios from 'axios';
import { getConsolidationReport } from '../admin/api';
const periods = getPeriodsFromSchedule(config.schedule);

const Reportperiods = getPeriodsFromSchedule(config.Reportschedule);

export const getOpenedLocations = () =>
    firebase
        .firestore()
        .collection(config.firestoreCollections.locations)
        .where('isOpened', '==', true)
        .get()
        .then((snapshot) => {
            let locationArray: any = [];
            snapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id}) as FirebaseLocation)
                .map((loc: any) => {
                    if (isBefore(loc.visibleSince.toDate(), new Date())) {
                        locationArray.push({
                            ...loc,
                            startDate: format(loc.startDate.toDate(), config.dateFormat),
                        });
                    }
                });
            locationArray = orderBy(locationArray, ['name'], 'asc');
            // locationArray = uniqBy(locationArray, function (e: any) {
            //     return e.qbenchCustomerId;
            // });
            return locationArray;
        });
export const getAllOpenedLocations = () =>
    firebase
        .firestore()
        .collection(config.firestoreCollections.locations)
        .get()
        .then((snapshot) => {
            let locationArray: any = [];
            snapshot.docs
                .map((doc) => doc.data())
                .map((loc: any) => locationArray.push(loc));
            locationArray = orderBy(locationArray, ['name'], 'asc');
            return locationArray;
        });
export const getApptReport = async (location: any) => {
    // firebase
    //     .functions()
    //     .httpsCallable('getMysqlConsolidationReport')(location.qbenchCustomerId)
    //     .then(function (response) {
    //         if (response.data) {
    //             console.log("KC", response.data)
    //             generateApptReport(response.data)
    //             console.log("KC1", response.data)
    //         }
    //     }).catch((err) => false);
    try {
        const response = await getConsolidationReport(location.qbenchCustomerId);
        console.log('consolidatedData', response)
        generateApptReport(response);
    } catch (error) {
        console.log(error)
    }
};

function generateApptReport(appointments: any, locationName?: string) {
    try {
        const data = [
            [
                'FirstName',
                'LastName',
                'PatientIdentifier',
                'DOB',
                'Gender',
                'Race',
                'Ethnicity',
                'Patient_Street_Address',
                'Apartment_Number',
                'City',
                'State',
                'Zipcode',
                'PatientPhoneNumber',
                'Patient Email',
                'OrderingFacility',
                'Referring Physician',
                'Physician address',
                'Physician City',
                'Physician State',
                'Physician Zipcode',
                'Physician phone number',
                'Accession #',
                'Specimen Collection Date',
                'Specimen Received Date',
                'Specimen Type',
                'Test reported date',
                'Test Code_LOINC',
                'Test name',
                'Result',
                'PerformingFacility',
                'CLIA',
                'Pregnancy Status',
            ],
            ...appointments.reduce((acc: any[], appt: any) => {
                const a = acc;
                console.log('appt', appt);
                // const row = [
                //     appt.Samples_X_PATIENT_FIRST_NAME,
                //     appt.Samples_X_PATIENT_LAST_NAME,
                //     appt.PatientIdentifier,
                //     appt.Samples_X_PATIENT_DOB,
                //     appt.Sex,
                //     appt.Race,
                //     appt.Ethnicity,
                //     `${appt.Address_Address + ',' + appt.Address_City + ',' + appt.Address_State + ',' + appt.Address_ZipCode}`,
                //     appt.Address_Address,
                //     appt.Address_City,
                //     appt.Address_State,
                //     appt.Address_ZipCode,
                //     appt.Phone,
                //     appt.Email,
                //     appt.OrderingFacility,
                //     appt.MedicalDirector,
                //     appt.PhysicianAddress,
                //     appt.PhysicianCity,
                //     appt.PhysicianState,
                //     appt.PhysicianZipcode,
                //     appt.PhysicianPhoneNo,
                //     appt.Samples_X_EXTERNAL_ACCESSION_ID,
                //     appt.Date,
                //     appt.Tests_Complete_Date_Formated,
                //     appt.Samples_Accessioning_Type_Value,
                //     appt.Samples_Time_of_collection,
                //     appt.Test_Code_LOINC,
                //     appt.Tests_Assay_Title,
                //     appt.Tests_Results,
                //     appt.PerformingFacility,
                //     appt.CLIA,
                //     appt.PregnancyStatus,
                // ];

                const row = [
                    appt.firstName,
                    appt.lastName,
                    '',
                    appt.birthDate,
                    appt.sex,
                    appt.race,
                    appt.ethnicity,
                    `${appt.address !== undefined
                        ? appt.address['address'] +
                        ',' +
                        appt.address['city'] +
                        ',' +
                        appt.address['state'] +
                        ',' +
                        appt.address['zipCode']
                        : ''
                    }`,

                    `${appt.address !== undefined ? appt.address['address'] : ''}`,
                    `${appt.address !== undefined ? appt.address['city'] : ''}`,
                    `${appt.address !== undefined ? appt.address['state'] : ''}`,
                    `${appt.address !== undefined ? appt.address['zipCode'] : ''}`,
                    appt.phone,
                    appt.email,
                    'Work Site Lab',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    appt.custom_formatted_id,
                    `${appt.date}`,
                    `${appt.samples !== undefined &&
                        appt.samples.time_of_collection !== undefined
                        ? appt.samples.time_of_collection
                        : ''
                    }`,
                    `${appt.samples !== undefined &&
                        appt.samples.accessioning_type !== undefined
                        ? appt.samples.accessioning_type['value']
                        : ''
                    }`,
                    `${appt.samples !== undefined ? appt.samples['time_of_collection'] : ''
                    }`,
                    `${appt.samples !== undefined ? appt.samples['time_of_collection'] : ''
                    }`,
                    `${appt.accessioning_type !== undefined ? appt.accessioning_type : ''}`,
                    `${appt.tests !== undefined ? appt.tests['results'] : ''}`,
                    `${
                        appt.location !== undefined &&
                        appt.location !== null &&
                        appt.location.performingfacility !== undefined &&
                        appt.location.performingfacility !== null
                          ? `Worksite Labs - ${appt.location.performingfacility}`
                          : ''
                      }`,
                    `${
                    appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.CLIA !== undefined &&
                    appt.location.CLIA !== null
                        ? appt.location.CLIA
                        : ''
                    }`,
                    '',
                ];
                a.push(row);

                return a;
            }, []),
        ];

        const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
        const blob = new Blob([buffer]);

        saveAs(
            blob,
            `LAC_WorkSiteLab_Neg_${format(new Date(), config.fileDateFormat)}.xlsx`
        );
    } catch (error) {
        console.log("err", error)
    }
}

export const generateReport = (appointments: any, locationName?: string) => {

    const data = [
        ['Date', 'First Name', 'Last Name', 'Phone', 'Flight Date And Time'],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;

            // const row = [
            //     appt.DateAndSlot,
            //     appt.FirstName,
            //     appt.LastName,
            //     appt.Phone,
            //     appt.DepartureDateAndTime,
            // ];
            const row = [
                `${appt.date} ${appt.slot?.period !== undefined
                    ? Reportperiods[appt.slot?.period].label
                    : ''
                }`,
                appt.firstName,
                appt.lastName,
                appt.phone,
                appt.departureDateAndTime,
            ];
            a.push(row)
            return a;
        }, []),
    ];

    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);

    saveAs(blob, `report${locationName ? `-${locationName}` : ''}.xlsx`);
}


export const generateInsuranceAndStripeReport = (appointments: any) => {
    const data = [
        ['Date', 'Billing Type', 'Location', 'Amount'],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;

            const row = [
                // appt.Date,
                // appt.BillingType,
                // appt.Location,
                // appt.Amount ?? 0
                appt.date,
                `${appt.paymentIntentId.length >= 25 ? 'Credit Card' : 'Insurance'} `,
                appt.location?.name,
                appt.amount ? appt.amount : 0
            ];
            a.push(row);

            return a;
        }, []),
    ];

    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);

    saveAs(blob, "Insurance_and_credit_card_report.xlsx");
}



// export const generateAppointmentsReport = async (location: Location) => {
//     const pastDates: Array<any> = Last7Days();

//     const today = new Date();
//     const previousday = new Date(today);
//     previousday.setDate(previousday.getDate() - 7);
//     const previousdate = format(previousday, 'LL/dd/yyyy');

//     firebase
//         .firestore()
//         .collection(config.firestoreCollections.qbenchacknowledgement)
//         .where('slot.date', '<=', previousdate)
//         .get()
//         .then((snapshot) => {
//             const qbenchRes = snapshot.docs.map((doc) => doc.data());

//             let crelioSnapsnot = firebase
//                 .firestore()
//                 .collection(config.firestoreCollections.crelioacknowledgement)
//                 .where('slot.date', '<=', previousdate)
//                 .get()
//                 .then((snapshot) => {
//                     const crelioRes = snapshot.docs.map((doc) => doc.data());

//                     const res = [...qbenchRes, ...crelioRes];

//                     const apptsByDate = res.sort((a, b) => {
//                         if (a.date === b.date) {
//                             const aPeriod = periods[a.slot.period];
//                             const bPeriod = periods[b.slot.period];

//                             if (aPeriod && bPeriod) {
//                                 return (
//                                     aPeriod.startTime.getTime() - bPeriod.startTime.getTime()
//                                 );
//                             }
//                         }

//                         const aDate = parse(a.date, config.dateFormat, new Date());
//                         const bDate = parse(b.date, config.dateFormat, new Date());

//                         return aDate.getTime() - bDate.getTime();
//                     });

//                     var output = filter(apptsByDate, (user) => {
//                         return user.slot?.locationId == location.qbenchCustomerId; // && user.slot.date >= todate
//                     });

//                     generateReport(output, location.name);
//                 });
//         });
// };
export const getMysqlGenerateAppointmentReports = async (location: any) => {
    try {
        const today = new Date();
        const previousday = new Date(today);
        previousday.setDate(previousday.getDate() - 7);
        const previousdayiousdate = format(previousday, 'LL/dd/yyyy');
        // firebase
        //     .functions()
        //     .httpsCallable('getMysqlGenerateAppointmentReports', { timeout: 540 * 1000 })(previousdayiousdate).then((response) => {
        //         var output = filter(response.data, (user) => {
        //             return user.slot?.locationId == location?.qbenchCustomerId; // && user.slot.date >= todate
        //         });
        // generateReport(output, location.name);
        //     });
        let params = {
            slotDate: previousdayiousdate,
            qbenchCustomerId: (location !== undefined || null) && location.qbenchCustomerId
        }
        const response = await cloudRunPost(config.cloudRunApi.getMysqlGenerateAppointmentReports, params);
        if (response) {
            const apptReport = response.data
            const locationName = apptReport.map((data: { LocationName: string; }) => data.LocationName)
            if (apptReport.length > 0) {
                generateReport(apptReport, locationName[0])
            } else {
                alert('No data found for the location')
            }
        } else {
            console.log('Response is not received')
        }

    } catch (error) {
        console.log(error)
    }

}
// export const generateAllAppointmentsReport = async (location: Location) => {
//     const pastDates: Array<any> = Last7Days();
//     firebase
//         .firestore()
//         .collection(config.firestoreCollections.qbenchacknowledgement)
//         .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
//         .get()
//         .then((snapshot) => {
//             const qbenchRes = snapshot.docs.map((doc) => doc.data());

//             // include crelio report
//             firebase
//                 .firestore()
//                 .collection(config.firestoreCollections.crelioacknowledgement)
//                 .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
//                 .get()
//                 .then((crelioSnapshot) => {
//                     const crelioRes = crelioSnapshot.docs.map((doc) => doc.data());

//                     const res = [...qbenchRes, ...crelioRes];

//                     const apptsByDate = res.sort((a, b) => {
//                         // if (a.date === b.date) {
//                         //   const aPeriod = periods[a.slot.period];
//                         //   const bPeriod = periods[b.slot.period];

//                         //   if (aPeriod && bPeriod) {
//                         //     return aPeriod.startTime.getTime() - bPeriod.startTime.getTime();
//                         //   }
//                         // }

//                         const aDate = parse(a.date, config.dateFormat, new Date());
//                         const bDate = parse(b.date, config.dateFormat, new Date());

//                         return aDate.getTime() - bDate.getTime();
//                     });

//                     generateReport(apptsByDate, location.name);
//                 });
//         });
// };

export const downloadAllReports = async () => {
    try {
        firebase
            .firestore()
            .collection('locations')
            .where('isOpened', '==', true)
            .get()
            .then((snapshot: any) => {
                const periods = getPeriodsFromSchedule(config.schedule);

                snapshot.docs.forEach((doc: any) => {
                    const location = doc.data();
                    firebase
                        .firestore()
                        .collection('appointments')
                        .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
                        .get()
                        .then((snapshot: any) => {
                            const res = snapshot.docs.map((doc: any) => doc.data());

                            const apptsByDate = res.sort((a: any, b: any) => {
                                if (a.date === b.date) {
                                    const aPeriod = periods[a.slot.period];
                                    const bPeriod = periods[b.slot.period];

                                    if (aPeriod && bPeriod) {
                                        return (
                                            aPeriod.startTime.getTime() - bPeriod.startTime.getTime()
                                        );
                                    }
                                }

                                const aDate = parse(a.date, config.dateFormat, new Date());
                                const bDate = parse(b.date, config.dateFormat, new Date());

                                return aDate.getTime() - bDate.getTime();
                            });

                            generateReport(apptsByDate, location.name);
                        });
                });
            });
    } catch (err) {
        console.error('checkResults', err);
    }
};

export const getAllAppointmentsCreatedOnDate = async (date?: string) =>
    firebase
        .functions()
        .httpsCallable('getAllAppointmentsCreatedOnDate')(date)
        .then((res) => generateReport(res.data));

export const generateAllAppointmentsReportNew = async (location: Location) =>
    firebase
        .functions()
        .httpsCallable('generateAllAppointmentsReportNew')({
            locationId: location.qbenchCustomerId,
        })
        .then((res) => generateReport(res.data.appointments, location.name));

export const getSlotsByLocationId = async (locationId: any) =>
    firebase
        .functions()
        .httpsCallable('getSlotsByLocationId')(locationId)
        .then((res: any) => {
            if (res.data) {
                return res.data.slots[0].slots;
            } else {
                return [];
            }
        });

export const getMysqlDiscountAmountDetails = async (selectedFromDate: any, selectedToDate: any) => {
    try {
        // return firebase
        //     .functions()
        //     .httpsCallable('getMysqlDiscountAmountDetails')({
        //         fromDate: selectedFromDate,
        //         toDate: selectedToDate
        //     }).then((res: any) => {
        //         return res;
        //     })
        let params = {
            data: {
                fromDate: selectedFromDate,
                toDate: selectedToDate
            }
        }
        return axios({
            method: 'post',
            url: 'https://wsl-multitenancy-cloudrun-qt3dziqiia-uc.a.run.app/getMysqlDiscountAmountDetails_V2',
            data: params,
        })
            .then((res: any) => {
                return res;
            })
    } catch (error) {
        console.log(error)
    }
};


export const getMysqlInsuranceAndStripe = async (selectedFromDate: any, selectedToDate: any, locationId: null | Number, createOrAppointment: string) => {
    try {
        let params = {
            data: {
                fromDate: selectedFromDate,
                toDate: selectedToDate,
                locationId: locationId,
                createOrAppointment: createOrAppointment
            }
        }
        console.log('1st', params)

        return axios({
            method: 'post',
            url: 'https://wsl-multitenancy-cloudrun-qt3dziqiia-uc.a.run.app/getMysqlInsuranceAndStripe_V2',
            data: params,
        })

            .then((res: any) => {
                console.log('2nd', params)
                return res;
            })
    } catch (error) {
        console.log(error)
    }
}

export const cloudRunPost = async (api: any, body: any) => {
    const API = `${config.cloudRun.baseUrl}${api}`;
    try {
        const result = await axios.post(API, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return result;
    } catch (error) {
        let errorMessage = "Failed to do something exceptional";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.log(errorMessage);
    }
};

export const cloudRunGet = async (api: any) => {
    const API = `${config.cloudRun.baseUrl}${api}`;
    try {
        const result = await axios.get(API, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
        });
        return result;
    } catch (error) {
        let errorMessage = "Failed to do something exceptional";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.log(errorMessage);
    }
};

export const cloudRunDelete = async (api: any, params: any) => {
    const API = `${config.cloudRun.baseUrl}${api}`;
    try {
        const result = await axios.delete(API, {
            data: params,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return result;
    } catch (error) {
        let errorMessage = "Failed to do something exceptional";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.log(errorMessage);
    }
};

export const getEmployeeCoupon = async (data: any) => {
    return firebase.functions().httpsCallable('getEmployeeCoupon')(data);
}

export const getCoupon = async (data: any) => {
    return firebase.functions().httpsCallable('getCoupon')(data);
}

