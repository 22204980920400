import * as React from 'react';
import { useAdminDispatch, useAdminState } from './../../provider'
import { Layout,Table } from 'antd';
import { getAdminConfigDaywise, getInventory,getInventoryAuditLog } from '../../api';
import { InventoryTableColumn } from './../../../admin/components/AppointmentList/constants';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import EditCount from './EditCount'


const ManageInventory : React.FC = () => {
	const { editLocation } = useAdminState();
	const { goToPrevStep } = useAdminDispatch();
	const [isAntigen, setIsAntigen] = React.useState(false);
	const [rowdata, setrowdata] = React.useState<any>([]);
	const [loading, setLoading] = React.useState(false);
	const [loadings, setLoadings] = React.useState(false);
	const [countRapid,setCountRapid]=React.useState();
	const [Length,setLength]=React.useState(0);
	const [countRapids,setCountRapids]=React.useState(0);
	const [countAntigen,setCountAntigen]=React.useState(0);
	const [id,setId]=React.useState('');

	const [handleOpen, setHandleOpen] = React.useState(false);
    const handleClickOpen = () => {
        setHandleOpen(true);
    };

    const getInventorys = async(data: any) => {
        setLoadings(true)
        setLoading(true)
        const result = await getInventory(data);
        if(result.data.status === 200){

            setCountRapid(result.data.data[0]);
           
            setCountRapids(result.data.data[0]?.rapidKitCount ? result.data.data[0].rapidKitCount : 0);
            setCountAntigen(result.data.data[0]?.antigenKitCount ? result.data.data[0].antigenKitCount : 0);
            
            setId(result.data.data[0]?.id ? result.data.data[0].id : null);
          
            setLoadings(false)
            setLoading(false)

        }
        
    };

    const getAuditLog = async(data: any) => {
        setLoading(true)
        const result = await getInventoryAuditLog(data);
        if(result.data.status === 200){
            setrowdata( result.data.data);
            setLength(result.data.data.length)
            setLoading(false)
        }
        console.log("Success1", result);
       
    };
	
	React.useEffect(() => {
		let data = {
			locationName: editLocation?.name,
		}
		getInventorys(data);
		getAuditLog(data);
    }, []);

	

	// Table location item
	const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]);
	
	async function getAdminConfigDaywiseColumn() {
		setLoading(true);
		const tableColumnData = InventoryTableColumn();
		setRenderTableColumn(tableColumnData);
		let response = await getAdminConfigDaywise({
		  email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
		});
  
		if (response.data.status === 200) {
		  if (response.data.data.length > 0) {
			if (
			  response.data.data[0].daywiseconfig.hideandshowcolumn.length > 0
			) {
			  setRenderTableColumn(
				InventoryTableColumn()
			  );
			  setLoading(false);
			}
		  } else {
			const tableColumnData = InventoryTableColumn();
			setRenderTableColumn(tableColumnData);
			setLoading(false);
		  }
		} else {
		  const tableColumnData = InventoryTableColumn();
		  setRenderTableColumn(tableColumnData);
		  setLoading(false);
		}
	  }

	React.useEffect(() => {
		getAdminConfigDaywiseColumn();
	}, []);
	
	return (
		<Layout
			style={{
			marginTop: '10px',
			backgroundColor: '#FFF',
			border: '1px solid #f3eeee',
			boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.12)',
			padding:'20px 10px'
			}}
		>
			{/* Location name and back  */}
			<div 
				style={{
					padding: '10px 20px'
				}}
			>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					
				}}>
					<IconButton color="primary" >
						<ArrowBackIcon fontSize='large'  onClick={()=> goToPrevStep()} />
					</IconButton>  
					<h4>Location Name :{' '}
						<span 
						style={{
							fontWeight:'bold',
						}}
						>
							{editLocation?.name}
						</span>
					</h4>
				</div>
			</div>

			{/* current Inventory with Edit */}
			<div  style={{ 
					padding: '10px 20px',
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center'
					
				}}>
				<h3>Current Inventory</h3>
				<div>
					<b>Rapid Kit</b>
					<div
						style={{ 
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<h3>{loadings ? '...' : countRapids}</h3>
						<span style={{marginLeft:'10px'}}><EditTwoToneIcon onClick={()=>{
							setIsAntigen(false)
							handleClickOpen()
						}}/></span>
					</div>
				</div>
				<div style={{marginLeft:'10px'}}>
					<b>Antigen Kit</b>
					<div
						style={{ 
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<h3>{loadings ? '...' : countAntigen}</h3>
						<span style={{marginLeft:'10px'}}><EditTwoToneIcon onClick={()=>{
							setIsAntigen(true)
							handleClickOpen();
						}}/></span>
					</div>
				</div>
			</div>
			
			{/* Edit Pop-up */}
			<EditCount
                open={handleOpen}
                handleClose={() => setHandleOpen(false)}
                isAntigen={isAntigen}
				rapidCount={countRapid}
				getInventorys={getInventorys}
				getAuditLog={getAuditLog}
				id={id}
                Length={Length}
            />

			{/* Main Table */}
			<div style={{ padding: '30px 20px 10px 20px' }}>
				<h4 style={{ marginBottom: '15px'}}>Audit Log: </h4>
				<Table
					size="small"
					columns={renderTableColumn as any}
					bordered
					dataSource={(rowdata as any) || []}
					pagination= { {pageSizeOptions: ['100','200','500','1000'], showSizeChanger: true}}
					loading={loading}
					scroll={{ x: 1500 }}
					rowKey={(row: any) => row.key}
				/>
			</div>
		</Layout>
	);
}
export default ManageInventory;




