import React, { useState } from 'react';
import styled from 'styled-components';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Form, Formik, FormikErrors } from 'formik';
import { makeStyles } from '@material-ui/core';
import {
    Button,
    Container,
    Content,
    ErrorText,
} from '../../../../shared/components/styled';
import {
    isTenDigitNumber,
    isUSPhone,
    isValidEmail,
} from '../../../../../validations';
import { AdminManagePage } from '../dictionaries';
import { getAppointmentByPhoneorEmail } from '../../../../shared/providers/manage/api';
import { colors } from '../../../../../styles/colors';
import Input from '../../../../shared/components/form/Input';
import Checkbox from '../../../../shared/components/form/Checkbox';
import FormLabel from '../../../../shared/components/form/FormLabel';
import { useAdminDispatch } from '../../../provider';
interface NoticeProps {
    margin?: string;
}

const useStyles = makeStyles({
    selectInput: {
        borderRadius: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '8px 28px',
        transition: ' all 0.15s ease-in-out',
        width: '100%',
        border:'0px solid gray',
        pointerEvents:'unset',
    },
});

const Notice = styled.p`
    margin: ${({ margin }: NoticeProps) => margin || '27px 0 65px'};
    font-size: 14px;
`;

const Error = styled.div`
    color: ${colors.red};
    margin-top: 15px;
    text-align: center;
`;

const Divider = styled.h2`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #1a96db;
    line-height: 0.1em;
    margin: 30px 0 20px 0;
    color: #2a5f87;
    font-size: 16px;
    font-weight: 700;
`;

const DividerSpan = styled.span`
    background: #fff;
    padding: 0 10px;
`;

const ManageScreen: React.FC = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [countryCode, setCountryCode] = useState<string>('+1');
    const [phoneNumber, setPhoneNumber] = useState('+1 United States')
    const {
        updateResultsScreen,
        goToPage,
        setMinorAccount,
        setMinorEmail,
    } = useAdminDispatch();

    interface FormValues {
        phone: string;
        email: string;
        code: string;
    }

    const phoneNumberStyle: any = {
        borderRadius: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '4px 16px',
        transition: ' all 0.15s ease-in-out',
        width: '100%',
        border: '1px solid gray',
        marginBottom: '12px',
    };

    const handleCountryCode = (selectedCode: string) => {
        setCountryCode(selectedCode);
    };

    const validateForm = (values: any) => {
        let errors: FormikErrors<FormValues> = {};

        if (values.email == '' && values.phone == '') {
            errors.phone = 'Required field';
            errors.email = 'Required field';
        }

        if (values.email !== '' && !isValidEmail.test(values.email)) {
            errors.email = 'Please enter valid email address';
        }

        if (values.phone !== '' && !isTenDigitNumber.test(values.phone)) {
            errors.phone = 'Please enter valid US phone number';
        }
        if (values.email !== '') {
            if (!isValidEmail.test(values.email)) {
                errors.email = 'Please enter valid email address';
            }
        }

        if (values.email !== '' && values.phone !== '') {
            setError('Please use any one of the method to continue.');
        }

        return errors;
    };

    async function getAppointments(
        phone: string,
        email: string,
        minor: boolean,
        code: string
    ) {
        return new Promise(async (resolve, reject) => {
            let result: any;

            if (
                (email === '' && phone !== '') ||
                (email !== '' && phone === '')
            ) {
                setLoading(true);
                setError(null);
                setMinorAccount(minor);
                
                if (email) {
                     result = await getAppointmentByPhoneorEmail(
                        '',
                        email.trim().toLowerCase(),
                        minor,
                        code
                    );
                    setMinorEmail(email);
                } else if (phone) {
                    console.log(code);
                    result = await getAppointmentByPhoneorEmail(
                        phone.trim(),
                        '',
                        null,
                        code
                    );
                }
                setLoading(false);
                

                if (result.length > 0) {
                    setLoading(false);
                    updateResultsScreen(result);
                    goToPage(AdminManagePage.MangeResults);
                } else {
                    setError('Appointment is not found');
                }
            } else {
                setError('Pleasse use any one of the method to continue.');
            }
        });
    }
    return (
        <Container>
            <Content>
                <Formik
                    initialValues={{
                        email: '',
                        phone: '',
                        minor: false,
                        code: countryCode,
                    }}
                    onSubmit={async ({
                        phone,
                        email,
                        minor,
                        code,
                    }: {
                        phone: string;
                        email: string;
                        minor: boolean;
                        code: string;
                    }) => {
                        if (
                            (email === '' && phone !== '') ||
                            (email !== '' && phone === '')
                        )
                            getAppointments(phone, email.toLowerCase(), minor, code);
                        else
                            setError(
                                'Pleasse use any one of the method to continue.'
                            );
                    }}
                    enableReinitialize
                    validate={validateForm}
                >
                    {({ values }) => (
                        <Form>
                            <FormLabel label="Email">
                                <Input name="email" noBottomMargin />
                            </FormLabel>
                            <br />
                            <Checkbox name="minor">
                                <p> This is Minor account</p>
                            </Checkbox>
                            <Divider>
                                <DividerSpan>OR</DividerSpan>
                            </Divider>
                            <FormLabel label="Select Country code">
                                <input
                                    type="hidden"
                                    name="code"
                                    value={countryCode}
                                />
                                <IntlTelInput
                                    preferredCountries={['us']}
                                    style={phoneNumberStyle}
                                    inputClassName={classes.selectInput}
                                    onSelectFlag={(num: any, country: any) => {
                                        const selectedCode: any =
                                            '+' + country.dialCode;
                                        handleCountryCode(selectedCode);
                                        setPhoneNumber(
                                            selectedCode + ' ' + country.name
                                        );
                                    }}
                                    value={phoneNumber}
                                    placeholder="+1 United States States"
                                />
                                <Notice margin={'4px 0 16px'}>
                                    By default the country code is ( +1 - United
                                    States )
                                </Notice>
                                <Input
                                    name="phone"
                                    placeholder="Mobile Number"
                                    noBottomMargin
                                />

                                <Notice margin={'18px 0 35px'}>
                                    The same 10-digit number you used when
                                    scheduling.
                                </Notice>
                            </FormLabel>
                            <Button libraryType="primary" disabled={loading}>
                                {loading ? 'Processing...' : 'Verify Identity'}
                            </Button>
                            {error && <Error>{error}</Error>}
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );
};

export default ManageScreen;
