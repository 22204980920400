/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {
    updateAndRelaseAntigenResult,
    updateAntigenResultFalse,
} from '../../../api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { Modal, Select, Table } from 'antd';
import { config } from '../../../../../config';
import { ResultTable } from '../constants';
const { Option } = Select;
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface props {
    record: any;
    handleSearch: () => void;
}
export default function DialogSelect({
    open,
    handleClose,
    record,
    handleSearch,
}: any) {
    const [result, setResult] = React.useState<number | string>('');
    
    const [modal,setModal]=React.useState<{open:boolean, payload?:any, isOkBtn?:boolean, index?:number|null}>({open:false, payload:null, isOkBtn:false, index:null});   
    const [initialPanelTestResult, setInitialPanelTestResult] = React.useState<any[]>(record?.tests ?? []);
    console.log("KC initialPanelTestResult",initialPanelTestResult, "\n\n modal",modal,"\n\n record", record);
    
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: "",
        msg: "",
    });
    const [loading, setLoading] = React.useState(false);
    const { setAppointments } = useAdminDispatch();
    const { appointments } = useAdminState();
    const release = Boolean(record.isRelease);
    const updateResults = async () => {
        if (result || record.isPanel) {
            setalertbox({...alertbox,open:false});
            setLoading(true);
            try {
                let params: any = {
                    sampleId: record.sampleId,
                    result: result,
                    locationId : record.location.qbenchCustomerId,
                    cPartnerID : record.location.cPartnerID,
                    testType : record.isAntigen ? 'antigen' : record.isRapidTest ? 'rapid' : record.isPanel ? 'panel' : '',
                    email:JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))?.email ?? '',
                    isPanel:record.isPanel ?? false,
                    panelTestResult :initialPanelTestResult
                };
                if(record.isPanel)
                    delete params.result
                await updateAndRelaseAntigenResult(params).then(
                    (response: any) => {
                        if (!response.data?.status) {
                            setLoading(false);
                            setalertbox({open:true,type:'error',msg:response?.data?.message ?? "Something Went Wrong Please Contact Steve Wilson and Anton Co to get this fixed"})
                        } else {
                            setLoading(false);
                            handleClose();
                            let tableDatas: any = appointments;
                            tableDatas = appointments?.map((data: any) => {
                                let rowData: any = data;
                                if (record.sampleId === data.sampleId) {
                                    rowData = {
                                        ...rowData,
                                        isRelease: true,
                                        results: record.isPanel ? initialPanelTestResult.filter((e:any)=>e.result).length !== initialPanelTestResult.length ? '' : initialPanelTestResult[0]?.result ?? '' :  result,
                                        isResultReportGenerated: false,
                                        status: record.isPanel ? initialPanelTestResult.filter((e:any)=>e.result).length === initialPanelTestResult.length ? 'Completed' : '' : 'Completed',
                                        tests:initialPanelTestResult.map((e:any)=>({id:e.id,name:e.name,result:e?.result??''}))
                                    };
                                }
                                return rowData;
                            });
                            setAppointments(tableDatas);
                            //handleSearch();
                        }
                    }
                );
            } catch (error) {
                setalertbox({open:true,type:'error',msg:
                `Something Went Wrong. 
                 ${error}
                `});
            }
        } else {
            setalertbox({open:true,type:'warning',msg:'Please Choose the Result'});
        }
    };
    const getDropDown = (action: any, payload: any) =>{
        return <><Select
            style={{ width: '100%',marginBottom:10 }}
            disabled={loading || payload.result}
            allowClear
            placeholder={payload.result ? "Result Un-Released" : "Please select"}
            showArrow
            value={(initialPanelTestResult?.filter((e:any)=>e.id === payload.id && !e?.isUnReleaseResult)?.[0]?.result ?initialPanelTestResult?.filter((e:any)=>e.id === payload.id && !e?.isUnReleaseResult)?.[0]?.result : null) ?? null }
            onChange={(value: string) => { 
                setInitialPanelTestResult((res:any)=>{
                    return res?.map((e:any, index:number)=>{
                        if(e.id === payload.id)
                            return {...payload, result:value}
                        else
                            return {...e};
                    }) || []
                })
                
            }}
        >
            {config.RESULTOPTIONS.map((result:string, index:number)=><Option key={index} value={result}>{result}</Option>)}
        </Select>
        {payload.result && !initialPanelTestResult.filter(e=>e.id === payload.id)?.[0]?.isUnReleaseResult && 
            <a onClick={()=>{
            setModal({open:true, payload:[payload], isOkBtn:false, index:payload?.index })
        }}>Un-Release Result</a>}
        </>
    }

    const updateAntigenFalse = async () => {
        setLoading(true);
        try {
            let params: any = {
                sampleId: record.sampleId,
            };
            await updateAntigenResultFalse(params).then((response: any) => {
                if (!response.data) {
                    setalertbox({ open: true, type: 'success', msg: 'Result unreleased successfully ' });
                    setLoading(false);
                    handleClose();
                    let tableDatas: any = appointments;
                    tableDatas = appointments?.map((data: any) => {
                        let rowData: any = data;
                        if (record.sampleId === data.sampleId) {
                            rowData = {
                                ...rowData,
                                isRelease: false,
                                results: '',
                                isResultReportGenerated: false,
                                status: '',
                            };
                        }
                        return rowData;
                    });
                    setAppointments(tableDatas);
                    //handleSearch();
                } else {
                    setalertbox({ open: true, type: 'error', msg: 'Something Went Wrong Please Contact Steve Wilson and Anton Co to get this fixed' });
                    setLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <Modal
                okButtonProps={{ loading: loading }}
                cancelButtonProps={{ loading: loading }}
                centered
                keyboard={false}
                maskClosable={false}
                visible={open}
                width={500}
                onCancel={() => {
                    if (!loading) {
                        handleClose();
                    }
                }}
                onOk={async () => {
                    if(record.isPanel)
                    {
                        if(initialPanelTestResult.filter((e:any, index:any)=>((e.result ? e.result : null) !== (record?.tests?.[index]?.result ? record?.tests?.[index]?.result : null ))).length)
                        {
                            const data = initialPanelTestResult.filter((data:any, index:number)=>(data.result ? data.result : null) !== (record.tests[index]?.result ? record.tests[index]?.result : null));
                            if(data.filter((e:any)=>!e.isUnReleaseResult).length)
                                setModal({open:true, payload:data, isOkBtn:true})
                            else
                            {
                                updateResults()                                
                            }
                        }
                        else
                        {
                            setalertbox({open:true, type:'warning',msg:'Changes not detected'})
                        }
                    }
                    else if(release)
                        setModal({...modal,open:true,payload:null})
                    else
                        setModal({...modal,open:true,payload:null})
                }}
                okText={
                    loading
                        ? 'Updating...'
                        : record.isPanel ? record.tests.filter((e:any)=>e.result).length === record.tests.length ? 'Unrelease Result' : initialPanelTestResult.filter((e:any)=>e.result).length === record.tests.length ? 'Release Result' : 'Save Result' : release
                        ? 'Unrelease Result'
                        : 'Release Result'
                }
                closable={false}
            >
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={alertbox.open}
                    autoHideDuration={6000}
                    onClose={() => setalertbox({ open: false, type: '', msg: '' })}
                >
                    <Alert
                        onClose={() =>
                            setalertbox({ open: false, type: '', msg: '' })
                        }
                        severity={alertbox.type}
                    >
                        <div dangerouslySetInnerHTML={{__html:alertbox.msg}}></div>
                    </Alert>
                </Snackbar>
                {record.isPanel ? 
                <>
                        <h3>{record.tests.filter((e:any)=>e.result).length===0 ?'Release': record.tests.filter((e:any)=>e.result).length === record.tests.length ? 'Un-Release' : 'Update'} Results for <b>{record.sampleId}</b></h3>
                        <hr />
                        <br />
                    <Table pagination={false} columns={ResultTable(getDropDown) as any} dataSource={record.tests.map((e:any, index:number)=>({...e, index})) || []}/>
                </>: (
                    <>
                        <h3>{release?'Un-':''}Release Result for <b>{record.sampleId}</b></h3>
                        <hr />
                        <br />
                        {release?<>
                        Un-Release the result <b>{record.results}</b> for sampleId <b>{record.sampleId}</b>
                        </>
                        :<Select
                            style={{ width: '100%' }}
                            disabled={loading}
                            allowClear
                            placeholder="Please select"
                            showArrow
                            onChange={(value: string) => { setResult(value); if (value) setalertbox({...alertbox,open:false}) }}
                        >
                            {config.RESULTOPTIONS.map((result:string, index:number)=><Option key={index} value={result}>{result}</Option>)}
                        </Select>}
                    </>
                )}
            </Modal>

            {modal.open &&

                <Modal
                // okButtonProps={{ loading: loading }}
                // cancelButtonProps={{ loading: loading }}
                centered
                keyboard={false}
                maskClosable={false}
                visible={modal.open}
                width={500}
                onCancel={() => {
                    if (!loading) {
                        setModal({...modal,open:false, isOkBtn:false,})
                        // handleClose();
                    }
                }}
                onOk={
                    async () => {
                        if(!record?.isPanel){
                            if (release) {
                                setModal({...modal,open:false, isOkBtn:false})
                                updateAntigenFalse();
                            } else {
                                setModal({...modal,open:false, isOkBtn:false})
                                updateResults();
                            }
                        }else{
                            setInitialPanelTestResult((res:any)=>{
                                return res?.map((e:any)=>{
                                    if(e.id === record.tests[modal?.index ?? 0]?.id && !modal.isOkBtn)
                                        return {...record.tests[modal?.index ?? 0] ?? {},isUnReleaseResult:true,result:'' }
                                    else
                                        return e;
                                }) || []
                            })
                            setModal({...modal,open:false, isOkBtn:false})
                            if(modal.isOkBtn)
                                updateResults()
                        }
                    }
                }   
                okText={'Yes'}
                cancelText={'No'}
                closable={false}
            > 
            {(record.isPanel ? !modal.isOkBtn : release)  ? <h3>Are you sure you want to un-release the result <b>{record.isPanel ? record.tests?.[modal?.index ?? 0]?.result : record.results}</b> for {record.isPanel ? 'Test' : 'Sample'} <b>{record.isPanel ? record.tests?.[modal?.index ?? 0]?.id+" ("+record.tests?.[modal?.index ?? 0]?.name+") " : record.sampleId}</b></h3>:
            modal.isOkBtn ? <ul>
                {modal.payload.filter(((e:any)=>!e.isUnReleaseResult)).map((e:any)=>{                
                    return <li>
                            <h3>Are you sure you want to release the result for Test <b>{e.id+" ("+e.name+") "}</b> as <b>{e.result}</b></h3>
                        </li>
                })}
                </ul> : <h3>Are you sure you want to release the result for Sample <b>{record.sampleId}</b> as <b>{result}</b></h3>
            }
           </Modal>
            }
        </div>
    );
}
