import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useAuth0 } from "@auth0/auth0-react"; // Auth0-imp
import { isExpired, decodeToken } from "react-jwt";
import {
	Button,
	Container,
	Content,
	ErrorText,
	PageHeader,
	PageTitle,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { signIn } from '../api';

import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Header from '../../shared/components/Header';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import { config } from '../../../config';
import { useAdminDispatch, useAdminState } from '../provider';

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

const Login: React.FC = () => {
	const { updateLoginError } = useAdminDispatch();
	let { user, isAuthenticated, logout, getAccessTokenSilently, loginWithRedirect, error } : any = useAuth0();
	const [loading, setLoading] = useState(false);


	const getToken = async () => {
		// setLoginError(false);
		// setLoading(false);
		const accessToken = await getAccessTokenSilently({
			audience: `https://dev-lixcq4a8.us.auth0.com/api/v2/`,
			scope: "read:current_user",
		});
		window.localStorage.setItem('accessToken', JSON.stringify({
			accessToken,
			sub:user.sub
		}));
	}
	if (isAuthenticated) {
		setLoading(true); 
		getToken();
		updateLoginError(false);
		window.localStorage.setItem('userDetails', JSON.stringify(user));
		window.localStorage.setItem('Access', JSON.stringify({
			Access: false
		}));
		window.location.href = '/dashboard';
	} else if(!isAuthenticated && error !== undefined){
		updateLoginError(true);
		setLoading(false);
		window.localStorage.removeItem('accessToken');
		window.localStorage.removeItem('userDetails');
		logout({ returnTo: config.auth0Redirection.login });

	}

	useEffect(() => {
		if(!isAuthenticated)
			loginWithRedirect();
	}, [isAuthenticated])

	

	return (
		<div>
			{/* <Header isAdmin /> */}
			<Container>
				{/* <Content>
					<PageHeader>
					<PageTitle>Login to Worksitelabs Admin Portal</PageTitle>
					</PageHeader>
					<Button  libraryType="primary" onClick={() => {
						if(loading == false){
							loginWithRedirect()
						}
					}}>
					{loading ? 'Processing...' : 'Login'}
					</Button>
					<div>
						{isLoginError && <ErrorText hasError>Access Denied</ErrorText>}
					</div>
				</Content> */}
			</Container>
			<Footer />
		</div>

	);
};

export default Login;
