import axios from "axios";
import {firebase} from "../../../../firebase";
import {config} from "../../../../config";
import { servicedata } from "./Model";
export const getApiData = async (url:any) => {
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/'+url,
        headers: {
            'content-type': 'application/json'
        }
    });
    return responsedata;
}

export const postService = async (data: servicedata,id:null|string=null) => {
    
    let price = 0;
    if(data.price!=='')
        price = parseInt(data.price);
    data = {...data,time:data.time.value}
    let responsedata;
    if(id===''||id===null)
    {
        responsedata = await axios({
            method: 'POST',
            url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/postServices',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                name: data.name,
                time: data.time,
                price,
                description: data.description
            }
        });
    }
    else
    {
        responsedata = await axios({
            method: 'POST',
            url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/updateServices',
            headers: {
                'content-type': 'application/json'
            },
            data: {
                id: id,
                name: data.name,
                time: data.time,
                price,
                description: data.description
            }
        });
    }
    return responsedata;
}


export const modifyLocations = async (data: any) => {
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/postorupdateLocation',
        headers: {
            'content-type': 'application/json'
        },
        data:data
    });
    console.log('response data',responsedata)
    console.log('data ......',data)
    return responsedata;
}

// export const editSlotsConfiguration = async (data: any) => {
//     const responsedata = await axios({
//         method: 'POST',
//         url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/editSlotsConfiguration',
//         headers: {
//             'content-type': 'application/json'
//         },
//         data:data
//     });
//     console.log('response data',responsedata)
//     console.log('data ......',data)
//     return responsedata;
// }

export const editSlotsConfigurationDummy = async (data: any)=>{
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/editSlotsConfigurationDummy',
        headers: {
            'content-type': 'application/json'
        },
        data:data
    });
    console.log('response data',responsedata)
    console.log('data ......',data)
    return responsedata;
}

export const addHolidays = async (datas: any) => {
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/addBlockOutDays',
        headers: {
            'content-type': 'application/json'
        },
        data:{
            data:datas
        }
    });
    console.log('response data',responsedata)
    console.log('data ......',datas)
    return responsedata;
}

export const loadstate=()=>
    firebase.firestore().collection(config.firestoreCollections.state).get().then((response:any)=>{
        const data: any = response.docs.map(
            (doc: any) => doc.data()
        );
        return data   
    })

export const loadCountry=()=>
    firebase.firestore().collection(config.firestoreCollections.country).get().then((response:any)=>{
        const data: any = response.docs.map(
            (doc: any) => doc.data()
        );
        return data   
    })

export const getJotFormConfigList = async () => {
    try {
        const responseData = await axios({
            method: 'POST',
            url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getJotFormConfig',
            headers: {
                'content-type': 'application/json'
            },
            data: { data: {} }
        });
        console.log(responseData);

        return responseData;
    }
    catch (error) {
        console.log(error);
    }
}

export const storeJotFormConfig = async (data:any) => {
    try {
        const responseData = await axios({
            method: 'POST',
            url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/storeJotFormConfig',
            headers: {
                'content-type': 'application/json'
            },
            data: {data: data}
        });
        console.log(responseData);

        return responseData;
    }
    catch (error) {
        console.log(error);
    }
}
export const getQbenchCustomers= async() => {
    try{
        const responseData = await axios({
           method: 'POST',
           url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getQbenchCustomerList', 
           headers: {
               'content-type': 'application/json'
           },
           data: {data: {}}
        });
        return responseData;
    }
    catch(error){
        console.log(error);
    }

}
export const addLocation= async(data:any) => {
    try{
        const responseData = await axios({
           method: 'POST',
           url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/addLocation', 
           headers: {
               'content-type': 'application/json'
           },
           data:  {data}
        });
        return responseData;
    }
    catch(error){
        console.log(error);
    }

}
   
export const getAssayList = async () => {
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getQbenchAssayList',
        headers: {
            'content-type': 'application/json'
        },
        data: {data: {}}

    });
    return responsedata;
}

export const getLocation = async () => {
    const responsedata = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getLocation',
        headers: {
            'content-type': 'application/json'
        },
        data: {data: {}}

    });
    return responsedata;
}

export const getTelemedicineAppointment = async (data: any) => {
    let URL = "https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getTelemedicineAppointmentForAdmin";
    // let URL = "http://localhost:5001/wsl-multitenancy-dev-ac13b/us-central1/getTelemedicineAppointmentForAdmin";
    const responsedata = await axios({
        method: 'POST',
        url: URL,
        headers: {
            'content-type': 'application/json'
        },
        data: { data }

    });
    return responsedata;
}

export const updateGeneralLocation = async (params:any) =>
  firebase
    .functions()
    .httpsCallable('updateGeneralLocation', { timeout: 540 * 1000 })(params);

export const getLocationCpartnerId = async (params:any) =>
  firebase
    .functions()
    .httpsCallable('getLocationCpartnerId', { timeout: 540 * 1000 })(params);

export const getTelemedicineAppointmentAccept = async (data:any) => firebase
    .functions()
    .httpsCallable('getTelemedicineAppointmentAccept')(data)

export const getTelemedicineInfo = async (data:any) => firebase
    .functions()
    .httpsCallable('getTelemedicineInfo')(data) 

export const addTelemedicineApptViewLog = async (data:any) => firebase
    .functions()
    .httpsCallable('addTelemedicineApptViewLog')(data) 

export const getTelemedicineViews = async (data:any) => firebase
    .functions()
    .httpsCallable('getTelemedicineAppointment')(data) 

export const updateTelemedicineInfo = async (data:any) => firebase
    .functions()
    .httpsCallable('updateTelemedicineInfo')(data) 

export const TelemedicineApptNoteLogs = async (data:any) => firebase
    .functions()
    .httpsCallable('TelemedicineApptNoteLogs')(data) 