export default {
  schedule: '/',
  admin: '/admin',
  manage: '/manage',
  report: '/report',
  dashboard: '/dashboard',
  consolidationreport: '/consolidationreport',
  commonpass: {
    default: '/common-pass/login',
    authorize: '/common-pass/authorize',
  },
  clearpass: {
    default: '/clear-pass/login',
    authorize: '/clear-pass/authorize',
  },
};
