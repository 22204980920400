import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import { useStepsState, useStepsDispatch } from '../../../../shared/providers/schedule/provider';
import { Slot } from '../../../../shared/providers/schedule/models';

import Modal from '../../../../shared/components/Modal';
import Slots from '../../../../shared/components/Slots';
import { colors } from '../../../../../styles/colors';
import { Button } from '../../../../shared/components/styled';
import { Minor } from '../../../../shared/models';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { updateAppointmentdata } from '../../../api';
import { format, parse } from 'date-fns';
import { config } from '../../../../../config';
const Container = styled.div`
  max-width: 700px;
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const ChangeDateTimeModal: React.FC<Props> = ({ showModal, closeModal }) => {
    const { updateFormValues } = useStepsDispatch();
    const { Editappointments } = useAdminState();
    const {setEditAppointment} = useAdminDispatch()
    const appointmentdata: any = Editappointments !== null ? Editappointments[0] : null
    const [loading, setloading] = React.useState<boolean>(false)
    let newSlot: Slot | null = appointmentdata.slot;
    localStorage.setItem('desiredSlot', '')

    return (
        <Modal open={showModal} onClose={closeModal}>
            <Container>
                <Title>Change date & time</Title>
                <Formik
                    initialValues={{ slot: appointmentdata?.slot }}
                    onSubmit={async() => {
                        
                        if (localStorage.getItem('desiredSlot') !== '' && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot')))) !== null) {
                            appointmentdata.slot = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot'))))
                            appointmentdata.date = appointmentdata.slot.date
                        }

                        appointmentdata.minors.map((e: any) => e.birthDate = e.birthDate
                            ? (Object.keys(e.birthDate).length) > 0 ? e.birthDate : "01/01/1901" //format(e.birthDate, config.dateFormat)
                            : appointmentdata.birthDate)


                        let params = {
                            ...appointmentdata,
                            note:appointmentdata?.note?appointmentdata?.note:''
                            }
                        setloading(true)
                        await updateAppointmentdata(params);
                        setloading(false)
                        appointmentdata.minors.map((e: any) => e.birthDate = e.birthDate
                            ? parse(e.birthDate, config.dateFormat, new Date())
                            : null)

                        closeModal();
                    }}
                >
                    {() => (
                        <Form>
                            <Slots
                                isAdmin={true}
                                alwaysShowDatePicker
                                appointment={appointmentdata}
                                isWalkUpTest={appointmentdata?.isWalkUpTest}
                                onFieldUpdate={(update) => {
                                    newSlot = { ...update.slot } as Slot;
                                    updateFormValues({ slot:appointmentdata.isReelHealth? {...appointmentdata.slot,date:update.date}:update.slot });
                                    setEditAppointment([appointmentdata.isReelHealth?{...appointmentdata,slot:{...appointmentdata.slot,date:update.date}}:{...appointmentdata,...update}])
                                }}

                                onChangeDate={(newDepartureDate) => {
                                    console.log("reached reelhealth")
                                    appointmentdata.date = format(newDepartureDate, config.dateFormat)
                                    setEditAppointment([{...appointmentdata,slot:undefined,date: format(newDepartureDate, config.dateFormat)}])
                                    updateFormValues({
                                        slot: undefined,
                                        date: format(newDepartureDate, config.dateFormat),
                                    });
                                }}
                            />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button disabled={loading} libraryType="primary" size="sm" type="submit">
                                    {loading?'Processing...':'Change'}
                                </Button>
                            </ButtonsContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    );
};

export default ChangeDateTimeModal;
