import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { parse, format } from 'date-fns';
import { Button, ErrorText } from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import { config } from '../../../../../config';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Minor as IMinor } from '../../../../shared/providers/schedule/models';
import Modal from '../../../../shared/components/Modal';
import Input from '../../../../shared/components/form/Input';
import FormLabel from '../../../../shared/components/form/FormLabel';
import { DatepickerField } from '../../../../shared/components/Datepicker';
import { isUSPhone, isValidEmail } from '../../../../../validations';
import { useAdminState, useAdminDispatch } from '../../../provider';
import {
    useStepsDispatch,
    useStepsState,
} from '../../../../shared/providers/schedule/provider';
import pin from '../../../../../assets/pin-grey.svg';
import calendar from '../../../../../assets/calendar-grey.svg';
import clock from '../../../../../assets/clock.svg';
import person from '../../../../../assets/person-grey.svg';
import Select from '../../../../shared/components/form/Select';
import { Breakpoints } from '../../../../../../src/dictionaries';
import { ageCalculator } from '../../../../../../src/utils';
import ZipCodeInput from '../../../../shared/components/Address/ZipCodeInput';
import { states } from '../../../../shared/components/Address/states';
import { updateAppointmentdata } from '../../../api';
import Checkbox from '../../../../shared/components/form/Checkbox';
import { country } from '../../../../shared/components/PersonalInformation/PassportCountry';
import { countryAddress } from '../../../../shared/components/Address/country';
import { makeStyles } from '@material-ui/core/styles';
import EditAppoinment from './EditAppointment';
import { isDate } from 'lodash';

const Container = styled.div``;

const Title = styled.h2`
    color: ${colors.darkBlue};
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 13px;
`;

const CityStateRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:first-child {
        width: 48%;
    }

    & > *:last-child {
        width: 48%;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 240px;
    margin: 30px -5px 0;

    & > * {
        margin: 0 5px;
    }
`;
const TestingInfoBtn = styled(Button)`
    max-width: 200px;
`;

export const TestingInfoContainer = styled.div`
    background: ${colors.blue8};
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 6px;

    & > * {
        &:first-child {
            margin-bottom: 20px;
        }
    }

    @media (min-width: ${Breakpoints.sm}px) {
        flex-direction: row;
        padding: 30px;
        justify-content: space-between;

        & > * {
            &:first-child {
                margin-bottom: 0;
            }
        }
    }
`;

export const Icon = styled.div`
    position: absolute;
    left: 0;
    top: 1px;
`;

export const PinIcon = styled(Icon)`
    left: 5px;
    width: 14px;
    height: 20px;
    background: url(${pin}) no-repeat center center;
`;

export const CalendarIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${person}) no-repeat center center;
`;

export const TestingInfoRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: -8px 0;

    & > * {
        margin: 8px 0;
    }

    @media (min-width: ${Breakpoints.md}px) {
        flex-direction: row;
        margin: 0 -30px;

        & > * {
            margin: 0 30px;
        }
    }
`;

export const TestingInfo = styled.div`
    position: relative;
    padding-left: 31px;

    svg {
        width: 24px;
        height: 24px;
    }
`;

const useStyles = makeStyles({
    countryDropDown: {
        borderRadius: '10px',
        width: '100%',
        padding: '18px',
        border: '1px solid #B4B4BB',
        margin: '0px 0px 25px 0px',
    },
    countryDropDownerror: {
        borderRadius: '10px',
        width: '100%',
        padding: '18px',
        border: '1px solid red',
        margin: '0px 0px -2px 0px',
    },
    regionDropDown: {
        borderRadius: '10px',
        width: '100%',
        padding: '18px',
        border: '1px solid #B4B4BB',
        margin: '0px 0px 25px 0px',
    },
    regionDropDownerror: {
        borderRadius: '10px',
        width: '100%',
        padding: '18px',
        border: '1px solid red',
        margin: '0px 0px -2px 0px',
    },
});

const MinorsListWrapper = styled.div``;

const Minor = styled.div`
    padding: 25px 15px 20px;
    background: ${colors.blue8};
    display: inline-grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;

    & > * {
        width: 100%;
    }

    & input {
        padding: 15px 11px;
    }

    @media (min-width: ${Breakpoints.sm}px) {
        flex-direction: row;
        margin: 0 -8px;
        padding: 25px 15px 5px;

        & > * {
            margin: 0 8px;

            &:nth-child(1),
            &:nth-child(2) {
                flex-basis: 27%;
            }

            &:nth-child(3) {
                flex-basis: 24%;
            }

            &:nth-child(4) {
                flex-basis: 20%;
            }

            &:nth-child(5) {
                flex-basis: 3%;
            }
        }
    }

    @media (min-width: ${Breakpoints.md}px) {
        padding: 5px;
        margin: 0 -15px;

        & > * {
            margin: 0 15px;
        }
    }
`;

const InputRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:first-child {
        width: 48%;
        margin-right: 10px;
    }

    & > *:last-child {
        width: 48%;
        margin-left: 10px;
    }
`;
const PassportRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:first-child {
        width: 48%;
    }

    & > *:last-child {
        width: 48%;
    }
`;

// const inputname = classes.inputname'

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const EditAppointmentModal: React.FC<Props> = ({ showModal, closeModal }) => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const { form } = useStepsState();
    const { setEditAppointment } = useAdminDispatch();
    const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
    const { Editappointments } = useAdminState();
    const [checkState, setCheckState] = useState(false);
    const appointmentdata: any =
        Editappointments !== null ? Editappointments[0] : null;
    const [statename, setstatename] = useState(
        Editappointments !== null && Editappointments !== undefined
            ? Editappointments[0]?.address?.state
                ? Editappointments[0]?.address?.state
                : ''
            : ''
    );
    const [countryname, setcountryname] = useState(
        Editappointments !== null && Editappointments !== undefined
            ? Editappointments[0]?.address?.country
                ? Editappointments[0]?.address?.country
                : ''
            : ''
    );
    const [zipcodevalue, setzipcodevalue] = useState(
        Editappointments !== null && Editappointments !== undefined
            ? Editappointments[0]?.address?.zipCode
                ? Editappointments[0]?.address?.zipCode
                : ''
            : ''
    );
    useEffect(() => {
        appointmentdata.minors.map(
            (e: any) =>
                (e.birthDate =
                    e.birthDate !== null &&
                    e.birthDate !== undefined &&
                    Object.keys(e.birthDate).length > 0
                        ? parse(e.birthDate, config.dateFormat, new Date())
                        : new Date())
        );
    }, []);

    console.log('appointmentdata', appointmentdata);
    const updateAddressValue = (newValue: any) => {
        updateFormValues({
            address: {
                ...appointmentdata.address,
                ...newValue,
            },
        });
    };

    console.log(
        'appointmentdata?.address.state',
        appointmentdata?.address.state
    );
    const classes = useStyles();
    return (
        <Modal open={showModal} onClose={closeModal} maxWidth={800}>
            <Container>
                <Title>Edit basic Info</Title>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        firstName: appointmentdata?.firstName,
                        middleName: appointmentdata?.middleName,
                        lastName: appointmentdata?.lastName,
                        birthDate: appointmentdata?.birthDate
                            ? parse(
                                  appointmentdata.birthDate,
                                  config.dateFormat,
                                  new Date()
                              )
                            : null,
                        email: appointmentdata?.email,
                        phone: appointmentdata?.phone,
                        address: appointmentdata?.address,

                        country: appointmentdata?.address.country
                            ? appointmentdata?.address.country !==
                                  'United States' &&
                              appointmentdata?.address.country !== 'CA'
                                ? appointmentdata.address.country
                                : countryAddress.filter((o) =>
                                      Object.values(o).includes(
                                          appointmentdata?.address.country
                                      )
                                  )[0]
                            : countryAddress[0],
                        zipCode: zipcodevalue,
                        add123: appointmentdata?.address?.country
                            ? appointmentdata?.address?.country !==
                                  'United States' &&
                              appointmentdata?.address?.country !== 'CA'
                                ? appointmentdata?.address.state
                                : appointmentdata?.address?.country ===
                                      'United States' ||
                                  appointmentdata?.address?.country === 'US'
                                ? states['United States'].filter((o) =>
                                      Object.values(o).includes(
                                          appointmentdata?.address.state
                                      )
                                  )[0]
                                : states.CA.filter((o) =>
                                      Object.values(o).includes(
                                          appointmentdata?.address.state
                                      )
                                  )[0]
                            : states['United States'].filter((o: any) =>
                                  Object.values(o).includes(
                                      appointmentdata?.address.state
                                  )
                              )[0],

                        minors: appointmentdata?.minors,
                        passportCountry: country.filter((o) =>
                            Object.values(o).includes(
                                appointmentdata?.passportCountry?.value
                            )
                        )[0],
                        passportNo: appointmentdata?.passportNo,
                        travelType: appointmentdata?.travelType,
                        isINTNameFormat: appointmentdata?.isINTNameFormat,
                    }}
                    onSubmit={async (values) => {
                        if (!statename) {
                            setCheckState(true);
                        } else {
                            setLoading(true);
                            setCheckState(false);
                            appointmentdata.firstName = values.firstName;
                            appointmentdata.lastName = values.lastName;
                            appointmentdata.middleName = values.middleName;
                            appointmentdata.isINTNameFormat =
                                values.isINTNameFormat;

                            appointmentdata.birthDate = values.birthDate
                                ? format(values.birthDate, config.dateFormat)
                                : appointmentdata.birthDate;                                  
                            appointmentdata.email = values.email;
                            if(values.phone.startsWith('+'))
                                appointmentdata.phone = values.phone;
                            else
                                appointmentdata.phone = "+"+values.phone;
                            appointmentdata.address = values.address;
                            appointmentdata.address.state = statename;
                            appointmentdata.address.country = countryname;
                            appointmentdata.address.zipCode = zipcodevalue;
                            appointmentdata.passportCountry =
                                values.passportCountry;
                            appointmentdata.passportNo = values.passportNo;

                            appointmentdata.minors = values.minors;

                            await appointmentdata.minors.map(
                                (e: any) =>
                                    (e.birthDate = e.birthDate ? format(e.birthDate, config.dateFormat) : appointmentdata.birthDate)
                            );
                            // appointmentdata.minors.map((e: any) =>
                            //     console.log('e.birthDate', e.birthDate)
                            // );
                            let params = {
                                ...appointmentdata,
                                note: appointmentdata?.note
                                    ? appointmentdata?.note
                                    : '',
                            };
                            await updateAppointmentdata(params);
                            appointmentdata.minors.map(
                                (e: any) =>
                                    (e.birthDate =
                                        e.birthDate !== null &&
                                        e.birthDate !== undefined &&
                                        Object.keys(e.birthDate).length > 0
                                            ? parse(
                                                  e.birthDate,
                                                  config.dateFormat,
                                                  new Date()
                                              )
                                            : new Date())
                            );
                            setLoading(false);
                            closeModal();
                        }
                    }}
                >
                    {({props,values}:any) => (
                        <Form>
                            <InputRow>
                                {appointmentdata.travelType === 'INT' ? (
                                    <Checkbox
                                        name="isINTNameFormat"
                                        onChange={(checked) => {
                                            props.setFieldValue(
                                                'INTNameFormat',
                                                checked
                                            );
                                            updateFormValues({
                                                isINTNameFormat: checked,
                                            });
                                        }}
                                    >
                                        Don't have middleName? Please click here
                                    </Checkbox>
                                ) : (
                                    false
                                )}
                            </InputRow>
                            <InputRow>
                                <FormLabel label="First name">
                                    <Input name="firstName"
                                    value={values.firstName??''}
                                    isRequired />
                                </FormLabel>
                                <FormLabel label="Middle name">
                                    <Input
                                        name="middleName"
                                        value={values.middleName??''}
                                        validate={(value) => {
                                            if (
                                                appointmentdata.travelType !==
                                                    undefined &&
                                                appointmentdata.travelType !==
                                                    null &&
                                                appointmentdata.travelType ===
                                                    'INT' &&
                                                !props.values.isINTNameFormat &&
                                                !value
                                            ) {
                                                return 'Required field';
                                            }
                                        }}
                                    />
                                </FormLabel>
                                <FormLabel label="Last name">
                                    <Input name="lastName"
                                    value={values.lastName??''}
                                    isRequired />
                                </FormLabel>
                            </InputRow>
                            <InputRow>
                                <FormLabel label="Date of birth">
                                    <DatepickerField
                                    value={isDate(values.birthDate)?format(values.birthDate,config.dateFormat):typeof(values.birthDate==='string')?values.birthDate:''}
                                        inputPlaceholder="MM/DD/YYYY"
                                        name="birthDate"
                                    />
                                </FormLabel>

                                {/* {appointmentdata?.minorIndex === null && ( */}

                                <FormLabel label="Email">
                                    <Input
                                        name="email"
                                        value={values.email??''}
                                        validate={(value) => {
                                            let error;

                                            if (!isValidEmail.test(value)) {
                                                error =
                                                    'Please enter valid email address';
                                            }

                                            if (value === '') {
                                                error = 'Required field';
                                            }

                                            return error;
                                        }}
                                    />
                                </FormLabel>
                            </InputRow>
                            {/* )}
             {appointmentdata?.minorIndex === null && ( */}
                            <FormLabel label="Phone">
                                {appointmentdata?.address.country ==
                                    'United States' ||
                                appointmentdata?.address.country == 'CA' ? (
                                    <Input
                                        name="phone"
                                        value={values.phone??''}
                                        validate={(value) => {
                                            let error;
                                            console.log(
                                                appointmentdata.travelType !==
                                                    undefined ,
                                                appointmentdata.travelType !==
                                                    null ,
                                                appointmentdata.travelType ===
                                                    'DOM' ,
                                                isUSPhone.test(value)
                                            )
                                            if (
                                                appointmentdata.travelType !==
                                                    undefined &&
                                                appointmentdata.travelType !==
                                                    null &&
                                                appointmentdata.travelType ===
                                                    'DOM' &&
                                                !isUSPhone.test(value)
                                            ) {
                                                error =
                                                    'Please enter valid US phone number';
                                            }

                                            return error;
                                        }}
                                    />
                                ) : (
                                    <Input
                                        name="phone"
                                        value={values.phone??''}
                                        validate={(value) => {
                                            let error;

                                            if (value === '') {
                                                error = 'Required field';
                                            }
                                            let valueArr = value.split('');
                                            //   if (!valueArr.includes('+')) {
                                            //     error = 'Please include country code';
                                            //   }

                                            if (
                                                valueArr.includes(' ') ||
                                                valueArr.includes('-') ||
                                                valueArr.includes('(') ||
                                                valueArr.includes(')')
                                            ) {
                                                error =
                                                    'Please avoid special characters and spaces.(Valid format is:3239205839)';
                                            }

                                            return error;
                                        }}
                                    />
                                )}{' '}
                            </FormLabel>

                            <FormLabel label="Street address">
                                <Input
                                value={values.address.address??''}
                                    onChange={(value) =>
                                        updateAddressValue({ address: value })
                                    }
                                    name="address.address"
                                    isRequired
                                />
                            </FormLabel>
                            {/* country state */}
                            <CityStateRow>
                                <FormLabel label="country">
                                    <CountryDropdown
                                        classes={
                                            countryname !== ''
                                                ? classes.countryDropDown
                                                : classes.countryDropDownerror
                                        }
                                        value={countryname}
                                        name="address.country"
                                        //   defaultOptionLabel="United States"
                                        onChange={(value) => {
                                            updateAddressValue({
                                                country: value,
                                            });
                                            setcountryname(value);
                                            setstatename('');
                                            setzipcodevalue('');
                                        }}
                                    />
                                    {countryname === '' && (
                                        <span
                                            style={{
                                                color: 'red',
                                                fontSize: '13px',
                                            }}
                                        >
                                            Required Field
                                        </span>
                                    )}
                                </FormLabel>

                                <FormLabel label="State">
                                    <RegionDropdown
                                        classes={
                                            statename !== ''
                                                ? classes.regionDropDown
                                                : classes.regionDropDownerror
                                        }
                                        name="add123"
                                        country={countryname}
                                        value={statename}
                                        valueType={appointmentdata?.isReelHealth ?'full':'short'}
                                        onChange={(value) => {
                                            updateAddressValue({
                                                state: value,
                                            });
                                            setstatename(value);
                                        }}
                                    />
                                    {statename === '' && (
                                        <span
                                            style={{
                                                color: 'red',
                                                fontSize: '13px',
                                            }}
                                        >
                                            Required Field
                                        </span>
                                    )}
                                </FormLabel>
                            </CityStateRow>
                            {/* city zip */}
                            <CityStateRow>
                                <FormLabel label="City">
                                    <Input
                                    value={values.address.city??''}
                                        onChange={(value) =>
                                            updateAddressValue({ city: value })
                                        }
                                        name="address.city"
                                        isRequired
                                    />
                                </FormLabel>
                                <ZipCodeInput
                                    updateAddressValue={updateAddressValue}
                                    updateCountyValue={updateAddressValue}
                                    onChange={(e: any) => {
                                        setzipcodevalue(e);
                                    }}
                                    countryname={countryname}
                                    value={zipcodevalue}
                                    isEdit={true}
                                />
                            </CityStateRow>

                            <PassportRow>
                                <FormLabel label="Passport Country">
                                    <Select<string>
                                        value={values.passportCountry??''}
                                        name="passportCountry"
                                        options={country}
                                        onChange={(value) =>
                                            updateFormValues({
                                                passportCountry: value,
                                            })
                                        }
                                        validate={(value) => {
                                            if (
                                                appointmentdata.travelType !==
                                                    undefined &&
                                                appointmentdata.travelType !==
                                                    null &&
                                                appointmentdata.travelType ==
                                                    'INT' &&
                                                !value
                                            ) {
                                                return 'Required field';
                                            }
                                        }}
                                    />
                                </FormLabel>
                                <FormLabel label="Passport #">
                                    <Input
                                    value={values.passportNo??''}
                                        onChange={(value) =>
                                            updateFormValues({
                                                passportNo: value,
                                            })
                                        }
                                        name="passportNo"
                                        validate={(value) => {
                                            if (
                                                appointmentdata.travelType !==
                                                    undefined &&
                                                appointmentdata.travelType !==
                                                    null &&
                                                appointmentdata.travelType ==
                                                    'INT' &&
                                                (!value || !value.trim().length)
                                            ) {
                                                return 'Required field';
                                            }
                                        }}
                                        isRequired
                                    />
                                </FormLabel>
                            </PassportRow>

                            {appointmentdata?.minors.length > 0 ? (
                                <Title>Minor Information</Title>
                            ) : (
                                false
                            )}

                            {values?.minors.map(
                                (minor: IMinor, index: number) => (
                                    <Minor key={index}>
                                        {/* <InputRow > */}
                                        <FormLabel label="First Name">
                                            <Input
                                                name={`minors.${index}.firstName`}
                                                value={minor.firstName??''}
                                                isRequired
                                                // onChange={(value) =>
                                                //   saveMinorDetails('firstName', index, value)
                                                // }
                                            />
                                        </FormLabel>
                                        <FormLabel label="Middle Name">
                                            <Input
                                                name={`minors.${index}.middleName`}
                                                value={minor?.middleName??''}
                                                validate={(value) => {
                                                    if (
                                                        appointmentdata.travelType !==
                                                            undefined &&
                                                        appointmentdata.travelType !==
                                                            null &&
                                                        appointmentdata.travelType ===
                                                            'INT' &&
                                                        !props.values
                                                            .isINTNameFormat &&
                                                        !value
                                                    ) {
                                                        return 'Required field';
                                                    }
                                                }}
                                            />
                                        </FormLabel>
                                        <FormLabel label="Last Name">
                                            <Input
                                                name={`minors.${index}.lastName`}
                                                value={minor.lastName??''}
                                                isRequired
                                            />
                                        </FormLabel>
                                        <FormLabel label="Date of Birth">
                                            <DatepickerField
                                                name={`minors.${index}.birthDate`}
                                                inputPlaceholder="MM/DD/YYYY"
                                                maxDate={new Date()}
                                                value={isDate(minor.birthDate)?format(minor.birthDate,config.dateFormat):typeof(minor.birthDate==="string")?minor.birthDate:''}
                                                isRequired
                                                validate={(value) => {
                                                    let error;

                                                    let date = ageCalculator(
                                                        value
                                                    );
                                                    if (
                                                        date.years >= 18 &&
                                                        value !== null
                                                    ) {
                                                        if (
                                                            (date.years == 18 &&
                                                                date.months !==
                                                                    0) ||
                                                            (date.months == 0 &&
                                                                date.days !== 0)
                                                        ) {
                                                            error =
                                                                'Minor must be under 18 years old';
                                                        } else if (
                                                            date.years > 18
                                                        ) {
                                                            error =
                                                                'Minor must be under 18 years old';
                                                        }
                                                    }

                                                    //Validate less then 5 years old
                                                    // if (date.years <= 5) {
                                                    //     error =
                                                    //         'Children under 5 years of age from the date of the appointment do not need a Covid test.';
                                                    // }

                                                    return error;
                                                }}
                                                onChange={(
                                                    value: Date | null
                                                ) =>
                                                    // saveMinorDetails('birthDate', index, value)

                                                    (appointmentdata.minors[
                                                        index
                                                    ].birthDate = value)
                                                }
                                            />
                                        </FormLabel>
                                        <FormLabel label="Relationship">
                                            <Input
                                                name={`minors.${index}.relationship`}
                                                value={minor.relationship??''}
                                                isRequired
                                                // onChange={(value) =>
                                                //   saveMinorDetails('relationship', index, value)
                                                // }
                                            />
                                        </FormLabel>

                                        <FormLabel label="Passport Country">
                                            <Select<string>
                                                name={`minors.${index}.passportCountry`}
                                                isRequired
                                                options={country}
                                                value={minor.passportCountry??''}
                                                validate={(value) => {
                                                    if (
                                                        appointmentdata.travelType !==
                                                            undefined &&
                                                        appointmentdata.travelType !==
                                                            null &&
                                                        appointmentdata.travelType ==
                                                            'INT' &&
                                                        !value
                                                    ) {
                                                        return 'Required field';
                                                    }
                                                }}
                                            />
                                        </FormLabel>
                                        <FormLabel label="Passport #">
                                            <Input
                                                name={`minors.${index}.passportNo`}
                                                value={minor.passportNo??''}
                                                validate={(value) => {
                                                    if (
                                                        appointmentdata.travelType !==
                                                            undefined &&
                                                        appointmentdata.travelType !==
                                                            null &&
                                                        appointmentdata.travelType ==
                                                            'INT' &&
                                                        !value
                                                    ) {
                                                        return 'Required field';
                                                    }
                                                }}
                                            />
                                        </FormLabel>

                                        {/* </InputRow> */}
                                    </Minor>
                                    
                                )
                            )}

                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        loading ||
                                        statename === '' ||
                                        countryname === ''
                                    }
                                    libraryType="primary"
                                    size="sm"
                                    type="submit"
                                >
                                    {loading ? 'Processing...' : 'Change'}
                                </Button>
                            </ButtonsContainer>
                        </Form>
                         )}
                </Formik>
                {error && <ErrorText hasError>{error}</ErrorText>}
            </Container>
        </Modal>
    );
};

export default EditAppointmentModal;
