import { Button, Select, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAdminDispatch, useAdminState } from '../../provider';
import { stylesForListing } from './styles';
import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getAllQbenchCustomerIdsFromLocation } from '../../api';
import {loadstate} from './API'
import "./addLocations.css"
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
};
@media (min-width: 450px) { flex-direction: row; }
`;

const Returnbtn = styled.div`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const ErrorContainer = styled.div`
font-size: 15px;
margin-bottom: 15px;
color: red;
opacity: 1;
margin-top: 10px !important;
transition: opacity 0.15s ease-in-out;
margin-left: 0px;
`;

const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;
const QbenchContainer = styled.div`
width: 800px;
`;

type QBenchSetupPage = {
  onBack: () => void,
  onNext: () => void,
  customerDropdown: any,
  assayDropdown: any,
  qbenchId : any,
  rapidAssayId : any,
  assayId : any,
  antigenAssayId : any,
}


const QBenchSetupPage: React.FC<QBenchSetupPage> = ({ onBack, onNext, customerDropdown, assayDropdown, qbenchId, assayId, rapidAssayId, antigenAssayId }) => {
  console.log("abc", customerDropdown, assayDropdown)
  const { locationData } = useAdminState();
  const { updateLocation } = useAdminDispatch();
  const { Option } = Select;
  const [qbenchCustomerId, setQbenchCustomerId] = useState<any>(null);
  const [qbenchStandardAssayId, setQbenchStandardAssayId] = useState<any>(null);
  const [qbenchRapidAssayId, setQbenchRapiddAssayId] = useState<any>(null);
  const [qbenchAntigenAssayId, setQbenchAntigendAssayId] = useState<any>(null);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState<boolean>(false);
  const [states, setstate] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [showStandardAssayDropdown, setShowStandardAssayDropdown] = useState<boolean>(false);
  const [showRapidAssayDropdown, setShowRapidAssayDropdown] = useState<boolean>(false);
  const [showAntigenAssayDropdown, setShowAntigenAssayDropdown] = useState<boolean>(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
  const [qbenchErr, setQbenchErr] = useState<boolean>(false);
  const [btnText, setBtnText]= useState('Next');
  const [,setDummy] = useState(false)
  const [alertbox, setalertbox] = useState({
    open: false,
    type: '',
    msg: '',
});
  const errorTxt = 'Please select Qbench customer';
  const classes = stylesForListing();

    useEffect(()=>{
      if(qbenchId.length !== 0){
        setQbenchCustomerId(qbenchId[0])
        handleCheck()
      }
    },[qbenchId])
  const nextHandler = () => {
    setBtnText('Processing...')
    if(!qbenchCustomerId && qbenchId.length === 0 ){
      setIsError(true);
      setBtnText('Next')
    } else if (!showUpdateConfirm) {
      setQbenchErr(true)
      setBtnText('Next')
    } else{
      const data = locationData.data;
    data.qbenchCustomerId = qbenchCustomerId?.record ? qbenchCustomerId?.record : qbenchId[0]?.record ? qbenchId[0]?.record : null;
    data.qbenchStandardAssayId = qbenchStandardAssayId?.record ? qbenchStandardAssayId?.record : assayId[0]?.record ? assayId[0]?.record : null;
    data.qbenchExpressAssayId = qbenchStandardAssayId?.record ? qbenchStandardAssayId?.record : assayId[0]?.record ? assayId[0]?.record : null;
    data.qbenchRapidAssayId = qbenchRapidAssayId?.record ? qbenchRapidAssayId?.record : rapidAssayId[0]?.record ? rapidAssayId[0]?.record : null;
    data.qbenchAntigenAssayId = qbenchAntigenAssayId?.record ? qbenchAntigenAssayId?.record : antigenAssayId[0]?.record ? antigenAssayId[0]?.record : null;
    data.qbenchCustomerOldIds=[qbenchCustomerId?.record ? qbenchCustomerId?.record : qbenchId[0]?.record ? qbenchId[0]?.record : null]
    updateLocation({
      ...locationData,
      data
    })
    console.log("qbenchsetuppage", locationData)
    onNext();
    }
  }
  const handleCustomerId=()=>{
    setShowCustomerDropdown(true);
     setIsError(false);
     setQbenchErr(false)
  }

  const handleCancel = async () =>{
    let newStates:any[] = []
     await loadstate().then((response: any) => {
      setstate(response)
      newStates=response
  })
  const states = newStates.filter(e=>e.value===locationData.data.state || e.label === locationData.data.state)[0]
    let cPartnerID = { label: locationData.data.cPartnerID ? locationData.data.cPartnerID : '', value: locationData.data.cPartnerID ? locationData.data.cPartnerID : '' };
    let state = { label: states.label, value: states.value };
    let qbenchCustomerOldIds =[{label: locationData.data.qbenchCustomerId ? locationData.data.qbenchCustomerId : '', value: locationData.data.qbenchCustomerId ? locationData.data.qbenchCustomerId : ''}] ;
    let country = { label: locationData.data.country ? locationData.data.country : 'United State', value: locationData.data.country ? locationData.data.country : 'United State' };
    let timezone = {label:locationData.data.timezone ? locationData.data.timezone : '',value:locationData.data.timezone ? locationData.data.timezone : ''};
    updateLocation({
      name: locationData.data.name,
      CLIA:locationData.data.CLIA,
      visibleSince: locationData.data?.visibleSince ? locationData.data?.visibleSince._seconds?new Date(locationData.data?.visibleSince._seconds*1000):new Date(locationData.data?.visibleSince): "",
      address1: locationData.data.address1 ? locationData.data.address1 : '',
      address2: locationData.data.address2 ? locationData.data.address2 : '',
      city: locationData.data.city ? locationData.data.city : '',
      zipcode: locationData.data.zipcode ? locationData.data.zipcode : '',
      locationlink: locationData.data.locationlink ? locationData.data.locationlink : '', 
      isTravel:  locationData.data.isTravel ? true : false,
      isGeneral: locationData.data.isGeneral ? true : false,
      isSchool: locationData.data.isSchool ? true : false,
      isWork: locationData.data.isWork ? true : false,
      isCommunity:  locationData.data.isCommunity ? true : false,
      rapidTest: locationData.data.rapidTest ? true : false,
      standardTest: locationData.data.standardTest ? true : false,
      expressTest: locationData.data.hasVipSlots ? true : false,
      isAntigen: locationData.data.isAntigen ? true : false,
      delayopenstartdate: locationData.data.startDate ? locationData.data.startDate : new Date(),
      startDate: locationData.data?.startDate ? locationData.data?.startDate._seconds ? new Date(locationData.data?.startDate._seconds*1000) : new Date(locationData.data?.startDate) : '',
      medicalDirector: locationData.data.medicalDirector ? locationData.data.medicalDirector : '',
      performingfacility:locationData.data.performingfacility,
      siteStatus: locationData.data.isOpened,
      tenentTypes: {
        travel: locationData.data.isTravel ? true : false,
        general: locationData.data.isGeneral ? true : false,
        school: {
            BRSSD: locationData.data?.cPartners.includes('BEL001'),
            PVSD: locationData.data?.cPartners.includes('POR001'),
            SUHSD: locationData.data?.cPartners.includes('SEQ001'),
            SCSD: locationData.data?.cPartners.includes('SCS001'),
            SSFSD: locationData.data?.cPartners.includes('SSF001'),
        },
        community: locationData.data.isCommunity ? true : false,
        work: locationData.data.isWork ? true : false,
    },cPartnerID,state,qbenchCustomerOldIds,country,timezone
    })
    onBack()
  }

  const handleCheck = async() => {
   
    if(!!qbenchCustomerId || !!qbenchId ){
      setBtnText('Processing...')
        const res = await getAllQbenchCustomerIdsFromLocation({});
       
        if (res.data.status === 200) {
            if (res.data.message) {
                let allOldCustomerIds = res.data.message.filter(
                    (v: any, i: any, a: any) => a.indexOf(v) === i
                );
                if (!!qbenchCustomerId) {
                  allOldCustomerIds.includes(qbenchCustomerId.record)
                  ? 
                  setalertbox({
                        open: true,
                        type: 'warning',
                        msg:
                            'This Qbench Customer Id is already used in other location',
                    })
                  : setShowUpdateConfirm(true);
                  setBtnText('Next')
                }else if (!!qbenchId) {
                  allOldCustomerIds.includes(qbenchId[0]?.record)
                  ? 
                  setalertbox({
                        open: true,
                        type: 'warning',
                        msg:
                            'This Qbench Customer Id is already used in other location',
                    })
                  : setShowUpdateConfirm(true);
                  setBtnText('Next')
                }
               
            } else {
                setalertbox({
                    open: true,
                    type: 'error',
                    msg: 'Internal error',
                });
                console.log('res :::', res.data);
                setBtnText('Next')
            }
        } else {
            setalertbox({
                open: true,
                type: 'error',
                msg: 'Internal error',
            });
            console.log('res :::', res.data);
            setBtnText('Next')
        }
    }        
  }
  interface IProps{
    title: string;
    dropdown: any;
    type: string
  }
  const dropdownPopup = ({dropdown, type, title}:IProps ) => {
    return(
      <Modal
            zIndex={1000}
            maskClosable={false}
            visible={true}
            onCancel={()=>{
              setShowCustomerDropdown(false);
              setShowStandardAssayDropdown(false);
              setShowRapidAssayDropdown(false);
              setShowAntigenAssayDropdown(false);
            }}
            onOk={() =>{
             title === 'Select QBench Customer ID' ?  handleCheck() : setDummy(false)
              setShowCustomerDropdown(false);
              setShowStandardAssayDropdown(false);
              setShowRapidAssayDropdown(false);
              setShowAntigenAssayDropdown(false);
            }}
            okText={'Update'}
        >
           <div style={{ zIndex: 3000, padding: '8px', width: '100%' }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        className={classes.fontweight700}
                    >
                        {title}
                    </Typography>
    <Select
            showArrow
            onChange={(value, option: any) => {
              if(type === "customer")setQbenchCustomerId({label: option.children, record: value});
              else if(type=== "assayStandard") setQbenchStandardAssayId({label: option.children, record: value})
              else if(type === "assayRapid") setQbenchRapiddAssayId({label: option.children, record: value});
              else if(type === "assayAntigen" ) setQbenchAntigendAssayId({label: option.children, record: value});
            }}
            style={{ width: '100%', padding: '2px', fontSize: '18px' }}
          >
            {dropdown && dropdown?.map((data: any, index: any) => {
              return (
                <Option
                  style={{ zIndex: 3000 }}
                  key={index}
                  value={data.record}
                >
                  {data.label}
                </Option>
              );
            })}
          </Select>
          </div>
          </Modal>
    )
  }

  return (
    <QbenchContainer>
     <Typography style={{width: '122%'}} variant="subtitle1" color="initial" className={classes.fontweight700}>
      <h2>
        <b>Add New Location - QBench Connections</b>
      </h2>
      </Typography>
      <div className={classes.QbenchLocationItem}>
        <div className={classes.flex35}>
          <h3>QBench Customer</h3>  
                  
        </div>
        <div className={classes.flex35}>
        {qbenchCustomerId?.label ? <div>{qbenchCustomerId?.label}</div> : <div>{qbenchId[0]?.label}</div>}
        </div>
        <div className={classes.flex30} style={{ justifyContent: 'flex-end' }}>
          
          <Button className={classes.QbenchLocationButton} 
          onClick={handleCustomerId}> Update QBench Customer</Button>
          {showCustomerDropdown &&
          dropdownPopup({type:"customer",title:"Select QBench Customer ID", dropdown: customerDropdown})}
        </div>

      </div>
      <div className={classes.QbenchLocationItem}>
        <div className={classes.flex35}>
          <h3>Standard/Express Assay</h3>
        </div>
        <div className={classes.flex35}>
        {qbenchStandardAssayId?.label ? <div>{qbenchStandardAssayId?.label}</div> : <div>{assayId[0]?.label}</div>}
        {assayId?.label && <div>{assayId.label}</div>}
        </div>
        <div className={classes.flex30} style={{ justifyContent: 'flex-end' }}>
          <Button className={classes.QbenchLocationButton} style={{minWidth: "200px"}}
          onClick= {()=>{setShowStandardAssayDropdown(true)}}> Update Assay</Button>
          {showStandardAssayDropdown &&
           dropdownPopup({type:"assayStandard",title:"select QBench Assay for Standard/Express", dropdown: assayDropdown})}
        </div>

      </div>
      <div className={classes.QbenchLocationItem}>
        <div className={classes.flex35}>
          <h3>Rapid Assay</h3>
        </div>
        <div className={classes.flex35}>
        {qbenchRapidAssayId?.label ? <div>{qbenchRapidAssayId?.label}</div> : <div>{rapidAssayId[0]?.label}</div> }
        </div>
        <div className={classes.flex30} style={{ justifyContent: 'flex-end' }}>
          <Button className={classes.QbenchLocationButton} style={{minWidth: "200px"}}
          onClick={()=>setShowRapidAssayDropdown(true)}> Update Assay</Button>
          {showRapidAssayDropdown &&
          dropdownPopup({type:"assayRapid",title:"select QBench Assay for Rapid", dropdown: assayDropdown})}
        </div>
      </div>
      <div className={classes.QbenchLocationItem}>
        <div className={classes.flex35}>
          <h3>Antigen Assay</h3>
        </div>
        <div className={classes.flex35}>
        {qbenchAntigenAssayId?.label ? <div>{qbenchAntigenAssayId?.label}</div> : <div>{antigenAssayId[0]?.label}</div> }
        </div>
        <div className={classes.flex30} style={{ justifyContent: 'flex-end' }}>
          <Button className={classes.QbenchLocationButton} style={{minWidth: "200px"}}
          onClick={()=>setShowAntigenAssayDropdown(true)}> Update Assay</Button>
          {showAntigenAssayDropdown &&
          dropdownPopup({type:"assayAntigen",title:"select QBench Assay for Antigen", dropdown: assayDropdown})}
        </div>
      </div>
      <div>{isError ?  <ErrorContainer>{errorTxt}</ErrorContainer> : '' }</div>
      <div > { qbenchErr ? <ErrorContainer>Please select different Qbench customer</ErrorContainer> : ''} </div>
        <ButtonContainer>
                      <Returnbtn className='btn-common' onClick={handleCancel}> Back</Returnbtn>
                      <Space />
                      <Confirmbtn disabled={btnText !== 'Next' ? true : false} className='btn-common btn-alter Mui-selected' type='submit' onClick={() => nextHandler()}>{btnText}</Confirmbtn>
        </ButtonContainer>
        {alertbox.open === true &&
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={alertbox.open}
          autoHideDuration={3000}
          onClose={() =>
            setalertbox({ open: false, type: '', msg: '' })
          }
        >
          <Alert
            onClose={() =>
              setalertbox({ open: false, type: '', msg: '' })
            }
            severity={alertbox.type}
          >
            {alertbox.msg}
          </Alert>
        </Snackbar>
      }
     </QbenchContainer>
  )
}

export default QBenchSetupPage