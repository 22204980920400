import React from 'react'
import {
    Chart as ChartJS,
    PointElement,LineElement
} from 'chart.js';
const Line = require('react-chartjs-2').Line;

ChartJS.register(PointElement,LineElement);

const StripeLineChart = ({rangeArray,isInsurance}:any) => {
    let {
        labelForInsuranceAndStripe,
        labelForNoInsurance,
        forNoInsuranceArray,
        forPreviousNoInsuranceArray,
        totalForInsuranceAndStripe,
        previousTotalForInsuranceAndStripe
    } = rangeArray;
    const labels = isInsurance ? labelForInsuranceAndStripe : labelForNoInsurance;
    const data = {
        labels,
        datasets: [
            {
                label: 'Selected range $',
                data: isInsurance ? totalForInsuranceAndStripe : forNoInsuranceArray,
                fill: true,
                borderColor: '#742774',
            },
            {
                label: 'Previous range $',
                data: isInsurance ? previousTotalForInsuranceAndStripe : forPreviousNoInsuranceArray,
                fill: true,
                backgroundColor:'#3CB371',
                borderColor: '#64E986',
            },
        ],
    };
  return (
    <Line data={data} />
  )
}

export default StripeLineChart