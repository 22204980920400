import React, { useMemo, useState } from 'react';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import styled from 'styled-components';
import { format, parse, isBefore, subHours, startOfDay } from 'date-fns';

import pin from '../../../../assets/pin-grey.svg';
import calendar from '../../../../assets/calendar-grey.svg';
import clock from '../../../../assets/clock.svg';
import person from '../../../../assets/person-grey.svg';

import {
	Container,
	Content,
	PageHeader,
	PageTitle,
	PageSubTitle,
	Button,
} from '../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../providers/schedule/provider';
import { Breakpoints } from '../../../../dictionaries';
import { config } from '../../../../config';
import { getPeriodsFromSchedule, slotsListPeriod } from '../../../../utils';

import Checkbox from '../../../shared/components/form/Checkbox';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import ChangeLocationModal from '../../../shared/components/ChangeLocationModal';
import ChangeDateTimeModal from './ChangeDateTimeModal';
import PaymentModal from './PaymentModal';
import ChangeApptAttendeeModal from './ChangeApptAttendeeModal';
import ChangeDepartureTimeModal from '../../../shared/components/ChangeDepartureTimeModal';
import FormLabel from '../../../shared/components/form/FormLabel';
import Datepicker, { DatepickerField } from '../../../shared/components/Datepicker';
import { cloneDeep } from 'lodash';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { checkReservationValidity } from '../../providers/schedule/api';
// import EditableInfo from './EditableInfo';

const Checkboxes = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 21px 0;
`;

export const TestingInfoContainer = styled.div`
  background: ${colors.blue8};
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;

    & > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;

export const PinIcon = styled(Icon)`
  left: 5px;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat center center;
`;

export const CalendarIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${person}) no-repeat center center;
`;

interface TestingInfoRow {
	width?: string;
}
export const TestingInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  width: ${({ width }: TestingInfoRow) => width || ""}

  & > * {
    margin: 8px 0;
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    margin: 0 -30px;

    & > * {
      margin: 0 30px;
    }
  }
`;

export const TestingInfo = styled.div`
  position: relative;
  padding-left: 31px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TestingInfoBtn = styled(Button)`
  max-width: 200px;
`;

const Error = styled.div`
  color: ${colors.red};
  margin: 0px 0px 10px 0px;
  text-align: center;
`;

interface ButtonProps {
	libraryType: 'primary' | 'default';
	accentColor?: string;
	size?: 'sm' | 'lg';
}


const ReturnButton = styled.button`
    border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
    font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
    line-height: 1.6;
    border-radius: 5px;
    padding: ${({ size }: ButtonProps) =>
		size === 'sm' ? '4px 15px' : '9px 20px'};
    font-weight: ${({ size }: ButtonProps) =>
		size === 'sm' ? 'normal' : 'bold'};
    width: 100%;
    text-align: center;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
		return libraryType === 'primary'
			? colors.white
			: accentColor || colors.darkBlue;
	}};
    background: ${({ libraryType, accentColor }: ButtonProps) =>
		libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
		libraryType === 'primary'
			? accentColor || colors.darkBlue50
			: colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
		return libraryType === 'primary'
			? colors.white
			: accentColor || colors.darkBlue50;
	}};
    }
`;


const InnerBox = styled.div`
    border: 1px solid rgb(26, 150, 219);
    margin: 0px 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    align-items: center;
    display: grid;
    background: ${({ selected }: { selected?: boolean }) => selected ? colors.blue : colors.white};
`;

const TestTypeTitle = styled.p`
    font-size: medium;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 15px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const TestCostLabel = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 30px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const TestDurationInfoLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 9px;
      font-weight: bold;
      color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};
    }
`;

// const EditableInfosWrapper = styled.div`
//   margin-top: 40px;
// `;

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);


const ConfirmAndPay: React.FC = () => {
	const {
		form: {
			commitToAttend,
			agreeToCancel,
			location,
			phone,
			date,
			slot,
			firstName,
			lastName,
			minors,
			isExpressSameDayTest,
			departureDateAndTime,
			isRapidTest,
			reservationId
		},
		form,
		prices,
		slotsList,
	} = useStepsState();
	const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
	const { upateTestType } = useSharedDispatch();
	const { standradTest } = useSharedState();

	const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showChangeApptAttendeeModal, setShowChangeApptAttendeeModal] = useState(false);
	const [showDepartureTime, setshowDepartureTime] = useState(false);
	const [departureDateAndTimedata, setdepartureDateAndTimedata] = useState<any>(departureDateAndTime
		? parse(departureDateAndTime, config.dateTimeFormat, new Date())
		: null);

	const [showDepartureDateandTime, setShowDepartureDateandTime] = useState(false);
	const rollbackValues = useMemo(() => cloneDeep(departureDateAndTime), [showDepartureDateandTime]);
	const rollbackSlot = useMemo(() => cloneDeep(slot), [showDepartureDateandTime]);
	const rollbackDate = useMemo(() => cloneDeep(date), [showDepartureDateandTime]);

	const [loading, setLoading] = useState<boolean>(false);

	const price = form.isExpressSameDayTest ? prices.expedited : form.isRapidTest ? prices.rapid : prices.standard;
	const amount = price + price * form.minors.length;


	//To set an error on the slots
	const [slotError, setSlotError] = useState<string>("")

	const selectedDate = date
		? parse(date, config.dateFormat, new Date())
		: new Date();

	const locationStartDate = location
		? parse(location.startDate, config.dateFormat, new Date())
		: null;
	return (
		<Container size="lg">
			<Content>
				<PageHeader>
					<PageTitle>Confirm Your Booking Appointment</PageTitle>
					<PageSubTitle>
						Please review and confirm the information below is correct by
						clicking the “BOOK APPOINTMENT” button.
					</PageSubTitle>
				</PageHeader>
				<Formik
					initialValues={{
						commitToAttend,
						agreeToCancel,
						phone,
						name: `${firstName} ${lastName}`,
						slot,
						isExpressSameDayTest, departureDateAndTimedata
					}}
					onSubmit={async () => {
						setLoading(true);
						const response: any = await checkReservationValidity(form?.reservationId)
						if (response.data.status == 400) {
							setLoading(false);
							updateFormValues({
								slot: undefined
							})
						} else {
							setLoading(false);
							setShowPaymentModal(true);
						}
					}}
				>
					{({ dirty, isValid, errors, values }) => (
						<Form>
							<Checkboxes>
								{/* <Checkbox
									name="commitToAttend"
									onChange={(checked) =>
										updateFormValues({ commitToAttend: checked })
									}
									isRequired
								>
									I commit to attend this appointment as shown
                				</Checkbox>
								<Checkbox
									name="agreeToCancel"
									onChange={(checked) =>
										updateFormValues({ agreeToCancel: checked })
									}
									isRequired
								>
									I AGREE to cancel if I am unable to attend so the appointment
									can be released to another party
                				</Checkbox> */}
							</Checkboxes>
							<Title>Testing Information</Title>
							<TestingInfoContainer>
								<TestingInfo>
									<PinIcon />
									<strong>Departure date and time</strong>
									<br />
									{departureDateAndTime}
								</TestingInfo>
								<TestingInfoBtn
									type="button"
									onClick={() => {
										setShowDepartureDateandTime(showDepartureDateandTime ? false : true);
										updateFormValues({ departureDateAndTime: rollbackValues });
										// toggleShowDepartureDateTimeModal(true)
										setdepartureDateAndTimedata(departureDateAndTime
											? parse(departureDateAndTime, config.dateTimeFormat, new Date())
											: null)
										setshowDepartureTime(true)
									}}
									size="sm"
									libraryType="default"
								>
									Change departure date and time
								</TestingInfoBtn>
							</TestingInfoContainer>
							{showDepartureDateandTime &&
								<>
									{/* <ChangeDepartureTimeModal
											showModal={showDepartureTime}
											closeModal={() => setshowDepartureTime(false)}
										/> */}
									<FormLabel label="Flight departure date & time (use the final leg of departure)">
										<DatepickerField

											name="departureDateAndTimedata"
											showTimeSelect
											minDate={
												locationStartDate === null ||
													isBefore(locationStartDate, new Date())
													? new Date()
													: locationStartDate
											}
											onChange={async (newDepartureDate: any) => {

												//This time zone offset is for pacific daylight time
												//Will need to add the other time zones offset later.
												let getTimeZoneOffSet = new Date(newDepartureDate).getTimezoneOffset();

												const overTime = new Date(newDepartureDate).getHours();

												let newDate = values.isExpressSameDayTest
													? subHours(newDepartureDate, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  // startOfDay(newDepartureDate)
													// : subHours(newDepartureDate, config.maxAdvanceHours);
													: subHours(newDepartureDate, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours)

												if (isBefore(newDate, new Date())) {
													newDate = startOfDay(new Date());
												}

												setdepartureDateAndTimedata(newDepartureDate)

												updateFormValues({
													slot: undefined,
													departureDateAndTime: format(
														newDepartureDate,
														config.dateTimeFormat
													),
													date: format(newDate, config.dateFormat),
												});

												// if(slot !== undefined || slot !== null){
												// 	await releaseReservation(
												// 		slot?.period,
												// 		minors.length + 1,
												// 		slot?.locationId,
												// 		date,
												// 		reservationId,
												// 		false
												// 	)
												// }

												updateFormValues({
													slot: undefined,
													reservationId: null
												});
											}}
										/>
									</FormLabel>
									<div style={{ display: "flex", margin: '0px 0px 25px 0px' }}>
										<ReturnButton disabled={false} libraryType="default" onClick={() => {
											updateFormValues({ departureDateAndTime: rollbackValues }); updateFormValues({ slot: rollbackSlot });
											setdepartureDateAndTimedata(rollbackValues ? parse(rollbackValues, config.dateTimeFormat, new Date()) : null);
											setShowDepartureDateandTime(false);
											updateFormValues({ date: rollbackDate });
										}} >
											Cancel
										</ReturnButton>
										<div style={{ margin: "10px" }} />
										<Button onClick={() => {
											setShowDepartureDateandTime(false);
											slot === undefined ? setShowChangeDatetimeModal(true) : setShowChangeDatetimeModal(false)
										}} libraryType="primary" disabled={false}>
											{/* {loading ? 'Processing...' : 'Verify Identity'} */}
											Change
										</Button>
									</div>
								</>
							}
							<TestingInfoContainer>
								<TestingInfo>
									<PinIcon />
									<strong>{location?.name}</strong>
									<br />
									{location?.address1} {location?.address2}
								</TestingInfo>
								<TestingInfoBtn
									type="button"
									onClick={() => toggleChangeLocationModal(true)}
									size="sm"
									libraryType="default"
								>
									Change Location
								</TestingInfoBtn>
							</TestingInfoContainer>
							<TestingInfoContainer>
								<TestingInfoRow>
									<TestingInfo>
										<CalendarIcon />
										<strong>Date</strong>
										<br />
										{format(selectedDate, config.weekDateFormat)}
									</TestingInfo>
									<TestingInfo>
										<ClockIcon />
										<strong>Time</strong>
										<br />
										{/* {slot && periods[slot.period].label} */}
										{/* {slot &&  (slotsList[slot.period].label || periods[slot.period].label) } */}
										{/* {slot !== undefined && slot !== null && slotsList[slot.period] && slotsList[slot.period].label !== undefined ? slotsList[slot.period].label : slot !== undefined && slot !== null && periods[slot.period].label} */}
										{slot !== undefined && slot !== null ? `${slotsListPeriod(isRapidTest == true ? "rapid" : "other", slot)}` : ""}
									</TestingInfo>
								</TestingInfoRow>
								<TestingInfoBtn
									type="button"
									onClick={() => setShowChangeDatetimeModal(true)}
									size="sm"
									libraryType="default"
								>
									Change Date & Time
								</TestingInfoBtn>
							</TestingInfoContainer>
							{slot === null || slot === undefined && <Error>Please select slot time</Error>}
							{/* { slot === null || slot === undefined && <Error>{slotError}</Error>} */}
							{/*<EditableInfosWrapper>*/}
							{/*  <EditableInfo label="Name" name="name" value={name} />*/}
							{/*  <EditableInfo label="Phone" name="phone" value={phone} />*/}
							{/*</EditableInfosWrapper>*/}
							<TestingInfoContainer>
								<TestingInfoRow>
									<TestingInfo>
										<PersonIcon />
										<strong>Appointment for</strong>
										<br />
										{firstName} {lastName}
										{minors?.length > 0
											? minors?.map(({ firstName, lastName }: any, i: number) => (
												<div key={i}>
													{firstName} {lastName}
												</div>
											))
											: ''}
									</TestingInfo>
								</TestingInfoRow>
								<TestingInfoBtn
									type="button"
									onClick={() => setShowChangeApptAttendeeModal(true)}
									size="sm"
									libraryType="default"
								>
									Change Appointment Attendees
								</TestingInfoBtn>
							</TestingInfoContainer>
							<TestingInfoContainer>
								<TestingInfoRow>
									<TestingInfo>
										{/* <PersonIcon /> */}
										<strong>Test type</strong>
										<br />
										{minors?.length > 0 ?
                      isExpressSameDayTest ? `Express Tests - $${amount}` : isRapidTest ? `Rapid Tests - $${amount}` :  `Standard Tests - $${amount}`
                      : isExpressSameDayTest ? `Express Test - $${amount}` : isRapidTest ? `Rapid Test - $${amount}` : `Standard Test - $${amount}`}
									</TestingInfo>
								</TestingInfoRow>
							</TestingInfoContainer>
							<TestingInfoContainer>
								<TestingInfoRow style={{ width: "100%" }}>
									<TestingInfo style={{ flex: 1 }}>
										{/* <PersonIcon /> */}
										<strong>Please select your testing option</strong>
										<br />

									</TestingInfo>
									<InnerBox
										selected={isRapidTest}
										style={{
											border: !location?.rapidTest ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
											background: !location?.rapidTest ? "#D3D3D3" : isRapidTest ? colors.blue : colors.white,
											cursor: !location?.rapidTest ? "not-allowed" : "pointer",
											flex: 1,
											textAlign: "center"
										}}
										onClick={async () => {
											if (!location?.rapidTest) {
												return
											}

											setShowChangeDatetimeModal(true)
											upateTestType(false);

											// let Ddate : any= departureDateAndTime
											const overTime = new Date(departureDateAndTimedata).getHours();
											const newDateValue = subHours(departureDateAndTimedata, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  //values.departureDateAndTime 

											updateFormValues({
												slot: undefined,
												isExpressSameDayTest: false,
												isRapidTest: true,
												date: format(newDateValue, config.dateFormat),
												reservationId: ""
											});

											// if(slot !== undefined && slot !== null){
											// 	await releaseReservation(
											// 		slot?.period,
											// 		minors.length + 1,
											// 		slot?.locationId,
											// 		date,
											// 		reservationId,
											// 		false,
											// 		isRapidTest
											// 	)
											// }											
										}} >
										<TestTypeTitle selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }} >
											RAPID TEST
										</TestTypeTitle>
										<TestCostLabel selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
											${prices.rapid}
										</TestCostLabel>
										<TestDurationInfoLabel selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
											Results within 1.5 hours
										</TestDurationInfoLabel>
									</InnerBox>
									<InnerBox
										selected={isExpressSameDayTest}
										style={{
											border: !location?.hasVipSlots ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
											background: !location?.hasVipSlots ? "#D3D3D3" : isExpressSameDayTest ? colors.blue : colors.white,
											cursor: !location?.hasVipSlots ? "not-allowed" : "pointer",
											flex: 1,
											textAlign: "center"
										}}
										onClick={async () => {

											if (!location?.hasVipSlots) {
												return
											}

											// let Ddate : any= departureDateAndTime
											const overTime = new Date(departureDateAndTimedata).getHours();
											const newDateValue = subHours(departureDateAndTimedata, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  //values.departureDateAndTime 

											updateFormValues({
												isExpressSameDayTest: true,
												isRapidTest: false,
												date: format(newDateValue, config.dateFormat),
												slot: undefined,
												reservationId: null

											});

											upateTestType(false);

											// if(slot !== undefined && slot !== null){
											// 	await releaseReservation(
											// 		slot?.period,
											// 		minors.length + 1,
											// 		slot?.locationId,
											// 		date,
											// 		reservationId,
											// 		false,
											// 		isRapidTest,
											// 	)
											// }

											// updateFormValues({
											// 	slot: undefined
											// });

											// if (slot === undefined) {
											setShowChangeDatetimeModal(true)
											// }

										}} >
										<TestTypeTitle selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }} >
											EXPRESS TEST
										</TestTypeTitle>
										<TestCostLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
											${prices.expedited}
										</TestCostLabel>
										<TestDurationInfoLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
											Results within 12 hours
										</TestDurationInfoLabel>
									</InnerBox>
									<InnerBox
										selected={standradTest}
										style={{
											flex: 1,
											textAlign: "center"
										}}
										onClick={async () => {

											setShowChangeDatetimeModal(true);

											const newDate = subHours(
												startOfDay(departureDateAndTimedata),
												config.maxAdvanceHours
											);

											updateFormValues({
												isExpressSameDayTest: false,
												isRapidTest: false,
												date: format(newDate, config.dateFormat),
												slot: undefined,
												reservationId: null
											});

											upateTestType(true);

											// if(slot !== undefined && slot !== null){
											// 	await releaseReservation(
											// 		slot?.period,
											// 		minors.length + 1,
											// 		slot?.locationId,
											// 		date,
											// 		reservationId,
											// 		false,
											// 		isRapidTest
											// 	)
											// }
											// updateFormValues({
											// 	slot: undefined,
											// });

											// setShowChangeDatetimeModal(true);
										}} >
										<TestTypeTitle selected={standradTest} >
											STANDARD TEST
										</TestTypeTitle>
										<TestCostLabel selected={standradTest}>
											${prices.standard}
										</TestCostLabel>
										<TestDurationInfoLabel selected={standradTest}>
											{/* Results within 24 hours */}
											{location?.qbenchCustomerId == 35 ? "Results within 36 hours" : "Results within 24 hours"}
										</TestDurationInfoLabel>
									</InnerBox>
								</TestingInfoRow>
							</TestingInfoContainer>
							<NavigationButtons
								loading={loading || showChangeDatetimeModal}
								confirmBtnText="Confirm & Pay"
								isValid={slot !== null && slot !== undefined}
							/>
						</Form>
					)}
				</Formik>
			</Content>
			<ChangeLocationModal openModal={() => setShowChangeDatetimeModal(true)} />
			<ChangeDateTimeModal
				showModal={showChangeDatetimeModal}
				closeModal={() => setShowChangeDatetimeModal(false)}
			/>
			<PaymentModal
				showModal={showPaymentModal}
				closeModal={() => setShowPaymentModal(false)}
			/>
			<ChangeApptAttendeeModal
				showModal={showChangeApptAttendeeModal}
				closeModal={() => setShowChangeApptAttendeeModal(false)}
				onChange={(res) => {
					if (res.status == 400) {
						// setSlotError(res.message)
						updateFormValues({
							slot: undefined
						})
					} else {
						setSlotError("")
					}

				}}
			/>
			{/* <ChangeDepartureTimeModal
					showModal={showDepartureTime}
					closeModal={() => setshowDepartureTime(false)}
				/> */}
		</Container>
	);
};

export default ConfirmAndPay;
