import { isBefore, addDays, format, addMonths } from "date-fns";

const formatWeek = (value: any) => {
    let weekArr = []
    for (let i = 0; i < value.length - 1; i++) {
        let val = value[i] + '-' + format(addDays(new Date(value[i + 1]), -1), 'LLL dd')
        weekArr.push(val)
    }
    return weekArr
}
export const getLabels = (start: any, end: any, type: any) => {
    let date = new Date(start)
    let arr = [start]
    let label: any = []
    let addDay = 0
    if (type === 'days') {
        addDay = 1
    } else if (type === 'weeks') {
        addDay = 7
        return ['Week-1', 'Week-2', 'Week-3', 'Week-4',]
    } else if (type === 'years') {
        addDay = 1
    }
    while (isBefore(date, new Date(end))) {
        date = new Date(type === 'years' ? addMonths(date, addDay) : addDays(date, addDay));
        arr.push(format(new Date(date), 'MM/dd/yyyy'));
    }
    const data = arr.map((e) => {
        const month = format(new Date(e), type === 'years' ? 'LLL' : 'LLL dd')
        label.push(month)
        return label
    })
    let newData: any = []
    let i = 1
    if (type === 'years') {
        return data[0]
    } else {
        while (i <= data[0].length) {
            newData.push('Day-' + i)
            i++
        }
    }
    return newData
    //return type === 'weeks' ? formatWeek(data[0]) : data[0]
}

export const addArraysByEqualIndex: any = (arr1: any, arr2: any) => {
    let finalArr = []
    for (let i = 0; i < arr1.length; i++) {
        finalArr.push(arr1[i] + arr2[i])
    }
    return finalArr
}