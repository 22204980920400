import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../../../shared/providers/schedule/provider';
import { Button } from '../../../../shared/components/styled';
import { colors } from '../../../../../../src/styles/colors';
import { Breakpoints } from '../../../../../../src/dictionaries';

import Modal from '../../../../shared/components/Modal';
import LocationButtonGroup from '../../../../shared/components/form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { updateAppointmentdata } from '../../../api';
import { config } from '../../../../../config';
import { parse, format } from 'date-fns';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
color: ${colors.darkBlue};
font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const ChangeLocationModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const {
    form: { location },
    showChangeLocationModal,
  } = useStepsState();
  const { params, appointments, Editappointments } = useAdminState();
  const locationdata =
    Editappointments !== null ? Editappointments[0]?.location : null;
  const appointmentdata: any =
    Editappointments !== null ? Editappointments[0] : null;
  const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
  const rollbackLocation = useMemo(() => cloneDeep(locationdata), [
    showChangeLocationModal,
  ]);
  const [loading, setloading] = React.useState<boolean>(false)
  const handleSearchfunction = async(locationclick: any) => {
    appointmentdata.location = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem('location')))
    );
    // appointmentdata.slot.locationId = appointmentdata.location.qbenchCustomerId

    appointmentdata.minors.map(
      (e: any) =>
        (e.birthDate = e.birthDate
          ? format(e.birthDate, config.dateFormat)
          : appointmentdata.birthDate)
    );

    let params:any = {
            ...appointmentdata,
             note:appointmentdata?.note?appointmentdata?.note:''
          }
    setloading(true)
    await updateAppointmentdata(params);
    setloading(false)

    appointmentdata.minors.map(
      (e: any) =>
        (e.birthDate = e.birthDate
          ? parse(e.birthDate, config.dateFormat, new Date())
          : null)
    );

    // updateAppointmentdata(appointmentdata)
    closeModal();
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Formik
        initialValues={{ location: locationdata }}
        onSubmit={() => {
          if (
            appointmentdata.location.qbenchCustomerId !==
            JSON.parse(
              JSON.parse(JSON.stringify(localStorage.getItem('location')))
            ).qbenchCustomerId && appointmentdata.cPartnerID !=='LLT001'
          ) {
            appointmentdata.slot = undefined;
          } else if(
            appointmentdata.location.qbenchCustomerId !==
            JSON.parse(
              JSON.parse(JSON.stringify(localStorage.getItem('location')))
            ).qbenchCustomerId && appointmentdata.cPartnerID ==='LLT001'
          ) {
            appointmentdata.slot.label = '';
          }
           handleSearchfunction(location);
        }}
      >
        {() => (
          <Form>
            <Content>
              <Title>Change Location</Title>
              <LocationButtonGroup
                isEdit={true}
                appoitmentLocation={appointmentdata.location.cPartnerID === "HAL001" ? "WSL001" : appointmentdata.location.cPartnerID}
                showAllAvailableLocation={appointmentdata?.isSchedularLite}
              />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    updateFormValues({ location: rollbackLocation });
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button disabled={loading} libraryType="primary" size="sm" type="submit">
                    {loading?'Processing...':'Change'}
                </Button>
              </ButtonsContainer>
            </Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLocationModal;
