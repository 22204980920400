import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CardHeader from './Card/CardHeader';
import CardBody from './Card/CardBody';
import Card from './Card/Card.js';
import { Wrapper } from '../../../shared/components/styled';
import Loader from '../../../shared/components/Loader';
import { createRecurringForLasLomitasTrigger } from '../../api';

const ButtonWrapper = styled.div`
    margin-bottom: 15px;
    max-width: 1200px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        cardTitleWhite: {
            color: '#FFFFFF',
            marginTop: '0px',
            minHeight: 'auto',
            fontWeight: 'bold',
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: '3px',
            textDecoration: 'none',
        },
        typo: {
            paddingLeft: '25%',
            marginBottom: '40px',
            position: 'relative',
        },
        note: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            bottom: '10px',
            color: '#c0c1c2',
            display: 'block',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '13px',
            left: '0',
            marginLeft: '20px',
            position: 'absolute',
            width: '260px',
        },
        cardCategoryWhite: {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
    })
);

function Recurring() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const res = await createRecurringForLasLomitasTrigger();
            console.log('res :', res);
            setLoading(false);
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <Wrapper>
                <Card>
                    <CardHeader color="primary">
                        <h2 className={classes.cardTitleWhite}>Recurring Pub Sub</h2>
                    </CardHeader>
                    {!loading ? (
                        <CardBody>
                            <div
                                style={{
                                    paddingLeft: '20%',
                                    marginBottom: '40px',
                                    position: 'relative',
                                    marginTop: '30px',
                                }}
                            >
                                <div className={classes.note}></div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={handleClick}
                                        >
                                            Las Lomitas Tenant
                                        </Button>
                                    </ButtonWrapper>
                                </div>
                            </div>
                        </CardBody>
                    ) : (
                        <Loader />
                    )}
                </Card>
            </Wrapper>
        </div>
    );
}

export default Recurring;
