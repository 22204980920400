import * as React from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { Button, Container, Grid, IconButton, Paper, Snackbar, Typography } from '@material-ui/core';
import Input from '../../../shared/components/form/Input';
import Select from '../../../shared/components/form/Select';
import { stylesForListing } from './styles';
import Textarea from '../../../shared/components/form/Textarea';
import { servicedata } from './Model';
import { postService } from './API';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAdminState,useAdminDispatch } from './../../provider'
import MuiAlert from '@material-ui/lab/Alert'
export interface IAddServicesProps {
}

export default function AddServices ({}: IAddServicesProps) {
  function Alert(props:any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const history = useHistory();
  const {GetServiceData,getID} = useAdminState();
  const {SetID} = useAdminDispatch();
  let INITIAL_FORM_STATE = {
    name: '', price: '', time: {}, description: ''
  }
  if(getID === '' || getID === null)
  {
    INITIAL_FORM_STATE = {
      name: '', price: '', time: {}, description: ''
    }
  }
  else
  {
    try {
      let newdata = GetServiceData.filter((row: any) => row.id === getID)
      INITIAL_FORM_STATE = {
        name: newdata[0].name, price: '' + newdata[0].price, time: {
          label: newdata[0].time,
          value: newdata[0].time
        },
        description: newdata[0].description
      }
    }
    catch (err) {
      INITIAL_FORM_STATE = {
        name: '', price: '', time: {}, description: ''
      }
    }
  }
  const [submit, setsubmit] = React.useState(false)
  const [alertbox, setalertbox] = React.useState({open:false,type:'',msg:''})
  const [load, setload] = React.useState(false)
  const validateform = (value: servicedata) => {
    let error: FormikErrors<any> = {};    
    if (value.name === '') error.name = 'Please Enter the Name';
    if (value.price === '')
    {
      error.price='Please Enter the Price';
    }
    else
    {
      try
      {
        let currency = parseInt(value.price);
        if (''+currency!==value.price)
        {
          error.price='Enter Correct Price'
        }
      }
      catch(e)
      {
        error.price="Enter Price";
      }
    }
    if (!(value.time.label))error.time='Select the Price';
    return error;
  }
  const options = [
    { label: '15 Min', value: '15 Min' },
    { label: '30 Min', value: '30 Min' },
    { label: '45 Min', value: '45 Min' },
    { label: '1 Hour', value: '1 Hour' },
    { label: '2 Hour', value: '2 Hour' },
    { label: '3 Hour', value: '3 Hour' },
    { label: '4 Hour', value: '4 Hour' },
    { label: '5 Hour', value: '5 Hour' },
    { label: '6 Hour', value: '6 Hour' },
    { label: '7 Hour', value: '7 Hour' },
    { label: '8 Hour', value: '8 Hour' },
    { label: '9 Hour', value: '9 Hour' },
    { label: '10 Hour', value: '10 Hour' },
    { label: '11 Hour', value: '11 Hour' },
    { label: '12 Hour', value: '12 Hour' },
    { label: '13 Hour', value: '13 Hour' },
    { label: '14 Hour', value: '14 Hour' },
    { label: '15 Hour', value: '15 Hour' },
    { label: '16 Hour', value: '16 Hour' },
    { label: '17 Hour', value: '17 Hour' },
    { label: '18 Hour', value: '18 Hour' },
    { label: '19 Hour', value: '19 Hour' },
    { label: '20 Hour', value: '20 Hour' },
    { label: '21 Hour', value: '21 Hour' },
    { label: '22 Hour', value: '22 Hour' },
    { label: '23 Hour', value: '23 Hour' },
    { label: '24 Hour', value: '24 Hour' },
  ]
  const handleClose= ()=>{
    setalertbox({open:false,type:'',msg:''})
  }
  const classes = stylesForListing();
  if(load)
  {
    return (<></>);
  }
  return (
    <div>
      <Container maxWidth='md'>
        <Snackbar open={alertbox.open} onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={alertbox.type}>
            {alertbox.msg }
          </Alert>
        </Snackbar>
        <Grid container justify='center'>
          <Grid xl={8} lg={8} md={10} sm={12} xs={12} item >
            <Link to='/dashboard/services' className={classes.backbtn}>
              <IconButton color="primary">
                <ArrowBackIcon fontSize='large' />
              </IconButton>
            </Link>
            <Paper elevation={0} className={classes.paperBorder}>
              <Formik
                enableReinitialize={true}
                initialValues={{ ...INITIAL_FORM_STATE }}
                validate={validateform}
                onSubmit={(value, { resetForm }) => {
                  setsubmit(true);
                  postService(value,getID).then((e:any)=>{
                    if(e.status===200)
                    {
                      setalertbox({open:true,msg:'Service Added Successfully',type:'info'})
                      setTimeout(() => {
                        history.push('/dashboard/services');
                      }, 1000);
                    }
                    else
                    {
                      setalertbox({ open: true, msg: e.data.msg, type: 'error' })
                      setTimeout(() => {
                        history.push('/dashboard/services');
                      }, 1000);
                    }
                    setsubmit(false);
                  })
                }}
              >
                <Form>
                  <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                    {getID === '' || getID === null ?'ADD':'Update '} A Service Offering
                  </Typography>
                  <Typography variant="subtitle2" color="initial">
                    Please enter or edit the service details below.
                  </Typography><br />
                  <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                    Name (i.e. Standard PCR, Express)
                  </Typography>
                  <Input name="name" isRequired={true} />
                  <Grid container spacing={3}>
                    <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                      <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Price</Typography>
                      <Input name='price' isRequired={true} />
                    </Grid>
                    <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                      <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Turnaround Time</Typography>
                      <Select name='time' options={options} isRequired={true}/>
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Test Description</Typography>
                  <Textarea name='description' isRequired={false}/>
                  <Grid container spacing={2} justify='flex-end'>
                    <Grid item><Button variant="outlined" color="default" className={classes.btnoutline}>Cancel</Button></Grid>
                    <Grid item><Button type='submit' variant='contained' disabled={submit} className={classes.btncontain}>{!submit ? getID === '' || getID === null?'Confirm':'Update':"Proccessing..."}</Button></Grid>
                  </Grid>
                </Form>
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
