
import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, TextField, Button, Card, Container, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import AppointmentList from '../../components/AppointmentList';
import dashboardRoutes from '../../../admin/components/layouts/routes';
import { Formik, Field, Form } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialogBox from './ConfirmDialogBox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { apiConfig } from '../../../../apiConfig';
import { EditOutlined } from '@ant-design/icons'
import { config } from '../../../../config';
import { Link } from 'react-router-dom';
import { useAdminDispatch, useAdminState } from '../../provider';
import { Modal, Input } from 'antd';

const useStyles = makeStyles({
    titleStyle: {
        color: '#FFF',
        background: "linear-gradient(60deg, #00acc1, #00acc1)",
        padding: '5px',
    }, btncolor: {
        borderRadius: '5px',
        minHeight: "3.9em",
        color: 'white',
        background: "#1890ff",
    }
});

export default function RolesAccessForm() {
    const [expanded, setExpanded] = React.useState(false);
    const [rolesArray, setRolesArray] = React.useState({})
    const [flag, setFlag] = React.useState(false);
    const [textInput, setTextInput] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const [alertbox, setalertbox] = useState({ open: false, type: '', msg: '' });
    const [backdrop, setbackdrop] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState({ id: '', name: '' })
    const [oldRole, setOldRole] = useState('');
    const classes = useStyles()
    const [loading1, setLoading1] = React.useState(false);
    const { userRole } = useAdminState();

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    //Code to check authorization for current component
    React.useEffect(() => {
        getRoleByEmail()
    }, [])
    const getRoleByEmail = async () => {
        try {
            // const resData = await fetch(`${apiConfig.hostUrl}/getRoleByEmail`, {
            const resData = await fetch(`${config.cloudRun.baseUrl}getRoleByEmail`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ email: JSON.parse(window.localStorage.getItem("userDetails")).email })
            })
            const jsonData = await resData.json()
                .then(res => {
                    if (res.data.length == 0) {
                        getAllRolesAccessFields("Default")
                    } else {
                        getAllRolesAccessFields(res.data[0].data.role)
                    }
                })
        }
        catch (error) {
            setalertbox({ open: true, type: 'error', msg: 'failed' });
        }
    }

    const getAllRolesAccessFields = async (roleName) => {
        // const resData = await fetch(`${apiConfig.hostUrl}/getAccessFieldsByRole`, {
        const resData = await fetch(`${config.cloudRun.baseUrl}getAccessFieldsByRole`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ roleName: roleName })
        })
        const jsonData = await resData.json()
            .then(res => checkAccess(res.data[0]?.data.accessFields))
    }
    const checkAccess = (allAccessFields) => {
        //check allAccessFiels includes curent component name
        //Name should be as given in the routes
        if (!allAccessFields.includes("Roles Access Form")) {
            alert("You should be admin to access this page")
            window.location.href = "https://admin-dev-b5e92.web.app/dashboard";
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleNewRole = async () => {
        if (!textInput.trim().length || textInput === null || !textInput) {
            // alert("please enter Role")
            setalertbox({ open: true, type: 'error', msg: 'please enter Role' });
            return
        }
        let roleName = '';
        let resData;
        setLoading(true)
        try {
            roleName = rolesArray.filter((role) => role.data.roleName.toLowerCase() === textInput.toLowerCase());
            if (roleName.length > 0) {
                setalertbox({ open: true, type: 'error', msg: 'Role already exists' });
                setLoading(false)
            } else {
                setLoading(true)
                resData = await fetch(`${config.cloudRun.baseUrl}addNewRole`, {
                    method: "POST",
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ roleName: textInput })
                })

                if (resData.ok) {
                    setLoading(false)
                    setalertbox({ open: true, type: 'success', msg: 'Role Added Successfully' });
                    getAllRolesAccess();
                    setTextInput('')
                }
            }

        } catch (error) {
            //alert("error occurred please try again later")
            setalertbox({ open: true, type: 'error', msg: 'Failed' });
        }
    }

    const handleDeleteRole = async (deleteID) => {
        try {
            const resData = await fetch(`${apiConfig.hostUrl}/deleteRole`, {
                method: "DELETE",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ id: deleteID })
            })
            if (resData.ok) {
                setalertbox({ open: true, type: 'success', msg: 'Role Deleted Successfully' });
                getAllRolesAccess();
            }
        } catch (error) {
            setalertbox({ open: true, type: 'success', msg: 'Failed' });
        }
    }
    const getAllRolesAccess = async () => {
        // const resData = await fetch(`${apiConfig.hostUrl}/getAllAccessRolesData`)
        const resData = await fetch(`${config.cloudRun.baseUrl}getAllAccessRolesData`)
        const jsonData = await resData.json();
        setRolesArray(jsonData?.data)
        console.log("rolename".jsonData?.data)
        setFlag(true);
    }

    useEffect(() => {
        getAllRolesAccess()
    }, [])

    const UpdateAccessFields = async (updateId, accessFields) => {
        let tempObj = { id: updateId, accessFields: accessFields }
        try {
            // const resData = await fetch(`${apiConfig.hostUrl}/UpdateAccessFields`, {
            const resData = await fetch(`${config.cloudRun.baseUrl}UpdateAccessFields`, {
                method: "PUT",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(tempObj)
            }
            )
            if (resData.ok) {
                setalertbox({ open: true, type: 'success', msg: 'Updated Successfully' });
            }
        }
        catch (error) {
            //alert('error ocurred, please try again later')
            setalertbox({ open: true, type: 'success', msg: 'Failed' });
        }
    }

    const UpdateUserRole = async (id, oldRoleName, newRoleName) => {
        let tempObj = { id, oldRoleName, newRoleName }

        try {
            // const resData = await fetch(`${apiConfig.hostUrl}/UpdateUserRole`, {
            const resData = await fetch(`${config.cloudRun.baseUrl}UpdateUserRole`, {
                method: "PUT",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(tempObj)
            }
            )
            if (resData.ok) {
                setalertbox({ open: true, type: 'success', msg: 'Updated Successfully' });
            }
        }
        catch (error) {
            setalertbox({ open: true, type: 'success', msg: 'Failed' });
        }
    }

    const onEditName = (role) => {
        setIsEditing(true);
        setEditName(role);
        setOldRole(role);
    };

    return (
        <Container>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertbox.open} autoHideDuration={3000} onClose={() => setalertbox({ oepn: false, type: '', msg: '' })}>
                <Alert onClose={() => setalertbox({ open: false, type: '', msg: '' })} severity={alertbox.type}>{alertbox.msg}</Alert>
            </Snackbar>
            <Link to='/dashboard/Users' >
                <IconButton color="primary">
                    <ArrowBackIcon fontSize='large' />
                </IconButton>
            </Link>
            <AppBar position="static" color="primary" className={classes.titleStyle} style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="inherit" component="div" style={{ padding: "15px" }} >
                    Add/Update Role Access
                </Typography>
            </AppBar>
            <br /><br />
            <div>
                <Box mb={4}>
                    <TextField onChange={(e) => {
                        setTextInput(e.target.value)
                    }} value={textInput} id="newRole" label="New Role" variant="outlined" name='newRole' />&nbsp;
                    <Button variant="contained" className={classes.btncolor} color="primary" onClick={() => { handleNewRole() }}>{loading ? <CircularProgress /> : "Add New Role"}</Button>
                </Box>

                {flag ? rolesArray.sort((a, b) => a.data.roleName.localeCompare(b.data.roleName)).map((role, i) => {

                    return (
                        <React.Fragment key={role.newid}>
                            <Card style={{ border: "1.5px grey solid" }} >
                                <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", backgroundColor: "#F3F0D7" }}>
                                    <span style={{ padding: "0.5em" }}>{role.data.roleName}
                                        {/* {role.data.roleName == "Super Admin" && userRoleName == "Super Admin" ? <EditOutlined onClick = {() => {
                     onEditName(role.data.roleName)
                     setEditName({id:role.newid, name:role.data.roleName})
                 }}
                  /> : ''} */}
                                        {role.data.roleName !== "Super Admin" && role.data.roleName.toLowerCase() !== "admin" && role.data.roleName !== "Default" ? <EditOutlined onClick={() => {
                                            onEditName(role.data.roleName)
                                            setEditName({ id: role.newid, name: role.data.roleName })
                                        }}
                                        /> : ''}
                                    </span>
                                    {role.data.roleName.toLowerCase() == "admin" || role.data.roleName == "Default" || role.data.roleName == "Super Admin" ? '' :
                                        <span style={{ padding: "0.5em" }}><ConfirmDialogBox getAllRolesAccess={getAllRolesAccess} deleteID={role.newid} /></span>}
                                </div>
                                <Accordion style={{ borderTop: "0px" }} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {/* {role.data.roleName} */}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ borderTop: "0px", display: "flex", justifyContent: "center" }}>
                                        <Typography>

                                            <Formik
                                                initialValues={{
                                                    checked: role.data.accessFields ? role.data.accessFields : [],
                                                }}
                                                onSubmit={(values) => {
                                                    setLoading1(true)
                                                    UpdateAccessFields(role.newid, values.checked).then(() => setLoading1(false))

                                                }}
                                            >
                                                {({ values }) => (
                                                    <Form >
                                                        <strong><center><i><div id="checkbox-group" >{`Access for: ${role.data.roleName}`} </div></i></center></strong><br />
                                                        <div role="group" aria-labelledby="checkbox-group" style={{ display: "grid", gridGap: "1em", gridTemplateColumns: "1fr 1fr 1fr", justifyContent: "space-evenly" }}>
                                                        {dashboardRoutes.filter(SideBarRoute => SideBarRoute.display).map(sideBarRoute => (
                                                                <span> <label>
                                                                    {/* {role.data.roleName=="Admin"?<Field type="checkbox" name="checked" checked={true} value={sideBarRoute.name}/>:  */}
                                                                    {role.data.roleName.toLowerCase() == "admin" || role.data.roleName == "Super Admin" ? null :
                                                                        sideBarRoute.name == "User Roles Form" || sideBarRoute.name == "Roles Access Form" || (role.data.roleName !== 'Super Admin' && sideBarRoute.name == "Revenue Dashboard") ? '' : <Field type="checkbox" name="checked" value={sideBarRoute.name} />
                                                                    }

                                                                    {/* {role.data.roleName=="Admin"?sideBarRoute.name=="User Roles" || sideBarRoute.name=="User Roles Form" || sideBarRoute.name=="Roles Access Form"?sideBarRoute.name:sideBarRoute.name : */}
                                                                    {role.data.roleName == "Super Admin" ? <span style={{ paddingLeft: "1em" }}>{sideBarRoute.name}</span> :
                                                                        sideBarRoute.name == "User Roles Form" || sideBarRoute.name == "Roles Access Form" || (role.data.roleName !== 'Super Admin' && sideBarRoute.name == "Revenue Dashboard") ? '' : <span style={{ paddingLeft: "1em" }}>{sideBarRoute.name}</span>
                                                                    }

                                                                </label> &nbsp;&nbsp;
                                                                </span>
                                                            ))}
                                                            {
                                                                role.data.roleName == "Super Admin" && userRole !== 'Super Admin' && <span style={{ paddingLeft: "1em" }}>Revenue Dashboard</span>
                                                            }
                                                        </div>
                                                        <br />
                                                        {/* <button type="submit" >Submit</button> */}
                                                        {role.data.roleName.toLowerCase() == "admin" || role.data.roleName == "Super Admin" ? <strong>
                                                            {/* <center><p>Admin will have all Access, which is directly updated in backend. (In accessFields of allRoles)</p></center> */}
                                                        </strong> : <center><Button type="submit" className={classes.btncolor} variant="contained" color="primary"  >{loading1 ? <CircularProgress /> : "Update Access"}</Button></center>}
                                                    </Form >
                                                )}
                                            </Formik>
                                        </Typography>

                                    </AccordionDetails>
                                </Accordion>
                            </Card>

                            <br />
                        </React.Fragment>
                    )
                }) : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>}
                <Modal
                    title="Name"
                    visible={isEditing}
                    okText="Save"
                    onCancel={() => {
                        setIsEditing(false);
                    }}
                    onOk={() => {
                        const userRole = rolesArray.filter((role) => role.data.roleName.toLowerCase() === editName.name.toLowerCase());
                        if (userRole.length > 0) {
                            setalertbox({ open: true, type: 'error', msg: 'Role already exists' });
                        } else {
                            let data = rolesArray?.filter((role) => role.newid === editName.id)[0]
                            let updatedata = rolesArray?.filter((role) => role.newid !== editName.id)
                            setRolesArray([...updatedata, { ...data, data: { ...data.data, roleName: editName.name } }])
                            // updateUserRolesNameData(editName.id, oldRole, editName.name)
                            setIsEditing(false)
                        }
                    }}
                >
                    <Input value={editName.name} onChange={(e) => {
                        setEditName({ id: editName.id, name: e.target.value })
                    }} />

                </Modal>

            </div>
        </Container>
    );
}

