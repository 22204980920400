import React from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../layouts/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "../layouts/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import MiniDrawer from "../layouts/LeftSideMenu";
import routes from "./routes.js";
import styles from "../layouts/adminStyle.js";
import bgImage from "../../../../assets/step-1-header-bg.jpg";
import logo from "../../../../assets/reactlogo.png";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useAuth0 } from "@auth0/auth0-react";
import { apiConfig } from "../../../../apiConfig";
import { getReportAccess } from '../../api'
import { useAdminDispatch } from '../../../admin/provider';
import { config } from "../../../../config";

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/dashboard") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })}
        <Redirect from="/dashboard" to={window.localStorage.getItem('checkin') ? window.localStorage.getItem('checkin') : '/dashboard/Home'} />
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

    let { user, isAuthenticated, isLoading, logout, getAccessTokenSilently, error } = useAuth0();


    // styles
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(isLoading);
    const [updatedRoutes, setUpdatedRoutes] = React.useState([])
    const {updateUserRole, setAccess } = useAdminDispatch();

    const insuranceReportAccess = () => {
        // reportAccess().then((res)=>{
        getReportAccess().then((res) => {
            if ((res?.data[0].insurance)?.includes(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails')))).email))
                setAccess(true)
        })
    }
    const handleDrawerTogglemenu = () => {
        setOpen(!open);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const checkToken = async (accessToken) => {
        const token = JSON.parse(accessToken);
        const tmp = decodeToken(token.accessToken);
        const isMyTokenExpired = isExpired(token.accessToken);
        if (isMyTokenExpired) {
            window.localStorage.removeItem('accessToken');
            window.localStorage.removeItem('userDetails');
            logout({ returnTo: config.auth0Redirection.login });
        } else {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (window.localStorage.accessToken) {
            checkToken(window.localStorage.accessToken);
        }
        getRoleByEmail();
        insuranceReportAccess()
    }, []);
    const getRoleByEmail = async () => {
        // const resData = await fetch(`${apiConfig.hostUrl}/getRoleByEmail`, {
        const resData = await fetch(`${config.cloudRun.baseUrl}getRoleByEmail`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ email: JSON.parse(window.localStorage.getItem("userDetails")).email })
        })
        const jsonData = await resData.json()
            .then(res => {
                console.log("res",res)
                if (res.data.length == 0) {
                    getAllRolesAccess("Default")
                    updateUserRole("Default")
                } else {
                    getAllRolesAccess(res.data[0].data.role)
                    updateUserRole(res.data[0].data.role)
                }
            })

    }

    const getAllRolesAccess = async (roleName) => {
        const resData = await fetch(`${config.cloudRun.baseUrl}getAccessFieldsByRole`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ roleName: roleName })
        })
        const jsonData = await resData.json()
            .then(res => {
                if (res.data.length == 0) {
                    getAllRolesAccess("Default")
                } else {
                    mapRoutes(res?.data[0]?.data.accessFields)
                }
            })
    }

    const mapRoutes = (currentUserAccessFields) => {
        const updatedRoutesArr = routes.map(route => {
            let tempObj = route
            if (tempObj.display == false) {
                tempObj.display = false
            } else {

                let flag = currentUserAccessFields.includes(tempObj.name)
                tempObj.display = flag

            }
            return tempObj
        })

        const updatedRoutesArrs = updatedRoutesArr.map(route => {
            let tempObj = route

            // if (tempObj.name == 'Customers') {
            //     tempObj.display = true;
            // } else if (tempObj.name == 'NP Destinations') {
            //     tempObj.display = true;
            // } else if (tempObj.name == 'Recurring PubSub') {
            //     tempObj.display = true;
            // }

            return tempObj
        })
        setUpdatedRoutes(updatedRoutesArrs)
    }

    return (
        <div className={classes.wrapper}>
            {open
                ?
                <Sidebar
                    routes={updatedRoutes}
                    logoText={"Work Site Labs"}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={handleDrawerTogglemenu}
                    open={open}
                    color={color}
                    {...rest}
                />
                :
                false
            }
            <div className={open ? classes.mainPanel : classes.mainPanel1} ref={mainPanel}>
                <Navbar
                    routes={routes}
                    open={open}
                    handleDrawerToggle={handleDrawerTogglemenu}
                    {...rest}
                />

                {
                    getRoute()
                        ? (
                            <div className={classes.content}>
                                <div className={classes.container}>{switchRoutes}</div>
                            </div>
                        )
                        :
                        (
                            <div className={classes.map}>{switchRoutes}</div>
                        )
                }

            </div>
        </div>
    );
}
