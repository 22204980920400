import React from 'react';
import styled from 'styled-components';
import { useAdminState } from '../../../provider';
import { CSVLink } from 'react-csv';
import { parse, format } from 'date-fns';
import { config } from '../../../../../config';

const Wrapper = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: right;
  a {
    text-decoration: none;
  }
`;

const headers = [
    { label: 'Appt Time', key: 'period' },
    { label: 'Status', key: 'isCanceled' },
    { label: 'Airline', key: 'airlineCode' },
    { label: 'Travel Type', key: 'travelType' },
    { label: 'Destination', key: 'destination' },
    { label: 'Collection Method', key: 'collectionmethod' },
    { label: 'VIP Slot', key: 'isExpressSameDayTest' },
    { label: 'Rapid Test', key: 'isRapidTestText' },
    { label: 'Test Type', key: 'testType'},
    { label: 'Notes', key: 'note'},
    { label: 'Confirmation ID', key: 'confirmationId' },
    { label: 'Sample ID', key: 'sampleId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'DOB', key: 'birthDate' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Flight Date and Time', key: 'departureDateAndTime' },
    { label: 'Symptoms Reported?', key: 'symptoms' },
    { label: 'Contact in last 14 days?', key: 'hadContact' },
    { label: 'Results', key: 'results' },
    // { label: 'Check In', key: 'checkin' },
];

const DownloadLinkCSV: React.FC = () => {
  const { params, appointments } = useAdminState();

  return (
    <Wrapper>
      {Array.isArray(appointments) && appointments.length > 0 ? (
        <CSVLink
          data={
            appointments
              ? appointments.map((appt) => ({
                ...appt,
                symptoms:
                  Array.isArray(appt.symptoms) && appt.symptoms.length > 0
                    ? appt.symptoms.join(', ')
                    : 'None',
              }))
              : []
          }
          headers={headers}
          filename={`Appointments ${format(
            params.date != '' ? parse(params.date, config.dateFormat, new Date()) : new Date(),
            config.fileDateFormat
          )}.csv`}

        >
          Download CSV
        </CSVLink>
      ) : null}
    </Wrapper>
  );
};

export default DownloadLinkCSV;
