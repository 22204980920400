import * as React from 'react';
import { getLocation } from '../LocationAndService/API';
import { useAdminDispatch } from './../../provider'
import { Button,Card,Col,Layout,Row,Space,Table,Typography } from 'antd';
import { getAdminConfigDaywise } from '../../api';
import { styles } from '../../components/AppointmentList/styles';
import { locationTableColumn } from './../../../admin/components/AppointmentList/constants';
import { Form,Select, } from 'antd';
import { comparePhone, } from '../../../../utils';
import { useMemo } from 'react';
import { states }  from '../../components/states'

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ListInventory : React.FC = () => {

	const { goToNextStep, setLocation } = useAdminDispatch();
	
	const [rowdata, setrowdata] = React.useState<any>([]);
	
	const [loading, setLoading] = React.useState(false);

	const [qbenchCustomerId, setQbenchCustomerId] = React.useState();
	
	let inventory: boolean = true;
	
	React.useEffect(() => {
		
		getLocation().then((res:any) => {
			if(res.status===200)
			{
				let newdata = res.data.locationservice.map((e: any) => {
					if(e)
					{
                        
						let visible:any=e.data.startDate?._seconds!==undefined&&e.data.startDate?._nanoseconds!==undefined?(e.data.startDate?._seconds+(e.data.startDate?._nanoseconds)*0.00000001)*1000:''
						let start:any=e.data.visibleSince?._seconds!==undefined&&e.data.visibleSince?._nanoseconds!==undefined?(e.data.visibleSince?._seconds+(e.data.visibleSince?._nanoseconds)*0.00000001)*1000:''
						let newdata = {...e.data,
							visibleSince:new Date(visible),
							startDate:new Date(start)
						}
                        let value: any = states.find((es: any)=> {
                            if(e.data.state === es.value)
                                return es.label;
                        })
                        console.log('checking1234',value.label);
						return ({ id: e.newid, ...newdata, state: value.label })
					}
					else
						return ({ id: e.newid, ...e.data })                 
				});
				
				setrowdata(newdata);
				getAdminConfigDaywiseColumn();
			}
			else
			{
				console.log(res);
			}
		}).catch(err => { console.log(err); })
	}, []);

	// Table location item
	const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]); 

	
	const renderActionsMenu = (action: any, record: any) => (
		<Button type='primary' onClick={(e) => {
			console.log(record);
			setLocation(record)
			goToNextStep()
		}}>Manage Inventory</Button>
	);

	async function getAdminConfigDaywiseColumn() {
		setLoading(true);
		const tableColumnData = locationTableColumn(renderActionsMenu,inventory);
		setRenderTableColumn(tableColumnData);
		let response = await getAdminConfigDaywise({
		  email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
		});
  
		if (response.data.status === 200) {
		  if (response.data.data.length > 0) {
			if (
			  response.data.data[0].daywiseconfig.hideandshowcolumn.length > 0
			) {
			  setRenderTableColumn(
				locationTableColumn(renderActionsMenu, inventory)
			  );
			  setLoading(false);
			}
		  } else {
			const tableColumnData = locationTableColumn(renderActionsMenu, inventory);
			setRenderTableColumn(tableColumnData);
			setLoading(false);
		  }
		} else {
		  const tableColumnData = locationTableColumn(renderActionsMenu, inventory);
		  setRenderTableColumn(tableColumnData);
		  setLoading(false);
		}
	  }

	React.useEffect(() => {
		getAdminConfigDaywiseColumn();
	}, []);

    const dataSourceAppointments = useMemo(
        () =>
            rowdata
                ?.filter((appointment: any) => {
                    const value = appointment['qbenchCustomerId'];
                    if (qbenchCustomerId &&!comparePhone(value, qbenchCustomerId)) {
                            return false;
                    }
                    return true;
                })
                .map((appointment: any, appIndex: number) => ({
                    ...appointment,
                    key: appIndex,
                })),

        [rowdata, qbenchCustomerId]
    );
	

	return (
		<Layout
			style={{
			marginTop: '100px',
			backgroundColor: '#FFF',
			border: '1px solid #f3eeee',
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
			}}
		>
		<div
			style={{
				borderRadius: '3px',
				background: 'linear-gradient(60deg, #00acc1, #00acc1)',
				boxShadow:
				'0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
				margin: '0px 25px',
				marginTop: '-25px',
			}}
		>
		  <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
			Inventory Tracker
		  </Title>
		</div>
		<Content style={styles.content}>
			<Space direction="vertical" style={styles.spaceWidth} size="middle">
                <Card>
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                        xl: 40,
                        xxl: 48,
                    }}
                >
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            label="Location"
                            name="location"
                        >
                            <Select
                                disabled={loading}
                                allowClear
                                placeholder="Please select"
                                showArrow
                                onChange={e => setQbenchCustomerId(e)}
                            >
                                {rowdata?.map(
                                    (location: any, i: any) => (
                                        <Option
                                            key={i}
                                            value={
                                                location.qbenchCustomerId
                                            }
                                        >
                                            {location.name}
                                        </Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                </Card>
				<Card>
					<div style={{ padding: '25px 35px' }}>
						<Table
							size="small"
							columns={renderTableColumn as any}
							bordered
							dataSource={(dataSourceAppointments as any) || []}
							pagination= { {pageSizeOptions: ['10','25','50','100'], showSizeChanger: true}}
							loading={loading}
							scroll={{ x: 1500 }}
							rowKey={(row: any) => row.key}
						/>
					</div>
				</Card>
			</Space>
		</Content>
	  </Layout>
	);
}
export default ListInventory;



