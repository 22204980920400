import * as React from 'react';
import { Button, Card} from 'antd';
import moment from 'moment';
import { Container, Dialog, DialogActions, DialogContent, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import { DeleteFilled} from '@ant-design/icons';
import { BsPencilFill } from 'react-icons/bs';
import Cancel from '@material-ui/icons/Cancel';
interface Props{
    customerInfo: any[];
    setCustomerInfo:(data:any)=>void;
    close:()=>void;
    setEdit:(i:number)=>void;
    setDelete:(i:number)=>void;
}
const TelemedicinNotes: React.FC<Props> = ({customerInfo,setCustomerInfo,close,setEdit,setDelete}) => {
    const [open , setOpen] = React.useState(false)
    return (
        <>
        {customerInfo?.length > 0 && 
            <div>
                <Container>
                    <Card style={{ marginTop: '50px', overflow: 'auto', maxHeight:'200px' }}>
                    <Button type='default' style={{left:'92%'}}  onClick={close}><Cancel/></Button>
                        {customerInfo[0].noteLogs !== undefined && customerInfo[0].noteLogs.length > 0 ?
                            <>
                                <TableContainer component={Paper}>
                                    <div>
                                        <TableHead>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Time</TableCell>
                                            <TableCell>Viewer Name</TableCell>
                                            <TableCell>Notes</TableCell>
                                            <TableCell>Edit</TableCell>
                                            <TableCell>Delete</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {customerInfo[0].noteLogs.map((row: any, index: any) => (                                                
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {moment(row.dateTime).format("MM-DD-YYYY")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(row.dateTime).format("hh:mm A")}
                                                    </TableCell>
                                                    <TableCell>{row.CreatedBy}</TableCell>
                                                    <TableCell>{row.note}</TableCell>
                                                    <TableCell><Button type='link' onClick={()=>setEdit(index)}><BsPencilFill /></Button></TableCell>
                                                    <TableCell>
                                                        <Button type='default' onClick={() => setDelete(index)}><DeleteFilled style={{color:'red'}}/>
                                                        {/* <Dialog
                                                            open={open}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                                >
                                                            <DialogContent>{'Are you sure, Would you like to delete this note?'}</DialogContent>
                                                            <DialogActions>
                                                            <Button  onClick={()=>}>Yes</Button>
                                                            <Button  onClick={() => setOpen(false)}>No</Button>
                                                            </DialogActions>
                                                        </Dialog> */}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </div>
                                </TableContainer>
                            </>
                        : (<p>You Didn't Added the Note Please add the Note to see it here.</p>)}
                    </Card>
                </Container>
            </div>}
        </>
    );
}
export default TelemedicinNotes;



