import React, {
  useEffect,
  useCallback,
  useState,
  createContext,
  useMemo,
} from 'react';

import { SharedState, SharedDispatch, Location} from './models';
import { getAllOpenedLocations, getOpenedLocations } from './api';
import { firebase } from '../../firebase';
import { newLocationFormItems } from '../admin/Pages/SchedularLite/schedularUtils';

export const SharedContext = createContext<SharedState>({
  locations: null,
  standradTest: false,
  records:null,
  newLocationForm:newLocationFormItems.reduce((a:any,b:any)=>({...a,[b.name]:b.type==='text'?'':b.type==='number'?null:b.type==='select'?'':''}),{}),
  allLocations:null,
  daywiseUsers:null
});

export const SharedDispatchContext = createContext<SharedDispatch>({
  getLocations() {},
  upateTestType(){},
  updateRecords(){},
  setLocation(){},
  setFormData(){},
  updateBlockOutDays() {},
  updateSharedSlots() {},
  setDaywiseUsers() {}
});

export const SharedProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [allLocations, setAllLocations] = useState<any[] | null>(null);
  const [blockOutDays, setBlockOutDays] = useState<Number[]>([])
  const [daywiseUsers, setDaywiseUser] = useState<any[] | null>(null);
  const [sharedSlots, setSlots] = useState<any>([]);
  const [standradTest, setTestType] = useState<boolean>(false);
  const [newLocationForm, setNewLocationForm] = useState<any>(newLocationFormItems.reduce((a:any,b:any)=>({...a,[b.name]:b.type==='text'?'':b.type==='number'?null:b.type==='select'?'':''}),{}));
  const [records, setRecods] = useState({});

  const getLocations = useCallback(async () => {
    try {
      const locations = await getOpenedLocations();
      setLocations(locations);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const getAllLocations = useCallback(async () => {
    try {
      const locations = await getAllOpenedLocations();
      setAllLocations(locations);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    firebase.auth().signInAnonymously();

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser:any) => {
        if (firebaseUser) {
          getLocations();
          getAllLocations();
        }
      });
    
    return () => unsubscribe();
  }, [getLocations, getAllLocations]);

  const store = useMemo(
    () => ({
      locations,
      standradTest,
      records,
      allLocations,
      newLocationForm,
      blockOutDays,
      daywiseUsers
    }),
    [locations, standradTest, records,allLocations,newLocationForm, blockOutDays, daywiseUsers]
  );

  const upateTestType = useCallback(
    (update: any) => {
      setTestType(update)
    },
    [setTestType]
  );
  const updateSharedSlots = useCallback(
    (update: any) => {
        setSlots(update);
    },
    [setSlots]
);
  const updateBlockOutDays = useCallback(
    (data:Number[] ) => {
        setBlockOutDays(data);
    },
    [setBlockOutDays]
);
  const updateRecords = useCallback(
    (data: any) => {
      setRecods(data)
    },
    [setRecods]
  );
  const setLocation = useCallback(
    (data: any) => {
      setAllLocations(data)
    },
    [setAllLocations]
  );
  
  const setFormData = useCallback(
    (data: any) => {
        setNewLocationForm((state:any)=>({ ...state ?? {} , ...data ?? {}}))
    },
    [setNewLocationForm]
  );
  const setDaywiseUsers = useCallback(
    (data: any) => {
        setDaywiseUser((state:any)=>([  ...data ?? {}]))
    },
    [setDaywiseUser]
  );

  const dispatch = useMemo(
    () => ({
      getLocations,
      upateTestType,
      updateRecords,
      setLocation,
      getAllLocations,
      setFormData,
      updateBlockOutDays,
      updateSharedSlots,
      setDaywiseUsers
    }),
    [standradTest, updateRecords,setLocation, getAllLocations,setFormData, setDaywiseUsers] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SharedContext.Provider value={store}>
      <SharedDispatchContext.Provider value={dispatch}>
        {children}
      </SharedDispatchContext.Provider>
    </SharedContext.Provider>
  );
  
};

export const useSharedState = () => {
  const context = React.useContext(SharedContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedState` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};

export const useSharedDispatch = () => {
  const context = React.useContext(SharedDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedDispatch` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};
