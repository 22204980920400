import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';

import { AppointmentListItem } from '../../../models';
import {
  cancelAppointment,
  cancelCrelioAppointment,
  cancelAppointmentLosLomitos
} from '../../../../shared/providers/schedule/api';
import { CloseOutlined } from '@ant-design/icons';
import { deleteNpDestination } from '../../../api';
import { useAdminDispatch, useAdminState } from '../../../provider';
const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
`;

interface IProps {
  appt?: AppointmentListItem;
  isNpDest?: boolean;
  deletes?: any;
  getNpDest?:() => void;
  handleSearch:()=>void;
}

const Cancel = ({ appt, isNpDest, deletes, getNpDest ,handleSearch}: IProps) => {
    const { setAppointments } = useAdminDispatch();
    const { appointments }= useAdminState()
  const [cancelText, setCancelText] = useState(isNpDest === true ? 'Delete' : 'Cancel');
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect(() => {
    if (appt?.isCanceled !== '' && appt?.isCanceled !== false && isNpDest !== true) {
      setCancelText('Cancelled');
    }
  }, []);
  let index:any=[]
  if(appt?.minorIndex===null || appt?.minorIndex===-1 ){
      index.push(-1)
  }else{
      index.push(appt?.minorIndex)
  }
  const handleCancel = async () => {
      console.log("appts",appt)
      console.log("indexarray",index);
      
    setCancelText('Cancel');
    try {
        if (appt?.appointmentId) {
            //   let form:any=appt;
            let params: any = { id: appt.appointmentId }

            params['index'] = index
            
            setCancelText('Cancelling ...');
            if (appt?.location?.limsinfo?.type === 'crelio') {
                await cancelCrelioAppointment(appt?.appointmentId);
            } else {
                console.log("index", params)
                const cancel = await cancelAppointment(params);
                console.log("cancel", cancel)
                if (cancel.data.status === "Appointment cancelled") {
                    await sleep(2000);
                    setCancelText('Cancelled');
                    await sleep(1000);
                    let tableDatas: any = appointments;
                    tableDatas = appointments?.map((data: any) => {
                        let rowData: any = data
                        if (data.appointmentId === appt.appointmentId) {
                            rowData = { ...rowData, isCanceled: true, status: 'Cancelled' };
                        }
                        return rowData;
                    })
                    setAppointments(tableDatas);
                    //handleSearch();        
                }
            }
        }
    } catch (e) {
      setCancelText('Error');
      console.error(e);
    }
  };
    const handleCancelLosLomitos=async(type:any)=>{
        try {
            let params = {
            firstName: appt?.firstName,
              lastName: appt?.lastName,
              email: appt?.email,
              id: appt?.confirmationId,
              type: type
            }
            setCancelText('Cancelling ...');
           const cancel=await cancelAppointmentLosLomitos(params)
            if(cancel.data.status==="Appointment cancelled"){
                await sleep(1000);
                setCancelText('Cancelled');
                handleSearch();        
            }
        } catch (error) {
            setCancelText('Error');
            console.error(error);
        }
    }
  const handleDelete = async () => {
    try {
      if (isNpDest === true) {
        setCancelText('Deleting ...');
        const del = await deleteNpDestination(deletes);
        if(del?.data?.status === 200){
            console.log('delete1')
            if(getNpDest){
                const data =  getNpDest();
                console.log('delete2',data);
            }
            setCancelText('Deleted');
        }
      }
    } catch (e) {
      setCancelText('Error');
      console.error(e);
    }
  };

  if (cancelText === 'Cancel' && appt?.cPartnerID !=='LLT001') {
    return (
        <Popconfirm
            title={'Are you sure you would like to cancel this appointment?'}
            onConfirm={handleCancel}
            okText="Yes"
            cancelText="No"
            disabled={cancelText !== 'Cancel' || appt?.results !== '' || appt?.isCheckedIn==true}
        >
            <Button
                type="link"
                disabled={cancelText !== 'Cancel' || appt?.results !== '' || appt?.isCheckedIn ==true}
                block
                icon={<CloseOutlined />}
            >
                {cancelText}
            </Button>
        </Popconfirm>
    );
  } else if (cancelText === 'Delete') {
    return (
      <Popconfirm
        title={'Are you sure you would like to delete this NP destination?'}
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="link"
          disabled={cancelText !== 'Delete'}
          block
          icon={<CloseOutlined />}
        >
          {cancelText}
        </Button>
      </Popconfirm>
    );
  } else if(cancelText === 'Cancel' && appt?.cPartnerID ==='LLT001') {
    return (
        <Popconfirm
          title={`Do you want to cancel all your future appointments at ${appt?.location?.name}?`}
          onConfirm={()=>handleCancelLosLomitos('all')}
         onCancel={ ()=>handleCancelLosLomitos('single')}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            disabled={cancelText !== 'Cancel'}
            block
            icon={<CloseOutlined />}
          >
            {cancelText}
          </Button>
        </Popconfirm>
      );
  }
  else {
    return <Text>{cancelText}</Text>;
  }
};

export default Cancel;
