import { Modal, Spin } from 'antd';
import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getTestResultByQBenchCustomerId, updateAssayIdByQbenchCustomerId, updateQbenchCustomerIdByLocationId } from '../../api';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface IProps {
    setShowQBenchUpdateModal: Function;
    showUpdateConfirm: boolean;
    setShowUpdateConfirm: Function;
    updatedValue: any;
    QBdata: any;
    isAssay: boolean;
    isUpdatingForRapid: boolean;
    isUpdatingForAntigen: boolean;
    setConfirmedUpdatedData: any;
    handleDrawerToggle: any;
}
const QBenchUpdateConfirmation = ({
    setShowQBenchUpdateModal,
    showUpdateConfirm,
    setShowUpdateConfirm,
    updatedValue,
    QBdata,
    isAssay,
    isUpdatingForRapid,
    isUpdatingForAntigen,
    setConfirmedUpdatedData,
    handleDrawerToggle,
}: IProps) => {
    const [showUpdateAllAssay, setShowUpdateAllAssay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allAssayLoading, setAllAssayLoading] = useState(false);
    const [alertbox, setalertbox] = useState({
        open: false,
        type: '',
        msg: '',
    });
    const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    const handleOk = async () => {
        setLoading(true);
        if (isAssay === false){
            let oldCustomerIds: any = QBdata.qbenchCustomerOldIds?.length > 0
                ? [...QBdata.qbenchCustomerOldIds, updatedValue.record]
                : [QBdata.qbenchCustomerId, updatedValue.record];
            console.log('oldCustomerIds 1 ::', oldCustomerIds);
            oldCustomerIds = await oldCustomerIds.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
            console.log('oldCustomerIds unique ::', oldCustomerIds);
            const res = await updateQbenchCustomerIdByLocationId({
                qbenchCustomerId: updatedValue.record,
                oldCustomerIds: oldCustomerIds,
                locationName: QBdata.name
            });
            if (res.data.status === 200) {
                setalertbox({
                    open: true,
                    type: 'success',
                    msg: 'Qbench Customer Id is updated',
                });
                setTimeout(() => {
                    setConfirmedUpdatedData([updatedValue]);
                    handleDrawerToggle((prevData: boolean) => {
                        return !prevData;
                    });
                    window.location.reload();
                    setLoading(false);
                    setShowUpdateConfirm(false);
                    setShowQBenchUpdateModal(false);
                }, 500);
            } else {
                setalertbox({
                    open: true,
                    type: 'error',
                    msg: 'Internal error',
                });
                console.log('res :::', res.data);
            }
        }else{
            setalertbox({
                open: true,
                type: 'warning',
                msg: `This will take some minutes to update in QBench, Dont close this screen`,
            });
            const res = await updateAssayIdByQbenchCustomerId({
                qbenchCustomerId: QBdata.qbenchCustomerId,
                assayId: updatedValue.record,
                isUpdatingForRapid: isUpdatingForRapid,
                isUpdatingForAntigen: isUpdatingForAntigen,
            });
            
            if (res.data.status === 200) {
                // setalertbox({
                //     open: true,
                //     type: 'info',
                //     msg: `Assay Id for ${isUpdatingForRapid?'Rapid':'Standard and Express'} is updated`,
                // });
                // setTimeout(() => {
                //     setShowUpdateAllAssay(true);
                // }, 1000);

                await handleOkForAllAssay().catch((err)=>{
                    console.log(err);
                    //handleOkForAllAssay();
                });
            } else {
                setalertbox({
                    open: true,
                    type: 'error',
                    msg: 'Internal error',
                });
                console.log('res :::', res.data);
            }
        }
        setLoading(false) 
    };
    const handleOkForAllAssay = async () => {
        setAllAssayLoading(true);
        let oldAssayId = isUpdatingForRapid
            ? QBdata.qbenchRapidAssayId
            : isUpdatingForAntigen ? QBdata.qbenchAntigenAssayId
            : QBdata.qbenchExpressAssayId;
        
        let idLength = 0
        let oldCustomerIds: any = QBdata.qbenchCustomerOldIds?.length > 0 ? QBdata.qbenchCustomerOldIds : [QBdata.qbenchCustomerId]

        while (idLength < oldCustomerIds.length) {
            let qbenchCustomerId = oldCustomerIds[idLength]
            console.log(oldCustomerIds,qbenchCustomerId);
            
            let testDatas: any = [];
            let pageNumber = 1;
            let totalPage = 1;

            while (pageNumber <= totalPage) {
                if (pageNumber > 1) await sleep(3000);
                setalertbox({
                    open: true,
                    type: 'warning',
                    msg: `This will take some minutes to update in QBench, Dont close this screen`,
                });
                const res = await getTestResultByQBenchCustomerId({
                    qbenchCustomerId: qbenchCustomerId,
                    oldAssayId: oldAssayId ? oldAssayId : 6,
                    pageNumber: 1,
                    assayId: updatedValue.record,
                });
                if (res.data.status === 200) {
                    testDatas = [...testDatas, ...res.data.result?.data];
                    if (pageNumber === 1) {
                        totalPage = res.data.result?.total_pages;
                    }
                    console.log('totalPages,currentPage',totalPage,pageNumber);
                    pageNumber++;
                } else {
                    setalertbox({
                        open: true,
                        type: 'error',
                        msg: 'Internal error',
                    });
                    console.log('res :::', res.data);
                }
            }
            console.log('testDatas ::', testDatas);
            idLength++;
        }

        setalertbox({
            open: true,
            type: 'success',
            msg: 'All Tests Assay Id is updated!',
        });
        setTimeout(() => {
            setAllAssayLoading(false);
            setShowUpdateConfirm(false);
            setConfirmedUpdatedData([updatedValue]);
            handleDrawerToggle((prevData: boolean) => {
                return !prevData;
            });
            setShowQBenchUpdateModal(false);
        }, 500);
    };
    return (
        <Modal
            zIndex={1000}
            maskClosable={false}
            visible={showUpdateConfirm}
            onCancel={() => {
                setShowUpdateConfirm(false);
            }}
            onOk={handleOk}
            cancelText={'No'}
            okText={loading ? 'Processing...' : 'Yes'}
        >
            {isAssay === false && (
                <h2 style={{ padding: '16px' }}>
                    Are you sure you want to update the QBench Customer ID to:
                    <br />
                    <b> {updatedValue.label}</b> ?
                </h2>
            )}
            {isAssay === true && (
                <h2 style={{ padding: '16px' }}>
                    Are you sure you want to update the default assay for new
                    {isUpdatingForRapid
                        ? ` Rapid`
                        : isUpdatingForAntigen
                        ? ` Antigen`
                        : ` Standard and Express`}{' '}
                    Tests to: <br />
                    <b> {updatedValue.label}</b> ?
                </h2>
            )}
            {loading && (
                <h2 style={{ padding: '8px', textAlign: 'center' }}>
                    <Spin />
                </h2>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={3000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            {showUpdateAllAssay === true && (
                <Modal
                    zIndex={1000}
                    maskClosable={false}
                    visible={showUpdateAllAssay}
                    onCancel={() => {
                        if (!allAssayLoading) {
                            setShowUpdateAllAssay(false);
                            setShowUpdateConfirm(false);
                            setConfirmedUpdatedData([updatedValue]);
                            handleDrawerToggle((prevData: boolean) => {
                                return !prevData;
                            });
                            setLoading(false);
                            setShowQBenchUpdateModal(false);
                        }
                    }}
                    onOk={() => !allAssayLoading && handleOkForAllAssay()}
                    cancelText={'No'}
                    okText={'Yes'}
                >
                    <h2 style={{ padding: '16px' }}>
                        Do you want to update All existing QBench tests that
                        have not been started <br />
                        <b>(NOT STARTED)</b> to: <br />
                        <b> {updatedValue.label}</b> ?
                    </h2>
                    {allAssayLoading && (
                        <h2 style={{ padding: '8px', textAlign: 'center' }}>
                            <Spin />
                        </h2>
                    )}
                    {allAssayLoading === true && (
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={alertbox.open}
                            autoHideDuration={9000}
                            onClose={() =>
                                setalertbox({ open: false, type: '', msg: '' })
                            }
                        >
                            <Alert
                                onClose={() =>
                                    setalertbox({
                                        open: false,
                                        type: '',
                                        msg: '',
                                    })
                                }
                                severity={alertbox.type}
                            >
                                {alertbox.msg}
                            </Alert>
                        </Snackbar>
                    )}
                </Modal>
            )}
        </Modal>
    );
};

export default QBenchUpdateConfirmation;
