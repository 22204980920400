import axios from "axios";
export interface data{

}


export const checkemail = async(email:string)=>{
    let data = await axios({
        method:'POST',
        url:'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/UserDetailsUpdateAsPerEmail',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            type:'search',
            email:email.toLocaleLowerCase()
        }
    })
    return data;
}


export const updateMobile = async (id: string,mobile:string) => {
    let data = await axios({
        method: 'POST',
        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/UserDetailsUpdateAsPerEmail',
        headers: {
            'content-type': 'application/json'
        },
        data: {
            type: 'edit',
            id:id,
            mobile:mobile
        }
    })
    return data;
}
