import * as React from 'react';
import { Button, Card,Typography} from 'antd';
import moment from 'moment';
import { Container, Grid, Modal, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField } from '@material-ui/core';
import TelemedicinNotes from './TelemedicinNotes';
import { TelemedicineApptNoteLogs } from '../LocationAndService/API';

interface Props {
    open: any;
    close: () => void;
    customerInfo: any[];
    setCustomerInfo: (data:any) => void;
}
const textField = {
    margin: "5px 0px 5px 0px"
}

const { Title } = Typography;

const TelemedicinUserInfo: React.FC<Props> = ({open,close,customerInfo,setCustomerInfo}) => {
    const [viweNote, setViweNote] = React.useState(false)
    const [edit, setEdit] = React.useState(-1);
    const [note, setNote] = React.useState('');
    const [deleteNote, setDelete] = React.useState(-1);    
    
    async function handleSubmit() {
        let oldNote:any[] = customerInfo[0].noteLogs ?? [];
        if(deleteNote !== -1){
            oldNote = oldNote.filter((e:any,i:number)=>deleteNote !== i);
            setDelete(-1)
        } else {
            if(edit === -1){
                oldNote.push({
                    id:customerInfo[0]?.id ?? '',
                    CreatedBy: JSON.parse(window.localStorage.getItem('userDetails')!).name,
                    email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
                    note
                })
            } else {
                oldNote[edit] ={
                    id:customerInfo[0]?.id ?? '',
                    CreatedBy: JSON.parse(window.localStorage.getItem('userDetails')!).name,
                    email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
                    note
                } 
                setEdit(-1);
            }
            setNote('');
        }
        setCustomerInfo([{...customerInfo[0],noteLogs:oldNote}])
        await TelemedicineApptNoteLogs({
            id:customerInfo[0]?.id,
            noteLogs:oldNote
        })
    }
    React.useEffect(() => {
        if(deleteNote !== -1){
            handleSubmit()
            return;
        }
        setNote(edit === -1 ? '' : customerInfo[0]?.noteLogs[edit]?.note ?? '')
    }, [edit,deleteNote])
    return (
    <>
        {customerInfo &&
            <div>
            <Modal open={open} onClose={close}>
                <Container style={{marginTop: '10px',marginBottom: '10px', overflow: 'auto', maxHeight:'99%'}}>
                    <Card >
                        <Title>Customer Details</Title>
                        <Grid container spacing={2}>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Full Name"
                                    value={customerInfo[0]?.firstName + " " + customerInfo[0]?.lastName}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-basic"
                                    label="Address"
                                    value={customerInfo[0]?.address.address + "." + " " + "City" + ":" + customerInfo[0]?.address.city + "."+" "+"State"+":" + customerInfo[0]?.address.state + "." + " " + "ZipCode" + ":" + customerInfo[0]?.address.zipCode}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                    fullWidth
                                /></Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Symptoms"
                                    value={customerInfo[0]?.symptoms}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                /></Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Ethnicity"
                                    value={customerInfo[0]?.ethnicity}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                /></Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Test Type"
                                    value={customerInfo[0]?.testType}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                /></Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Results"
                                    value={customerInfo[0]?.results}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    id="outlined-basic"
                                    label="Tested Date"
                                    value={customerInfo[0]?.appointmentdate}
                                    variant="outlined"
                                    style={textField}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>

                        <div style={{ margin: "10px" }}></div>
                        <div>
                            <TextareaAutosize
                                aria-labelledby='Note'
                                style={{
                                    width: '100%',
                                    padding: "15px",
                                    border: "1px solid",
                                    borderRadius: '3px',
                                    margin: "5px 0px 5px 0px"
                                }}
                                aria-label="empty textarea"
                                placeholder="Note"
                                onChange={(e)=>{setNote(e.target.value)}}
                                value={note}
                                defaultValue={ edit === -1 ? '' : customerInfo[0]?.noteLogs[edit]?.note ?? ''}
                            />
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    // variant="contained"
                                    style={{
                                        color: "#fff",
                                        background: "#1890ff",
                                        width: "30%",
                                        right: "3%"
                                    }}
                                    
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                            <Button type='primary' onClick={()=>setViweNote(true)}>
                                Visite notes
                            </Button>
                            {viweNote && <TelemedicinNotes
                                customerInfo={customerInfo}
                                setCustomerInfo={setCustomerInfo}
                                close={()=>setViweNote(false)}
                                setEdit={setEdit}
                                setDelete={setDelete}
                            />}
                        </div>
                        <div style={{ margin: "10px" }}></div>
                        <div style={{ overflow: 'auto', maxHeight: '444px' }} >
                            {customerInfo[0].viewLogs !== undefined && customerInfo[0].viewLogs.length > 0 &&
                                <>
                                    <div style={{ position: 'sticky' }}>
                                        <Title>Log</Title>
                                    </div>
                                    <TableContainer component={Paper} >
                                        <div>
                                            <TableHead>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Time</TableCell>
                                                <TableCell>Viewer Name</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {customerInfo[0].viewLogs.map((row: any, index: any) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {moment(row.dateTime).format("MM-DD-YYYY")}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(row.dateTime).format("hh:mm A")}
                                                        </TableCell>
                                                        <TableCell>{row.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </div>
                                    </TableContainer>
                                </>
                            }
                            <div style={{ margin: "40px" }}></div>
                        </div>
                    </Card>
                </Container>
            </Modal>
            </div>
        }
    </>
    );
}
export default TelemedicinUserInfo;



