import React, { useMemo, useState } from 'react';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components';

import { ErrorText } from '../styled';
import { colors } from '../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../../shared/providers/schedule/provider';
import { Location } from '../../models';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../provider';
import AlertModal from "../AlertModal";
import { cloneDeep, filter} from "lodash";
import { getSlotsByLocationId } from '../../api';
import { useAdminDispatch, useAdminState } from '../../../admin/provider';
import { useManageDispatch, useManageState } from '../../../shared/providers/manage/provider';
import { releaseReservation } from '../../../shared/providers/schedule/api';
import { includes } from "lodash";
import RapidAlertModal from '../RapidAlertModal';
import { config } from '../../../../config';

interface ButtonProps {
	selected: boolean;
}

const LocationButton = styled.button`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  border: 0;
  background: ${({ selected }: ButtonProps) =>
		selected ? colors.blue : colors.blue8};
  color: ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.black};
  transition: all 0.2s ease-in-out;
  padding: 18px 20px;
  cursor: pointer;
  margin-bottom: 4px;

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 24px 33px;
  }
`;

const Info = styled.div`
  text-align: left;
`;

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`;

const LocationsContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 45px;
`;

const Error = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
`;

const Tag = styled.div`  
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    position: absolute;
    width: 217px;
    height: 28px;
    overflow: hidden;
    left: -2px;
    top: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 30px;
    color: ${({ selected }: ButtonProps) =>
		selected ? colors.black : colors.white};
    -webkit-transform: rotate(
      36.31deg
      );
          -ms-transform: rotate(36.31deg);
          -webkit-transform: rotate(
      36.31deg
      );
    background-color:  ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.blue};
    `
const RapidTag = styled.div`  
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    position: absolute;
    width: 222px;
    height: 28px;
    overflow: hidden;
    left: -13px;
    top: 42px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 30px;
    color: ${({ selected }: ButtonProps) =>
		selected ? colors.black : colors.white};
    -webkit-transform: rotate(
      36.31deg
      );
          -ms-transform: rotate(36.31deg);
          -webkit-transform: rotate(
      36.31deg
      );
    background-color:  ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.blue};
    `

const TagBox = styled.div`
    position: absolute;
    width: 164px;
    height: 113px;
    right: 0px;
    top: 1px;
    overflow: hidden;
`
const RapidTagBox = styled.div`
    position: absolute;
    width: 180px;
    height: 113px;
    right: 0px;
    top: 1px;
    overflow: hidden;
`
const AntigenTagBox = styled.div`
  position: absolute;
  width: 246px;
  height: 113px;
  right: 0px;
  top: 1px;
  overflow: hidden;
`;
const AntigenTag = styled.div`
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  width: 277px;
  height: 28px;
  overflow: hidden;
  left: -33px;
  top: 48px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 30px;
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.black : colors.white};
  -webkit-transform: rotate(36.31deg);
  -ms-transform: rotate(36.31deg);
  -webkit-transform: rotate(36.31deg);
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.blueLight};
`;
interface props {
	confirmAndPay?: boolean;
	isManage?: boolean;
	isEdit?: boolean;
	appoitmentLocation?: string;
    showAllAvailableLocation?:boolean;
}
const LocationButtonGroup: React.FC<props> = ({ confirmAndPay, isManage, isEdit, appoitmentLocation, showAllAvailableLocation}) => {

	const { updateFormValues, toggleShowAlertModal, updateSlotsList } = useStepsDispatch();
	const { updateAdminSlots } = useAdminDispatch();
	const { upateTestType } = useSharedDispatch();
	const { updateRescheduleSlots, updateAppointment, updateStandradTest } = useManageDispatch();
	const { appointment } = useManageState();
    let { allLocations }:any = useSharedState();
    allLocations = allLocations?.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>['name'].every((k:any)=>v2[k] ===v[k]))===i) ?? []
	const { form: { isExpressSameDayTest, slot, location, travelType, minors, date, reservationId, isRapidTest, reservationCount}, showAlertModal, showChangeLocationModal,  } = useStepsState();
	const { locations } = useSharedState();
	const closeModal = () => toggleShowAlertModal(false);
	const rollbackLocation = useMemo(() => cloneDeep(location), [showChangeLocationModal]);
	const rollbackSlot = useMemo(() => cloneDeep(slot), [showChangeLocationModal]);
	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [showChangeLocationModal]);

	//Admin
	const { updateAdminManageAppointment, toggleAdminLocationModal, updateStandradTestAdmin } = useAdminDispatch();
	const { manageAppointment, Editappointments } = useAdminState();
	const appointmentdata: any = Editappointments !== null ? Editappointments[0] : manageAppointment
	const test = appointmentdata.isExpressSameDayTest ? 'express' :  appointmentdata.isRapidTest ? 'rapid' : appointmentdata.isAntigen ? 'antigen': 'standard';
	let IstraveltypeINT: boolean = false;
	let IstraveltypeDOM: boolean = false;

	if (travelType !== null && travelType !== undefined && travelType !== "") {
		IstraveltypeINT = travelType == "INT" ? true : false;
		IstraveltypeDOM = travelType == "DOM" ? true : false;
	} else if(appointment !== null && appointment !== undefined){
		IstraveltypeINT = appointment.travelType == "INT" ? true : false;
		IstraveltypeDOM = appointment.travelType == "DOM" ? true : false;
	} else {
		IstraveltypeINT = appointmentdata.travelType == "INT" ? true : false;
		IstraveltypeDOM = appointmentdata.travelType == "DOM" ? true : false;
	}

	var filteredlocation = showAllAvailableLocation ? allLocations : isEdit
    ? filter(locations, function (data: any) {
      if(data.schools && data.schools?.length){
        return data.schools.includes(appoitmentLocation) && (test === "standard" ? ((appointmentdata.payingMethod === "Insurance" && data.isInsurance?.standard === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.standard === true) || (appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.standard === true)) :
        test === "express" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.express === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.express === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.express === true)) :
          test === "rapid" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.rapid === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.rapid === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.rapid === true)) :
            test === "antigen" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.antigen === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.antigen === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.antigen === true)) : false );
      } else {
        return data.cPartnerID === appoitmentLocation && (appointmentdata.testSelection === 'general'? !!data.isGeneral : (appointmentdata.testSelection === 'flight' ? !!data.isTravel : (appointmentdata.testSelection === 'community' ? !!data.isCommunity : (appointmentdata.testSelection === 'sfoairport' ? !!data.isWork :false))))
        && (test === "standard" ? ((appointmentdata.payingMethod === "Insurance" && data.isInsurance?.standard === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.standard === true) || (appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.standard === true)) :
        test === "express" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.express === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.express === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.express === true)) :
          test === "rapid" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.rapid === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.rapid === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.rapid === true)) :
            test === "antigen" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.antigen === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.antigen === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.antigen === true)) : false )
      }
      })
    : filter(locations, function (data: any) {
      if (data.schools && data.schools?.length) {
        return data.schools.includes(appointmentdata.cPartnerID) && (test === "standard" ? ((appointmentdata.payingMethod === "Insurance" && data.isInsurance?.standard === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.standard === true) || (appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.standard === true)) :
          test === "express" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.express === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.express === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.express === true)) :
            test === "rapid" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.rapid === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.rapid === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.rapid === true)) :
              test === "antigen" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.antigen === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.antigen === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.antigen === true)) : false);
      } else {
        return data.cPartnerID === appointmentdata.cPartnerID && (appointmentdata.testSelection === 'general'? !!data.isGeneral : (appointmentdata.testSelection === 'flight' ? !!data.isTravel : (appointmentdata.testSelection === 'community' ? !!data.isCommunity : (appointmentdata.testSelection === 'sfoairport' ? !!data.isWork :false))))
        && (test === "standard" ? ((appointmentdata.payingMethod === "Insurance" && data.isInsurance?.standard === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.standard === true) || (appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.standard === true)) :
          test === "express" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.express === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.express === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card")&& data.isCreditCard?.express === true)) :
            test === "rapid" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.rapid === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.rapid === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.rapid === true)) :
              test === "antigen" ? ((appointmentdata.payingMethod === "No Insurance" && data.isNoInsurance?.antigen === true) || (appointmentdata.payingMethod === "Insurance" && data.isInsurance?.antigen === true) || ((appointmentdata.payingMethod === "Credit Card" || appointmentdata.payingMethod === "Credit card") && data.isCreditCard?.antigen === true)) : false)
      }
    });

	const [showRapidAlertModal, setShowRapidAlertModal] = useState(false);
	const handleRapidModalClose = () => {
		setShowRapidAlertModal(false);
	  };

	console.log("manageAppointment", manageAppointment, travelType)

    const TestTags:React.FC<any> = ({field,loc,num,name})=>{
        const data:any = {
          'express':'EXPRESS',
          'rapid':'RAPID',
          'antigen':'ANTIGEN'
        }
        return(
          <>
            {num===1 &&(
              <TagBox>
                <Tag selected={field.value?.name === loc.name}>
                  {data[name]} TESTING AVAILABLE
                </Tag>
              </TagBox>
            )}
            {num===2 && (
              <RapidTagBox>
                <RapidTag selected={field.value?.name === loc.name}>
                  {data[name]} TESTING AVAILABLE
                </RapidTag>
              </RapidTagBox>
            )}
            {num===3 && (
              <AntigenTagBox>
                <AntigenTag selected={field.value?.name === loc.name}>
                  {data[name]} TESTING AVAILABLE
                </AntigenTag>
              </AntigenTagBox>
            )}
          </>
        )
    }
	return (
		<>
			<Field
				name="location"
				validate={(value: Location | null) => {
					let error;

					if (!value) {
						error = 'Please choose location';
					}

					return error;
				}}
			>
				{({ form: { setFieldValue }, field, meta: { error } }: FieldProps) => (
					<LocationsContainer>
						{filteredlocation &&
							filteredlocation?.map((loc: any, index: any) => {

								if (showAllAvailableLocation || (IstraveltypeDOM === loc.isDomestic && loc.isDomestic === true)) {
									if (!field.value) {
										setFieldValue('location', appointmentdata.location);
									  }
									return (
										<LocationButton
											key={loc.name}
											type="button"
											selected={field.value?.name === loc.name}
											onClick={async () => {
												setFieldValue('location', loc);
												localStorage.setItem('location', JSON.stringify(loc))
												updateFormValues({ location: loc, slot: undefined });

												if (reservationId) {
													const responseData = await releaseReservation(
														slot?.period,
														reservationCount,
														location?.qbenchCustomerId,
														date,
														reservationId,
														false,
														isRapidTest
													)
												}

												let update: any = {
													location: loc,
													slot: undefined
												}
												updateAdminManageAppointment({
													...manageAppointment,
													...update
												})
												updateAppointment({
													...appointment,
													location: loc,
													slot: undefined,
                                                    reservationId: null,
												});

												if (loc?.hasVipSlots == false && loc?.rapidTest == false) {
													upateTestType(true);
													updateStandradTest(true);
													updateStandradTestAdmin(true);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isRapidTest: false,
                                                        isExpressSameDayTest: false,
                                                        isAntigen:false
													})
													
													let update: any = {
														location: loc,
														slot: undefined,
														isExpressSameDayTest: false,
														isRapidTest: false
													}
													
													updateAdminManageAppointment({
														...manageAppointment,
														...update
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
														isExpressSameDayTest: false,
													});

												}else if(loc?.hasVipSlots == true && loc?.rapidTest == false) {
													upateTestType(false);
													updateStandradTest(false);
													updateStandradTestAdmin(false);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
                                                        isRapidTest: false,
                                                        isAntigen:false
													})

													let update: any = {
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
														isRapidTest: false
													}

													updateAdminManageAppointment({
														...manageAppointment,
														...update
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
														isExpressSameDayTest: true,
                                                        reservationId: null,
                                                        isAntigen:false
													});
												}

												if (confirmAndPay && isExpressSameDayTest && loc?.hasVipSlots == false) {
													toggleShowAlertModal(true);
                                                }
                                                if (loc.qbenchCustomerId === config.qbenchCustomerId.PostIsolationRapidAntigenTest) {
                                                    updateFormValues({
                                                        isExpressSameDayTest: false,
                                                        isAntigen: true,
                                                    });
                                                }
                                                if (loc.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) {
                                                    updateFormValues({payingMethod:'Credit card',hasInsurance:false,isRapidTest:true,isAntigenTest:false,isExpressSameDayTest:false})
                                                }
											}}
										>
											<Info>
                                                <Name>{loc.name}</Name>
                                                {loc.address1}
                                                <br />
                                                {loc.address2}
                                                {loc?.isOnlyPaymentText&&(<><br/><span style={{color:'red'}}>{loc?.isOnlyPaymentText}</span></>)}
                                            </Info>
                                            {
                                                loc.hasVipSlots?
                                                (
                                                <>
                                                    <TestTags field={field} loc={loc} num={1} name={'express'}/>
                                                    {loc.rapidTest?
                                                    (<>
                                                    <TestTags field={field} loc={loc} num={2} name={'rapid'}/>
                                                    {loc.isAntigen&&<TestTags field={field} loc={loc} num={3} name={'antigen'}/>}
                                                    </>)
                                                    :
                                                    loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>
                                                    }
                                                </>
                                                )
                                                :
                                                loc.rapidTest?
                                                    (<>
                                                    <TestTags field={field} loc={loc} num={1} name={'rapid'}/>
                                                    {loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>}
                                                    </>)
                                                    :
                                                    (loc.isAntigen&&<TestTags field={field} loc={loc} num={1} name={'antigen'}/>)
                                            }
										</LocationButton>
									)

								}

								else if (IstraveltypeINT === loc.isInternational && loc.isInternational === true) {

									// console.log("Hlo")
									return (
										<LocationButton
											key={loc.name}
											type="button"
											selected={field.value?.name === loc.name}
											onClick={async () => {
												setFieldValue('location', loc);
												localStorage.setItem('location', JSON.stringify(loc));
												updateFormValues({ location: loc, slot: undefined });


												let update: any = {
													location: loc,
													slot: undefined
												}
												updateAdminManageAppointment({
													...manageAppointment,
													...update
												})

												updateAppointment({
													...appointment,
													location: loc,
													slot: undefined,
												});

												if (loc?.hasVipSlots == false && loc?.rapidTest == false) {
													upateTestType(true);
													updateStandradTest(true);
													updateStandradTestAdmin(true);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isRapidTest: false,
                                                        isExpressSameDayTest: false,
                                                        isAntigen:false
													})
													
													let update: any = {
														location: loc,
														slot: undefined,
														isExpressSameDayTest: false,
                                                        isRapidTest: false,
                                                        isAntigen:false
													}
													
													updateAdminManageAppointment({
														...manageAppointment,
														...update
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
                                                        isExpressSameDayTest: false,
                                                        isAntigen:false
													});

												}else if(loc?.hasVipSlots == true && loc?.rapidTest == false) {
													upateTestType(false);
													updateStandradTest(false);
													updateStandradTestAdmin(false);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
                                                        isRapidTest: false,
                                                        isAntigen:false
													})

													let update: any = {
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
                                                        isRapidTest: false,
                                                        isAntigen:false
													}

													updateAdminManageAppointment({
														...manageAppointment,
														...update
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
                                                        isExpressSameDayTest: false,
                                                        isAntigen:false
													});
												}

												if (confirmAndPay && isExpressSameDayTest && loc?.hasVipSlots == false) {
													toggleShowAlertModal(true);
                                                }
                                                if (loc.qbenchCustomerId === config.qbenchCustomerId.PostIsolationRapidAntigenTest) {
                                                    updateFormValues({
                                                        isExpressSameDayTest: false,
                                                        isAntigen: true,
                                                    });
                                                }
                                                if (loc.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) {
                                                    updateFormValues({payingMethod:'Credit card',hasInsurance:false,isRapidTest:true,isAntigenTest:false,isExpressSameDayTest:false})
                                                }
											}}
										>
											<Info>
                                                <Name>{loc.name}</Name>
                                                {loc.address1}
                                                <br />
                                                {loc.address2}
                                                {loc?.isOnlyPaymentText&&(<><br/><span style={{color:'red'}}>{loc?.isOnlyPaymentText}</span></>)}
                                            </Info>
                                            {
                                                loc.hasVipSlots?
                                                (
                                                <>
                                                    <TestTags field={field} loc={loc} num={1} name={'express'}/>
                                                    {loc.rapidTest?
                                                    (<>
                                                    <TestTags field={field} loc={loc} num={2} name={'rapid'}/>
                                                    {loc.isAntigen&&<TestTags field={field} loc={loc} num={3} name={'antigen'}/>}
                                                    </>)
                                                    :
                                                    loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>
                                                    }
                                                </>
                                                )
                                                :
                                                loc.rapidTest?
                                                    (<>
                                                    <TestTags field={field} loc={loc} num={1} name={'rapid'}/>
                                                    {loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>}
                                                    </>)
                                                    :
                                                    (loc.isAntigen&&<TestTags field={field} loc={loc} num={1} name={'antigen'}/>)
                                            }
										</LocationButton>
									)

								}

							}
							)}
						<Error>
							<ErrorText hasError={error !== undefined}>{error}</ErrorText>
						</Error>

						<AlertModal
							confirmAndPay={true}
							title={"Are you sure you want to move your appointment location?"}
							content={`You have selected a site that does not have the ${config.testType.expressTitle} testing. By selecting this location option you will be downgraded to ${config.testType.standardTitle} testing, which costs $90 and has a 24-hour turnaround time.`}
							onConfirm={() => {
								updateFormValues({ update: location });
								updateFormValues({ update: slot });
								setFieldValue("location", location);
								upateTestType(true);
								updateStandradTest(true);
								updateStandradTestAdmin(true);
								updateFormValues({ isExpressSameDayTest: false });
								updateAppointment({
									...appointment,
									isExpressSameDayTest: false
								})
								let update: any = {
									isExpressSameDayTest: false
								}

								updateAdminManageAppointment({
									...manageAppointment,
									...update
								})
								closeModal();
							}}
							onCancel={() => {
								upateTestType(false);
								updateFormValues({ location: rollbackLocation, slot: rollbackSlot, isExpressSameDayTest: rollbackisExpressSameDayTest });
								let update: any = {
									location: rollbackLocation, slot: rollbackSlot, isExpressSameDayTest: rollbackisExpressSameDayTest
								}

								updateAdminManageAppointment({
									...manageAppointment,
									...update
								})
								setFieldValue("location", rollbackLocation);
							}}
						/>
					</LocationsContainer>
				)}
			</Field>
		</>
	);
};

export default LocationButtonGroup;
