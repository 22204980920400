import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ImageReviewPage } from './TenantStyeling';

export default function LogoModal({ image, setimage}: any) {
    const classes = ImageReviewPage();
    return (
        <div>
            <Dialog
                open={image.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Image Review"}</DialogTitle>
                <DialogContent>
                    <img className={classes.imagereview} src={image.imageurl} alt="modelImage" />
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => setimage({ ...image,open:false})} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}