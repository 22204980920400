import React from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';
import Admin from './modules/admin/components/layouts/Admin';
import Login from './modules/admin/components/Login';
import { useHistory } from 'react-router';
import { AdminProvider } from './modules/admin/provider';
import { isValidEmail, UrlValidation } from './validations';
import { useAuth0 } from '@auth0/auth0-react';
function App() {
    const history = useHistory();
    let path = history.location.pathname+history.location.search;
    let { isAuthenticated } = useAuth0();
    if(UrlValidation.test(path))
    {
        window.localStorage.setItem('checkin',path);
        if(!isAuthenticated)
            history.push('/')
        else
            history.push(path)
    }
    return (
        <Switch>
            <Route path="/" exact>
            <Login />
            </Route>
            <Route path={routes.dashboard}>
                <AdminProvider>
                    <Admin />
                </AdminProvider>
            </Route>
        </Switch>
        );
}

export default App;
