
import React,{ SetStateAction, useState }from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components';

interface Props {
    open: boolean;
    handleClosed: () => void;
    handleCancel:()=>void;
    Title: string;

};

const Container = styled.div`
max-width: 750px;
background: #ffff;
padding: 35px;
`
const ConfirmationModal : React.FC<Props> = ({ open, Title, handleClosed , handleCancel}) => {
    const [openLabel, setOpenLabel] = useState<boolean>(false);
    const [btnloading, setBtnLoading] = useState<boolean>(false);
    return (
        <>
            <Modal
                open={open}
                onClose={handleClosed}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{display: 'grid', placeItems: 'center'}}
            >
                <Container >
                    <Typography id="modal-modal-title" variant="h6" component="h2"
                     style={{padding: '13px 0px'}}
                    >
                        {Title}
                    </Typography>
                    <DialogActions>
                        <Button
                        style ={{
                            width:'100px',
                            backgroundColor: "#6558F5",
                            color: 'white'
                        }}
                            variant='contained'
                            onClick={()=>{
                                handleClosed();
                                    //handleSearch()
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Container>
            </Modal>
         </>
    );
};
export default ConfirmationModal;