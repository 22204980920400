import React, {
  useCallback,
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import { nanoid } from 'nanoid';

import { AnyObject } from '../../models';
import { ScheduleDispatch, ScheduleState } from './models';
import { getPrices} from './api';
import { config } from '../../../../config';
import { getSiteHostName, getHostName,gettraveltype,getdestination, SetPrices } from '../../../../utils';

const steps = [];

const initialFormState = {
  id: `${nanoid()}-${Date.now()}`,
  phone: '',
  sendMessagesAboutTestResults: false,
  location: null,
  date: null,
  slot: null,
  address: {
    zipCode: '',
    address: '',
    city: '',
    state: '',
    county: '',
    country:''
  },
  firstName: '',
  lastName: '',
  middleName: '',
  isINTNameFormat: false,
  birthDate: null,
  hasMinors: false,
  minors: [],
  hasSymptoms: null,
  symptoms: [],
  email: '',
  password: '',
  confirmEmail: '',
  hasConditions: null,
  hadContact: null,
  sex: null,
  gender: null,
  sexualOrientation: null,
  race: null,
  ethnicity: null,
  isHACustomer: false,
  hipaaConfirmed: false,
  consentForTesting: false,
  commitToAttend: false,
  agreeToCancel: false,
  confirmationId: '',
  departureDateAndTime: null,
  isExpressSameDayTest: false,
  isRapidTest: false,
  IsAirline: true,
  airlineCode: '',
  travelType: '',
  destination: null,
  payingMethod: '',
  socialSecurityNumber: '',
  driverLicenseNumber: '',
  hasInsurance: false,
  insurance: {
    id: '',
    insuranceId: '',
    email: '',
    group: '',
    subscriberFirstName: '',
    subscriberLastName: '',
    frontCard: '',
    backCard: '',
    payerList: '',
  },
  guardian: {
    firstName: '',
    lastName: '',
    relationship: '',
    phone: '',
    email: '',
  },
  registeringFor: '',
  confirmPassword: '',
  postCode: '',
  hasAgreement: false,
  hasMarketCommuni: false,
  userSelect: false,
  reservationId: null,
  reservationCount: 0, 
  rescheduleReservationId: '',
  testSelection: '',
  cPartnerID: '',
  district: '',
  school: '',
  studentID: '',
  note:'',
  isAntigen:false,
  promoCode: "",
  discountAmount: 0
};

export const newMinor = {
  firstName: '',
  lastName: '',
  birthDate: null,
  relationship: '',
  passportCountry:'',
  passportNo : ''
};

export const StepsContext = createContext<ScheduleState>({
  form: initialFormState,
  step: 0,
  showChangeLocationModal: false,
  showAlertModal: false,
  showDepartureTime: false,
  slotsList: [],
  prices: {
    standard: 0,
    expedited: 0,
    rapid: 0,
  },
  configured: false,
});

export const StepsDispatchContext = createContext<ScheduleDispatch>({
  goToNextStep() {},
  goToPrevStep() {},
  updateFormValues() {},
  toggleChangeLocationModal() {},
  toggleShowAlertModal() {},
  updateSlotsList(){},
  toggleShowDepartureDateTimeModal(){},

});

export const ScheduleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState(initialFormState);
  const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [showDepartureTime, setShowDepartureDateTimeModal] = useState(false);
  const [prices, setPrices] = useState({
    standard: 0,
    expedited: 0,
    rapid: 0,
    standard_INT: 0,
    expedited_INT: 0,
    rapid_INT: 0,
    antigen:0,
    antigen_INT:0
  });
  const [configured, setConfigured] = useState(false);
  const [reservationId, setReservationId] = useState<string | null>(null);
  const [reservationCount, setReservationCount] = useState<number>(0);

  useEffect(() => {
    if (!configured) {
      getPrices().then((result) => {
        setConfigured(true);
        setPrices(SetPrices(prices,result)); 
      });
    
      // getSlotsConfigurations().then((result) => {
      //   setSlotsList(result.data);
      //   console.log("getSlotsConfigurations", result);
      // })
      
      const IsAirline: boolean = getSiteHostName();
      updateFormValues({ IsAirline: IsAirline })

      const Airline: string = getHostName();
      updateFormValues({ airlineCode: Airline })

      const traveltype: string | null = gettraveltype();
      updateFormValues({ travelType: traveltype })

      const destination: string | null = getdestination();
      updateFormValues({ destination: destination })

    }
  }, [configured, prices]);

  const updateFormValues = useCallback(
    (update: AnyObject) => {
      setForm((f) => ({
        ...f,
        ...update,
      }));
    },
    [setForm]
  );

  const updateSlotsList = useCallback(
    (update: any) => {
      setSlotsList(update)
    },
    [setSlotsList]
  );

  const goToNextStep = useCallback(() => {
    setStep((s: number) => {
      if (s + 1 <= steps.length - 1) {
        return s + 1;
      }

      return s;
    });
  }, [setStep]);

  const goToPrevStep = useCallback(() => {
    setStep((s: number) => {
      if (s - 1 >= 0) {
        return s - 1;
      }

      return s;
    });
  }, [setStep]);

  const toggleChangeLocationModal = useCallback(
    (show) => setShowChangeLocationModal(show),
    [setShowChangeLocationModal]
  );

  const addReservationId = useCallback(
    (reservationId: string | null) => setReservationId(reservationId),
    [setReservationId]
  );

  const addReservationCount = useCallback(
    (reservationCount: number) => setReservationCount(reservationCount),
    [reservationCount]
  );

  const toggleShowAlertModal = useCallback(
    (show) => setShowAlertModal(show),
    [setShowAlertModal]
  );

  const toggleShowDepartureDateTimeModal = useCallback(
    (show) => setShowDepartureDateTimeModal(show),
    [setShowDepartureDateTimeModal]
  );

  const store = useMemo(
    () => ({
      form,
      step,
      showChangeLocationModal,
      showAlertModal,
      prices,
      configured,
      slotsList,
      showDepartureTime,
      reservationId,
      reservationCount
    }),
    [step, form, showChangeLocationModal, showAlertModal, prices, configured, slotsList, showDepartureTime, reservationId, reservationCount]
  );

  const dispatch = useMemo(
    () => ({
      goToNextStep,
      goToPrevStep,
      updateFormValues,
      toggleChangeLocationModal,
      toggleShowAlertModal,
      updateSlotsList,
      toggleShowDepartureDateTimeModal,
      addReservationId,
      addReservationCount
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <StepsContext.Provider value={store}>
      <StepsDispatchContext.Provider value={dispatch}>
        {children}
      </StepsDispatchContext.Provider>
    </StepsContext.Provider>
  );
};

export const useStepsState = () => {
  const context = React.useContext(StepsContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useStepsState` hook must be used within a `Provider` component'
    );
  }

  return context;
};

export const useStepsDispatch = () => {
  const context = React.useContext(StepsDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useStepsDispatch` hook must be used within a `Provider` component'
    );
  }

  return context;
};
