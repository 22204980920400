import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import Button from '../CustomButtons/Button.js';

import styles from '../../AppointmentList/headerStyle.js';
import { useHistory } from 'react-router';
// import { isValidEmail } from "../../../../../validations";

const useStyles = makeStyles(styles);

export default function Header(props) {
    const classes = useStyles();
    function makeBrand() {
        var name;
        props.routes.map((prop) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                name = props.rtlActive ? prop.rtlName : prop.name;
            }
            return null;
        });
        return name;
    }
    const { color } = props;
    const appBarClasses = classNames({
        [' ' + classes[color]]: color,
    });

    const [state, setstate] = useState({ email: '' });

    useEffect(() => {
        let userAccount = JSON.parse(
            window.localStorage.getItem('userDetails')
        );
        setstate(userAccount);
        if (userAccount?.email)
            window.localStorage.setItem('checkInPath', 'true');
        else window.localStorage.setItem('checkInPath', 'false');
    }, []);

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar  className={classes.container}>
                <div className={classes.flex}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </div>
                <div>{state.email}</div>

                {/* <Hidden implementation="css"> */}
                    <AdminNavbarLinks
                    // handleDrawerToggle={props.handleDrawerToggle}
                    />
                {/* </Hidden> */}

                {/* <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden> */}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
};
