import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../../../shared/components/styled';
import {
    CalendarIcon,
    ClockIcon,
    PinIcon,
    PersonIcon,
    TestingInfo,
    TestingInfoContainer,
    TestingInfoRow,
} from '../../../../shared/components/ConfirmAndPay/ConfirmAndPay';
import UpArrow from '../../../../../assets/up.png';
import DownArrow from '../../../../../assets/down.png';
import { colors } from '../../../../../styles/colors';
import { Button } from '../../../../shared/components/styled';
import { Breakpoints } from '../../../../../dictionaries';
import { downloadResultsPDF } from '../../../api';
import { config } from '../../../../../config';
import { getPeriodsFromSchedule } from '../../../../../utils';
import { isAfter, parse } from 'date-fns';
import { AdminManagePage } from '../dictionaries';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { toLower } from 'lodash';

const periods = getPeriodsFromSchedule(config.schedule);

const ConfirmationNumber = styled.p`
    display: block;
    text-align: center;
    margin: 10px 0 0;
`;

const ResultValue = styled.div`
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: center;
    color: ${({ value }: { value: string }) =>
        value === 'POSITIVE' ? colors.red : colors.green};
`;

const Disclaimer = styled.div`
    max-width: 540px;
    margin: 0 auto 55px;
`;

const Detail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    text-align: left;
    width: 100%;
    background: ${colors.blue8};
    margin: 2px;

    @media (min-width: ${Breakpoints.sm}px) {
        width: 49%;
    }

    strong {
        display: block;
    }
`;

const SaveAsPDFButton = styled(Button)`
    width: 100%;
    margin: 19px auto 25px;

    @media (min-width: ${Breakpoints.md}px) {
    }
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px 20px,
  max-width: 905px;
`;

const ListDetails = styled.div`
    margin: 0px 0px 30px;
    cursor: pointer;
`;

const TestingInfoWrapper = styled.div`
    margin: 40px 0 45px;
`;

const Navigation = styled.div`
    display: flex;
    margin: 0 -6px 50px 0px;

    & > * {
        margin: 0 6px;
    }
`;

const ListContainer = styled.div`
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    background: ${colors.blue8};
    position: relative;
    &:hover {
        border: 2px solid ${colors.blue};
    }
`;

const ListItem = styled.div`
    width: 20%;
    margin: 0px 20px 0px 20px;

    @media (max-width: ${Breakpoints.sm}px) {
        width: 80%;
    }
`;

const ArrowContainer = styled.div`
    // margin: 0px 20px 0px 20px;

    @media (min-width: ${Breakpoints.md}px) {
        // width: 80%;
        min-width: max-content;
    }
    img {
        margin-left: 0.4rem;
        filter: invert(1);
    }

    @media (max-width: ${Breakpoints.sm}px) {
        width: 100%;
        img {
            margin-left: 0.4rem;
            margin-right: 0.5rem;
            filter: invert(1);
            float: right;
        }
        strong {
            float: right;
            margin-left: 13rem;
            margin-top: 0.5rem;
        }
    }
`;

const UserInfoWrapper = styled.div`
    justify-content: center;
    margin: 0px 0px 40px;
    display: flex;
    flex-wrap: wrap;
`;

const UserInfoText = styled.p`
    margin: 0px 20px;
    font-size: 20px;

    @media (max-width: ${Breakpoints.sm}px) {
        width: 85%;
        font-size: 16px;
    }
`;

interface ButtonProps {
    selected: boolean;
}

const Tag = styled.div`
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    position: absolute;
    width: 137px;
    text-align: center;
    height: 28px;
    overflow: hidden;
    left: -3px;
    top: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 30px;
    color: ${({ selected }: ButtonProps) =>
        selected ? colors.black : colors.white};
    -webkit-transform: rotate(42.31deg);
    -ms-transform: rotate(42.31deg);
    -webkit-transform: rotate(42.31deg);
    background-color: ${({ selected }: ButtonProps) =>
        selected ? colors.white : colors.blue};
`;

const TagBox = styled.div`
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 1px;
    overflow: hidden;
`;

function normalizeResultName(result: string) {
    const res = result ? result.toLowerCase() : '';

    if (res === 'detected') {
        return 'POSITIVE';
    } else if (res === 'not detected') {
        return 'NEGATIVE';
    }

    return 'INDETERMINATE';
}

function checkAppointmentStatus(
    params: any,
    IsMinorAccount: any,
    minorEmail: any
) {
    let result: boolean = false;
    if (IsMinorAccount) {
        params?.qbenchAcknowledgement.map((e: any) => {
            if (e?.minorIndex !== -1) {
                let minors = e?.minors[e?.minorIndex];
                if (
                    toLower(minors?.email) === toLower(minorEmail) &&
                    e?.results !== ''
                ) {
                    result = true;
                }
            }
        });
        if (result) {
            return (
                <>
                    <strong>Status</strong>
                    <br />
                    <p
                        style={{
                            fontSize: '20px',
                            color: colors.green,
                            margin: '10px 0px 0px 0px',
                        }}
                    >
                        Completed
                    </p>
                </>
            );
        } else {
            return (
                <>
                    <strong>Status</strong>
                    <br />
                    <p
                        style={{
                            fontSize: '20px',
                            color: colors.orange,
                            margin: '10px 10px 0px 0px',
                        }}
                    >
                        Upcoming
                    </p>
                </>
            );
        }
    } else if (params.orderById == 3) {
        return (
            <>
                <strong>Status</strong>
                <br />
                <p
                    style={{
                        fontSize: '20px',
                        color: colors.green,
                        margin: '10px 0px 0px 0px',
                    }}
                >
                    Completed
                </p>
            </>
        );
    } else if (params.orderById == 5) {
        return (
            <>
                <strong>Status</strong>
                <br />
                <p
                    style={{
                        fontSize: '20px',
                        color: colors.orange,
                        margin: '10px 10px 0px 0px',
                    }}
                >
                    Upcoming
                </p>
            </>
        );
    } else if (params.orderById == 4) {
        return (
            <>
                <strong>Status</strong>
                <br />
                <p
                    style={{
                        fontSize: '20px',
                        color: colors.blueLight,
                        margin: '10px 10px 0px 0px',
                    }}
                >
                    In Progress
                </p>
            </>
        );
    }
}

const MangeResults: React.FC = () => {
    const { appointmentList, IsMinorAccount, minorEmail } = useAdminState();
    const { goToPage, updateAdminManageAppointment } = useAdminDispatch();

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hide, setHide] = useState({ index: -1, aIndex: -1 });

    return (
        <Container size="xl">
            <Content>
                {(appointmentList || []).map((appt: any, index: any) => {
                    return (
                        <div key={index}>
                            {IsMinorAccount ? (
                                ''
                            ) : (
                                <UserInfoWrapper>
                                    <UserInfoText>
                                        <strong>Name:</strong> {appt.firstName}{' '}
                                        {appt.appointments[0]!.lastName}
                                    </UserInfoText>
                                    <UserInfoText>
                                        <strong>Email:</strong>{' '}
                                        {appt.appointments[0]!.email}
                                    </UserInfoText>
                                    <UserInfoText>
                                        <strong>Phone:</strong>{' '}
                                        
                                        {appt.appointments[0]!.phone}
                                    </UserInfoText>
                                </UserInfoWrapper>
                            )}
                            {appt.appointments.map(
                                (apptData: any, aIndex: any) => {
                                    return (
                                        <>
                                            <ListDetails
                                                key={aIndex}
                                                onClick={() => {
                                                    setHide({
                                                        index: index,
                                                        aIndex:
                                                            hide.aIndex ==
                                                            aIndex
                                                                ? -1
                                                                : aIndex,
                                                    });
                                                }}
                                            >
                                                <ListContainer key={aIndex}>
                                                    <ListItem>
                                                        <strong>
                                                            Confirmation#
                                                        </strong>
                                                        <br />
                                                        {
                                                            apptData.confirmationId
                                                        }
                                                    </ListItem>
                                                    <ListItem>
                                                        <strong>
                                                            Appointment Date
                                                        </strong>
                                                        <br />
                                                        {apptData.date}
                                                    </ListItem>
                                                    <ListItem>
                                                        <strong>
                                                            Location
                                                        </strong>
                                                        <br />
                                                        {
                                                            apptData.location
                                                                .address1
                                                        }{' '}
                                                        <br />
                                                        {
                                                            apptData.location
                                                                .address2
                                                        }
                                                    </ListItem>
                                                    <ListItem
                                                        style={{ width: '15%' }}
                                                    >
                                                        {checkAppointmentStatus(
                                                            apptData,
                                                            IsMinorAccount,
                                                            minorEmail
                                                        )}
                                                    </ListItem>
                                                    <ArrowContainer>
                                                        <img
                                                            src={
                                                                hide.index ==
                                                                    index &&
                                                                hide.aIndex ==
                                                                    aIndex
                                                                    ? UpArrow
                                                                    : DownArrow
                                                            }
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                            }}
                                                        />

                                                        <br />
                                                        {hide.index == index &&
                                                        hide.aIndex ==
                                                            aIndex ? (
                                                            <strong>
                                                                Less
                                                            </strong>
                                                        ) : (
                                                            <strong>
                                                                More
                                                            </strong>
                                                        )}
                                                    </ArrowContainer>
                                                    {apptData?.isExpressSameDayTest && (
                                                        <TagBox>
                                                            <Tag
                                                                selected={false}
                                                            >
                                                                EXPRESS TEST
                                                            </Tag>
                                                        </TagBox>
                                                    )}
                                                    {apptData?.isRapidTest && (
                                                        <TagBox>
                                                            <Tag
                                                                selected={false}
                                                            >
                                                                RAPID TEST
                                                            </Tag>
                                                        </TagBox>
                                                    )}
                                                </ListContainer>
                                            </ListDetails>
                                            {hide.index == index &&
                                                hide.aIndex == aIndex &&
                                                apptData.qbenchAcknowledgement.map(
                                                    (
                                                        item: any,
                                                        sindex: any
                                                    ) => {
                                                        let minorcheck = true;
                                                        if (IsMinorAccount) {
                                                            let indexid =
                                                                item.minorIndex;
                                                            if (
                                                                indexid !== -1
                                                            ) {
                                                                minorcheck =
                                                                    toLower(
                                                                        item
                                                                            ?.minors[
                                                                            indexid
                                                                        ]
                                                                            ?.email ===
                                                                            undefined
                                                                            ? ''
                                                                            : item
                                                                                  ?.minors[
                                                                                  indexid
                                                                              ]
                                                                                  ?.email
                                                                    ) ===
                                                                    toLower(
                                                                        minorEmail
                                                                            ? minorEmail
                                                                            : ''
                                                                    );
                                                            } else {
                                                                minorcheck = false;
                                                            }
                                                        }
                                                        if (
                                                            item.results !==
                                                                '' &&
                                                            (apptData.orderById ==
                                                                3 ||
                                                                apptData.orderById ==
                                                                    4) &&
                                                            minorcheck
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={sindex}
                                                                >
                                                                    <PageHeader>
                                                                        <PageTitle textAlign="center">
                                                                            Your
                                                                            COVID-19
                                                                            Test
                                                                            Result
                                                                        </PageTitle>
                                                                        <ConfirmationNumber>
                                                                            Order
                                                                            ID:{' '}
                                                                            {
                                                                                apptData.confirmationId
                                                                            }
                                                                        </ConfirmationNumber>
                                                                    </PageHeader>
                                                                    <ResultValue
                                                                        value={normalizeResultName(
                                                                            item.results
                                                                        )}
                                                                    >
                                                                        {normalizeResultName(
                                                                            item.results
                                                                        )}
                                                                    </ResultValue>
                                                                    <Disclaimer>
                                                                        A
                                                                        negative
                                                                        result
                                                                        for this
                                                                        test
                                                                        means
                                                                        that
                                                                        SARS-CoV-2
                                                                        RNA (the
                                                                        cause of
                                                                        COVID-19)
                                                                        was not
                                                                        detected
                                                                        in the
                                                                        collected
                                                                        sample.
                                                                    </Disclaimer>
                                                                    <Details
                                                                        key={
                                                                            sindex
                                                                        }
                                                                    >
                                                                        <Detail>
                                                                            <strong>
                                                                                Patient’s
                                                                                Name
                                                                            </strong>
                                                                            {
                                                                                apptData
                                                                                    .qbenchAcknowledgement[
                                                                                    sindex
                                                                                ]
                                                                                    .firstName
                                                                            }{' '}
                                                                            {
                                                                                apptData
                                                                                    .qbenchAcknowledgement[
                                                                                    sindex
                                                                                ]
                                                                                    .lastName
                                                                            }
                                                                        </Detail>
                                                                        <Detail>
                                                                            <strong>
                                                                                Patient’s
                                                                                Date
                                                                                of
                                                                                Birth
                                                                            </strong>
                                                                            {apptData
                                                                                .qbenchAcknowledgement[
                                                                                sindex
                                                                            ]
                                                                                .birthDate !==
                                                                                undefined &&
                                                                            Object.keys(
                                                                                apptData
                                                                                    .qbenchAcknowledgement[
                                                                                    sindex
                                                                                ]
                                                                                    .birthDate
                                                                            )
                                                                                .length >
                                                                                0
                                                                                ? apptData
                                                                                      .qbenchAcknowledgement[
                                                                                      sindex
                                                                                  ]
                                                                                      .birthDate
                                                                                : ''}
                                                                        </Detail>
                                                                        <Detail>
                                                                            <strong>
                                                                                Collection
                                                                                Date
                                                                            </strong>
                                                                            {
                                                                                apptData.date
                                                                            }
                                                                        </Detail>
                                                                        {apptData.location && (
                                                                            <Detail>
                                                                                <strong>
                                                                                    Collection
                                                                                    Location
                                                                                </strong>
                                                                                {
                                                                                    apptData
                                                                                        .location
                                                                                        .address1
                                                                                }{' '}
                                                                                {
                                                                                    apptData
                                                                                        .location
                                                                                        .address2
                                                                                }
                                                                            </Detail>
                                                                        )}
                                                                    </Details>
                                                                    <SaveAsPDFButton
                                                                        type="button"
                                                                        libraryType="primary"
                                                                        onClick={async () => {
                                                                            setSaving(
                                                                                true
                                                                            );
                                                                            try {
                                                                                await downloadResultsPDF(
                                                                                    item.sampleId
                                                                                );
                                                                            } catch (e) {
                                                                                console.error(
                                                                                    e
                                                                                );
                                                                            }
                                                                            setSaving(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {saving
                                                                            ? 'Saving...'
                                                                            : 'Save as PDF'}
                                                                    </SaveAsPDFButton>
                                                                    <p
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                            margin:
                                                                                '0px 0px 35px 0px',
                                                                        }}
                                                                    >
                                                                        Download
                                                                        as PDF
                                                                        now to
                                                                        save a
                                                                        copy of
                                                                        your
                                                                        test
                                                                        result.
                                                                    </p>
                                                                </div>
                                                            );
                                                        } else if (
                                                            item.results ===
                                                                '' &&
                                                            minorcheck
                                                        ) {
                                                            let data: any = [];

                                                            if (
                                                                apptData
                                                                    ?.qbenchAcknowledgement
                                                                    .length >
                                                                    0 &&
                                                                apptData?.minors
                                                                    ?.length >
                                                                    0 &&
                                                                apptData.orderById !=
                                                                    5
                                                            ) {
                                                                apptData?.qbenchAcknowledgement?.map(
                                                                    (
                                                                        item: any,
                                                                        i: any
                                                                    ) => {
                                                                        if (
                                                                            item.results ===
                                                                                '' &&
                                                                            i ===
                                                                                sindex
                                                                        ) {
                                                                            data.push(
                                                                                {
                                                                                    firstName:
                                                                                        item.firstName,
                                                                                    lastName:
                                                                                        item.lastName,
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            } else {
                                                                data.push({
                                                                    firstName:
                                                                        apptData.firstName,
                                                                    lastName:
                                                                        apptData.lastName,
                                                                });
                                                            }
                                                            console.log(
                                                                'sindex',
                                                                sindex,
                                                                apptData
                                                            );
                                                            return (
                                                                <div
                                                                    key={sindex}
                                                                    style={{
                                                                        display: IsMinorAccount
                                                                            ? 'block'
                                                                            : apptData.orderById ==
                                                                                  2 ||
                                                                              apptData.orderById ==
                                                                                  4
                                                                            ? 'block'
                                                                            : apptData.orderById ==
                                                                                  5 &&
                                                                              sindex ==
                                                                                  0
                                                                            ? 'block'
                                                                            : 'none',
                                                                    }}
                                                                >
                                                                    {data.length >
                                                                        0 &&
                                                                    item.results ===
                                                                        ''
                                                                        ? data.map(
                                                                              (
                                                                                  item: any,
                                                                                  i: any
                                                                              ) => (
                                                                                  <TestingInfoWrapper>
                                                                                      <TestingInfoContainer>
                                                                                          <TestingInfo>
                                                                                              <PinIcon />
                                                                                              <strong>
                                                                                                  {
                                                                                                      apptData
                                                                                                          ?.location
                                                                                                          ?.name
                                                                                                  }
                                                                                              </strong>
                                                                                              <br />
                                                                                              {
                                                                                                  apptData
                                                                                                      ?.location
                                                                                                      ?.address1
                                                                                              }{' '}
                                                                                              {
                                                                                                  apptData
                                                                                                      ?.location
                                                                                                      ?.address2
                                                                                              }
                                                                                          </TestingInfo>
                                                                                      </TestingInfoContainer>
                                                                                      <TestingInfoContainer>
                                                                                          <TestingInfoRow>
                                                                                              <TestingInfo>
                                                                                                  <CalendarIcon />
                                                                                                  <strong>
                                                                                                      Date
                                                                                                  </strong>
                                                                                                  <br />
                                                                                                  {
                                                                                                      apptData?.date
                                                                                                  }
                                                                                              </TestingInfo>
                                                                                              <TestingInfo>
                                                                                                  <ClockIcon />
                                                                                                  <strong>
                                                                                                      Time
                                                                                                  </strong>
                                                                                                  <br />
                                                                                                  {apptData?.slot &&
                                                                                                      (apptData
                                                                                                          ?.slot
                                                                                                          .label ||
                                                                                                          periods[
                                                                                                              apptData
                                                                                                                  ?.slot
                                                                                                                  .period
                                                                                                          ]
                                                                                                              .label)}
                                                                                              </TestingInfo>
                                                                                          </TestingInfoRow>
                                                                                      </TestingInfoContainer>
                                                                                      <TestingInfoContainer>
                                                                                          <TestingInfoRow>
                                                                                              <TestingInfo>
                                                                                                  <PersonIcon />
                                                                                                  <strong>
                                                                                                      Appointment
                                                                                                      for
                                                                                                  </strong>
                                                                                                  <br />
                                                                                                  {IsMinorAccount ? (
                                                                                                      ''
                                                                                                  ) : (
                                                                                                      <>
                                                                                                          {
                                                                                                              item?.firstName
                                                                                                          }{' '}
                                                                                                          {
                                                                                                              item?.lastName
                                                                                                          }
                                                                                                      </>
                                                                                                  )}
                                                                                                  {apptData &&
                                                                                                  apptData
                                                                                                      ?.minors
                                                                                                      ?.length >
                                                                                                      0
                                                                                                      ? apptData?.minors?.filter((minor:any)=>!minor.isCanceled).map(
                                                                                                            (
                                                                                                                items: any,
                                                                                                                i: any
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    key={
                                                                                                                        i
                                                                                                                    }
                                                                                                                >
                                                                                                                    {IsMinorAccount ? (
                                                                                                                        toLower(
                                                                                                                            minorEmail
                                                                                                                        ) ===
                                                                                                                        toLower(
                                                                                                                            items.email
                                                                                                                        ) ? (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    items.firstName
                                                                                                                                }{' '}
                                                                                                                                {
                                                                                                                                    items.lastName
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            ''
                                                                                                                        )
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                items.firstName
                                                                                                                            }{' '}
                                                                                                                            {
                                                                                                                                items.lastName
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )
                                                                                                        )
                                                                                                      : ''}
                                                                                              </TestingInfo>
                                                                                          </TestingInfoRow>
                                                                                      </TestingInfoContainer>
                                                                                  </TestingInfoWrapper>
                                                                              )
                                                                          )
                                                                        : ''}
                                                                    {IsMinorAccount
                                                                        ? ''
                                                                        : apptData.orderById !==
                                                                              4 && (!apptData.isSolvHealth && !apptData.isReelHealth)
                                                                                &&  (
                                                                              <Navigation>
                                                                                  {apptData?.date &&
                                                                                      isAfter(
                                                                                          parse(
                                                                                              apptData?.date,
                                                                                              config.dateFormat,
                                                                                              new Date()
                                                                                          ),
                                                                                          new Date()
                                                                                      ) && (
                                                                                          <Button
                                                                                              type="button"
                                                                                              libraryType="default"
                                                                                              onClick={() => {
                                                                                                  let appointment = apptData;
                                                                                                  updateAdminManageAppointment(
                                                                                                      appointment
                                                                                                  );
                                                                                                  goToPage(
                                                                                                      AdminManagePage.ManageCancel
                                                                                                  );
                                                                                              }}
                                                                                          >
                                                                                              Cancel
                                                                                              Appointment
                                                                                          </Button>
                                                                                      )}
                                                                                  <Button
                                                                                      disabled={
                                                                                          loading
                                                                                      }
                                                                                      type="button"
                                                                                      libraryType="primary"
                                                                                      onClick={async () => {
                                                                                          setLoading(
                                                                                              true
                                                                                          );
                                                                                          let appointment = apptData;
                                                                                          updateAdminManageAppointment(
                                                                                              appointment
                                                                                          );
                                                                                          // const data: any = await getSlotsByLocationId(apptData?.location?.qbenchCustomerId);
                                                                                          // updateRescheduleSlots(data);
                                                                                          setLoading(
                                                                                              false
                                                                                          );
                                                                                          goToPage(
                                                                                              AdminManagePage.ManageReschedule
                                                                                          );
                                                                                      }}
                                                                                  >
                                                                                      {loading
                                                                                          ? 'Processing...'
                                                                                          : 'Reschedule Appointment'}
                                                                                  </Button>
                                                                              </Navigation>
                                                                          )}
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                )}
                                        </>
                                    );
                                }
                            )}
                        </div>
                    );
                })}
            </Content>
        </Container>
    );
};

export default MangeResults;
