import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { Button } from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import { Breakpoints } from '../../../../../dictionaries';

import Modal from '../../../../shared/components/Modal';
import LocationButtonGroup from '../../../../shared/components/form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import { useAdminDispatch, useAdminState } from '../../../provider';
// import { useManageDispatch, useManageState } from '../provider';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const ManageLocationModal: React.FC = () => {

    const { manageAppointment, showAdminLocationModal } = useAdminState();
    const { updateAdminManageAppointment, toggleAdminLocationModal } = useAdminDispatch();
    const Location = manageAppointment?.location
    const closeModal = () => toggleAdminLocationModal(false);
    const rollbackLocation = useMemo(() => cloneDeep(Location), [showAdminLocationModal]);
    const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(manageAppointment?.isExpressSameDayTest), [showAdminLocationModal]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(manageAppointment?.isRapidTest), [showAdminLocationModal]);

    return (
        <Modal open={showAdminLocationModal} onClose={closeModal}>
            <Formik
                initialValues={{ Location }}
                onSubmit={() => {
                    //   updateFormValues({ update: location });
                    //   updateFormValues({ update: slot });
                    // updateAppointment({ update: Location })
                    // if (appointment?.location?.hasVipSlots) {
                    //     updateFormValues({ isExpressSameDayTest: true });
                    // }
                    // else {
                    //     updateFormValues({ isExpressSameDayTest: false });
                    // }
                    closeModal();
                }}
            >
                {() => (
                    <Form>
                        <Content>
                            <Title>Change location</Title>
                            <LocationButtonGroup isManage={true} />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        
                                        let update: any={
                                            location: rollbackLocation, 
                                            isExpressSameDayTest: rollbackisExpressSameDayTest,
                                            isRapidTest: rollbackisRapidTest
                                        }
                                        updateAdminManageAppointment({
                                            ...manageAppointment,
                                            ...update
                                        });
                                        closeModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button libraryType="primary" size="sm" type="submit">
                                    Change
                                </Button>
                            </ButtonsContainer>
                        </Content>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ManageLocationModal;
