import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, DatePicker, Row, Select, Skeleton, Statistic } from 'antd'
import Title from 'antd/lib/typography/Title'
import { format } from 'date-fns';
import React, { useState } from 'react'
const { Option } = Select;

interface IProps {
    title:any
    onchange: any;
    color: string;
    date: string;
    totalAmount: any;
    compareTotal: any;
    selectText: string;
    onSearch?: any;
    report?: any
    isInsurance?:any
    comparedAndPrevious?:any
}
const StripebarChart = ({
    title,
    onchange,
    color,
    date,
    totalAmount,
    compareTotal,
    selectText,
    onSearch,
    report,
    isInsurance,
    comparedAndPrevious,
}: IProps) => {
    const rangeStyle:any = {width: '70%',fontSize:'14px',background:'white',padding:'4px',boxShadow:'0 3px 3px 0 rgba(0,0,0,0.1)',textAlign:'center',borderRadius:'10px'}
    const differenceStyle:any = {fontSize:'14px',marginLeft:'10px'}
    let compareStartDate: any;
    let compareEndDate: any;
    let previousStartDate: any;
    let previousEndDate: any;
    if(report === true){
        const { startDate, endDate } = comparedAndPrevious;
        compareStartDate = isInsurance ? startDate.forInsurance : startDate.forNoInsurance
        compareEndDate = isInsurance ? endDate.forInsurance : endDate.forNoInsurance
        previousStartDate = isInsurance ? startDate.forPreviousInsurance : startDate.forPreviousNoInsurance
        previousEndDate = isInsurance ? endDate.forPreviousInsurance : endDate.forPreviousNoInsurance
    }
    const [range, setRange] = useState<any>('days')
    const onChangeForReport = (value: string) => {
        if(value === 'yearToDate'){
            setRange('years')
        }
        else setRange('days')
        return onchange(value, isInsurance);
    };
    const formatAmount = (amount: any) => {
        return '$ ' + new Intl.NumberFormat('en-US').format(amount);
    };
    const formatToMonth =(date:any)=>{
        return format(new Date(date), range === 'years' ? 'LLL yyyy' : 'LLL dd');
    }
    function getDifference(a:any, b:any) {
        return '(' + formatAmount(Math.abs(a - b)) + ')';
    }
    return (
        <div>
            <div>
                <p style={{ textAlign: 'center',fontWeight:'bold',textTransform:'uppercase' }}>{title}</p>
                <br />
                <Row gutter={24}>
                    <Col span={12}>
                        <Card style={{ borderRadius: '20px' }}>
                            <Statistic
                                title={report ? 'Selected' : 'Today'}
                                value={formatAmount(totalAmount) || 0}
                                prefix={
                                    totalAmount < compareTotal ? (
                                        <ArrowDownOutlined />
                                    ) : (
                                        <ArrowUpOutlined />
                                    )
                                }
                                valueStyle={{ color }}
                                suffix={<div style={{...differenceStyle,color: totalAmount < compareTotal ? 'red' : 'green'}}>
                                    {getDifference(totalAmount,compareTotal)}
                                </div> }
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{ borderRadius: '20px' }}>
                            <Statistic
                                title={report ? 'Previous' : date}
                                value={formatAmount(compareTotal) || 0}
                                valueStyle={{
                                    color:
                                        totalAmount > compareTotal
                                            ? '#cf1322'
                                            : '#3f8600',
                                }}
                                suffix=""
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <br />
            <Row gutter={24}>
                <Col  span={12}>
                    <>{selectText}</>
                </Col >
                { report ?
                    <Col span={12}>
                            <Select
                            style={{width:'70%'}}
                                showSearch
                                placeholder="Select a range"
                                onChange={onChangeForReport}
                                onSearch={onSearch}
                                defaultValue="7days"
                            >
                                <Option value="7days">Last 7 days</Option>
                                <Option value="4weeks">Last 4 weeks</Option>
                                {/* <Option value="12months">Last 12 months</Option> */}
                                <Option value="monthToDate">
                                    Month to date
                                </Option>
                                {/* <Option value="quarter">Quarter to date</Option> */}
                                <Option value="yearToDate">Year to date</Option>
                            </Select>
                        </Col>
                :
                <Col span={12}>
                        <DatePicker onChange={onchange} />
                    </Col>
                }
                
            </Row>
            <br/>
            {report ? (
                    <Row gutter={24}>
                        <Col span={12}>
                            <>Current period</>
                        </Col>
                        <Col span={12}>
                            <>Previous period</>
                        </Col>
                    </Row>
                ):'' }
            {report && (
                <>
               
                    <Row style={{ marginTop: '8px' }} gutter={24}>
                        <Col span={12}>
                            <div style={{ ...rangeStyle }}>
                                {formatToMonth(compareStartDate) +' - '+ formatToMonth(compareEndDate)}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ ...rangeStyle }}>
                                {formatToMonth(previousStartDate) +' - ' + formatToMonth(previousEndDate)}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default StripebarChart