import React, { useEffect, useState } from 'react';
import moment from 'moment';

type Props = {
    checkInExpTime: string;
    result: string;
};
const CheckinTimeLeft: React.FC<Props> = ({ checkInExpTime, result }) => {
    const calculateTimeLeft = (checkInExpTime: string) => {
        let timeLeftInSec = moment( moment.utc(checkInExpTime)).diff(
           new Date().toUTCString(),
            'seconds'
        ); 
        if(result){
            return `completed`;
        }
       else if (timeLeftInSec <= 0) {
            return `Time's up`;
        } else {
            let hours = Math.floor(timeLeftInSec / 3600);
            timeLeftInSec %= 3600;
            let minutes = Math.floor(timeLeftInSec / 60);
            let seconds = timeLeftInSec % 60;

            // If you want strings with leading zeroes:
            let min = String(minutes).padStart(2, '0');
            let hr = String(hours).padStart(2, '0');
            let sec = String(seconds).padStart(2, '0');
            return hr + ':' + min + ':' + sec;
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(checkInExpTime));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(checkInExpTime));
        }, 1000);
        return () => clearInterval(timer);
    });

    return <span>{timeLeft}</span>;
};

export default CheckinTimeLeft;
