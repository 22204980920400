import { Col, Row } from 'antd';
import React from 'react'

const AmountDetails = ({amount,report}:any) => {
    const formatAmount = (amount: any) => {
        return '$ ' + new Intl.NumberFormat('en-US').format(amount);
    };
    const amountStyle={display:'flex' ,color:'blueviolet',fontSize:'14px'}
  return (
    <>
    <br/>

     {<Row gutter={24} style={{fontSize:'14px',color:'blueviolet'}}>
        <Col span={12}>
           {amount.isInsurance ? <>
                    <span style={{'color':'grey'}}>Insurance:</span> {formatAmount(amount.todayInsurance) || 0}
                    <br/>
                    <span style={{'color':'grey'}}>Credit Card:</span> {formatAmount(amount.todayStripe) || 0} 
            </> :
            <>
             <span style={{'color':'grey'}}>Uninsured:</span> {formatAmount(amount.todayNoInsurance) || 0}
            </>
            }
                    </Col>
                    <Col span={12}>
                  { amount.isInsurance  ?   <>
                    <span style={{'color':'grey'}}>Insurance:</span> {formatAmount(amount.comparedInsurance) || 0}
                    <br/>
                    <span style={{'color':'grey'}}>Credit Card:</span> {formatAmount(amount.comparedStripe) || 0} 
                        </>
                        : 
                        <>
                        <span style={{'color':'grey'}}>Uninsured:</span> {formatAmount(amount.comparedNoInsurance) || 0}
                        </>
                        }
                    </Col>
        </Row>}
        <br/>
    </>
  )
}

export default AmountDetails