import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import {  AdminProvider } from '../../provider';
import Locationstep from '../LocationAndService/Locationstep'
function LocationWrapper(){


    useEffect(() => {
        mixpanel.track('Page View');
    }, []);

    return(
        <AdminProvider>
            <Locationstep/>
        </AdminProvider>
                            )
                        }

export default LocationWrapper;