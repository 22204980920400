import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
// import { Bar } from 'react-chartjs-2';
const Bar = require('react-chartjs-2').Bar;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Credit Card',
//             data: [1000, 10000],
//             backgroundColor: '#6495ED',
//         },
//         {
//             label: 'Insurance',
//             data: [5000, 20000],
//             backgroundColor: '#77dd77',
//         },
//     ],
// };
interface IProps {
    chartFor: any;
    compareWith: any;
    insuranceData?: any;
    stripeData?: any;
    noInsuranceData?:any;
    uninsured?: boolean;
}

export function StripeChart({chartFor,compareWith,insuranceData,stripeData,noInsuranceData,uninsured}:IProps) {
    const labels = chartFor === 'range' ? [compareWith] : ['Today', compareWith];
    let creditCardAmount:any = (chartFor === 'range' ? (stripeData?.endRangeAmount) : stripeData?.comparedAmount )|| 0
    let insuranceAmount:any = (chartFor === 'range' ? insuranceData?.endRangeAmount : insuranceData?.comparedAmount ) || 0
    let noInsuranceAmount:any = (chartFor === 'range' ? noInsuranceData?.endRangeAmount : noInsuranceData?.comparedAmount ) || 0
    
    const data = {
        labels,
        datasets: !uninsured ? [
            {
                label: 'Credit Card $',
                data: chartFor === 'range' ? [creditCardAmount] : [stripeData?.todayAmount || 0, creditCardAmount || 0],
                backgroundColor: '#6495ED',
            },
            {
                label: 'Insurance $',
                data: chartFor === 'range' ? [insuranceAmount] : [insuranceData?.todayAmount || 0, insuranceAmount || 0],
                backgroundColor: '#77dd77',
            },
        ] :[
            {
                label: 'UnInsured $',
                data: chartFor === 'range' ? [noInsuranceAmount] : [noInsuranceData?.todayAmount || 0, noInsuranceAmount || 0],
                backgroundColor: '#B5E5CF',
            },
        ],
    };
    return (
        <div style={{width:'90%',margin:'auto'}}>
            <Bar options={options} data={data}
            />
        </div>
    );
}
