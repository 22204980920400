import * as React from 'react';
import { Formik, Form, } from 'formik';
import { Container, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import Select from '../../../shared/components/form/Select';
import { stylesForListing } from './styles';
import { useAdminState, useAdminDispatch } from './../../provider'
import { slotstartdatefor30Value, slotdurationValue, slotcapacity,   editSlotConfigurationDummy } from './Model';
import styled from 'styled-components';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { isEqual, differenceWith, cloneDeep} from 'lodash';
import { getNewSlots, getSlotsConfigurationss } from './../../../admin/api';
import ConfirmationModal from './ConfirmationModal';
import { setContext } from '@sentry/react';
import { Radio } from 'antd';


const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
gap: 20%;
};
@media (min-width: 450px) { flex-direction: row; }
`;
const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;

const Returnbtn = styled.button`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:510px;
`;

const ErrorText = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  color: red;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
`;

type IEditLocationPage = {
	isNewLocation?: boolean,
	onBack?: () => void, 
    onNext?: () => void,
	slotsHandle?: any,
	counter?: number,
	testTypeDropdownNew?:any,
	defaultTest?: any,
}


const testTypeDropdownData ={
	standard: {
		label:'Standard Test',
		value:'standard',
	},
	express :{
		label:'Express Test',
		value:'express',
	},
	rapid : {
		label:'Rapid Test',
		value:'rapid',
	},
	antigen : {
		label:'Antigen Test',
		value:'antigen',
	},
  };
var slotNewDetails: any = [];	
const Editlocation: React.FC<IEditLocationPage> = ({isNewLocation, onBack, onNext, slotsHandle, counter, testTypeDropdownNew, defaultTest}) =>{
  const {editLocation, locationData } = useAdminState();
  const {  goToNextStep,goToPrevStep, updateLocation } = useAdminDispatch();
  const [isNp, setIsNp] = React.useState(false)
  const [isDropdown, setIsDropdown] = React.useState(false)
  const [btnText, setBtnText] = React.useState<string>('Save');

  console.log("Final Data", locationData)

  slotNewDetails = locationData.slotNewDetails
  const [loader, setloader] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [tstErr, setTstErr] = React.useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
//   const [testTypeDropdown, setTestTypeDropDown ] = React.useState<any>(null)
//   const [defaultTest, setDefaultTest] = React.useState<any>(null)
	
	const [testTypedata, setTestTypeData ]= React.useState<any>([])
	const [myTest, setMyTest] = React.useState<any>(isNewLocation ? (!!testTypeDropdownNew ? testTypeDropdownNew[0]?.value : '') : testTypedata[0]?.value);
	const [validateData, setValidateData] = React.useState<any>([
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 0,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 1,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 2,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 3,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 4,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 5,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 6,
	  isDone:false
	}
	]);
	const [data, setData] = React.useState<any>([
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 0,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 1,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 2,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 3,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 4,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 5,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 6,
	  isDone:false
	}
	]);

	const [resetData,] = React.useState<any>([
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 0,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 1,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 2,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 3,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 4,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 5,
		  isDone:false
		},
		{
		  startTime:'' ,
		  endTime:'' ,
		  periodDuration: '',
		  available: 24,
		  day: 6,
		  isDone:false
		}
		]);
	const errTxt = 'Please Select Test type'
	// React.useEffect(()=>{
	// 	if(isNewLocation){
	// 	let standardData = locationData.data.standardTest ? testTypeDropdownData.standard : null;
	// 	let expressData = locationData.data.expressTest ? testTypeDropdownData.express : null;
	// 	let rapidData = locationData.data.rapidTest ? testTypeDropdownData.rapid : null;
	// 	let antigenData = locationData.data.isAntigen ? testTypeDropdownData.antigen : null;
	// 	let data: any = []
	// 	data.push(standardData, expressData, rapidData, antigenData)
	// 	let filteredData = data.filter((data: any) => data !== null)
	// 	setTestTypeDropDown(filteredData)
	// 	console.log(filteredData)
	// 	}
	// },[locationData])
    const [countData,setcountData]=React.useState(0)

	React.useEffect(()=>{
		if(isNewLocation){
			
			setData(slotsHandle)
			if(!!defaultTest){
			setMyTest(defaultTest[0].value)
			}
			// setDefaultTest(() => {
			// 	let res = testTypedata.filter(
			// 		(item: any) => item.value === locationData?.slotsList?.testType
			// 	);
			// 	return res;
			// });
		}
	},[])
	React.useEffect(()=>{
		if(loading === true && !isNewLocation){
			setBtnText('Processing...')
		}
	},[loading])

	React.useEffect(()=>{
		if(!isNewLocation) {
			let standardData = editLocation.standardTest ? testTypeDropdownData.standard : null;
			let expressData = editLocation.isExpressSameDay ? testTypeDropdownData.express : null;
			let rapidData = editLocation.rapidTest ? testTypeDropdownData.rapid : null;
			let antigenData = editLocation.isAntigen ? testTypeDropdownData.antigen : null;
			let data: any = []
				data.push(standardData, expressData, rapidData, antigenData)
				let filteredData = data.filter((data: any) => data !== null)
				setMyTest(filteredData[0].value)
				setTestTypeData(filteredData)
				setIsDropdown(true)
		}
	},[])
	React.useEffect(()=>{
		setTstErr(false)
		if(isNewLocation){
			setData(resetData)
		}
	},[myTest])

	const calculateTime = (time: any) => {
		let timecal = time.split(':')
		let meridian = time.charAt(time.length - 1)
		let minutes = meridian === 'a' ? ((parseInt(timecal[0]) * 60) + parseInt(timecal[1])) : (((parseInt(timecal[0]) + 12) * 60) + parseInt(timecal[1]))
		return minutes;
	}

	const handleSlots=()=>{
		setloader(true)
		let slotDetail :any = []
		if (myTest === 'standard') {
			let stdFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'standard')
			slotDetail =  stdFilter[0]?.slotDetails ? stdFilter[0]?.slotDetails : [null]
		} else if (myTest === 'express') {
			let expFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'express')
			slotDetail = expFilter[0]?.slotDetails ? expFilter[0]?.slotDetails : [null]
		} else if (myTest === 'rapid') {
			let rapFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'rapid')
			slotDetail = rapFilter[0]?.slotDetails ? rapFilter[0]?.slotDetails : [null]
		} else if (myTest === 'antigen') {
			let antFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'antigen')
			slotDetail = antFilter[0]?.slotDetails ? antFilter[0]?.slotDetails : [null]
		}
  	console.log('slotdetials addloc',slotDetail )
  	let slotList = [];
  	for(let i=0;i<=6;i++) {
		let start = slotDetail[i]?.startTime
		let end = slotDetail[i]?.endTime
		let avl = slotDetail[i]?.available
		let period = slotDetail[i]?.periodDuration
	
		slotList.push({
		  startTime: start && start!==undefined && start!==null ? start : '',
		  endTime:  end && end!==undefined && end!==null ? end : '',
		  periodDuration: period && period!==undefined && period!==null ? period : '',
		  available:   avl && avl!==undefined && avl!==null ? avl : 0,
		  day: i,
		  isDone:start && start!==undefined && start!==null ? true : false
		});
		setData(slotList);
		setValidateData(slotList)
		setloader(false)
}
	}

	const getSlotsData = async () => {
		setloader(true)
		let datas: any = {
			testType: myTest,
			locationId: editLocation?.qbenchCustomerId,
			cPartnerID: editLocation?.cPartnerID 
		}
		// const slotsDetail: any = await getSlotsConfigurationss(datas)
		const slotsDetail: any = await getNewSlots(datas)
		let slotList = [];
        let count: any = 0;
		console.log('editloc slotdetials',slotsDetail )
		for(let i=0;i<=6;i++) {
			let len = slotsDetail?.data[0]?.slotsByDay[i]?.length
			let start = slotsDetail?.data[0]?.slotsByDay[i][0]?.startTime
			let end = slotsDetail?.data[0]?.slotsByDay[i][len - 1]?.endTime
			let avl = slotsDetail?.data[0]?.slotsByDay[i][0]?.available
			let period = slotsDetail?.data[0]?.slotsByDay[i][0]?.periodDuration
            console.log('check1',start);
		
			slotList.push({
				startTime: start && start!==undefined && start!==null ? start : '',
				endTime:  end && end!==undefined && end!==null ? end : '',
				periodDuration: period && period!==undefined && period!==null ? period : '',
				available:   avl && avl!==undefined && avl!==null ? avl : 0,
				day: i,
				isDone:start && start!==undefined && start!==null ? true : false
			});

            if(start && start!==undefined && start!==null) count++
            
            setcountData(count)
                
		}
		console.log('slotList',slotList);
		setData(slotList);
		setValidateData(slotList);
        setIsNp(editLocation?.isNpDestination || false);
		setloader(false);
  	}

	  const addSlots=(slotsData : any, myTest: string, locationId : number)=>{
		  
		let slotsList = {
			locationId : locationData.data.qbenchCustomerId,
			slotDetails: slotsData,
			testType: myTest
	}
	console.log('slots', slotsList)
	let slotDetail :any = []
		if (myTest === 'standard') {
			let stdFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'standard')
			slotDetail = stdFilter[0]?.slotDetails ? stdFilter[0]?.slotDetails : []
		} else if (myTest === 'express') {
			let expFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'express')
			slotDetail = expFilter[0]?.slotDetails ? expFilter[0]?.slotDetails : []
		} else if (myTest === 'rapid') {
			let rapFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'rapid')
			slotDetail = rapFilter[0]?.slotDetails ? rapFilter[0]?.slotDetails : []
		} else if (myTest === 'antigen') {
			let antFilter = slotNewDetails.filter((obj: any)=>obj.testType === 'antigen')
			slotDetail = antFilter[0]?.slotDetails ? antFilter[0]?.slotDetails : []
		}
		console.log('slotDetails', slotsList)
		
		let slotCheck = false; 
		slotsList.slotDetails.map((slot: any)=>{
			if(slot.isDone){
				slotCheck=true
			}
		})
		if(slotCheck || locationData.slotNewDetails.length!==0){
		if(!slotDetail.length) {
			slotNewDetails.push(slotsList)
		} else {
			let index = slotNewDetails.findIndex((obj: any) => obj.testType === myTest)
			slotNewDetails[index] = slotsList 
		}
	}
	console.log('slotNewDetails', slotNewDetails)
	setBtnText('Next')
}
	const handleCheckSave = () => {
			let dataVal: any =[]
			var dif = differenceWith(data, validateData, isEqual)
			dif = dif?.map((datas: any)=>{
				if(datas?.startTime.length){
					dataVal?.push(datas?.startTime)
				}
				if(datas?.endTime.length){
					dataVal?.push(datas?.endTime)
				}
				if(datas?.periodDuration.length) {
					dataVal?.push(datas?.periodDuration)
				}
				if(datas?.available !== 0) {
					dataVal?.push(datas?.periodDuration)
				}
			})
			if(dataVal?.length !==0 && btnText === 'Save'){
				setOpenConfirmationModal(true)
				setTitle('Please save you changes before changing the Testtype')
			}
	}
	  const nextHandler = (): void => {
        updateLocation({...locationData, slotNewDetails})
		if(onNext) onNext();
    }

	const backHandler = (): void => {
		if(onBack) onBack();
	}

	React.useEffect(()=>{
		setBtnText('Save')
		if(isNewLocation && myTest){
			setError('')
			handleSlots()
	}
	},[myTest])

	React.useEffect(()=>{
		setBtnText('Save')
		if(editLocation?.qbenchCustomerId && myTest )
		getSlotsData()
	},[editLocation, myTest])

  	const classes = stylesForListing();

	const handleCheckbox = (event: any, index: number, isChecked: boolean) => {
		let clonedSlotData = cloneDeep(data);

		if(!!clonedSlotData?.length){
			clonedSlotData[index]['startTime'] = event.startTime ? event.startTime : '';
			clonedSlotData[index]['endTime'] = event.endTime ? event.endTime : '';
			clonedSlotData[index]['periodDuration'] = event.periodDuration ? event.periodDuration  : '' ;
			clonedSlotData[index]['available'] = event.available ? event.available : 0
			clonedSlotData[index]['day'] =  index
			clonedSlotData[index]['isDone'] =  isChecked
		}
		setData(clonedSlotData)
	}	
  
	return (
		<Container maxWidth='md' style={{paddingLeft: '0', marginLeft: isNewLocation ? '-50px': ''}}>
			<Grid container>
				<Grid xl={8} lg={12} md={10} sm={12} xs={12} item >
					<Paper elevation={0}>
						<Formik
							initialValues={{ 
                                data: data, 
                                countData:countData 
                            }}
							onSubmit={async (e)=>{
                                let err: any = false;
								if(myTest){
									setTstErr(false)
								}else{
									setTstErr(true)
									return
								}
								if(!isNewLocation){
									for (let item of e.data) {
										let hasErr = false;
										
										if(item.isDone === true) {
											if( item.startTime === '' || item.startTime === undefined || item.startTime === null ) {
												hasErr = true;
												setError('Start time is missing');
												console.log('checking', 2)
											}
											else if( item.endTime === '' || item.endTime === undefined || item.endTime === null) {
												hasErr = true;
												setError('End time is missing');
												console.log('checking', 3)
											}
											else if( item.periodDuration === '' || item.periodDuration === undefined || item.periodDuration === null) {
												hasErr = true;
												setError('Period duration is missing');
												console.log('checking', 4)
											}
											else if( item.available === 0 || item.available === undefined || item.available === null) {
												hasErr = true;
												setError('Available slot is missing');
												console.log('checking', 5)
											}
											else if (item.startTime && item.endTime && item.periodDuration) {
												let startTime = calculateTime(item.startTime);
												let endTime = calculateTime(item.endTime);
												let timeDiff = endTime - startTime
												if ((item.periodDuration === "30" && timeDiff < 30)
													|| (item.periodDuration === "60" && timeDiff < 60)
													|| (item.periodDuration === "120" && timeDiff < 120)) {
													hasErr = true;
													setError('Please select start time and end time based on period duration');
												}

											}
											if(hasErr === true) {
												err = true;
												break;
											}
										} else{
											item.startTime = '' 
											item.endTime = '' 
											item.periodDuration =  ''
											item.available =  0
											item.isDone = false
										}
	
										
									}
								}else{
									for (let item of data) {
										let hasErr = false;
										if(item.isDone === true) {
											if( item.startTime === '' || item.startTime === undefined || item.startTime === null ) {
												hasErr = true;
												setError('Start time is missing');
												console.log('checking', 2)
											}
											else if( item.endTime === '' || item.endTime === undefined || item.endTime === null) {
												hasErr = true;
												setError('End time is missing');
												console.log('checking', 3)
											}
											else if( item.periodDuration === '' || item.periodDuration === undefined || item.periodDuration === null) {
												hasErr = true;
												setError('Period duration is missing');
												console.log('checking', 4)
											}
											else if (item.available === 0 || item.available === undefined || item.available === null) {
												hasErr = true;
												setError('Available slot is missing');
												console.log('checking', 5)
											}
											else if (item.startTime && item.endTime && item.periodDuration) {
												let startTime = calculateTime(item.startTime);
												let endTime = calculateTime(item.endTime);
												let timeDiff = endTime - startTime
												if ((item.periodDuration === "30" && timeDiff < 30)
													|| (item.periodDuration === "60" && timeDiff < 60)
													|| (item.periodDuration === "120" && timeDiff < 120)) {
													hasErr = true;
													setError('Please select start time and end time based on period duration');
												}
												
										}
											if(hasErr === true) {
												err = true;
												break;
											}
									} else {
											item.startTime = '' 
											item.endTime = '' 
											item.periodDuration =  ''
											item.available =  0
											item.isDone = false
										}
	
										
									}
								}
                              
                                
                                if(!err && !isNewLocation){
                                    setError('');
                                    setLoading(true);
									if(btnText === 'Next'){
										goToNextStep()
									}else{
										await editSlotConfigurationDummy(data, myTest, editLocation?.qbenchCustomerId, editLocation?.cPartnerID , isNp).then((resdata: any) => {
											if (resdata.status) {
												console.log('succ', resdata)
												setLoading(false);
												setBtnText('Next')
												// setNext(true)
											}
											else {
												console.log('error')
												setLoading(false);
											}
											}).catch(err => {
												setLoading(false);
											});
									}
                                    
                                }else if(!err && isNewLocation && !tstErr){
									if(btnText === 'Save'){
									addSlots(data, myTest, locationData?.data.qbenchCustomerId)
									setError('')
									} else {
										if(locationData.slotNewDetails.length !== 0){
											nextHandler()
										} else {
											setError('Please select atleast one slot')
											setBtnText('Save')
										}
									}
								}
                            }}
							enableReinitialize
						>
							<Form style={{maxWidth : '805px', marginLeft: isNewLocation ? '150px': '85px',  width: isNewLocation ? '490px': ''}}>
								{ isNewLocation ? 
								<Typography style={{width: '122%'}} variant="subtitle1" color="initial" className={classes.fontweight700}><h2><b>Add New Location - Timeslot Config</b></h2></Typography>
								 : <Typography style={{width: '122%'}} variant="subtitle1" color="initial" className={classes.fontweight700}> <h3><b>Location Name: {editLocation?.name}</b></h3></Typography>}
								<Typography 
									variant="subtitle2" 
									color="initial"
								>
									{!isNewLocation ? <b>Edit timeslot configuration</b> : <b style={{visibility : 'hidden'}}></b>}
									<br></br>
									{isNewLocation ? <div style={{width: '122%'}}>Hours of operation and timeslots are configured by Test Type. First select the test type and then modify the attributes as needed.</div>
									: <div>Hours of operation and timeslots are configured by Test Type. First select the test type and then modify the attributes as needed</div>}
								</Typography>
								<br />
								
								<Typography
									variant="subtitle2"
									color="initial"
									className={classes.fontweight700}
								>
										Test Type*
								</Typography>
								{isNewLocation ? 
								<div onClick={handleCheckSave}>
								<Select name='myTest'
									styles={{width : '125%'}}
									options={testTypeDropdownNew}
                                    defaultValue={defaultTest ? defaultTest[0] : !!testTypeDropdownNew ? testTypeDropdownNew[0] : null }
									onChange={(e) => {
										console.log('checking2',e);
										setMyTest(e);
									}} 
								/></div> : isDropdown ?
								<div onClick={handleCheckSave}>
								<Select name='myTest'
									options={testTypedata}
                                    defaultValue={testTypedata[0]}
									onChange={(e) => {
										console.log('checking2',e);
										setMyTest(e);
									}} 
								/></div>
								: <></>
								}
							
							{tstErr && <ErrorText >{errTxt}</ErrorText>}
								{loader ? (
                                    <Box>
                                        <CircularProgress/> 
                                    </Box>
                                )
								:
                                // countData === 0 ? (
                                //     <Box>
                                //         <h4>No data available</h4>
                                //     </Box>
                                // )
                                // : 
								(
									<div>
										<Typography 
											variant="subtitle2" 
											color="initial"  
											className={classes.fontweight700}
										>
											Hours of Operation *
										</Typography>

										<Table className={classes.table} id={myTest} >
											<TableHead>
												<TableRow>
													<TableCell>Open?</TableCell>
													<TableCell>Day</TableCell>
													<TableCell>Start Time</TableCell>
													<TableCell >End Time</TableCell>
													<TableCell>Minutes per Slot</TableCell>
													<TableCell># Of Slots</TableCell>
												</TableRow>
											</TableHead>
										
											<TableBody>
											
												{data?.map((item: any,i: any) => {
													return (
														<TableRow>
															<TableCell>  
																{isNewLocation ? <input 
																	type="checkbox" 
																	checked={data[i]['isDone'] === false  ? false : true}
																	onChange={(e) => {
																		if(e?.target) {
																			setData((data:any) => {
																				setError('')
																				let newData = JSON.parse(JSON.stringify(data));
																				newData[i]['startTime'] = data[i].startTime ? data[i].startTime : '';
																				newData[i]['endTime'] = data[i].endTime ? data[i].endTime : '';
																				newData[i]['periodDuration'] = data[i].periodDuration ? data[i].periodDuration  : '' ;
																				newData[i]['available'] = data[i].available ? data[i].available : 0
																				newData[i]['day'] = i
																				newData[i]['isDone'] = e?.target?.checked
																				return newData;
																			})
																		}
																		
                                                                        // if(e.target.checked){
                                                                        //     data[i] = {
                                                                        //         "startTime": data[i].startTime,
                                                                        //         "endTime": data[i].endTime,
                                                                        //         "periodDuration": data[i].periodDuration ? '30' : '',
                                                                        //         "available":  data[i].available ? data[i].available : 0,
                                                                        //         "day": i,
                                                                        //         "isDone":e.target.checked
                                                                        //     }
                                                                        //     console.log('check',data[i])
                                                                        // } else{
                                                                        //     data[i] = {
                                                                        //         "startTime": '',
                                                                        //         "endTime": '',
                                                                        //         "periodDuration": '',
                                                                        //         "available":  0,
                                                                        //         "day": i,
                                                                        //         "isDone":e.target.checked
                                                                        //     }
                                                                        //     console.log('check',data[i])
                                                                        // }
																	}} 
																/> :
																<input 
																	type="checkbox" 
																	defaultChecked={data[i]['isDone'] === false ? false : true}
																	onChange={(e) => {
																		// setData((data:any) => {
																		// 	let newData = JSON.parse(JSON.stringify(data));
                                                                        //     newData[i]['isDone'] = !newData[i]['isDone'];
                                                                        //     return newData;
                                                                        // })
                                                                        // if(e?.target?.checked){
                                                                        //     data[i] = {
                                                                        //         "startTime": data[i].startTime,
                                                                        //         "endTime": data[i].endTime,
                                                                        //         "periodDuration": data[i].periodDuration ? '30' : '',
                                                                        //         "available":  data[i].available ? data[i].available : 0,
                                                                        //         "day": i,
                                                                        //         "isDone":e?.target?.checked
                                                                        //     }
                                                                        //     console.log('check',data[i])
                                                                        // } else{
                                                                        //     data[i] = {
                                                                        //         "startTime": '',
                                                                        //         "endTime": '',
                                                                        //         "periodDuration": '',
                                                                        //         "available":  0,
                                                                        //         "day": i,
                                                                        //         "isDone":e?.target?.checked
                                                                        //     }
                                                                        //     console.log('check',data[i])
                                                                        // }
																			handleCheckbox(item, i, e?.target?.checked);
																	}} 
																/>}
															</TableCell>
															<TableCell>
															{(item?.day === 0 ? 'Sun' : item?.day === 1 ? 'Mon' : item?.day === 2 ? 'Tues' : item?.day === 3 ? 'Wednes' : item?.day === 4 ? 'Thurs' : item?.day === 5 ? 'Fri' : item?.day === 6 ? 'Satur' : '')}day
															</TableCell>
															<TableCell>
																{ isNewLocation ?
																	<select
																	defaultValue={data[i]['startTime'] === '' && data[i]['startTime']!==undefined ? '' : data[i]['startTime']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] =  e?.target?.value;
																			newData[i]['endTime'] = data[i]?.endTime ? data[i]?.endTime : '';
																			newData[i]['periodDuration'] = data[i]?.periodDuration ? data[i]?.periodDuration  : '' ;
																			newData[i]['available'] = data[i]?.available ? data[i]?.available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i]?.isDone
																			setBtnText('Save')
																			return newData;
																		})
																		// data[i] = {
																		// 	"startTime": e.target.value,
																		// 	"endTime": data[i].endTime,
																		// 	"periodDuration": data[i].periodDuration,
																		// 	"available": data[i].available ? data[i].available : 0,
																		// 	"day": i,
                                                                        //     "isDone":data[i].isDone
																		// }
																		
																	}}
																>{slotstartdatefor30Value.map((slot: any) => 
																	<option value={slot.value} selected={slot.value == data[i]['startTime']}>
																		{slot.label}
																	</option>
																)}
																</select>  :
																	<select
																	defaultValue={data[i]['startTime'] === '' && data[i]['startTime']!==undefined ? '' : data[i]['startTime']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		// data[i] = {
																		// 	"startTime": e?.target?.value,
																		// 	"endTime": data[i].endTime,
																		// 	"periodDuration": data[i].periodDuration,
																		// 	"available": data[i].available ? data[i].available : 0,
																		// 	"day": i,
                                                                        //     "isDone":data[i].isDone
																		// }
																		// setBtnText('Save')
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] =  e?.target?.value;
																			newData[i]['endTime'] = data[i]?.endTime ? data[i]?.endTime : '';
																			newData[i]['periodDuration'] = data[i]?.periodDuration ? data[i]?.periodDuration  : '' ;
																			newData[i]['available'] = data[i]?.available ? data[i]?.available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i]?.isDone
																			setBtnText('Save')
																			return newData;
																		})
																	}}
																> {slotstartdatefor30Value.map((slot: any) => 
																	<option value={slot.value} selected={slot.value == data[i]['startTime']}>
																		{slot.label}
																	</option>
																)}
																</select> 
																}
																
															
															</TableCell>
															<TableCell>
																	
																{isNewLocation ? <select
																	value={data[i]['endTime'] === '' && data[i]['endTime'] !== undefined ? '' : data[i]['endTime']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i]?.startTime;
																			newData[i]['endTime'] = e?.target?.value;
																			newData[i]['periodDuration'] = data[i]?.periodDuration ? data[i]?.periodDuration  : '' ;
																			newData[i]['available'] = data[i]?.available ? data[i]?.available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i]?.isDone
																			setBtnText('Save')
																			return newData;
																		})
																		console.log('data',e.target.value)
																	}}
																>
																{slotstartdatefor30Value.map((slot) =>
																	<option value={slot.value} selected={data[i].endTime}>
																		{slot.label}
																	</option>)}
																</select> :
																<select
																	value={data[i]['endTime'] === '' && data[i]['endTime'] !== undefined ? '' : data[i]['endTime']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i].startTime;
																			newData[i]['endTime'] = e?.target?.value;
																			newData[i]['periodDuration'] = data[i].periodDuration ? data[i].periodDuration  : '' ;
																			newData[i]['available'] = data[i].available ? data[i].available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i].isDone
																			setBtnText('Save')
																			return newData;
																		})
																		// handleHoursOfOperation(e.target.value, myTest, item, 'startTime')
																	}}
																>
																{slotstartdatefor30Value.map((slot) =>
																	<option value={slot.value} selected={data[i].endTime}>
																		{slot.label}
																	</option>)}
																</select> }
															</TableCell>
															<TableCell>
																{isNewLocation ? <select
																	defaultValue={data[i]['periodDuration'] === '' && data[i]['periodDuration'] !==undefined ? '' : data[i]['periodDuration']}
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i].startTime;
																			newData[i]['endTime'] = data[i].endTime;
																			newData[i]['periodDuration'] = e?.target?.value ;
																			newData[i]['available'] = data[i].available ? data[i].available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i].isDone
																			setBtnText('Save')
																			return newData;
																		})
																	}}
																>
																{slotdurationValue.map((slot) =>
																	<option value={slot.value} selected={slot.value === data[i].periodDuration}>
																		{slot.label}
																	</option>
																)}
																</select> :
																<select
																	defaultValue={data[i]['periodDuration'] === '' && data[i]['periodDuration'] !==undefined ? '' : data[i]['periodDuration']}
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {

																		// data[i] = {
																		// 	"startTime": data[i].startTime,
																		// 	"endTime":  data[i].endTime,
																		// 	"periodDuration": e?.target?.value,
																		// 	"available": data[i].available ? data[i].available : 0,
																		// 	"day": i,
                                                                        //     "isDone":data[i].isDone
																		// }
																		// setBtnText('Save')
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i].startTime;
																			newData[i]['endTime'] = data[i].endTime;
																			newData[i]['periodDuration'] = e?.target?.value ;
																			newData[i]['available'] = data[i].available ? data[i].available : 0
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i].isDone
																			setBtnText('Save')
																			return newData;
																		})
																	}}
																>
																{slotdurationValue.map((slot) =>
																	<option value={slot.value} selected={slot.value === data[i].periodDuration}>
																		{slot.label}
																	</option>
																)}
																</select>} 
															</TableCell>
															<TableCell>
																{isNewLocation ? <select
																	defaultValue={data[i]['available'] === 0 && data[i]['available']!==undefined ? 0 :  data[i]['available']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i].startTime;
																			newData[i]['endTime'] = data[i].endTime;
																			newData[i]['periodDuration'] = data[i].periodDuration ;
																			newData[i]['available'] = parseInt(e?.target?.value)
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i].isDone
																			setBtnText('Save')
																			return newData;
																		})
																		// handleHoursOfOperation(e.target.value, myTest, item, 'startTime')
																	}}
																	// onChange={(e) => handleHoursOfOperation(e.target.value, myTest, item, 'capacity')}
																>
																{slotcapacity(30).map((slot: any) =>
																	<option value={slot.value} selected={slot.value === data[i]['available']}>
																		{slot.label}
																	</option>
																)}
																</select> :
																<select
																	defaultValue={data[i]['available'] === 0 && data[i]['available']!==undefined ? 0 :  data[i]['available']} 
																	disabled={!data[i]?.isDone}
																	onChange={(e) => {
																		// data[i] = {
																		// 	"startTime": data[i].startTime,
																		// 	"endTime":  data[i].endTime,
																		// 	"periodDuration": data[i].periodDuration,
																		// 	"available": parseInt(e?.target?.value),
																		// 	"day": i,
                                                                        //     "isDone":data[i].isDone
																		// }
																		// setBtnText('Save')
																		setData((data:any) => {
																			let newData = JSON.parse(JSON.stringify(data));
																			newData[i]['startTime'] = data[i].startTime;
																			newData[i]['endTime'] = data[i].endTime;
																			newData[i]['periodDuration'] = data[i].periodDuration ;
																			newData[i]['available'] = parseInt(e?.target?.value)
																			newData[i]['day'] = i
																			newData[i]['isDone'] = data[i].isDone
																			setBtnText('Save')
																			return newData;
																		})
																		// handleHoursOfOperation(e.target.value, myTest, item, 'startTime')
																	}}
																	// onChange={(e) => handleHoursOfOperation(e.target.value, myTest, item, 'capacity')}
																>
																{slotcapacity(30).map((slot: any) =>
																	<option value={slot.value} selected={slot.value === data[i]['available']}>
																		{slot.label}
																	</option>
																)}
																</select> }
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
										<ConfirmationModal
                                            open={openConfirmationModal}
                                            handleClosed={() => {
                                                setOpenConfirmationModal(false);
												setTitle('')
                                            }}
                                            handleCancel={() => {
                                                setOpenConfirmationModal(false);
												setTitle('')
                                            }}
											Title={title}
                                        />
									
			
										<br />
                                        {/* {isNewLocation ? <></> : <><Typography 
											variant="subtitle2" 
											color="initial"  
											className={classes.fontweight700}
										>
											Does this location provide Nasopharyngeal (NP) testing ?
										</Typography>
                                        <Radio.Group onChange={()=>setIsNp(!isNp)} value={isNp}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
										<br /><br /></>} */}
									</div>
								)
								}
									
                                {error && <ErrorText >{error}</ErrorText>}
								{isNewLocation ?
									<ButtonContainer style={{width:'122%', gap: '5%'}}>
									<Returnbtn  className='btn-common' type="button" onClick={() => backHandler()} >  Back  </Returnbtn>
									<Confirmbtn  className='btn-common btn-alter Mui-selected' type = "submit" >  {loading === true ? 'Processing...' : btnText}</Confirmbtn>
								</ButtonContainer>
							 : <ButtonContainer >
									<Returnbtn  type="button" onClick={() => goToPrevStep()} >  Back  </Returnbtn>
									<Confirmbtn type="submit">  
										{btnText}  
									</Confirmbtn>
								</ButtonContainer>}
							</Form>
						</Formik>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);


}

export default Editlocation;