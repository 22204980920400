/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, notification, Select, Switch, Table } from 'antd';
import {useHistory} from 'react-router';
import Title from 'antd/lib/typography/Title';
import { FileImageOutlined } from '@ant-design/icons';
import { schedularLite } from '../../components/AppointmentList/constants';
import { useSharedState, useSharedDispatch } from '../../../shared/provider';
import { updateSchedularLite } from './API';
import { initialSetUpforSchedule, locationLink, newLocationFormItems, numberFormat, QRCodeLink } from './schedularUtils';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { config } from '../../../../config';
const { Option } = Select;
const openNotificationWithIcon = () => {
    notification.success({
      message: 'Success',
      description: 'Settings Applied',
    });
};
const SchedularLite = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState<{
        open: boolean;
        type: 'QR Code Link' | 'All Location' | 'Add Location' | '';
        imageLink?: string;
    }>({ open: false, type: '' });
    const [select, setSelect] = useState<any[]>([])
    const [selectOptions, setSelectOptions] = useState(false);
    const closeModal = () => {setSelectOptions(e=>false); setOpen((state)=> state.type === 'Add Location' ? {...state,type:'All Location'} :  { ...state, type: '', open: false }); };
    const { allLocations, newLocationForm }:any = useSharedState();
    const [changed, setChanged] = useState(false);
    
    const [submit, setSubmit] = useState(false)
    const { setLocation, setFormData } = useSharedDispatch();    
    const updateLocation = (id:any,qbenchCustomerId:any,cPartnerID:any,data:any) =>{
        const user:any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
        const dateAndTime = format(new Date(), config.dateTimeFormat)
        const updatedBy = {user:user?.email ?? '' , time: dateAndTime ?? ''};
        const schedularLite = {...data.schedularLite, updatedBy}
        let newLocation = allLocations.map((e:any)=>{
            if(e.id===id && qbenchCustomerId === e.qbenchCustomerId && e.cPartnerID === cPartnerID)
                return {...e,schedularLite}
            return e;
        })
        openNotificationWithIcon()
        setLocation(newLocation);
        updateSchedularLite({qbenchCustomerId,data})
        setChanged(!changed);
    }
    const { existingLocations, newLocations } = useMemo(() => {
        const existingLocations: any[] = [];
        const newLocations: any[] = [];
        const QbenchID: any[] = [];
        allLocations?.map((e: any, i: number) => {
            if (!QbenchID.includes(e.qbenchCustomerId)) {
                if ('schedularLite' in e) {
                    existingLocations.push({
                        ...e,
                        action: e.qbenchCustomerId,
                        QRlink: `${QRCodeLink}${e.qbenchCustomerId}`,
                        active: e?.schedularLite?.isActive ?? false,
                        locationLink:`${locationLink}${e.qbenchCustomerId}`
                    });
                } else {
                    newLocations.push(
                        <Option key={i} value={e.qbenchCustomerId}>
                            {e.name}
                        </Option>
                    );
                }
                QbenchID.push(e.qbenchCustomerId);
            }
        });
        newLocations.push(
        <Option key={100} value={-1}>
            Other
        </Option>)
        return { existingLocations, newLocations };
    }, [allLocations, changed]);
    const children: React.ReactNode[] = newLocations;
    const qrCodeLink = (action: any, payload: any) => (
        <FileImageOutlined
            style={{ fontSize: 40 }}
            onClick={() =>
                setOpen({
                    open: true,
                    type: 'QR Code Link',
                    imageLink: payload.QRlink,
                })
            }
        />
    );
    const link = (action: any, payload: any) => (
        <a target="blank" href={payload.locationLink} >{payload.locationLink}</a>
    );
    const swich = (action: any, payload: any) => (
        <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <Switch
                defaultChecked={payload?.schedularLite?.isActive ?? false}
                checked={payload?.schedularLite?.isActive ?? false}
                onChange={(value)=>{
                    updateLocation(payload.id,payload.qbenchCustomerId,payload.cPartnerID, 
                        {schedularLite:{
                            ...payload.schedularLite,
                            isActive:value
                        }}
                    )
                }}
            />
        </div>
    );
    const action = (action: any, payload: any) => (
        <Button
            type='primary'
            onClick={() => {
                history.push(`/dashboard/configureSchedularLite?locationID=${payload.id}`)
            }}
        >
            Configure
        </Button>
    );
    const createdBy = (action: any, payload: any)=> payload?.schedularLite?.createdBy?.user ? <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}><p>{payload?.schedularLite?.createdBy?.user ?? '-' }</p><p>{payload?.schedularLite?.createdBy?.time ?? '-' }</p> </div> : <p>-</p>
    const updatedBy = (action: any, payload: any)=> payload?.schedularLite?.updatedBy?.user ? <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}><p>{payload?.schedularLite?.updatedBy?.user ?? '-' }</p><p>{payload?.schedularLite?.updatedBy?.time ?? '-' }</p></div> : <p>-</p>
    const handleLocations = async() =>{
        if(select.length && open.type==='All Location'){
            updateSchedularLite({type:"add",qbenchCustomerId:select})        
            setLocation(allLocations.map((e:any)=>{
                if(select.includes(e?.qbenchCustomerId ?? 0)){
                    const user:any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
                    const dateAndTime = format(new Date(), config.dateTimeFormat)
                    const createdBy = {user:user?.email ?? '' , time: dateAndTime ?? ''};
                    const schedularLite = {...initialSetUpforSchedule.schedularLite,createdBy }
                    return {...e,schedularLite}
                }else{
                    return e;
                }
            }))
            setChanged(!changed);
            setSelect([]);
            closeModal();
        }
        else if(open.type==='Add Location'){
            if(newLocationFormItems.filter(e=>e.required && e.name !== 'isQRCodeSend').some(e=>!newLocationForm?.[e.name]))
                setSubmit(true)
            else
            {
                setLoading(true)
                setSubmit(false);
                try {
                    const result = await updateSchedularLite({data:{...newLocationForm, isOpened:true},type:'insert'});
                    setLocation([...allLocations,{...newLocationForm,id:result?.data ?? ""}])
                    closeModal()
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            }
        }
    }
    const handleChange = (value:any,key:any)=>{
        setFormData({[key]:numberFormat.includes(key)? parseInt(value) : value})
    }
    useEffect(() => {
        if(select.includes(-1))
        {
            setOpen({type:'Add Location',open:true})
            setSelect(state=>state.filter(e=>e!==-1))
        }  
    }, [select])
    useEffect(() => {
        document.getElementsByClassName('ant-select-dropdown')[0]?.setAttribute('class',(open.open || selectOptions)?'':'hide');
    }, [open.open, selectOptions])
    useEffect(()=>{
        window.document.getElementsByClassName('makeStyles-mainPanel1-3')?.[0]?.scroll(0,0)
        window.document.getElementsByClassName('jss3')?.[0]?.scroll(0,0)
    },[])
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px 0',
                }}
                id="schedulerLiteHomePage"
            >
                <Title
                    level={3}
                    style={{
                        margin: '24px 16px',
                        color: '#black',
                        textAlign: 'center',
                        fontWeight: 400,
                        fontSize: '40px',
                    }}
                >
                    Scheduler Lite Management
                </Title>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    margin: '20px 0',
                }}
            >
                <Button
                    type="primary"
                    style={{ height: '40px' }}
                    onClick={() =>
                        setOpen({ open: true, type: 'All Location' })
                    }
                >
                    Add Schedular Location
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Table
                    size="middle"
                    columns={schedularLite(qrCodeLink, swich, action, link, createdBy, updatedBy) as any}
                    bordered
                    dataSource={existingLocations || []}
                    loading={allLocations ? false : true}
                    pagination={false}
                    rowKey={(row: any) => row.key}
                />
            </div>
            <Modal
                visible={open.open}
                title={open.type}
                footer={
                    open.type === 'QR Code Link'
                        ? [
                            <Button
                                onClick={closeModal}
                            >
                                Close
                            </Button>,
                            <a href={open?.imageLink?.split('chl=')?.[1]} style={{marginLeft:10}} target='blank'><Button type="primary">Open Link</Button></a>,
                          ]
                          : [
                              <Button
                                  disabled={loading}
                                  key="back"
                                  type="ghost"
                                  onClick={closeModal}
                              >
                                  Cancel
                              </Button>,
                              <Button
                                  loading={loading}
                                  disabled={loading}
                                  key="submit"
                                  type="primary"
                                  onClick={handleLocations}
                              >
                                  {loading?'Adding Location':'Ok'}
                              </Button>,
                          ]
                }
                onCancel={loading ? ()=>{} :()=>{ setSelectOptions(e=>false); closeModal();  } }
                width={open.type==='Add Location' ? 1040 : 520}
            >
                {open.type === 'Add Location' && (<>
                    <Form
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Grid container spacing={3}>
                            {newLocationFormItems.map((e,i:number)=>(<Grid key={i} item xs={12} md={6} lg={6}>
                                <Form.Item
                                    label={e.label}
                                    name={e.name}
                                    rules={[{ required: e.required, message: 'Please input your username!' }]}
                                    style={{gap:10}}
                                >
                                    {e.type !=='select' ? 
                                        (<>
                                            <Input name={e.name} onChange={(value)=>handleChange(value.target.value,e.name)} type={e.type}/>
                                            {(submit && e.required && !(newLocationForm?.[e.name] ?? '')) && <span style={{color:'red'}}>Required*</span>}
                                        </>)
                                    :
                                        (<>
                                            <Select 
                                            placeholder="Please select"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={(e.name === 'isQRCodeSend' ? newLocationForm?.[e.name] ? 'SHOW':'HIDE' : newLocationForm?.[e.name]) ?? ''} onChange={(value)=>handleChange((value === 'SHOW' ? true : value === 'HIDE' ? false : value),e.name)} >
                                                {e.list?.map((column:{value:any,label:any},index:number)=><Option key={index} value={ column.value }>{ column.label }</Option>)}
                                            </Select>
                                            {(submit && e.required && !(e.name !== 'isQRCodeSend' ? newLocationForm?.[e.name] ?? '':true)) && <span style={{color:'red'}}>Required*</span>}
                                        </>    
                                        )
                                    }
                                </Form.Item>
                            </Grid>))}
                        </Grid>
                    </Form>
                </>)}
                {open.type === 'All Location' && (
                    (open.open || selectOptions) && <>
                        <Select
                            open={ open.open && selectOptions }
                            onSelect={()=>{
                                const selectComponentId = document.getElementById('selectMultipleLocation')
                                selectComponentId?.blur();
                                setSelectOptions(e=>false)}
                            }
                            onFocus={()=>setSelectOptions(e=>true)}
                            id="selectMultipleLocation"
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={[]}
                            onChange={setSelect}
                            value={select}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {children}
                        </Select>
                    </>
                )}
                {open.type === 'QR Code Link' && (
                    <div style={{ width: '100%' }}>
                        <a href={open.imageLink} target="blank">
                            <img
                                style={{ width: '100%' }}
                                src={open.imageLink}
                                alt="Not Found"
                            />
                        </a>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default SchedularLite;
