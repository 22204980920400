import * as React from 'react';
import { Formik, Form, FormikErrors, Field } from 'formik';
import { Container, Grid, Paper, Typography, IconButton, Snackbar, Backdrop, CircularProgress, Card, RadioGroup } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { HashRouter, Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Checkbox from '../../../shared/components/form/Checkbox';
import Input from '../../../shared/components/form/Input';
import Select from '../../../shared/components/form/Select';
import { stylesForListing } from './styles';
import { DatepickerField } from '../../../shared/components/Datepicker';
import MultipleSelect from './Components/MultipleSelect';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MuiAlert from '@material-ui/lab/Alert'
import { useAdminState, useAdminDispatch } from './../../provider'
import { slotstartdatefor30, slotduration, slotcapacity, finalvalue, getEditDetails } from './Model';
import { loadstate, loadCountry, getQbenchCustomers, getAssayList, addLocation, updateGeneralLocation, getLocationCpartnerId} from './API';
import { firebase } from '../../../../firebase';
import styled from 'styled-components';
// import Table from 'material';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import QBenchModal from './QBenchModal';
import QBenchSetupPage from './QBenchSetupPage';
import Editlocation from './EditLocation';
import Holidays from './Holidays';
import { Radio } from 'antd';
import "./addLocations.css"
import { getPostalCodeDetails } from '../../../shared/providers/schedule/api';
import { country } from '../../../shared/components/PersonalInformation/PassportCountry';
import { is } from 'date-fns/locale';
import { format } from 'date-fns';
import { config } from '../../../../config';


const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
};
@media (min-width: 450px) { flex-direction: row; }
`;

const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;
const ErrorContainer = styled.div`
font-size: 14px;
margin-bottom: 15px;
color: red;
opacity: 1;
transition: opacity 0.15s ease-in-out;
margin-top: -25px
`;

const Returnbtn = styled.div`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const AddBtn = styled.div`
width: 100%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;
const PaymentMethodContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
padding-left: 13px;
width: 100%;
justify-content: space-between;
justify-content: left;
gap: 20%;
};
@media (min-width: 450px) { flex-direction: row; }
`;

const TypoContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
white-space: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
gap: 5%;
};
@media (min-width: 450px) { flex-direction: row; }
`;

const ErrorText = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  color: red;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
`;


export default function AddLocations() {
  const { GetServiceData, getID, locationData, adminSlots, editLocation } = useAdminState();
  const { SetID, updateLocation } = useAdminDispatch();
  const history = useHistory();
  const params: any = useParams()
  const search = useLocation().search;
  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [alertbox, setalertbox] = React.useState<{ type: string, msg: string, open: true | false }>({ type: '', msg: '', open: false })
  const [slotstype, setslotstype] = React.useState<string>('30 MIN')
  const [submitbtn, setsubmitbtn] = React.useState<true | false>(false)
  const [traveltype, settraveltype] = React.useState<any>(false);
  const [testtype, settesttype] = React.useState<any>(false);
  const [checkweek, setcheckweek] = React.useState<any>(false);
  const [visibleWeek, setVisibleWeek] = React.useState<any>(false);
  const [visible, setvisible] = React.useState<any>({ touch: false, record: [] });
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [update, setupdate] = React.useState<true | false>(false)
  const [loader, setloader] = React.useState<boolean>(false);
  const [state, setstate] = React.useState<any>([]);
  const [country, setcountry] = React.useState<any>([]);
  const [useeffect, setuseeffect] = React.useState<boolean>(true);
  const [submit, setsubmit] = React.useState<boolean>(false)
  const [ID, setID] = React.useState<any>(null);
  const [assayDropdown, setAssayDropdown] = React.useState<any>();
  const [customerDropdown, setCustomerDropdown] = React.useState<any>();
  const [cPartnerDropdown, setCPartnerDropdown] = React.useState<any>();
  const [step, setStep] = React.useState<number>(Number(params?.id) || 1)
  const [tenentTypes, setTenentType] = React.useState<any>(false);
  const [holidays, setHolidays] = React.useState<any>([]);
  const [myTest, setMyTest] = React.useState<any>();
  const [slotsList, setSlotsList] = React.useState<any>();
  const [testTypeDropdown, setTestTypeDropdown] = React.useState<any>([]);
  const [testTypeDropdownNew, setTestTypeDropDownNew] = React.useState<any>(null)
  const [isNp, setIsNp] = React.useState<boolean>(true);
  const [siteStatus, setSiteStatus] = React.useState<boolean>(true);
  const [isStateErr, setStateErr] = React.useState<boolean>(false);
  const [isaddressErr, setaddressErr] = React.useState<boolean>(false);
  const [isNameErr, setNameErr] = React.useState<boolean>(false);
  const [isCityErr, setCityErr] = React.useState<boolean>(false);
  const [isCountryErr, setCountryErr] = React.useState<boolean>(false);
  const [isZipErr, setZipErr] = React.useState<boolean>(false);
  const [isMapErr, setMapErr] = React.useState<boolean>(false);
  const [isPayErr, setPayErr] = React.useState<boolean>(false);
  const [isTestErr, setTestErr] = React.useState<boolean>(false);
  // const [isSiteStatusErr, setSiteStatusErr] = React.useState<boolean>(false);
  const [isTenentErr, setTenentErr] = React.useState<boolean>(false);
  const [isVisSinceErr, setVisSinceErr] = React.useState(false);
  const [isTimeZoneErr, setTimeZoneErr] = React.useState(false);
  const [isCpIdErr, setCpIdErr] = React.useState(false);
  const timeZones = ['HST', 'AKST', 'PST', 'MST', 'CST', 'EST'];
  const [creditCard, setCreditCard] = React.useState<boolean>(true);
  const [insurance, setInsurance] = React.useState<boolean>(true);
  const [stdTest, setStdTest] = React.useState<boolean>(true);
  const [expTest, setexpTest] = React.useState<boolean>(true);
  const [rapTest, setRapTest] = React.useState<boolean>(true);
  const [antTest, setAntTest] = React.useState<boolean>(true);
  const [stdSdTest, setStdSdTest] = React.useState<boolean>(true);
  const [expSdTest, setExpSdTest] = React.useState<boolean>(true);
  const [rapSdTest, setRapSdTest] = React.useState<boolean>(true);
  const [antSdTest, setAntSdTest] = React.useState<boolean>(true);
  const [creditCardStd, setCreditCardStd] = React.useState<boolean>(true);
  const [creditCardExp, setCreditCardExp] = React.useState<boolean>(true);
  const [creditCardRap, setCreditCardRap] = React.useState<boolean>(true);
  const [creditCardAnt, setCreditCardAnt] = React.useState<boolean>(true);
  const [insuranceStd, setInsuranceStd] = React.useState<boolean>(true);
  const [insuranceExp, setInsuranceExp] = React.useState<boolean>(false);
  const [insuranceRap, setInsuranceRap] = React.useState<boolean>(false);
  const [insuranceAnt, setInsuranceAnt] = React.useState<boolean>(true);
  const [noInsuranceStd, setNoInsuranceStd] = React.useState<boolean>(true);
  const [noInsuranceExp, setNoInsuranceExp] = React.useState<boolean>(false);
  const [noInsuranceRap, setNoInsuranceRap] = React.useState<boolean>(false);
  const [noInsuranceAnt, setNoInsuranceAnt] = React.useState<boolean>(true);
  const [paymentErr, setPaymentErr] = React.useState<boolean>(false);
  const [updateId, setUpdateId] = React.useState<any>(null)
  const [zipCheck,setZipCheck] = React.useState<boolean>(false);
  const [noInsurance, setNoInsurance] = React.useState<boolean>(true)
  const [trav, setTrav] = React.useState<boolean>(false);
  const [gen, setGen] = React.useState<boolean>(false);
  const [schBRSSD, setSchBRSSD] = React.useState<boolean>(false);
  const [schPVSD, setSchPVSD] = React.useState<boolean>(false);
  const [schSUHSD, setSchSUHSD] = React.useState<boolean>(false);
  const [schSCSD, setSchSCSD] = React.useState<boolean>(false);
  const [schSSFSD, setSchSSFSD] = React.useState<boolean>(false);
  const [com, setcom] = React.useState<boolean>(false);
  const [sfo, setSfo] = React.useState<boolean>(false);
  const [qbenchId, setQbenchId] = React.useState<any>([]);
  const [assayId, setAssayId] = React.useState<any>([]);
  const [rapidAssayId, setRapidAssayId] = React.useState<any>([]);
  const [antigenAssayId, setAntigenAssayId] = React.useState<any>([]);
  const [oldData, setOldData] = React.useState<any>({});
  const [counter, setCounter] = React.useState(0);
  const [dropdownlist, setDropdownlist] = React.useState<any>('')
  const [locationUrl, setURL] = React.useState<any>('')
  const [urlErr,setUrlErr] = React.useState<boolean>(false)
  const [travelVal,setTravelVal] = React.useState<boolean>(false)
  const [generalVal,setGeneralVal] = React.useState<boolean>(false)
  const [communityVal,setCommunityVal] = React.useState<boolean>(false)
  const [sfoVal, setSfoVal] = React.useState<boolean>(false)
  const [defaultTest, setDefaultTest] = React.useState<any>(null)
  const [slotsHandle, setSlotsHandle] = React.useState<any>([
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 0,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 1,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 2,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 3,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 4,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 5,
      isDone: false
    },
    {
      startTime: '',
      endTime: '',
      periodDuration: '',
      available: 24,
      day: 6,
      isDone: false
    }
  ]);
  const [loading, setLoading] = React.useState<boolean>(false)
  const timeZoneDropdown: any = []
  const updateLocationData = async (data: any) => {
    let newData: any;
    data.weeklyVisibleDays = []
    data.weeklyVisibleNumber = { weekDay: 0, weekNumber: 0 }
    data.isQRCodeSend = false
    data.isWeeklyFlag = false
    data.isDayFilter = false
    data.isWeekDay = false
    data.isWeekEnd = false
    data.isAssay = false;
    data.isActive = true;
    data.county = '';
    delete data.blockOutDays
    delete data.delayopenstartdate
    delete data.haCustomerOnly
    delete data.holidays
    delete data.isAssay
    delete data.isDayFilter
    delete data.isDomestic
    delete data.isExpressNp
    delete data.isInternational
    delete data.isQRCodeSend
    delete data.isRapidNp
    delete data.isStandardNp
    delete data.isWeekDay
    delete data.isWeekEnd
    delete data.isWeeklyFlag
    delete data.qbenchAssayId
    delete data.qbenchCustomerId
    delete data.qbenchCustomerOldIds
    delete data.weeklyVisibleDays
    delete data.weeklyVisibleNumber
    delete data.visible
    delete data.data
    delete data.tenentTypes
    delete data.testtype
    delete data.slotsList
    delete data.type
    delete data.acceptance
    delete data.fromhourofoperation
    delete data.qbenchassay
    delete data.slotcapacity
    delete data.tohourofoperation
    delete data.weeklyVisibleDay
    delete data.weeksofoperation
    delete data.sitestatus
    const startDate = data.startDate.toString();
    const visibleSince = data.visibleSince.toString();
    let cPartnerID = data.cPartnerID === undefined ? dropdownlist?.cPartnerID.value : typeof (data.cPartnerID) === 'string' ? data.cPartnerID : data.cPartnerID !== undefined ? data.cPartnerID?.value : dropdownlist?.cPartnerID.value;
    let country = data.country === undefined ? dropdownlist?.country.value : typeof (data.country) === 'string' ? data.country : data.country !== undefined ? data.country?.value : dropdownlist?.country.value;
    let state = data.state === undefined ? dropdownlist?.state.value : typeof (data.state) === 'string' ? data.state : data.state !== undefined ? data.state?.value : dropdownlist?.state.value;
    let timezone = data.timezone === undefined ? dropdownlist?.timezone.value : typeof (data.timezone) === 'string' ? data.timezone : data.timezone !== undefined ? data.timezone?.value : dropdownlist?.timezone.value;
    newData = { ...data, visibleSince: visibleSince ? visibleSince : '', startDate: startDate ? startDate : '', cPartnerID, country, state, timezone, id: updateId }
    setLoading(true)
      let arrayData=[]
      let tenantType = {
        isTravel: newData?.isTravel,
        isGeneral: newData?.isGeneral,
        isCommunity: newData?.isCommunity,
        isWork: newData?.isWork
      }
      if(tenantType.isTravel !== oldData?.isTravel || tenantType?.isGeneral !== oldData?.isGeneral) {
        if (tenantType?.isTravel === true && tenantType?.isGeneral === true) {
          arrayData.push({
            ...newData,
            isTravel: true,
            isGeneral: true,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'add'
          })
        } else if (tenantType?.isTravel === true && tenantType?.isGeneral === false) {
          arrayData.push({
            ...newData,
            isTravel: true,
            isGeneral: false,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'add'
          })
        } else if (tenantType?.isTravel === false && tenantType?.isGeneral === true) {
          arrayData.push({
            ...newData,
            isTravel: false,
            isGeneral: true,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'add'
          })
        } else if (tenantType?.isTravel === false && tenantType?.isGeneral === false) {
          arrayData.push({
            ...newData,
            cPartnerID: 'WSL001',
            type: 'delete'
          })
        }
      }
      if(tenantType?.isCommunity !== oldData?.isCommunity) {
        if(tenantType?.isCommunity === true) {
          arrayData.push({
            ...newData,
            isTravel: false,
            isGeneral: false,
            isCommunity: true,
            isWork: false,
            cPartnerID: 'COM001',
            type : 'add'
          })
        } else if (tenantType?.isCommunity === false) {
          arrayData.push({
            ...newData,
            cPartnerID: 'COM001',
            type : 'delete'
          })
        }
      }
      if(tenantType?.isWork !== oldData?.isWork) {
        if (tenantType?.isWork === true) {
          arrayData.push({
            ...newData,
            isTravel: false,
            isGeneral: false,
            isCommunity: false,
            isWork: true,
            cPartnerID: 'SFO001',
            type : 'add'
          })
        } else if (tenantType?.isWork === false) {
          arrayData.push({
            ...newData,
            cPartnerID: 'SFO001',
            type : 'delete'
          })
        }
      }
      if(tenantType?.isWork === true && oldData?.isWork === true) {
        arrayData.push({
          ...newData,
          isTravel: false,
          isGeneral: false,
          isCommunity: false,
          isWork: true,
          cPartnerID: 'SFO001',
          type : 'existing'
        })
      } 
      if(tenantType?.isCommunity === true && oldData?.isCommunity === true) {
          arrayData.push({
            ...newData,
            isTravel: false,
            isGeneral: false,
            isCommunity: true,
            isWork: false,
            cPartnerID: 'COM001',
            type : 'existing'
          })
        }
      if(tenantType?.isTravel === true || tenantType?.isGeneral === true) {
        if (tenantType?.isTravel === true && oldData.isTravel === true && tenantType?.isGeneral === true && oldData.isGeneral === true) {
          arrayData.push({
            ...newData,
            isTravel: true,
            isGeneral: true,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'existing'
          })
        } else if (tenantType?.isTravel === true && oldData.isTravel === true && tenantType?.isGeneral === false && oldData.isGeneral === false) {
          arrayData.push({
            ...newData,
            isTravel: true,
            isGeneral: false,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'existing'
          })
        } else if (tenantType?.isTravel === false && oldData.isTravel === false && tenantType?.isGeneral === true && oldData.isGeneral === true) {
          arrayData.push({
            ...newData,
            isTravel: false,
            isGeneral: true,
            isCommunity: false,
            isWork: false,
            cPartnerID: 'WSL001',
            type: 'existing'
          })
        }
      }
      console.log('arraydata', arrayData)
      setTimeout(()=>{
        setloader(false)
        },20000)
    const result: any = await updateGeneralLocation({newData : newData, arrayData : arrayData})
    setLoading(false)
    return result.data.status
  }
  timeZones.map((item) => { timeZoneDropdown.push({ label: item, value: item }) });
  const PreloadData = async (id: any, cPartnerIDs: any) => {
    setloader(true)
    const result: any = await updateGeneralLocation({newData: {id: id}, oldData: {} })
    console.log('KC result', result);

    const newdata = result?.data?.data?.[0]?.data ?? {};
    setUpdateId(newdata.id)
    setOldData(newdata)
    setloader(true)
    let newStates: any[] = []
    let disableDataFromDb = await getLocationCpartnerId({locationName : newdata?.name})
    setTravelVal(disableDataFromDb?.data?.isTravel && (!newdata?.isTravel && newdata?.cPartnerID !== 'WSL001' && !newdata?.isTravel))
    setGeneralVal(disableDataFromDb?.data?.isGeneral && (!newdata?.isGeneral && newdata?.cPartnerID !== 'WSL001' && !newdata?.isGeneral))
    setCommunityVal(disableDataFromDb?.data?.isCommunity && (!newdata?.isCommunity && newdata?.cPartnerID !== 'COM001'))
    setSfoVal(disableDataFromDb?.data?.isWork && (!newdata?.isWork && newdata?.cPartnerID !== 'SFO001'))
    await loadstate().then((response: any) => {

      setstate(response)
      newStates = response
    })
    const states = newStates.filter(e => e.value === newdata.state || e.label === newdata.state)[0]
    console.log('newDataAAAADDDD', newdata)
    setloader(true)
    let cPartnerID = { label: newdata.cPartnerID ? newdata.cPartnerID : '', value: newdata.cPartnerID ? newdata.cPartnerID : '' };
    let state = { label: states.label, value: states.value };
    let qbenchCustomerOldIds = [{ label: newdata.qbenchCustomerId ? newdata.qbenchCustomerId : '', value: newdata.qbenchCustomerId ? newdata.qbenchCustomerId : '' }];
    let country = { label: newdata.country ? newdata.country : 'United State', value: newdata.country ? newdata.country : 'United State' };
    let timezone = { label: newdata.timezone ? newdata.timezone : '', value: newdata.timezone ? newdata.timezone : '' };
    updateLocation({
      GMT: newdata.GMT,
      name: newdata.name ? newdata.name : '',
      CLIA: newdata.CLIA,
      visibleSince: newdata?.visibleSince ? newdata?.visibleSince._seconds ? new Date(newdata?.visibleSince._seconds * 1000) : new Date(newdata?.visibleSince) : "",
      address1: newdata.address1 ? newdata.address1 : '',
      address2: newdata.address2 ? newdata.address2 : '',
      city: newdata.city ? newdata.city : '',
      zipcode: newdata.zipcode ? newdata.zipcode : '',
      locationlink: newdata.locationlink ? newdata.locationlink : '',
      isInternational: newdata.isInternational ? true : false,
      isTravel: newdata.isTravel ? true : false,
      isGeneral: newdata.isGeneral ? true : false,
      isSchool: newdata.isSchool ? true : false,
      isWork: newdata.isWork ? true : false,
      isCommunity: newdata.isCommunity ? true : false,
      rapidTest: newdata.rapidTest ? true : false,
      standardTest: newdata.standardTest ? true : false,
      expressTest: newdata.hasVipSlots ? true : false,
      isExpressSameDay: newdata.hasVipSlots ? true : false,
      hasVipSlots: newdata.hasVipSlots ? true : false,
      isAntigen: newdata.isAntigen ? true : false,
      isStandardNp: newdata.isStandardNp ? true : false,
      isRapidNp: newdata.isRapidNp ? true : false,
      isExpressNp: newdata.isExpressNp ? true : false,
      delayopenstartdate: newdata.startDate ? newdata.startDate : new Date(),
      medicalDirector: newdata.medicalDirector ? newdata.medicalDirector : '',
      startDate: newdata?.startDate ? newdata?.startDate._seconds ? new Date(newdata?.startDate._seconds * 1000) : new Date(newdata?.startDate) : '',
      performingfacility: newdata.performingfacility,
      tenentTypes: {
        travel:(newdata?.isTravel === true && newdata?.cPartnerID === "WSL001") ? true : false,
        general: (newdata?.isGeneral === true && newdata?.cPartnerID === "WSL001") ? true : false,
        school: {
          BRSSD: newdata?.schools?.includes('BEL001'),
          PVSD: newdata?.schools?.includes('POR001'),
          SUHSD: newdata?.schools?.includes('SEQ001'),
          SCSD: newdata?.schools?.includes('SCS001'),
          SSFSD: newdata?.schools?.includes('SSF001'),
        },
        community:( newdata?.isCommunity === true && newdata?.cPartnerID === "COM001") ? true : false,
        work:( newdata?.isWork === true && newdata?.cPartnerID === "SFO001") ? true : false,
      },
      cPartnerID, state, qbenchCustomerOldIds, country, timezone
    })
    setDropdownlist({ cPartnerID, state, qbenchCustomerOldIds, country, timezone })
    setTrav(newdata?.isTravel ? true : false)
    setGen(newdata?.isGeneral ? true : false)
    setSchBRSSD(newdata?.schools?.includes('BEL001'))
    setSchPVSD(newdata?.schools?.includes('POR001'))
    setSchSUHSD(newdata?.schools?.includes('SEQ001'))
    setSchSCSD(newdata?.schools?.includes('SCS001'))
    setSchSSFSD(newdata?.schools?.includes('SSF001'))
    setcom(newdata?.isCommunity ? true : false)
    setSfo(newdata?.isWork ? true : false)
    setSiteStatus(newdata.isOpened)
    // setNoInsurance(newdata.isNoInsurance?true:false);
    setIsNp(newdata.isNpDestination)
    setStdTest(newdata?.standardTest ? true : false)
    setexpTest(newdata?.expressTest ? true : false)
    setRapTest(newdata?.rapidTest ? true : false)
    setAntTest(newdata?.isAntigen ? true : false)
    setStdSdTest(newdata?.isSameDay?.standard && newdata?.standardTest ? true : false)
    setExpSdTest(newdata?.isSameDay?.express && newdata?.expressTest ? true : false)
    setRapSdTest(newdata?.isSameDay?.rapid && newdata?.rapidTest ? true : false)
    setAntSdTest(newdata?.isSameDay?.antigen && newdata?.isAntigen ? true : false)
    setCreditCardStd(newdata?.isCreditCard?.standard && newdata?.standardTest ? true : false)
    setCreditCardExp(newdata?.isCreditCard?.express && newdata?.expressTest ? true : false)
    setCreditCardRap(newdata?.isCreditCard?.rapid && newdata?.rapidTest ? true : false)
    setCreditCardAnt(newdata?.isCreditCard?.antigen && newdata?.isAntigen ? true : false)
    setInsuranceStd(newdata?.isInsurance?.standard && newdata?.standardTest ? true : false)
    setInsuranceExp(newdata?.isInsurance?.express && newdata?.expressTest ? true : false)
    setInsuranceRap(newdata?.isInsurance?.rapid && newdata?.rapidTest ? true : false)
    setInsuranceAnt(newdata?.isInsurance?.antigen && newdata?.isAntigen ? true : false)
    setNoInsuranceStd(newdata?.isNoInsurance?.standard && newdata?.standardTest ? true : false)
    setNoInsuranceExp(newdata?.isNoInsurance?.express && newdata?.expressTest ? true : false)
    setNoInsuranceRap(newdata?.isNoInsurance?.rapid && newdata?.rapidTest ? true : false)
    setNoInsuranceAnt(newdata?.isNoInsurance?.antigen && newdata?.isAntigen ? true : false)
    // setCreditCard(newdata?.hasCreditCard?true:false)
    // setInsurance(newdata?.hasInsurance?true:false);   

    setTenentErr(false)
    setTenentType(true)
    handleURl(newdata.locationlink)
    setloader(false)
  }

  const handleReset = () => {
    updateLocation({
      GMT: '',
      name: '',
      CLIA: '',
      cPartnerID: '',
      visibleSince: "",
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      locationlink: '',
      isInternational: false,
      isTravel: false,
      isGeneral: false,
      isSchool: false,
      isWork: false,
      isCommunity: false,
      rapidTest: false,
      standardTest: true,
      expressTest: true,
      isExpressSameDay: false,
      hasVipSlots: false,
      isAntigen: false,
      isStandardNp: false,
      isRapidNp: false,
      isExpressNp: false,
      delayopenstartdate: '',
      qbenchCustomerOldIds: [{ label: '', value: '' }],
      country: { label: '', value: '' },
      medicalDirector: '',
      timezone: { label: '', value: '' },
      startDate: '',
      performingfacility: '',
      slotNewDetails: [],
      tenentTypes: {
        travel: false,
        general: false,
        community: false,
        work: false
      }
    })
    history.push('/dashboard/locations')
  }
  const testTypedata = [
    {
      label: 'Standard Test',
      value: 'standard'
    },
    {
      label: 'Express Test',
      value: 'express'
    },
    {
      label: 'Rapid Test',
      value: 'rapid'
    },
    {
      label: 'Antigen Test',
      value: 'antigen'
    },
  ]
  const testTypeDropdownData = {
    standard: {
      label: 'Standard Test',
      value: 'standard',
    },
    express: {
      label: 'Express Test',
      value: 'express',
    },
    rapid: {
      label: 'Rapid Test',
      value: 'rapid',
    },
    antigen: {
      label: 'Antigen Test',
      value: 'antigen',
    },
  };
  const methodfortesttype = () => {
    settesttype(true)
  }
  const handleStdChange = () => {
    methodfortesttype();
    setStdTest(!stdTest)
    setTestErr(false)
  }
  const handleExpChange = () => {
    methodfortesttype();
    setexpTest(!expTest)
    setTestErr(false)
  }
  const handleRapChange = () => {
    methodfortesttype();
    setRapTest(!rapTest)
    setTestErr(false)
  }
  const handleAntChange = () => {
    methodfortesttype();
    setAntTest(!antTest)
    setTestErr(false)
  }
  const handleStdSdChange = () => {
    methodfortesttype();
    setStdSdTest(!stdSdTest)
  }
  const handleExpSdChange = () => {
    methodfortesttype();
    setExpSdTest(!expSdTest)
  }
  const handleRapSdChange = () => {
    methodfortesttype();
    setRapSdTest(!rapSdTest)
  }
  const handleAntSdChange = () => {
    methodfortesttype();
    setAntSdTest(!antSdTest)
  }
  const methodforcheckweek = () => {
    setcheckweek(true)
  }
  const methodforcheckweek2 = () => {
    setVisibleWeek(true)
  }
  const methodfortraveltype = () => {
    settraveltype(true)
  }
  const methodforTenentType = () => {
    if (trav || gen || schBRSSD || schPVSD || schSUHSD || schSCSD || schSSFSD || com || sfo) {
      setTenentType(true)
      setTenentErr(false)
    } else {
      setTenentType(false)
      setTenentErr(true)
    }
  }
  const handleTrav = () => {
    setTrav(!trav)
    setTenentErr(false)
  }
  const handleGen = () => {
    setGen(!gen)
    setTenentErr(false)
  }
  const handleBRSSD = () => {
    setSchBRSSD(!schBRSSD)
    setTenentErr(false)
  }
  const handlePVSD = () => {
    setSchPVSD(!schPVSD)
    setTenentErr(false)
  }
  const handleSUHSD = () => {
    setSchSUHSD(!schSUHSD)
    setTenentErr(false)
  }
  const handleSCSD = () => {
    setSchSCSD(!schSCSD)
    setTenentErr(false)
  }
  const handleSSFSD = () => {
    setSchSSFSD(!schSSFSD)
    setTenentErr(false)
  }
  const handleCom = () => {
    setcom(!com)
    setTenentErr(false)
  }
  const handleSfo = () => {
    setSfo(!sfo)
    setTenentErr(false)
  }
  // const handleCred=()=>{
  //   setCreditCard(!creditCard)
  //   setPayErr(false)
  // }
  // const handleIns=()=>{
  //   setInsurance(!insurance)
  //   setPayErr(false)
  // }
  // const handleNoIns=()=>{
  //   setNoInsurance(!noInsurance)
  //   setPayErr(false)
  // }

  const handleCreditCardStd = () => {
    setPaymentErr(false)
    setCreditCardStd(!creditCardStd)
  }
  const handleCreditCardAnt = () => {
    setPaymentErr(false)
    setCreditCardAnt(!creditCardAnt)
  }
  const handleCreditCardExp = () => {
    setPaymentErr(false)
    setCreditCardExp(!creditCardExp)
  }
  const handleCreditCardRap = () => {
    setPaymentErr(false)
    setCreditCardRap(!creditCardRap)
  }
  const handleInsuranceStd = () => {
    setPaymentErr(false)
    setInsuranceStd(!insuranceStd)
  }
  const handleInsuranceAnt = () => {
    setPaymentErr(false)
    setInsuranceAnt(!insuranceAnt)
  }
  const handleInsuranceExp = () => {
    setPaymentErr(false)
    setInsuranceExp(!insuranceExp)
  }
  const handleInsuranceRap = () => {
    setPaymentErr(false)
    setInsuranceRap(!insuranceRap)
  }
  const handleNoInsuranceStd = () => {
    setPaymentErr(false)
    setNoInsuranceStd(!noInsuranceStd)
  }
  const handleNoInsuranceAnt = () => {
    setPaymentErr(false)
    setNoInsuranceAnt(!noInsuranceAnt)
  }
  const handleNoInsuranceExp = () => {
    setPaymentErr(false)
    setNoInsuranceExp(!noInsuranceExp)
  }
  const handleNoInsuranceRap = () => {
    setPaymentErr(false)
    setNoInsuranceRap(!noInsuranceRap)
  }

  const clickMe = () => {
    setHolidays([...holidays, { id: holidays.length + 1, startDay: '', endDay: '', reason: '' }])

  }
  const setInput = (event: any, type: any, index: number) => {
    holidays[index - 1][`${type}`] = event.target.record;
    setHolidays(holidays);
  }

  const handleHoursOfOperation = (record: any, test: string, day: string, type: string) => {
    const slots = slotsList;
    slots[test][day][type] = record;
    setSlotsList(slots);
  }
  var errorTxt = 'Required Field';
  var zipErr = 'Please Enter Valid ZipCode'
  var paymentErrTxt = 'Please select any payment method for all selected Test Types'
  const validateform = (record: any) => {
    let error: FormikErrors<any> = {};

    if (!record.state.value) error.state = 'Required Field';
    if (record.country === '') error.country = 'Required Field';
    // if (record.acceptance.record === '') error.acceptance = 'Required Field';
    if (record.sitestatus.record === '') error.sitestatus = 'Required Field';
    if (record.qbenchcustomer === '') error.qbenchcustomer = 'Required Field';
    if (((submitbtn || tenentTypes) && !(record?.tenentTypes?.travel || record?.tenentTypes?.general || record?.tenentTypes?.school || record?.tenentTypes?.community || record?.tenentTypes?.work))) error.tenentTypes = 'Choose';
    if (((submitbtn || traveltype) && !(record?.traveltypes?.domestic || record?.traveltypes?.international))) error.traveltype = 'Choose';
    if ((submitbtn || testtype) && !(record?.testtype?.expressTest || record?.testtype?.rapidTest || record?.testtype?.standardTest || record?.testtype?.antigenTest || record?.testtype?.expressNpTest || record?.testtype?.standerdNpTest || record?.testtype?.rapidNpTest)) error.testtype = 'Choose';
    if (!record.traveltypes.domestic && !record.traveltypes.international) error.traveltypes = 'Required Field';
    return error;
  }
  const classes = stylesForListing();
  const handleClose = () => {
    setalertbox({ open: false, type: '', msg: '' })
  }
  const getData = async () => {
    const snaps = await firebase.firestore().collection('clientpartner').get();
    const responseData = snaps.docs.map((doc: any) => doc.data());
    const dropdown: any = []
    responseData.map((data) => {
      if (data.cPartnerID)
        dropdown.push({ label: data.cPartnerID, value: data.cPartnerID })
    });
    setCPartnerDropdown(dropdown)
    await getQbenchCustomers().then((response: any) => {
      const data: any = [];
      if (response?.data?.result) {
        for (const customer of response.data.result?.data?.data)
          if (!customer.deleted)
            data.push({ label: customer.customer_name, record: customer.id })

        setCustomerDropdown(data);
      }
    })
    await getAssayList().then((response: any) => {
      const data: any = [];
      if (response?.data?.result?.assayList) {
        for (const assay of response.data.result.assayList?.data)
          data.push({ label: assay.method, record: assay.id })
        setAssayDropdown(data);
      }
    })
  }
  React.useEffect(()=>{
    return()=>{
      handleReset()
    console.log("reset")
    }
  },[])
  React.useEffect(() => {
    loadstate().then((response: any) => {
      setstate(response)
    })
    if (history.location.pathname === '/dashboard/updateLocation' && new URLSearchParams(search).get('id')) {
      PreloadData(new URLSearchParams(search).get('id'), new URLSearchParams(search).get('cPartnerID'))
      setupdate(true)
    }
    else {
      updateLocation({}, true)
      setloader((previous) => {
        return true;
      });
      getData()
      loadCountry().then((response: any) => {
        let data: any = [];
        response.map((res: any) => {
          let newdata = {
            label: res?.label,
            record: res?.label
          }
          data.push(newdata);
        })
        setcountry(data)
        if (!update) {
          setloader((previous) => false);
        }
      })
    }
  }, [])

  function handleChange(event: any) {
  }

  /* Add or edit location handling functionality */
  const addLocationHandler = async (record: any): Promise<void> => {
    let data = {
      type: "add",
      data: record
    }

    updateLocation(data)
    console.log('addloc', data)
    setStep(step + 1);

    // history.push("/dashboard/addLocation/lims")
  };

  const onBackClickHandler = () => {
    if (step === 2) {
      setStateErr(false);
      setCountryErr(false);
      setTimeZoneErr(false);
      setVisSinceErr(false);
      setTenentErr(false);
      setNameErr(false);
      setaddressErr(false);
      setCityErr(false);
      setMapErr(false);
      setZipErr(false);
      setPayErr(false);
      setTestErr(false);
      setPaymentErr(false)
    }
    else if (step === 3) {
      setQbenchId(() => {
        let res = customerDropdown.filter(
          (item: any) => item.record === locationData.data.qbenchCustomerId
        );
        return res;
      });
      setAssayId(() => {
        let res = assayDropdown.filter(
          (item: any) =>
            item.record ===
            locationData.data.qbenchStandardAssayId
        )
        return res;
      });
      setRapidAssayId(() => {
        let res = assayDropdown.filter(
          (item: any) =>
            item.record === locationData.data.qbenchRapidAssayId
        )
        return res;
      });
      setAntigenAssayId(() => {
        let res = assayDropdown.filter(
          (item: any) =>
            item.record === locationData.data.qbenchAntigenAssayId
        )
        return res;
      });
    } else if (step === 4) {
      setDefaultTest(() => {
        let res = testTypedata.filter(
          (item: any) => item.value === locationData?.slotNewDetails[0].testType
        );
        return res;
      });
      let slotDetail = locationData?.slotNewDetails[0].slotDetails
      console.log('slotdetials addloc', slotDetail)
      let slotList = [];
      for (let i = 0; i <= 6; i++) {
        let start = slotDetail[i]?.startTime
        let end = slotDetail[i]?.endTime
        let avl = slotDetail[i]?.available
        let period = slotDetail[i]?.periodDuration

        slotList.push({
          startTime: start && start !== undefined && start !== null ? start : '',
          endTime: end && end !== undefined && end !== null ? end : '',
          periodDuration: period && period !== undefined && period !== null ? period : '',
          available: avl && avl !== undefined && avl !== null ? avl : 0,
          day: i,
          isDone: start && start !== undefined && start !== null ? true : false
        });
        setSlotsHandle(slotList)
      }
      setCounter(counter + 1)
    }
    setStep(step - 1);
  };

  const handlePaymentCheck = () => {
    let count = 0
    if (stdTest && !creditCardStd && !insuranceStd && !noInsuranceStd) {
      count++
    } if (expTest && !creditCardExp && !insuranceExp && !noInsuranceExp) {
      count++
    }
    if (rapTest && !creditCardRap && !insuranceRap && !noInsuranceRap) {
      count++
    }
    if (antTest && !creditCardAnt && !insuranceAnt && !noInsuranceAnt) {
      count++
    }

    if (count === 0) {
      setPaymentErr(false)
    } else {
      setPaymentErr(true)
    }
  }

  const onNextClickHanlder = () => {
    if (step === 2) {
      let standardData = locationData.data.standardTest ? testTypeDropdownData.standard : null;
      let expressData = locationData.data.expressTest ? testTypeDropdownData.express : null;
      let rapidData = locationData.data.rapidTest ? testTypeDropdownData.rapid : null;
      let antigenData = locationData.data.isAntigen ? testTypeDropdownData.antigen : null;
      let data: any = []
      data.push(standardData, expressData, rapidData, antigenData)
      let filteredData = data.filter((data: any) => data !== null)
      setTestTypeDropDownNew(filteredData)
    }
    setStep(step + 1);

  }

  const addLocationFinal = async () => {

    await getPostalCodeDetails(locationData.data.zipcode).then((resdata: any) => {

      if (resdata) {
        locationData.data.county = resdata[0].County;

        addLocation(locationData).then((response: any) => {
          if (response) {
            console.log('succ', response)
            handleReset()
            history.push('/dashboard/locations');
            // setNext(true)
          }
          else {
            console.log('error')
          }
        }).catch(err => {
          throw err;
        });

      }
    })

  }


  const statefun = () => {
    setStateErr(false)
  }
  const cpartnerFun = () => {
    setCpIdErr(false)
  }
  const countryFun = () => {
    setCountryErr(false)
  }
  const timezoneFun = () => {
    setTimeZoneErr(false)
  }
  const visSinceFun = () => {
    setVisSinceErr(false)
  }
  const nameFun = () => {
    setNameErr(false)
  }
  const addressFun = () => {
    setaddressErr(false)
  }
  const cityFun = () => {
    setCityErr(false)
  }
  const zipFun = () => {
    setZipErr(false)
  }
  const locationFun = (evt: any) => {
    console.log('testad', evt)
    setURL(evt)
    setMapErr(false)
    setUrlErr(false)
  }
  const handleURl = (url?: any) => {
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    let urlCheck = url ? url : locationUrl
    if (urlCheck.match(regex)) {
      setUrlErr(false)
    } else {
      setUrlErr(true)
    }
  }
  const handleZip = async (e: any) => {
    const resdata = await getPostalCodeDetails(e);
    if (resdata) {
      if (resdata.length) {
        setZipCheck(false)
      } else {
        setZipCheck(true)
      }

    }

  }
  const handleAlert = () => {
    setalertbox({
      open: true,
      type: 'warning',
      msg:
          'Please fill all the required fields',
  })
  setTimeout(()=>{
    setalertbox({
      open: false,
      type: '',
      msg: '',
  })
  },2000)
  }

  React.useEffect(() => {
    updateLocation({ visible: personName })
  }, [personName])
  const stepTwoSetup = (record: any) => {
    let dropdown = [];

    if (record.testtype.standardTest) dropdown.push({ label: "Standard Test", record: "standard" });
    if (record.testtype.rapidTest) dropdown.push({ label: "Rapid Test", record: "rapid" });
    if (record.testtype.expressTest) dropdown.push({ label: "Express Test", record: "express" });
    if (record.testtype.antigenTest) dropdown.push({ label: "Antigen Test", record: "antigen" });
    if (record.testtype.standerdNpTest) dropdown.push({ label: "Standard NP Test", record: "standardNp" });
    if (record.testtype.expressNpTest) dropdown.push({ label: "Express NP Test", record: "expressNp" });
    if (record.testtype.rapidNpTest) dropdown.push({ label: "Rapid NP Test", record: "rapidNp" });
    setTestTypeDropdown(dropdown)
  }
  if (loader) {
    return <>
      <Backdrop open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop></>
  }
  else
    return (
      <Container maxWidth='lg'>
        <Snackbar open={alertbox.open} onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={alertbox.type}>
            {alertbox.msg}
          </Alert>
        </Snackbar>
        <Grid container justify='center'>
          <Grid xl={8} lg={8} md={10} sm={12} xs={12} item >
            <Paper elevation={0}>
              <Formik
                enableReinitialize
                initialValues={{ ...locationData }}
                // validate={validateform}
                onSubmit={async (record, { resetForm }) => {
                  handlePaymentCheck()
                  methodforTenentType()
                  handleURl(record.locationlink ? record.locationlink : '')
                  if (!record.state || !record.country || record.country === '' || record.country.value === '' || !record.timezone || record.timezone === '' || record.timezone.value === '' || !record.visibleSince || (!trav && !gen && !schBRSSD && !schSUHSD && !schSCSD && !schPVSD && !schSSFSD && !com && !sfo)
                    || zipCheck || !record.name || !record.address1 || !record.city || !record.locationlink || !record.zipcode || (!creditCard && !insurance && !noInsurance)
                    || (!stdTest && !expTest && !rapTest && !antTest) || (stdTest && !creditCardStd && !insuranceStd && !noInsuranceStd) || (expTest && !creditCardExp && !insuranceExp && !noInsuranceExp)
                    || (rapTest && !creditCardRap && !insuranceRap && !noInsuranceRap) || (antTest && !creditCardAnt && !insuranceAnt && !noInsuranceAnt)
                    || !!urlErr) {
                    if (!record.state) {
                      setStateErr(true);
                      handleAlert()
                    }
                    if (!record.country || record.country === '' || record.country.value === '' ) {
                      setCountryErr(true);
                      handleAlert()
                    }
                    if (!record.timezone || record.timezone.value === '') {
                      setTimeZoneErr(true);
                      handleAlert()
                    }
                    if (!record.visibleSince) {
                      setVisSinceErr(true)
                      handleAlert()
                    }
                    if ((!trav && !gen && !schBRSSD && !schSUHSD && !schSCSD && !schPVSD && !schSSFSD && !com && !sfo)) {
                      setTenentErr(true)
                      handleAlert()
                    }
                    if (!record.name) {
                      setNameErr(true)
                      handleAlert()
                    }
                    if (!record.address1) {
                      setaddressErr(true)
                        handleAlert()
                    }
                    if (!record.city) {
                      setCityErr(true)
                      handleAlert()
                    }
                    if (!record.locationlink) {
                      setMapErr(true)
                      handleAlert()
                    }
                    if (!record.zipcode || record.zipcode === '' ) {
                      setZipErr(true)
                      handleAlert()
                    }
                    if (!creditCard && !insurance && !noInsurance) {
                      setPayErr(true)
                      handleAlert()
                    }
                    if (!stdTest && !expTest && !rapTest && !antTest) {
                      setTestErr(true)
                      handleAlert()
                    }
                    if (urlErr) {
                      setalertbox({open: true, type: 'warning', msg: 'Please enter valid URL'})
                    setTimeout(()=>{
                      setalertbox({open: false, type: '', msg: '',})
                    },2000)
                    }

                  }
                  else {


                    console.log(record);
                    if (step === 1) {
                      record['isTravel'] = record.tenentTypes.travel ? true : false;
                      record['isGeneral'] = record.tenentTypes.general ? true : false;
                      // let schoolData=[];
                      // if(record.tenentTypes.school) {
                      //   if(record.tenentTypes.school.BRSSD){
                      //     schoolData.push('BEL001')
                      //   }
                      //   if(record.tenentTypes.school.SUHSD){
                      //     schoolData.push('SEQ001')
                      //   }
                      //   if(record.tenentTypes.school.SCSD){
                      //     schoolData.push('SCS001')
                      //   }
                      //   if(record.tenentTypes.school.SSFSD){
                      //     schoolData.push('SSF001')
                      //   }
                      // }
                      let cPartners = [];
                      if (record.tenentTypes) {
                        if (record.tenentTypes.community) {
                          cPartners.push('COM001')
                        }
                        if (record.tenentTypes.general || record.tenentTypes.travel) {
                          cPartners.push('WSL001')
                        }
                        if (record.tenentTypes.work) {
                          cPartners.push('SFO001')
                        }
                        if (record.tenentTypes.school) {
                          if (record.tenentTypes.school.BRSSD) {
                            cPartners.push('BEL001')
                          }
                          if (record.tenentTypes.school.PVSD) {
                            cPartners.push('POR001')
                          }
                          if (record.tenentTypes.school.SUHSD) {
                            cPartners.push('SEQ001')
                          }
                          if (record.tenentTypes.school.SCSD) {
                            cPartners.push('SCS001')
                          }
                          if (record.tenentTypes.school.SSFSD) {
                            cPartners.push('SSF001')
                          }
                        }
                      }
                      record['cPartners'] = cPartners;
                      record['schools'] = [];
                      record['CLIA'] = record.CLIA ? record.CLIA : '';
                      record['startDate'] = record.startDate ? record.startDate : new Date()
                      record['isWork'] = record.tenentTypes.work ? true : false;
                      record['isCommunity'] = record.tenentTypes.community ? true : false;
                      record['rapidTest'] = rapTest == true ? true : false;
                      record['standardTest'] = stdTest == true ? true : false;
                      record['expressTest'] = expTest == true ? true : false;
                      record['isAntigen'] = antTest == true ? true : false;
                      record['isExpressSameDay'] = expTest == true ? true : false;
                      record['blockOutDays'] = [];
                      record['hasVipSlots'] = record['expressTest'] == true ? true : false;
                      record['qbenchCustomerId'] = null;
                      record['qbenchCustomerOldIds'] = [];
                      record['qbenchAssayId'] = null;
                      record['timezone'] = record.timezone.value;
                      record['GMT'] = record.timezone === 'EST' ? '-0400' : record.timezone === 'CST'
                        ? '-0500' : record.timezone === 'MST' ? '-0600' : record.timezone === 'PST'
                          ? '-0700' : record.timezone === 'AKST' ? '-0800' : '-1000';
                      record['isOpened'] = siteStatus == true ? true : false;
                      // record['hasCreditCard'] = creditCard == true ? true : false;
                      // record['hasInsurance'] = insurance == true ? true : false;
                      record['isNpDestination'] = isNp == true ? true : false;
                      record['isDomestic'] = true;
                      record['isInternational'] = true;
                      record['haCustomerOnly'] = ''
                      record['isSameDay'] = {
                        standard: stdSdTest && stdTest ? true : false,
                        antigen: antSdTest && antTest ? true : false,
                        rapid: rapSdTest && rapTest ? true : false,
                        express: expSdTest && expTest ? true : false,
                      };
                      record['isCreditCard'] = {
                        standard: creditCardStd  && stdTest ? true : false,
                        antigen: creditCardAnt && antTest ? true : false,
                        rapid: creditCardRap && rapTest ? true : false,
                        express: creditCardExp && expTest ? true : false,
                      };
                      record['isInsurance'] = {
                        standard: insuranceStd  && stdTest ? true : false,
                        antigen: insuranceAnt && antTest ? true : false,
                        rapid: insuranceRap && rapTest ? true : false,
                        express: insuranceExp && expTest ? true : false, 
                      };
                      record['isNoInsurance'] = {
                        standard: noInsuranceStd  && stdTest ? true : false,
                        antigen: noInsuranceAnt && antTest ? true : false,
                        rapid: noInsuranceRap && rapTest ? true : false,
                        express: noInsuranceExp && expTest ? true : false,
                      };
                      // record['isNoInsurance']=noInsurance;
                      record['weeklyVisibleDays'] = []
                      record['weeklyVisibleNumber'] = {
                        weekDay: 0,
                        weekNumber: 0
                      }
                      record['state'] = record.state.value;
                      record['test2Treat'] = record?.state === 'CA' ? true : false;
                      record['isQRCodeSend'] = false
                      record['isWeeklyFlag'] = false
                      record['isDayFilter'] = false
                      record['isWeekDay'] = false
                      record['isWeekEnd'] = false
                      record['isAssay'] = false;
                      record['isActive'] = true;
                      // record['cPartnerID']=record.cPartnerID.value;
                      record['country'] = record.country.value;
                      record['county'] = '';
                      delete record['visible']
                      delete record['data']
                      delete record['tenentTypes']
                      delete record['testtype']
                      delete record['slotsList']
                      delete record['type']
                      delete record['holidays']
                      locationData.slotNewDetails = []
                      if (update) {
                        const result = await updateLocationData(record)
                        if (result) {
                          setloader(true)
                          setloader(false)
                          setalertbox({ type: 'info', msg: 'Updated Successfully', open: true })
                        }
                        setTimeout(() => {
                          updateLocation({}, true);
                          resetForm();
                          history.push('/dashboard/locations');
                          window.location.reload();
                        }, 500);
                        return
                      } else {
                        await addLocationHandler(record);
                        return
                      }
                    }

                    if (step === 2 && !update) {
                      stepTwoSetup(record)
                      setStep(step + 1)
                    }
                    else {
                      record['slotConfig'] = slotsList;
                      record['holidayList'] = holidays;

                      console.log(slotsList);
                      if (!update) {
                        setsubmit(true);
                        await finalvalue(record, 'add').then((resdata: any) => {
                          if (resdata.status) {
                            setalertbox({ open: true, type: 'info', msg: resdata.msg })
                          }
                          else {
                            setalertbox({ open: true, type: 'error', msg: 'Something Wents Wrong' })
                          }
                        }).catch(err => {

                        });
                      }
                      else {
                        setsubmit(true);
                        await finalvalue(record, 'update', ID).then((resdata: any) => {
                          if (resdata.status) {
                            setalertbox({ open: true, type: 'info', msg: resdata.msg })
                          }
                          else {
                            setalertbox({ open: true, type: 'error', msg: 'Something Wents Wrong' })
                          }
                        }).catch(err => {
                        });
                      }
                      setloader(true)
                      setsubmit(false);
                      settraveltype(false);
                      setcheckweek(false);
                      setVisibleWeek(false);
                      settesttype(false);
                      updateLocation(record, true);
                      resetForm();
                      setloader(false);
                      setsubmitbtn(false);
                      setTimeout(() => {
                        history.push('/dashboard/locations');
                      }, 5000);
                    }
                  }
                }
                }
              >
                {(values: any) => {
                  return (
                    <Form>
                      {/* Add new location */}
                      {step === 1 && <>
                        <Typography variant="subtitle1" color="initial" className='top-title'>
                          {!update ? 'Add New' : 'Edit'}  Location
                        </Typography>

                        <br />
                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                          Location Name *
                        </Typography>
                        <Input name="name" value={values.values.name} onChange={nameFun} disabled={update}/>
                        {isNameErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                          Address 1 *
                        </Typography>
                        <Input name="address1" value={values.values.address1} onChange={addressFun} />
                        {isaddressErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                          Address 2
                        </Typography>
                        <Input name="address2" value={values.values.address2} />

                        <Grid container spacing={3} >
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingRight: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>City *</Typography>
                            <Input name='city' value={values.values.city} onChange={cityFun} />
                            {isCityErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>

                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingLeft: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>State *</Typography>
                            <Select name='state' onChange={statefun} options={state} />
                            {isStateErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingRight: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700} >Country *</Typography>
                            <Select name='country' onChange={countryFun} options={[{ label: 'United States', value: 'United States' }]} />
                            {isCountryErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>

                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingLeft: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                              CLIA
                            </Typography>
                            <Input name="CLIA" value={values.values.CLIA} type='text' />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingRight: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Zip Code *</Typography>
                            <Input name='zipcode' value={values.values.zipcode} onBlur={handleZip} onChange={zipFun} type='number'
                            onKeyDown={ (evt:any) => evt.key === 'e' && evt.preventDefault() } />
                            {zipCheck ? <ErrorContainer>{zipErr}</ErrorContainer> : ''}
                            {isZipErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingLeft: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Google Maps Shortlink *</Typography>
                            <Input name='locationlink' value={values.values.locationlink} onBlur={handleURl} onChange={(evt:any)=>locationFun(evt)} type='text' />
                            {}
                            {isMapErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : (urlErr ? <ErrorContainer>Invalid URL</ErrorContainer> : '')}
                          </Grid>
                        </Grid>


                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Select all flows that this location should be apart of *</Typography>
                        <Checkbox name='tenentTypes.travel' disabled={schBRSSD || schSUHSD || schSCSD || schSSFSD || schPVSD || travelVal } onChange={handleTrav} >Travel</Checkbox><br />
                        <Checkbox name='tenentTypes.general' disabled={schBRSSD || schSUHSD || schSCSD || schSSFSD || schPVSD || generalVal } onChange={handleGen}>General</Checkbox><br />
                        <Checkbox name='tenentTypes.school.BRSSD' disabled={trav || gen || com || sfo || travelVal || generalVal || communityVal || sfoVal} onChange={handleBRSSD}>School - Belmond-Redwood Shores School District</Checkbox><br />
                        <Checkbox name='tenentTypes.school.PVSD' disabled={trav || gen || com || sfo || travelVal || generalVal || communityVal || sfoVal} onChange={handlePVSD}>School - Portola Valley School District</Checkbox><br />
                        <Checkbox name='tenentTypes.school.SUHSD' disabled={trav || gen || com || sfo || travelVal || generalVal || communityVal || sfoVal} onChange={handleSUHSD}>School - Sequoia Union High School District</Checkbox><br />
                        <Checkbox name='tenentTypes.school.SSFSD' disabled={trav || gen || com || sfo || travelVal || generalVal || communityVal || sfoVal} onChange={handleSCSD}>School - South San Francisco Scool District</Checkbox><br />
                        <Checkbox name='tenentTypes.school.SCSD' disabled={trav || gen || com || sfo || travelVal || generalVal || communityVal || sfoVal} onChange={handleSSFSD}>School - San Carlos School District</Checkbox><br />
                        <Checkbox name='tenentTypes.community' disabled={schBRSSD || schSUHSD || schSCSD || schSSFSD || schPVSD || communityVal } onChange={handleCom}>Community</Checkbox><br />
                        <Checkbox name='tenentTypes.work' disabled={schBRSSD || schSUHSD || schSCSD || schSSFSD || schPVSD || sfoVal } onChange={handleSfo}>Work</Checkbox><br />
                        {isTenentErr ? <ErrorText>{errorTxt}</ErrorText> : ''}
                        <br />


                        {/* <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Travel Types Available *</Typography>
                            <Checkbox name='traveltypes.domestic' onChange={methodfortraveltype} >Domestic Travel</Checkbox><br />
                            <Checkbox name='traveltypes.international' onChange={methodfortraveltype}>International Travel</Checkbox><br /> */}
                        {/* <Checkbox name='traveltypes.cruises' onChange={methodfortraveltype}>Cruises</Checkbox><br />
                            <Checkbox name='traveltypes.events' onChange={methodfortraveltype}>Events</Checkbox><br /> */}
                        {/* {((submitbtn) && !(locationData?.traveltypes?.domestic || locationData?.traveltypes?.international)) ? (<span className={classes.errormsg}>&nbsp;Required field</span>) : ''}
                            <br /> */}




                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                          Medical Director
                        </Typography>
                        <Input name="medicalDirector" value={values.values.medicalDirector} />
                        <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                          Performing Facility
                        </Typography>
                        <Input name="performingfacility" value={values.values.performingfacility} />




                        {/* <Grid container spacing={3}>
                            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                                <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Accepts Insurence?</Typography>
                                <Select name='acceptance' options={[{ label: 'Yes', record: true }, { label: 'No', record: false }]} />
                            </Grid>*/}
                        {/* </Grid>  */}


                        {/* <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                                
                            

                            
                            {/* 
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Qbench Customer *</Typography>
                            <Select name='qbenchcustomer' options={customerDropdown} isRequired={true} />
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Default Assay *</Typography>
                            <Select name='qbenchassay' options={assayDropdown} isRequired={true} /> */}

                        {/* <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Which user groups can view this site? *</Typography>
                            <MultipleSelect names={[
                            'Worksite Labs',
                            'American Research Labs',
                            'ID Technology'
                            ]} setvisible={setvisible} errors={(submitbtn || visible.touch) ? locationData?.visible ? true : false : false} personName={personName} setPersonName={setPersonName} />
                            {(submitbtn || visible.touch) ? locationData?.visible ? false : true : false ? (<><div className={classes.errormsg2}>&nbsp;Required field</div></>) : ''} */}

                        {/* <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Available TestTypes *</Typography>
                            
                            <Checkbox name='testtype.standardTest' onChange={methodfortesttype}>Standard Testing </Checkbox><br />
                            <Checkbox name='testtype.expressTest' onChange={methodfortesttype}>Express Testing </Checkbox><br />
                            <Checkbox name='testtype.rapidTest' onChange={methodfortesttype}>Rapid Test </Checkbox><br />
                            <Checkbox name='testtype.antigenTest' onChange={methodfortesttype}>Antigen Test</Checkbox><br /> */}
                        {/* <Checkbox name='testtype.standardNpTest' onChange={methodfortesttype}>Standard Test (NP)</Checkbox><br />
                            <Checkbox name='testtype.expressNpTest' onChange={methodfortesttype}>ExpressTest (NP)</Checkbox><br />
                            <Checkbox name='testtype.rapidNpTest' onChange={methodfortesttype}>Rapid Test (NP)</Checkbox><br /> */}
                        <Typography
                          variant="subtitle1"
                          color="initial"
                          className={classes.fontweight700}
                        >
                          Site Status *
                        </Typography>
                        <Radio.Group name='sitestatus' onChange={() => setSiteStatus(!siteStatus)} value={siteStatus}>
                          <Radio value={true} className='radio-alt' >Open</Radio><br />
                          <Radio value={false} className='radio-alt'>Closed</Radio>
                          {/* {isSiteStatusErr ?  <ErrorText>{errorTxt}</ErrorText> : '' }   */}
                        </Radio.Group>
                        <br /> <br />


                        <Grid container spacing={3}>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingRight: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Delay Open Start Date</Typography>
                            <DatepickerField inputPlaceholder='' name='startDate' />

                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item style={{ paddingLeft: '25px' }}>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Visible Since *</Typography>
                            <DatepickerField onChange={visSinceFun} inputPlaceholder='' name='visibleSince' />
                            {isVisSinceErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Time Zone *</Typography>
                            <Select name='timezone' onChange={timezoneFun} options={timeZoneDropdown} />
                            {isTimeZoneErr ? <ErrorContainer>{errorTxt}</ErrorContainer> : ''}
                          </Grid>
                          {/* <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                            <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>
                            Client Parter ID *
                            </Typography>
                            <Select name='cPartnerID' onChange={cpartnerFun} options={cPartnerDropdown}  />
                            {isCpIdErr ?  <ErrorContainer>{errorTxt}</ErrorContainer> : '' }  
                            </Grid> */}
                        </Grid>

                        {(submitbtn) && !(locationData?.testtype?.expressTest || locationData?.testtype?.rapidTest || locationData?.testtype?.standardTest || locationData?.testtype?.antigenTest || locationData?.testtype?.standardNpTest || locationData?.testtype?.expressNpTest || locationData?.testtype?.rapidNpTest) ? (<span className={classes.errormsg}>&nbsp;Required field</span>) : ''}
                        <br />
                            {/* {console.log('ADexpress', expTest)} */}
                        <table style={{ minWidth: '1000px' }} className='tab'>
                          <tr>
                            <th><Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Available Test Types  *</Typography> </th>
                            <th></th>
                            <th><Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Allow Same Day Booking?</Typography></th>
                            <th></th>
                            <th><Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Credit Card</Typography></th>
                            <th><Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Insurance</Typography></th>
                            <th><Typography variant="subtitle1" color="initial" className={classes.fontweight700}>No Insurance</Typography></th>
                          </tr>
                          <tr>
                            <td>	<Typography variant="subtitle1" color="initial" style={{ width: '143px' }} className={classes.fontweight400}><span className='text-alt'>Standard Testing (24 Hrs)</span></Typography></td>


                            <td><Checkbox name='testtype.standardTest' onChange={handleStdChange} defaultChecked={stdTest} >{ }</Checkbox><br /></td>
                            <td><Checkbox name='isSameDay.standard' onChange={handleStdSdChange} defaultChecked={stdSdTest}>{ }</Checkbox><br /></td>
                            <td></td>

                            <td><Checkbox name='isCreditCard.standard' onChange={handleCreditCardStd} defaultChecked={creditCardStd} >{ }</Checkbox><br /></td>
                            <td><Checkbox name='isInsurance.standard' onChange={handleInsuranceStd} defaultChecked={insuranceStd}>{ }</Checkbox><br /></td>
                            <td><Checkbox name='isNoInsurance.standard' onChange={handleNoInsuranceStd} defaultChecked={noInsuranceStd}>{ }</Checkbox><br /></td>
                          </tr>
                          <tr>
                            <td><Typography variant="subtitle1" color="initial" style={{ width: '143px' }} className={classes.fontweight400}><span className='text-alt'>Express Testing (12 Hrs)</span></Typography></td>

                            <td> <Checkbox name='testtype.expressTest' onChange={handleExpChange} defaultChecked={expTest} >{ }</Checkbox><br /></td>
                            {/* {console.log('adExpress', expTest)} */}
                            <td> <Checkbox name='isSameDay.express' onChange={handleExpSdChange} defaultChecked={expSdTest} >{ }</Checkbox><br /></td>
                            <td></td>

                            <td>  <Checkbox name='isCreditCard.express' onChange={handleCreditCardExp} defaultChecked={creditCardExp}>{ }</Checkbox><br /></td>
                            <td> <Checkbox name='isInsurance.express' onChange={handleInsuranceExp} defaultChecked={insuranceExp}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isNoInsurance.express' onChange={handleNoInsuranceExp} defaultChecked={noInsuranceExp} >{ }</Checkbox><br /></td>
                          </tr>
                          <tr>
                            <td>	<Typography variant="subtitle1" color="initial" style={{ width: '143px' }} className={classes.fontweight400}><span className='text-alt'>Rapid Testing (1.5 Hrs) </span></Typography></td>

                            <td>  <Checkbox name='testtype.rapidTest' onChange={handleRapChange} defaultChecked={rapTest}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isSameDay.rapid' onChange={handleRapSdChange} defaultChecked={rapSdTest}>{ }</Checkbox><br /></td>
                            <td> </td>

                            <td>  <Checkbox name='isCreditCard.rapid' onChange={handleCreditCardRap} defaultChecked={creditCardRap}>{ }</Checkbox><br /></td>
                            <td>   <Checkbox name='isInsurance.rapid' onChange={handleInsuranceRap} defaultChecked={insuranceRap}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isNoInsurance.rapid' onChange={handleNoInsuranceRap} defaultChecked={noInsuranceRap}>{ }</Checkbox><br /></td>
                          </tr>
                          <tr>
                            <td>	<Typography variant="subtitle1" style={{ width: '143px' }} color="initial" className={classes.fontweight400}><span className='text-alt'>Antigen Testing (30 mins)</span></Typography></td>

                            <td>	<Checkbox name='testtype.antigenTest' onChange={handleAntChange} defaultChecked={antTest}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isSameDay.antigen' onChange={handleAntSdChange} defaultChecked={antSdTest}>{ }</Checkbox><br /></td>
                            <td> </td>

                            <td>  <Checkbox name='isCreditCard.antigen' onChange={handleCreditCardAnt} defaultChecked={creditCardAnt}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isInsurance.antigen' onChange={handleInsuranceAnt} defaultChecked={insuranceAnt}>{ }</Checkbox><br /></td>
                            <td>  <Checkbox name='isNoInsurance.antigen' onChange={handleNoInsuranceAnt} defaultChecked={noInsuranceAnt}>{ }</Checkbox><br /></td>
                          </tr>
                        </table>
                        {paymentErr ? <ErrorText>{paymentErrTxt}</ErrorText> : ''}
                        {isTestErr ? <ErrorText>{errorTxt}</ErrorText> : ''}
                        <br />
                        <Typography
                          variant="subtitle1"
                          color="initial"
                          className={classes.fontweight700}
                        >
                          Does this location provide Nasopharyngeal (NP) testing ?
                        </Typography>
                        <Radio.Group onChange={() => setIsNp(!isNp)} value={isNp}>
                          <Radio className='radio-alt' value={true}>Yes</Radio><br />
                          <Radio className='radio-alt' value={false}>No</Radio>
                        </Radio.Group>
                        <br /> <br />

                        {/* <Typography variant="subtitle1" color="initial" className={classes.fontweight700}>Payment Methods Accepted *</Typography>

                            <Grid container spacing={3}>
                            <PaymentMethodContainer>
                                <Typography variant="subtitle1" color="initial" style={{width :'140px'}} className={classes.fontweight400}>Credit Card </Typography>
                                <ToggleButtonGroup color="primary" value={creditCard} exclusive onChange={handleCred } >
                                <ToggleButton className='btn-alt btn-common' value={false} color="primary">off</ToggleButton>
                                <ToggleButton className='btn-alter btn-common' value={true} color="primary">on</ToggleButton>
                                </ToggleButtonGroup>
                            </PaymentMethodContainer>
                            <PaymentMethodContainer>
                                <Typography variant="subtitle1" color="initial" style={{width :'140px'}} className={classes.fontweight400}>Insurance  </Typography>
                                <ToggleButtonGroup color="primary" value={insurance} exclusive onChange={handleIns} >
                                <ToggleButton className='btn-alt btn-common' value={false}>off</ToggleButton>
                                <ToggleButton className='btn-alter btn-common' value={true}>on</ToggleButton>
                                </ToggleButtonGroup>
                            </PaymentMethodContainer>
                            <PaymentMethodContainer>
                                <Typography variant="subtitle1" color="initial" style={{width :'140px'}} className={classes.fontweight400}>No Insurance  </Typography>
                                <ToggleButtonGroup color="primary" value={noInsurance} exclusive onChange={handleNoIns} >
                                <ToggleButton className='btn-alt btn-common' value={false}>off</ToggleButton>
                                <ToggleButton className='btn-alter btn-common' value={true}>on</ToggleButton>
                                </ToggleButtonGroup>
                            </PaymentMethodContainer>
                            </Grid>
                            {isPayErr ?  <ErrorText>{errorTxt}</ErrorText> : '' }  
                            <br /><br /> */}


                        <ButtonContainer>
                          <Returnbtn className='btn-common' aria-disabled={loading} onClick={handleReset}> Cancel</Returnbtn>
                          <Space />
                          <Confirmbtn className='btn-common btn-alter Mui-selected' disabled={loading} type="submit">{loading ? 'Processing...' : !update ? 'Next' : 'Update'} </Confirmbtn>
                        </ButtonContainer>

                      </>}

                      {step === 2 && <QBenchSetupPage assayDropdown={assayDropdown} qbenchId={qbenchId} assayId={assayId} rapidAssayId={rapidAssayId} antigenAssayId={antigenAssayId} customerDropdown={customerDropdown} onBack={() => onBackClickHandler()} onNext={() => onNextClickHanlder()} />}
                      {step === 3 && <>
                        <Editlocation isNewLocation={true} testTypeDropdownNew={testTypeDropdownNew} slotsHandle={slotsHandle} counter={counter} defaultTest={defaultTest} onBack={() => onBackClickHandler()} onNext={() => onNextClickHanlder()} />
                        {/* <button type='submit' onClick={()=>{setsubmitbtn(true)}} >{!update?'conform':'update'} </button> */}
                      </>}
                      {step === 4 && <>
                        <Holidays isNewLocation={true} onBack={() => onBackClickHandler()} onNext={() => addLocationFinal()} />
                        {/* <button type='submit' onClick={()=>{setsubmitbtn(true)}} >{!update?'conform':'update'} </button> */}
                      </>}
                    </Form>
                  )
                }}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
}
