import { fullNameValidation, isValidEmail, password } from '../../../../validations';

export const addNewUser = [
    {
        label: 'Full Name',
        required: true,
        hidden: false,
        type: 'text',
        name: 'name',
        edit:true,
    },
    {
        label: 'Email ID',
        required: true,
        hidden: false,
        type: 'text',
        name: 'email',
        edit:true,
    },
    // {
    //     label: 'Password',
    //     required: true,
    //     hidden: false,
    //     type: 'text',
    //     edit:false,
    //     name: 'password'
    // },
    // {
    //     label: 'Re-enter Password',
    //     required: true,
    //     hidden: false,
    //     type: 'password',
    //     name: 'repassword',
    //     edit:false,
    //     checkPassword:true
    // },
    {
        label: 'Assign Location',
        required: true,
        hidden: false,
        type: 'select',
        name: 'location',
        edit:true,
    }
]
export const validForm = (key:string, form:any,flag:any=false)=>{
    if(flag)
    {
        if(!form?.[key])
            return "Required*";
    }
    if(key === 'name' && form[key] && !fullNameValidation.test(form[key]))
        return 'Please Enter First Name and Last Name';
    if( key === 'repassword' && form[key] && (form[key] !== form['password']) )
        return 'Password mismatch';
    if( key === 'email' && form[key] && !isValidEmail.test(form[key]))
        return 'Invalid Email';
    if( key === 'password' && form[key] && !password.test(form[key]))
        return 'Password must contain 1 uppercase, 1 lowercase, 1 special and 1 numeric character and length should be more than 8 characters';
    if(!form?.[key])
        return "Required*";
}