import React, { useState } from 'react'
import { Button, Table, Layout } from 'antd';
import Title from 'antd/lib/typography/Title';
import { specialViewUsers } from '../../components/AppointmentList/constants';
import FormModal from './Modal';
import { useSharedState, useSharedDispatch } from '../../../shared/provider';
import { apiCallForDayWiseUsers } from './API';
import { AiFillEdit } from 'react-icons/ai'
const { Content } = Layout;
const OutSideAdministrator= () => {
    let { daywiseUsers } = useSharedState();
    const {setDaywiseUsers} = useSharedDispatch()
    const [openModal, setOpenModal] = useState<{open:boolean, title:string, okButton:string, id:string}>({open:false,title:'', okButton:'', id:''});
    const [loading, setLoading] = useState(false)
    const toggleModal = (data?:string, okButton?:string, id?:string) => setOpenModal(state=>({ ...state, open:!state.open, title:data ?? '', okButton:okButton ?? '', id: id ? id : ''}));
    const action = (action: any, payload: any) =>(<AiFillEdit style={{fontSize:25, cursor:'pointer'}} onClick={()=>toggleModal('Edit User','Edit',payload?.id ?? '')}/>);
    const Switch = (action: any, payload: any) => (<p>{payload?.isActive ? 'Active' : 'Inactive' }</p>);
    const editAccess = (action: any, payload: any) => (<p>{payload?.isEditAccess ? 'True' : 'False' }</p>);
    const isWeeklyTesting = (action: any, payload: any) => (<p>{(payload?.allAppointments && payload.allSchedulerLite) ? 'All Appointments' : payload.allSchedulerLite ? 'All Scheduler Lite Appointments' :  'Only Weekly Testing Appointments' }</p>);
    const locationName = (action: any, payload: any) => payload?.locationName?.map((e:string)=><div>{e}</div>);
    const getAllUsers = async()=>{
        setLoading(true);
        const response = await apiCallForDayWiseUsers({type:'get'})
        setDaywiseUsers(response?.data?.filter((e:any)=>e?.email) ?? [])
        setLoading(false);
    }
    React.useEffect(() => {
        getAllUsers();
    }, [])
    
  return (

    <Layout
		style={{
		  marginTop: '100px',
		  backgroundColor: '#FFF',
		  border: '1px solid #f3eeee',
		  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
		}}
	  >
		<div
		  style={{
			borderRadius: '3px',
			background: 'linear-gradient(60deg, #00acc1, #00acc1)',
			boxShadow:
			  '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
			margin: '0px 25px',
			marginTop: '-25px',
		  }}
		>
		  <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
            Special Day Wise Users
		  </Title>
		</div>
        <Content style={{margin:30}}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    margin: '20px 0px',
                }}
            >
                <Button
                    type="primary"
                    style={{ height: '40px' }}
                    onClick={()=>toggleModal('Add Users', 'Add User','')}
                >
                    Add New User
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Table
                    size="middle"
                    columns={specialViewUsers(Switch, action,locationName,editAccess,isWeeklyTesting) as any}
                    bordered
                    dataSource={ daywiseUsers ?? []}
                    loading={loading}
                    pagination={false}
                    rowKey={(row: any) => row?.id}
                />
            </div>
            {openModal?.open && <FormModal
                open={openModal?.open}
                closeModal={toggleModal}
                title={openModal?.title}
                okBtn={openModal.okButton}
                id={openModal.id}
            /> }
        </Content>
	  </Layout>
  )
}

export default OutSideAdministrator;