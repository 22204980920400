import React, {
    useCallback,
    useState,
    createContext,
    useMemo,
    useEffect,
} from 'react';
import { User } from 'firebase/app';
import { format, parse, compareAsc, compareDesc } from 'date-fns';

import {
    AdminState,
    AdminDispatch,
    AppointmentListItem,
    Comparables,
    AppointmentsListParams,
    ConsolidationParams,
    Appointment,
} from './models';
import { SortByDirection, FieldType } from './dictionaries';
import { firebase } from '../../firebase';
import { config } from '../../config';
import { AdminManagePage } from './Pages/Manage/dictionaries';
import { getPrices } from '../shared/providers/schedule/api';
// import { getSlotsConfigurations } from './api';
import { AnyObject } from '../shared/models';
import { SetPrices } from '../../utils';
import { steps } from '../../../src/modules/admin/Pages/LocationAndService/Locationstep';
import Holidays from './Pages/LocationAndService/Holidays';
import { getAllClientPartner } from './Pages/SchedularLite/API';

const initJotForm = {
    jotFormUrl: '',
    location: '',
    formName: '',
    qBenchCustomer: '',
    mappedFields: [],
    createdAt: new Date(),
};

export const AdminContext = createContext<AdminState>({
    params: {
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        // date: format(new Date(), config.dateFormat),
        date: '',
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments: '',
    },
    checkinParams: {
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: format(new Date(), config.dateFormat),
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments: '',
    },
    appointments: null,
    Editappointments: null,
    sortBy: null,
    sortByDirection: SortByDirection.Ascending,
    user: null,
    configured: false,
    adminSlots: [],
    currentManagePage: AdminManagePage.ManageScreen,
    appointmentList: null,
    manageAppointment: null,
    apptPricesAdmin: {
        rapid: 0,
        standard: 0,
        expedited: 0,
        antigen: 0,
    },
    showStandradTestAdmin: false,
    showAdminLocationModal: false,
    isLoginError: false,
    getAllClientPartners: [],
    getID: null,
    userRole: '',
    editLocation: null,
    GetServiceData: [],
    locationData: {
        traveltypes: {
            international: false,
            cruises: false,
            events: false,
            domestic: false,
        },
        testtype: {
            standard: false,
            express: false,
            rapid: false,
            antigen: false,
            standardNp: false,
            expressNp: false,
            rapidNp: false,
        },
        weeksofoperation: {
            tue: false,
            mon: false,
            sun: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
        },
        locationname: '',
        cPartnerID: {},
        address1: '',
        address2: '',
        visibleSince: '',
        medicalDirector: '',
        country: { label: '', value: '' },
        state: { label: '', value: '' },
        city: '',
        zipcode: '',
        googlemapshortlink: '',
        sitestatus: { label: '', value: '' },
        delayopenstartdate: '',
        acceptance: { label: '', value: '' },
        slotcapacity: { label: '', value: '' },
        fromhourofoperation: { label: '', value: '' },
        tohourofoperation: { label: '', value: '' },
        qbenchcustomer: '',
        qbenchassay: { label: '', value: '' },
        visible: [],
        weeklyVisibleDay: {
            tue: false,
            mon: false,
            sun: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
        },
        dayFromWeeklyVisibleNumber: '',
        weekNumber: '',
        tenentTypes: {
            travel: false,
            general: false,
            school: false,
            community: false,
            work: false,
        },
        holidays: [],
        blockOutDays: [],
    },
    getAllUserRoles: '',
    getUserID: '',
    getPrintSampleLabels: '',
    getPrintID: '',
    jotForm: initJotForm,
    step: 0,
    IsMinorAccount: false,
    minorEmail: '',
    insuranceAccess: false,
    toggle: false,
    cPartner: [],
});

export const AdminDispatchContext = createContext<AdminDispatch>({
    updateParams() {},
    updateCheckinParams() {},
    setAppointments() {},
    sortTable() {},
    setUser() {},
    updateAppointmentInStore() {},
    reportParams() {},
    setEditAppointment() {},
    updateAdminSlots() {},
    goToPage() {},
    updateResultsScreen() {},
    updateUserRole() {},
    updateAdminManageAppointment() {},
    updateStandradTestAdmin() {},
    toggleAdminLocationModal() {},
    updateLoginError() {},
    SetAllClientPartner() {
        return false;
    },
    SetID() {},
    setLocation() {},
    setAccess() {},
    SetServiceData() {},
    updateLocation() {},
    SetAllUserRole() {},
    SetUserID() {},
    SetPrintSampleLabel() {},
    SetPrintID() {},
    updateJotFormValues() {},
    goToNextStep() {},
    goToPrevStep() {},
    goToInitialStep() {},
    setMinorAccount() {},
    setMinorEmail() {},
    setToggleData() {},
});

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
    const [params, setParams] = useState<AppointmentsListParams>({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        // date: format(new Date(), config.dateFormat),
        date: '',
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments:'',
    });
    const [checkinParams, setCheckinParams] = useState<AppointmentsListParams>({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: format(new Date(), config.dateFormat),
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments:''
    });

    const [reportparams, setreportParams] = useState<ConsolidationParams>({
        appttime: '',
        results: '',
        location: '',
        fromdate: '',
        todate: '',
    });

    const [appointments, setAppointments] = useState<
        AppointmentListItem[] | null
    >(null);

    const [Editappointments, setEditAppointment] = useState<
        Appointment[] | null
    >(null);

    const [insuranceAccess,setInsuranceAccess]=useState(false)
    const [toggle,setToggle]=useState(false)
    const [locationData, setlocationData] = useState<any>({
      data:{         
        traveltypes: {
            international: false,
            cruises: false,
            events: false,
            domestic: false,
        },
        testtype: {
            standard: false,
            express: false,
            rapid: false,
            antigen: false,
            standardNp: false,
            expressNp: false,
            rapidNp: false,
        },
        weeksofoperation: {
            tue: false,
            mon: false,
            sun: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
        },
        locationname: '',
        cPartnerID: {},
        address1: '',
        address2: '',
        visibleSince: '',
        medicalDirector: '',
        country: { label: '', value: '' },
        state: { label: '', value: '' },
        city: '',
        zipcode: '',
        // googlemapshortlink: '',
        sitestatus: { label: '', value: '' },
        delayopenstartdate: '',
        acceptance: { label: '', value: '' },
        slotcapacity: { label: '', value: '' },
        fromhourofoperation: { label: '', value: '' },
        tohourofoperation: { label: '', value: '' },
        qbenchcustomer: '',
        qbenchassay: { label: '', value: '' },
        visible: [],
        weeklyVisibleDay: {
            tue: false,
            mon: false,
            sun: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
        },
        dayFromWeeklyVisibleNumber: '',
        weekNumber: '',
        tenentTypes: {
            travel: false,
            general: false,
            school: false,
            community: false,
            work: false,
        },
        holidays: [],
        blockOutDays: [],
    
    },
      slotsList:{
        locationId: null,
        slotDetails: [] ,
        testType:'',
      },
      holidays: [],
      slotNewDetails: []
    });
    const [sortBy, setSortBy] = useState<keyof AppointmentListItem | null>(
        null
    );
    const [sortByDirection, setSortByDirection] = useState<SortByDirection>(
        SortByDirection.Ascending
    );
    const [user, setUser] = useState<User | null>(null);
    const [configured, setConfigured] = useState(false);

    const [adminSlots, setSlots] = useState<any>([]);

    // const [appointments, setReportFilter] = useState<ConsolidationParams[] | null>(null);

    //Admin Manage Screen changes
    const [currentManagePage, setCurrentManagePage] = useState(
        AdminManagePage.ManageScreen
    );
    const [appointmentList, setAppointmentList] = useState<any>(null);
    const [
        manageAppointment,
        setAdminManageAppointment,
    ] = useState<Appointment | null>(null);
    const [apptPricesAdmin, setPrices] = useState({
        rapid: 0,
        standard: 0,
        expedited: 0,
        standard_INT: 0,
        expedited_INT: 0,
        rapid_INT: 0,
        antigen: 0,
        antigen_INT: 0,
    });
    const [showStandradTestAdmin, setShowStandradTestAdmin] = useState(false);
    const [showAdminLocationModal, setshowAdminLocationModal] = useState(false);

    const [isLoginError, setLoginError] = useState(false);
    const [getAllClientPartners, setAllClientPartners] = useState([]);
    const [getID, setClientPartnerID] = useState(null);
    const [userRole, setUserRole] = useState('');
    const [editLocation, setEditLocation] = useState(null);
    const [GetServiceData, setServiceData] = useState([]);
    const [step, setStep] = useState(0);

    const [getAllUserRoles, setAllUserRoles] = useState([]);
    const [getUserID, setUserRoleID] = useState(null);
    const [getPrintSampleLabels, setPrintSampleLabels] = useState([]);
    const [getPrintID, setPrintSampleID] = useState(null);
    const [jotForm, setJotForm] = useState(initJotForm);
    const [IsMinorAccount, setIsMinorAccount] = useState<boolean>(false);
    const [minorEmail, setminorEmail] = useState<string>('');
    const [newLocation, setnewLocation] = useState<any>('');
    let [cPartner, setCpartner] = useState([])

    useEffect(() => {
        const clientPartner = async () => {
          let cPartner: any = [];
          let data: any = await getAllClientPartner();
    
          let clientPartner = data.filter((e: any, i: any) => e.name !== '' && e.name !== undefined).map((e: any) => {
            cPartner.push({ value: e.cPartnerID, label: e.name })
            return e
          });
          // making unique Objects
          let uniqueData:any = cPartner.filter((ele: any, index: any) => cPartner.findIndex((obj: any) => obj.value === ele.value) === index)
          // HardCoding the Worksite Labs - General Label to Worksite Labs - General & Travel
          let hardCode: any = uniqueData.map((e: any) => e.value === 'WSL001' ? { ...e, label: 'Worksite Labs - General & Travel' } : e).sort((a: any, b: any) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
    
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          setCpartner(hardCode);
        }
        clientPartner();
      }, [])
    useEffect(() => {
        getPrices().then((result) => {
            const newPrices = {
                ...apptPricesAdmin,
            };
            result.data.data.forEach((price: any) => {
                if (price.product === config.products.rapid) {
                    newPrices.rapid = price.unit_amount / 100;
                }
                if (price.product === config.products.standard) {
                    newPrices.standard = price.unit_amount / 100;
                }
                if (price.product === config.products.expedited) {
                    newPrices.expedited = price.unit_amount / 100;
                }
                if (price.product === config.products.antigen) {
                    newPrices.antigen = price.unit_amount / 100;
                }
                if (price.product === config.products.rapid_INT) {
                    newPrices.rapid_INT = price.unit_amount / 100;
                }
                if (price.product === config.products.standard_INT) {
                    newPrices.standard_INT = price.unit_amount / 100;
                }
                if (price.product === config.products.expedited_INT) {
                    newPrices.expedited_INT = price.unit_amount / 100;
                }
                if (price.product === config.products.antigen_INT) {
                    newPrices.antigen_INT = price.unit_amount / 100;
                }
            });
            setPrices(newPrices);
        });

        // getSlotsConfigurations().then((result) => {
        //     setSlots(result.data);
        // });

        const unsubscribe = firebase
            .auth()
            .onAuthStateChanged(async (firebaseUser: any) => {
                if (firebaseUser) {
                    setUser(firebaseUser);
                }

                setConfigured(true);
            });

        return () => unsubscribe();
    }, [setUser]);

    const updateParams = useCallback(
        (update: AppointmentsListParams) => {
            setParams((currentParams) => ({
                ...(currentParams || {}),
                ...update,
            }));
        },
        [setParams]
    );

    const updateCheckinParams = useCallback(
        (update: AppointmentsListParams) => {
            setCheckinParams((currentParams) => ({
                ...(currentParams || {}),
                ...update,
            }));
        },
        [setCheckinParams]
    );

    const reportParams = useCallback(
        (update: ConsolidationParams) => {
            setreportParams((currentParams) => ({
                ...(currentParams || {}),
                ...update,
            }));
        },
        [setreportParams]
    );

    const sortTable = (columnName: keyof AppointmentListItem) => {
        let direction: SortByDirection = SortByDirection.Ascending;

        if (sortBy === columnName) {
            direction =
                sortByDirection === SortByDirection.Ascending
                    ? SortByDirection.Descending
                    : SortByDirection.Ascending;
        }

        setSortBy(columnName);
        setSortByDirection(direction);
    };

    const goToNextStep = useCallback(() => {
        setStep((s: number) => {
            if (s + 1 <= steps.length - 1) {
                return s + 1;
            }

            return s;
        });
    }, [setStep]);

    const goToPrevStep = useCallback(() => {
        setStep((s: number) => {
            if (s - 1 >= 0) {
                return s - 1;
            }

            return s;
        });
    }, [setStep]);

    const goToInitialStep = useCallback(() => {
        setStep((s: number) => {
            return 0;
        });
    }, [setStep]);

    const sortAppointments = (
        appointments: AppointmentListItem[] | null,
        sortBy: keyof AppointmentListItem | null,
        sortByDirection: SortByDirection
    ) => {
        if (
            sortBy !== null &&
            appointments !== null &&
            appointments.length > 0
        ) {
            const compare = comparables[sortBy] || { type: FieldType.Text };

            appointments?.sort((a, b) => {
                if (compare.type !== FieldType.Date) {
                    let aval: string = String(a[sortBy]).toUpperCase();
                    let bval: string = String(b[sortBy]).toUpperCase();
                    if (aval < bval) {
                        return sortByDirection === SortByDirection.Ascending
                            ? -1
                            : 1;
                    }
                    if (aval > bval) {
                        return sortByDirection === SortByDirection.Ascending
                            ? 1
                            : -1;
                    }
                    return 0;
                } else {
                    const aDate = parse(
                        a[sortBy] as string,
                        compare?.data?.dateFormat,
                        new Date()
                    );
                    const bDate = parse(
                        b[sortBy] as string,
                        compare?.data?.dateFormat,
                        new Date()
                    );
                    return sortByDirection === SortByDirection.Ascending
                        ? compareAsc(aDate, bDate)
                        : compareDesc(aDate, bDate);
                }
            });
        }
        return appointments;
    };

    const updateAdminSlots = useCallback(
        (update: any) => {
            setSlots(update);
        },
        [setSlots]
    );

    const updateAppointmentInStore = (
        firebaseId: string,
        minorIndex: number | null,
        update: Partial<AppointmentListItem>
    ) => {
        setAppointments((appts) => {
            if (appts === null) {
                return null;
            }

            const index = appts.findIndex(
                (appt) =>
                    appt.id === firebaseId && appt.minorIndex === minorIndex
            );
            appts[index] = {
                ...appts[index],
                ...update,
            };

            return appts;
        });
    };

    const goToPage = useCallback(
        (newPage: AdminManagePage) => {
            setCurrentManagePage(newPage);
        },
        [setCurrentManagePage]
    );

    //Admin Manage screen handuler
    const updateResultsScreen = useCallback(
        (appointmentList: any) => setAppointmentList(appointmentList),
        [setAppointmentList]
    );

    const updateAdminManageAppointment = useCallback(
        (update: any) => {
            setAdminManageAppointment({
                ...manageAppointment,
                ...update,
            });
        },
        [setAdminManageAppointment]
    );

    const updateStandradTestAdmin = useCallback(
        (show: any) => {
            setShowStandradTestAdmin(show);
        },
        [setShowStandradTestAdmin]
    );

    const toggleAdminLocationModal = useCallback(
        (show: boolean) => {
            setshowAdminLocationModal(show);
        },
        [setshowAdminLocationModal]
    );
    const updateUserRole = useCallback(
        (update: any) => {
            setUserRole(update);
        },
        [setUserRole]
    );
    const updateLoginError = useCallback(
        (show: boolean) => {
            setLoginError(show);
        },
        [setLoginError]
    );
    const SetAllClientPartner = useCallback(
        (data: any) => {
            setAllClientPartners(data);
        },
        [setAllClientPartners]
    );
    const SetID = useCallback(
        (data: any) => {
            setClientPartnerID(data);
        },
        [setClientPartnerID]
    );
    const setLocation = useCallback(
        (data: any) => {
            setEditLocation(data);
        },
        [setEditLocation]
    );

    const setAccess = useCallback(
        (data: any) => {
            setInsuranceAccess(data);
        },
        [setInsuranceAccess]
    );

    const setToggleData = useCallback(
        (data: any) => {
            setToggle(data);
        },
        [setToggle]
    );

    const SetServiceData = useCallback(
        (data: any) => {
            setServiceData(data);
        },
        [setServiceData]
    );

    const updateLocation = (data: any, reset: boolean = false) => {
        if (!reset) {
            let newdata = {  ...locationData,...data };
            // console.log('admin provider line 419', newdata);
            setlocationData(newdata);
        } else {
            setlocationData({
                traveltypes: {
                    international: false,
                    cruises: false,
                    events: false,
                    domestic: false,
                },
                testtype: {
                    standard: false,
                    express: false,
                    rapid: false,
                    antigen: false,
                    standardNp: false,
                    expressNp: false,
                    rapidNp: false,
                },
                weeksofoperation: {
                    tue: false,
                    mon: false,
                    sun: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                },
                locationname: '',
                cPartnerID: '',
                address1: '',
                address2: '',
                visibleSince: '',
                medicalDirector: '',
                country: { label: '', value: '' },
                state: { label: '', value: '' },
                city: '',
                zipcode: '',
                // googlemapshortlink: '',
                sitestatus: { label: '', value: '' },
                delayopenstartdate: '',
                acceptance: { label: '', value: '' },
                slotcapacity: { label: '', value: '' },
                fromhourofoperation: { label: '', value: '' },
                tohourofoperation: { label: '', value: '' },
                qbenchcustomer: '',
                qbenchassay: { label: '', value: '' },
                visible: [],
                weeklyVisibleDay: {
                    tue: false,
                    mon: false,
                    sun: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                },
                dayFromWeeklyVisibleNumber: '',
                weekNumber: '',
                tenentTypes: {
                    travel: false,
                    general: false,
                    school: false,
                    community: false,
                    work: false,
                },
                holidays: [], 
                blockOutDays: [],
            });
        }
    };

    const SetAllUserRole = useCallback(
        (data: any) => {
            setAllUserRoles(data);
        },
        [setAllUserRoles]
    );
    const SetUserID = useCallback(
        (data: any) => {
            setUserRoleID(data);
        },
        [setUserRoleID]
    );
    const SetPrintSampleLabel = useCallback(
        (data: any) => {
            setPrintSampleLabels(data);
        },
        [setPrintSampleLabels]
    );
    const SetPrintID = useCallback(
        (data: any) => {
            setPrintSampleID(data);
        },
        [setPrintSampleID]
    );

    const updateJotFormValues = useCallback(
        (update: AnyObject) => {
            setJotForm((f) => ({
                ...f,
                ...update,
            }));
        },
        [setJotForm]
    );
    const setMinorAccount = useCallback((data: boolean) => {
        setIsMinorAccount(data);
    }, []);
    const setMinorEmail = useCallback((data: string) => {
        setminorEmail(data);
    }, []);

    const setNewLocationData = useCallback((data: string) => {
        setnewLocation(data);
    }, []);

    const store = useMemo(
        () => ({
            params,
            checkinParams,
            appointments: sortAppointments(
                appointments,
                sortBy,
                sortByDirection
            ),
            sortBy,
            sortByDirection,
            user,
            configured,
            Editappointments,
            adminSlots,
            currentManagePage,
            appointmentList,
            manageAppointment,
            apptPricesAdmin,
            showStandradTestAdmin,
            showAdminLocationModal,
            isLoginError,
            getAllClientPartners,
            getID,
            userRole,
            editLocation,
            GetServiceData,
            locationData,
            getAllUserRoles,
            getUserID,
            getPrintSampleLabels,
            getPrintID,
            jotForm,
            IsMinorAccount,
            minorEmail,
            step,
            insuranceAccess,
            toggle,
            cPartner,
        }),
        [
            params,
            checkinParams,
            locationData,
            appointments,
            sortBy,
            sortByDirection,
            user,
            configured,
            Editappointments,
            adminSlots,
            currentManagePage,
            appointmentList,
            manageAppointment,
            apptPricesAdmin,
            showStandradTestAdmin,
            showAdminLocationModal,
            isLoginError,
            getAllClientPartners,
            getID,
            GetServiceData,
            getAllUserRoles,
            getUserID,
            getPrintSampleLabels,
            getPrintID,
            jotForm,
            IsMinorAccount,
            minorEmail,
            step,
            insuranceAccess,
            toggle,
            cPartner,
        ]
    );

    const dispatch = useMemo(
        () => ({
            updateParams,
            updateCheckinParams,
            goToNextStep,
            goToPrevStep,
            setAppointments,
            sortTable,
            setUser,
            updateAppointmentInStore,
            reportParams,
            setEditAppointment,
            updateAdminSlots,
            goToPage,
            updateResultsScreen,
            updateAdminManageAppointment,
            updateStandradTestAdmin,
            toggleAdminLocationModal,
            updateLoginError,
            SetAllClientPartner,
            SetID,
            updateUserRole,
            setLocation,
            setAccess,
            SetServiceData,
            updateLocation,
            SetAllUserRole,
            SetUserID,
            SetPrintSampleLabel,
            SetPrintID,
            updateJotFormValues,
            setMinorAccount,
            setMinorEmail,
            goToInitialStep,
            setNewLocationData,
            setToggleData,
        }),
        [
            sortBy,
            sortByDirection,
            updateLocation,
            goToPage,
            updateResultsScreen,
            updateAdminManageAppointment,
            updateStandradTestAdmin,
            toggleAdminLocationModal,
            updateLoginError,
            SetAllClientPartner,
            SetID,
            SetServiceData,
            SetAllUserRole,
            setUserRoleID,
            SetPrintSampleLabel,
            SetPrintID,
            updateJotFormValues,
            setMinorAccount,
            updateAdminSlots,
            setMinorEmail,
            goToNextStep,
            goToPrevStep,
            goToInitialStep,
            setNewLocationData,
            setAccess,
            setToggleData,
        ] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <AdminContext.Provider value={store}>
            <AdminDispatchContext.Provider value={dispatch}>
                {children}
            </AdminDispatchContext.Provider>
        </AdminContext.Provider>
    );
};

export const useAdminState = () => {
    const context = React.useContext(AdminContext);

    if (typeof context === 'undefined') {
        throw new Error(
            '`useAdminState` hook must be used within a `Provider` component'
        );
    }

    return context;
};

export const useAdminDispatch = () => {
    const context = React.useContext(AdminDispatchContext);

    if (typeof context === 'undefined') {
        throw new Error(
            '`useAdminDispatch` hook must be used within a `Provider` component'
        );
    }

    return context;
};

const comparables: Comparables = {
    birthDate: {
        type: FieldType.Date,
        data: {
            dateFormat: config.dateFormat,
        },
    },
    departureDateAndTime: {
        type: FieldType.Date,
        data: {
            dateFormat: config.dateTimeFormat,
        },
    },
};

export const useMediaQuery = (query: string) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = (e: any) => setMatches(e.matches);
        mediaMatch.addEventListener('change', handler);
        return () => mediaMatch.removeEventListener('change', handler);
    });
    return matches;
};
