import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../providers/schedule/provider';
import { Button } from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';

import Modal from '../../shared/components/Modal';
import LocationButtonGroup from '../../shared/components/form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import { useManageDispatch, useManageState } from '../providers/manage/provider';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const LocationModal: React.FC = () => {
    //   const {
    //     form: { location, slot, isExpressSameDayTest },
    //     showChangeLocationModal,
    //   } = useStepsState();
    const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
    const { showLocation, appointment } = useManageState();
    const Location = appointment?.location
    const { toggleLocation, updateAppointment } = useManageDispatch();
    const closeModal = () => toggleLocation(false);
    const rollbackLocation = useMemo(() => cloneDeep(Location), [showLocation]);
    // const rollbackSlot = useMemo(() => cloneDeep(appointment?.slot), [showChangeLocationModal]);
    const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(appointment?.isExpressSameDayTest), [showLocation]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(appointment?.isRapidTest), [showLocation]);

    return (
        <Modal open={showLocation} onClose={closeModal}>
            <Formik
                initialValues={{ Location }}
                onSubmit={() => {
                    //   updateFormValues({ update: location });
                    //   updateFormValues({ update: slot });
                    // updateAppointment({ update: Location })
                    // if (appointment?.location?.hasVipSlots) {
                    //     updateFormValues({ isExpressSameDayTest: true });
                    // }
                    // else {
                    //     updateFormValues({ isExpressSameDayTest: false });
                    // }
                    closeModal();
                }}
            >
                {() => (
                    <Form>
                        <Content>
                            <Title>Change location</Title>
                            <LocationButtonGroup isManage={true} />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        updateFormValues({ location: rollbackLocation, isExpressSameDayTest: rollbackisExpressSameDayTest, isRapidTest: rollbackisRapidTest});
                                        closeModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button libraryType="primary" size="sm" type="submit">
                                    Change
                                </Button>
                            </ButtonsContainer>
                        </Content>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default LocationModal;
