import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Wrapper, Container, Content } from './styled';
import { Location } from '../models';
import Tab from "@material-ui/core/Tab";
import InputLabel from "@material-ui/core/InputLabel";

import InsertInvitation from "@material-ui/icons/InsertInvitation";
import Button from "@material-ui/core/Button";
import {
  Typography,
} from 'antd';

import GridItem from "../../admin/components/AppointmentList/GridItem";
import GridContainer from "../../admin/components/AppointmentList/GridContainer";
import CustomTabs from "../../admin/components/AppointmentList/CustomTabs";
import CardHeader from "../../admin/components/AppointmentList/Card/CardHeader";
import CardBody from "../../admin/components/AppointmentList/Card/CardBody";
import Card from "../../admin/components/AppointmentList/Card/Card.js";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getOpenedLocations,
  downloadAllReports,
  getAllAppointmentsCreatedOnDate,
  generateAllAppointmentsReportNew,
  getMysqlGenerateAppointmentReports
} from '../api';
import { truncateSync } from 'fs';
import Loader from '../components/Loader';
import { generateAllAppointmentsReport, generateAppointmentsReport } from '../../admin/api';

const { Title } = Typography;


const ButtonWrapper = styled.div`
  margin-bottom: 15px;
  max-width: 1200px;
`;

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typo: {
      paddingLeft: "35%",
      marginBottom: "40px",
      position: "relative"
    },
    note: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#c0c1c2",
      display: "block",
      fontSize: "13px",
      lineHeight: "13px",
      left: "0",
      marginLeft: "20px",
      position: "absolute",
      width: "260px"
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  }),
);

// const useStyles = makeStyles(styles);

const Report: React.FC = () => {
  const classes = useStyles();
  const [locations, setLocations] = useState<Location[] | null>(null);

  useEffect(() => {
    getOpenedLocations().then((loc) => {
      console.log("setLocations", loc)
      setLocations(loc);
    });
  }, []);

  if (!locations) {
    return <Loader />;
  }

  return (

    <Wrapper>

      {/* <div style={{ backgroundColor: '#fff' }}>
        <Title level={4} style={{ margin: '24px 16px' }}>
          Report
        </Title>
      </div> */}
      <Card>
        <CardHeader color="primary">
          <h2 className={classes.cardTitleWhite}>REPORT</h2>
        </CardHeader>
        <CardBody>
          <div style={{
            paddingLeft: "15%",
            marginBottom: "40px",
            position: "relative"
          }}>
            <div className={classes.note}></div>
            {locations.map((location) => (
              <div style={{ display: "flex", flex: 1 }}>
                <ButtonWrapper style={{ flex: 0.5 }} key={location.qbenchCustomerId}>
                  <Button variant="outlined"

                    onClick={() => generateAppointmentsReport(location.qbenchCustomerId)}
                  >
                    Download Report for {location.name}
                  </Button>
                </ButtonWrapper>

                <ButtonWrapper style={{ flex: 0.5 }} key={location.qbenchCustomerId}>
                  <Button variant="outlined"

                    onClick={() => generateAllAppointmentsReport(location.qbenchCustomerId)}
                  >
                    Download All Data for {location.name}
                  </Button>
                </ButtonWrapper>
              </div>
            ))}
            {/* <ButtonWrapper>
            <Button variant="contained"

              onClick={() => downloadAllReports()}
            >
              Download All Report
              </Button>
          </ButtonWrapper> */}
          </div>



        </CardBody></Card>

    </Wrapper>

  );
};



export default Report;
