// const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID //UAT
const projectId = 'wsl-multitenancy-dev-ac13b'

const config = {
    baseURL: `https://us-central1-${projectId}.cloudfunctions.net`,
    qbenchCustomerId: {
        LasVegas_TheOrleans: 2,
        SanJose: 7,
        Atlanta_ID_Tech_Molecular: 30,
        Location15: 15,
        Location35: 35,
        SFO_Airport: 16,
        Oakland_HolidayInnExpress: 11,
        WoodSideHighSchool: 46,
        CarlmontHighSchool: 48,
        SequoiaHighSchool: 49,
        EastPaloAltoAcademy: 52,
        PostIsolationRapidAntigenTest: 63,
        SFOAirport_BARTStation: 67,
        KernHealthSystems: 106,
        RalstonMiddleSchool: 57,
        SanFransiscoDownTown: 36,
        SanDiagoVallyPark: 34,
        ArroyoUpperSchool: 68,
        ProudBird: 77,
        BoysGirlsClub: 66,
        SanFrancisco: 3,
        Austin: 74,
        Portland: 12,
        LongBeach: 10,
        LasVegasTheOrleans: 5,
    },
    RESULTOPTIONS: ['DETECTED', 'NOT DETECTED', 'INDETERMINATE'],
    firestoreCollections: {
        schedules: 'schedules',
        appointments: 'appointments',
        locations: 'locations',
        onetimepassword: 'onetimepassword',
        qbenchacknowledgement: 'qbenchacknowledgement',
        postalcodes: 'postalcodes',
        reschedulerefunds: 'reschedulerefunds',
        schedulesrapid: 'schedulesrapid',
        userdetails: 'userdetails',
        state: 'state',
        country: 'country',
        schedulewalkup: 'schedulewalkup',
        clientpartner: 'clientpartner',
    },
    internationalPricing: {
        destinations: ['JPN', 'KNA', 'ATG', 'BRB', 'CHN', 'MAF', 'JAM', 'NAN'],
        locations: [2, 6, 9, 10, 12, 38, 60],
    },
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: 'wsl-multitenancy.appspot.com',
    },
    weekDateFormat: 'EEEE, MMM d, yyyy',
    dateTimeFormat: 'LL/dd/yyyy hh:mm a',
    dateFormat: 'LL/dd/yyyy',
    fileDateFormat: 'yyyyLLdd',
    maxSlotsByPeriod: 16,
    schedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 30,
    },
    Reportschedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 30,
    },
    rapidSchedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 60,
    },
    products: {
        standard: process.env.REACT_APP_STANDARD_TEST_ID,
        expedited: process.env.REACT_APP_EXPEDITED_TEST_ID,
        rapid: process.env.REACT_APP_RAPID_TEST_ID,
        antigen: process.env.REACT_APP_ANTIGEN_TEST_ID,
        standard_INT: process.env.REACT_APP_INT_STANDARD_TEST_ID,
        expedited_INT: process.env.REACT_APP_INT_EXPEDITED_TEST_ID,
        rapid_INT: process.env.REACT_APP_INT_RAPID_TEST_ID,
        antigen_INT: process.env.REACT_APP_INT_ANTIGEN_TEST_ID,
    },
    currency: 'usd',
    maxAdvanceHours: 72,
    locationsfor36Hrs: [32, 31, 42, 33, 41],
    minAdvanceHours: 48,
    vipSlots: {
        maxAdvanceHours: 72,
        minAdvanceHours: 12,
        lastExpressSlotIndex: 12,
    },
    rapidSlots: {
        maxAdvanceHours: 72,
        minAdvanceHours: 3,
        lastExpressSlotIndex: 12,
    },
    lastExpressSlotIndex: 1,
    reportData: {
        location: '450 Bauchet Street, Los Angeles CA 90012',
        reviewedBy: 'Anthony Victorio, MD',
        technician: 'Cherisse Heirs',
    },
    manageScreen: {
        upcoming: 5,
        inProcess: 4,
        completed: 3,
        missed: 2,
    },
    locations_abbreviation: [
        {
            value: 'LAX',
            qbenchCustomerId: 2,
        },
        {
            value: 'SFO',
            qbenchCustomerId: 3,
        },
        {
            value: 'LAS',
            qbenchCustomerId: 5,
        },
        {
            value: 'SEA',
            qbenchCustomerId: 10,
        },
        {
            value: 'SJC',
            qbenchCustomerId: 12,
        },
        {
            value: 'SMF',
            qbenchCustomerId: 22,
        },
        {
            value: 'LGB',
            qbenchCustomerId: 23,
        },
        {
            value: 'PDX',
            qbenchCustomerId: 33,
        },
        {
            value: 'SAN',
            qbenchCustomerId: 34,
        },
        {
            value: 'PHX',
            qbenchCustomerId: 62,
        },
        {
            value: 'AUS',
            qbenchCustomerId: 105,
        },
        {
            value: 'SFO Airport',
            qbenchCustomerId: 119,
        },
        {
            value: 'OAK',
            qbenchCustomerId: 132,
        },
        {
            value: 'JFK',
            qbenchCustomerId: 140,
        },
        {
            value: 'MIA',
            qbenchCustomerId: 142,
        },
        {
            value: 'ATL',
            qbenchCustomerId: 145,
        },
        {
            value: 'PBI',
            qbenchCustomerId: 147,
        },
        {
            value: 'FLL',
            qbenchCustomerId: 148,
        },
        {
            value: 'SFO Downtown',
            qbenchCustomerId: 152,
        },
        {
            value: 'WPB',
            qbenchCustomerId: 160,
        },
        {
            value: 'PB12',
            qbenchCustomerId: 161,
        },
        {
            value: '',
            qbenchCustomerId: 162,
        },
        {
            value: 'WDS',
            qbenchCustomerId: 173,
        },
        {
            value: 'MAT',
            qbenchCustomerId: 174,
        },
        {
            value: 'CAR',
            qbenchCustomerId: 175,
        },
        {
            value: 'SEQ',
            qbenchCustomerId: 176,
        },
        {
            value: 'RED',
            qbenchCustomerId: 177,
        },
        {
            value: 'MILLS',
            qbenchCustomerId: 178,
        },
        {
            value: 'EPAA',
            qbenchCustomerId: 179,
        },
        {
            value: 'TIDE',
            qbenchCustomerId: 180,
        },
        {
            value: 'RAL',
            qbenchCustomerId: 188,
        },
        {
            value: 'RSE',
            qbenchCustomerId: 189,
        },
        {
            value: 'BADEN',
            qbenchCustomerId: 192,
        },
        {
            value: 'ECHS',
            qbenchCustomerId: 193,
        },
        {
            value: 'WMS',
            qbenchCustomerId: 194,
        },
    ],
    encryption: {
        secretKey: '9vApoNk5GUHDnIrM',
    },
    scheduleURL: 'https://wsl-multitenancy.web.app',
    daywiseLocationSearchAlert: `There are too many results to display. Please add one more search criteria to narrow down the results.`,
    auth0Redirection: {
        // clientId: "4g3CXyRnWYtPliyqYJkVADZCNpHC3cEP",
        // domain: 'worksitelabs.us.auth0.com',
        // domainURL: 'https://worksitelabs.us.auth0.com',
        // homeUrl: `https://admin.schedulecovidtesting.com/dashboard/Home`,
        // login: `https://admin.schedulecovidtesting.com`,
        // redirectUrl: `https://www.schedulecovidtesting.com/signin`,
        homeUrl: `https://localhost:3000/dashboard/Home`,
        login: `https://localhost:3000`,
        //  homeUrl: `https://schedule-dev-c05b5.web.app/dashboard/Home`,           //UAT
        //  login: `https://schedule-dev-c05b5.web.app`,                            //UAT
        redirectUrl: `https://wsl-multitenancy.web.app/signin`, //UAT
        // redirectUrl: `https://wsl-multitenancy-dev-ac13b.web.app/signin`,    //Dev
        clientId: 'qZtAQ3eiIxpxdJwRiXRymgWblkA6ed6t', //Dev
        domain: 'dev-lixcq4a8.us.auth0.com', //Dev
        domainURL: 'https://dev-lixcq4a8.us.auth0.com', //Dev
    },
    Billing_CPT_Code: {
        StandardLab_PCR: 'U0003, U0005',
        AntigenTest: '87426',
    },

    testType: {
        rapid: 'Rapid PCR Test',
        rapidTitle: 'Rapid PCR',
        express: 'Express PCR Test',
        expressTitle: 'Express PCR Test',
        standard: 'Standard PCR Test',
        standardTitle: 'Standard PCR',
        antigen: 'Antigen Test',
        panel: 'Flu A&B/Covid-19',
    },
    cloudRunApi: {
        getMysqlInsuranceReport: 'getMysqlInsuranceReport_V2',
        getMysqlDiscountAmountDetails: 'getMysqlDiscountAmountDetails_V2',
        getMysqlInsuranceAndStripe: 'getMysqlInsuranceAndStripe_V2',
        getMysqlCountyReport: 'getMysqlCountyReport_V2',
        getMysqlConsolidationReport: 'getMysqlConsolidationReport_V2',
        getMysqlALDDReports: 'getMysqlALDDReports_V2',
        getMysqlGenerateAppointmentReports: 'getMysqlGenerateAppointmentReports_V2',
        getApptBasedOnLocation: 'getApptBasedOnLocation',
        getApptBasedOnGender: 'getApptBasedOnGender',
        getApptBasedOnResults: 'getApptBasedOnResults',
        getApptConsolidationDetails: 'getApptConsolidationDetails',
        getReportAccess: 'getReportAccess',
        getCountry: 'getCountry',
        getNpDestination: 'getNpDestination',
        addNpDestination: 'addNpDestination',
        deleteNpDestination: 'deleteNpDestination',
        getCountyReport: 'getCountyReport',
        getInsuranceReport: 'getInsuranceReport',
        getConsolidationReport: 'getConsolidationReport',
        getDiscountAmountDetail: 'getDiscountAmountDetail',
        getInsuranceAndStripe: 'getInsuranceAndStripe',
        generateAppointmentsReport: 'generateAppointmentsReport',
        generateAllAppointmentsReport: 'generateAllAppointmentsReport',
        getALDDReport: 'getALDDReport',
        getNewSlots: 'getNewSlots',
        getPrices: 'getPrices',
        getQbenchOrderById: 'getQbenchOrderById',
        getSlotAvailability: 'getSlotAvailability',
        getQbenchResults: 'getQbenchResults',
        getCustomersSearchData: 'getCustomersSearchData',
        adminRegisterLogin: 'adminRegisterLogin',
        getEmployeeTesting: 'getEmployeeTesting',
        addEmployeeTesting: 'addEmployeeTesting',
        deleteEmployeeTesting: 'deleteEmployeeTesting',
        getDayWiseAppointmentsSearchData: 'getDayWiseAppointmentsSearchData',
        getAdminConfig: 'getAdminConfig',
        insertAdminConfig: 'insertAdminConfig',
        getAppointmentdata: 'getAppointmentdata',
        updateIscheckedIn: 'updateIscheckedIn',
        updateNoteQbenchacknowledgement: 'updateNoteQbenchacknowledgement',
        sendConfirmationEmail: 'sendConfirmationEmail',
        updatedaywiseAppointment: 'updatedaywiseAppointment',
        getQbenchacknowledgement: 'getQbenchacknowledgement',
        userCheckin: 'userCheckin',
        addOrUndoNoShow: 'addOrUndoNoShow'
    },
    cloudRun: {
        baseUrl: 'https://wsl-cloudrun-api-la2j6ijlpq-uc.a.run.app/',
        // baseUrl: 'http://localhost:8080/'
    },
};

export { config };
