import React, { useRef, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getAppointmentdata,
  updateMultipleAppointmentdata,
} from '../../../api';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { parse, format } from 'date-fns';
import { config } from '../../../../../../src/config';
import styled from 'styled-components';
import {
  getQbenchacknowledgement,
  updateNoteQbenchacknowledgement,
} from '../../../../shared/providers/admin/api';

const Error = styled.div`
  color: red;
  margin-top: 15px;
  text-align: left;
`;

interface Props {
  open: boolean;
  handleAddNoteClose: (number?:number) => void;
  appointmentsData: any;
  handleSearch: () => void;
}

const AddNote: React.FC<Props> = ({
  open,
  handleAddNoteClose,
  appointmentsData,
  handleSearch,
}) => {
    const { setAppointments } = useAdminDispatch();
    const { appointments } = useAdminState();
  const [openModel, setOpenModel] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [note, setNote] = useState<string>('');

  const updateNote = async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all(
       appointmentsData.map(async (appt: any, index: number) => {
        let params = {
          id: appt?.sampleId,
          note: note || '',
          qbenchId: null,
        };
        await updateNoteQbenchacknowledgement(params).then(() => {
          handleAddNoteClose();
          setLoading(false);
        });
      })
      )
      let tableDatas: any = appointments;
      tableDatas = appointments?.map((data: any) => {
          let rowData: any = data;
          let sampleIDs: any = appointmentsData?.map((data:any)=>  {return data?.sampleId});
          if (sampleIDs?.includes(data?.sampleId)) {
              rowData = {
                  ...rowData,
                  note: [...rowData.note,
                      {
                          message: note,
                          user: JSON.parse(
                              window.localStorage.getItem('userDetails')!
                          ).email,
                          time: format(new Date(), 'LL/dd/yyyy hh:mm a '),
                      },
                  ],
              };
          }
          return rowData;
      });
      setAppointments(tableDatas);
      setOpenModel(false)
    } catch (error) {
        setOpenModel(false)
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };
  return (
    <div>
      {openModel && (
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //onClose(event, reason);
              handleAddNoteClose();
            }
          }}
          fullWidth
          disableEscapeKeyDown
          keepMounted
        >
          <DialogTitle>Add a note</DialogTitle>
          <DialogContent>
            <TextField
              placeholder="Add a note here"
              multiline
              variant="outlined"
              style={{ width: '100%' }}
              rows={4}
              value={note}
              //inputRef={valueRef}
              onChange={(e) => {
                const newNote = e.target.value;
                setNote(newNote);
                console.log(note);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleAddNoteClose(1);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: ' rgb(24, 144, 255)',
                color: '#FFFFFF',
                margin: '0pc 10px 0px 10px',
              }}
              onClick={() => {
                //editAppointment(appointmentData);
                updateNote();
              }}
            >
              {loading ? 'Processing...' : 'Confirm'}
            </Button>
            {error && <Error>{error}</Error>}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AddNote;
