import React, { useEffect, useState } from 'react'
import { StripeChart } from './StripeChart'
import {  Skeleton } from 'antd';
import {  Col, Row } from 'antd';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { InsuranceData, NoInsuranceData, StripeData } from '../../api';
import StripebarChart from './StripebarChart';
import AmountDetails from './AmountDetails';
import StripeLineChart from './StripeLineChart';
import { addArraysByEqualIndex, getLabels } from './reusableFunctions';

const StripeDashboard = () => {
    let today: any = new Date()
    const [compareDate, setCompareDate] = useState<any>({
        forInsurance: format(new Date(), 'MM/dd/yyyy'),
        forNoInsurance: format(new Date(), 'MM/dd/yyyy'),
    });
    const [comparedRange, setComparedRange] = useState<any>({
        forInsurance: '-',
        forNoInsurance: '-',
    });
    const [selectType, setSelectType] = useState<any>({
        forInsurance: '-',
        forNoInsurance: '-',
    });
    const [insuranceData, setInsuranceData] = useState<any>({ todayAmount: 0, comparedAmount: 0, endRangeAmount: 0,previousRangeAmount: 0 })
    const [stripeData, setStripeData] = useState<any>({ todayAmount: 0, comparedAmount: 0, endRangeAmount: 0,previousRangeAmount: 0 })
    const [noInsuranceData, setNoInsuranceData] = useState<any>({ todayAmount: 0, comparedAmount: 0, endRangeAmount: 0,previousRangeAmount: 0 })
    const [startDate, setStartDate] = useState<any>({
        forInsurance: format(new Date(), 'MM/dd/yyyy'),
        forNoInsurance: format(new Date(), 'MM/dd/yyyy'),
        forPreviousInsurance: format(new Date(), 'MM/dd/yyyy'),
        forPreviousNoInsurance: format(new Date(), 'MM/dd/yyyy'),
    });
    const [endDate, setEndDate] = useState<any>({
        forInsurance: format(new Date(), 'MM/dd/yyyy'),
        forNoInsurance: format(new Date(), 'MM/dd/yyyy'),
        forPreviousInsurance: format(new Date(), 'MM/dd/yyyy'),
        forPreviousNoInsurance: format(new Date(), 'MM/dd/yyyy'),
    });
    const [todayTotal, setTodayTotal] = useState<any>({
        forInsurance: Number(insuranceData.todayAmount) + Number(stripeData.todayAmount),
        forNoInsurance: Number(noInsuranceData.todayAmount),
    })
    const [comparedTotal, setComparedTotal] = useState<any>({
        forInsurance: Number(insuranceData.comparedAmount) + Number(stripeData.comparedAmount),
        forNoInsurance: Number(noInsuranceData.comparedAmount),
    })
    const [rangeTotal, setRangeTotal] = useState<any>({
        forInsurance: Number(insuranceData.endRangeAmount) + Number(stripeData.endRangeAmount),
        forNoInsurance: Number(noInsuranceData.endRangeAmount),
        forPreviousInsurance: Number(insuranceData.previousRangeAmount) + Number(stripeData.previousRangeAmount),
        forPreviousNoInsurance: Number(noInsuranceData.previousRangeAmount),
    })
    const [rangeArray, setRangeArray] = useState<any>({
        totalForInsuranceAndStripe: [],
        previousTotalForInsuranceAndStripe: [],
        forNoInsuranceArray: [],
        forPreviousNoInsuranceArray: [],
        labelForInsuranceAndStripe: [],
        labelForNoInsurance: [],
    });
    const [loading, setLoading] = useState<any>({
        insurance:false,
        insuranceRange:false,
        uninsured:false,
        uninsuredRange:false
    })
    let y: any = today.getFullYear();
    let m: any = today.getMonth();
    let firstDay: any = new Date(y, m, 1);
    let previousDay: any = new Date(addDays(today, -1))

    const dateToMmDdYyyy = (date: any) => {
        if(!date){
            return '-';
        }
        const [yyyy, mm, dd] = date.split(/-/g);
        return `${mm}/${dd}/${yyyy}`;
    };
    const dateFormat = (date: any) => {
        return date ? format(new Date(date), 'MM/dd/yyyy') : format(new Date(), 'MM/dd/yyyy');
    }
    const onDateChange = async (date: any, dateString: any) => {
        console.log('dateString', dateString);
        
        setCompareDate((prev:any)=>{
            return { ...prev, forInsurance: dateToMmDdYyyy(dateString) };
        });
    };
    const onDateChangeForNoInsurance = async (date: any, dateString: any) => {
        setCompareDate((prev: any) => {
            return { ...prev, forNoInsurance: dateToMmDdYyyy(dateString) };
        });
    };
    const switchSetDateData = (state:string, field: string, value: any) => {
        if (state === 'Start') {
            setStartDate((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'End') {
            setEndDate((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'Range') {
            setComparedRange((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'SelectType') {
            setSelectType((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
    }


    const onChange = (value: string,isInsurance:boolean) => {
        console.log(`selected ${value}`);
        let start:any
        let end: any = dateFormat(today);
        let prevStart:any
        let prevEnd:any
        let selectType:any = 'days'
        let range:any
        let fieldToUpdate:any = isInsurance ? 'forInsurance': 'forNoInsurance'
        let fieldToUpdatePrev:any = isInsurance ? 'forPreviousInsurance': 'forPreviousNoInsurance'
        let labelField: any = isInsurance ? 'labelForInsuranceAndStripe' : 'labelForNoInsurance';
        let labelValue: any

        if (value === '7days') {
            start = dateFormat(addDays(today, -6));
            prevStart = dateFormat(addDays(new Date(start), -7)) // write start date for previous
            prevEnd = dateFormat(addDays(new Date(start),-1)); // write end date for previous
            range = 'Last 7 days'
        }
        if (value === '4weeks') {
            selectType = 'weeks';
            start = dateFormat(addDays(today, -27));
            prevStart = dateFormat(addDays(new Date(start), -28));  // write start date for previous
            prevEnd = dateFormat(addDays(new Date(start),-1));  // write end date for previous
            range = 'Last 4 weeks';
        }
        // if (value === '12months') {
        //     let date:any= new Date(addMonths(today,-12)) < new Date('01/01/2022') ? '01/01/2022' :dateFormat(addMonths(today, -12))
        //     switchSetDateData('Start',isInsurance ? 'forInsurance': 'forNoInsurance', date);
        //     switchSetDateData('End',isInsurance ? 'forInsurance': 'forNoInsurance', dateFormat(today));
        //     switchSetDateData('Range',isInsurance ? 'forInsurance': 'forNoInsurance', 'Last 12 months');
        // }
        if (value === 'monthToDate') {
            start = dateFormat(firstDay);
            prevStart = dateFormat(addMonths(new Date(start), -1)); // write start date for previous
            prevEnd = dateFormat(addMonths(new Date(today), -1)); // write end date for previous
            range = 'Month to date';
        }
        // if (value === 'quarter') {
        //     switchSetDateData('Start',isInsurance ? 'forInsurance': 'forNoInsurance', dateFormat(addMonths(today, -3)));
        //     switchSetDateData('End',isInsurance ? 'forInsurance': 'forNoInsurance', dateFormat(today));
        //     switchSetDateData('Range',isInsurance ? 'forInsurance': 'forNoInsurance', 'Quarter to date');
        // }
        if (value === 'yearToDate') {
            selectType = 'years';
            start = dateFormat(new Date(y, 0, 1));
            prevStart = dateFormat(addYears(new Date(start),-1)); // write start date for previous
            prevEnd = dateFormat(addYears(new Date(today),-1)); // write end date for previous
            range = 'Year to date';
            let data:any = (getLabels(start, end, selectType))
            data.pop();
            labelValue = data;
        }
        if (value !== 'yearToDate') {
            labelValue = getLabels(start, end, selectType);
        }
        switchSetDateData('SelectType',fieldToUpdate, selectType);
        switchSetDateData('Start',fieldToUpdate, start);
        switchSetDateData('End',fieldToUpdate, end);
        switchSetDateData('Start',fieldToUpdatePrev, prevStart);
        switchSetDateData('End',fieldToUpdatePrev, prevEnd);
        switchSetDateData('Range',fieldToUpdate, range);
        switchSetAmountData('RangeArray',labelField,labelValue);
    };
    const onSearch = (value: string) => {
        console.log('search:', value);
    };
    const switchSetLoading = (field:string,flag:boolean) =>{
        setLoading((prev: any) => {
            return { ...prev, [field]: flag };
        });
    }
    const switchSetAmountData = (state:string, field: string, value: any) => {
        if(state === 'Insurance'){
            setInsuranceData((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'NoInsurance') {
            setNoInsuranceData((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'Stripe') {
            setStripeData((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
        if (state === 'RangeArray') {
            setRangeArray((prev: any) => {
                return { ...prev, [field]: value };
            });
        }
    };
    const getSum = (total:any, num:any) => {
        return total +(num);
    }

    const getAmount = async (type: any,isInsurance:boolean,selectType?:any) => {
        let insuranceAmount: any;
        let stripeAmount: any;
        let uninsuranceAmount: any;
        let previousInsuranceAmount: any;
        let previousStripeAmount: any;
        let previousUninsuranceAmount: any;
        if (type === '') {
            if (isInsurance) {
                switchSetLoading('insurance',true);
                insuranceAmount = await InsuranceData({ start: dateFormat(today) });
                stripeAmount = await StripeData({ start: dateFormat(today) });
                if (insuranceAmount?.data) {
                    switchSetAmountData('Insurance','todayAmount', insuranceAmount?.data[0]);
                }
                if (stripeAmount?.data) {
                    switchSetAmountData('Stripe','todayAmount', stripeAmount?.data);
                }
                switchSetLoading('insurance', false);
            } else {
                switchSetLoading('uninsured', true);
                uninsuranceAmount = await NoInsuranceData({ start: dateFormat(today) });
                if (uninsuranceAmount?.data) {
                    switchSetAmountData('NoInsurance','todayAmount', uninsuranceAmount?.data[0]);
                }
                switchSetLoading('uninsured', false);
            }
        }
        if (type === 'range') {
            if (isInsurance) {
                switchSetLoading('insuranceRange', true);
                insuranceAmount = await InsuranceData({ type, start: startDate.forInsurance, end: endDate.forInsurance,selectType });
                previousInsuranceAmount = await InsuranceData({ type, start: startDate.forPreviousInsurance, end: endDate.forPreviousInsurance,selectType });
                stripeAmount = await StripeData({ type, start: startDate.forInsurance, end: endDate.forInsurance,selectType });
                previousStripeAmount = await StripeData({ type, start: startDate.forPreviousInsurance, end: endDate.forPreviousInsurance,selectType });
                if (insuranceAmount?.data && previousInsuranceAmount?.data) {
                    let total: any = insuranceAmount?.data.reduce(getSum, 0);
                    let previousTotal: any = previousInsuranceAmount?.data.reduce(getSum, 0);
                    switchSetAmountData('Insurance','endRangeAmount', total);
                    switchSetAmountData('Insurance', 'previousRangeAmount', previousTotal);
                }
                if (stripeAmount?.data && previousStripeAmount?.data) {
                    let total: any = stripeAmount?.data.reduce(getSum, 0);
                    let previousTotal: any = previousStripeAmount?.data.reduce(getSum, 0);
                    switchSetAmountData('Stripe', 'endRangeAmount', total);
                    switchSetAmountData('Stripe', 'previousRangeAmount', previousTotal);
                }
                if (insuranceAmount?.data && stripeAmount?.data){
                    let arr1:any = insuranceAmount?.data
                    let arr2:any = stripeAmount?.data
                    switchSetAmountData('RangeArray', 'totalForInsuranceAndStripe', addArraysByEqualIndex(arr1,arr2));
                }
                if (previousInsuranceAmount?.data && previousStripeAmount?.data){
                    let arr1:any = previousInsuranceAmount?.data
                    let arr2:any = previousStripeAmount?.data
                    switchSetAmountData('RangeArray', 'previousTotalForInsuranceAndStripe', addArraysByEqualIndex(arr1,arr2));
                }
                switchSetLoading('insuranceRange', false);
            } else {
                switchSetLoading('uninsuredRange', true);
                uninsuranceAmount = await NoInsuranceData({ type, start: startDate.forNoInsurance, end: endDate.forNoInsurance,selectType });
                previousUninsuranceAmount = await NoInsuranceData({ type, start: startDate.forPreviousNoInsurance, end: endDate.forPreviousNoInsurance,selectType });
                if (uninsuranceAmount?.data && previousUninsuranceAmount?.data) {
                    let total: any = uninsuranceAmount?.data.reduce(getSum, 0);
                    let previousTotal: any = previousUninsuranceAmount?.data.reduce(getSum, 0);
                    switchSetAmountData('NoInsurance', 'endRangeAmount', total);
                    switchSetAmountData('NoInsurance', 'previousRangeAmount', previousTotal);
                    switchSetAmountData('RangeArray', 'forNoInsuranceArray',uninsuranceAmount?.data );
                    switchSetAmountData('RangeArray', 'forPreviousNoInsuranceArray',previousUninsuranceAmount?.data );
                }
                switchSetLoading('uninsuredRange', false);
            }
        }
        if (type === 'total') {
            if (isInsurance) {
                insuranceAmount = await InsuranceData({ type });
                stripeAmount = await StripeData({ type });
            } else {
                uninsuranceAmount = await NoInsuranceData({ type });
            }
        }
        if (type === 'compare') {
            if (isInsurance) {
                switchSetLoading('insurance', true);
                insuranceAmount = await InsuranceData({ start: compareDate.forInsurance });
                stripeAmount = await StripeData({ start: compareDate.forInsurance });
                if (insuranceAmount?.data) {
                    switchSetAmountData('Insurance','comparedAmount', insuranceAmount?.data[0]);
                }
                else switchSetAmountData('Insurance','comparedAmount', 0);
                if (stripeAmount?.data) {
                    switchSetAmountData('Stripe','comparedAmount', stripeAmount?.data);
                }
                else switchSetAmountData('Stripe','comparedAmount', 0);
                switchSetLoading('insurance', false);
            } else {
                switchSetLoading('uninsured', true);
                uninsuranceAmount = await NoInsuranceData({ start: compareDate.forNoInsurance });
                if (uninsuranceAmount?.data) {
                    switchSetAmountData('NoInsurance','comparedAmount', uninsuranceAmount?.data[0]);
                }
                else switchSetAmountData('NoInsurance','comparedAmount', 0);
                switchSetLoading('uninsured', false);
            }
        }
    }

    useEffect(() => {
        getAmount('',true);
        getAmount('', false);
        onChange('7days',true)
        onChange('7days',false)
    }, []);
    useEffect(() => {
        getAmount('compare',true);
    }, [compareDate.forInsurance]);
    useEffect(() => {
        getAmount('compare',false);
    }, [compareDate.forNoInsurance]);
    useEffect(() => {
        comparedRange.forInsurance !== '-' && getAmount('range',true,selectType.forInsurance);
    }, [comparedRange.forInsurance]);
    useEffect(() => {
        comparedRange.forNoInsurance !== '-' && getAmount('range',false,selectType.forNoInsurance);
    }, [comparedRange.forNoInsurance]);

    const switchSetTodayTotal = (forInsurance: any, forNoInsurance: any) => {
        setTodayTotal({forInsurance ,forNoInsurance })
    };
    const switchSetComparedTotal = (forInsurance: any, forNoInsurance: any) => {
        setComparedTotal({ forInsurance, forNoInsurance });
    };
    const switchSetRangeTotal = (forInsurance: any, forNoInsurance: any, forPreviousInsurance:any, forPreviousNoInsurance:any) => {
        setRangeTotal({ forInsurance, forNoInsurance,forPreviousInsurance,forPreviousNoInsurance });
    };

    useEffect(() => {
        switchSetTodayTotal(Number(insuranceData.todayAmount) + Number(stripeData.todayAmount) || 0, Number(noInsuranceData.todayAmount) || 0);
        switchSetComparedTotal(Number(insuranceData.comparedAmount) + Number(stripeData.comparedAmount) || 0,Number(noInsuranceData.comparedAmount) || 0);
        switchSetRangeTotal(
            Number(insuranceData.endRangeAmount) + Number(stripeData.endRangeAmount) || 0,
            Number(noInsuranceData.endRangeAmount) || 0,
            Number(insuranceData.previousRangeAmount) + Number(stripeData.previousRangeAmount) || 0,
            Number(noInsuranceData.previousRangeAmount) || 0
        );
    }, [insuranceData, stripeData,noInsuranceData]);

    return (
        <>
            <div>
                <Row>
                    <Col span={12}>
                        <Row style={{ width: '90%', padding: '4% 6%', margin: 'auto', background: '#F4F4F4' }}>
                            <Col span={24}>
                                <StripebarChart
                                    title={'Credit and insurance balance summary '}
                                    onchange={onDateChange}
                                    color='blueviolet'
                                    date={compareDate.forInsurance}
                                    totalAmount={todayTotal.forInsurance}
                                    compareTotal={comparedTotal.forInsurance}
                                    selectText='Select a date to compare'
                                />
                                <AmountDetails amount={
                                    {isInsurance:true,todayInsurance:insuranceData.todayAmount,todayStripe:stripeData.todayAmount,comparedInsurance:insuranceData.comparedAmount,comparedStripe:stripeData.comparedAmount}
                                } report={false}/>
                                {loading.insurance ?
                                    <Skeleton active /> :
                                    <StripeChart chartFor={'date'} compareWith={compareDate.forInsurance} insuranceData={insuranceData} stripeData={stripeData} />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={{ width: '90%', padding: '4% 6%', margin: 'auto', background: '#F4F4F4' }}>
                            <Col span={24}>
                                <StripebarChart
                                    title={'Uninsured balance summary '}
                                    onchange={onDateChangeForNoInsurance}
                                    color='blueviolet'
                                    date={compareDate.forNoInsurance}
                                    totalAmount={todayTotal.forNoInsurance}
                                    compareTotal={comparedTotal.forNoInsurance}
                                    selectText='Select a date to compare'
                                />
                                <AmountDetails amount={
                                    {isInsurance:false,todayNoInsurance:noInsuranceData.todayAmount,comparedNoInsurance:noInsuranceData.comparedAmount}
                                } report={false}/>
                                {loading.uninsured ?
                                    <Skeleton active /> :
                                    <StripeChart uninsured={true} chartFor={'date'} compareWith={compareDate.forNoInsurance} noInsuranceData={noInsuranceData} />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col span={12}>
                        <Row style={{ width: '90%', padding: '4% 6%', margin: 'auto', background: '#F4F4F4' }}>
                            <Col span={24}>
                               <StripebarChart
                                title={'Credit and insurance balance summary '}
                                    onchange={onChange}
                                    color='blueviolet'
                                    date={comparedRange.forInsurance}
                                    totalAmount={rangeTotal.forInsurance}
                                    compareTotal={rangeTotal.forPreviousInsurance}
                                    selectText='Select a range to compare'
                                    onSearch={onSearch}
                                    report={true}
                                    isInsurance={true}
                                    comparedAndPrevious={{startDate,endDate}}
                                />
                                <AmountDetails amount={
                                    {isInsurance:true,todayInsurance:insuranceData.endRangeAmount,todayStripe:stripeData.endRangeAmount,comparedInsurance:insuranceData.previousRangeAmount,comparedStripe:stripeData.previousRangeAmount}
                                } report={true}/>
                                {loading.insuranceRange ?
                                    <Skeleton active /> :
                                    // <StripeChart chartFor={'range'} compareWith={comparedRange.forInsurance} insuranceData={insuranceData} stripeData={stripeData} />
                                    <StripeLineChart rangeArray={rangeArray} isInsurance={true} />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={{ width: '90%', padding: '4% 6%', margin: 'auto', background: '#F4F4F4' }}>
                            <Col span={24}>
                                <StripebarChart
                                    title={'Uninsured balance summary '}
                                    onchange={onChange}
                                    color='blueviolet'
                                    date={comparedRange.forNoInsurance}
                                    totalAmount={rangeTotal.forNoInsurance}
                                    compareTotal={rangeTotal.forPreviousNoInsurance}
                                    selectText='Select a range to compare'
                                    onSearch={onSearch}
                                    report={true}
                                    isInsurance={false}
                                    comparedAndPrevious={{startDate,endDate}}
                                />
                                <AmountDetails amount={
                                    {isInsurance:false,todayNoInsurance:noInsuranceData.endRangeAmount,comparedNoInsurance:noInsuranceData.previousRangeAmount}
                                } report={true}/>
                                {loading.uninsuredRange ?
                                    <Skeleton active /> :
                                    <StripeLineChart rangeArray={rangeArray} isInsurance={false} />
                                    // <StripeChart uninsured={true} chartFor={'range'} compareWith={comparedRange.forNoInsurance} noInsuranceData={noInsuranceData} />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
        </>
    );
}

export default StripeDashboard