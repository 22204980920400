export const countryAddress = [
    {
      label: 'United States',
      value: 'US',
    },
    {
      label: 'Canada',
      value: 'CA',
    },
    {
      label: 'Other',
      value: 'other',
    }
  ];
  