import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import Modal from '../../../shared/components/Modal';
import Minors from '../PersonalInformation/Minors';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { Button } from '../../../shared/components/styled';
import Checkbox from '../../../shared/components/form/Checkbox';
import { Breakpoints } from '../../../../dictionaries';
import FormLabel from '../../../shared/components/form/FormLabel';
import Input from '../../../shared/components/form/Input';
import { DatepickerField } from '../../../shared/components/Datepicker';
import { differenceInYears, format, parse } from 'date-fns';
import { config } from '../../../../config';
import { cloneDeep } from 'lodash';
import { useStepsDispatch, useStepsState, newMinor } from '../../providers/schedule/provider';
import { country } from '../PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';

const Container = styled.div`
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
`;

interface Props {
  showModal: boolean;
  closeModal: () => void;
  onChange: (data: any) => void;
}

const ChangeApptAttendeeModal: React.FC<Props> = ({ showModal, closeModal, onChange }) => {
  const { form } = useStepsState();
  const { firstName, lastName, birthDate, minors, slot, passportCountry, passportNo, travelType, date  } = form;
  const { updateFormValues } = useStepsDispatch();
  const [hasMinors, setHasMinors] = useState(minors.length > 0);
  const values = {
    minors: minors.map((minor: any) => ({
      ...minor,
      birthDate:
        minor.birthDate &&
        parse(minor.birthDate, config.dateFormat, new Date()),
    })),
    firstName,
    lastName,
    birthDate: birthDate && parse(birthDate, config.dateFormat, new Date()),
    hasMinors,
    slot,
    numberOfGuests: minors.length + 1,
    passportNo,
    passportCountry: country.filter((o) => Object.values(o).includes(passportCountry?.value))[0],
  };

  const rollbackValues = useMemo(() => cloneDeep(values), [showModal]);

  return (
    <Modal open={showModal} onClose={closeModal} maxWidth={1200}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          initialValues={values}
          onSubmit={ async (values) => {
            updateFormValues({
              ...values,
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
              minors: values.minors.map((minor: any) => ({
                ...minor,
                birthDate:
                  minor.birthDate && format(minor.birthDate, config.dateFormat),
              })),
            });
            if (slot !== null) {
              let numberOfGuestsDiff: number =
                values.minors.length + 1 - rollbackValues.numberOfGuests;

                console.log("numberOfGuestsDiff", numberOfGuestsDiff, form.reservationCount, values.minors.length + 1, slot);
                
              // if (numberOfGuestsDiff !== 0 && (form.reservationCount !== values.minors.length + 1)) {

              //   await releaseReservation(
              //     slot?.period,
              //     form.reservationCount,
              //     slot?.locationId,
              //     date,
              //     form.reservationId,
              //     false,
              //     form.isRapidTest
              //   )

              //   updateFormValues({
              //     slot: undefined
              //   })
              // }
              updateFormValues({
                slot: undefined,
                reservationId: ""
              })
            }

            closeModal();
          }}
        >
          {(formProps) => (
            <Form>
              <InputRow>
                <FormLabel label="First Name">
                  <Input
                    name="firstName"
                    isRequired
                    onChange={(value) => updateFormValues({ firstName: value ? value.trim() : ''})}
                  />
                </FormLabel>
                <FormLabel label="Last Name">
                  <Input
                    name="lastName"
                    isRequired
                    onChange={(value) => updateFormValues({ lastName: value ? value.trim() : ''})}
                  />
                </FormLabel>
                <FormLabel label="Date of Birth">
                  <DatepickerField
                    name="birthDate"
                    inputPlaceholder="MM/DD/YYYY"
                    validate={(value) => {
                      let error;

                      if (differenceInYears(new Date(), value) < 18) {
                        error = 'You must be 18 years old or above';
                      }

                      return error;
                    }}
                  />
                </FormLabel>
              </InputRow>
              <PassportRow>
                <FormLabel label="Passport Country">
                  <Select<string>
                    name="passportCountry"
                    options={country}
                    onChange={(value) => updateFormValues({passportCountry: value})}
                    validate={(value) => {
                      if (travelType == "INT" && !value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
                <FormLabel label="Passport #">
                  <Input
                    onChange={(value) => updateFormValues({passportNo: value})}
                    name="passportNo"
                    validate={(value) => {
                      if (travelType == "INT" && (!value || !value.trim().length)) {
                        return 'Required field';
                      }
                    }}
                    isRequired
                  />
                </FormLabel>

                </PassportRow>
              <Checkbox
                name="hasMinors"
                onChange={(checked) => {
                  const newMinors = checked ? [newMinor] : [];
                  formProps.setFieldValue('minors', newMinors);
                  updateFormValues({ minors: newMinors });
                  setHasMinors(checked);
                }}
              >
                I am registering for minors.
              </Checkbox>
              {hasMinors && <Minors />}

              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    
                    let formValues: any = rollbackValues;
                    let birthDate: any = rollbackValues.birthDate
                    let mirorsDetail: any = []
                    formValues.birthDate = birthDate !== null && birthDate !== undefined ? format(new Date(birthDate), config.dateFormat) : null;

                    if (rollbackValues.minors.length > 0) {
                      rollbackValues.minors.map((item: any) => {
                        mirorsDetail.push({
                          ...item,
                          birthDate: item.birthDate !== null && item.birthDate !== undefined ? format(new Date(item.birthDate), config.dateFormat) : null
                        })
                      })
                    }

                    updateFormValues({
                      ...rollbackValues,
                      birthDate: formValues.birthDate,
                      minors: mirorsDetail
                    })

                    closeModal();

                  }}
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit">
                  Change
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal >
  );
};

export default ChangeApptAttendeeModal;
