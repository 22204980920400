import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    subHours,
    isBefore,
    startOfDay,
    parse,
    format,
    isAfter,
} from 'date-fns';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import {
    Container,
    // PageHeader,
    PageTitle,
    PageSubTitle,
    Content,
} from './styled';
import pin from '../../../assets/pin.svg';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { AnyObject, Appointment, Location } from '../models';

import ChangeLocationModal from './ChangeLocationModal';
import Slots from './Slots';
import NavigationButtons from './NavigationButtons';
import Datepicker, { DatepickerField } from './Datepicker';
import FormLabel from './form/FormLabel';
import { cloneDeep, includes } from 'lodash';
import { useStepsDispatch } from '../../shared/providers/schedule/provider';
import { useSharedDispatch, useSharedState } from '../provider';
import { useManageDispatch } from '../../shared/providers/manage/provider';
import LocationModal from '../../shared/components/LocationModal';
import ManageLocationModal from '../../admin/Pages/Manage/components/ManageLocationModal';
import { useAdminDispatch } from '../../admin/provider';
import RapidAlertModal from './RapidAlertModal';
import { convertPricing } from '../../../utils';
import { getCoupon, getEmployeeCoupon } from '../api'
import Input from './form/Input';
import { addDays } from 'date-fns/esm';

const ChangeLocationButton = styled.button`
    display: inline-block;
    border: 0;
    background: none;
    color: ${colors.darkBlue};
    cursor: pointer;
`;

const PinIcon = styled.div`
    position: absolute;
    left: 0;
    top: 4px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
    width: 14px;
    height: 20px;
    background: url(${pin}) no-repeat;
    background-size: cover;
`;

const HeaderLocation = styled.div`
    position: relative;
    padding-left: 24px;
    text-align: left;

    @media (min-width: ${Breakpoints.sm}) {
        flex-direction: row;
    }
`;

const PageContent = styled.div`
    border-top: 1px solid ${colors.blue};
    padding-top: 25px;

    @media (min-width: ${Breakpoints.lg}px) {
        padding-top: 45px;
    }
`;

const AboveColumns = styled.div`
    max-width: 650px;
    margin-bottom: 20px;
`;

const Columns = styled.div`
    display: flex;
    margin: 0 -30px;

    & > * {
        margin: 0 30px;
    }
`;

const DatepickerWrapper = styled.div`
    display: none;

    @media (min-width: ${Breakpoints.md}px) {
        display: block;
        flex: 1 1 auto;
    }
`;

const SlotsWrapper = styled.div`
    width: 100%;

    @media (min-width: ${Breakpoints.md}px) {
        flex: 2 1 auto;
    }
`;

const LocationNameAndAddress = styled.div`
    margin-right: 10px;
`;

const PageHeader = styled.div`
    margin-bottom: 40px;
    flex: 1;
`;

const HeadarContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const Error = styled.div`
  margin-top: 15px;
`;

const TestTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 135px;
    width: 605px;
    margin: 10px;
    justify-content: center;
    text-align: center;
    align-content: center;
`;

const InnerBox = styled.div`
    border: 1px solid rgb(26, 150, 219);
    margin: 0px 5px;
    width: 40%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    align-items: center;
    display: grid;
    baschkground: ${({ selected }: { selected?: any }) =>
        selected ? colors.blue : colors.white};
`;

const TestTypeTitle = styled.p`
    font-size: medium;
    font-weight: bold;
    color: ${({ selected }: { selected?: any }) =>
        selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 15px;
        font-weight: bold;
        color: ${({ selected }: { selected: any }) =>
            selected ? colors.white : colors.blue};
    }
`;

const TestCostLabel = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${({ selected }: { selected: any }) =>
        selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 30px;
        font-weight: bold;
        color: ${({ selected }: { selected: any }) =>
            selected ? colors.white : colors.blue};
    }
`;

const TestDurationInfoLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${({ selected }: { selected: any }) =>
        selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 9px;
        font-weight: bold;
        color: ${({ selected }: { selected?: any }) =>
            selected ? colors.white : colors.blue};
    }
`;

const RefundInfoText = styled.div`
    font-weight: bold;
`;

export function disableFutureDt(): Date {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const disableFuture = new Date(year + 1, month, day); // PLUS 1 YEAR
    return disableFuture;
}
interface Props {
    title?: string;
    onSchedule: (values: FormikValues) => void;
    onFieldUpdate: (update: AnyObject) => void;
    onLocationChange?: () => void;
    appointment: Appointment;
    confirmBtnText?: string;
    loading?: boolean;
    isManage?: boolean;
    isAdmin?: boolean;
    standardTest?: boolean;
    prices?: any;
    goBack?: () => void;
    RescheduleTestType?:any;
    blockOutDays?:Number[];
}

export function getMinAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean
): Date {
    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(
                    config.schedule.endTime,
                    'h:mmaaaaa',
                    departureDateAndTime
                )
            )
        ) {
            return subHours(
                departureDateAndTime,
                config.vipSlots.maxAdvanceHours
            ); //departureDateAndTime;
        }
    }

    if (isRapidTest && location && location.rapidTest) {
        if (
            isBefore(
                departureDateAndTime,
                parse(
                    config.schedule.endTime,
                    'h:mmaaaaa',
                    departureDateAndTime
                )
            )
        ) {
            return subHours(
                departureDateAndTime,
                config.vipSlots.maxAdvanceHours
            ); //departureDateAndTime;
        }
    }
    if (
        isBefore(
            subHours(
                startOfDay(departureDateAndTime),
                getTimeZoneOffSet == 420 ||
                    getTimeZoneOffSet == 300 ||
                    getTimeZoneOffSet == 240
                    ? 71
                    : config.maxAdvanceHours
            ),
            new Date()
        )
    ) {
        return new Date();
    }

    // avoid to open up previous day without available slots in datepicker
    if (
        isAfter(
            departureDateAndTime,
            parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, config.minAdvanceHours);
    }

    return subHours(
        departureDateAndTime,
        getTimeZoneOffSet == 420 ||
            getTimeZoneOffSet == 300 ||
            getTimeZoneOffSet == 240
            ? 71
            : config.maxAdvanceHours
    );
}

export function getMaxAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean
): Date {
    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(
                    config.schedule.startTime,
                    'h:mmaaaaa',
                    departureDateAndTime
                )
            )
        ) {
        }

        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(
            departureDateAndTime,
            overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours
        );
    }

    if (isRapidTest && location && location.rapidTest) {
        // console.log("getMinAvailableDate", subHours(departureDateAndTime, 3));
        const overTime = new Date(departureDateAndTime).getHours();

        return subHours(departureDateAndTime, 3);
    }

    // avoid to open up next day without available slots in datepicker
    if (
        isBefore(
            departureDateAndTime,
            parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(
            departureDateAndTime,
            getTimeZoneOffSet == 420 ||
                getTimeZoneOffSet == 300 ||
                getTimeZoneOffSet == 240
                ? 71
                : config.maxAdvanceHours
        );
    }

    return subHours(departureDateAndTime, config.minAdvanceHours);
}

const Schedule: React.FC<Props> = ({
    title,
    appointment,
    onSchedule,
    onFieldUpdate,
    onLocationChange,
    confirmBtnText,
    loading,
    isManage,
    isAdmin,
    standardTest,
    prices,
    goBack,
    RescheduleTestType,
    blockOutDays 
}) => {
    const {
        location,
        slot,
        departureDateAndTime,
        isExpressSameDayTest,
        date,
        isRapidTest,
        travelType,
        destination,
        isAntigen,
        testSelection,
        isWalkUpTest,
        email,
        promoCode,
        discountAmount,
        hasInsurance,
    } = appointment;

    const appointmentPrice: any = prices;
    const { updateStandradTest } = useManageDispatch();
    const { updateStandradTestAdmin } = useAdminDispatch();
    const { upateTestType } = useSharedDispatch();

    const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(
        false
    );
    const [isEmpEmail, setIsEmpEmail] = useState(false);
    const [showRapidAlertModal, setShowRapidAlertModal] = useState(false);
    const handleRapidModalClose = () => {
        setShowRapidAlertModal(false);
    };
    const [btnloading, setBtnLoading] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    if(typeof(blockOutDays) !== 'object' && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays')))) !== null && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays')))) !== undefined){
        blockOutDays = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays'))))
    }

    useEffect(() => {
        getEmployeeEmail()
        updateStandradTest(
            isRapidTest
                ? false
                : isExpressSameDayTest
                ? false
                : isAntigen
                ? false
                : true
        );
        updateStandradTestAdmin(
            isRapidTest
                ? false
                : isExpressSameDayTest
                ? false
                : isAntigen
                ? false
                : true
        );
        if (isExpressSameDayTest || isRapidTest || isAntigen) {
            setMessage('');
            onFieldUpdate({
                couponId: '',
                discountAmount: 0,
            });
            setMessage('')
        }
        setMessage('')
    }, [isExpressSameDayTest, isRapidTest, isAntigen]);
  useEffect(()=>{
        if(isManage &&RescheduleTestType.change&&appointment.slot && isEmpEmail && promoCode === 'EMPLOYEE'){
            promoCodeValidation('')
        }
    },[isManage &&RescheduleTestType.change&&appointment.slot])
    
    const promoCodeValidation = async (promoCodes: any) => {
        try {
            setBtnLoading(true);
            const response = await getCoupon({
                code: promoCodes ? promoCodes : promoCode,
                email: email,
                testSelection: testSelection
            });

            if (response?.data?.status === 200) {
                onFieldUpdate({ couponId: response.data.couponId });
                if ( promoCodes === 'EMPLOYEE' ||
                (promoCode === 'EMPLOYEE' && isEmpEmail)) {
                    setMessage(
                        `Coupon code applied succesfully, Please click Continue Reschedule.`
                    );
                } else if (isRapidTest) {
                    onFieldUpdate({ discountAmount: (response.data.amount / 100) * 3 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 3
                        } as discount. Please click pay now.`
                    );
                } else if (isExpressSameDayTest) {
                    onFieldUpdate({ discountAmount: (response.data.amount / 100) * 2 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 2
                        } as discount. Please click pay now.`
                    );
                } else {
                    onFieldUpdate({ discountAmount: response.data.amount / 100 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${response.data.amount / 100
                        } as discount. Please click pay now.`
                    );
                }
            } else {
                setMessage(response?.data?.message);
                onFieldUpdate({ discountAmount: 0, couponId: '', promoCode:'' });
            }
            setBtnLoading(false);
        } catch (e) {
            console.log('error', e);
        }
    }
    const getEmployeeEmail = async () => {
        console.log('userEmail', appointment)
        let empData = await getEmployeeCoupon(appointment?.email)
        const dataEmail: any = appointment?.email?.split('@');
        const worksiteEmail = dataEmail[1];
        if (
            (worksiteEmail === 'worksitelabs.com' || !!empData.data) &&
            testSelection === 'general'
        ) {
            setIsEmpEmail(true)
        }
    }

    let bufferDate: any[] = [];
    
    const locationStartDate = location
        ? parse(location.startDate, config.dateFormat, new Date())
        : null;
    console.log('prices are ', prices);
    const pageTitle =
        standardTest || isExpressSameDayTest || isRapidTest
            ? 'Pick a date and time'
            : 'Select your test type';
    const { goToPrevStep } = useStepsDispatch();

    const rollbackisExpressSameDayTest = useMemo(
        () => cloneDeep(isExpressSameDayTest),
        [isManage]
    );
    const rollbackisRapidTest = useMemo(() => cloneDeep(isRapidTest), [
        isManage,
    ]);
    const TestTypeBox: React.FC<any> = ({
        testType,
        location,
        values,
        locationTestType,
        title,
        testDuration,
        isCostLabelVisibile
    }) => {
        let rapidTest = false,
            expressTest = false,
            standardTest = false,
            antigenTest = false;
        if (title === 'RAPID TEST') {
            rapidTest = true;
            expressTest = false;
            standardTest = false;
            antigenTest = false;
        } else if (title === 'EXPRESS TEST') {
            rapidTest = false;
            expressTest = true;
            standardTest = false;
            antigenTest = false;
        } else if (title === 'STANDARD TEST') {
            rapidTest = false;
            expressTest = false;
            standardTest = true;
            antigenTest = false;
        } else if (title === 'ANTIGEN TEST') {
            rapidTest = false;
            expressTest = false;
            standardTest = false;
            antigenTest = true;
        }
        return (
            <InnerBox
                selected={testType}
                style={
                    // standardTest
                    //     ? {}
                    //     : 
                        {
                              border: locationTestType
                                  ? '1px solid #D3D3D3'
                                  : '1px solid rgb(26, 150, 219)',
                              background: locationTestType
                                  ? '#D3D3D3'
                                  : testType
                                  ? colors.blueLight
                                  : colors.white,
                              cursor: locationTestType
                                  ? 'not-allowed'
                                  : 'pointer',
                          }
                }
                aria-disabled={standardTest}
                onClick={async () => {
                    // if (!standardTest) {
                        if (locationTestType) {
                            return;
                        }
                    // }
                    upateTestType(standardTest);
                    updateStandradTest(standardTest);
                    updateStandradTestAdmin(standardTest);
                    rapidTest && setShowRapidAlertModal(true);
                    appointment.isWalkUpTest ?
                    onFieldUpdate({
                        isExpressSameDayTest: expressTest,
                        isRapidTest: rapidTest,
                        isAntigen: antigenTest,
                    })
                        :
                        onFieldUpdate({
                        slot: undefined,
                        isExpressSameDayTest: expressTest,
                        isRapidTest: rapidTest,
                        isAntigen: antigenTest,
                    });
                    if (!values.departureDateAndTime) {
                        return;
                    }
                    const overTime = new Date(
                        values.departureDateAndTime
                    ).getHours();
                    let newDate: any;
                    if (standardTest) {
                        newDate = subHours(
                            startOfDay(values.departureDateAndTime),
                            config.maxAdvanceHours
                        );
                        upateTestType(standardTest);
                        updateStandradTest(standardTest);
                    } else {
                        newDate = subHours(
                            values.departureDateAndTime,
                            overTime >= 12
                                ? 24
                                : rapidTest
                                    ? config.rapidSlots.minAdvanceHours
                                    : config.vipSlots.minAdvanceHours
                        );
                    }
                    appointment.isWalkUpTest ?
                    onFieldUpdate({
                        isRapidTest: rapidTest,
                        isAntigen: antigenTest,
                        isExpressSameDayTest: expressTest,
                        date: format(newDate, config.dateFormat),
                    })
                    :
                        onFieldUpdate({
                        slot: undefined,
                        isRapidTest: rapidTest,
                        isAntigen: antigenTest,
                        isExpressSameDayTest: expressTest,
                        date: format(newDate, config.dateFormat),
                        reservationId: null,
                    });
                }}
            >
                <TestTypeTitle
                    selected={testType}
                    style={
                        // standardTest
                        //     ? {}
                        //     : 
                            {
                                  color: locationTestType
                                      ? '#939393'
                                      : testType
                                      ? colors.white
                                      : colors.blueLight,
                              }
                    }
                >
                    {title}
                </TestTypeTitle>
                {isCostLabelVisibile && (
                <TestCostLabel
                    selected={testType}
                    style={
                        // standardTest
                        //     ? {}
                        //     : 
                            {
                                  color: locationTestType
                                      ? '#939393'
                                      : testType
                                      ? colors.white
                                      : colors.blueLight,
                              }
                    }
                >
                    $
                    {convertPricing(
                        expressTest,
                        rapidTest,
                        prices,
                        travelType,
                        destination,
                        location?.qbenchCustomerId,
                        antigenTest
                    )}
                </TestCostLabel>
                )}
                <TestDurationInfoLabel
                    selected={testType}
                    style={
                        // standardTest
                        //     ? {}
                        //     : 
                            {
                                  color: locationTestType
                                      ? '#939393'
                                      : testType
                                      ? colors.white
                                      : colors.blueLight,
                              }
                    }
                >
                    {testDuration}
                </TestDurationInfoLabel>
            </InnerBox>
        );
    };
    return (
        <Formik
            enableReinitialize
            initialValues={{
                slot,
                departureDateAndTime: departureDateAndTime
                    ? parse(
                          departureDateAndTime,
                          config.dateTimeFormat,
                          new Date()
                      )
                    : null,
                isExpressSameDayTest,
                isRapidTest,
                isAntigen,
            }}
            onSubmit={onSchedule}
            validate={(values) => {
                const errors: FormikErrors<FormikValues> = {};
                if (!values.slot) {
                    errors.slot = 'Please select slot';
                }

                return errors;
            }}
        >
            {({ values }) => (
                <Container size="xl">
                    <Content>
                        <HeadarContent>
                            <PageHeader>
                                <PageTitle>{pageTitle}</PageTitle>
                                <PageSubTitle>
                                    <HeaderLocation>
                                        <PinIcon />
                                        <LocationNameAndAddress>
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {location?.name}
                                            </span>
                                            ,
                                            <p>
                                                {location?.address1}{' '}
                                                {location?.address2}
                                            </p>
                                        </LocationNameAndAddress>

                                        {(!appointment.isWalkUpTest)&&onLocationChange && (
                                            <ChangeLocationButton
                                                type="button"
                                                onClick={onLocationChange}
                                            >
                                                Change location
                                            </ChangeLocationButton>
                                        )}
                                    </HeaderLocation>
                                </PageSubTitle>
                            </PageHeader>
                            {/* {( appointment.promoCode !== 'EMPLOYEE' || hasInsurance)&& (
                                <TestTypeContainer>
                                    {appointment.payingMethod !== 'No Insurance' && appointment.payingMethod !== 'Insurance' && location?.rapidTest && <TestTypeBox
                                        testType={isRapidTest}
                                        location={location}
                                        values={values}
                                        locationTestType={(!location?.rapidTest) || (isEmpEmail)}
                                        title={'RAPID TEST'}
                                        testDuration={'Results within 1.5 hours'}
                                        isCostLabelVisibile={(appointment.hasInsurance === true || appointment.payingMethod === 'No Insurance' ) ? false : true} 
                                    />}
                                   {appointment.payingMethod !== 'No Insurance' && appointment.payingMethod !== 'Insurance' && location?.isExpressSameDay && <TestTypeBox
                                        testType={isExpressSameDayTest}
                                        location={location}
                                        values={values}
                                        locationTestType={!location?.isExpressSameDay}
                                        title={'EXPRESS TEST'}
                                        testDuration={'Results within 12 hours'}
                                        isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true} 
                                    />}
                                    {location?.standardTest&&<TestTypeBox
                                        testType={standardTest}
                                        location={location}
                                        values={values}
                                        locationTestType={!location?.standardTest}
                                        title={'STANDARD TEST'}
                                        testDuration={
                                            includes(
                                                config.locationsfor36Hrs,
                                                location?.qbenchCustomerId
                                            )
                                                ? 'Results within 36 hours'
                                                : 'Results within 24 hours'
                                        }
                                        isCostLabelVisibile={(appointment.hasInsurance === true  || appointment.payingMethod === 'No Insurance') ? false : true} 
                                    />}
                                    {location?.isAntigen&&<TestTypeBox
                                        testType={isAntigen}
                                        location={location}
                                        values={values}
                                        locationTestType={(!location?.isAntigen) || (isEmpEmail && appointment.payingMethod !== 'No Insurance' && appointment.payingMethod !== 'Insurance')}
                                        title={'ANTIGEN TEST'}
                                        testDuration={'Results within 30 minutes'}
                                        isCostLabelVisibile={( appointment.hasInsurance === true  || appointment.payingMethod === 'No Insurance') ? false : true} 
                                    />}
                                </TestTypeContainer>
                            )} */}
                        </HeadarContent>
                        <PageContent>
                            {!standardTest &&
                                !isExpressSameDayTest &&
                                !isRapidTest &&
                                !isAntigen && (
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            onClick={() => {
                                                goToPrevStep();
                                            }}
                                            style={{
                                                width: '24%',
                                            }}
                                            libraryType="default"
                                            type="button"
                                        >
                                            Return
                                        </Button>
                                    </div>
                                )}

                            <>
                                <Form>
                                    {/* {(standardTest ||
                                        isExpressSameDayTest ||
                                        isRapidTest ||
                                        isAntigen) &&
                                        testSelection === 'flight' && (
                                            <AboveColumns>
                                                <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                                    <DatepickerField
                                                        name="departureDateAndTime"
                                                        showTimeSelect
                                                        minDate={
                                                            locationStartDate ===
                                                                null ||
                                                            isBefore(
                                                                locationStartDate,
                                                                new Date()
                                                            )
                                                                ? new Date()
                                                                : locationStartDate
                                                        }
                                                        maxDate={appointment.isWalkUpTest?new Date('01/01/2100'):disableFutureDt()}
                                                        onChange={async (
                                                            newDepartureDate
                                                        ) => {
                                                            // This time zone offset is for pacific daylight time
                                                            // Will need to add the other time zones offset later.
                                                            let getTimeZoneOffSet = new Date(newDepartureDate).getTimezoneOffset();

                                                            const overTime = new Date(newDepartureDate).getHours();

                                                            let newDate = values.isRapidTest ?
                                                                    subHours(newDepartureDate, 3)
                                                                        : values.isExpressSameDayTest ?
                                                                            subHours(newDepartureDate, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  // startOfDay(newDepartureDate)
                                                                            // : subHours(newDepartureDate, config.maxAdvanceHours);
                                                                            : subHours(newDepartureDate, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours)

                                                            if (isBefore(newDate, new Date())) {
                                                                newDate = startOfDay(new Date());
                                                            }

                                                            appointment.departureDateAndTime = format(newDepartureDate, config.dateTimeFormat);

                                                            onFieldUpdate({
                                                                departureDateAndTime: format(
                                                                    newDepartureDate,
                                                                    config.dateTimeFormat
                                                                ),
                                                                date: format(
                                                                    newDepartureDate,
                                                                    config.dateFormat
                                                                ),
                                                            });

                                                            onFieldUpdate({
                                                                slot: undefined,
                                                                reservationId: null,
                                                            });
                                                        }}
                                                    />
                                                </FormLabel>
                                            </AboveColumns>
                                        )}
                                    {values.departureDateAndTime && (
                                        <Columns>
                                            <DatepickerWrapper>
                                            <Datepicker
                                                    value={
                                                        date
                                                            ? parse(date, config.dateFormat, new Date())
                                                            : new Date()
                                                    }
                                                    onChange={(newDate) =>
                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            reservationId: null,
                                                            date: format(newDate, config.dateFormat),
                                                        })
                                                    }
                                                    minDate={getMinAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    maxDate={appointment.isWalkUpTest?new Date('01/01/2100'):getMaxAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    inline
                                                    isblockOutDays={location?.blockOutDays}
                                                />
                                            </DatepickerWrapper>
                                            <SlotsWrapper>
                                                <Slots
                                                    appointment={appointment}
                                                    onFieldUpdate={
                                                        onFieldUpdate
                                                    }
                                                    isWalkUpTest={appointment.isWalkUpTest}
                                                    isAdmin={true}
                                                    onChangeDate={(
                                                        newDepartureDate
                                                    ) => {
                                                        appointment.date = format(
                                                            newDepartureDate,
                                                            config.dateFormat
                                                        );
                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            date: format(
                                                                newDepartureDate,
                                                                config.dateFormat
                                                            ),
                                                        });
                                                    }}
                                                />
                                                {isManage &&
                                                    RescheduleTestType.change &&
                                                    appointment.slot &&
                                                    (
                                                        <CouponContainer>
                                                            <FormLabel label="Coupon Code">
                                                                <Input
                                                                    name="promoCode"
                                                                    placeholder="Enter The Coupon Code"
                                                                    onChange={(value: any) => {
                                                                        onFieldUpdate({ promoCode: value });
                                                                    }}
                                                                    value={promoCode}
                                                                />
                                                            </FormLabel>
                                                            <FormLabel label="">
                                                                <Button
                                                                    libraryType="primary"
                                                                    type="button"
                                                                    onClick={() => promoCodeValidation('')}
                                                                >
                                                                    {btnloading ? 'Processing...' : 'Apply'}
                                                                </Button>
                                                            </FormLabel>
                                                        </CouponContainer>
                                                    )}
                                                {isManage && !isEmpEmail &&
                                                    RescheduleTestType.change &&
                                                    appointment.slot && (
                                                        <div style={{ margin: '30px 0', fontWeight: 800 }}>
                                                            Note*
                                                            <br />
                                                            If{' '}
                                                            {RescheduleTestType.upgrade
                                                                ? ' Upgrading '
                                                                : ' Downgrading '}{' '}
                                                            from a {RescheduleTestType.previous} Test to an{' '}
                                                            {RescheduleTestType.present} Test, a full refund
                                                            for your paid {RescheduleTestType.previous} Tests
                                                            will be credited to the original credit card in
                                                            7-15 business days. There will be a new charge for
                                                            the {RescheduleTestType.present} Tests.
                                                        </div>
                                                    )}
                                                {(isManage && isAdmin&&!isEmpEmail) &&
                                                    rollbackisExpressSameDayTest ==
                                                        false &&
                                                    isExpressSameDayTest ==
                                                        true && (
                                                        <div
                                                            style={{
                                                                margin:
                                                                    '20px 10px 0px 0px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                *Note
                                                            </div>
                                                            {isExpressSameDayTest && (
                                                                <RefundInfoText>
                                                                    If upgrading
                                                                    from a
                                                                    Standard
                                                                    Test to an
                                                                    Express
                                                                    Test, a full
                                                                    refund for
                                                                    your paid
                                                                    Standard
                                                                    Tests will
                                                                    be credited
                                                                    to the
                                                                    original
                                                                    credit card
                                                                    in 7-15
                                                                    business
                                                                    days. There
                                                                    will be a
                                                                    new charge
                                                                    for the
                                                                    Express
                                                                    Tests.
                                                                </RefundInfoText>
                                                            )}
                                                        </div>
                                                    )}
                                                {(isManage && isAdmin) &&
                                                    rollbackisRapidTest ==
                                                        false &&
                                                    isRapidTest == true && (
                                                        <div
                                                            style={{
                                                                margin:
                                                                    '20px 10px 0px 0px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                *Note
                                                            </div>
                                                            {isRapidTest && (
                                                                <RefundInfoText>
                                                                    If upgrading
                                                                    from a
                                                                    Standard
                                                                    Test or
                                                                    Express Test
                                                                    to a Rapid
                                                                    Test, a full
                                                                    refund for
                                                                    your paid
                                                                    Standard
                                                                    Tests or
                                                                    Express
                                                                    Tests will
                                                                    be credited
                                                                    to the
                                                                    original
                                                                    credit card
                                                                    in 7-15
                                                                    business
                                                                    days. There
                                                                    will be a
                                                                    new charge
                                                                    for the
                                                                    Rapid Tests.
                                                                </RefundInfoText>
                                                            )}
                                                        </div>
                                                    )}
                                                <NavigationButtons
                                                    loading={loading}
                                                    confirmBtnText={
                                                        confirmBtnText
                                                    }
                                                    onReturnButtonClick={goBack}
                                                />
                                            </SlotsWrapper>
                                        </Columns>
                                    )} */}
                                    {(standardTest ||
                                        isExpressSameDayTest ||
                                        isRapidTest ||
                                        isAntigen) &&
                                        // testSelection !== 'flight' && 
                                        !values.departureDateAndTime && (
                                            <Columns>
                                                <DatepickerWrapper>
                                                    <Datepicker
                                                        value={
                                                            date
                                                                ? parse(
                                                                      date,
                                                                      config.dateFormat,
                                                                      new Date()
                                                                  )
                                                                : new Date()
                                                        }
                                                        onChange={(newDate) =>
                                                            onFieldUpdate({
                                                                slot: undefined,
                                                                reservationId: null,
                                                                date: format(
                                                                    newDate,
                                                                    config.dateFormat
                                                                ),
                                                            })
                                                        }
                                                        isDayFilter={
                                                            testSelection !==
                                                            'general'
                                                                ? location?.isDayFilter !==
                                                                  undefined
                                                                    ? location?.isDayFilter
                                                                    : false
                                                                : false
                                                        }
                                                        isWeeklyFlag={
                                                            // testSelection !==
                                                            // 'general'
                                                            //     ? location?.isWeeklyFlag !==
                                                            //       undefined
                                                            //         ? 
                                                            //         location?.isWeeklyFlag
                                                            //         : false
                                                            //     : false
                                                            blockOutDays !== undefined
                                                            ?
                                                                blockOutDays.length < 7
                                                                ?
                                                                    true
                                                                :
                                                                    location?.isWeeklyFlag
                                                            :
                                                            location?.isWeeklyFlag                                            
                                                        }
                                                        // weeklyVisible={
                                                        //     testSelection !==
                                                        //     'general'
                                                        //         ? {
                                                        //               weeklyVisibleDays:
                                                        //                   location?.weeklyVisibleDays !==
                                                        //                   undefined
                                                        //                       ? location?.weeklyVisibleDays
                                                        //                       : [
                                                        //                             0,
                                                        //                             1,
                                                        //                             2,
                                                        //                             3,
                                                        //                             4,
                                                        //                             5,
                                                        //                             6,
                                                        //                         ],
                                                        //               weeklyVisibleNumber:
                                                        //                   location?.weeklyVisibleNumber !==
                                                        //                   undefined
                                                        //                       ? location?.weeklyVisibleNumber
                                                        //                       : {
                                                        //                             weekDay: 0,
                                                        //                             weekNumber: 0,
                                                        //                         },
                                                        //           }
                                                        //         : {}
                                                        // }
                                                        weeklyVisible={{
                                                            weeklyVisibleDays:blockOutDays,
                                                            weeklyVisibleNumber:
                                                                location?.weeklyVisibleNumber !==
                                                                    undefined
                                                                    ? location?.weeklyVisibleNumber
                                                                    : {
                                                                        weekDay: 0,
                                                                        weekNumber: 0,
                                                                    },
                                                        }
                                                }
                                                        minDate={new Date()}
                                                        maxDate={appointment.isWalkUpTest?new Date('01/01/2100'):disableFutureDt()}
                                                        inline
                                                        location={location}
                                                        // isblockOutDays={location?.blockOutDays}
                                                        isblockOutDays={
                                                            location?.blockOutDays !==
                                                                undefined
                                                                ? location?.blockOutDays
                                                                : bufferDate
                                                        }
                                                    />
                                                </DatepickerWrapper>
                                                <SlotsWrapper>
                                                    <Slots
                                                        appointment={
                                                            appointment
                                                        }
                                                        isWalkUpTest={appointment.isWalkUpTest}
                                                        onFieldUpdate={
                                                            onFieldUpdate
                                                        }
                                                        isAdmin={true}
                                                        onChangeDate={(
                                                            newDepartureDate
                                                        ) => {
                                                            appointment.date = format(
                                                                newDepartureDate,
                                                                config.dateFormat
                                                            );
                                                            onFieldUpdate({
                                                                slot: undefined,
                                                                date: format(
                                                                    newDepartureDate,
                                                                    config.dateFormat
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    {isManage &&
                                                        RescheduleTestType.change &&
                                                        appointment.slot && !isEmpEmail && (
                                                            <CouponContainer>
                                                                <FormLabel label="Coupon Code">
                                                                    <Input
                                                                        name="promoCode"
                                                                        placeholder="Enter The Coupon Code"
                                                                        onChange={(value: any) => {
                                                                            onFieldUpdate({ promoCode: value });
                                                                        }}
                                                                        value={promoCode}
                                                                    />
                                                                </FormLabel>
                                                                <FormLabel label="">
                                                                    <Button
                                                                        libraryType="primary"
                                                                        type="button"
                                                                        onClick={() => promoCodeValidation('')}
                                                                    >
                                                                        {btnloading ? 'Processing...' : 'Apply'}
                                                                    </Button>
                                                                </FormLabel>
                                                            </CouponContainer>
                                                        )} 
                                                         {isManage &&
                                                        RescheduleTestType.change &&
                                                        appointment.slot && !!isEmpEmail && (
                                                            <CouponContainer>
                                                                <FormLabel label="Coupon Code">
                                                                    <Input
                                                                        disabled
                                                                        name="promoCode"
                                                                        defaultValue="EMPLOYEE"
                                                                        value="EMPLOYEE"
                                                                    />
                                                                </FormLabel>
                                                                <FormLabel label="">
                                                                    <Button
                                                                        libraryType="primary"
                                                                        id='promo'
                                                                        type="button"
                                                                        onClick={() => promoCodeValidation('EMPLOYEE')}
                                                                    >
                                                                        {btnloading ? 'Processing...' : 'Apply'}
                                                                    </Button>
                                                                </FormLabel>
                                                            </CouponContainer>
                                                        )} 
                                                    {message && (
                                                        <Error
                                                            style={{
                                                                color: ((discountAmount ? (discountAmount) : -1) > 0) ||
                                                                    (isEmpEmail && message !== "Invalid coupon code") ? 'green' : 'red',
                                                            }}
                                                        >
                                                            {message}
                                                        </Error>
                                                    )}
                                                    {isManage && !isEmpEmail &&
                                                        RescheduleTestType.change &&
                                                        appointment.slot && (
                                                            <div style={{ margin: '30px 0', fontWeight: 800 }}>
                                                                Note*
                                                                <br />
                                                                If{' '}
                                                                {RescheduleTestType.upgrade
                                                                    ? ' Upgrading '
                                                                    : ' Downgrading '}{' '}
                                                                from a {RescheduleTestType.previous} Test to an{' '}
                                                                {RescheduleTestType.present} Test, a full refund
                                                                for your paid {RescheduleTestType.previous} Tests
                                                                will be credited to the original credit card in
                                                                7-15 business days. There will be a new charge for
                                                                the {RescheduleTestType.present} Tests.
                                                            </div>
                                                        )}
                                                    {(isManage && isAdmin && !isEmpEmail) &&
                                                        rollbackisExpressSameDayTest ==
                                                        false &&
                                                        isExpressSameDayTest ==
                                                        true && (
                                                            <div
                                                                style={{
                                                                    margin:
                                                                        '20px 10px 0px 0px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    *Note
                                                                </div>
                                                                {isExpressSameDayTest && (
                                                                    <RefundInfoText>
                                                                        If
                                                                        upgrading
                                                                        from a
                                                                        Standard
                                                                        Test to
                                                                        an
                                                                        Express
                                                                        Test, a
                                                                        full
                                                                        refund
                                                                        for your
                                                                        paid
                                                                        Standard
                                                                        Tests
                                                                        will be
                                                                        credited
                                                                        to the
                                                                        original
                                                                        credit
                                                                        card in
                                                                        7-15
                                                                        business
                                                                        days.
                                                                        There
                                                                        will be
                                                                        a new
                                                                        charge
                                                                        for the
                                                                        Express
                                                                        Tests.
                                                                    </RefundInfoText>
                                                                )}
                                                            </div>
                                                        )}
                                                    {(isManage && isAdmin) &&
                                                        rollbackisRapidTest ==
                                                        false &&
                                                        isRapidTest == true && (
                                                            <div
                                                                style={{
                                                                    margin:
                                                                        '20px 10px 0px 0px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    *Note
                                                                </div>
                                                                {isRapidTest && (
                                                                    <RefundInfoText>
                                                                        If
                                                                        upgrading
                                                                        from a
                                                                        Standard
                                                                        Test or
                                                                        Express
                                                                        Test to
                                                                        a Rapid
                                                                        Test, a
                                                                        full
                                                                        refund
                                                                        for your
                                                                        paid
                                                                        Standard
                                                                        Tests or
                                                                        Express
                                                                        Tests
                                                                        will be
                                                                        credited
                                                                        to the
                                                                        original
                                                                        credit
                                                                        card in
                                                                        7-15
                                                                        business
                                                                        days.
                                                                        There
                                                                        will be
                                                                        a new
                                                                        charge
                                                                        for the
                                                                        Rapid
                                                                        Tests.
                                                                    </RefundInfoText>
                                                                )}
                                                            </div>
                                                        )}
                                                    <NavigationButtons
                                                        loading={loading}
                                                        confirmBtnText={
                                                            confirmBtnText
                                                        }
                                                        onReturnButtonClick={
                                                            goBack
                                                        }
                                                    />
                                                </SlotsWrapper>
                                            </Columns>
                                        )}
                                </Form>
                            </>
                        </PageContent>
                    </Content>
                    <ChangeLocationModal
                        openModal={() => setShowChangeDatetimeModal(false)}
                    />
                    <LocationModal />
                    <ManageLocationModal />
                    <RapidAlertModal
                        form={appointment}
                        handleModalClose={handleRapidModalClose}
                        open={showRapidAlertModal}
                    />
                </Container>
            )}
        </Formik>
    );
};

export default Schedule;
