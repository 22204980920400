import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';
import { deleteEmployeeTesting } from '../../api';
import { CloseOutlined } from '@ant-design/icons';

const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
`;

interface IProps {
  isEmpTest?: boolean;
  deletes?: any;
  getEmpTesting?:() => void;
  handleSearch:()=>void;
}

const Cancel = ({ isEmpTest, deletes, getEmpTesting ,handleSearch, }: IProps) => {
  const [cancelText, setCancelText] = useState(isEmpTest === true ? 'Delete' : 'Cancel');
  const handleCancel = async () => {
      
    setCancelText('Cancel');
   
  };

  const handleDelete = async () => {
    try {
      if (isEmpTest === true) {
        setCancelText('Deleting ...');
      let data = {
          email : deletes.emailId
        }
        const del = await deleteEmployeeTesting(data)
        if(del?.data.status === 200){
          setCancelText('Deleted');      
          window.location.reload();
        }
      }
    } catch (e) {
      setCancelText('Error');
      console.error(e);
    }
  };

   if (cancelText === 'Delete') {
    return (
      <Popconfirm
        title={'Are you sure you would like to delete this email?'}
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="link"
          disabled={cancelText !== 'Delete'}
          block
          icon={<CloseOutlined />}
        >
          {cancelText}
        </Button>
      </Popconfirm>
    );
  } else {
    return <Text>{cancelText}</Text>;
  }
};

export default Cancel;
