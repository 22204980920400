import * as React from 'react';
import { Button, Card, Col, Layout, Row, Space,Table, Typography, Form } from 'antd';
import { format, isAfter } from 'date-fns';
import moment from 'moment';
import { useMemo } from 'react';
import { addTelemedicineApptViewLog, getTelemedicineAppointment, getTelemedicineAppointmentAccept, getTelemedicineInfo } from '../LocationAndService/API';
import { getAdminConfigDaywise } from '../../api';
import { styles } from '../../components/AppointmentList/styles';
import { telemedicineTableColumn, searchBarActionLayout } from './../../../admin/components/AppointmentList/constants';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { config } from '../../../../config';
import { useHistory, useParams } from 'react-router-dom';
import { useSharedDispatch } from '../../../shared/provider';
import { isEqual } from 'lodash';
import Loader from '../../../shared/components/Loader';
import TelemedicinUserInfo from './TelemedicinUserInfo';

const { Content } = Layout;
const { Title } = Typography;

const ListTelemedicine: React.FC = () => {

    const [rowdata, setrowdata] = React.useState<any>([]);

    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const params: any = useParams();
    const [open, setOpen] = React.useState(false);
    
    const handleOpen = (id:any) => setOpen(true)
    const [customerInfo, setCustomerInfo] = React.useState<any>([]);
    
    const dateFormat = 'MM/DD/YYYY';
    const { updateRecords } = useSharedDispatch();

    const [searchKeys, setSearchKeys] = React.useState<any>({
        date: moment().format('MM/DD/YYYY'),
    });

    const handleSearchKeysUpdate = (key: string) => (
        value: string | number
    ) => {
        setSearchKeys({
            ...searchKeys,
            [key]: value,
        });
    };

    const getAppointmentdata = async () => {
        getTelemedicineAppointment(searchKeys).then((res: any) => {
            if (res.status === 200) {
                let newdata = res.data.appointment.map((datas: any) => {
                    if (datas) {

                        ////console.log("datas.appointment", datas)
                        let apptdetail = {
                            ...datas,
                            date: datas.appointment.date,
                            time: moment(datas.appointment.startTime).format("h:mm A"),
                            customerName: datas.customer.name,
                            customerEmail: datas.customer.email,
                            providerName: datas.provider.name,
                            videoLink: datas.videoLinkProvider,
                            language: (datas.appointment.description !== undefined || datas.appointment.description !== null || datas.appointment.description !== "") ? datas.appointment.description.includes(':') ? datas.appointment.description.split(':')[1].split(">")[1] : datas.appointment.description : '',
                            isAccept: datas.isAccept,
                        }
                        return ({ ...apptdetail })
                    }
                    else
                        return ({ id: datas.newid, ...datas.data })
                });
                setrowdata(newdata);
                getAdminConfigDaywiseColumn();
            } else {
                //console.log(res);
            }
        }).catch(err => { 
            //console.log(err); 
        })
    }
    React.useEffect(() => {
        getAppointmentdata()
    }, []);

    //Customer Details popup

     const sortByDate = (a: any, b: any) => {
        if (a.appointmentdate !== null && b.appointmentdate !== null) {
            const date1: any = new Date(a.appointmentdate)
            const date2: any = new Date(b.appointmentdate)
            return date2 - date1
        }
        return 0;
    };
    const customerInfomedicineData = async (id: any) => {
        setLoading(true);
        getTelemedicineInfo({ id: id }).then((response: any) => {
            //////console.log("res",response)
            if (response.data.data.length > 0) {
                let newdata: any = response.data.data.sort(sortByDate)
                setCustomerInfo([{...newdata[0],...response.data.appointment[0]}]);                
                setLoading(false);
            } 
            else {
                history.push(`/dashboard/telemedicineAppt`);
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
        })
    }

    const addViewLog = async (id: any) => {
        setLoading(true);
        addTelemedicineApptViewLog({
            id: id,
            name: JSON.parse(window.localStorage.getItem('userDetails')!).name,
            email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
        }).then((response: any) => {
        }).catch((error: any) => {
            setLoading(false);
        })
    }

    async function callStack() {
        await addViewLog(params.id);
        customerInfomedicineData(params.id);
    }

    React.useEffect(() => {
        callStack();
    }, []);
    

    
    // Table telemedicine item
    const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]);

    const handleAcceptAppt = async (record: any) => {

        let data = await getTelemedicineAppointmentAccept({
            id: record.id,
            isAccept: record?.isAccept ? false : true,
            email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
            name: (record.isAccept === false || record.isAccept === undefined) && JSON.parse(window.localStorage.getItem('userDetails')!).name,
            customerName: record.customerName,
            appointmentDate: record.appointment.date,
            appointmentTime: record.time,
            appointmentId: record.appointment.id
        })
        getAppointmentdata()
    }

    const renderacceptAction = (action: any, record: any) => {
        // setLoading(true);
        const email = JSON.parse(window.localStorage.getItem('userDetails')!).email ?? ''
        if (email === record.provider.email || !record.isAccept) {
            if (isAfter(new Date(record.appointment.date), new Date()) ||
                isEqual(format(new Date(record.appointment.date), "LL/dd/yyyy"), format(new Date(), "LL/dd/yyyy"))) {
                    // setLoading(false);
                return (
                    <Button style={{ backgroundColor: record?.isAccept ? "orange" : "green", color: record?.isAccept ? "black" : "white" }}
                        type="primary" onClick={() => { handleAcceptAppt(record) }}>
                        {record.isAccept ? 'Unaccept' : 'Accept'}
                    </Button>
                );
            } else {
                return(<p style={{ color: 'red' }}>Expired</p>)
                
            }
        } else {

            return (<p>Accepted</p>)
        }
    };

    const rendercustomername = (customername: any, record: any) => {
        return (
            <>
                <a style={{ color: 'skyblue', cursor: 'pointer' }} onClick ={() => {
                    // history.push(`/dashboard/telemedicineInfo/${record.id}`);
                    customerInfomedicineData(record.id);
                    addViewLog(record.id);
                    handleOpen(record.id);
                    updateRecords(record);
                }}>
                    {record.customerName}
                </a>
            </>
        );
    }

    const renderActionsMenu = (action: any, record: any) => {
        if (isAfter(new Date(record.appointment.date), new Date()) ||
            isEqual(format(new Date(record.appointment.date), "LL/dd/yyyy"), format(new Date(), "LL/dd/yyyy"))) {
            if (record.isAccept === true && record.provider.email === JSON.parse(window.localStorage.getItem('userDetails')!).email) {
                return (
                    <Button type='primary' onClick={() => {
                        window.open(record.videoLink, '_blank');
                    }}>Join Room</Button>
                )
            }
        }
    };

    async function getAdminConfigDaywiseColumn() {
        setLoading(true);
        const tableColumnData = telemedicineTableColumn(renderActionsMenu, renderacceptAction, rendercustomername);
        setRenderTableColumn(tableColumnData);
        let response = await getAdminConfigDaywise({
            email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
        });

        if (response.data.status === 200) {
            if (response.data.data.length > 0) {
                if (
                    response.data.data[0].daywiseconfig.hideandshowcolumn.length > 0
                ) {
                    setRenderTableColumn(
                        telemedicineTableColumn(renderActionsMenu, renderacceptAction, rendercustomername)
                    );
                    setLoading(false);
                }
            } else {
                const tableColumnData = telemedicineTableColumn(renderActionsMenu, renderacceptAction, rendercustomername);
                setRenderTableColumn(tableColumnData);
                setLoading(false);
            }
        } else {
            const tableColumnData = telemedicineTableColumn(renderActionsMenu, renderacceptAction, rendercustomername);
            setRenderTableColumn(tableColumnData);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getAdminConfigDaywiseColumn();
    }, []);

    const dataSourceAppointments = useMemo(
        () =>
            rowdata
                ?.map((appointment: any, appIndex: number) => ({
                    ...appointment,
                    key: appIndex,
                })),

        [rowdata]
    );

    if (loading) {
        return <Loader />
    }

    return (
        <>
            {open&& <TelemedicinUserInfo 
                open={open}
                close={()=>setOpen(false)}
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
            />}
            <Layout
                style={{
                    marginTop: '100px',
                    backgroundColor: '#FFF',
                    border: '1px solid #f3eeee',
                    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
                }}
            >
                <div
                    style={{
                        borderRadius: '3px',
                        background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                        boxShadow:
                            '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                        margin: '0px 25px',
                        marginTop: '-25px',
                    }}
                >
                    <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
                        Telemedicine Appointment
                    </Title>
                </div>
                <Content style={styles.content}>
                    <Space direction="vertical" style={styles.spaceWidth} size="middle">
                        <Card>
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                    xl: 40,
                                    xxl: 48,
                                }}
                            >
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                >
                                    <Form.Item label="Date" name="date">
                                        <AntDDatePicker
                                            style={styles.fullWidth}
                                            disabled={loading}
                                            showToday
                                            allowClear
                                            format={dateFormat}
                                            placeholder="MM/DD/YYYY"
                                            disabledDate={(current: any) => {
                                                return current && current < moment().format("MM/DD/YYYY");
                                            }}
                                            onChange={(newDate) =>
                                                handleSearchKeysUpdate(
                                                    'date'
                                                )(
                                                    newDate
                                                        ? format(
                                                            newDate!,
                                                            config.dateFormat
                                                        )
                                                        : ''
                                                )
                                            }
                                        />
                                    </Form.Item>

                                </Col>
                                <Form.Item {...searchBarActionLayout}>
                                    <Space
                                        size="small"
                                        style={styles.searchBarActions}
                                    >
                                        <Button
                                            type="primary"
                                            loading={loading}
                                            style={{ background: "#1890ff" }}
                                            onClick={() => {
                                                getAppointmentdata()
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Space>
                                </Form.Item>
                                <Col
                                    md={{ span: 10 }}
                                >
                                    <Form.Item>
                                        <Space
                                            size="large"
                                            align='end'
                                        >
                                            <Button style={{ color: "orange", borderColor: 'orange', justifyContent: 'center' }} 
                                            onClick={() => {history.push(`/dashboard/providertelemedicine`)}}>
                                                Book appointment
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card>
                            <div style={{ padding: '25px 35px' }}>
                                <Table
                                    size='small'
                                    columns={(renderTableColumn as any)}
                                    bordered
                                    dataSource={(dataSourceAppointments as any) || []}
                                    pagination={{ pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true }}
                                    loading={loading}
                                    scroll={{ x: 1500 }}
                                    rowKey={(row: any) => row.key}
                                />
                            </div>
                        </Card>
                    </Space>
                </Content>
            </Layout>
        </>
    );
}
export default ListTelemedicine;



