import React, { useState } from 'react';
import { FileAddOutlined, FileTextFilled } from '@ant-design/icons';
import {Button, Tooltip } from 'antd';
import { Table} from "antd";
import { noteTable } from '../constants';
import  Modal  from './../../../../shared/components/Modal'
import AddNote from './AddNote';
interface inputProps {
    record: any;
    handleSearch: () => void;
}

const Notes: React.FC<inputProps> = ({ record,handleSearch }) => {
    const [openTable, setOpenTable] = useState(false);
    const [openAddNote, setOpenAddNote] = useState(false);
    const RenderTable = () => {
        return <>
            <Modal open={openTable} onClose={toggleTable} closeOnEsc maxWidth={800} closeOnOverlayClick>
                <Table pagination={false} columns={noteTable as any} dataSource={(record?.note as any) || []} />
                <hr />
                <div style={{textAlign:'right'}}>
                    <Button onClick={toggleTable}>Close</Button>&nbsp;
                    <Button onClick={()=>{toggleAddNote();toggleTable()}} type='primary'>Add Note</Button>
                </div>
            </Modal>
        </>
    }
    const toggleTable = () => setOpenTable(!openTable)
    const toggleAddNote = (number=0) => {
            if(number === 0)  
            {
                setOpenAddNote(!openAddNote); 
                if(openAddNote !== openTable) {toggleTable()}
            }
            else 
            {
                setOpenAddNote(!openAddNote);}
            } 
    return (
        <>
            {openTable && <RenderTable />}
            {openAddNote && <AddNote
                open={openAddNote}
                handleAddNoteClose={toggleAddNote}
                appointmentsData={[record]}
                handleSearch={handleSearch}
            />}
            {record?.note?.length ? (
                <Tooltip title="Click to View the Note">
                    <FileTextFilled
                        style={{ fontSize: 30 }}
                        onClick={()=>{toggleTable()}}
                    />
                </Tooltip>
            ) : (
                <Tooltip title="Click to Add Note">
                    <FileAddOutlined
                        style={{ fontSize: 30 }}
                        onClick={()=>{toggleAddNote()}}
                    />
                </Tooltip>
            )}
        </>
    );
};

export default Notes;
