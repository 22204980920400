import React, { useState } from 'react'
import { Container, Box, Divider, Grid, Paper, Typography, TextField, Button, InputAdornment } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TenantImageReview from './LogoModal';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import { useAdminDispatch, useAdminState } from '../../provider'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        hidefile: {
            display: 'none'
        },
        imgheight:{
            height:'80px',
            marginRight:'20px'
        },
        displayflex:{
            display: 'flex',
        },
        btncolor:{
            borderRadius:0,
            background: "#1890ff",
            "&:hover": {
                backgroundColor: "#40a9ff !important",
            }
        },
        color:{
            background: "#1890ff",
        },
        colorwhite:{
            color:'white'
        }
    }),
);
export default function ClientPartnerForm() {
    const history = useHistory();
    const { getAllClientPartners, getID } = useAdminState();
    const { SetID } = useAdminDispatch();
    const [update, setupdate] = useState(false);
    const [error, seterror] = useState([])
    const [alertbox, setalertbox] = useState({
        type: '',
        msg: '',
        open: false
    });
    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event: any, reason: any) => {
        setalertbox({
            type: '',
            msg: '',
            open: false
        });
    }
    const [submitform, setsubmitform] = useState(false)
    const [image, setimage] = useState<any>({
        open: false,
        imageurl: ''
    })
    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            let baseURL: any = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const [InputFormData, setInputFormData] = useState({
        CustomerPID: '',
        CustomerDate: '',
        PartnerID: '',
        PartnerName: '',
        Prefix:'',
        TenantID: '',
        Logo: '',
        isActive: false,
    });
    const newfunforerrorcheck = () => {
        let errorlist: any = [];
        if (InputFormData.CustomerPID === null || InputFormData.CustomerPID.trim() === '') {
            errorlist.push('Please Enter the CustomerPID');
        }
        if (InputFormData.CustomerDate === null || InputFormData.CustomerDate.trim() === '') {
            errorlist.push('Please Select the CustomerDate');
        }
        if (InputFormData.PartnerID === null || InputFormData.PartnerID.trim() === '') {
            errorlist.push('Please Enter the PartnerID');
        }
        if (InputFormData.PartnerName === null || InputFormData.PartnerName.trim() === '') {
            errorlist.push('Please Enter the PartnerName');
        }
        if (InputFormData.TenantID === null || InputFormData.TenantID.trim() === '') {
            errorlist.push('Please Enter the TenantID');
        }
        if (InputFormData.Prefix === null || InputFormData.Prefix.trim() === '') {
            errorlist.push('Please Enter the Prefix');
        }
        seterror(errorlist);
        return errorlist.length > 0 ? false : true;
    }
    const handleform = async (e: any) => {
        e.preventDefault();
        if (newfunforerrorcheck()) {
            setsubmitform(true);
            seterror([]);
            console.log(InputFormData);
            e.target.reset();
            let responsedata: any;
            try {
                
                if (!update) {
                    responsedata = await axios({
                        method: 'POST',
                        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/createClientPartnerData',
                        headers: {
                            'content-type': 'application/json'
                        },
                        data: {
                            'cPartnerID': InputFormData.CustomerPID,
                            'createdDT': InputFormData.CustomerDate,
                            'partnerID': InputFormData.PartnerID,
                            'partnerName': InputFormData.PartnerName,
                            'tenantid': InputFormData.TenantID,
                            'tenantlogo': InputFormData.Logo,
                            'isActive': InputFormData.isActive,
                            'prefix': InputFormData.Prefix
                        }
                    });
                }
                else
                {
                    responsedata = await axios({
                        method: 'POST',
                        url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/updateClientPartnerData',
                        headers: {
                            'content-type': 'application/json'
                        },
                        data: {
                            'cPartnerID': InputFormData.CustomerPID,
                            'createdDT': InputFormData.CustomerDate,
                            'partnerID': InputFormData.PartnerID,
                            'partnerName': InputFormData.PartnerName,
                            'tenantid': InputFormData.TenantID,
                            'tenantlogo': InputFormData.Logo,
                            'isActive': InputFormData.isActive,
                            'prefix': InputFormData.Prefix,
                            'id': getID
                        }
                    });
                    setimage({open:false,imageurl:''})
                }
                if(update)
                {
                    setTimeout(() => {
                        history.push('/dashboard/tenant');
                    }, 2000);
                }
                if (responsedata.status === 200) {
                    console.log(responsedata.data.msg);
                    setalertbox({ type: 'info', msg: responsedata.data.msg, open: true });
                    setsubmitform(false);
                    setInputFormData({
                        CustomerPID: '',
                        CustomerDate: '',
                        PartnerID: '', 
                        PartnerName: '',
                        TenantID: '',
                        Logo: '',
                        isActive: false,
                        Prefix:''
                    })
                }
                else {
                    setalertbox({ type: 'error', msg: responsedata.data.msg, open: true });
                    setsubmitform(false);
                    setInputFormData({
                        CustomerPID: '',
                        CustomerDate: '',
                        PartnerID: '',
                        PartnerName: '',
                        Prefix:'',
                        TenantID: '',
                        Logo: '',
                        isActive: false
                    })
                }
            }
            catch (err) {
                setalertbox({ type: 'error', msg: responsedata.data.msg, open: true });
                setsubmitform(false);
                setInputFormData({
                    CustomerPID: '',
                    CustomerDate: '',
                    PartnerID: '',
                    PartnerName: '',
                    Prefix:'',
                    TenantID: '',
                    Logo: '',
                    isActive: false
                });
                console.log(err);
            }
        }
    }
    const fileHandler = (e: any) => {
        let file = e.target.files[0];
        if (!(file === undefined || file === null)) {
            setalertbox({
                type: '', msg: '', open: false
            })
            if (file.size <= 1024000) {
                console.log(file.size);
                getBase64(file)
                    .then(result => {
                        file["base64"] = result;
                        let newbase64data: any = { ...InputFormData };
                        newbase64data.Logo = result;
                        setInputFormData(newbase64data);
                    })
                    .catch(err => {
                        console.log(err);
                    });
                try {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            let data1: any = { ...InputFormData }
                            data1.Logo = file;
                            setInputFormData(data1);
                            setimage({ open: true, imageurl: reader.result });
                        }
                    }
                    reader.readAsDataURL(file);
                } catch (error) { console.log(error); }
            }
            else {
                seterror([]);
                setalertbox({type:'warning',msg:'File Too Large, Not Uploading.',open:true})
                e.target.value='';
            }
        }
    }
    React.useEffect(() => {
        try {
            let ID = getID;
            let data = getAllClientPartners;
            console.log(ID);
            if(ID!==null&&ID.length>5&&ID.trim()!=='')
            {
                let newdata = data.filter((e:any)=>e.id===ID);
                newdata.length > 0 ? setupdate(true) : setupdate(false)
                newdata=newdata[0];
                
                setInputFormData({
                    CustomerPID: newdata.cPartnerID,
                    CustomerDate: newdata.createdDT,
                    PartnerID: newdata.partnerID,
                    PartnerName: newdata.partnerName,
                    Prefix: newdata.prefix,
                    TenantID: newdata.tenantid,
                    Logo: newdata.tenantlogo,
                    isActive: newdata.isActive,
                })
                setimage({open:false,imageurl:newdata.tenantlogo})
            }
            else
            {
                setupdate(false)
            }
        } catch (error) {
            console.log(error);
            setupdate(false)
        }    
    }, [])
    const classes = useStyles();

    const Listdata = ({error}: any)=>{
        return(
        <ul>
            {error.map((e: any)=>(<li>{e}</li>))}
        </ul>
        )
    }
    const inputfile: any = React.useRef();
    return (<>
        <Backdrop className={classes.backdrop} open={submitform}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={error.length>0?true:alertbox.open} onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <Alert onClose={handleClose} severity={error.length>0?'error':alertbox.type}>
                {error.length===0?alertbox.msg:''}
                {error.length>0?(<>Required Field<br /><Listdata error={error}/></>):''}
            </Alert>
        </Snackbar>
        <Container maxWidth='lg'>
            <Link to='/dashboard/tenant' >
                <IconButton  color="primary">
                    <ArrowBackIcon fontSize='large' />
                </IconButton>
            </Link>
            <Paper elevation={3}>
                {!update ? (<TenantImageReview image={image} setimage={setimage} />):''}
                <Grid xl={12}>
                    <Box m={2} p={3} className={classes.color}>
                        <Typography variant="h4" className={classes.colorwhite}>
                            {update?'Update':'Add'} Tenant
                        </Typography>
                        {/* <Divider /> */}
                    </Box>
                    <Box m={2} p={4}>
                        <form noValidate autoComplete='off' onSubmit={handleform}>
                            <Box mb={4}>
                                <TextField id="CustomerPID" onChange={(e) => {
                                    let data = { ...InputFormData };
                                    data.CustomerPID = e.target.value;
                                    setInputFormData(data)
                                }} fullWidth label="Customer Partner ID" variant="outlined" name='CustomerPID' value={InputFormData.CustomerPID} />
                            </Box>
                            <Box mb={4}>
                                <TextField
                                    onChange={(e) => {
                                        let data = { ...InputFormData };
                                        data.CustomerDate = e.target.value;
                                        setInputFormData(data)
                                        
                                    }}
                                    fullWidth
                                    value={InputFormData.CustomerDate}
                                    variant='outlined'
                                    name='CustomerDate'
                                    id="CustomerDate"
                                    label="Customer Created Date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box mb={4}>
                                <TextField onChange={(e) => {
                                    let data = { ...InputFormData };
                                    data.PartnerID = e.target.value;
                                    setInputFormData(data)
                                }} value={InputFormData.PartnerID} id="PartnerID" fullWidth label="Partner ID" variant="outlined" name='PartnerID' />
                            </Box>
                            <Box mb={4}>
                                <TextField onChange={(e) => {
                                    let data = { ...InputFormData };
                                    data.PartnerName = e.target.value;
                                    setInputFormData(data)
                                }} value={InputFormData.PartnerName} id="PartnerName" fullWidth label="Partner Name" variant="outlined" name='PartnerName' />
                            </Box>
                            <Box mb={4}>
                                <TextField onChange={(e) => {
                                    
                                    let data = { ...InputFormData };
                                    data.TenantID = e.target.value;
                                    setInputFormData(data)
                                }} value={InputFormData.TenantID} id="TenantID" fullWidth label="Tenent ID" variant="outlined" name='TenantID' />
                            </Box>
                            <Box mb={4}>
                                <TextField onChange={(e) => {

                                    let data = { ...InputFormData };
                                    data.Prefix = e.target.value;
                                    setInputFormData(data)
                                }} value={InputFormData.Prefix} id="Prefix" fullWidth label="Prefix" variant="outlined" name='Prefix' />
                            </Box>
                            <Box mb={4}>
                                {!update ? (<>
                                    <TextField type='file' inputProps={{accept: 'image/*'}} onChange={(e) => { fileHandler(e); }} id="Logo" fullWidth label="Upload Logo" InputLabelProps={{ shrink: true }} variant="outlined" name='Logo' />
                                    <Typography variant='caption' display="block" gutterBottom>
                                        Max Logo Size is 1MB.
                                    </Typography>
                                </>) : (<>
                                        <TextField inputRef={inputfile} inputProps={{ accept: 'image/*' }} className={classes.hidefile} type='file' onChange={(e) => { fileHandler(e); }} id="Logo" fullWidth label="Upload Logo" InputLabelProps={{ shrink: true }} variant="outlined" name='Logo' />
                                        <div className={classes.displayflex}>
                                            <img src={typeof image.imageurl==='object'?image.imageurl.base64:image.imageurl} alt="" className={classes.imgheight}/>
                                            <IconButton onClick={()=>inputfile.current.click()}>
                                                <CloudUploadIcon fontSize="large" color='primary' />
                                            </IconButton>
                                        </div>
                                        <br/>
                                        <Typography variant='caption' display="block" gutterBottom>
                                            Max Logo Size is 1MB.
                                        </Typography>
                                </>)}
                            </Box>
                            <Box mb={6}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Switch
                                            onChange={() => {
                                                let data = { ...InputFormData };
                                                data.isActive = !data.isActive;
                                                setInputFormData(data)
                                            }}
                                            color="primary"
                                            checked={InputFormData.isActive}
                                            name="isActive"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                    label="Is Account Active"
                                    labelPlacement="end"
                                />
                            </Box>
                            <Button variant="contained" disabled={submitform} color='secondary' className={classes.btncolor} type='submit'>{update?!submitform?'Update':'Updating':!submitform ? 'Submit' : 'Please Wait'}</Button>&nbsp;
                            <Button variant="contained" color='secondary' type='reset' className={classes.btncolor} disabled={submitform} onClick={() => {
                                setalertbox({type:'',msg:'',open:false})
                                seterror([]);
                                setInputFormData({
                                    CustomerPID: '',
                                    CustomerDate: '',
                                    PartnerID: '',
                                    PartnerName: '',
                                    Prefix:'',
                                    TenantID: '',
                                    Logo: '',
                                    isActive: false,
                                })
                            }}>Reset</Button>
                        </form>
                    </Box>
                </Grid>
            </Paper>
        </Container>
    </>);
}
