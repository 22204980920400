import { format, parse } from "date-fns";
import { config } from "../../../../config";

const formItemLayout = {
labelCol: {
  md: {
    span: 24,
  },
  lg: {
    span: 8,
  },
},
wrapperCol: {
  md: {
    span: 24,
  },
  lg: {
    span: 16,
  },
},
};
const npDestTableColumn = (renderActionsMenu?: any) => [
  {
      title: 'Location Name',
      dataIndex: 'label',
      key: 'label',
      width: '30%',
      align: 'start',
      sorter: (a: any, b: any) => a.label > b.label,
  },
  {
      title: 'Code',
      dataIndex: 'value',
      key: 'value',
      width: '10%',
      align: 'start',
      sorter: (a: any, b: any) => a.value > b.value,
  },
  {
      title: 'Actions',
      key: 'actions',
      width: '5%',
      align: 'center',
      fixed: 'right',
      render: renderActionsMenu,
  },
];

const EmployeeTestingColumn = (renderActionsMenu? :any) => [
{
  title: 'Email Address',
  dataIndex: 'emailId',
  key: 'emailId',
  width: '20%',
  align: 'start',
  sortDirections: ['descend', 'ascend', 'descend'],
  sorter: (a: any, b: any) => a?.emailId ? a?.emailId?.localeCompare(b?.emailId) : a?.emailId,
},
{
  title: 'First Name',
  dataIndex: 'firstName',
  key: 'firstName',
  width: '20%',
  align: 'start',
  sortDirections: ['descend', 'ascend', 'descend'],
  sorter: (a: any, b: any) => a?.firstName?.localeCompare(b?.firstName),
},
{
  title: 'Last Name',
  dataIndex: 'lastName',
  key: 'lastName',
  width: '20%',
  align: 'center',
  fixed: 'right',
  sortDirections: ['descend', 'ascend', 'descend'],
  sorter: (a: any, b: any) => a?.lastName?.localeCompare(b?.lastName),
},
{
  title: '',
  key: 'actions',
  width: '5%',
  align: 'center',
  fixed: 'right',
  render: renderActionsMenu,
},
];

const searchBarActionLayout = {
wrapperCol: {
  md: {
    span: 24,
  },
  lg: {
    offset: 8,
    span: 16,
  },
},
};

const formatTableColumn = (
  renderResults: any,
  renderActionsMenuTitle: any,
  renderActionsMenu: any,
  renderCheckin: any,
  renderTat: any,
  date: any,
  sortByDate: any,
  renderNotes: any,
  getColumnSearchProps?: any
) => [
  {
      title: 'Check In',
      dataIndex: 'isCanceled',
      key: 'check in',
      width: 124,
      align: 'center',
      render: renderCheckin,
  },
  {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
      width: 150,
      align: 'center',
      render: renderNotes,
  },
  {
      title: 'Test Type',
      dataIndex: 'testType',
      key: 'testType',
      width: 150,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.testType > a.testType) return -1;
          if (b.testType < a.testType) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      onFilter: (value: any, record: any) =>
          record.testType.startsWith(value),
      filterSearch: true,
      filters: [
          {
              text: config.testType.antigen,
              value: config.testType.antigen,
          },
          {
              text: config.testType.express,
              value: config.testType.express,
          },
          {
              text: config.testType.rapid,
              value: config.testType.rapid,
          },
          {
              text: config.testType.standard,
              value: config.testType.standard,
          },
          {
              text: config.testType.panel,
              value: config.testType.panel,
          },
      ],
  },
  {
      title: 'Appt Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (new Date(b.date) > new Date(a.date)) return -1;
          if (new Date(b.date) < new Date(a.date)) return 1;
          return 0;
      },
      sortDirections: ['ascend'],
  },
  {
      title: 'Appt Time',
      dataIndex: 'period',
      key: 'period',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any) => {
          if (a?.slot?.label !== undefined && b?.slot?.label !== undefined) {
              const date1: any = parse(
                  a?.slot?.label.split('-')[0] ?? '',
                  'h:mm a',
                  new Date()
              );
              const date2: any = parse(
                  b?.slot?.label.split('-')[0] ?? '',
                  'h:mm a',
                  new Date()
              );
              return date1 - date2;
          }
          return 0;
      },
      sortDirections: !date ? [] : ['ascend'],
      showSorterTooltip: !date ? false : true,
  },
  {
      title: 'Confirmation No',
      dataIndex: 'confirmationId',
      key: 'confirmationId',
      width: 195,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.confirmationId > a.confirmationId) return -1;
          if (b.confirmationId < a.confirmationId) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('confirmationId'),
  },
  {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.status > a.status) return -1;
          if (b.status < a.status) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'No Show',
      dataIndex: 'noShow',
      key: 'noShow',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (a?.noShow > b?.noShow) return 1;
          if (a?.noShow < b?.noShow) return -1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      onFilter: (value: any, record: any) => record.noShow.startsWith(value),
      filterSearch: true,

      filters: [
          {
              text: 'No Show',
              value: 'No Show',
          },
      ],
  },
  {
      title: 'Collection Method',
      dataIndex: 'collectionmethod',
      key: 'collectionmethod',
      width: 150,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.collectionmethod > a.collectionmethod) return -1;
          if (b.collectionmethod < a.collectionmethod) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'VIP Slot',
      dataIndex: 'isExpressSameDayTest',
      key: 'isExpressSameDayTest',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.isExpressSameDayTest > a.isExpressSameDayTest) return -1;
          if (b.isExpressSameDayTest < a.isExpressSameDayTest) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'Rapid',
      dataIndex: 'isRapidTestText',
      key: 'isRapidTestText',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.isRapidTestText > a.isRapidTestText) return -1;
          if (b.isRapidTestText < a.isRapidTestText) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },

  {
      title: 'Sample ID',
      dataIndex: 'sampleId',
      key: 'sampleId',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.sampleId > a.sampleId) return -1;
          if (b.sampleId < a.sampleId) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('sampleId'),
  },
  {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.firstName > a.firstName) return -1;
          if (b.firstName < a.firstName) return 1;
          return 0;
      },
      // (a: any, b: any) => a.firstName > b.firstName,

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('firstName'),
  },
  {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.lastName > a.lastName) return -1;
          if (b.lastName < a.lastName) return 1;
          return 0;
      },
      //  (a: any, b: any) => a.lastName > b.lastName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lastName'),
  },
  {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 254,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.email > a.email) return -1;
          if (b.email < a.email) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('email'),
  },
  {
      title: 'DOB',
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: 124,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (
              new Date(
                  typeof b?.birthDate === 'string'
                      ? b?.birthDate
                      : format(new Date(), 'LL/dd/yyyy')
              ) >
              new Date(
                  typeof a?.birthDate === 'string'
                      ? a?.birthDate
                      : format(new Date(), 'LL/dd/yyyy')
              )
          )
              return -1;
          if (
              new Date(
                  typeof b?.birthDate === 'string'
                      ? b?.birthDate
                      : format(new Date(), 'LL/dd/yyyy')
              ) <
              new Date(
                  typeof a?.birthDate === 'string'
                      ? a?.birthDate
                      : format(new Date(), 'LL/dd/yyyy')
              )
          )
              return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('birthDate'),
  },
  {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.phone > a.phone) return -1;
          if (b.phone < a.phone) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('phone'),
  },
  {
      title: 'Airline',
      dataIndex: 'airlineCode',
      key: 'airlineCode',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (a?.airlineCode > b?.airlineCode) return 1;
          if (a?.airlineCode < b?.airlineCode) return -1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'Travel Type',
      dataIndex: 'travelType',
      key: 'travelType',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.travelType > a.travelType) return -1;
          if (b.travelType < a.travelType) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      width: 100,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.destination > a.destination) return -1;
          if (b.destination < a.destination) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  {
      title: 'Flight Date and Time',
      dataIndex: 'departureDateAndTime',
      key: 'departureDateAndTime',
      width: 100,
      align: 'center',
      sorter: (a: any, b: any, dire: any) => {
          if (b.departureDateAndTime > a.departureDateAndTime) return -1;
          if (b.departureDateAndTime < a.departureDateAndTime) return 1;
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
  },
  // {
  //   title: 'Symptoms Reported?',
  //   dataIndex: 'symptoms',
  //   key: 'symptoms',
  //   width: 100,
  //   align: 'center',
  //   render: renderSymptoms,
  // },
  // {
  //   title: 'Contact in last 14 days?',
  //   dataIndex: 'hadContact',
  //   key: 'hadContact',
  //   width: 120,
  //   align: 'center',
  //   filters: [
  //     {
  //       text: 'Yes',
  //       value: 'Yes',
  //     },
  //     {
  //       text: 'No',
  //       value: 'No',
  //     },
  //   ],
  //   filterMultiple: false,
  //   onFilter: (value: string, record: any) =>
  //     record.hadContact.indexOf(value) === 0,
  // },
  {
      title: 'Classroom',
      dataIndex: 'classroom',
      key: 'classroom',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any) => {
          if (a && b) {
              if (b.room > a.room) return -1;
              if (b.room < a.room) return 1;
              return 0;
          }
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('room'),
  },

  {
      title: '3rd Party Appt',
      dataIndex: 'SolvHealth',
      key: 'SolvHealth',
      width: 90,
      align: 'center',
      sorter: (a: any, b: any) => {
          if (a && b) {
              if (b.SolvHealth > a.SolvHealth) return -1;
              if (b.SolvHealth < a.SolvHealth) return 1;
              return 0;
          }
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('SolvHealth'),
  },
  {
      title: 'Results',
      dataIndex: 'results',
      key: 'results',
      render: renderResults,
      width: 150,
      align: 'center',
      sorter: (a: any, b: any) => {
          if (a && b) {
              if (b.results > a.results) return -1;
              if (b.results < a.results) return 1;
              return 0;
          }
          return 0;
      },
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('results'),
  },
  {
      title: 'Turnaround Time',
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      render: renderTat,
      width: 150,
      align: 'center',
  },
  {
      title: 'Action',
      key: 'Action',
      width: 70,
      align: 'center',
      fixed: 'right',
      render: renderActionsMenu,
  },
];

const customerTable = (renderCheckin: any,getColumnSearchProps?: any) => [
{
  title: 'Email',
  dataIndex: 'email',
  key: 'email',
  width: 140,
  align: 'center',
  sorter: (a: any, b: any) => a.email > b.email,
  sortDirections: ['descend', 'ascend'],
  ...getColumnSearchProps('email'),
},
{
  title: 'Phone Number',
  dataIndex: 'phone',
  key: 'phone',
  width: 125,
  align: 'center',
  sorter: (a: any, b: any) => a.phone > b.phone,
  sortDirections: ['descend', 'ascend'],
  ...getColumnSearchProps('phone'),
},
{
  title: 'First Name',
  dataIndex: 'firstName',
  key: 'firstName',
  width: 125,
  align: 'center',
  sorter: (a: any, b: any) => a.firstName > b.firstName,
  sortDirections: ['descend', 'ascend'],
  ...getColumnSearchProps('firstName'),
},
{
  title: 'Last Name',
  dataIndex: 'lastName',
  key: 'lastName',
  width: 125,
  align: 'center',
  sorter: (a: any, b: any) => a.lastName > b.lastName,
  sortDirections: ['descend', 'ascend'],
  ...getColumnSearchProps('lastName'),
},
{
  title: 'Log in as customer',
  dataIndex: 'isCanceled',
  key: 'check in',
  width: 130,
  align: 'center',
  render: renderCheckin,
},
];

// locationTable
const locationTableColumn = (renderActionsMenu?: any, inventory?:boolean) => [
  {
    title: 'Location Name',
    dataIndex: 'name',
    key: 'name',
    // width: 15,
    align: 'start',
    sorter: (a: any, b: any) => a.name > b.name,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    // width: 11,
    align: 'center',
    sorter: (a: any, b: any) => a.city > b.city,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    // width: 8,
    align: 'center',
    sorter: (a: any, b: any) => a.state > b.state,
  },
  {
    title: 'Flow',
    dataIndex: 'travelType',
    key: 'travelType',
    // width: 8,
    align: 'center',
    sorter: (a: any, b: any) => a.travelType ? a.travelType.localeCompare(b.travelType) : a.travelType,
    },
  {
      title: 'WalkUp Link',
      dataIndex: 'walkUpLink',
      key: 'walkUpLink',
      //width: 20,
      align: 'center',
      //sorter: (a: any, b: any) => a.walkUpLink ? a.walkUpLink.localeCompare(b.walkUpLink) : a.walkUpLink,
  },
  {
    title: 'Action',
    key: 'Action',
    width: inventory === true ? 250 : 90,
    align: 'center',
    fixed: 'right',
    render: renderActionsMenu,
  },
];

// Inventory_audit log table 
const InventoryTableColumn = (renderActionsMenu?: any, inventory?:boolean) => [
{
  title: 'Date',
  dataIndex: 'createdDate',
  key: 'createdDate',
  width: 15,
  align: 'start',
  //sorter: (a: any, b: any) => a.createdDate > b.createdDate,
},
{
  title: 'User',
  dataIndex: 'name',
  key: 'name',
  width: 11,
  align: 'start',
  //sorter: (a: any, b: any) => a.name > b.name,
},
{
  title: 'Action',
  dataIndex: 'isUser',
  key: 'isUser',
  width: 8,
  align: 'start',
  //sorter: (a: any, b: any) => a.isUser > b.isUser,
},
{
  title: 'Confirmation ID',
  dataIndex: 'confirmationId',
  key: 'confirmationId',
  width: 8,
  align: 'start',
  //sorter: (a: any, b: any) => a.confirmationId > b.confirmationId
},
{
  title: 'Test Type',
  dataIndex: 'testType',
  key: 'testType',
  width: 8,
  align: 'start',
  //sorter: (a: any, b: any) => a.testType > b.testType,
},
{
  title: 'Old Count',
  dataIndex: 'oldCount',
  key: 'oldCount',
  width: 4,
  align: 'start',
  //sorter: (a: any, b: any) => a.oldCount > b.oldCount,
},
{
  title: 'New Count',
  dataIndex: 'newCount',
  key: 'newCount',
  width: 4,
  align: 'start',
  //sorter: (a: any, b: any) => a.newCount > b.newCount,
},
];
const noteTable = [
  {
    title: 'Notes',
    dataIndex: 'message',
    key: 'message',
    width:'50%'
  },
  {
    title: 'Date/Time',
    dataIndex: 'time',
    key: 'time',
    width:'20%'
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width:'30%'
  }
]
const schedularLite = (QRlink:any, swich:any, action:any, link:any, createdBy:any, updatedBy:any) => [
  {
      title: 'Location Name',
      dataIndex: 'name',
      key: 'name',
      width:'60',
      align: 'center',
      sorter: 
      (a: any, b: any, dire:any) => {
          if (a.name > b.name)
              return 1;
          if (a.name < b.name) 
              return -1;
          return 0
      },
      sortDirections: ['ascend','descend'],
  },
  {
      title: 'Location Link',
      dataIndex: 'locationLink',
      key: 'locationLink',
      width:'60',
      align: 'center',
      render:link
  },
  {
      title: 'QR Code Link',
      dataIndex: 'QRlink',
      key: 'QRlink',
      width:'15',
      align: 'center',
      render: QRlink,
  },
  {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width:'15',
      align: 'center',
      render: createdBy,
  },
  {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy.user',
      width:'15',
      align: 'center',
      render: updatedBy,
  },
  {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width:'15',
      align: 'center',
      render: swich,
  },
  {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width:'10',
      align: 'center',
      render: action,
  }
]
const specialViewUsers = (swich: any, action: any, locationName: any, editAccess: any, isWeeklyTesting:any) =>[
  {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      width:'15',
      className:'capitalize',
      align: 'center',
      sorter: 
      (a: any, b: any, dire:any) => {
          if (a.name > b.name)
              return 1;
          if (a.name < b.name) 
              return -1;
          return 0
      },
      sortDirections: ['ascend','descend'],
  },    
  {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width:'15',
      align: 'center',
      sorter: 
      (a: any, b: any, dire:any) => {
          if (a.email > b.email)
              return 1;
          if (a.email < b.email) 
              return -1;
          return 0
      },
      sortDirections: ['ascend','descend'],
  },    
  {
      title: 'Assigned Locations',
      dataIndex: 'locationName',
      key: 'locationName',
      width:'15',
      align: 'center',
      render:locationName
  },
  {
      title: 'Assigned Appointments',
      dataIndex: 'isWeeklyTesting',
      key: 'isWeeklyTesting',
      width: '10',
      align: 'center',
      render: isWeeklyTesting
  },  
  {
      title: 'Edit Access',
      dataIndex: 'isEditAccess',
      key: 'isEditAccess',
      width: '10',
      align: 'center',
      render: editAccess
  },
  {
      title: 'User Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width:'15',
      align: 'center',
      render: swich,
  },   
  {
      title: 'Action',
      dataIndex: 'location',
      key: 'location',
      width:'15',
      align: 'center',
      render: action,
  }

]
const ResultTable = (ResultOptions: any) => [{
  title: 'Test Type',
  dataIndex: 'name',
  key: 'name',
  width: '30%',
  align: 'start'
},
{
  title: 'Test ID',
  dataIndex: 'id',
  key: 'id',
  width: '30%',
  align: 'center'
},
{
  title: 'Result',
  dataIndex: 'result',
  key: 'result',
  width: '60%',
  align: 'start',
  render:ResultOptions
}]
const appointmentCheckingTable = (appointmentCheckingTable: any) => [
    {
      title: 'Confirmation No',
      dataIndex: 'confirmationId',
      key: 'confirmationId',
      width: 195,
      align: 'center',
      sorter: (a: any, b: any) => a.confirmationId > b.confirmationId,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any) => a.firstName > b.firstName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 120,
      align: 'center',
      sorter: (a: any, b: any) => a.lastName > b.lastName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'DOB',
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: 124,
      align: 'center',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: 140,
      align: 'center',
      sorter: (a: any, b: any) => a.phone > b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 254,
      align: 'center',
      sorter: (a: any, b: any) => a.email > b.email,
      sortDirections: ['descend', 'ascend'],
    },
  ];
  const telemedicineTableColumn = (renderActionsMenu?: any,renderacceptAction?:any,rendercustomername?:any) => [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // width: 15,
      align: 'start',
    //   sortDirections: ['descend', 'ascend', 'descend'],
    //   sorter: (a: any, b: any) => a.name ? a.name.localeCompare(b.name) : a.name,
          
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      // width: 11,
      align: 'start',
    //   sorter: (a: any, b: any) => a.city ? a.city.localeCompare(b.city) : a.city,
    },
    {
        title:'Language',
        dataIndex:'language',
        key:'language',
        align:'start',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      // width: 8,
      align: 'start',
	  render:rendercustomername
    //   sorter: (a: any, b: any) => a.state ? a.state.localeCompare(b.state) : a.state,
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail',
      // width: 8,
      align: 'start',
    //   sorter: (a: any, b: any) => a.travelType ? a.travelType.localeCompare(b.travelType) : a.travelType,
    },
    {
      title: 'Provider Name',
      dataIndex: 'providerName',
      key: 'providerName',
      // width: 8,
      align: 'start',
    //   sorter: (a: any, b: any) => a.travelType ? a.travelType.localeCompare(b.travelType) : a.travelType,
    },
    {
      title: 'Meeting Link',
      key: 'Action',
      width: 120,
      align: 'center',
      fixed: 'right',
      render: renderActionsMenu,
    },
	// {
	// 	title : 'Status',
	// 	key:'status',
	// 	width:120,
	// 	dataIndex:'status',
	// 	align:'center',
	// 	render:renderStatus
	// },
	{
		title:'Action',
		key:'isAccept',
		width: 120,
		dataIndex: 'isAccept',
		align:'center',
		fixed: 'right',
		render:renderacceptAction
		
	}
  ];
export {telemedicineTableColumn, appointmentCheckingTable, formItemLayout, searchBarActionLayout, formatTableColumn, customerTable, locationTableColumn, InventoryTableColumn, npDestTableColumn, EmployeeTestingColumn, noteTable, schedularLite, specialViewUsers, ResultTable };
