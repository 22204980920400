import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAdminState } from '../../provider';
import Editlocation from './EditLocation';
import Holidays from './Holidays';
import Listing from './Listing';



interface WrapperProps {
    backgroundColor: number;
  }

  export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  background: ${({
  backgroundColor,
}: WrapperProps) =>
    backgroundColor === 1 
      ? '#FFFFFF'
      : '#FFFFFF'};
  & > * {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;


export const steps = [
    Listing,
    Editlocation,
    Holidays,
];

function Locationstep() {
  const { step } = useAdminState();
  const CurrentStep = steps[step];


  return (
    <Wrapper backgroundColor={step} >
      <div>
        <CurrentStep />
      </div>
    </Wrapper>
  );
}

export default Locationstep;