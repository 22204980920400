import { format } from "date-fns";
import { config } from "../../../../config";
import { firebase } from "../../../../firebase";

export const updateSchedularLite = async (data:any) => 
{
    const user:any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
    if(user?.email)
    {
        if(data.type === 'add')
        {
            const dateAndTime = format(new Date(), config.dateTimeFormat)
            data.email = user?.email ?? '';
            data.time = dateAndTime ?? '';
        }
        if(data?.type)
        {
            return firebase
                .functions()
                .httpsCallable('schedularLite')(data);
        }else{
            const db = firebase.firestore();
            let responceSnapShot:any = await db.collection(config.firestoreCollections.locations).where('qbenchCustomerId','==',data.qbenchCustomerId).get();
                responceSnapShot = responceSnapShot.docs.map((e:any)=>({...e.data(),id:e.id}))
                for(var j = 0; j < (responceSnapShot?.length ?? 0); j++){
                    const schedularLite = {...data.data.schedularLite}
                    await db.collection(config.firestoreCollections.locations).doc(responceSnapShot[j]?.id).update({schedularLite});
                }
        }
    }    
}

export const getAllClientPartner = async () => {
    try {
        const db = firebase.firestore();
        const querySnapshot = await db
            .collection(config.firestoreCollections.clientpartner)
            .get();
        const data = querySnapshot.docs.map((doc: any) => doc.data());
        return data
    } catch (err) {

    }
}