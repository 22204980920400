import { modifyLocations, editSlotsConfigurationDummy} from "./API"
import moment from 'moment';
export type servicedata={
    name:string,
    price:string,
    description?:string,
    time:any
        }

export const slotstartdatefor30=[
    { label: '', value: '' },
    { label: '06:00 AM', value: '06:00 AM' },
    { label: '06:30 AM', value: '06:30 AM' },
    { label: '07:00 AM', value: '07:00 AM' },
    { label: '07:30 AM', value: '07:30 AM' },
    { label: '08:00 AM', value: '08:00 AM' },
    { label: '08:30 AM', value: '08:30 AM' },
    { label: '09:00 AM', value: '09:00 AM' },
    { label: '09:30 AM', value: '09:30 AM' },
    { label: '10:00 AM', value: '10:00 AM' },
    { label: '10:30 AM', value: '10:30 AM' },
    { label: '11:00 AM', value: '11:00 AM' },
    { label: '11:30 AM', value: '11:30 AM' },
    { label: '12:00 PM', value: '12:00 PM' },
    { label: '12:30 PM', value: '12:30 PM' },
    { label: '01:00 PM', value: '01:00 PM' },
    { label: '01:30 PM', value: '01:30 PM' },
    { label: '02:00 PM', value: '02:00 PM' },
    { label: '02:30 PM', value: '02:30 PM' },
    { label: '03:00 PM', value: '03:00 PM' },
    { label: '03:30 PM', value: '03:30 PM' },
    { label: '04:00 PM', value: '04:00 PM' },
    { label: '04:30 PM', value: '04:30 PM' },
]


export const slotstartdatefor30Value=[
    { label: '', value: '' },
    { label: '06:00 AM', value: '06:00a' },
    { label: '06:30 AM', value: '06:30a' },
    { label: '07:00 AM', value: '07:00a' },
    { label: '07:30 AM', value: '07:30a' },
    { label: '08:00 AM', value: '08:00a' },
    { label: '08:30 AM', value: '08:30a' },
    { label: '09:00 AM', value: '09:00a' },
    { label: '09:30 AM', value: '09:30a' },
    { label: '10:00 AM', value: '10:00a' },
    { label: '10:30 AM', value: '10:30a' },
    { label: '11:00 AM', value: '11:00a' },
    { label: '11:30 AM', value: '11:30a' },
    { label: '12:00 PM', value: '12:00p' },
    { label: '12:30 PM', value: '12:30p' },
    { label: '01:00 PM', value: '01:00p' },
    { label: '01:30 PM', value: '01:30p' },
    { label: '02:00 PM', value: '02:00p' },
    { label: '02:30 PM', value: '02:30p' },
    { label: '03:00 PM', value: '03:00p' },
    { label: '03:30 PM', value: '03:30p' },
    { label: '04:00 PM', value: '04:00p' },
    { label: '04:30 PM', value: '04:30p' },
    { label: '05:00 PM', value: '05:00p' },
    { label: '05:30 PM', value: '05:30p' },
    { label: '06:00 PM', value: '06:00p' },
    { label: '06:30 PM', value: '06:30p' },
    { label: '07:00 PM', value: '07:00p' },
    { label: '07:30 PM', value: '07:30p' },
    { label: '08:00 PM', value: '08:00p' },
    { label: '08:30 PM', value: '08:30p' },
    { label: '09:00 PM', value: '09:00p' },
    { label: '09:30 PM', value: '09:30p' },
    { label: '10:00 PM', value: '10:00p' },
    { label: '10:30 PM', value: '10:30p' },
    { label: '11:00 PM', value: '11:00p' },
    { label: '11:30 PM', value: '11:30p' }, 
]

export const slotenddatefor30 = [
    { label: '06:30 AM', value: '06:30a' },
    { label: '07:00 AM', value: '07:00a' },
    { label: '07:30 AM', value: '07:30a' },
    { label: '08:00 AM', value: '08:00a' },
    { label: '08:30 AM', value: '08:30a' },
    { label: '09:00 AM', value: '09:00a' },
    { label: '09:30 AM', value: '09:30a' },
    { label: '10:00 AM', value: '10:00a' },
    { label: '10:30 AM', value: '10:30a' },
    { label: '11:00 AM', value: '11:00a' },
    { label: '11:30 AM', value: '11:30a' },
    { label: '12:00 PM', value: '12:00p' },
    { label: '12:30 PM', value: '12:30p' },
    { label: '01:00 PM', value: '01:00p' },
    { label: '01:30 PM', value: '01:30p' },
    { label: '02:00 PM', value: '02:00p' },
    { label: '02:30 PM', value: '02:30p' },
    { label: '03:00 PM', value: '03:00p' },
    { label: '03:30 PM', value: '03:30p' },
    { label: '04:00 PM', value: '04:00p' },
    { label: '04:30 PM', value: '04:30p' },
    { label: '05:00 PM', value: '05:00p' },
    { label: '05:30 PM', value: '05:30p' },
    { label: '06:00 PM', value: '06:00p' },
    { label: '06:30 PM', value: '06:30p' },
    { label: '07:00 PM', value: '07:00p' },
    { label: '07:30 PM', value: '07:30p' },
    { label: '08:00 PM', value: '08:00p' },
    { label: '08:30 PM', value: '08:30p' },
    { label: '09:00 PM', value: '09:00p' },
    { label: '09:30 PM', value: '09:30p' },
    { label: '10:00 PM', value: '10:00p' },
    { label: '10:30 PM', value: '10:30p' },
    { label: '11:00 PM', value: '11:00p' },
    { label: '11:30 PM', value: '11:30p' },

]

export const slotstartdatefor60 = [
    { label: '06:00 AM', value: '06:00 AM' },
    { label: '07:00 AM', value: '07:00 AM' },
    { label: '08:00 AM', value: '08:00 AM' },
    { label: '09:00 AM', value: '09:00 AM' },
    { label: '10:00 AM', value: '10:00 AM' },
    { label: '11:00 AM', value: '11:00 AM' },
    { label: '12:00 PM', value: '12:00 PM' },
    { label: '01:00 PM', value: '01:00 PM' },
    { label: '02:00 PM', value: '02:00 PM' },
    { label: '03:00 PM', value: '03:00 PM' },
    { label: '04:00 PM', value: '04:00 PM' },
]

export const slotenddatefor60 = [
    { label: '07:00 AM', value: '07:00 AM' },
    { label: '08:00 AM', value: '08:00 AM' },
    { label: '09:00 AM', value: '09:00 AM' },
    { label: '10:00 AM', value: '10:00 AM' },
    { label: '11:00 AM', value: '11:00 AM' },
    { label: '12:00 PM', value: '12:00 PM' },
    { label: '01:00 PM', value: '01:00 PM' },
    { label: '02:00 PM', value: '02:00 PM' },
    { label: '03:00 PM', value: '03:00 PM' },
    { label: '04:00 PM', value: '04:00 PM' },
    { label: '05:00 PM', value: '05:00 PM' },
]

export const slotduration=[
    {label:'',value:''},
    {label:'30 MIN',value:'30'},
    {label:'1 HOUR',value:'60'},
    {label:'120 MIN',value:'120'}
]

export const slotdurationValue=[
    {label:'',value:''},
    {label:'30 min', value:'30'},
    {label:'60 min', value:'60'},
    {label:'120 min', value:'120'}
]

const countcapacity = (num:number) =>{
    let i:number=1;
    let newarray:any = [];
    for(i=0;i<=num;i++)
    {
        let newdata:{label:number,value:number}={label:i,value:i};
        newarray.push(newdata);
    }
    return newarray;
}

export const slotcapacity = (num:number)=>countcapacity(num);

const Hoursformate24 = (time:string) =>{
    let timesplit = time.split(' ');
    let sufix = timesplit[1];
    let prefix = timesplit[0];

    let newtime = prefix.split(':');
    let hours:number = parseInt(newtime[0]);
    let minit: string = newtime[1];
    if(sufix==='PM' || sufix==='pm')
    {
        if(hours===12)
            hours=12;
        else
            hours=hours+12;
    }
    let submitprefix = sufix==='PM'?'p':'a';
    return {hourI:hours,minitS:minit,hourS:newtime[0],prefixFull:prefix,prefixSmall:submitprefix}
} 


const timeformate12 =(hours:number,minit:number)=>
{
    let newtime;
    if (minit < 10) {
        newtime = '0' + minit
    }
    else {
        newtime = minit + ''
    }
    if(hours>=13)
    {
        newtime = '0'+(hours-12)+':'+newtime+'p';
    }
    else
    {
        if(hours==12)
        {
            newtime = hours + ':' + newtime + 'p';
        }
        else
        {
            if (hours < 10)
                newtime = '0' + hours + ':' + newtime + 'a';
            else
                newtime = hours + ':' + newtime + 'a';
        }
    }
    return newtime;
}

const returnlabel=(start:string,end:string)=>
{
    let newlabel:string='';
    let s = start.split(':');
    let e = end.split(':');
    let data = s[0];
    newlabel=data+':'+s[1].substring(0,2)+' ';
    if(s[1].charAt(s[1].length-1)==='a')
        newlabel+='AM - '
    else
        newlabel+='PM - '
    data=e[0];
    newlabel += data +':'+e[1].substring(0,2)+' ';
    if (e[1].charAt(e[1].length - 1) === 'a')
        newlabel += 'AM'
    else
        newlabel += 'PM'

    return newlabel;
}

const returnid30=(hour:number,minit:any)=>
{
    let index = hour-6;
    if(minit==='30')
        index = (index * 2) + 1
    else
        index = (index * 2) + 0
    return index;
}
const returnid60 = (hour: number) => {
    let index = hour - 6;
    return index;
}
const returnslotsasperperiod = (start: string, end: string, slot: string, available:number)=>
{
    let stime:any = Hoursformate24(start);
    let etime: any = Hoursformate24(end);
    const countslots = slot==='30 MIN'?2:1;
    let timeslotarray:any = [];
    let i = 1;

    let checkendtime = parseInt(etime.hourI+etime.minitS)
    let startminit = stime.minitS;
    while(parseInt((stime.hourI+i)+stime.minitS)<=checkendtime)
    {
        if(countslots===1)
        {
            if(stime.minitS==='00'&& parseInt((stime.hourI+i)+stime.minitS)<=parseInt(etime.hourI+'00'))
            {
                let startTime = timeformate12(stime.hourI+i-1,parseInt(stime.minitS))
                let endTime = timeformate12(stime.hourI + i, parseInt(stime.minitS))
                timeslotarray.push({
                    startTime, endTime, index: returnid60(stime.hourI+i-1), available, label: returnlabel(startTime,endTime)
                })
            }
            else if(stime.minitS==='30'&& parseInt((stime.hourI+i)+stime.minitS)<=parseInt(etime.hourI+'00'))
            {
                let startTime = timeformate12(stime.hourI+i,0)
                let endTime = timeformate12(stime.hourI + i+1, 0)
                timeslotarray.push({
                    startTime, endTime, index: returnid60(stime.hourI+i-1), available, label: returnlabel(startTime,endTime)
                })
            }
        }
        else
        {
            let curmin = stime.minitS;
            for(let j=1;j<=countslots;j++)
            {
                if(startminit==='30')
                {
                    if (curmin === '30') {
                        let startTime = timeformate12(stime.hourI + i - 1, 30)
                        let endTime = timeformate12(stime.hourI + i, 0)
                        timeslotarray.push({
                            startTime, endTime, index: returnid30(stime.hourI+i-1,'30'), available, label: returnlabel(startTime,endTime)
                        })
                        curmin = '00';
                    }
                    else {
                        let startTime = timeformate12(stime.hourI + i , 0)
                        let endTime = timeformate12(stime.hourI + i , 30)
                        timeslotarray.push({
                            startTime, endTime, index: returnid30(stime.hourI+i, '00'), available, label: returnlabel(startTime,endTime)
                        })
                        curmin = '30';
                    }
                }
                else
                {
                    if (curmin === '30') {
                        let startTime = timeformate12(stime.hourI + i - 1, 30)
                        let endTime = timeformate12(stime.hourI + i, 0)
                        timeslotarray.push({
                            startTime, endTime, index: returnid30(stime.hourI+i-1, '30'), available, label: returnlabel(startTime,endTime)
                        })
                        curmin = '00';
                    }
                    else {
                        let startTime = timeformate12(stime.hourI + i - 1, 0)
                        let endTime = timeformate12(stime.hourI + i - 1, 30)
                        timeslotarray.push({
                            startTime, endTime, index: returnid30(stime.hourI+i-1, '00'), available, label: returnlabel(startTime,endTime)
                        })
                        curmin = '30';
                    }
                }
            }
        }
        i++;
    }
    if (etime.minitS === '30' && countslots===2)
    {
        if(etime.minitS!==stime.minitS)
        {            
            let startTime = timeformate12(etime.hourI, 0)
            let endTime = timeformate12(etime.hourI, 30)
            timeslotarray.push({
                startTime, endTime, index: returnid30(etime.hourI, '30'), available, label: returnlabel(startTime, endTime)
            })
        }
    }
    if(stime.minitS==='30'&&countslots===2)
    {
        if (etime.minitS !== stime.minitS) {
            let startTime = timeformate12(etime.hourI-1, 30)
            let endTime = timeformate12(etime.hourI, 0)
            timeslotarray.push({
                startTime, endTime, index: returnid30(etime.hourI-1, '30'), available, label: returnlabel(startTime, endTime)
            })
        }
    }
    return timeslotarray;
}

const getSlotsConfigurationsData = (value:any,weekday:boolean,weekend:boolean)=>{
    let weekarray:any={};
    let weekarrayforrapid:any = {};
    if(value.testtype.standard||value.testtype.express){
        if (value.weeksofoperation.sun) {
            weekend=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"0":data}
        } else {
            weekarray={...weekarray,"0":[]}
        }
        if (value.weeksofoperation.mon) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"1":data}
        } else {
            weekarray={...weekarray,"1":[]}
        }
        if (value.weeksofoperation.tue) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"2":data}
        } else {
            weekarray={...weekarray,"2":[]}
        }
        if (value.weeksofoperation.wed) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"3":data}
        } else {
            weekarray={...weekarray,"3":[]}
        }
        if (value.weeksofoperation.thu) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"4":data}
        } else {
            weekarray={...weekarray,"4":[]}
        }
        if (value.weeksofoperation.fri) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"5":data}
        } else {
            weekarray={...weekarray,"5":[]}
        }
        if (value.weeksofoperation.sat) {
            weekend=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '30 MIN', parseInt(value.slotcapacity.value));
            weekarray = {...weekarray,"6":data}
        } else {
            weekarray={...weekarray,"6":[]}
        }
    }
    if(value.testtype.rapid)
    {
        if (value.weeksofoperation.sun)
        {
            weekend=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"0":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"0":[]}
        }
        if (value.weeksofoperation.mon) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"1":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"1":[]}
        }
        if (value.weeksofoperation.tue) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"2":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"2":[]}
        }
        if (value.weeksofoperation.wed) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"3":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"3":[]}
        }
        if (value.weeksofoperation.thu) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"4":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"4":[]}
        }
        if (value.weeksofoperation.fri) {
            weekday=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"5":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"5":[]}
        }
        if (value.weeksofoperation.sat) {
            weekend=true;
            let data = returnslotsasperperiod(value.fromhourofoperation.value, value.tohourofoperation.value, '1 HOUR', parseInt(value.slotcapacity.value));
            weekarrayforrapid = {...weekarrayforrapid,"6":data}
        } else {
            weekarrayforrapid={...weekarrayforrapid,"6":[]}
        }
    }
    return {data:{'standard':weekarray,'rapid':weekarrayforrapid},
    weekday,
    weekend
}
}
const configSlots = (slots: any, weekday: boolean, weekend: boolean) => {
    let weekArrayStandard: any = {}, weekArrayRapid: any = {}, weekArrayExpress: any = {}, weekArrayAntigen: any = {};
    let weekArrayStandardNp: any = {}, weekArrayRapidNp: any = {}, weekArrayExpressNp: any = {};
        if(slots.standard)
        {
            if (slots.standard?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.standard.Sun.startTime, slots.standard.Sun.endTime, slots.standard.Sun.duration, parseInt(slots.standard.Sun.capacity));
                weekArrayStandard = { ...weekArrayStandard, "0": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"0":[]}
            if (slots.standard?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standard.Mon.startTime, slots.standard.Mon.endTime, slots.standard.Mon.duration, parseInt(slots.standard.Mon.capacity));
                weekArrayStandard = { ...weekArrayStandard, "1": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"1":[]}
            if (slots.standard?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standard.Tue.startTime, slots.standard.Tue.endTime, slots.standard.Tue.duration, parseInt(slots.standard.Tue.capacity));
                weekArrayStandard = { ...weekArrayStandard, "2": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"2":[]}
            if (slots.standard?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standard.Wed.startTime, slots.standard.Wed.endTime, slots.standard.Wed.duration, parseInt(slots.standard.Wed.capacity));
                weekArrayStandard = { ...weekArrayStandard, "3": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"3":[]}
            if (slots.standard?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standard.Thu.startTime, slots.standard.Thu.endTime, slots.standard.Thu.duration, parseInt(slots.standard.Thu.capacity));
                weekArrayStandard = { ...weekArrayStandard, "4": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"4":[]}
            if (slots.standard?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standard.Fri.startTime, slots.standard.Fri.endTime, slots.standard.Fri.duration, parseInt(slots.standard.Fri.capacity));
                weekArrayStandard = { ...weekArrayStandard, "5": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"5":[]}
            if (slots.standard?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.standard.Sat.startTime, slots.standard.Sat.endTime, slots.standard.Sat.duration, parseInt(slots.standard.Sat.capacity));
                weekArrayStandard = { ...weekArrayStandard, "6": data }
            }
            else  weekArrayStandard = {...weekArrayStandard,"6":[]}

        }

        if(slots.express)
        {
            if (slots.express?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.express.Sun.startTime, slots.express.Sun.endTime, slots.express.Sun.duration, parseInt(slots.express.Sun.capacity));
                weekArrayExpress = { ...weekArrayExpress, "0": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"0":[]}
            if (slots.express?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.express.Mon.startTime, slots.express.Mon.endTime, slots.express.Mon.duration, parseInt(slots.express.Mon.capacity));
                weekArrayExpress = { ...weekArrayExpress, "1": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"1":[]}
            if (slots.express?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.express.Tue.startTime, slots.express.Tue.endTime, slots.express.Tue.duration, parseInt(slots.express.Tue.capacity));
                weekArrayExpress = { ...weekArrayExpress, "2": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"2":[]}
            if (slots.express?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.express.Wed.startTime, slots.express.Wed.endTime, slots.express.Wed.duration, parseInt(slots.express.Wed.capacity));
                weekArrayExpress = { ...weekArrayExpress, "3": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"3":[]}
            if (slots.express?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.express.Thu.startTime, slots.express.Thu.endTime, slots.express.Thu.duration, parseInt(slots.express.Thu.capacity));
                weekArrayExpress = { ...weekArrayExpress, "4": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"4":[]}
            if (slots.express?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.express.Fri.startTime, slots.express.Fri.endTime, slots.express.Fri.duration, parseInt(slots.express.Fri.capacity));
                weekArrayExpress = { ...weekArrayExpress, "5": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"5":[]}
            if (slots.express?.Sat?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.express.Sat.startTime, slots.express.Sat.endTime, slots.express.Sat.duration, parseInt(slots.express.Sat.capacity));
                weekArrayExpress = { ...weekArrayExpress, "6": data }
            }
            else  weekArrayExpress = {...weekArrayExpress,"6":[]}


        }

        if(slots.rapid)
        {
            if (slots.rapid?.Sun?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.rapid.Sun.startTime, slots.rapid.Sun.endTime, slots.rapid.Sun.duration, parseInt(slots.rapid.Sun.capacity));
                weekArrayRapid = { ...weekArrayRapid, "0": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"0":[]}
            if (slots.rapid?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapid.Mon.startTime, slots.rapid.Mon.endTime, slots.rapid.Mon.duration, parseInt(slots.rapid.Mon.capacity));
                weekArrayRapid = { ...weekArrayRapid, "1": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"1":[]}
            if (slots.rapid?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapid.Tue.startTime, slots.rapid.Tue.endTime, slots.rapid.Tue.duration, parseInt(slots.rapid.Tue.capacity));
                weekArrayRapid = { ...weekArrayRapid, "2": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"2":[]}
            if (slots.rapid?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapid.Wed.startTime, slots.rapid.Wed.endTime, slots.rapid.Wed.duration, parseInt(slots.rapid.Wed.capacity));
                weekArrayRapid = { ...weekArrayRapid, "3": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"3":[]}
            if (slots.rapid?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapid.Thu.startTime, slots.rapid.Thu.endTime, slots.rapid.Thu.duration, parseInt(slots.rapid.Thu.capacity));
                weekArrayRapid = { ...weekArrayRapid, "4": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"4":[]}
            if (slots.rapid?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapid.Fri.startTime, slots.rapid.Fri.endTime, slots.rapid.Fri.duration, parseInt(slots.rapid.Fri.capacity));
                weekArrayRapid = { ...weekArrayRapid, "5": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"5":[]}
            if (slots.rapid?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.rapid.Sat.startTime, slots.rapid.Sat.endTime, slots.rapid.Sat.duration, parseInt(slots.rapid.Sat.capacity));
                weekArrayRapid = { ...weekArrayRapid, "6": data }
            }
            else  weekArrayRapid = {...weekArrayRapid,"6":[]}
        }
        if(slots.antigen)
        {
            if (slots.antigen?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.antigen.Sun.startTime, slots.antigen.Sun.endTime, slots.antigen.Sun.duration, parseInt(slots.antigen.Sun.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "0": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"0":[]}
            if (slots.antigen?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.antigen.Mon.startTime, slots.antigen.Mon.endTime, slots.antigen.Mon.duration, parseInt(slots.antigen.Mon.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "1": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"1":[]}
            if (slots.antigen?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.antigen.Tue.startTime, slots.antigen.Tue.endTime, slots.antigen.Tue.duration, parseInt(slots.antigen.Tue.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "2": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"2":[]}
            if (slots.antigen?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.antigen.Wed.startTime, slots.antigen.Wed.endTime, slots.antigen.Wed.duration, parseInt(slots.antigen.Wed.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "3": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"3":[]}
            if (slots.antigen?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.antigen.Thu.startTime, slots.antigen.Thu.endTime, slots.antigen.Thu.duration, parseInt(slots.antigen.Thu.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "4": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"4":[]}
            if (slots.antigen?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.antigen.Fri.startTime, slots.antigen.Fri.endTime, slots.antigen.Fri.duration, parseInt(slots.antigen.Fri.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "5": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"5":[]}
            if (slots.antigen?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.antigen.Sat.startTime, slots.antigen.Sat.endTime, slots.antigen.Sat.duration, parseInt(slots.antigen.Sat.capacity));
                weekArrayAntigen = { ...weekArrayAntigen, "6": data }
            }
            else  weekArrayAntigen = {...weekArrayAntigen,"6":[]}

        }
        if(slots.standardNp)
        {
            if (slots.standardNp?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.standardNp.Sun.startTime, slots.standardNp.Sun.endTime, slots.standardNp.Sun.duration, parseInt(slots.standardNp.Sun.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "0": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"0":[]}
            if (slots.standardNp?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standardNp.Mon.startTime, slots.standardNp.Mon.endTime, slots.standardNp.Mon.duration, parseInt(slots.standardNp.Mon.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "1": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"1":[]}
            if (slots.standardNp?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standardNp.Tue.startTime, slots.standardNp.Tue.endTime, slots.standardNp.Tue.duration, parseInt(slots.standardNp.Tue.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "2": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"2":[]}
            if (slots.standardNp?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standardNp.Wed.startTime, slots.standardNp.Wed.endTime, slots.standardNp.Wed.duration, parseInt(slots.standardNp.Wed.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "3": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"3":[]}
            if (slots.standardNp?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standardNp.Thu.startTime, slots.standardNp.Thu.endTime, slots.standardNp.Thu.duration, parseInt(slots.standardNp.Thu.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "4": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"4":[]}
            if (slots.standardNp?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.standardNp.Fri.startTime, slots.standardNp.Fri.endTime, slots.standardNp.Fri.duration, parseInt(slots.standardNp.Fri.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "5": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"5":[]}
            if (slots.standardNp?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.standardNp.Sat.startTime, slots.standardNp.Sat.endTime, slots.standardNp.Sat.duration, parseInt(slots.standardNp.Sat.capacity));
                weekArrayStandardNp = { ...weekArrayStandardNp, "6": data }
            }
            else  weekArrayStandardNp = {...weekArrayStandardNp,"6":[]}

        }
        if(slots.expressNp)
        {
            if (slots.expressNp?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.expressNp.Sun.startTime, slots.expressNp.Sun.endTime, slots.expressNp.Sun.duration, parseInt(slots.expressNp.Sun.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "0": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"0":[]}
            if (slots.expressNp?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.expressNp.Mon.startTime, slots.expressNp.Mon.endTime, slots.expressNp.Mon.duration, parseInt(slots.expressNp.Mon.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "1": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"1":[]}
            if (slots.expressNp?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.expressNp.Tue.startTime, slots.expressNp.Tue.endTime, slots.expressNp.Tue.duration, parseInt(slots.expressNp.Tue.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "2": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"2":[]}
            if (slots.expressNp?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.expressNp.Wed.startTime, slots.expressNp.Wed.endTime, slots.expressNp.Wed.duration, parseInt(slots.expressNp.Wed.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "3": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"3":[]}
            if (slots.expressNp?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.expressNp.Thu.startTime, slots.expressNp.Thu.endTime, slots.expressNp.Thu.duration, parseInt(slots.expressNp.Thu.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "4": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"4":[]}
            if (slots.expressNp?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.expressNp.Fri.startTime, slots.expressNp.Fri.endTime, slots.expressNp.Fri.duration, parseInt(slots.expressNp.Fri.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "5": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"5":[]}
            if (slots.expressNp?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.expressNp.Sat.startTime, slots.expressNp.Sat.endTime, slots.expressNp.Sat.duration, parseInt(slots.expressNp.Sat.capacity));
                weekArrayExpressNp = { ...weekArrayExpressNp, "6": data }
            }
            else  weekArrayExpressNp = {...weekArrayExpressNp,"6":[]}

        }
         if(slots.rapidNp)
        {
            if (slots.rapidNp?.Sun?.check) {
                weekend = true;
                let data = returnslotsasperperiod(slots.rapidNp.Sun.startTime, slots.rapidNp.Sun.endTime, slots.rapidNp.Sun.duration, parseInt(slots.rapidNp.Sun.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "0": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"0":[]}
            if (slots.rapidNp?.Mon?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapidNp.Mon.startTime, slots.rapidNp.Mon.endTime, slots.rapidNp.Mon.duration, parseInt(slots.rapidNp.Mon.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "1": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"1":[]}
            if (slots.rapidNp?.Tue?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapidNp.Tue.startTime, slots.rapidNp.Tue.endTime, slots.rapidNp.Tue.duration, parseInt(slots.rapidNp.Tue.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "2": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"2":[]}
            if (slots.rapidNp?.Wed?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapidNp.Wed.startTime, slots.rapidNp.Wed.endTime, slots.rapidNp.Wed.duration, parseInt(slots.rapidNp.Wed.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "3": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"3":[]}
            if (slots.rapidNp?.Thu?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapidNp.Thu.startTime, slots.rapidNp.Thu.endTime, slots.rapidNp.Thu.duration, parseInt(slots.rapidNp.Thu.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "4": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"4":[]}
            if (slots.rapidNp?.Fri?.check) {
                weekday = true;
                let data = returnslotsasperperiod(slots.rapidNp.Fri.startTime, slots.rapidNp.Fri.endTime, slots.rapidNp.Fri.duration, parseInt(slots.rapidNp.Fri.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "5": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"5":[]}
            if (slots.rapidNp?.Sat?.check) {
                weekend= true;
                let data = returnslotsasperperiod(slots.rapidNp.Sat.startTime, slots.rapidNp.Sat.endTime, slots.rapidNp.Sat.duration, parseInt(slots.rapidNp.Sat.capacity));
                weekArrayRapidNp = { ...weekArrayRapidNp, "6": data }
            }
            else  weekArrayRapidNp = {...weekArrayRapidNp,"6":[]}

        }

    return {
        data: {
            'standard': weekArrayStandard, 'express': weekArrayExpress, 'rapid': weekArrayRapid,
            'antigen': weekArrayAntigen,
            'standardNp': weekArrayStandardNp,
            'expressNp': weekArrayExpressNp,
            'rapidNp': weekArrayRapidNp
        },
        weekday,
        weekend
    }
}

const handleHolidays = (holidays: any)=>{
    const blockOutDays: any =[]
    const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
    for(const row of holidays){
        if(row.startDay && row.endDay){
        const start: number = new Date(row.startDay).getTime();
        const end: number = new Date(row.endDay).getTime();
        const daysBetweenDates: number = Math.ceil((end - start) / MS_PER_DAY);
        
        // The days array will contain a Date object for each day between dates (inclusive)
       const dates =  Array.from(new Array(daysBetweenDates + 1), 
            (v, i) => moment(new Date(start + (i * MS_PER_DAY))).format('MM/DD/yyyy'));
        
        dates.forEach((date)=> blockOutDays.push(date))
    }

    }
    return blockOutDays;

    
 
    }


export const finalvalue = async(value:any,type:string,id:any=null,qbenchId?:any)=>{
   
    const blockOutDays = value.holidayList.length? handleHolidays(value.holidayList): [];  
    let visibleWeekArray=[]
    let day:any;
    let isDayFilter = false;
    let weekMap=["sun","mon","tue","wed","thu","fri","sat"]
    for( day in value.weeklyVisibleDay){
        if(value.weeklyVisibleDay[day]){
         visibleWeekArray.push(weekMap.indexOf(day))
        }
    }
        
    let weekWiseVisibleNumber={weekDay:0,weekNumber:0};
    
    if(value.dayFromWeeklyVisibleNumber && value.weekNumber && value.weekNumber!="0"){
        weekWiseVisibleNumber.weekDay=weekMap.indexOf(value.dayFromWeeklyVisibleNumber.toLowerCase())
        weekWiseVisibleNumber.weekNumber=parseInt(value.weekNumber)
        isDayFilter= true
    }
    
    let weekday=false;
    let weekend=false;
    let objects = configSlots(value.slotConfig,weekday, weekend);
    //let objects = getSlotsConfigurationsData(value,weekday,weekend);
    let weekarray = objects.data;
    weekday=objects.weekday;
    weekend=objects.weekend;
    let newdata = {
        medicalDirector:value.medicalDirector,
        performingfacility:value.locationname,
        visibleSince: value.visibleSince,
        startDate: value.delayopenstartdate,
        name: value.locationname,
        isDomestic: value.traveltypes.domestic?true:false,
        rapidTest: value.testtype.rapidTest ? true : false,
        standardTest:value.testtype.standardTest?true:false,
        isExpressSameDay: value.testtype.expressTest ? true : false,
        expressTest: value.testtype.expressTest ? true : false,
        isInsurance: value.acceptance.value?true:false,
        isAssay: value.qbenchassay.value?true:false,
        GMT:new Date().getTimezoneOffset().toString(),
        isWeekDay:weekday,
        isWeekEnd:weekend,
        isInternational: value.traveltypes.international ? true : false,
        address1:value.address1,
        CLIA:'',
        cPartnerID:value.cPartnerID?value.cPartnerID.value:'WSL001',
        timezone:'',
        isOpened: value.sitestatus.value?true:false,
        address2: value.address2,
        qbenchCustomerId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
        qbenchAssayId: parseInt(value.qbenchassay.value),
        hasVipSlots: value.testtype.expressTest?true:false,
        haCustomerOnly:true,
        loactionlink: value.googlemapshortlink,
        city: value.city,
        state: value.state.value,
        zipcode:value.zipcode,
        country:value.country.value,
        isTravel:value.tenentTypes.travel? true: false,
        isGeneral:value.tenentTypes.general? true: false,
        isSchool:value.tenentTypes.school? true: false,
        isCommunity:value.tenentTypes.isCommunity? true: false,
        isWork:value.tenentTypes.work? true: false,
        limsinfo:{
            label:
            "",
            orgId:
            "",
            testId:
            "",
            type:
            "qbench"
        },
        weeklyVisibleDays:visibleWeekArray,
        weeklyVisibleNumber:weekWiseVisibleNumber,
        // slotduration: value.slotduration.value,
        isDayFilter: isDayFilter,
        isWeeklyFlag: true,
        blockOutDays : blockOutDays,
        holidays: value.holidayList,
        isAntigen: value.testtype.antigenTest ? true : false,
        isStandardNp: value.testtype.standardNpTest ? true : false,
        isRapidNp: value.testtype.rapidNpTest ? true : false,
        isExpressNp: value.testtype.expressNpTest ? true : false
    }
    let configdatarapid = {
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.rapid
        }
    }
    let configdatastandard={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.standard
        }
    }
    let configdataexpress={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.express
        }
    }
    let configDataAntigen={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.antigen
        }
    }
    let configDataStandardNp={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.standardNp
        }
    }
    let configDataExpressNp={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.expressNp
        }
    }
    let configDataRapidNp={
        [parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)]: {
            locationId: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId),
            slotsByDay: weekarray.rapidNp
        }
    }
    let configdata={
        standard:configdatastandard,
        rapid:configdatarapid,
        express: configdataexpress,
        antigen: configDataAntigen,
        standardNp: configDataStandardNp,
        rapidNp: configDataRapidNp,
        expressNp: configDataExpressNp
    }
    let finalformdata={
        id,
        type,
        data:newdata,
        configdata:configdata,
        configid: parseInt(value.qbenchcustomer.value && value.qbenchcustomer.value !==undefined && value.qbenchcustomer.value !== null ? value.qbenchcustomer.value : qbenchId)
    }
    console.log('finalformdata',finalformdata);
    let msg={};
    await modifyLocations(finalformdata).then((response:any)=>{
        if(response.status===200)
            msg = { ...msg, status: true, msg: response.data.msg}
        else
            msg = { ...msg, status: false, msg: 'Something Wents Wrong' }
    }).catch(err =>{msg = { ...msg, status: false, msg: 'Something Wents Wrong' }});
    return msg;
}


// export const editSlotConfiguration = async(value:any,type:string,id:any=null,isNp:boolean)=>{
//     console.log(value);
    
//     let finalformdata = {
//         locationId: id,
//         testType: type,
//         slotDetails: value,
//         isNpDestination: isNp
//     }
//     console.log('finalformdata',finalformdata);
//     let msg={};
//     await editSlotsConfiguration({data: finalformdata}).then((response:any)=>{
//         if(response.status===200)
//             msg = { ...msg, status: true, msg: response.data.msg}
//         else
//             msg = { ...msg, status: false, msg: 'Something Wents Wrong' }
//     }).catch(err =>{msg = { ...msg, status: false, msg: 'Something Wents Wrong' }});
//     return msg;
// }

export const editSlotConfigurationDummy = async (value: any, type: string, id: any = null,cPartnerID:string,isNp:boolean)=>{
    console.log(value);
    
    let finalformdata = {
        locationId: id,
        testType: type,
        slotDetails: value,
        cPartnerID: cPartnerID,
        isNpDestination: isNp
    }
    console.log('finalformdata',finalformdata);
    let msg={};
    await editSlotsConfigurationDummy({data: finalformdata}).then((response:any)=>{
        if(response.status===200)
            msg = { ...msg, status: true, msg: response.data.msg}
        else
            msg = { ...msg, status: false, msg: 'Something Wents Wrong' }
    }).catch(err =>{msg = { ...msg, status: false, msg: 'Something Wents Wrong' }});
    return msg;
}

export const getEditDetails = ({qbenchCustomerId,rapidTest,hasVipSlots, isAntigen, isStandardNp, isRapidNp, isExpressNp}:any,slots:any)=>{
    let startTime:number = 2000,endTime:number=0,weekDays:any=[],countslot=0, stime='',etime='';
    let slotsData;
    if(slots.length>0)
    {
        const reduceFunction=(row:any)=>{
           const weekDays:any=[]
            let rowData =row?.[qbenchCustomerId]?.slotsByDay;
            if(rowData)
            {
                for(let i=0;i<=6;i++)
                {
                    if(row?.[qbenchCustomerId]?.slotsByDay[i].length)
                    {
                        if(!weekDays.includes(i)) weekDays.push(i);
                        let slotlist = row?.[qbenchCustomerId].slotsByDay[i];
                        for(let number=0;number<=slotlist.length-1;number++)
                        {
                            let slotcounts = parseInt(slotlist[number].available);
                            if(slotcounts>countslot)countslot=slotcounts;
                        }
                        let starttime:any = slotlist[0]?.label.split(' - ')[0];
                        let endtime:any = slotlist[slotlist.length-1]?.label.split(' - ')[1];
                        let newstarttime = Hoursformate24(starttime);
                        let newendtime = Hoursformate24(endtime);
                                            
                        if(parseInt(newstarttime.hourI+newstarttime.minitS)<=startTime)
                        {
                            startTime = parseInt(newstarttime.hourI+newstarttime.minitS)
                            stime = starttime;
                        }
                        if(parseInt(newendtime.hourI+newendtime.minitS)>=endTime)
                        {
                            endTime=parseInt(newendtime.hourI+newendtime.minitS)
                            etime=endtime;
                        }
                    }
                }
            }
            let weekMapArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const duration= row.testType==='rapid'? '1 HOUR': '30 MIN'
            const obj: any= {}
            if(weekDays.length){
            weekDays.map((day: any) => {
                obj[weekMapArr[day]] = {"check": true, "startTime": stime, "endTime":etime, "capacity":countslot, "duration": duration}

            })
            return obj;}
            else return;
        }
        let rapidData, expressData, standardData, antigenData, standardNpData, expressNpData, rapidNpData;

        slots.map((row:any) => {   
            if(row.testType==='rapid'&&rapidTest)
            {
              rapidData = reduceFunction(row);
            }
            if(row.testType==='express'&&hasVipSlots)
            {
              expressData =  reduceFunction(row);
            }
            if(row.testType==='standard')
            {
               standardData = reduceFunction(row);
            }
            if(row.testType==='antigen'&& isAntigen)
            {
               antigenData = reduceFunction(row);
            }
            if(row.testType==='standardNp' && isStandardNp)
            {
               standardNpData = reduceFunction(row);
            }
            if(row.testType==='expressNp' && isExpressNp)
            {
               expressNpData = reduceFunction(row);
            }
            if(row.testType==='rapidNp' && isRapidNp)
            {
               rapidNpData = reduceFunction(row);
            }
        });

        let antigenSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let standardSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let standardNpSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let rapidSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let rapidNpSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let expressSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        let expressNpSample = { 'Sun': {}, 'Mon': {}, 'Tue': {}, 'Wed': {}, 'Thu': {}, 'Fri': {}, 'Sat': {} };
        slotsData = {
            rapid: rapidData? rapidData: rapidSample,
            express: expressData? expressData: expressSample,
            standard: standardData? standardData: standardSample,
            rapidNp: rapidNpData? rapidNpData: rapidNpSample,
            expressNp: expressNpData? expressNpData: expressNpSample,
            standardNp: standardNpData? standardNpData: standardNpSample ,
            antigen: antigenData ? antigenData: antigenSample
        }
    }    

    let finaldata = {
        fromhourofoperation:{label:stime,value:stime},
        tohourofoperation:{label:etime,value:etime},
        slotcapacity:{label:countslot,value:countslot},
        weeksofoperation:{
            tue: weekDays.includes(2),
            mon: weekDays.includes(1),
            sun: weekDays.includes(0),
            wed: weekDays.includes(3),
            thu: weekDays.includes(4),
            fri: weekDays.includes(5),
            sat: weekDays.includes(6)
        }
    }
    console.log("inside model", slotsData);
    
    return {finaldata: finaldata, slots: slotsData? slotsData: {}};
}

