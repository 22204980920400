import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { config } from '../../../../config';

import InsertInvitation from '@material-ui/icons/InsertInvitation';
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Select,
    Typography,
} from 'antd';
import GridItem from '../AppointmentList/GridItem';
import GridContainer from '../AppointmentList/GridContainer';

import CustomTabs from '../AppointmentList/CustomTabs_Slot';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { useSharedState } from '../../../shared/provider';

import { has } from 'lodash';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    })
);

const { Option } = Select;

function SlotOccupancy() {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), 'LL/dd/yyyy')
    );
    const [slotoccupancyresult, setslotoccupancyresult] = useState([]);
    const [loc, setLoc] = useState('');
    const [loading, setLoading] = useState(false);
    const [length, setlength] = useState(null);
    const [expand, setExpand] = useState(true);

    const { locations } = useSharedState();

    const [searchKeys, setSearchKeys] = useState({
        testType: null,
        date: format(new Date(), config.dateFormat),
        locationId: null,
    });

    const handleSearchKeysUpdate = (key: string) => (
        value: string | number
    ) => {
        setSearchKeys({
            ...searchKeys,
            [key]: value,
        });
    };

    function getslotoccupany() {
        setLoading(true);
        axios({
            method: 'post',
            url:
                // 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getSlotAvailability',
                `${config.cloudRun.baseUrl}getSlotAvailability`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'ynrc2f9h7dpyd4ybyyfxm68t799xqa',
            },
            data: searchKeys,
        })
            .then(function (response) {
                console.log('slotRes', response)
                if (response.status == 200) {
                    setlength(response.data.data.length);
                    setslotoccupancyresult(response.data.data);
                    setLoading(false);
                    return true;
                } else {
                    setslotoccupancyresult([]);
                    setLoading(false);
                }
            })
            .catch(() => {
                setslotoccupancyresult([]);
                setLoading(false);
            });
    }

    const handleSearch = () => {
        getslotoccupany();
    };

    const TestType: any = [
        { name: 'Rapid', value: 'rapid' },
        { name: 'Standard', value: 'standard' },
        { name: 'Express', value: 'express' },
    ];

    const locName = (name: any) => {
        const locs: any = locations?.find((e) => e.qbenchCustomerId == name);
        setLoc(locs?.name);
    };

    return (
        <div>
            {/* <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Birthday"
        type="date"
        value={selectedDate}
        
          onChange={handleDateChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form> */}
            <Form style={{ marginLeft: '5%' }}>
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                        xl: 40,
                        xxl: 48,
                    }}
                >
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item label="Slot Date" name="date">
                            <AntDDatePicker
                                style={{ width: '80%' }}
                                disabled={false}
                                defaultValue={new Date(selectedDate)}
                                showToday
                                onChange={(newDate: any) => {
                                    setSelectedDate(
                                        format(newDate, 'LL/dd/yyyy')
                                    );
                                    handleSearchKeysUpdate('date')(
                                        format(newDate!, config.dateFormat)
                                    );
                                }}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item label="Location" name="location">
                            <Select
                                style={{ width: '80%' }}
                                disabled={loading}
                                allowClear
                                placeholder="Please select"
                                showArrow
                                onChange={(e: any) => {
                                    handleSearchKeysUpdate('locationId')(e);
                                    locName(e);
                                    setslotoccupancyresult([]);
                                }}
                            >
                                {locations?.map((location) => (
                                    <Option
                                        key={location.qbenchCustomerId}
                                        value={location.qbenchCustomerId}
                                    >
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item label="TestType" name="testType">
                            <Select
                                style={{ width: '80%' }}
                                disabled={loading}
                                allowClear
                                placeholder="Please select"
                                showArrow
                                onChange={handleSearchKeysUpdate('testType')}
                            >
                                {TestType?.map((TestTypes: any) => (
                                    <Option
                                        key={TestTypes.value}
                                        value={TestTypes.value}
                                    >
                                        {TestTypes.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        span={18}
                        style={{
                            marginLeft: expand ? '0%' : '65%',
                        }}
                    ></Col>
                    <Col span={6} style={{ marginLeft: expand ? '0%' : '65%' }}>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={handleSearch}
                            disabled={
                                searchKeys.testType &&
                                    searchKeys.locationId &&
                                    searchKeys.date
                                    ? false
                                    : true
                            }
                        >
                            Search
                        </Button>{' '}
                    </Col>
                </Row>
            </Form>
            <GridContainer>
                <GridItem>
                    <CustomTabs
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: `Slot Date : ${selectedDate}`,
                                tabIcon: InsertInvitation,
                                tabContent: (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Booked</TableCell>
                                                <TableCell>Available</TableCell>
                                                <TableCell>Capacity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {slotoccupancyresult.length === 0 &&
                                            loc && (
                                                <>
                                                    <TableRow>
                                                        <div
                                                            style={{
                                                                margin: '5% 2%',
                                                            }}
                                                        >
                                                            <b>
                                                                Location: {loc}{' '}
                                                            </b>
                                                        </div>
                                                    </TableRow>
                                                    {length == 0 && (
                                                        <h2
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            No Data Found
                                                        </h2>
                                                    )}
                                                </>
                                            )}
                                        {slotoccupancyresult.map(
                                            (model: any, index: number) => (
                                                <TableBody>
                                                    {index == 0 ? (
                                                        <TableRow>
                                                            <div
                                                                style={{
                                                                    margin:
                                                                        '5% 2%',
                                                                }}
                                                            >
                                                                <b>
                                                                    Location:{' '}
                                                                    {loc}{' '}
                                                                </b>
                                                            </div>
                                                        </TableRow>
                                                    ) : (
                                                        false
                                                    )}
                                                    <div
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    ></div>
                                                    {model?.label ? (
                                                        <TableRow>
                                                            <TableCell>
                                                                {model.label}
                                                            </TableCell>
                                                            <TableCell>
                                                                {model.booked}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    model.availableCapacity
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    model.available
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        false
                                                    )}
                                                </TableBody>
                                            )
                                        )}
                                    </Table>
                                ),
                            },
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default SlotOccupancy;
