import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { sortedLastIndexOf } from "lodash";
import { setMaxListeners } from "process";

export const stylesForListing = makeStyles({
    button:{
        border: '2px solid #4d41d5',
        borderRadius:'5px',
        color:'#4d41d5',
        padding: '20px 0px',
        fontSize:'20px',
        marginBottom:'30px',
        fontWeight:400,

    },
   
    borderonly:{
        border: '1px solid #b4b4bb',
        borderRadius:0,
        marginBottom:'10px',
        padding:'10px'
    },
    editbtn:{
        
        color:'#4d41d5',
        border:'1px solid #4d41d5',
        fontwei:500,
        width:'max-content',
        
    },

    heading:{
        color:'rgb(0 0 0 / 85%)',
        marginBottom:'30px',
        fontWeight:'bold',
    },
    paperBorder:{
        border: '1px solid #d2d2d6',
        borderRadius: 0,
        padding: '20px',
        marginBottom:'30px'
    },
    fontweight400:{
        fontWeight:400
    },
    fontweight500: {
        fontWeight: 500
    },
    fontweight600: {
        fontWeight: 600
    },
    fontweight700: {
        fontWeight: 700
    },
    fontweight800: {
        fontWeight: 800
    },
    fontweight900: {
        fontWeight: 900
    },
    fontweightbold: {
        fontWeight: 'bold'
    },
    errormsg:{
        marginTop: '-7px',
        fontSize:'13px',
        color: '#FF5A60',
        transition: 'opacity 0.15s ease-in-out',
        position: 'absolute',
    },
    errormsg1:{
        marginTop: '35px',
        fontSize: '13px',
        color: '#FF5A60',
        transition: 'opacity 0.15s ease-in-out',
        position: 'absolute',
    },
    errormsg2: {
        marginTop: '-32px',
        fontSize: '13px',
        color: '#FF5A60',
        transition: 'opacity 0.15s ease-in-out',
        position: 'absolute',
    },
    btncontain:
    {
        width:'300px',
        backgroundColor:'#1890ff',
        fontSize: '13px',
        color:'white',
        fontWeight:900,
        '&:focus,&:hover': {
        backgroundColor: '#1890ff'
        }
    },
    btnoutline:{
        borderColor:'#1890ff',
        color:'#1890ff',
        fontSize:16,
        fontWeight:800,
        width: '290px',



    },
    textcenter:{
        textAlign:'center',
        border: '1px solid #d2d2d6',
        borderRadius: 0,
        padding: '20px',
        marginBottom: '30px'
    },
    backbtn:{
        margin:'14px 0px 7px -15px',
    },

    table: {
        padding:'1.1em',
        marginBottom: '5px',
        border:'inset'
    },
    td: {
        border:'1px'
    },
    addbtn:{
        border: '2px  #1890ff',
        borderRadius:'6px',
        width:'100px',
        background:'#1890ff',
        display:'inherit',
        color: 'white',
        '&:hover': {
            background:'#1890ff',
        }
    },

    QbenchLocationButton: {
        border: '1px solid #1890ff',
        color: '#1890ff',
        borderRadius: '3px'
    },
    flex50: {
        display: 'flex',
        width: '50%'
    },
    QbenchLocationItem: {
        display: 'flex',
        margin: '20px 0',
        width: '800px'
    }, 
    textEnd: {
        textAlign: 'end'

    },
    flex35: {
        display: 'flex',
        width: '35%'
    },
    flex30: {
        display: 'flex',
        width: '30%'
    },
  
  
    
//   backgroud1:{
//     backgroundColor:'red'
//   },
//   background2:{
//       backgroundColor:'black',
//       color:'white',
//   },
//   background3:{
//       backgroundColor:'pink'
//   }
})  
