import * as React from 'react';
import { Form, } from 'antd';
import { format } from 'date-fns';
import AntDDatePicker from './AntDDatePicker';
import Loader from './Loader';
import Button from "@material-ui/core/Button";
import CardHeader from '../../admin/components/AppointmentList/Card/CardHeader';
import CardBody from "../../admin/components/AppointmentList/Card/CardBody";
import Card from "../../admin/components/AppointmentList/Card/Card.js";
import { Box, Typography } from '@material-ui/core';
import { exportDiscountAmoutData, getDiscountAmountDetail } from '../../admin/api';
import { config } from '../../../config';
import { cloudRunPost } from '../api';


export interface DiscountReportProps {
}
export default function DiscountReport(props: DiscountReportProps) {

    const [selectedFromDate, setSelectedFromDate] = React.useState(format(new Date(), config.dateFormat));
    const [selectedToDate, setSelectedToDate] = React.useState(format(new Date(), config.dateFormat));
    const [loader, setloader] = React.useState(false);
    const [listofdata, setlistofdata] = React.useState<[]>([]);
    const [searched, setsearched] = React.useState(false);
    const handleselectionfromdate = (date: any) => {
        var toDates = date
        setSelectedFromDate(format(toDates, config.dateFormat))
        var myFutureDate = new Date(date);
        var myFutureDates = new Date(myFutureDate.setDate(myFutureDate.getDate() + 7))

        setSelectedToDate(format(myFutureDates, config.dateFormat))
    }
    const handlesearch = async (e: any) => {
        setloader(true);
        setsearched(false);
        // getMysqlDiscountAmountDetails(selectedFromDate,selectedToDate).then((res: any) => { 
        let discountReportData;
        // try {
        //     let params = {
        //         fromDate: selectedFromDate,
        //         toDate: selectedToDate,
        //     }
        //     const response = await cloudRunPost(config.cloudRunApi.getMysqlDiscountAmountDetails, params);
        //     if (response) {
        //         discountReportData = response.data
        //     } else {
        //         console.log('Response is not received')
        //     }
        // } catch (error) {
        //     console.log(error)
        // }
        discountReportData = await getDiscountAmountDetail(selectedFromDate, selectedToDate)
        //Setting the value to state
        if (discountReportData.length > 0) {
            setlistofdata(discountReportData);
            setloader(false);
            setsearched(true)
        } else {
            setloader(false);
            setsearched(false);
        }

        // })
    }

    React.useEffect(() => {
        var todate = new Date();
        var todateselect = new Date(todate.setDate(todate.getDate() + 7))
        setSelectedToDate(format(new Date(), config.dateFormat))
        setSelectedFromDate(format(new Date(), config.dateFormat))
    }, []);

    const downloaddata = (data: any) => {
        // let newarray: any = []
        // data.map((e: any) => {
        //     newarray.push(e.data)
        // })
        exportDiscountAmoutData(data, selectedFromDate)
    }
    return (
        <>
            <div id="container" >
                <div style={{ float: "left", width: "24%", paddingTop: '5px', paddingBottom: '15px' }}>
                    <Form style={{ marginLeft: "5%" }}>
                        <Form.Item label="Report Date" name="fromdate">
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                format={config.dateFormat}
                                disabled={false}
                                defaultValue={new Date(selectedFromDate)}
                                showToday
                                onChange={(fromDate: any) => handleselectionfromdate(fromDate)}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ float: 'left', width: '24%', paddingTop: '5px' }}>
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="To Date" name="todate">
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                format={config.dateFormat}
                                disabled={loader}
                                defaultValue={new Date(selectedToDate)}
                                showToday
                                onChange={(fromDate: any) =>
                                    setSelectedToDate(
                                        format(fromDate, config.dateFormat)
                                    )
                                }
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <Button
                    onClick={handlesearch}
                    variant='contained' style={{ backgroundColor: 'rgb(0, 172, 193)', color: 'white', height: 33, margin: '5px 0 0 17px' }}>Search</Button>
            </div>
            <br />
            <Card>
                <CardHeader color="primary">
                    <Typography variant="h2" style={{ color: 'white', fontSize: '1.5rem' }}>Discount Amount Report</Typography>
                </CardHeader>
                <CardBody>
                    {loader ? <Loader /> : (<>
                        {searched ? listofdata.length > 0 ? (<>
                            <Box alignContent='center' alignItems='center' textAlign='center'>
                                {
                                    <Button variant='contained'
                                        onClick={(e: any) => {
                                            downloaddata(listofdata);
                                        }}
                                    >
                                        Download Report
                                    </Button>
                                }
                            </Box>
                        </>)
                            : (<><Typography variant="h5" align='center' color="initial">No Data Found.</Typography></>) : ''}
                    </>)}
                </CardBody>
            </Card>
        </>
    );
}