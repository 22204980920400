import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import InsertInvitation from "@material-ui/icons/InsertInvitation";
// core components
import GridItem from "../AppointmentList/GridItem";
import GridContainer from "../AppointmentList/GridContainer";
import CustomTabs from "../AppointmentList/CustomTabs";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { has } from "lodash";
import { getQbenchResults } from "../../api";
import Loader from '../../../shared/components/Loader';
function QbenchResultsList(props:any) {
    const [currentResults, setCurrentResults] = useState([]);
    const [pastResults, setPastResults] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [value, setValue] = React.useState(0);
    useEffect(() => {
        setLoading(true)
        setValue(has(props.location.state,'Activetabs') ? props.location.state.Activetabs :value )
        getQbenchResults().then(
            (response: any) => {
                if (response.data) {
                    setCurrentResults(response.data.resultsToday);
                    setPastResults(response.data.pastResults);
                    setLoading(false)
                    return true;
                } else {
                    setCurrentResults([]);
                    setPastResults([]);
                    setLoading(false)
                    return true;
                }
            }
        )
    }, []);
    return (
        <div>
            { loading? <Loader />:
            <GridContainer>
                <GridItem>
                    <CustomTabs
                    Activetabs={value}
                    setValue={setValue}
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: "Today",
                                // tabIcon: InsertInvitation,
                                tabContent: (
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Confirmation Id</TableCell>
                                                <TableCell>First Name</TableCell>
                                                <TableCell>Last Name</TableCell>
                                                <TableCell>Phone</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Test Status</TableCell>
                                                <TableCell>Test Results</TableCell>
                                                <TableCell>Completed date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {(currentResults).map((item: any, index: any) => (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell>{item.custom_formatted_id}</TableCell>
                                                    <TableCell>{item.samples.X_PATIENT_FIRST_NAME}</TableCell>
                                                    <TableCell>{item.samples.X_PATIENT_LAST_NAME}</TableCell>
                                                    <TableCell>{item.phone}</TableCell>
                                                    <TableCell>{item.email}</TableCell>
                                                    <TableCell>{item.tests.results}</TableCell>
                                                    <TableCell>{item.tests.state}</TableCell>
                                                    <TableCell>{item.tests.complete_date_formated}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                )
                            },
                            {
                                tabName: "Past Week",
                                // tabIcon: InsertInvitation,
                                tabContent: (
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Confirmation Id</TableCell>
                                                <TableCell>First Name</TableCell>
                                                <TableCell>Last Name</TableCell>
                                                <TableCell>Phone</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Test Status</TableCell>
                                                <TableCell>Test Results</TableCell>
                                                <TableCell>Completed date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {(pastResults).map((item: any, index: any) => (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell>{item.samples.X_PATIENT_EXTERNAL_ID}</TableCell>
                                                    <TableCell>{item.samples.X_PATIENT_FIRST_NAME}</TableCell>
                                                    <TableCell>{item.samples.X_PATIENT_LAST_NAME}</TableCell>
                                                    <TableCell>{item.phone}</TableCell>
                                                    <TableCell>{item.email}</TableCell>
                                                    <TableCell>{item.tests.results}</TableCell>
                                                    <TableCell>{item.tests.state}</TableCell>
                                                    <TableCell>{item.tests.complete_date_formated}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                )
                            },
                        ]}
                    />
                </GridItem>
            </GridContainer>}
        </div>
    );
}
export default QbenchResultsList;