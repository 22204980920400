import { firebase } from '../../firebase';
import {
    AppointmentListItem,
    AppointmentsListParams,
    ConsolidationParams,
    Appointment,
} from './models';
import xlsx from 'node-xlsx';
import { config } from '../../config';
import { parse, format } from 'date-fns';
import axios from 'axios';
import { cloudRunDelete, cloudRunGet, cloudRunPost, generateReport } from '../shared/api';
// import qbench from '../';

export const signIn = async (email: string, password: string) => {
    firebase.auth().signInWithEmailAndPassword(email, password);
    window.location.href = '/dashboard';
};

export const signOut = async () => firebase.auth().signOut();

export const getAppointmentsByDate = async (params: AppointmentsListParams) =>
    firebase
        .functions()
        .httpsCallable('getAppointmentsByDate', { timeout: 540 * 1000 })(params);

export const getAppointmentsByconfirmationId = async (
    params: AppointmentsListParams
) =>
    firebase
        .functions()
        .httpsCallable('getAppointmentsByconfirmationId', { timeout: 540 * 1000 })(
            params
        );

// export const getDayWiseAppointmentsSearchData = async (
//     params: AppointmentsListParams
// ) => firebase
//     .functions()
//     .httpsCallable('getDayWiseAppointmentsSearchData', { timeout: 540 * 1000 })(
//         params
//     )

// export const getUserSearchData = async (params: AppointmentsListParams) =>
//     firebase
//         .functions()
//         .httpsCallable('getCustomersSearchData', { timeout: 540 * 1000 })(params);

// export const getUserDetails = async (params: AppointmentsListParams) =>
//     firebase.functions().httpsCallable('getCustomersSearchData', { timeout: 540 * 1000 })(
//         params
//     );
export const InsuranceData = async (params: any) =>
    firebase.functions().httpsCallable('InsuranceData')(params);

export const StripeData = async (params: any) =>
    firebase.functions().httpsCallable('StripeData')(params);

export const NoInsuranceData = async (params: any) =>
    firebase.functions().httpsCallable('NoInsuranceData')(params);
    
export const updateAndRelaseAntigenResult = async (params: any) =>
    firebase.functions().httpsCallable('updateAndRelaseAntigenResult', { timeout: 540 * 1000 })(
        params
    );

export const updateAntigenResultFalse = async (params: AppointmentsListParams) =>
    firebase.functions().httpsCallable('updateAntigenResultFalse', { timeout: 540 * 1000 })(
        params
    );
// export const adminRegisterLogin = async (params: any) =>
//     firebase.functions().httpsCallable('adminRegisterLogin')(params);

export const updateCustomer = async (params: any) =>
    firebase.functions().httpsCallable('updateCustomer')(params);

export const getInsurancePersonsDetails = async (params: any) =>
    firebase.functions().httpsCallable('getInsurancePersonsDetails')({ ...params, onlyThirdParty: true });

export const getMysqlInsuranceReport = async (params: any) =>
    firebase.functions().httpsCallable('getMysqlInsuranceReport')(params);

export const getMysqlInsuranceAndStripe = async (params: any) =>
    firebase.functions().httpsCallable('getMysqlInsuranceAndStripe')(params);


export const getPaymentIntentId = async (params: any) =>
    firebase.functions().httpsCallable('getPaymentIntentId')(params);

export const getfilterwithrecords = async (params: ConsolidationParams) =>
    firebase
        .functions()
        .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);

export const getAllAppointmentSlottime = async () =>
    firebase.functions().httpsCallable('getAllAppointmentSlottime')();

export const sendConfirmationEmail = async (params: any) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.sendConfirmationEmail,params);
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const getSlotsConfigurationss = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getSlotsConfigurationss')(params);
}
export const getNewSlots = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getNewSlots')(params);
}

export const downloadResultsPDF = async (sampleId: string) => {
    const { data } = await firebase
        .functions()
        .httpsCallable('getResultsPDFDownloadLink')(sampleId);

    if (data?.url) {
        // window.open(data?.url, '_blank');
        // var link = document.createElement('a');
        // link.href = `${data?.url}`;
        // link.target = '_blank'
        // link.click();

        var a = window.document.createElement('a');
        a.target = '_blank';
        a.href = data?.url;

        // Dispatch fake click
        var e = window.document.createEvent('MouseEvents');
        e.initMouseEvent(
            'click',
            true,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );
        a.dispatchEvent(e);

        // let a: any = await document.createElement("a");
        //   document.body.appendChild(a);
        //   a.href = `${data?.url}`;
        //   a.download = 'report';
        //   a.click();
        //   document.body.removeChild(a);
    }
};

export const TestdownloadResultsPDF = async (sampleId?: string) => {
    var windowReference: any = window.open();
    axios({
        method: 'POST',
        url:
            'https://us-central1-worksite-labs-ccb7d.cloudfunctions.net/getResultsPDFDownloadLink',
        data: {
            data: 89181,
        },
    }).then((res: any) => {
        console.log('res', res);
        windowReference.location = res.data.result?.url;
        // if (res.data.result?.url) {

        //   const link = document.createElement('a');
        //   link.href = res.data.result?.url;
        //   link.setAttribute(
        //     'download',
        //     `FileName.pdf`,
        //   );

        //   // Append to html link element page
        //   document.body.appendChild(link);

        //   // Start download
        //   link.click();

        //   // Clean up and remove the link
        //   // link.parentNode.removeChild(link);
        // }
    });
};

// crelio report as pdf
export const downloadCrelioPdf = async (name: string, reportBase64: string) => {
    if (reportBase64) {
        // Insert a link & automate download the PDF file
        var link = document.createElement('a');
        link.download = `${name}-report.pdf`;
        link.href = 'data:application/octet-stream;base64,' + reportBase64;
        link.target = '_blank';
        link.click();
    } else {
        // no data found
    }
};

export const updateAppointment = async ({
    firebaseId,
    qbenchId,
    sampleId,
    update,
    minorIndex,
}: {
    firebaseId: string;
    qbenchId: string;
    sampleId: string;
    minorIndex: number | null;
    update: Partial<AppointmentListItem>;
}) =>
    firebase.functions().httpsCallable('updateAppointment')({
        firebaseId,
        sampleId,
        qbenchId,
        update,
        minorIndex,
    });

export const upgradeTestType = async (params: any) =>
    firebase.functions().httpsCallable('upgradeTestType')(params);

export const createRecurringForLasLomitasTrigger = async () =>
    firebase.functions().httpsCallable('createRecurringForLasLomitasTrigger')();

// export const getQbenchResults = async () =>
//     firebase.functions().httpsCallable('getQbenchResults')();

// export const getApptConsolidationDetails = async () =>
//     firebase.functions().httpsCallable('getApptConsolidationDetails')();

export const reportAccess = async () =>
    firebase.functions().httpsCallable('reportAccess')();

export const getApptbasedonLocation = async () =>
    firebase.functions().httpsCallable('getApptbasedonLocation')();

export const getApptbasedonGender = async () =>
    firebase.functions().httpsCallable('getApptbasedonGender')();

export const getApptbasedonresults = async () =>
    firebase.functions().httpsCallable('getApptbasedonresults')();

export const getAppointmentCheckinList = async (params: any) =>
    await firebase
        .functions()
        .httpsCallable('getAppointmentsForCheckin', { timeout: 540 * 1000 })(
            params
        );

export const checkInCrelioAppointment = async (params: any) => {
    await firebase.functions().httpsCallable('checkIn', { timeout: 540 * 1000 })(
        params
    );
};

// export const getCountyReport = (runDate?: any) =>
//   firebase
//     .firestore()
//     .collection("qbenchconsolidationresults")
//     .where('tests.complete_date_formated', '==', runDate)
//     .get()
//     // .then(parseSnapshot);
//     .then((snapshot) => {

//       const consolidationdetails: any = snapshot.docs.map(
//         (doc: any) => doc.data()
//       );

//       const positiveconsolidationdetails = consolidationdetails.filter(function (
//         item: any
//       ) {
//         return item.tests.results == 'DETECTED';
//       });

//       const negativeconsolidationdetails = consolidationdetails.filter(function (
//         item: any
//       ) {
//         return item.tests.results == 'NOT DETECTED';
//       });

//       return {
//         Allconsolidationdetails: consolidationdetails,
//         Positiveconsolidationdetails: positiveconsolidationdetails,
//         Negativeconsolidationdetails: negativeconsolidationdetails,
//       }
//     });

// export const getCountyReport = (runDate?: any) =>
//     Promise.all([
//         firebase
//             .firestore()
//             .collection('qbenchconsolidationresults')
//             .where('tests.complete_date_formated', '==', runDate)
//             .get(),

//         firebase
//             .firestore()
//             .collection('thirdpartyqbenchconsolidationresults')
//             .where('tests.complete_date_formated', '==', runDate)
//             .get(),
//     ]).then((res) => {
//         const consolidationdetailsFromQbench: any = res[0].docs.map((doc: any) =>
//             doc.data()
//         );
//         const consolidationdetailsFromThirdParty: any = res[1].docs.map(
//             (doc: any) => doc.data()
//         );

//         const consolidationdetailsFromPrimary: any = consolidationdetailsFromThirdParty.filter(
//             (record: any) => record.isPrimary
//         );
//         const consolidationdetails: any = [
//             ...consolidationdetailsFromQbench,
//             ...consolidationdetailsFromPrimary,
//         ];

//         let output: any = {};
//         output['Allconsolidationdetails'] = consolidationdetails;
//         output['Positiveconsolidationdetails'] = consolidationdetails.filter(
//             (record: any) => record.tests.results == 'DETECTED'
//         );
//         output['Negativeconsolidationdetails'] = consolidationdetails.filter(
//             (record: any) => record.tests.results == 'NOT DETECTED'
//         );

//         console.log('output', output);
//         return output;
//     });

export const getMysqlCountyReport = async (params: any) =>
    firebase
        .functions()
        .httpsCallable('getMysqlCountyReport', { timeout: 540 * 1000 })(params);
export const generatecountyReport = async (appointments: any) => {
    const data = [
        [
            'FirstName',
            'LastName',
            'PatientIdentifier',
            'DOB',
            'Gender',
            'Race',
            'Ethnicity',
            'Patient_Street_Address',
            'Apartment_Number',
            'City',
            'State',
            'Zipcode',
            'PatientPhoneNumber',
            'Patient Email',
            'OrderingFacility',
            'Referring Physician',
            'Physician address',
            'Physician City',
            'Physician State',
            'Physician Zipcode',
            'Physician phone number',
            'Accession #',
            'Specimen Collection Date',
            'Specimen Received Date',
            'Specimen Type',
            'Test reported date',
            'Test Code_LOINC',
            'Test name',
            'Result',
            'PerformingFacility',
            'CLIA',
            'Pregnancy Status',
            'County Name',
            'Sample ID',
        ],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;
            const row = [
                //     appt.Samples_X_PATIENT_FIRST_NAME,
                //     appt.Samples_X_PATIENT_LAST_NAME,
                //     appt.PatientIdentifier,
                //     appt.Samples_X_PATIENT_DOB,
                //     appt.Sex,
                //     appt.Race,
                //     appt.Ethnicity,
                //     appt.Address_Address,
                //     appt.Address_Address,
                //     appt.Address_City,
                //     appt.Address_State,
                //     appt.Address_ZipCode,
                //     appt.Phone,
                //     appt.Email,
                //     appt.OrderingFacility,
                //     appt.MedicalDirector,
                //     appt.PhysicianAddress,
                //     appt.PhysicianCity,
                //     appt.PhysicianState,
                //     appt.PhysicianZipcode,
                //     appt.PhysicianPhoneNo,
                //     appt.Samples_X_EXTERNAL_ACCESSION_ID,
                //     appt.Date,
                //     appt.Tests_Complete_Date_Formated,
                //     appt.Samples_Accessioning_Type_Value,
                //     appt.Samples_Time_of_collection,
                //     appt.Test_Code_LOINC,
                //     appt.Tests_Assay_Title,
                //     appt.Tests_Results,
                //     appt.PerformingFacility,
                //     appt.CLIA,
                //     appt.PregnancyStatus,
                //     appt.Address_County,
                //     appt.SampleId,
                // ];
                `${appt.samples !== undefined ? appt.samples['X_PATIENT_FIRST_NAME'] : ''
                }`,
                `${appt.samples !== undefined ? appt.samples['X_PATIENT_LAST_NAME'] : ''
                }`,
                '',
                `${appt.samples !== undefined ? appt.samples['X_PATIENT_DOB'] : ''}`,
                appt.sex,
                appt.race,
                appt.ethnicity,
                `${appt.address !== undefined ? appt.address['address'] : ''}`,

                `${appt.address !== undefined ? appt.address['address'] : ''}`,
                `${appt.address !== undefined ? appt.address['city'] : ''}`,
                `${appt.address !== undefined ? appt.address['state'] : ''}`,
                `${appt.address !== undefined ? appt.address['zipCode'] : ''}`,
                appt.phone,
                appt.email,
                'Work Site Lab',
                `${appt.location !== null && appt.location.medicalDirector !== undefined
                    ? appt.location.medicalDirector
                    : 'NA'
                }`,
                'NA',
                'NA',
                'NA',
                'NA',
                'NA',
                `${appt.samples !== undefined
                    ? appt.samples['X_PATIENT_EXTERNAL_ID']
                    : ''
                }`,
                `${appt.date}`,
                `${appt.tests !== undefined ? appt.tests['complete_date_formated'] : ''
                }`,
                `${appt.samples !== undefined &&
                    appt.samples !== null &&
                    appt.samples.accessioning_type !== undefined &&
                    appt.samples.accessioning_type !== null &&
                    appt.samples.accessioning_type['value'] !== null &&
                    appt.samples.accessioning_type['value'] !== undefined
                    ? appt.samples.accessioning_type['value']
                    : ''
                }`,
                `${appt.samples !== undefined &&
                    appt.samples['time_of_collection'] !== undefined &&
                    appt.samples['time_of_collection'] !== null &&
                    appt.samples['time_of_collection'] !== ''
                    ? format(
                        new Date(appt.samples['time_of_collection']),
                        config.dateFormat
                    )
                    : ''
                }`,
                `${appt.tests !== undefined &&
                    appt.tests !== null &&
                    appt.tests.assay !== undefined &&
                    appt.tests.assay !== null &&
                    appt.tests.assay['title'] !== undefined &&
                    appt.tests.assay['title'] !== null
                    ? appt.tests.assay['title'] ==
                        'Atila iAMP NAAT RT-PCR COVID-19 Detection'
                        ? '94500-6'
                        : appt.tests.assay['title'] ==
                            'Quidel Lyra NAAT RT-PCR COVID-19 Detection'
                            ? '94559-2'
                            : appt.tests.assay['title'] == 'Biorad DD PCR'
                                ? '94533-7'
                                : appt.tests.assay['title'] ==
                                    'LumiraDx SARS-CoV-2 RNA STAR NAAT RT-PCR Detection'
                                    ? '94559-2'
                                    : appt.tests.assay['title'] ==
                                        'Cepheid Xpert® Xpress SARS-CoV-2 NAAT RT-PCR'
                                        ? '94500-6'
                                        :appt.tests.assay['title'] ==
                                        'Celltrion DiaTrustTM COVID-19 Ag Rapid Antigen Test'?
                                        '95209-3'
                                        : ''
                    : ''
                }`,
                `${appt.tests !== undefined &&
                    appt.tests !== null &&
                    appt.tests.assay !== undefined &&
                    appt.tests.assay !== null &&
                    appt.tests.assay['title'] !== undefined &&
                    appt.tests.assay['title'] !== null
                    ? appt.tests.assay['title']
                    : ''
                }`,
                `${appt.tests !== undefined && appt.tests !== null
                    ? appt.tests['results']
                    : ''
                }`,
                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.performingfacility !== undefined &&
                    appt.location.performingfacility !== null
                    ? `Worksite Labs - ${appt.location.performingfacility}`
                    : ''
                }`,
                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.CLIA !== undefined &&
                    appt.location.CLIA !== null
                    ? appt.location.CLIA
                    : ''
                }`,
                'NA',
                `${appt.address !== undefined &&
                    appt.address.county !== undefined &&
                    appt.address.county !== null
                    ? appt.address['county']
                    : ''
                }`,
                `${appt.sampleId}`,
            ];
            a.push(row);
            return a;
        }, []),
    ];
    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);
    saveAs(blob, `report.xlsx`);
};

export const generateALDDReport = async (appointments: any) => {
    const data = [
        [
            'Appt Time',
            'Status',
            'Airline',
            'Travel Type',
            'Destination',
            'VIP Slot',
            'Rapid Test',
            'Confirmation ID',
            'Sample ID',
            'First Name',
            'Last Name',
            'Email',
            'DOB',
            'Phone Number',
            'Flight Date and Time',
            'Symptoms Reported?',
            'Contact in last 14 days?',
            'Results',
            'Google/NPS',
            'Appointment Date',
            'Location',
        ],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;
            if (appt.IsCanceled !== 'Canceled' && appt.Results !== '') {
                const row = [
                    appt.period,
                    appt.isCanceled,
                    // appt.airlineCode,
                    appt?.airlineCode?.value !== undefined &&
                        appt?.airlineCode?.value !== null
                        ? appt.airlineCode.value
                        : appt.airlineCode,
                    appt.travelType,
                    appt.destination,
                    appt.isExpressSameDayTest,
                    appt.isRapidTest,
                    appt.confirmationId,
                    appt.sampleId,
                    appt.firstName,
                    appt.lastName,
                    appt.email,
                    appt.birthDate,
                    appt.phone,
                    appt.departureDateAndTime,
                    appt.symptoms,
                    appt.hadContact,
                    appt.results,
                    'NPS',
                    appt.date,
                    appt.location?.limsinfo?.label ? appt.location?.limsinfo?.label : '',
                ];

                // const row = [
                //     appt.Slot_Label,
                //     appt.Status,
                //     appt.AirlineCode,
                //     appt.TravelType,
                //     appt.Destination,
                //     appt.IsExpressSameDayTest,
                //     appt.IsRapidTest,
                //     appt.ConfirmationId,
                //     appt.SampleId,
                //     appt.FirstName,
                //     appt.LastName,
                //     appt.Email,
                //     appt.BirthDate,
                //     appt.Phone,
                //     appt.DepartureDateAndTime,
                //     appt.Symptoms,
                //     appt.HadContact,
                //     appt.Results,
                //     appt.NPS,
                //     appt.Date,
                //     appt.Location,
                // ]
                a.push(row);
            }
            return a;
        }, []),
    ];
    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);
    saveAs(blob, `ALDD_report.xlsx`);
};

// export const updateAppointmentdata = async (params: Appointment) =>
//     firebase
//         .functions()
//         .httpsCallable('updatedaywiseAppointment', { timeout: 540 * 1000 })(params);

export const updateMultipleAppointmentdata = async (params: Appointment[]) =>
    firebase
        .functions()
        .httpsCallable('updateMultipleAppointments', { timeout: 540 * 1000 })(params);

// export const getAppointmentdata = async (params: any) =>
//     firebase
//         .functions()
//         .httpsCallable('getAppointmentdata', { timeout: 540 * 1000 })(params);

export const generateResultReport = async (params: any) =>
    firebase
        .functions()
        .httpsCallable('generateResultReport', { timeout: 540 * 1000 })(params);

export const getSlotsConfigurations = async () =>
    firebase.functions().httpsCallable('getSlotsConfigurations')();

// export const updateAppointmentdata = async (appointment: Appointment) => {

//   console.log("appointmentappointment",appointment)

//   firebase
//   .firestore().collection(config.firestoreCollections.appointments)
//   .doc(appointment.id)
//   .update({
//     location:appointment.location,firstName:appointment.firstName,
//     lastName:appointment.lastName,
//     email:appointment.email,
//     phone:appointment.phone,
//     birthDate:appointment.birthDate,
//     address:appointment.address,
//     slot:appointment.slot,
//     date:appointment.date,
//     minors:appointment.minors
//   });

//   //qbench.updateSample(qbenchId, sampleId, update);

// }

// // firebase
// // .functions()
// // .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);

export const getMysqlALDDReports = async (params: AppointmentsListParams) =>
    firebase
        .functions()
        .httpsCallable('getMysqlALDDReports', { timeout: 540 * 1000 })(params);

// export const getCountry = async () =>
//     firebase
//         .functions()
//         .httpsCallable('getCountry')();

export const addNpDestionation = async (params: any) =>
    firebase
        .functions()
        .httpsCallable('addNpDestionation')(params);

export const getNpDestionation = async () =>
    firebase
        .functions()
        .httpsCallable('getNpDestionation')();

// // Excel format is updated according to new format below the commented code.

// export const exportdatatoexel = async (appointments: any) => {
//   const data = [
//     ['FirstName',
//       'LastName',
//       'DOB',
//       'Gender',
//       'Race',
//       'Ethnicity',
//       'Patient_Street_Address',
//       'City',
//       'State',
//       'Zipcode',
//       'PatientPhoneNumber',
//       'Patient Email',
//       'Referring Physician',
//       'Accession #',
//       'Specimen Collection Date',
//       'Specimen Received Date',
//       'Specimen Type',
//       'Test reported date',
//       'Test Code_LOINC',
//       'Test name',
//       'Result',
//       'PerformingFacility',
//       'CLIA',
//       'County Name',
//       'Sample ID',
//       'CPT',
//       'Insurance Back Card',
//       'Insurance Front Card',
//       'Payer Name',
//       'Payer ID',
//       'Group',
//       'Subscriber First Name',
//       'Subscriber Last Name'
//     ],
//     ...appointments.reduce((acc: any[], appt: any) => {
//       const a = acc;
//       const row = [
//       `${appt.samples !== undefined
//       ? appt.samples['X_PATIENT_FIRST_NAME']
//       : ''
//       }`,
//       `${appt.samples !== undefined
//         ? appt.samples['X_PATIENT_LAST_NAME']
//         : ''
//       }`,
//       `${appt.samples !== undefined
//         ? appt.samples['X_PATIENT_DOB']
//         : ''
//       }`,
//       appt.sex,
//       appt.race,
//       appt.ethnicity,
//       `${appt.address !== undefined
//         ? appt.address['address']
//         : ''
//       }`,
//       `${appt.address !== undefined
//         ? appt.address['city']
//         : ''
//       }`,
//       `${appt.address !== undefined
//         ? appt.address['state']
//         : ''
//       }`,
//       `${appt.address !== undefined
//         ? appt.address['zipCode']
//         : ''
//       }`,
//       appt.phone,
//       appt.email,
//       `${appt.location !== null && appt.location.medicalDirector !== undefined ? appt.location.medicalDirector : "NA"}`,
//       `${appt.samples !== undefined
//         ? appt.samples['X_PATIENT_EXTERNAL_ID']
//         : ''
//       }`,
//       `${appt.date
//       }`
//         ,
//       `${appt.tests !== undefined
//         ? appt.tests['complete_date_formated']
//         : ''
//       }`
//         ,
//       `${appt.samples !== undefined && appt.samples !== null && appt.samples.accessioning_type !== undefined && appt.samples.accessioning_type !== null && appt.samples.accessioning_type['value'] !== null && appt.samples.accessioning_type['value'] !== undefined
//         ? appt.samples.accessioning_type['value']
//         : ''
//       }`,
//       `${appt.samples !== undefined && appt.samples['time_of_collection'] !== undefined && appt.samples['time_of_collection'] !== null && appt.samples['time_of_collection'] !== ''
//         ? format(
//           new Date(appt.samples['time_of_collection']),
//           config.dateFormat
//         )
//         : ''
//       }`,
//       `${appt.tests !== undefined && appt.tests !== null && appt.tests.assay !== undefined && appt.tests.assay !== null && appt.tests.assay['title'] !== undefined && appt.tests.assay['title'] !== null
//         ? appt.tests.assay['title'] == 'Atila iAMP NAAT RT-PCR COVID-19 Detection' ? "94500-6"
//           : (appt.tests.assay['title'] == 'Quidel Lyra NAAT RT-PCR COVID-19 Detection' ? "94559-2"
//             : appt.tests.assay['title'] == 'Biorad DD PCR' ? "94533-7"
//               : appt.tests.assay['title'] == 'LumiraDx SARS-CoV-2 RNA STAR NAAT RT-PCR Detection' ? "94559-2"
//                 : appt.tests.assay['title'] == 'Cepheid Xpert® Xpress SARS-CoV-2 NAAT RT-PCR' ? "94500-6"
//                   : '')
//         : ''
//       }`,
//       `${appt.tests !== undefined && appt.tests !== null && appt.tests.assay !== undefined && appt.tests.assay !== null && appt.tests.assay['title'] !== undefined && appt.tests.assay['title'] !== null
//         ? appt.tests.assay['title']
//         : ''
//       }`,
//       `${appt.tests !== undefined && appt.tests !== null
//         ? appt.tests['results']
//         : ''
//       }`
//         ,
//       `${appt.location !== undefined && appt.location !== null && appt.location.performingfacility !== undefined && appt.location.performingfacility !== null
//         ? `Worksite Labs - ${appt.location.performingfacility}`
//         : ''
//       }`,
//       `${appt.location !== undefined && appt.location !== null && appt.location.CLIA !== undefined && appt.location.CLIA !== null
//         ? appt.location.CLIA
//         : ''
//       }`,
//       `${appt.address !== undefined && appt.address.county !== undefined && appt.address.county !== null
//         ? appt.address['county']
//         : ''
//       }`,
//       `${appt.sampleId
//       }`,
//       'U004',
//       `${appt.insurance !== undefined
//         ? appt.insurance.frontCard
//         : ''
//       }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.backCard
//         : ''
//       }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.payerList
//           : ''
//         }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.insuranceId
//           : ''
//         }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.group
//           : ''
//         }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.subscriberFirstName
//           : ''
//         }`,
//         `${appt.insurance !== undefined
//           ? appt.insurance.subscriberLastName
//           : ''
//         }`,
//       ];
//       a.push(row);
//       return a;
//     }, []),
//   ];
//   const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
//   const blob = new Blob([buffer]);
//   saveAs(blob, `report.xlsx`);
// }

export const exportdatatoexel = async (appointments: any) => {
    const data = [
        [
            'Accessioning Number',
            'Date of Collection',
            'Appointment Date',
            'Patient First Name',
            'Patient Last Name',
            'Patient Street',
            'Patient City',
            'Patient State',
            'Patient Zip',
            'Patient DOB',
            'Patient Sex',
            'Patient Phone Number',
            'Insurance Carrier Name',
            'Insurance Policy Holder',
            'Subscriber First Name',
            'Subscriber Last Name',
            'Insurance Member ID',
            'Insurance Group',
            'Policy Holder First Name',
            'Policy Holder Last Name',
            'Policy Holder DOB',
            'Ordering Provider',
            'Ordering Provider NPI',
            'Facility ID',
            'Partner Group',
            'Facility CLIA',
            'Facility Street',
            'Facility City',
            'Facility State',
            'Facility Zip',
            'Billing CPT Code',
            'ICD 10 Code',
            'Patient Drivers License',
            'Patient Social Security Number',
            'Insurance Front Card',
            'Insurance Back Card',
            'WSL order_id',
            'Insurance Missing',
            'Source'
        ],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;
            // let row = [];
            // if (appt.isPrimary || appt.isSolvHealth || appt.isReelHealth || appt.isCLXHealth) {
            const row = [
                `${appt.samples !== undefined &&
                    appt.samples !== null &&
                    appt.samples.X_EXTERNAL_ACCESSION_ID !== undefined &&
                    appt.samples.X_EXTERNAL_ACCESSION_ID !== null
                    ? appt.sampleId.toString()
                    : ''
                }`,
                `${appt.samples !== undefined && appt.samples !== null && appt.samples.time_of_collection !== undefined && appt.samples.time_of_collection !==null ? format(new Date(appt.samples.time_of_collection),config.dateFormat) : ''
                }`,
                // `${appt.tests !== undefined && appt.tests !== null ? appt.tests['complete_date_formated'] : ''}`,
                `${appt.date}`,

                // `${appt.samples !== undefined && appt.samples['time_of_recieve'] !== undefined && appt.samples['time_of_recieve'] !== null && appt.samples['time_of_recieve'] !== ''
                //   ? appt.samples['time_of_recieve']
                //   : ''
                // }`,

                // `${appt.samples !== undefined && appt.samples['time_of_collection'] !== undefined && appt.samples['time_of_collection'] !== null && appt.samples['time_of_collection'] !== ''
                //   ? format(
                //     new Date(appt.samples['time_of_collection']),
                //     config.dateFormat
                //   )
                //   : ''
                // }`,

                `${appt.samples !== undefined && appt.samples !== null ? appt.samples['X_PATIENT_FIRST_NAME'] : ''
                }`,

                `${appt.samples !== undefined && appt.samples !== null ? appt.samples['X_PATIENT_LAST_NAME'] : ''
                }`,

                `${appt.address !== undefined && appt.address !== null ? appt.address['address'] : ''}`,

                `${appt.address !== undefined && appt.address !== null ? appt.address['city'] : ''}`,

                `${appt.address !== undefined && appt.address !== null ? appt.address['state'] : ''}`,

                `${appt.address !== undefined && appt.address !== null ? appt.address['zipCode'] : ''}`,

                `${appt.samples !== undefined && appt.samples !== null ? appt.samples['X_PATIENT_DOB'] : ''}`,

                appt.sex,

                appt.phone,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.payerList : ''}`,

                `${appt.insurance !== undefined && appt.insurance !== null
                    ? appt.insurance.insuranceFor == "i'm" || appt.insurance.insuranceFor =='Self'
                        ? 'Self'
                        : appt.insurance.insuranceFor == 'my Parents'
                            ? 'Child / Dependent'
                            : appt.insurance.insuranceFor == 'my spouse or partner'
                            ? 'Spouse / Partner'
                            : appt.isSolvHealth ?
                                appt.insurance.insuranceFor
                            : 'Other'
                    
                    : ''
                }`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.subscriberFirstName : ''
                }`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.subscriberLastName : ''
                }`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.insuranceId : ''}`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.group : ''}`,

                `${appt.insurance !== undefined && 
                    appt.insurance !== null && 
                    appt.insurance.policyHolderFirstName !== undefined && 
                    appt.insurance.policyHolderFirstName !== null
                ? appt.insurance.policyHolderFirstName
                : appt.policyHolderFirstName
                }`,
                `${appt.insurance !== undefined && 
                    appt.insurance !== null && 
                    appt.insurance.policyHolderLastName !== undefined && 
                    appt.insurance.policyHolderLastName !== null
                    ? appt.insurance.policyHolderLastName
                    : appt.policyHolderLastName
                }`,
                `${appt.insurance !== undefined && 
                    appt.insurance !== null && 
                    appt.insurance.policyHolderbirthDate !== undefined && 
                    appt.insurance.policyHolderbirthDate !== null && 
                    typeof (appt.insurance.policyHolderbirthDate) == 'string'
                    ? appt.insurance.policyHolderbirthDate
                    : appt.birthDate
                }`,

                'Christopher Reeves',

                '1871630319',

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.facilityId !== undefined &&
                    appt.location.facilityId !== null
                    ? appt.location.facilityId
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.name !== undefined &&
                    appt.location.name !== null
                    ? appt.location.name
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.CLIA !== undefined &&
                    appt.location.CLIA !== null
                    ? appt.location.CLIA
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.facilityStreet !== undefined &&
                    appt.location.facilityStreet !== null
                    ? appt.location.facilityStreet
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.facilityCity !== undefined &&
                    appt.location.facilityCity !== null
                    ? appt.location.facilityCity
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.facilityState !== undefined &&
                    appt.location.facilityState !== null
                    ? appt.location.facilityState
                    : ''
                }`,

                `${appt.location !== undefined &&
                    appt.location !== null &&
                    appt.location.facilityZip !== undefined &&
                    appt.location.facilityZip !== null
                    ? appt.location.facilityZip
                    : ''
                }`,

                `${appt.isAntigen !== undefined && 
                    appt.isAntigen !== null && 
                    appt.isAntigen
                    ? config.Billing_CPT_Code.AntigenTest 
                    : config.Billing_CPT_Code.StandardLab_PCR
                }`,

                'Z11.52',

                `${appt.driverLicenseNumber !== undefined &&
                    appt.driverLicenseNumber !== null
                    ? appt.driverLicenseNumber
                    : ''
                }`,

                `${appt.socialSecurityNumber !== undefined &&
                    appt.socialSecurityNumber !== null
                    ? appt.socialSecurityNumber
                    : ''
                }`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.frontCard : ''}`,

                `${appt.insurance !== undefined && appt.insurance !== null ? appt.insurance.backCard : ''}`,

                `${appt.qbenchId !== undefined && appt.qbenchId !== null
                    ? appt.qbenchId.toString()
                    : ''
                }`,

                `${appt.insurance !== undefined && appt.insurance !== null && appt.insurance.insuranceId ? 'FALSE' : 'TRUE'
                }`,
                appt.isPrimary ? '911' 
                    : appt.isSolvHealth ? 'SolvHealth' 
                    : appt.isReelHealth ? 'ReelHealth' 
                    : appt?.isCLXHealth ? 'CLX' 
                    : appt?.isSchedularLite ? 'Scheduler Lite'
                    : 'Scheduler',        
            ];
            // } else {

            //     row = [
            //         appt.SampleId,
            //         appt.Tests_Complete_Date_Formated,
            //         appt.Date,
            //         appt.Samples_X_PATIENT_FIRST_NAME,
            //         appt.Samples_X_PATIENT_LAST_NAME,
            //         appt.Address_Address,
            //         appt.Address_City,
            //         appt.Address_State,
            //         appt.Address_ZipCode,
            //         appt.Samples_X_PATIENT_DOB,
            //         appt.Sex,
            //         appt.Phone,
            //         appt.InsuranceName,
            //         appt.InsurancePolicyHolder,
            //         appt.subscriberFirstName,
            //         appt.subscriberLastName,
            //         appt.insuranceId,
            //         appt.PayerId,
            //         appt.SubscriberDOB,
            //         appt.OrderingProvider,
            //         appt.OrderingProviderNPI,
            //         appt.FacilityID,
            //         appt.PartnerGroup,
            //         appt.FacilityCLIA,
            //         appt.FacilityStreet,
            //         appt.FacilityCity,
            //         appt.FacilityState,
            //         appt.FacilityZipcode,
            //         appt.BillingCPTCode,
            //         appt.ICD10Code,
            //         appt.DriverLicenseNumber,
            //         appt.SocialSecurityNumber,
            //         appt.InsuranceFrontCard,
            //         appt.InsuranceBackCard,
            //         appt.QbenchId,
            //         appt.InsuranceMissing,
            //         appt.isPrimary ? '911' : appt.isSolvHealth ? 'SolvHealth' : appt.isReelHealth ? 'ReelHealth' : appt.isCLXHealth ? 'CLX' : 'Scheduler'
            //     ];
            // }
            a.push(row);
            return a;
        }, []),
    ];
    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);
    saveAs(blob, `report.xlsx`);
};

export const exportDiscountAmoutData = async (
    appointments: any,
    selectedFromDate: any
) => {
    const data = [
        ['First Name', 'Last Name', 'Email', 'Confirmation ID', 'Discount Amount', 'Promo Code'],
        ...appointments.reduce((acc: any[], appt: any) => {
            const a = acc;
            // console.log('acc', appt);
            const row: any = [
                appt.firstName,
                appt.lastName,
                appt.email,
                appt.confirmationId,
                appt.discountAmount !== undefined ? appt.discountAmount : '',
                appt.promoCode ?? ''
            ];
            a.push(row);
            // console.log('acc', a)
            return a;
        }, []),
    ];
    const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
    const blob = new Blob([buffer]);
    const date = format(new Date(selectedFromDate), config.dateFormat);
    saveAs(blob, `discounted_appt_${date}.xlsx`);
};

// export const userCheckin = async (params: any) =>
//     firebase.functions().httpsCallable('userCheckin', { timeout: 540 * 1000 })(
//         params
//     );

// export const getAdminConfigDaywise = async (params: any) =>
//     firebase.functions().httpsCallable('getAdminConfig', { timeout: 540 * 1000 })(
//         params
//     );

// export const insertAdminConfigDaywise = async (params: any) => {
//     return firebase
//         .functions()
//         .httpsCallable('insertAdminConfig', { timeout: 540 * 1000 })(params);
// };

export const getTestResultByQBenchCustomerId = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getTestResultByQBenchCustomerId')(params);
}

export const updateAssayIdByQbenchCustomerId = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('updateAssayIdByQbenchCustomerId')(params);
}

export const updateAssayIdByQBenchCustIdInQBench = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('updateAssayIdByQBenchCustIdInQBench')(params);
}
export const updateQbenchCustomerIdByLocationId = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('updateQbenchCustomerIdByLocationId')(params);
}
export const getAllQbenchCustomerIdsFromLocation = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getAllQbenchCustomerIdsFromLocation')(params);
}

// Manage Inventory
export const getLocation = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getLocation')(params);
}
export const addInventory = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('addInventory')(params);
}
export const getInventory = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('getInventory')(params);
}
export const getInventoryAuditLog = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('getInventoryAuditLog')(params);
}
// export const getEmployeeTesting = async () => {
//     return firebase
//         .functions()
//         .httpsCallable('getEmployeeTesting')();
// }
// export const addEmployeeTesting = async (params: any) => {
//     return firebase
//         .functions()
//         .httpsCallable('addEmployeeTesting')(params);
// }
// export const deleteEmployeeTesting = async (params: any) => {
//     return firebase
//         .functions()
//         .httpsCallable('deleteEmployeeTesting')(params);
// }
export const getEmployeeCoupon = async (data: any) => {
    return firebase.functions().httpsCallable('getEmployeeCoupon')(data);
}

export const getCoupon = async (data: any) => {
    return firebase.functions().httpsCallable('getCoupon')(data);
}

// CloudRun apis start
export const getApptBasedOnLocation = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getApptBasedOnLocation);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};

export const getApptBasedOnGender = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getApptBasedOnGender);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};

export const getApptBasedOnResults = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getApptBasedOnResults);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};


export const getApptConsolidationDetails = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getApptConsolidationDetails);
        return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const getReportAccess = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getReportAccess);
        return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const getCountry = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getCountry);
        return response;
    } catch (error) {
        console.log(error);
    };
};


export const getNpDestination = async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getNpDestination);
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const addNpDestination = async (params: any) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.addNpDestination, params);
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const deleteNpDestination = async (params: any) => {
    try {
        const response = await cloudRunDelete(config.cloudRunApi.deleteNpDestination, params);
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const getCountyReport = async (params: any) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getCountyReport, { runDate: params });
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};

export const getInsuranceReport = async (fromDate: string, toDate: string) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getInsuranceReport,
            { fromDate, toDate });
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const getConsolidationReport = async (qbenchCustomerId: number) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getConsolidationReport,
            { qbenchCustomerId });
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};


export const getDiscountAmountDetail = async (fromDate: string, toDate: string) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getDiscountAmountDetail,
            { fromDate, toDate });
        return response?.data.data;
    } catch (error) {
        console.log(error);
    };
};


export const getInsuranceAndStripe = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getInsuranceAndStripe,
            params);
        return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const generateAppointmentsReport = async (qbenchCustomerId: number) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.generateAppointmentsReport,
            { qbenchCustomerId });
        const apptReport = response?.data.data;
        const locationName = apptReport.map((data: { LocationName: string; }) => data.LocationName)
        if (apptReport.length > 0) {
            generateReport(apptReport, locationName[0])
        } else {
            alert('No data found for the location')
        }
        // return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const generateAllAppointmentsReport = async (qbenchCustomerId: number) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.generateAllAppointmentsReport,
            { qbenchCustomerId });
        const apptReport = response?.data.data;
        const locationName = apptReport.map((data: { LocationName: string; }) => data.LocationName)
        if (apptReport.length > 0) {
            generateReport(apptReport, locationName[0])
        } else {
            alert('No data found for the location')
        }
        // return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const getALDDReport = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getALDDReport,
            params);
        return response?.data;
    } catch (error) {
        console.log(error);
    };
};

export const getQbenchResults =async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getQbenchResults);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const getUserDetails = async (params: AppointmentsListParams) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getCustomersSearchData, params);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    }
};

export const adminRegisterLogin = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.adminRegisterLogin, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const getEmployeeTesting =async () => {
    try {
        const response = await cloudRunGet(config.cloudRunApi.getEmployeeTesting);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const addEmployeeTesting = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.addEmployeeTesting, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteEmployeeTesting = async (params: Object) => {
    try {
        const response = await cloudRunDelete(config.cloudRunApi.deleteEmployeeTesting, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const getDayWiseAppointmentsSearchData = async (params: AppointmentsListParams) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getDayWiseAppointmentsSearchData, params);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    }
}

export const getAdminConfigDaywise = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getAdminConfig, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const insertAdminConfigDaywise = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.insertAdminConfig, params);
        return response?.data.data;
    } catch (error) {
        console.log(error);
    }
}

export const getAppointmentdata = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.getAppointmentdata, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const updateAppointmentdata = async (params: Appointment) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.updatedaywiseAppointment, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export const userCheckin = async (params: Object) => {
    try {
        const response = await cloudRunPost(config.cloudRunApi.userCheckin, params);
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}