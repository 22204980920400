import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, Button, Grid, IconButton, TextField, Box, Typography, Snackbar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { checkemail, updateMobile } from './Model';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiAlert from '@material-ui/lab/Alert'
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    heights: {
        height: '55px',
        backgroundColor: '#1890ff',
        borderRadius: '0px',
        '&:focus,&:hover': {
            backgroundColor: '#3c82b7'
        }
    },
    heightfield: {
        height: '55px',
        '&:focus': {
            borderColor: '#3c82b7'
        }
    },
    containerheight: {
        height: '70vh'
    },
    heights1: {
        backgroundColor: '#1890ff',
        borderRadius: '0px',
        '&:focus,&:hover': {
            backgroundColor: '#3c82b7'
        }
    },
});

export default function ChangeMobileNumber() {

    const classes = useStyles();
    const [rows, setrows] = React.useState<any>([]);
    const [email, setemail] = React.useState<string>('');
    const [alertbox, setalertbox] = React.useState({ open: false, type: '', msg: '' })
    const [Reload, setReload] = React.useState<any>(false);
    const [getdata, setgetdata] = React.useState<any>(false);
    const [Numbers, setNumbers] = React.useState<any>(false);
    const [newphone, setnewphone] = React.useState<any>('')
    const [changephonenumber, setchangephonenumber] = React.useState<false | true>(false);
    const updateMobileNumber = (id: any) => {
        let mobile = newphone;
        if (mobile === '' || mobile.length < 10) {
            setalertbox({ open: true, type: 'error', msg: 'Please Enter Valid Number' })
        }
        else {
            setchangephonenumber(true)
            updateMobile(id, mobile).then((res: any) => {
                if (res.data) {
                    setchangephonenumber(false)
                    setnewphone('');
                    setrows([]);
                    setReload(false);
                    setgetdata(false);
                    setalertbox({ open: true, type: 'info', msg: 'Mobile Number Updared Successfully.' })
                }
            })
        }
    }

    function Bodydata({ data }: any) {
        return (
            rows.map((row: any) => (
                <Box mt={2}>
                    <hr />
                    <Typography variant="subtitle1" color="initial"> <b>Name :</b> {row.firstName + ' ' + row.lastName}</Typography>
                    <br />
                    <Typography variant="subtitle1" color="initial"> <b>E-Mail :</b> {row.email}</Typography>
                    <br />
                    {Numbers ? (<>
                        <Typography variant="subtitle1" color="initial"> <b>Phone : &nbsp;</b>
                            <TextField
                                autoFocus
                                variant="outlined"
                                label="Phone Number"
                                value={newphone}
                                onChange={(e) => setnewphone(e.target.value)}
                            /> &nbsp;&nbsp; &nbsp;
                            <Button variant="contained" onClick={() => { updateMobileNumber(row.id) }} color="primary" className={classes.heights}>
                                {!changephonenumber ? 'Change Number' : 'Please Wait...'}
                            </Button>&nbsp;&nbsp;
                            <IconButton aria-label="Edit" size='small' onClick={() => setNumbers(false)}>
                                <CancelIcon color='secondary' />
                            </IconButton>
                        </Typography>
                    </>) : (<>
                        <Typography variant="subtitle1" color="initial"> <b>Phone :</b> {row.phone}
                            <IconButton aria-label="Edit" size='small' onClick={() => setNumbers(true)}>
                                <EditIcon color='primary' />
                            </IconButton>
                        </Typography>
                    </>)
                    }
                    <br />
                    <Typography variant="subtitle1" color="initial"> <b>Date Of Birth :</b> {row.birthDate}</Typography>
                </Box>
            ))
        )
    }
    const submithandler = () => {
        setrows([]);
        setgetdata(false);
        let newarr: any = [];
        if (email !== '') {
            setReload(true);
            checkemail(email.toLowerCase()).then((response: any) => {
                const newrow = response;
                newrow.map((row: any) => {
                    let newdata = { id: row.id, firstName: row.firstName, lastName: row.lastName, phone: row.phone, email: row.email, birthDate: row.birthDate }
                    newarr.push(newdata);
                })
                setgetdata(true);
                setReload(false);
                setrows(newarr);
                setemail('');
            });
        }
        setReload(false);
    }
    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = () => {
        setalertbox({ open: false, type: '', msg: '' })
    }
    return (
        <Container maxWidth='md'>
            <Snackbar open={alertbox.open} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Alert onClose={handleClose} severity={alertbox.type}>
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            <Grid container justify='center' alignItems='center' className={classes.containerheight}>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <Typography variant="h5" color="initial"><u>User Info</u></Typography>
                            <br />
                            <Grid container justify='space-between'>
                                <Grid item xl={9} lg={9} md={9} sm={12}>
                                    <Box mt={2} mb={2}>
                                        <TextField fullWidth className={classes.heightfield} id="outlined-basic" value={email} onChange={(e) => {
                                            setemail(e.target.value);
                                        }} label="Enter E-Mail" variant="outlined" />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box mt={2} mb={2}>
                                        <Button variant="contained" onClick={() => { submithandler(); setReload(true); }} fullWidth color="primary" className={classes.heights}>
                                            Search By Email
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {
                                rows.length > 0 ?
                                    <Bodydata data={rows} /> :
                                    Reload ? (
                                        <Box mt={5} mb={3} textAlign='center' alignItems='center'>
                                            <hr />
                                            <CircularProgress color="primary" />
                                        </Box>
                                    ) : getdata ? (<Box mt={3} mb={3} textAlign='center' alignItems='center'>
                                        <hr />
                                        No Data Found...
                                    </Box>) : ''
                            }
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
