/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import clsx from 'clsx';
// core components
import AdminNavbarLinks from '../../layouts/Navbars/AdminNavbarLinks.js';

import styles from '../../AppointmentList/sidebarStyle.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useAdminDispatch,useAdminState} from '../../../../../../src/modules/admin/provider'

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const { color, logo, image, logoText, routes } = props;
    const classes = useStyles();
     const { updateParams, setAppointments } = useAdminDispatch();
     const { params } = useAdminState();
    // verifies if routeName is the one active (in browser input)
    const handleClickAway = () => {
        props.handleDrawerToggle(false);
      updateParams({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: '',
        locationQbenchId: null,
        sampleId: null,
    })
    setAppointments([])
    };

    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }

    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = ' ';
                var listItemClasses;
                if (prop.path === '/upgrade-to-pro') {
                    activePro = classes.activePro + ' ';
                    listItemClasses = classNames({
                        [' ' + classes[color]]: true,
                    });
                } else {
                    listItemClasses = classNames({
                        [' ' + classes[color]]: activeRoute(
                            prop.layout + prop.path
                        ),
                    });
                }
                const whiteFontClasses = classNames({
                    [' ' + classes.whiteFont]: activeRoute(
                        prop.layout + prop.path
                    ),
                });
                return (
                    <NavLink
                        to={prop.layout + prop.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        {prop.display === true ? (
                            <ListItem
                                button
                                className={classes.itemLink + listItemClasses}
                                onClick={handleClickAway}
                            >
                                {typeof prop.icon === 'string' ? (
                                    <Icon
                                        className={classNames(
                                            classes.itemIcon,
                                            whiteFontClasses,
                                            {
                                                [classes.itemIconRTL]:
                                                    props.rtlActive,
                                            }
                                        )}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(
                                            classes.itemIcon,
                                            whiteFontClasses,
                                            {
                                                [classes.itemIconRTL]:
                                                    props.rtlActive,
                                            }
                                        )}
                                    />
                                )}
                                <ListItemText
                                    primary={
                                        props.rtlActive
                                            ? prop.rtlName
                                            : prop.name
                                    }
                                    className={classNames(
                                        classes.itemText,
                                        whiteFontClasses,
                                        {
                                            [classes.itemTextRTL]:
                                                props.rtlActive,
                                        }
                                    )}
                                    disableTypography={true}
                                />
                            </ListItem>
                        ) : null}
                    </NavLink>
                );
            })}
        </List>
    );

    var brand = (
        <div className={classes.logo}>
            <a
                href="#"
                className={classNames(classes.logoLink)}
                //target="_blank"
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>

                {logoText}
            </a>
        </div>
    );
    return (
        <div>
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    anchor={props.rtlActive ? 'left' : 'right'}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{
                                backgroundImage: 'url(' + image + ')',
                            }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <ClickAwayListener>
                    <Drawer
                        anchor={props.rtlActive ? 'right' : 'left'}
                        variant="temporary"
                        open={props.open}
                        onClose={props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        // onEscapeKeyDown={handleDrawerClose}
                        // onBackdropClick={handleDrawerClose}
                        // classes={{
                        //   paper: classNames(classes.drawerPaper, {
                        //     [classes.drawerPaperRTL]: props.rtlActive
                        //   })
                        // }}
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        {brand}
                        <div className={classes.sidebarWrapper}>{links}</div>
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                }}
                            />
                        ) : null}
                    </Drawer>
                </ClickAwayListener>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
};
